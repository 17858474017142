import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const kakaoLogin = createAsyncThunk("others/kakaoLogin", async () => {
  const res = await api.get(`/base/kakaoLogin`);
  return res.data;
});

export const naverLogin = createAsyncThunk(
  "others/naverLogin",
  async (params) => {
    const res = await api.post(`/base/naverLogin`, params);
    return res.data;
  }
);

export const fetchAlarmLists = createAsyncThunk(
  "others/fetchAlarmLists", //알람 리스트 가져오기
  async (params) => {
    const res = await api.get(`/user/getAlarm/${params}`);
    if (res.data.ok) {
      return res.data.alarmList;
    }
  }
);

export const newAlarmCheck = createAsyncThunk(
  "others/newAlarmCheck", //새알람 여부 체크
  async (params) => {
    const res = await api.get(`/user/newAlarmCheck/${params}`);
    return res.data;
  }
);

export const setAlarmRead = createAsyncThunk(
  "others/setAlarmRead", //새알림 1개 읽기
  async (params) => {
    await api.get(`/user/setAlarmRead/${params}`);
  }
);

export const setAlarmReadAll = createAsyncThunk(
  "others/setAlarmReadAll", //새알림 전체 읽기
  async (params) => {
    const res = await api.get(`/user/setAlarmReadAll/${params}`);
    return res.data;
  }
);

export const sendMobileNo = createAsyncThunk(
  "others/sendMobileNo",
  async (params) => {
    const res = await api.post(`/base/sendMobileAuthNumber`, params);
    if (res) {
      return res.data;
    }
  }
);

export const checkRegisteredId = createAsyncThunk(
  "others/checkRegisteredId", //아이디(휴대폰 번호) 중복 체크-중복회원 체크
  async (params) => {
    const res = await api.get(`/user/idDubCheck/${params}`);
    if (res) {
      return res.data;
    }
  }
);

export const checkMobileAutoNo = createAsyncThunk(
  "others/checkMobileAutoNo",
  async (params) => {
    const res = await api.post(`/base/checkMobileAuthNumber`, params);
    if (res) {
      return res.data;
    }
  }
);

export const checkQuestionsComplete = createAsyncThunk(
  "others/checkQuestionsComplete", //설문 여부 체크
  async (params) => {
    // const res = await api.get(`/user/userQuestionCheck/${params}`);
    const res = await api.get(`/user/userQuestionCheckNew/${params}`);
    if (res.data.ok) {
      return res.data;
    }
  }
);

export const checkOpenTutorial = createAsyncThunk(
  "others/checkOpenTutorial", //튜토리얼 다시 보기
  async (params) => {
    const res = await api.get(`/user/setOpenTutorial3/${params}`);
    if (res.data.ok) {
      return res.data;
    }
  }
);
export const checkCloseTutorial = createAsyncThunk(
  "others/checkCloseTutorial", //튜토리얼 다시 보지 않기
  async (params) => {
    const res = await api.get(`/user/setNotOpenTutorial3/${params}`);
    if (res.data.ok) {
      return res.data;
    }
  }
);

export const checkNickname = createAsyncThunk(
  "others/checkNickname", //닉네임 중복확인 조회
  async (params) => {
    const res = await api.get(`user/checkNickName/${params}`);
    return res.data;
  }
);

export const fetchMyinfo = createAsyncThunk(
  "others/fetchMyinfo", //사용자 아이디로 내 정보 조회
  async (params) => {
    const res = await api.get(`/user/myInfo/${params}`);
    if (res.data.ok) {
      return res.data;
    }
  }
);

export const fetchMyActivity = createAsyncThunk(
  "others/fetchMyActivity", //즐겨찾기 식단, 즐겨찾기 레시피, 좋아요와 싫어요 음식
  async (params) => {
    const res = await api.post(`/user/getActivityRecord`, params);
    return res.data;
  }
);

const initialState = {
  alarmLists: [],
  status: null,
  mobileStatus: {},
  questionsCompleteCheck: {},
  userInfo: [],
  bookmarkedRecipeLists: null,
  bookmarkedMealLists: null,
  bookmarkedStatus: null,
  likeFoods: null,
  dislikeFoods: null,
};

const otherSlice = createSlice({
  name: "기타",
  initialState,
  reducers: {
    onResetBookmarkedStatus: (state) => {
      state.bookmarkedStatus = null;
    },
    onResetQuestionComplete: (state) => {
      state.questionsCompleteCheck = {};
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAlarmLists.fulfilled, (state, action) => {
      state.alarmLists = action.payload;
    });
    builder.addCase(sendMobileNo.fulfilled, (state, action) => {
      state.status = "success";
      state.mobileStatus = action.payload;
    });
    builder.addCase(checkMobileAutoNo.fulfilled, (state, action) => {
      state.status = "success";
      state.mobileAuthNoStatus = action.payload;
    });
    builder.addCase(checkQuestionsComplete.fulfilled, (state, action) => {
      state.questionsCompleteCheck = action.payload;
    });
    builder.addCase(fetchMyinfo.fulfilled, (state, action) => {
      state.status = "success";
      state.userInfo = action.payload;
    });
    builder.addCase(fetchMyActivity.pending, (state) => {
      state.bookmarkedStatus = "pending";
    });
    builder.addCase(fetchMyActivity.fulfilled, (state, action) => {
      state.bookmarkedStatus = "success";
      state.bookmarkedRecipeLists = action.payload.recipeList;
      state.bookmarkedMealLists = action.payload.recommendHList;
      state.likeFoods = action.payload.foodLikeList;
      state.dislikeFoods = action.payload.foodHateList;
    });
    builder.addCase(fetchMyActivity.rejected, (state) => {
      state.bookmarkedStatus = "rejected";
    });
  },

  //  {
  //   [fetchAlarmLists.fulfilled]: (state, action) => {
  //     state.alarmLists = action.payload;
  //   },
  //   [sendMobileNo.fulfilled]: (state, action) => {
  //     state.status = "success";
  //     state.mobileStatus = action.payload;
  //   },
  //   [checkMobileAutoNo.fulfilled]: (state, action) => {
  //     state.status = "success";
  //     state.mobileAuthNoStatus = action.payload;
  //   },
  //   [checkQuestionsComplete.fulfilled]: (state, action) => {
  //     state.questionsCompleteCheck = action.payload;
  //   },
  //   [fetchMyinfo.fulfilled]: (state, action) => {
  //     state.status = "success";
  //     state.userInfo = action.payload;
  //   },
  //   [fetchMyActivity.pending]: (state) => {
  //     state.bookmarkedStatus = "pending";
  //   },
  //   [fetchMyActivity.fulfilled]: (state, action) => {
  //     state.bookmarkedStatus = "success";
  //     state.bookmarkedRecipeLists = action.payload.recipeList;
  //     state.bookmarkedMealLists = action.payload.recommendHList;
  //     state.likeFoods = action.payload.foodLikeList;
  //     state.dislikeFoods = action.payload.foodHateList;
  //   },
  //   [fetchMyActivity.rejected]: (state) => {
  //     state.bookmarkedStatus = "rejected";
  //   },
  // },
});

export const { onResetBookmarkedStatus, onResetQuestionComplete } =
  otherSlice.actions;

export default otherSlice.reducer;
