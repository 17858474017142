import React from "react";
const NutritionInner = ({ imgUrl, num, unit, title }) => {
  return (
    <div className="space-y-2 flex flex-col justify-between text-center ">
      <img
        className="my-[-30px] h-[120px] scale-[50%]"
        src={imgUrl}
        alt={"영양 음식 이미지" + title}
      />

      <h1 className="flex flex-wrap justify-center text-body1_700 text-orange_140">
        <span className="block">{num}</span>
        <span className="block">{unit}</span>
      </h1>
      <h2 className="text-body3_700 text-gray_160">{title}</h2>
    </div>
  );
};

export default NutritionInner;
