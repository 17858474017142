import ApexChart from "react-apexcharts";
import dayjs from "dayjs";
import customToolTip from "./customToolTipGraph";
const WeightGraph = ({ graphOption, measureUnit, weightLists }) => {
  // const [dummyValue, forceUpdate] = useState(false)

  const xData = weightLists
    ? weightLists?.map((el) => (el.data ? el.data : 0))
    : [];
  const yDate = weightLists
    ? weightLists?.map((el) => dayjs(el.day).format("MM.DD"))
    : [];

  const areaTotal =
    Math.max(...xData) < 1 ? 1 : Math.max(...xData) + Math.max(...xData) / 3;

  // const nullData = xData
  //   ? xData?.map((el) =>
  //       el === null
  //         ? Math.max(...xData) / 90
  //           ? Math.max(...xData) / 90
  //           : 0.01
  //         : 0
  //     )
  //   : [];

  return (
    <div className="admin-graphs">
      <div id="yellow_h" className="border rounded-lg border-gray_60">
        <ApexChart
          // series={[{ data: nullData }, { data: xData }]}
          series={[{ data: xData }]}
          options={{
            // fill: {
            //   colors: ["#FFE9A0"],
            //   opacity: 1,
            // },
            colors: ["#FFE9A0"],
            chart: {
              selection: {
                enabled: true,
              },
              type: "line",

              toolbar: {
                show: false,
                autoSelected: undefined,
              },
              stacked: true,
            },
            stroke: {
              curve: "smooth",
              width: [5, 5],
            },
            // plotOptions: {
            //   bar: {
            //     horizontal: false,
            //     borderRadius: graphOption === "최근 7일" ? [7, 7] : [5, 5],
            //     columnWidth: graphOption === "최근 7일" ? "15%" : "70%",
            //     dataLabels: {
            //       position: "top",
            //       hideOverflowingLabels: false,
            //     },
            //   },
            // },
            dataLabels: {
              enabled: true,
              background: false,

              // enabledOnSeries: [0],
              // formatter: function (val) {
              //   if (graphOption === "최근 7일")
              //     return val !== null ? val?.toFixed(1) : "x";
              //   else return "";
              // },
              formatter: function (val) {
                return val !== 0 ? val?.toFixed(1).toLocaleString("ko-KR") : "";
              },
              offsetY: -10,
              style: {
                fontSize: "12px",
                colors: ["#746f6a"],
                fontWeight: "600",
              },
            },

            xaxis: {
              categories: yDate,
              tickPlacement: "on",
              position: "bottom",
              labels: {
                rotate: 0,
                hideOverlappingLabels: true,
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tickAmount: 7,
              // crosshairs: {
              //   //bar background
              //   show: true,
              //   position: "back",
              //   width: 1,
              //   stroke: {
              //     color: "#A39F9B",
              //     opacity: 1,
              //     width: 1,
              //     dashArray: 5,
              //   },
              // },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              min: 0,
              max: areaTotal,
              tickAmount: 5,
              labels: {
                show: graphOption === "최근 30일",
                formatter: function (val) {
                  if (val <= 1) return Math.round(val * 5);
                  return Math.round(val).toLocaleString("ko-KR");
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            states: {
              hover: {
                filter: {
                  type: "none",
                },
              },
              active: {
                filter: {
                  type: "none",
                },
              },
            },
            legend: {
              show: false,
            },
            tooltip: {
              enabled: true,
              enabledOnSeries: [0],
              custom: ({ series, seriesIndex, dataPointIndex }) =>
                customToolTip(
                  series,
                  seriesIndex,
                  dataPointIndex,
                  measureUnit,
                  yDate
                ),
            },
            grid: {
              padding: {
                right: 0,
                left: graphOption === "최근 7일" ? 5 : 10,
              },
            },
          }}
          type="line"
          height={350}
        />
      </div>
    </div>
  );
};

export default WeightGraph;
