import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllRepliesOfOnePosting,
  handlePageChangeCommu,
  handleReplySliceArr,
  onClickCommentOpen,
  onSetCommTab,
} from "../../../../features/admin/communitySlice";
import Navbar from "../../components/common/Navbar";
import Sidebar from "../../components/common/Sidebar";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import {
  onSetSection,
  onSetSubSection,
} from "../../../../features/admin/commonSlice";
import { BGButtonCustom } from "../../components/common/ButtonCustom";
import InformationPosting from "./InformationPosting";
import NotEditPosting from "./NotEditPosting";
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";
import CustomCenterModal from "../../components/common/CustomCenterModal";
import CommunityDetailReply from "../../components/board/CommunityDetailReply";
const InformationDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [prevbtn, setPrevbtn] = useState();
  const [nextbtn, setNextbtn] = useState();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const { isInInfoId, postingIdList, commentOpen, postingIdReplies } =
    useSelector((state) => state.commu);

  const [openEdit, setOpenEdit] = useState(false);
  const pageReplyCount = 5;
  const closesvg = "/images/admin/icon/close.svg";
  const goCustomerCenter = () => {
    navigate("/admin/board");
    dispatch(onSetCommTab("정보"));
    dispatch(onSetSubSection("정보"));
    dispatch(onSetSection("게시판 관리"));
  };

  const prevCenter = () => {
    if (prevbtn) {
      if (prevbtn === postingIdList?.id) {
        toastRepeat("이전글이 없습니다.");
      } else {
        buttonPosting(prevbtn);
      }
    } else {
      toastRepeat("이전글이 없습니다.");
    }
  };
  const toastRepeat = (ment) => {
    toast(<p>{ment}</p>, toastCommonProps("top-right", "toast_alert", 1000));
  };
  const nextCenter = () => {
    if (nextbtn) {
      if (nextbtn === postingIdList?.id) {
        toastRepeat("다음글이 없습니다.");
      } else {
        buttonPosting(nextbtn);
      }
    } else {
      toastRepeat("다음글이 없습니다.");
    }
  };
  const repeatRes = (res, api) => {
    if (api === "delete") {
      if (res.data.ok) {
        toastRepeat("해당 글이 삭제되었습니다.");
        navigate(-1);
      }
    } else {
      if (res.ok) {
        const viewer = new Viewer({
          el: document.querySelector("#viewer"),
        });
        const removeImageBr = res.posting.content?.replaceAll(
          '"false"><br>',
          '"false"><p><br></p>'
        );
        const whenEnterPlusBr = removeImageBr.replaceAll("<br>", "&nbsp;");
        viewer.setMarkdown(whenEnterPlusBr);
        setPrevbtn(res.prePostingID);
        setNextbtn(res.nextPostingID);
        handlePageFn(1, res.totalReplies);
      }
    }
  };
  const buttonPosting = useCallback(
    async (btnNum) => {
      let viewerInner = document.querySelector(".toastui-editor-contents");

      if (viewerInner) viewerInner.remove();
      let param = { postingId: btnNum, isInfo: true };
      const res = await dispatch(getAllRepliesOfOnePosting(param)).unwrap();
      repeatRes(res);
    },
    [dispatch, getAllRepliesOfOnePosting]
  );

  const getIdPosting = useCallback(async () => {
    if (isInInfoId) {
      let param = { postingId: isInInfoId.id, isInfo: true };
      const res = await dispatch(getAllRepliesOfOnePosting(param)).unwrap();
      repeatRes(res);
    }
  }, [dispatch, getAllRepliesOfOnePosting]);

  const handlePageFn = useCallback(
    (page, totalReple) => {
      dispatch(handlePageChangeCommu(page));
      const slicePages =
        totalReple &&
        totalReple.slice((page - 1) * pageReplyCount, page * pageReplyCount);
      dispatch(handleReplySliceArr(slicePages));
    },
    [dispatch, handlePageChangeCommu, handleReplySliceArr]
  );

  const toggleModalFalse = () => {
    dispatch(onClickCommentOpen(false)); //comment default
  };
  useEffect(() => {
    if (loginInfo?.adminId) {
      getIdPosting();
    } else {
      navigate("/admin/signin");
    }
  }, []);

  return (
    <>
      {commentOpen && (
        <CustomCenterModal
          onClickFc={toggleModalFalse}
          customlayout={
            "absolute top-1/2 left-1/2 transform-50 bg-white rounded-xl w-[800px] z-30 overflow-hidden"
          }
        >
          <div className="h-[600px] min-h-[400px] gap-[16px] overflow-y-auto py-[24px]">
            <div className="absolute left-0 top-0 z-30 flex w-full items-center justify-between rounded-t-[8px] border-b  border-gray_80 bg-white p-[8px]">
              <span className="text-subtitle_1 block flex items-center px-[24px] font-bold text-black">
                {"정보 댓글보기"}
              </span>
              <img
                src={closesvg}
                alt="close"
                className="cursor-pointer"
                onClick={toggleModalFalse}
              />
            </div>
            <div className="mt-16 rounded-b-[8px] bg-white">
              <div className="my-4 w-full ">
                {postingIdReplies?.length > 0 ? (
                  postingIdReplies?.map((el, idx) => (
                    <CommunityDetailReply
                      key={idx}
                      el={el}
                      postingId={postingIdList.id}
                    />
                  ))
                ) : (
                  <div className="px-16 py-4">게시글에 댓글이 없습니다.</div>
                )}
              </div>
            </div>
          </div>
        </CustomCenterModal>
      )}
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex flex-col items-start justify-start gap-[16px] p-[24px]">
          <div className="flex h-full w-full flex-col items-start justify-start p-0">
            <div className="w-a uto order-1 flex h-full flex-none grow-0 flex-col items-start justify-start self-stretch">
              <div id="infoDetailTop"></div>
              <div className="flex w-full flex-col items-start gap-[8px] rounded-3xl bg-[#FCFCFC] p-[16px]  px-[14px] shadow-md">
                <div className="flex w-full items-center justify-end px-[12px]">
                  <div className="flex gap-[12px]">
                    {isInInfoId && !openEdit && (
                      <>
                        <BGButtonCustom
                          onClick={prevCenter}
                          content={"이전 글"}
                          bgcolor={"bg-gray_70"}
                          textcolor={"text-gray_10"}
                          textsize={"text-caption1_600"}
                          icon={"/images/admin/icon/centerUp.svg"}
                          xpadding={"12px"}
                        />
                        <BGButtonCustom
                          onClick={nextCenter}
                          content={"다음 글"}
                          bgcolor={"bg-gray_70"}
                          textcolor={"text-gray_10"}
                          textsize={"text-caption1_600"}
                          icon={"/images/admin/icon/centerDown.svg"}
                          xpadding={"12px"}
                        />
                      </>
                    )}
                    <BGButtonCustom
                      onClick={goCustomerCenter}
                      content={"목록"}
                      bgcolor={"bg-gray_70"}
                      textcolor={"text-gray_10"}
                      textsize={"text-caption1_600"}
                      xpadding={"12px"}
                    />
                  </div>
                </div>
                {isInInfoId && !openEdit ? (
                  <NotEditPosting
                    setOpenEdit={setOpenEdit}
                    repeatRes={repeatRes}
                    handlePageFn={handlePageFn}
                  />
                ) : (
                  <InformationPosting />
                )}
              </div>
              <div id="infoDetailBottom"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationDetail;
