import React from "react";

const CommListSkeleton = () => {
  return (
    <>
      <div className="bg-white flex flex-col gap-4 py-5 px-4 border-t border-b border-gray_20 animate-pulse">
        <div className="flex flex-col gap-2.5">
          <ul className="flex flex-row gap-1">
            <li className="h-5 w-14 mr-1 rounded-xl bg-gray_20" />
            <li className="h-5 w-9 rounded-xl bg-gray_20" />
            <li className="h-5 w-9 rounded-xl bg-gray_20" />
          </ul>
          <div className="flex flex-col gap-2">
            <div className="h-5 w-[90%] rounded-xl bg-gray_20" />
            <div className="h-5 w-[55%] rounded-xl bg-gray_20" />
          </div>
          <div className="flex justify-between">
            <div className="h-5 w-[45%] bg-gray_20 rounded-xl" />
            <div className="flex gap-1">
              <div className="h-5 w-9 bg-gray_20 rounded-xl" />
              <div className="h-5 w-9 bg-gray_20 rounded-xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CommListSkeleton;
