import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchInfoPostingDetails } from "../../../../features/mobile/infoSlice";
import { mobileTypeFn } from "../../components/logic";
import Btn from "../../components/common/Btn";
import PostDetailsCard from "../../components/information/PostDetailsCard";
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";

const InfoDetailForShare = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { postingDetails } = useSelector((state) => state.info);

  const onMoveToStore = () => {
    window.scrollTo(0, 0);
    if (mobileTypeFn() === "iphone") {
      //아이폰
      window.open(`https://apps.apple.com/kr/app/id1636424503`, "_blank");
    } else if (mobileTypeFn() === "android") {
      //안드로이드
      window.open(`market://details?id=com.herings.healiary&pli=1`, "_blank");
    } else {
      //웹
      window.open(
        `https://play.google.com/store/apps/details?id=com.herings.healiary`,
        "_blank"
      );
    }
  };

  const bringPostingDetails = useCallback(async () => {
    let params = { userId: loginInfo.userId, postingId: id };
    const res = await dispatch(fetchInfoPostingDetails(params)).unwrap();
    const viewer = new Viewer({
      el: document.querySelector("#viewer"),
    });
    const brchange = res.posting.content?.replaceAll(
      '"false"><br>',
      '"false"><p><br></p>'
    );
    viewer.setMarkdown(brchange);

    if (res.ok) {
      viewer.setMarkdown(res.posting.content);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    bringPostingDetails();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {postingDetails ? (
        <section className="mb-28">
          <PostDetailsCard data={postingDetails.posting} />
          <div className="mt-8 p-4">
            <Btn
              bgColor={"bg-main"}
              textColor={"text-white"}
              borderColor={"border border-main"}
              title={"맞춤 식단 받기"}
              active={"on"}
              onAction={onMoveToStore}
            />
          </div>
        </section>
      ) : null}
    </>
  );
};

export default InfoDetailForShare;
