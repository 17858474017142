import React, { useEffect, useState, useRef } from "react";
import { regDecimal } from "../../components/logic";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import Btn from "../common/Btn";

const InputDialog = ({
  option,
  openDialog,
  setOpenDialog,
  feelings,
  onSaveDiary,
  savedDatas,
  symptomArr,
}) => {
  const inputRef = useRef(null);
  const textRef = useRef(null);
  const [value, setValue] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [memoMsg, setMemoMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const onChangeInput = (e) => {
    setValue(e.target.value.slice(0, 4));
    if (regDecimal(e.target.value)) {
      if (
        (e.target.value.length > 0 && Number(e.target.value) < 20) ||
        (e.target.value.length > 0 && Number(e.target.value) > 150)
      ) {
        //몸무게는 20-150 사이
        setErrorMsg("몸무게를 다시 입력해주세요");
      } else {
        setErrorMsg(null);
      }
    }
  };

  const symptomFn = (one) => {
    return (
      <li
        key={one.code}
        onClick={() => onCheckOne(one)}
        className={
          "rounded-2xl border border-indigo_100 p-4 text-btn_700 " +
          (checkedItems.find((item) => item.code === one.code)
            ? "bg-indigo_100 text-white"
            : "text-indigo_100")
        }
      >
        {" "}
        {one.name}
      </li>
    );
  };

  const onSaveAction = () => {
    if (option === "symptomCodeList") {
      onSaveDiary("symptomCodeList", checkedItems);
      setCheckedItems([]);
    } else if (option === "memo") {
      onSaveDiary("memo", memoMsg);
      setMemoMsg(null);
    }
    setOpenDialog(false);
    const body = document.querySelector("body");
    body.classList.remove("no-scroll");
  };

  const onCheckOne = (one) => {
    if (checkedItems.find((item) => item.code.includes(one.code))) {
      const arr = checkedItems.filter((el) => el.code !== one.code);
      setCheckedItems([...arr]);
    } else {
      setCheckedItems([...checkedItems, one]);
    }
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
    const body = document.querySelector("body");
    body.classList.remove("no-scroll");
    if (value) setValue("");
    else if (checkedItems) setCheckedItems([]);
    else if (memoMsg) setMemoMsg(null);
  };

  useEffect(() => {
    if (option === "symptomCodeList") {
      if (savedDatas?.[option]) {
        setCheckedItems([...savedDatas?.[option]]);
        console.log(...savedDatas?.[option], "...savedDatas?.[option]]");
      }
      return () => setCheckedItems([]);
    } else if (option === "memo") {
      setMemoMsg(savedDatas?.[option] ? savedDatas?.[option] : null);
      if (!savedDatas?.[option]) {
        //textRef.current?.focus()//시작하자마자 화면 따라 올라가는 현상 때문에 삭제
      }
      return () => setCheckedItems([]);
    }
    // eslint-disable-next-line
  }, [openDialog]);

  // 건강기록 체중, 메모 누르자마자 키보드 올라오게 구현 예정
  useEffect(() => {
    if (openDialog && option === "memo") {
      textRef.current.focus();
    }
  }, [openDialog, option]);

  return (
    <>
      <div
        onClick={onCloseDialog}
        className={
          openDialog
            ? "fixed left-0 top-0 z-10 h-full w-full bg-black opacity-70"
            : "hidden"
        }
      ></div>
      <div
        className={
          "maxW fixed left-0 right-0 z-20 mx-auto rounded-t-[32px] bg-white duration-700 ease-in-out " +
          (option === "feel"
            ? "h-[298px] "
            : option === "memo"
            ? "h-[364px] "
            : "h-[560px] ") +
          (openDialog
            ? "bottom-0"
            : option === "feel"
            ? "bottom-[-298px]"
            : option === "memo"
            ? "bottom-[-364px]"
            : "bottom-[-560px]")
        }
      >
        <div className="maxW flex items-start justify-between p-6">
          <div className="">
            <h1 className="text-subtitle2 text-black">
              {option === "feel"
                ? "기분"
                : option === "symptomCodeList"
                ? `증상(총 ${
                    checkedItems && checkedItems?.length > 0
                      ? checkedItems.length
                      : 0
                  }개)`
                : "메모"}
            </h1>
          </div>
          <img
            onClick={onCloseDialog}
            src="/images/mobile/icon/close.svg"
            alt="닫기 아이콘"
            className="m-3 h-6 w-6"
          />
        </div>
        {option === "feel" ? (
          <div className="mx-6">
            <ul className="grid grid-cols-5 gap-2 py-4">
              {feelings.map((feeling) => (
                <li
                  key={feeling.id}
                  onClick={() => onSaveDiary("feel", feeling.id)}
                  className="space-y-1 text-center"
                >
                  <img
                    className="mx-auto"
                    src={
                      savedDatas?.feel === feeling.id
                        ? "/images/mobile/icon/feeling/face" +
                          feeling.id +
                          "_active.svg"
                        : "/images/mobile/icon/feeling/face" +
                          feeling.id +
                          ".svg"
                    }
                    alt=""
                  />
                  <span
                    className={
                      "text-caption1_700 " +
                      (savedDatas?.feel === feeling.id
                        ? "text-orange_180"
                        : "text-black")
                    }
                  >
                    {feeling.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ) : option === "symptomCodeList" ? (
          <Swiper
            modules={[Pagination]}
            direction={"horizontal"}
            spaceBetween={10}
            pagination={{ clickable: true }}
            slidesPerView={1}
            slideToClickedSlide={true}
            centeredSlides={true}
          >
            {
              <>
                <SwiperSlide>
                  <ul className="flex flex-wrap gap-2 border border-gray_60 bg-white px-3 py-4">
                    {symptomArr.arr1.map((el) => symptomFn(el))}
                  </ul>
                </SwiperSlide>
                <SwiperSlide>
                  <ul className="flex flex-wrap gap-2 border border-gray_60 bg-white px-3 py-4">
                    {symptomArr.arr2.map((el) => symptomFn(el))}
                  </ul>
                </SwiperSlide>
                <SwiperSlide>
                  <ul className="mb-12 flex flex-wrap gap-2 border border-gray_60 bg-white px-3 py-4">
                    {symptomArr.arr3.map((el) => symptomFn(el))}
                  </ul>
                </SwiperSlide>
              </>
            }
          </Swiper>
        ) : (
          <div className="px-6">
            <textarea
              ref={textRef}
              onChange={(e) => setMemoMsg(e.target.value)}
              className="no-scrollbar h-[136px] w-full resize-none appearance-none whitespace-pre-line break-words rounded-md border border-gray_160 px-4 py-4 text-body2_500l text-black focus:outline-none"
              name="memo"
              value={memoMsg || ""}
              placeholder={
                savedDatas?.[option] ||
                "오늘의 기분 또는 증상을 자유롭게 기록해주세요."
              }
            ></textarea>
          </div>
        )}
        <div
          className={
            "mb-24 px-6 " + (option === "symptomCodeList" ? "pb-4" : "py-4")
          }
        >
          <Btn
            bgColor={"bg-gray_210"}
            textColor={"text-white"}
            borderColor={"border border-gray_210"}
            title={"확인"}
            active={
              (option === "feel" && savedDatas?.[option]) ||
              (option === "symptomCodeList" && checkedItems) ||
              (option === "memo" && memoMsg)
                ? "on"
                : "ready"
            }
            onAction={onSaveAction}
          />
        </div>
      </div>
    </>
  );
};

export default InputDialog;
