import React, { useState, useEffect } from 'react';
import InputBox from '../../components/common/InputBox';

const CertNumInput = ({ value, setValue, onChangeInput, onSetPrevStep, active, errorMsg }) => {
    const [time, setTime] = useState(180); // 인증번호 타이머 3분

    useEffect(() => {
        const now = new Date()
        window.localStorage.setItem("expireAt", JSON.stringify(new Date(now.getTime() + 3 * 60000)))
    }, [])

    // 타이머
    useEffect(() => {
        if (time > 0) {
            const expireAt = JSON.parse(localStorage.getItem("expireAt"));
            const certificationTimer = setInterval(() => {
                const gap = Math.floor((new Date(expireAt).getTime() - new Date().getTime()) / 1000)
                setTime(gap)
            }, [1000])
            return () => clearInterval(certificationTimer);
        }
        else {
            onSetPrevStep()
        }
    }, [time, onSetPrevStep])

    return (
        <div className='relative'>
            <InputBox option='common' type={'number'} placeholder={'인증번호'} onChangeInput={onChangeInput} value={value} setValue={setValue} time={time} active={active} errorMsg={errorMsg} />
        </div>
    );
};

export default CertNumInput;