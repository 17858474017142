import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { editPass } from "../../../../features/mobile/accountSlice";
import {
  checkRegisteredId,
  sendMobileNo,
  checkMobileAutoNo,
} from "../../../../features/mobile/otherSlice";
import { regMobile, regPass } from "../../components/logic";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import HeadCont from "../../components/common/HeadCont";
import Btn from "../../components/common/Btn";
import InputBox from "../../components/common/InputBox";
import TitleBox from "../../components/common/TitleBox";
import CertNumInput from "./CertNumInput";

const FindPass = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1); //1: 휴대폰 번호 입력, 2: 인증번호 입력, 3 새비밀번호 입력
  const [phoneNum, setPhoneNum] = useState("");
  const [certNum, setCertNum] = useState("");
  const [pwd, setPwd] = useState({ pass: "", again: "" });
  const [active, setActive] = useState("ready"); //ready, on
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsg2, setErrorMsg2] = useState({
    msg1: "영문, 숫자 조합 6자리 이상으로 입력해주세요",
    msg2: "",
  });
  const [active1, setActive1] = useState("ready");
  const [active2, setActive2] = useState("ready");
  const [responseSeq, setResponseSeq] = useState({});
  const passRef = useRef(null);
  const againRef = useRef(null);

  const onChangeInput = (e) => {
    let len = e.target.value.length;
    if (step === 1) {
      setPhoneNum(e.target.value);
      if (phoneNum && phoneNum?.length) {
        //휴대폰 번호 입력
        if (len === 3 || len === 8) setPhoneNum(e.target.value + " ");
        else setPhoneNum(e.target.value);
      }
    } else if (step === 2) {
      //인증번호 입력
      setCertNum(e.target.value);
      if (len === 5) setActive("on");
      else setActive("ready");
    }
  };

  const onChangeKeyDown = (e) => {
    console.log("123");
    let len = e.target.value.length;
    console.log(e.keyCode, len);
    if (e.keyCode === 8 && (len === 9 || len === 4)) {
      setPhoneNum(e.target.value.slice(0, e.target.value.length - 1));
    } else {
      setPhoneNum(e.target.value);
    }
  };

  const onChangeInput1 = (e) => {
    setPwd({ ...pwd, pass: e.target.value });
    if (!regPass(e.target.value)) {
      setErrorMsg2({
        msg1: "영문, 숫자 조합 6자리 이상으로 입력해주세요",
        msg2: "",
      });
      setActive1("ready");
      setActive2("ready");
    } else {
      setErrorMsg2({ msg1: "", msg2: "" });
      setActive1("on");
    }
  };

  const onChangeInput2 = (e) => {
    setPwd({ ...pwd, again: e.target.value });
    if (pwd.pass !== e.target.value) {
      setErrorMsg2({
        ...errorMsg2,
        msg2: "비밀번호 확인을 위해 다시 입력해주세요",
      });
      setActive2("ready");
    } else {
      if (active1 === "on") {
        setErrorMsg2({ ...errorMsg2, msg2: "비밀번호가 일치합니다" });
        setActive2("on");
      } else setActive2("ready");
    }
  };

  const onDeleteValue = (type) => {
    if (type === "pass") {
      setPwd({ pass: "", again: "" });
      setErrorMsg2({ ...errorMsg2, msg1: "" });
      passRef.current?.focus();
      setActive1("ready");
      setActive2("ready");
    } else {
      setPwd({ ...pwd, again: "" });
      setErrorMsg2({ ...errorMsg2, msg2: "" });
      againRef.current?.focus();
    }
  };

  const onSetNextStep = (step) => {
    if (step === 1) {
      let params = { mobileNo: phoneNum.replaceAll(" ", "") };
      dispatch(checkRegisteredId(phoneNum.replaceAll(" ", "")))
        .unwrap()
        .then((res) => {
          if (!res.ok) {
            //false일 경우 기존 회원, true: 없는 아이디
            dispatch(sendMobileNo(params))
              .unwrap()
              .then((res) => {
                if (res.ok) {
                  setResponseSeq(res.responseSEQ);
                  setStep(step + 1);
                  setActive("ready");
                  setErrorMsg("");
                } else {
                  setErrorMsg(res.error);
                }
              })
              .catch((err) => console.log(err));
          } else {
            setErrorMsg("가입하지 않은 회원입니다");
            setActive("ready");
            setPhoneNum("");
          }
        })
        .catch((err) => console.log(err));
    } else if (step === 2) {
      //핸드폰 문자 인증번호 확인
      let params = { authNo: certNum, responseSEQ: responseSeq };
      dispatch(checkMobileAutoNo(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            setStep(step + 1);
            setActive("ready");
            setErrorMsg("");
          } else {
            setErrorMsg(res.error);
            setActive("ready");
            setCertNum("");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onSetPrevStep = () => setStep(step - 1);

  const onSavePass = (e) => {
    e.preventDefault();
    let params = { userId: phoneNum.replaceAll(" ", ""), newPass: pwd.pass };
    dispatch(editPass(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          toast(
            <p>비밀번호 변경 되었습니다</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
          setPwd({ pass: "", again: "" });
          setPhoneNum("");
          setCertNum("");
          const timer = setTimeout(() => navigate(-1), 1000);
          return () => clearTimeout(timer);
        } else {
          setErrorMsg2({ ...errorMsg2, msg2: res.error });
          setPwd({ pass: "", again: "" });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (step === 1 && phoneNum?.length > 7) {
      if (regMobile(phoneNum.replaceAll(" ", ""))) {
        setErrorMsg("");
        setActive("on");
      } else {
        setErrorMsg("잘못된 형식의 휴대폰번호 입니다");
        setActive("ready");
      }
    } else if (step === 2 && certNum) {
      setErrorMsg("");
    } else if (step === 3) {
      let focused = true;
      if (focused) {
        passRef.current?.focus();
      }
      return () => (focused = false);
    }
  }, [phoneNum, certNum, step]);

  return (
    <>
      <HeadCont title={"비밀번호 찾기"} underline={true} />
      {step === 1 ? ( //1: 휴대폰 번호 입력
        <section className="mx-6 mt-[80px] flex flex-col gap-6">
          <TitleBox option={2} title={"휴대폰번호를 입력해주세요"} />
          <InputBox
            option="common"
            placeholder={"010 1111 2222"}
            comments={"‘-’를 제외하고 입력해주세요"}
            onChangeInput={onChangeInput}
            onChangeKeyDown={onChangeKeyDown}
            value={phoneNum}
            setValue={setPhoneNum}
            active={active}
            errorMsg={errorMsg}
          />
        </section>
      ) : step === 2 ? ( //2: 인증번호 입력
        <section className="mx-6 mt-[80px] flex flex-col gap-6">
          <p className="text-subtitle3 text-gray_180"></p>
          <TitleBox option={2} title={"인증번호를 입력해주세요"} />
          <CertNumInput
            value={certNum}
            setValue={setCertNum}
            onChangeInput={onChangeInput}
            onSetPrevStep={onSetPrevStep}
            active={active}
            errorMsg={errorMsg}
          />
        </section>
      ) : (
        <section className="mx-6 mt-[80px]">
          <form
            onSubmit={
              active1 === "on" && active2 === "on"
                ? onSavePass
                : (e) => e.preventDefault()
            }
            className="space-y-6"
          >
            <InputBox
              option="pass"
              refName={passRef}
              id={"pass"}
              nameTitle={"pass"}
              length={4}
              placeholder={"비밀번호"}
              onChangeAction={(e) => onChangeInput1(e)}
              value={pwd.pass || ""}
              active={active1}
              onClickAction={() => onDeleteValue("pass")}
              errorMsg={errorMsg2.msg1}
            />
            <InputBox
              option="pass"
              refName={againRef}
              id={"again"}
              nameTitle={"again"}
              length={4}
              placeholder={"비밀번호 확인"}
              onChangeAction={(e) => onChangeInput2(e)}
              value={pwd.again || ""}
              active={active2}
              onClickAction={() => onDeleteValue("again")}
              errorMsg={errorMsg2.msg2}
            />
            <Btn
              option={"bottom"}
              bgColor={"bg-main"}
              textColor={"text-white"}
              borderColor={"border border-main"}
              title={"비밀번호 저장"}
              active={active1 === "on" && active2 === "on" ? "on" : "ready"}
            />
          </form>
        </section>
      )}
      {(step === 1 || step === 2) && (
        <Btn
          option={"bottom"}
          bgColor={"bg-main"}
          textColor={"text-white"}
          borderColor={"border border-main"}
          active={active}
          onAction={() => onSetNextStep(step)}
          title={step === 1 ? "인증번호 문자 받기" : "인증번호 확인"}
        />
      )}
    </>
  );
};

export default FindPass;
