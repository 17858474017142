import React from 'react';
import TitleBox from '../common/TitleBox';

const IngredientsDialog = ({ openDialog, setOpenDialog, main, sub }) => {
    const datas = [{ id: 1, title: '주재료', dataName: main }, { id: 2, title: '부재료', dataName: sub }]

    return (
        <>
            <div onClick={() => setOpenDialog(false)} className={openDialog ? 'z-10 fixed top-0 left-0 w-full h-full bg-black opacity-70' : ''}></div>
            <div className={'z-20 fixed left-0 right-0 mx-auto bg-white maxW h-[550px] rounded-t-[32px] duration-700 ease-in-out overflow-scroll ' + (openDialog ? 'bottom-0' : 'bottom-[-550px]')} >
                <TitleBox option={7} title={'전체 재료 한 눈에 보기'} onAction={() => setOpenDialog(false)} />
                <div className='border-t border-gray_60 '>
                    <ul>
                        {datas.map(data =>
                            <li key={data.id} className='space-y-2 p-6 border-b border-gray_60'>
                                <TitleBox title={data.title} font={'text-caption1_700'} textColor={'text-gray_180'} />
                                <ul className='grid grid-cols-3 gap-4'>
                                    {data.dataName?.map((one, idx) =>
                                        <li key={idx}>
                                            <div className='w-[92px] h-[80px] rounded-xl bg-gray_160 overflow-hidden'>
                                                <img src='/images/mobile/image/준비중.svg' alt='재료 이미지' />
                                            </div>
                                            <TitleBox title={one} font={'text-caption1_700'} textColor={'text-gray_140'} />
                                        </li>
                                    )}
                                </ul>
                            </li>)}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default IngredientsDialog;