import React from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { onClickGetEditCommunityPosting } from "../../../../features/admin/communitySlice";
import { NoticeHeadList } from "../../components/common/TableHeadList";

export default function NoticeList({ showEachNoticeModal }) {
  const { allNotices } = useSelector((state) => state.commu);
  const dispatch = useDispatch();

  const repeatTd = (idx, data) => {
    const commoncss =
      "text-center py-4 text-caption_1 text-black px-4 border-gray_80 border-b";
    const 공지사항리스트 = [
      {
        tdclass:
          "border-b border-l border-gray_80 text-black text-center " +
          (idx + 1 === allNotices?.length ? " rounded-bl-lg" : ""),
        tdcontent: data?.no,
      },
      {
        tdclass: commoncss,
        tdcontent: data?.title,
      },
      { tdclass: commoncss, tdcontent: data.adminName },
      {
        tdclass: commoncss,
        tdcontent: dayjs(data.createdAt).format("YYYY-MM-DD"),
      },
      {
        tdclass: commoncss,
        tdcontent: dayjs(data.updatedAt).format("YYYY-MM-DD"),
      },
      {
        tdclass:
          " border-r px-4 border-b border-gray_80 text-center" +
          (idx + 1 === allNotices?.length ? " rounded-br-lg" : ""),
        tdcontent: data.isHide ? "비공개" : "공개",
      },
    ];
    return (
      <>
        {공지사항리스트?.map((el, index) => (
          <td className={el.tdclass} key={index}>
            {el.tdcontent}
          </td>
        ))}
      </>
    );
  };
  return (
    <table
      className="w-full col-span-3 border-separate table-auto 3xl:col-span-2"
      cellSpacing={0}
    >
      <thead>
        <tr className="bg-gray_60">
          {NoticeHeadList?.map((head, idx) => (
            <th
              key={idx}
              className={
                "border-y border-gray_80 p-4 text-black first:border-l last:border-r first:rounded-tl-lg last:rounded-tr-lg" +
                (idx === 0 ? " w-[80px] " : "")
              }
            >
              {head}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {allNotices?.map((data, idx) => (
          <tr
            key={idx}
            onClick={() => {
              showEachNoticeModal(data, "공지사항");
              dispatch(onClickGetEditCommunityPosting(data));
            }}
            className={
              "px-6 py-3  cursor-pointer hover:bg-primary_10 " +
              (idx % 2 === 1 ? "bg-gray_95" : "bg-gray_10")
            }
          >
            {repeatTd(idx, data)}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
