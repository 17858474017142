import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AnimatePresence } from "framer-motion";
import {
  fetchEatRecord,
  fetchRecommendMeal,
  fetchSymptomOneDay,
  onClickDaySelect,
} from "../../../../features/admin/managementSlice";
import RecommendMealDialog from "./RecommendMealDialog";
import CommonOption from "./CommonOption";
import { handlePageChange } from "../../../../features/admin/commonSlice";

const ManagementSubTab = ({
  tabNo,
  subTabs,
  onSetSubTab,
  subTabNo,
  days,
  onClickChange,
  // onClickChangeDiary,
  setDayNo,
  dayNo,
}) => {
  //이번주차 식사기록 및 추천식단보기 default 날짜계산
  const selRef = useRef();
  var now = new Date();
  var nowDayOfWeek = now.getDay();
  var nowDay = now.getDate();
  var nowMonth = now.getMonth();
  var nowYear = now.getYear();
  nowYear += nowYear < 2000 ? 1900 : 0;
  var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
  var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
  function formatDate(date) {
    var mymonth = date.getMonth() + 1;
    var myweekday = date.getDate();
    return nowYear + "-" + mymonth + "-" + myweekday;
  }
  //이번주차 식사기록 및 추천식단보기 default 날짜계산

  const dispatch = useDispatch();
  //weekDatas : 사용자가 입력한 주차 데이터리스트
  const { startDate, endDate, weekDatas } = useSelector(
    (state) => state.management
  );
  //symptomDaySelect : 증상, 일별 증상 기록보기 우측 select option 값
  const symptomDaySelect = useSelector(
    (state) => state.management.symptomDaySelect
  );
  const daySelect = useSelector((state) =>
    state.management.daySelect !== undefined ? state.management.daySelect : ""
  );

  const [selected, setSelected] = useState({
    startDate: formatDate(weekStartDate),
    endDate: formatDate(weekEndDate),
  });

  const [openDialog, setOpenDialog] = useState(false);
  const memberobj = useSelector(
    (state) => state.management.mangementDetailState
  );
  const userId = memberobj.userId; //나중에

  const onSetWeekDate = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const array = value.split(",");
    let params = { userId, startDate: array[0], endDate: array[1] };
    dispatch(fetchEatRecord(params));
    setSelected({ startDate: array[0], endDate: array[1] });
  };

  // const onOpenDialog = () => {
  //   let params = {
  //     userId,
  //     startDate: selected.startDate,
  //     endDate: selected.endDate,
  //   };
  //   dispatch(fetchRecommendMeal(params));
  //   setOpenDialog(true);
  // };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onChangeSymptomSelect = useCallback(() => {
    const symptomdaselect = selRef.current;
    let symptomdavalue =
      symptomdaselect.options[symptomdaselect.selectedIndex].value;
    dispatch(onClickDaySelect(symptomdavalue));
    let params = {
      userId: userId,
      day: symptomdavalue,
    };
    dispatch(fetchSymptomOneDay(params));
  }, [dispatch]);

  useEffect(() => {
    let params = {
      userId,
      startDate: formatDate(weekStartDate),
      endDate: formatDate(weekEndDate),
    };
    dispatch(fetchEatRecord(params));
    setSelected({
      startDate: formatDate(weekStartDate),
      endDate: formatDate(weekEndDate),
    });
    dispatch(onClickDaySelect(symptomDaySelect[0]));
  }, [dispatch, symptomDaySelect]);

  return (
    <>
      <div className="flex justify-between">
        <ul className="inline-flex h-min flex-shrink-0 gap-2 rounded-full border border-gray_80 bg-gray_20 p-1">
          {subTabs?.map((tab, idx) => (
            <li
              key={idx}
              onClick={() => {
                onSetSubTab(tab);
                setDayNo("최근 7일");
                if (tabNo === "체중" || tabNo === "다이어리") {
                  if (
                    tab === "체중 기록 보기" ||
                    tab === "다이어리 기록 보기"
                  ) {
                    onClickChange(1, "최근 7일", tabNo);
                  }
                }
                // else if (tabNo === "다이어리") {
                //   if (tab === "다이어리 기록 보기") {
                //     onClickChangeDiary(1, "최근 7일", tabNo);
                //   }
                // }
              }}
              className={
                "h-min flex-shrink-0 cursor-pointer rounded-full px-4 py-2 text-center  text-caption2_400 hover:shadow " +
                (subTabNo === tab
                  ? "bg-gray_10 font-semibold text-black shadow-sm "
                  : "bg-gray_90 font-normal text-gray_100 ")
              }
            >
              {tab}
            </li>
          ))}
        </ul>

        {subTabNo === "통계 그래프 보기" ||
        subTabNo === "체중 기록 보기" ||
        subTabNo === "주간 증상 설문 보기" ||
        subTabNo === "다이어리 기록 보기" ? (
          <div className="flex flex-wrap items-center justify-center gap-4">
            {tabNo === "증상" && (
              <p className="text-caption_1 text-primary_120">
                이번 주 증상 설문 참여 여부: 참여
              </p>
            )}

            <span className="text-caption1_400 text-gray_140">
              {startDate} ~ {endDate}
            </span>

            <ul className="flex gap-4">
              {days?.map((day, idx) => (
                <li
                  key={idx}
                  onClick={() => {
                    dispatch(handlePageChange(1));
                    onClickChange(1, day, tabNo);
                    setDayNo(day);
                  }}
                  className={
                    "text-body_2 cursor-pointer rounded-md border p-2 text-center text-gray_170 " +
                    (dayNo === day
                      ? "border-orange_100 bg-orange_20"
                      : "border-gray_100 bg-gray_10")
                  }
                >
                  {day}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="ml-5 flex flex-wrap items-center justify-center gap-2">
            {tabNo === "증상" ? (
              <>
                <p className="m-auto text-base">작성일</p>
                <select
                  ref={selRef}
                  onChange={onChangeSymptomSelect}
                  value={daySelect.date}
                  className="flex min-w-0 justify-between overflow-clip rounded-md border border-gray_20 px-2 py-3 hover:shadow-md focus:outline-gray_10"
                >
                  {symptomDaySelect?.length > 0 &&
                    symptomDaySelect?.map((data, idx) => (
                      <option key={idx}>{data}</option>
                    ))}
                </select>
              </>
            ) : (
              <div className="ml-5 flex gap-x-2">
                {/* 추천 식단 보기 */}
                <CommonOption
                  ver={3}
                  mywidth={300}
                  lists={weekDatas}
                  selectid={"weekDatas"}
                  onChangeFunction={onSetWeekDate}
                  weekstart={selected.startDate}
                  weekend={selected.endDate}
                />
                {/* <BGButtonCustom
                  onClick={onOpenDialog}
                  content={"추천 식단 보기"}
                  bgcolor={"bg-primary_100"}
                  textcolor={"text-gray_10"}
                  textsize={"text-body_2"}
                  xpadding={"12px"}
                /> */}
              </div>
            )}
          </div>
        )}
      </div>
      <AnimatePresence initial={false} mode="wait">
        {openDialog && (
          <RecommendMealDialog
            onCloseDialog={onCloseDialog}
            selected={selected}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ManagementSubTab;
