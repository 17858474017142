const date = new Date();
export const nowDate =
  date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

export function setSavedValue(e) {
  let value = e.target.value;
  let name = e.target.name;
  if (value === "kLack" || value === "pLack") return "부족";
  if (value === "kGood" || value === "pGood") return "양호";
  if (value === "kExcessive" || value === "pExcessive") return "과다";
  if (name === "files")
    return ["@" + e.target.files[0].name + ";type=" + e.target.files[0].type];
  else return value;
}

export function setSearchName(name, tabName) {
  let searchName =
    name === "암종류" || (tabName === "체중 가이드" && name === "치료 단계")
      ? "lcategory"
      : (tabName === "식사 가이드" && name === "치료 단계") ||
        name === "체중 변화"
      ? "mcategory"
      : name === "주차 및 시기" || name === "체중 상태"
      ? "scategory"
      : name;

  return searchName;
}

// 식사 가이드 select
export const foodOptions = [
  {
    id:1,
    label:"암종류",
    options:["-", "모든 암", "대장암", "위암"],
    type:"lcategory"
  },
  {
    id:2,
    label:"치료 단계",
    options:["-", "치료 중", "관리 중", "수술 후"],
    type:"mcategory"
  },
  {
     id:3,
     label:"주차 및 시기",
     options:  [
      "-",
      "열량 섭취 ≥ 110%",
      "90% ≤ 열량 섭취 < 110%",
      "75% ≤ 열량 섭취 < 90%",
      "50% < 열량 섭취 < 75%",
      "열량 섭취 ≤ 50%",
      "1주차",
      "2주차",
      "3주차",
      "4주차",
      "5주차",
      "6주차",
      "7주차",
      "8주차",
      "9주차",
      "10주차",
      "11주차",
      "12주차",
    ],
    type:"scategory"
  }
];


// 체중 가이드 select
export const weightOptions = [
  { 
    id:1,
    label:"치료 단계",
    options:["-", "치료 중", "관리 중"],
    type:"lcategory"
  },
  {
    id:2,
    label:"체중 변화",
    options:["-", "체중 증가", "약간의 체중 변화", "중증도 체중 변화", "심한 체중 감소"],
    type:"mcategory"
  },
  {
    id:3,
    label:"체중 상태",
    options:["-", "저체중", "정상체중", "비만 전단계", "비만"],
    type:"scategory"
  }
];

//체중가이드 modal select
export const weightModalOptions = [
  {
    id:2,
    label:"체중 변화",
    options:["-", "체중 증가", "약간의 체중 변화", "중증도 체중 변화", "심한 체중 감소"],
    type:"mcategory"
  },
  {
    id:3,
    label:"체중 상태",
    options:["-", "저체중", "정상체중", "비만 전단계", "비만"],
    type:"scategory"
  }
];

//증상가이드 input text layout
export const symptomInputs = [
  {
    id:1,
    label:"증상 명",
    name:"symptom",
  },{
    id:2,
    label:"용어 설명",
    name:"explanation",
  },{
    id:3,
    label:"대처방안 요약",
    name:"copingSummary",
  },
]
