import React from "react";

const TabCommon = ({ tabs, setTab, tab }) => {
  return (
    <ul className="flex px-6 border-b border-gray_60">
      {tabs.map((el) => (
        <li
          key={el}
          onClick={() => setTab(el)}
          className={
            "text-button w-1/2 py-4 text-center text-body3_700 font-bold " +
            (tab === el
              ? "border-b-[4px] border-gray_180 text-gray_180"
              : " text-gray_100")
          }
        >
          {el}
        </li>
      ))}
    </ul>
  );
};

export default TabCommon;
