import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const fetchTreatment = createAsyncThunk(
  // 해당일 진료일정 목록 가져오기
  "treatment/fetchTreatment",
  async (params) => {
    const res = await api.post(`/treatment/getTreatmentList`, params);
    console.log(res);
    return res.data;
  }
);

export const fetchOneTreatment = createAsyncThunk(
  // 진료일정 조회
  "treatment/fetchOneTreatment",
  async (id) => {
    const res = await api.get(`/treatment/getTreatment/${id}`);
    return res.data;
  }
);

export const saveTreatment = createAsyncThunk(
  // 진료일정 등록
  "treatment/saveTreatment",
  async (params) => {
    const res = await api.post(`/treatment/postTreatment`, params);
    return res.data;
  }
);

export const editOneTreatment = createAsyncThunk(
  // 진료일정 수정
  "treatment/editOneTreatment",
  async (params) => {
    const res = await api.post(`/treatment/modifyTreatment`, params);
    return res.data;
  }
);

export const deleteOneTreatment = createAsyncThunk(
  // 진료일정 삭제
  "treatment/deleteOneTreatment",
  async (id) => {
    const res = await api.get(`/treatment/delete/${id}`);
    return res.data;
  }
);

export const fetchExistTreatmentDataOfYear = createAsyncThunk(
  // 진료일정 여부 1년치
  "diary/fetchExistTreatmentDataOfYear",
  async (params) => {
    const res = await api.post(`/treatment/getTreatmentPoints`, params);
    if (res.data.ok) {
      return res.data;
    }
  }
);

const initialState = {
  // weeklyDiaryStatus: null,
  // weeklyDiaryLists: [],
  existDaysOfYear: [],
  // symptomLists: [],
  treatmentList: [],
};

const treatmentSlice = createSlice({
  name: "진료알림",
  initialState,
  // reducers: {
  // },

  extraReducers: (builder) => {
    builder.addCase(fetchTreatment.pending, (state) => {
      state.recommendMealListsStatus = "pending";
    });
    builder.addCase(fetchTreatment.fulfilled, (state, action) => {
      state.treatmentList = action.payload.list;
      // state.weeklyDiaryLists = action.payload.healthDiaryList;
    });
    // builder.addCase(fetchWeeklyDiary.rejected, (state) => {
    //   state.recommendMealListsStatus = "rejected";
    // });
    // builder.addCase(fetchSymptomLists.fulfilled, (state, action) => {
    //   state.symptomLists = action.payload;
    // });
    builder.addCase(
      fetchExistTreatmentDataOfYear.fulfilled,
      (state, action) => {
        state.existDaysOfYear = action.payload.list;
      }
    );
  },
});

export default treatmentSlice.reducer;
