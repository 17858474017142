import React, { useRef } from "react";
import $ from "jquery";

const InputCancerBox = ({
  nameTitle,
  value,
  setValue,
  placeholder,
  onChangeAction,
  onDeleteValue,
}) => {
  const inputRef = useRef(null);

  const onAction = (e) => {
    onChangeAction(e);
    /// state change while onAction is running will not affect datas
    /// resulting in datas from previous state to be used.
  };

  return (
    <div className="relative">
      <input
        type={"text"}
        id={nameTitle}
        name={nameTitle}
        placeholder={placeholder}
        onFocus={(e) => {
          let absolutePostion =
            inputRef.current?.getBoundingClientRect().top - 56;
          $("body, html").animate(
            {
              scrollTop: window.scrollY + absolutePostion,
            },
            300
          );
        }}
        onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
        onChange={onAction}
        value={value || ""}
        ref={inputRef}
        className="w-full h-16 py-4 pl-4 pr-10 text-black border rounded appearance-none border-gray_80 text-body2_500l focus:border-gray_160 focus:outline-none focus:ring-0"
      />
      <img
        onClick={() => {
          onDeleteValue();
          setValue(null);
        }}
        src="/images/mobile/icon/input_close.svg"
        alt="닫기 아이콘"
        className="absolute right-4 top-5"
      />
      <div className="absolute left-[-24px] z-[-1] h-screen w-screen bg-back_ground"></div>
    </div>
  );
};

export default InputCancerBox;
