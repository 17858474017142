import React, {useState}  from "react";
// import { Tooltip, Popover } from "@material-ui/core";
import TableHeader from "../common/TableHeader";
import symptomGuide from "./SymptomGuideTable";
import { useSelector } from "react-redux";
import SymptomBoxTd from "./SymptomBoxTd";
const DailySymptomBox = () => { //증상탭 빠져서 리팩토링 진행하지 x
  const [anchor, setAnchor] = useState(null);
  const {symptomList} = useSelector((state)=>state.management)
  const openPopover = (event) => {
    setAnchor(event.currentTarget);
  };
  const closePopover = () => {
    setAnchor(null)
  }

  const open = Boolean(anchor);
  const id = open ? 'symptomGuide-popover' : undefined;
  const toolTipClassName ="inline-block w-4 h-4 rounded-full border border-gray_80 "
  const headList = [
    "증상명",
    "발생빈도",
    "심각도",
    "일상생활지장 정도",
    "발생 유무",
    "양",
  ];
  const exampleHeadList = [
    "발생빈도",
    "심각도",
    "일상생활지장 정도",
    "발생 유무",
    "양",
  ];
  const exampleColors = ["#FA5E2C", "#FF914B", "#F5B21E", "#9DBE46", "#61BE82"];
  const exampleDatas = [
    {
      발생빈도: "거의 항상 있다",
      심각도: "매우 심하다",
      일상생활지장: "매우 많다",
      발생유무: "발생 O",
      양: "매우 많다",
    },
    {
      발생빈도: "자주 있다",
      심각도: "심하다",
      일상생활지장: "많다",
      발생유무: "-",
      양: "많다",
    },
    {
      발생빈도: "가끔 있다",
      심각도: "보통이다",
      일상생활지장: "다소 있다",
      발생유무: "-",
      양: "다소 있다",
    },
    {
      발생빈도: "드물게 있다",
      심각도: "약간 있다",
      일상생활지장: "약간 있다",
      발생유무: "-",
      양: "약간 있다",
    },
    {
      발생빈도: "전혀 없다",
      심각도: "전혀 없다",
      일상생활지장: "전혀 없다",
      발생유무: "발생 X",
      양: "전혀 없다",
    },
  ];



  return (
    // 3xl: 1920px 로 hide table 처리함
    <section className="space-y-4">
      <div className="flex" >
        <TableHeader title={"증상 기록"}  />
        <img 
          src="/images/mobile/icon/question.svg" 
          alt="해당없음 아이콘"  
          className="pl-2 block 3xl:hidden"
          aria-describedby={id}
          variant="contained"
          onClick={openPopover}
          />
        {/* <Popover 
          id={id}
          open={Boolean(anchor)} 
          anchorEl={anchor} 
          onClose={closePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          >
          {symptomGuide(exampleHeadList, exampleDatas, exampleColors)}
        </Popover> */}
        
      </div>
      <div className="grid grid-cols-3 gap-4">
        <table
          className="w-full table-auto 3xl:col-span-2 col-span-3 border-separate"
          cellSpacing={0}
        >
          <thead>
            <tr className="bg-gray_90">
              {headList?.map((head, idx) => (
                <th
                  key={idx}
                  className="border-t border-gray_80 p-4 text-sm font-semibold text-gray_10 first:border-l last:border-r first:rounded-tl-lg last:rounded-tr-lg"
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {symptomList !==undefined && symptomList?.map((head, idx) => (
            <tr
              key={idx}
              className={
              "text-center  " +
            (idx % 2 === 1
              ? "bg-gray_95 hover:bg-primary_10 "
              : "bg-gray_10 hover:bg-primary_10")
            }>
              <td
                className={
                "border-t border-l border-gray_80 p-4 text-sm font-semibold text-gray_10 " +
                (idx + 1 === symptomList?.length ? "border-y rounded-bl-lg" : "")
                }
              >
                {head.symptom}
              </td>
                <SymptomBoxTd 
                symptomList={symptomList}
                head ={head}
                toolTipClassName={toolTipClassName} 
                exampleColors={exampleColors}
                idx={idx}
                />

            </tr>
      
          ))}
          </tbody>
        </table>
        <div className="3xl:block hidden">{symptomGuide(exampleHeadList, exampleDatas, exampleColors)}</div>
      </div>
    </section>
  );
};

export default DailySymptomBox;
