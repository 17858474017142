import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getCancerType,
  onResetAll,
  onSavePrevTabTitle,
} from "../../../../features/mobile/infoSlice";
import HeadCont from "../../components/common/HeadCont";
import MenuBar from "../../components/common/MenuBar";
import InfoCard from "../../components/information/InfoCard";
import InfoSkeleton from "../../components/loading/InfoSkeleton";
import InfoLoading from "../../components/loading/InfoLoading";
import { useScrollDirection } from "../../hooks/useScrollDirection.js";
import { motion } from "framer-motion";
import { checkCancerType } from "../../components/logic";
import useInfo from "../../hooks/useInfo";

const variants = {
  open: {
    opacity: 1,
    y: 0,
  },
  closed: {
    opacity: 0,
    y: "-100%",
  },
};

const Information = () => {
  // constant variables
  const tabs = [
    // { id: 0, title: "전체" },
    { id: 5, title: "소식" },
    { id: 1, title: "국가 지원제도" },
    { id: 2, title: "항암치료 관리" },
    { id: 3, title: "암과 생활" },
    { id: 4, title: "암종별 정보" },
  ];

  // redux states
  const { loginInfo } = useSelector((state) => state.commonApp);
  // local states

  const [tabTitle, setTabTitle] = useState("소식");
  const [subTab, setSubTab] = useState(null);

  const {
    // variables
    postList,
    // popularPosts,
    loading,
    isMorePosting,
    bookmark,
    searchedValue,
    prevTabTitle,
    prevSubTabTitle,
    // functions
    onBringPostings,
    onFetchMorePosts,
    onClickPost,
    // state function
    setLoading,
    // etc.,
    dispatch,
    navigate,
  } = useInfo("main", loginInfo, tabTitle, subTab);

  const onSetTabTitle = (title) => {
    setTabTitle(title);
    setLoading(false);
    window.scrollTo(0, 0);
    onBringPostings({ category: title });
  };

  // reload function
  const handleReload = () => {
    setLoading(false);

    onBringPostings({ category: tabTitle }).catch((error) =>
      console.log(error)
    );
  };

  const onClickSearch = () => {
    window.scrollTo(0, 0);
    dispatch(onResetAll());
    dispatch(
      onSavePrevTabTitle({
        tabTitle: tabTitle,
        subTabTitle: subTab,
      })
    );
    navigate("/info/search");
  };

  const refs = tabs.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    // dispatch(getCancerType(loginInfo.userId))
    //   .unwrap()
    //   .then((res) => {
    //     if (res.ok) {
    //       setHasCancer(checkCancerType(res.cancerType));
    //       setCancerName(res.cancerName);
    //       setTabLoading(true);
    //     }
    //   });
    setLoading(false);
    if (postList?.length === 0 || searchedValue) {
      onBringPostings({
        tabTitle,
      })
        .then(() => setLoading(true))
        .catch((error) => console.log(error));
    }
    if (prevTabTitle) {
      setTabTitle(prevTabTitle);
      setLoading(true);
    } else {
      setTabTitle("소식");
      onBringPostings({
        tabTitle,
      })
        .then(() => {
          setLoading(true);
        })
        .catch((error) => console.log(error));
      // setSubTab(prevSubTabTitle);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refs[
      tabs.filter((value) => value.title === tabTitle)[0].id
    ]?.current?.scrollIntoView({ inline: "center", behavior: "smooth" });
  });

  return (
    <>
      <HeadCont
        rightIcon={"search"}
        transparent={"white"}
        title={"정보"}
        onRightAction={onClickSearch}
      />
      <div className="border-grey_60 fixed top-14 z-[11] w-full border-b bg-white">
        <ul className="flex mx-6 overflow-x-auto no-scrollbar flex-nowrap scroll-smooth">
          {tabs.map((tab) => (
            <li
              id={`info${tab.id}`}
              key={tab.id}
              ref={refs[tab.id]}
              className={`min-w-fit px-6 py-3 text-center text-lg font-bold text-gray_100 ${
                tabTitle === tab.title
                  ? "border-b-[4px] border-gray_180 text-gray_180"
                  : ""
              }`}
              onClick={() => onSetTabTitle(tab.title)}
            >
              {tab.title}
            </li>
          ))}
        </ul>
      </div>

      {loading ? (
        <>
          <section className={"z-[1] mb-24 mt-[127px]"}>
            {postList?.length > 0 ? (
              <>
                <InfiniteScroll
                  dataLength={postList.length}
                  next={onFetchMorePosts}
                  hasMore={isMorePosting}
                  loader={<InfoSkeleton />}
                  // pull down
                  refreshFunction={() => handleReload()}
                  pullDownToRefresh
                  pullDownToRefreshThreshold={100}
                  pullDownToRefreshContent={
                    <div className="h-11">
                      <div className="loader  mx-auto h-8 w-8 rounded-full border-[2px] border-t-[2px] border-gray-200 ease-linear " />
                    </div>
                  }
                  releaseToRefreshContent={
                    <div className="h-11">
                      <div className="loader  mx-auto h-8 w-8 rounded-full border-[2px] border-t-[2px] border-gray-200 ease-linear " />
                    </div>
                  }
                >
                  {
                    <>
                      <ul
                        className={`grid grid-cols-2 gap-3 overflow-y-auto ${
                          tabTitle !== "전체" && "pt-6"
                        } ${isMorePosting ? "" : "pb-3"} bg-white px-4`}
                      >
                        {postList?.map((list, idx) => (
                          <li key={idx}>
                            <InfoCard
                              id={list.id}
                              title={list.title}
                              category={list.category}
                              thumbnail={list.thumbnail}
                              marked={bookmark[idx]?.bookmark}
                              onOpenInfoPost={onClickPost}
                            />
                          </li>
                        ))}
                      </ul>
                    </>
                  }
                </InfiniteScroll>
              </>
            ) : tabTitle === "전체" && subTab === "My" ? (
              <div className="text-center mt-28 text-body3_700 text-gray_100 ">
                관련된 암 정보가 없습니다.
              </div>
            ) : null}
          </section>
        </>
      ) : (
        <div className={tabTitle === "전체" ? "mt-36" : "mt-28"}>
          <InfoLoading tabTitle={tabTitle} />
        </div>
      )}

      <MenuBar />
    </>
  );
};

export default Information;
