import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../common/api";
import toastCommonProps from "../../common/toast";

export const getAllAdmin = createAsyncThunk("admin/allAdmins", async () => {
  const res = await api.get("/admin");
  return res.data;
});
export const getSearchUser = createAsyncThunk(
  "admin/searchUser",
  async (params) => {
    const res = await api.get(`/user/searchForUserId/${params}`);
    if (res.data.ok) {
      return res.data;
    }
  }
);


export const deleteAdminInfo = createAsyncThunk(
  "admin/deleteAdmin",
  async (params) => {
    const res = await api.post("/admin/delete", params);
    if (res.data.ok) {
      toast(
        <p> {params.adminId} 삭제 완료</p>,
        toastCommonProps("top-right", "toast_alert",1000)
      );
      return res.data.user;
    } else console.log(res.data.error);
  }
);

export const getAllUsers = createAsyncThunk("getAllUsers", async () => {
  const res = await api.get("/user");
  return res.data;
});

//POST 관리자 등록
export const addAdmin = createAsyncThunk("addAdmin", async (params) => {
  const res = await api.post("/admin", params);
  return res;
});
export const editAdmin = createAsyncThunk("editAdmin", async (params) => {
  const res = await api.post("/admin/edit", params);
  return res;
});
// POST /admin/findID
export const adminFindId = createAsyncThunk("adminFindid", async (params) => {
  const res = await api.post("/admin/findID", params);
  return res;
});
// POST /admin/forFindAdminPass
export const adminFindPwd = createAsyncThunk("adminFindpwd", async (params) => {
  const res = await api.post("/admin/forFindAdminPass", params);
  return res;
});

// POST /admin/edit 어드민 수정
export const adminEditPwd = createAsyncThunk("adminEditPwd", async (params) => {
  const res = await api.post("/admin/edit", params);
  return res;
});


export const editPhoto = createAsyncThunk("editPhoto",async (params) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const formdata = new FormData();
    formdata.append("adminId", params.adminId);
    formdata.append("file", params.file[0])
    const res = await api.post("/admin/editPhoto", formdata, config);
    return res;
  });



const initialState = {
  i: 0,
  status: null,
  allAdmins: [],
  searchUser: [],
  allUsers: [],
};

const adminSlice = createSlice({
  name: "어드민",
  initialState,
  extraReducers: {
    [getAllUsers.fulfilled]: (state, action) => {
      state.status = "success";
      state.allUsers = action.payload.userList;
    },
  },
});

export const { onSetSection } = adminSlice.actions;

export default adminSlice.reducer;
