import React from "react";
import { useSelector } from "react-redux";
import TableHeader from "../common/TableHeader";
import AddedEatList from "./AddedEatList";
import DetailTable from "./DetailTable";

const EatDetailBox = () => {
  const { eatDayLists } = useSelector((state) => state.management);
  // console.log(eatDayLists, "eatDayLists");

  return (
    <div className="mt-4 space-y-4">
      <TableHeader title={"기록한 식사 리스트"} />
      <AddedEatList />
      <TableHeader title={"식사기록"} />
      <div className="flex gap-4 overflow-x-scroll">
        {eatDayLists.map((eatDayLists, idx) => (
          <DetailTable
            key={idx}
            idx={idx}
            eatDayLists={eatDayLists}
            option={"addedEat"}
          />
        ))}
      </div>
    </div>
  );
};

export default EatDetailBox;
