import React from "react";

const HelpModal = ({ modalOpen, closeModal, tapSelect }) => {
  //   const [isModalOpen, setIsModalOpen] = useState(false);
  //   const closeModal = () => {
  //     setIsModalOpen(false);
  //   };
  //   useEffect(() => {
  //     setIsModalOpen(modalOpen);
  //   }, [modalOpen]);

  const cancerTreatmentCenterModal = (
    <>
      {" "}
      <div
        role="presentation" //요소의 의미를 제거하고 순수한 디자인 요소로 취급하도록 하여, 스크린 리더 등의 보조 기술이 해당 요소에 대한 기본 행동을 무시하도록 함
        className="fixed inset-0 bg-black opacity-80"
        onClick={closeModal}
      ></div>
      <div className="relative flex  flex-col items-center justify-center gap-[6px] rounded-[21px] bg-white px-[12px] pb-[24px] pt-[10px]">
        <div className="flex w-full flex-col items-end gap-[2px]">
          <img
            src="/images/mobile/icon/close.svg"
            alt="닫기버튼"
            onClick={closeModal}
          />
        </div>
        <p className="flex items-center justify-center gap-1 text-body3_700 text-gray_180">
          수술
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="6"
            viewBox="0 0 6 6"
            fill="none"
          >
            <circle cx="3" cy="3" r="2.5" fill="#333333" />
          </svg>
          항암치료병원 산정 가이드
        </p>

        <div>
          <p className="text-center text-new1_700 text-gray_170">
            <span className="text-main">
              수술, 질병, 약제사용 등 병원의 의료서비스
            </span>{" "}
            를 <span className="text-main"> 의, 약학적 측면</span>과
            <span className="text-main"> 비용 효과적인 측면</span>
            에서 평가한 결과를 제공합니다.
            <br />
            <span className="text-main">
              {" "}
              전문 인력 구성, 진단적 평가, 수술, 항암 치료, 수술 사망률,
              입원일수
            </span>{" "}
            등을 종합적으로 평가하여
            <span className="text-main">
              {" "}
              95점 이상인 의료기관은 1등급으로 산정합니다.
              <br />
              <br />
            </span>
            대장암(7차), 위암(5차), 유방암(7차), 폐암(5차) 적정성 평가 결과 반영
          </p>
        </div>
      </div>
    </>
  );

  const nursingHomeModal = (
    <>
      {" "}
      <div
        role="presentation" //요소의 의미를 제거하고 순수한 디자인 요소로 취급하도록 하여, 스크린 리더 등의 보조 기술이 해당 요소에 대한 기본 행동을 무시하도록 함
        className="fixed inset-0 bg-black opacity-80"
        onClick={closeModal}
      ></div>
      <div className="relative flex  flex-col items-center justify-center gap-[6px] rounded-[21px] bg-white px-[12px] pb-[24px] pt-[10px]">
        <div className="flex w-full flex-col items-end gap-[2px]">
          <img
            src="/images/mobile/icon/close.svg"
            alt="닫기버튼"
            onClick={closeModal}
          />
        </div>
        <p className="flex items-center justify-center gap-1 text-body3_700 text-gray_180">
          요양병원산정 가이드
        </p>

        <div>
          <p className="text-center text-new1_700 text-gray_170">
            <span className="text-main">요양병원의 인력 구성</span>
            (의사 1인당 환자수, 간호사 1인당 환자수, 간호인력(간호사 +
            간호조무사) 1인당 환자수, 약사 재직일수),{" "}
            <span className="text-main"> 의료 서비스</span>
            (환자의 신체기능, 인지기능, 배설기능, 피부상태, 질환관리, 영양상태,
            건강상태)를 종합적으로 평가하여
            <span className="text-main">
              {" "}
              종합점수가 87점 이상인 경우 1등급
            </span>
            으로 산정합니다.
            <br />
            <br />
            2주기 3차 요양병원 적정성 평가 결과 반영
          </p>
        </div>
      </div>
    </>
  );

  return (
    <>
      {modalOpen && (
        <dialog className="fixed inset-0 z-10 px-11" open>
          {tapSelect === "cancerTreatmentCenter"
            ? cancerTreatmentCenterModal
            : nursingHomeModal}
        </dialog>
      )}
    </>
  );
};

export default HelpModal;
