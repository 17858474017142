import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProgressBar = ({ option, barProgress }) => {
  const location = useLocation().pathname;
  const { i, questionLists, questionListsStatus } = useSelector((state) => {
    if (location === "/question/health") {
      return state.questionHealth;
    } else if (location === "/question/food") {
      return state.questionFood;
    } else if (location === "/question/disease") {
      return state.questionDisease;
    } else if (location === "/question/foodadd") {
      return state.questionFoodAdd;
    }
  });
  const [progress, setProgress] = useState(
    Math.floor(((i + 1) / questionLists.length) * 100)
  );

  useEffect(() => {
    if (location === "/question/health") {
      if (questionListsStatus === "success") {
        setProgress(Math.floor(((i + 1) / questionLists.length) * 100));
      }
    } else if (location === "/question/food") {
      if (questionListsStatus === "success") {
        setProgress(Math.floor(((i + 1) / questionLists.length) * 100));
      }
    } else if (location === "/question/disease") {
      if (questionListsStatus === "success") {
        setProgress(Math.floor(((i + 1) / questionLists.length) * 100));
      }
    } else if (location === "/question/foodadd") {
      if (questionListsStatus === "success") {
        setProgress(Math.floor(((i + 1) / questionLists.length) * 100));
      }
    }
  }, [location, i, questionLists?.length, questionListsStatus]);

  return (
    <>
      {option === "header" ? (
        <div className="w-[200px]">
          <div className="flex h-2 overflow-hidden rounded-full bg-[#EBF1FF]">
            <div style={{ width: progress + "%" }} className="bg-main"></div>
          </div>
        </div>
      ) : option === "question" ? (
        <div className="mx-auto w-[267px]">
          <div className="flex h-2 overflow-hidden rounded-full bg-green_20">
            <div
              style={{ width: barProgress + "%" }}
              className="bg-green_120"
            ></div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ProgressBar;
