import React, { useEffect, useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";

//chip 선택은 추후..
// import ChipsCategory from "../../components/board/ChipsCategory";

import {
  getAllRepliesOfOnePosting,
  handleReplySliceArr,
  onClickCommentOpen,
  onSetCommTab,
} from "../../../../features/admin/communitySlice";
import Community from "./table/Community";
import CommonTabs from "../../components/common/CommonTabs";
import CustomerCenter from "./table/CustomerCenter";
import {
  toggleBoardModal,
  toggleCommuHide,
} from "../../../../features/admin/toggleSlice";
import RequestDB from "./table/RequestDB";
import Information from "./table/Information";
import CancerGPT from "./table/CancerGPT";
import { useNavigate } from "react-router-dom";
import Notice from "./table/Notice";
import BoardWriteModal from "./BoardWriteModal";
import BoardReadModal from "./BoardReadModal";

const Board = () => {
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const [writeModal, setWriteModal] = useState(false); //각 소식 게시글 클릭시 모달
  const { modalActive } = useSelector((state) => state.toggleAdmin);
  const dispatch = useDispatch();
  const tabName = useSelector((state) => state.commu.clickCommuTab);
  //소식상세 게시글 비공개처리
  const postingIdisHideToggle = useSelector(
    (state) => state.toggleAdmin.commuHide
  );
  const { page } = useSelector((state) => state.common);
  const pageReplyCount = 5;
  const navigate = useNavigate();

  //공지글 테이블 모달
  const showWriteModal = () => {
    setWriteModal(!writeModal);
  };
  const showEachNoticeModal = async (data, TABNAME) => {
    if (modalActive) {
      //hide EachModal
      dispatch(toggleBoardModal(false));
    } else {
      //show EachModal
      dispatch(toggleBoardModal(true));
      if (TABNAME === "소식" || TABNAME === "공지사항") {
        const param = {
          postingId: data.id,
          userId: data.writerUserId,
        };
        try {
          const res = await dispatch(getAllRepliesOfOnePosting(param)).unwrap();
          if (res.ok) {
            //페이지 오픈시 공개 비공개여부 초깃값 설정
            // console.log(res, "res.data");
            dispatch(toggleCommuHide(res.posting.isHide));
            if (
              res.totalReplies === undefined ||
              res.totalReplies?.length === 0
            ) {
              dispatch(handleReplySliceArr([]));
            } else {
              const slicePages = res.totalReplies.slice(
                (page - 1) * pageReplyCount,
                page * pageReplyCount
              );
              dispatch(handleReplySliceArr(slicePages));
            }
          } else {
            console.log(res);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  useEffect(() => {
    if (!loginInfo?.adminId) {
      navigate("/admin/signin");
    } else {
      dispatch(onClickCommentOpen(false)); //comment default
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AnimatePresence initial={false} mode="wait">
        {writeModal && <BoardWriteModal showWriteModal={showWriteModal} />}
        {modalActive && (
          <BoardReadModal
            postingIdisHideToggle={postingIdisHideToggle}
            showEachNoticeModal={showEachNoticeModal}
            showWriteModal={showWriteModal}
          />
        )}
      </AnimatePresence>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex flex-col items-start justify-start w-full gap-4 p-6 ">
          <div className="flex flex-col items-start justify-start w-full">
            <CommonTabs onSetCommTab={onSetCommTab} />
            {tabName === "소식" ? (
              <Community
                showWriteModal={showWriteModal}
                showEachNoticeModal={showEachNoticeModal}
              />
            ) : tabName === "1:1 이용문의" ? (
              <CustomerCenter />
            ) : tabName === "정보" ? (
              <Information />
            ) : tabName === "음식DB요청" ? (
              <RequestDB showEachNoticeModal={showEachNoticeModal} />
            ) : tabName === "CancerGPT" ? (
              <CancerGPT showEachNoticeModal={showEachNoticeModal} />
            ) : (
              <Notice
                showWriteModal={showWriteModal}
                showEachNoticeModal={showEachNoticeModal}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Board;
