import dayjs from "dayjs";
import React,{useEffect, useState} from "react";
import { useSelector } from "react-redux";
import Paging from "../common/Paging";

const WeeklySymptomTable = ({dayNo}) => {
  const getSymptomLists = useSelector((state)=>state.management.symptomDayList)
    const { page } = useSelector((state) => state.common);
  const headList = ["No.", "작성일", "증상 기록"];
  const [posts, setPosts] = useState([]);
  const [currentPosts, setCurrentPosts] = useState([]);
  const {pagePerList7} = useSelector((state)=>state.pagination)
  const indexOfLastPost = page * pagePerList7;
  const indexOfFirstPost = indexOfLastPost - pagePerList7;
  useEffect(() => {
    setPosts([...getSymptomLists].reverse());
    setCurrentPosts(posts.slice(indexOfFirstPost, indexOfLastPost));
  }, [indexOfFirstPost, indexOfLastPost, getSymptomLists, page]);
  //let EachPage = getSymptomLists.slice((page -1 )*pagePerList7, page*pagePerList7)
  const pagaNumerator = (idx, page) =>
  page === 1 ? idx + 1 : idx + 1 + 7 * (page - 1);
  const diaryReportList = (posts, idx, data) => (
    <>
      <td
        className={
          "border-b border-gray_80 border-l text-center py-4 text-caption_1 text-black " +
          (getSymptomLists?.length === 1 + idx ? "rounded-bl-lg" : "")
        }
      >
      {pagaNumerator(idx, page)}
      </td>
      <td className="border-b border-gray_80 text-center py-4 text-caption_1 text-black">
        {
        dayjs(data.day).format("YYYY-MM-DD (dd)")
        }
      </td>
      <td className={"border-b border-gray_80 border-r text-center py-4 text-caption_1 text-black w-2/3 word-break" +
          (posts?.length === 1 + idx ? " rounded-br-lg" : "")}
        >
        {data.data?.map((el,idx)=><span key={idx} className="inline-block">{el}{idx+1 !== data.data?.length && ","}</span>)}
        </td>
    </>
  );

  return (
    <>
      <table className="w-full table-auto mb-4 border-separate" cellSpacing={0}>
        <thead>
          <tr className="bg-gray_90">
            {headList?.map((head, idx) => (
              <th
                key={idx}
                className={"first:border-l last:border-r border-y border-gray_80 w-1/3 py-4 text-caption_1 font-semibold text-black first:rounded-tl-lg last:rounded-tr-lg "
                + (currentPosts === [] ? "first:rounded-bl-lg last:rounded-br-lg" : "")
                }>
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
        {dayNo === "최근 30일" || dayNo === "최근 90일"
              ? currentPosts?.map((list, idx) => (
                  <tr
                    key={idx}
                    className={
                      "px-6 py-3 " +
                      (idx % 2 === 1
                        ? "bg-gray_95 hover:bg-primary_10"
                        : "bg-gray_10 hover:bg-primary_10")
                    }
                  >
                    {diaryReportList(currentPosts, idx, list)}
                  </tr>
                ))
              : posts?.map((list, idx) => (
                  <tr
                    key={idx}
                    className={
                      "px-6 py-3 " +
                      (idx % 2 === 1
                        ? "bg-gray_95 hover:bg-primary_10"
                        : "bg-gray_10 hover:bg-primary_10")
                    }
                  >
                    {diaryReportList(posts, idx, list)}
                  </tr>
                ))}
        </tbody>
      </table>
      {(dayNo === "최근 30일" || dayNo === "최근 90일") && (
          <Paging
            totalSearchCount={posts?.length}
            pageUserCount={pagePerList7}
            pageRangeDisplayed={5}

          />
        )}
    </>
  );
};

export default WeeklySymptomTable;
