import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GPTAnswer from "../../components/cancerGpt/GPTAnswer";
import GPTMessage from "../../components/cancerGpt/GPTMessage";
import UserMessage from "../../components/cancerGpt/UserMessage";
import Btn from "../../components/common/Btn";
import HeadCont from "../../components/common/HeadCont";

const CancerGPT = () => {
  const { loginInfo } = useSelector((state) => state.commonApp);
  const textArea = useRef(null);
  const navigate = useNavigate();

  const [cancerQ, setCancerQ] = useState(null);
  const [questionAsked, setQuestionAsked] = useState({
    message: "",
    state: false,
  });
  const [count, setCount] = useState(null);

  const onChangeAction = (e) => {
    setCancerQ(e.target.value);
    setCount(e.target.value.length);
    e.target.style.height = "66px";
    let textAreaH = e.target.scrollHeight;
    e.target.style.height = `${textAreaH}px`;
  };

  const onClickButton = () => {
    setQuestionAsked({ message: cancerQ, state: true });
    setCancerQ(null);
    textArea.current.style.height = "66px";
  };

  return (
    <>
      <HeadCont
        leftIcon={"no icon"}
        rightIcon={"close"}
        title={"무엇이든 물어보세요"}
        onRightAction={() => navigate(-1)}
      />
      <section>
        <GPTMessage page={true} nav={"cancergpt"} />
        <div className="mx-10 mt-2 text-center text-caption2_400 text-gray_120">
          <div>{loginInfo.nickName}님의 힐리어리 데이터를 기반으로</div>
          <div>
            대화형 인공지능(Chat GPT)이 분석한 자료입니다. 제공되는 내용은
            참고사항일 뿐 이에 대한 법적 책임은 없음을 밝혀드립니다. 자세한
            내용은 전문가와 상담하시기 바랍니다.
          </div>
        </div>
        {questionAsked.state && (
          <UserMessage
            characterPhoto={loginInfo.characterPhoto}
            message={questionAsked.message}
          />
        )}
        {questionAsked.state && (
          <GPTAnswer question={questionAsked.message} cancerQ={cancerQ} />
        )}
      </section>

      <div className="maxW fixed bottom-0 z-10 flex w-full flex-col border-t border-gray_40 bg-white p-4">
        <div className="flex items-center justify-start ">
          <textarea
            ref={textArea}
            onChange={(e) => onChangeAction(e)}
            id={"question"}
            name={"question"}
            value={cancerQ || ""}
            maxLength={"60"}
            placeholder={"60자내로 작성해주세요."}
            className="no-scrollbar h-[66px] w-full resize-none appearance-none rounded border border-gray_80 p-4 text-body2_500l text-black focus:border-gray_160 focus:outline-none focus:ring-0"
          />
          <div className="mt-auto pl-2">
            {/* <Btn <---------------------- btn 컴포넌트 바꿈
                            option={5}
                            title={'질문'}
                            bgColor={'bg-main'}
                            textColor={'text-white'}
                            active={
                                !(cancerQ === '') && !(cancerQ === null)
                                    ? 'on'
                                    : 'ready'
                            }
                            onAction={onClickButton}
                        /> */}
            <Btn
              title={"질문"}
              bgColor={"bg-main"}
              textColor={"text-white"}
              active={!(cancerQ === "") && !(cancerQ === null) ? "on" : "ready"}
              onAction={onClickButton}
              innerClass={"w-full"}
              option="gpt"
            />
          </div>
        </div>
        {cancerQ && (
          <div className="ml-auto pr-[79px] pt-0.5">
            <span className="text-caption1_400 text-gray_160">{count}/60</span>
          </div>
        )}
        {/*Btn 기리와 Gap 기리 = 79*/}
      </div>
    </>
  );
};

export default CancerGPT;
