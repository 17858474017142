import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageArr } from "../../../../../features/admin/paginationSlice";
import "./pagination.css";

const PaginationLayout = ({
  totalPage,
  pagePerList,
  onClick,
  className,
  children,
}) => {
  const dispatch = useDispatch();
  const { page } = useSelector((state) => state.common);
  const { pagearr } = useSelector((state) => state.pagination);
  // const [movePage, setMovePage] = useState(page);
  const makeArray = (yourarray) => {
    return Array(yourarray)
      .fill()
      ?.map((v, i) => {
        return i + 1;
      });
  };
  const prevOnClick = () => {
    const prevLastIndex = pagearr[0] - 1;
    const prevallArr = makeArray(
      prevLastIndex === 0 //이전 클릭시 이전이 더이상 없을 경우
        ? totalPage > pagePerList
          ? pagePerList
          : totalPage //이전 클릭시 totalPage가 pagePerList보다 작은 경우 초기리스트 pagePerList기준 배열
        : prevLastIndex
    );
    prevLastIndex !== 0 && onClick(prevLastIndex - 9); // ho 이전 페이지가 존재할 경우에만 뒷 페이지 배열로 이동
    dispatch(
      setPageArr(
        prevLastIndex === 0
          ? prevallArr
          : prevallArr?.slice(prevLastIndex - pagePerList, prevLastIndex)
      )
    );
  };

  const nextOnClick = () => {
    let prevLastIndex = pagearr[pagearr?.length - 1];
    if (pagearr[pagearr?.length - 1] !== totalPage) {
      const allArr = makeArray(pagearr[pagePerList - 1] + pagePerList);
      onClick(pagearr[pagePerList - 1] + pagePerList - 9); // ho 페이지 이동시 배열에 첫 페이지로 자동 이동
      if (prevLastIndex + pagePerList > totalPage) {
        //딱 안떨어질때 (ex.11,12,,,19)
        dispatch(setPageArr(allArr?.slice(prevLastIndex, totalPage)));
      } else {
        //딱 떨어질때 (ex.10,20,30)
        dispatch(
          setPageArr(allArr?.slice(prevLastIndex, prevLastIndex + pagePerList))
        );
      }
    }
  };

  const prevEndOnClick = () => {
    onClick(1);
    if (totalPage < pagePerList) {
      const firstArr = makeArray(totalPage);
      dispatch(setPageArr(firstArr));
    } else {
      const firstArr = makeArray(pagePerList);
      dispatch(setPageArr(firstArr));
    }
  };

  const nextEndOnClick = () => {
    onClick(totalPage);
    const restCount = totalPage % pagePerList;
    if (pagearr[pagearr?.length - 1] !== totalPage) {
      const nextendall = makeArray(totalPage);
      dispatch(
        setPageArr(
          nextendall?.slice(
            restCount === 0 ? totalPage - pagePerList : totalPage - restCount,
            totalPage
          )
        )
      );
    }
  };

  const buttonText = (innerTxt, onClickFc) => {
    if (totalPage > 10) {
      return (
        <div className="pei_border pei_cursor" onClick={onClickFc}>
          {innerTxt}
        </div>
      );
    }
  };

  return (
    <div className="w-full">
      {children}
      <div className={`pei_flex + justify-center pt-6 ${className}`}>
        {buttonText("<<", prevEndOnClick)}
        {buttonText("<", prevOnClick)}
        {pagearr.length > 0 &&
          pagearr?.map((el, idx) => (
            <span
              key={idx}
              className={
                "pei_listwidth pei_cursor " +
                (page === el ? "pei_listwidth_active" : "")
              }
              onClick={() => {
                onClick(el);
              }}
            >
              {el}
            </span>
          ))}

        {buttonText(">", nextOnClick)}
        {buttonText(">>", nextEndOnClick)}
      </div>
    </div>
  );
};

export default PaginationLayout;
