import React from 'react'
import { useLocation } from 'react-router-dom';

const PhotoComp = ({onChangeFile,deleteImage,loadedImage,smallsize,option}) => {
  return (
    <>
      {/* 컨텐츠관리 - 증상가이드 files일 경우 앞쪽 */}
      {(smallsize && !(loadedImage === null|| loadedImage === undefined )) && loadedImage?.map((el,idx)=>(
      <div
        key={idx}
        className=
        {smallsize? "w-[48px] h-[48px] relative":"w-[80px] h-[80px] relative"}
        >
          <img src={el} alt={`image${idx}`} className="w-full h-full rounded"/>
          <img src="/images/admin/icon/close_circle.svg"
              alt="닫기 아이콘"
              className="w-[17px] h-[17px] absolute top-1 right-1 cursor-pointer"
              onClick={()=>deleteImage(el,idx)}
              />
        </div>
      ))}
      <div className=
      {smallsize?
        "border border-[#A39F9B] cursor-pointer w-[48px] h-[48px] rounded flex justify-center items-center":
        "border border-[#A39F9B] cursor-pointer w-[80px] h-[80px] rounded flex justify-center items-center"
      }
      >
        <label
          htmlFor="input_file"
          className="w-full h-full cursor-pointer  font-bold text-base flex items-center justify-center"
        >
          <img src={"/images/admin/icon/camera.svg"} alt="camera" />
        </label>
        {option ===1? //information detail에서는 단일 사진
          <input
          id="input_file"
          className="w-full hidden"
          type="file"
          label="Upload"
          name="image"
          accept="image/*"
          placeholder="첨부할 파일이 있는 경우 버튼을 클릭하여 등록하세요. (이미지는 최대 5개)"
          onChange={onChangeFile}
          />
        :
        <input
        id="input_file"
        className="w-full hidden"
        type="file"
        label="Upload"
        name="image"
        accept="image/*"
        multiple
        placeholder="첨부할 파일이 있는 경우 버튼을 클릭하여 등록하세요. (이미지는 최대 5개)"
        onChange={(e)=>onChangeFile(e)}
        />
        }

      </div>
      {/* 컨텐츠관리 - 증상가이드 files가 아닐경우 */}
      {!smallsize && !(loadedImage ===null|| loadedImage===undefined) && loadedImage?.map((el,idx)=>(
        <div
        key={idx}
        className=
        {smallsize? "w-[48px] h-[48px] relative":"w-[80px] h-[80px] relative"}
        >
          <img key={idx} src={el} alt={`image${idx}`} className="w-full h-full rounded"/>
          <img src="/images/admin/icon/close_circle.svg"
              alt="닫기 아이콘"
              className="w-[17px] h-[17px] absolute top-1 right-1 cursor-pointer"
              onClick={()=>deleteImage(el,idx)}
              />
        </div>
      ))}
    </>
  )
}

export default PhotoComp