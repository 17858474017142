import React from "react";
import { useSelector } from "react-redux";
import Parser from "html-react-parser";

const AnswerCard = ({ option, type, title, answer, onAction }) => {
  const { questionLists, i, gettingAnswers } = useSelector(
    (state) => state.questionFood
  );

  return (
    <>
      {option === 2 ? ( //2개이상 질문 페이지
        <li
          onClick={() => onAction(type, answer)}
          className={
            "rounded-2xl border p-4 text-center " +
            (gettingAnswers !== null && gettingAnswers[type] === answer
              ? "bg-main"
              : "border border-gray_200")
          }
        >
          <p
            className={
              gettingAnswers !== null && gettingAnswers[type] === answer
                ? "text-btn_700 text-white"
                : "text-btn_700 text-gray_200"
            }
          >
            {title ? Parser(title) : ""}
          </p>
        </li>
      ) : option === 3 ? ( //한 열에 3개 문항
        <li
          onClick={() => onAction(type, answer)}
          className={
            "rounded-2xl border px-1 py-4 text-center " +
            (gettingAnswers !== null && gettingAnswers[type] === answer
              ? "bg-main"
              : "border border-gray_200")
          }
        >
          <p
            className={
              gettingAnswers !== null && gettingAnswers[type] === answer
                ? "text-btn_700 text-white"
                : "text-btn_700 text-gray_200"
            }
          >
            {title ? Parser(title) : ""}
          </p>
        </li>
      ) : (
        <li
          onClick={() => onAction(type, answer)}
          className={
            "rounded-2xl border px-4 py-4 " +
            (type === "dietInconvenient" ? "text-left " : "text-center ") +
            (gettingAnswers !== null &&
            gettingAnswers[questionLists[i]?.saveUserColumn] === answer
              ? "bg-main"
              : "border border-gray_200")
          }
        >
          <p
            className={
              gettingAnswers !== null &&
              gettingAnswers[questionLists[i]?.saveUserColumn] === answer
                ? "text-btn_700 text-white"
                : "text-btn_700 text-gray_200"
            }
          >
            {title ? Parser(title) : ""}
          </p>
        </li>
      )}
    </>
  );
};

export default AnswerCard;
