const SearchBox = ({ value, onChange, onClick, placeholder,width,name }) => {
  return (
    <div
      className={`relative flex items-center gap-2 px-[20px] py-[12px] h-[48px] border border-gray_100 
      rounded-[4px]`}
      style={width === undefined?{width:"100%"}:
      typeof width === "number"?{width: `${width}px `}:{width:`${width}`}}
    >
      <input
        name={name}
        className="text-black bg-transparent focus:outline-none w-full h-full mr-6 text-body_2 "
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={(e) => (e.key === "Enter" ? onClick(e) : null)}
      />
      <img
        src="/images/admin/icon/finder.svg"
        alt="필터 아이콘"
        className="absolute w-4 h-4 right-4 top-0 bottom-0 my-auto cursor-pointer"
        onClick={onClick}
      />
    </div>
  );
};
export default SearchBox;
