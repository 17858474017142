import { mobileTypeFn } from '../mobile/components/logic';

const MoveAppStore = () => {

  if (mobileTypeFn() === 'iphone') {
    window.location.replace('https://apps.apple.com/kr/app/id1636424503', '_blank')
    setTimeout(() => {
      window.close()
    }, 1000);
  } else {
    window.location.replace(`market://details?id=com.herings.healiary&pli=1`, '_blank')
    setTimeout(() => {
      window.close()
    }, 1000);
  }
}
export default MoveAppStore;
