import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handlePageChange } from "../../../../../features/admin/commonSlice";
import {
  onClickGetEditCommunityPosting,
  onClickNewWrite,
  onSetToggleCommunity,
  searchManagementCommunity,
} from "../../../../../features/admin/communitySlice";
import { setPageArr } from "../../../../../features/admin/paginationSlice";
import { BGButtonCustom } from "../../../components/common/ButtonCustom";
import CommonOption from "../../../components/common/CommonOption";
import PaginationLayout from "../../../components/common/pagination/PaginationLayout";
import SearchBox from "../../../components/common/SearchBox";
import ToggleBtn from "../../../components/ToggleBtn";
import CommunityMemberList from "../CommunityMemberList";
import ContentLayout from "./ContentLayout";
const Community = ({ showEachNoticeModal, showWriteModal }) => {
  const navigate = useNavigate();
  const select_list = ["작성자", "제목", "내용"];
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const { toggleCommunity, totalPage } = useSelector((state) => state.commu);
  const { pagePerList } = useSelector((state) => state.pagination);
  const [searchinput, setSearchInput] = useState("");
  const onChangeInput = (e) => {
    setSearchInput(e.target.value);
  };
  const onClickSearch = useCallback(
    async (pageNum, toggleCommunity) => {
      dispatch(handlePageChange(pageNum));
      const commuSelectid = document.getElementById("commuSelect");
      let commuselectValue =
        commuSelectid.options[commuSelectid.selectedIndex].value;
      let params = {
        page: pageNum,
        countPerPage: pagePerList,
        writerUserId:
          commuselectValue === "작성자" && searchinput !== ""
            ? searchinput
            : null,
        title:
          commuselectValue === "제목" && searchinput !== ""
            ? searchinput
            : null,
        content:
          commuselectValue === "내용" && searchinput !== ""
            ? searchinput
            : null,
        isReport: toggleCommunity,
      };
      const res = await dispatch(searchManagementCommunity(params)).unwrap();
      if (res.ok) {
        const defaultarr = Array(res.totalPage)
          .fill()
          ?.map((v, i) => {
            return i + 1;
          });
        if (pagePerList >= pageNum) {
          //1~10사이일경우 pagePerList 10
          dispatch(setPageArr(defaultarr.slice(0, pagePerList)));
        } else {
          //11~
          dispatch(
            setPageArr(
              defaultarr.slice(
                (Math.ceil(pageNum / pagePerList) - 1) * pagePerList,
                Math.ceil(pageNum / pagePerList) * pagePerList
              )
            )
          );
        }
      }
    },
    [dispatch, pagePerList, searchinput, setPageArr]
  );

  const handlePageFn = (clickPage, toggleCommunity) => {
    dispatch(handlePageChange(clickPage));
    onClickSearch(clickPage, toggleCommunity);
  };

  useEffect(() => {
    if (loginInfo?.adminId) {
      onClickSearch(1, false);
      dispatch(handlePageChange(1));
    } else {
      navigate("/admin/signin");
    }
  }, [dispatch, loginInfo.adminId]);

  const onToggle = () => {
    setSearchInput("");
    dispatch(onSetToggleCommunity(!toggleCommunity));
    onClickSearch(1, !toggleCommunity);
  };
  return (
    <ContentLayout>
      <div className="flex w-full items-center justify-between pb-6">
        <BGButtonCustom
          onClick={() => {
            showWriteModal();
            dispatch(onClickNewWrite(true));
          }}
          content={"공지글 작성하기"}
          bgcolor={"bg-orange_100"}
          textcolor={"text-gray_10"}
          textsize={"text-caption1_600"}
          xpadding={"12px"}
        />
        <div className="flex h-[48px] flex-row items-start items-center gap-[24px] ">
          <ToggleBtn
            isOn={toggleCommunity}
            onToggle={onToggle}
            title={"신고된 게시글만 보기"}
          />
          <div className="flex gap-x-[8px]">
            <CommonOption
              lists={select_list}
              ver={1}
              selectid={"commuSelect"}
              mywidth={114}
            />
            <SearchBox
              value={searchinput}
              onChange={onChangeInput}
              onClick={() => onClickSearch(1, toggleCommunity)}
              placeholder={"검색내용을 입력하세요."}
              width={296}
            />
          </div>
        </div>
      </div>
      <div className="w-full gap-[16px]">
        <PaginationLayout
          totalPage={totalPage}
          pagePerList={pagePerList}
          onClick={(clickpage) => handlePageFn(clickpage, toggleCommunity)}
        >
          <CommunityMemberList showEachNoticeModal={showEachNoticeModal} />
        </PaginationLayout>
      </div>
    </ContentLayout>
  );
};

export default Community;
