import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { handlePageChange } from '../../../features/admin/commonSlice'
import { searchHospital } from '../../../features/admin/hospitalSlice'
import Navbar from '../components/common/Navbar'
import Sidebar from '../components/common/Sidebar'
import MainMemberCard from '../components/main/MainMemberCard'
import HospitalTable from './Hospital/table/HospitalTable'

const Hospital = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [hospitalList, setHospitalList] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [newCount, setNewCount] = useState(0);
  const { loginInfo } = useSelector((state) => state.adminLogin)

  useEffect(() => {
    if (loginInfo?.adminId) {
      const fetchHospitalData = async () => {
        const result = await dispatch(searchHospital()).unwrap()
        try {
          if (result.data.ok) {
            setHospitalList(result.data.hospitalList)
            setTotalCount(result.data.totalCount)
            setNewCount(result.data.newCount)
          } else {
            console.log(result)
          }
        } catch (error) {
          console.log(error)
        }
      }
      fetchHospitalData()
      dispatch(handlePageChange(1))
    } else {
      navigate("/admin/signin");
    }
  }, []);
  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        {loginInfo?.authority === "00" || loginInfo?.authority === "01" ?
          <div className="flex flex-col justify-start items-start p-[24px] gap-[16px] w-auto min-w-min h-full">
            <div className='grid grid-cols-5 w-full'>
              <MainMemberCard
                mainText={"병원"}
                subText1={"전체 병원 수"}
                subText2={"신규 병원 수"}
                totalUserCount={totalCount}
                newUserCount={newCount}
              />
            </div>
            <HospitalTable
              dataList={hospitalList}
            />
          </div>
          : ""}
      </div>
    </>
  )
}

export default Hospital