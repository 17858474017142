import { useState } from "react";

const useGAEvent = (eventDetails) => {
  const [trackedEvents, setTrackedEvents] = useState([]);

  const sendSingleEvent = (detail) => {
    if (!trackedEvents.includes(detail.value)) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(detail);
      // console.log("added >>> ", detail);
      setTrackedEvents([...trackedEvents, detail.value]);
    }
  };
  const sendEvent = (detail) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(detail);
    // console.log("added >>> ", detail);
  };

  return { sendSingleEvent, sendEvent };
};

export default useGAEvent;
