import React from "react";
import { PieChart, Pie, ResponsiveContainer } from "recharts";

const NutritionGraph = ({ datas }) => {
  const { carbohydratesG, proteinG, fatG } = datas || "";
  const percentDatas = [
    {
      name: "탄수화물",
      // value: isNaN(carbohydratesG) ? "" : carbohydratesG,
      value:
        carbohydratesG >= 0 && fatG >= 0 && proteinG >= 0
          ? Math.round(
              ((carbohydratesG * 4) /
                (carbohydratesG * 4 + proteinG * 4 + fatG * 9)) *
                100
            )
          : "",
      fill: "#FADA69",
    },
    {
      name: "단백질",
      // value: isNaN(proteinG) ? "" : proteinG,
      value:
        carbohydratesG >= 0 && fatG >= 0 && proteinG >= 0
          ? Math.round(
              ((proteinG * 4) /
                (carbohydratesG * 4 + proteinG * 4 + fatG * 9)) *
                100
            )
          : "",
      fill: "#82C6F6",
    },
    {
      name: "지방",
      // value: isNaN(fatG) ? "" : fatG,
      value:
        carbohydratesG >= 0 && fatG >= 0 && proteinG >= 0
          ? 100 -
            (Math.round(
              ((carbohydratesG * 4) /
                (carbohydratesG * 4 + proteinG * 4 + fatG * 9)) *
                100
            ) +
              Math.round(
                ((proteinG * 4) /
                  (carbohydratesG * 4 + proteinG * 4 + fatG * 9)) *
                  100
              ))
          : "",
      fill: "#9E87FB",
    },
    // 식사기록에서 아무것도 기록하지 않았을 때의  그래프
    {
      name: "",
      value: carbohydratesG + fatG + proteinG === 0 ? 20 : 0,
      fill: "#8B8B8B",
    },
    {
      name: "",
      value: carbohydratesG + fatG + proteinG === 0 ? 30 : 0,
      fill: "#C1C1C1",
    },
    {
      name: "",
      value: carbohydratesG + fatG + proteinG === 0 ? 50 : 0,
      fill: "#6F6F6F",
    },
  ];

  return (
    // prettier-ignore
    <div className='flex gap-[28px] bg-gray_20 rounded-full mt-4 px-4 py-3'>
            <div className='flex flex-col gap-1 justify-center items-center w-[64px] h-[64px] my-auto'>
                <ResponsiveContainer width="100%" height="100%" minHeight="0" minWidth="0">
                    <PieChart>
                        <Pie data={percentDatas} dataKey="value" cx="50%" cy="50%" innerRadius={10} outerRadius={30} />
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div className='flex flex-col justify-center rounded-full'>
                <div className='flex items-center gap-3'>
                    <span className='w-3 h-3 bg-[#FADA69] rounded-full'></span>
                    <h2 className='text-caption1_400 text-gray_160'>탄수화물 {(carbohydratesG / (carbohydratesG + fatG + proteinG)) ? `${Math.round(carbohydratesG*4 / (carbohydratesG*4 + proteinG*4 + fatG*9) * 100)}%` : '0'}</h2>
                    {/* <h2 className='text-caption1_400 text-gray_160'>탄수화물 {(carbohydratesG / (carbohydratesG + fatG + proteinG)) ? `${Math.round(carbohydratesG / (carbohydratesG + proteinG + fatG) * 100)}%` : '0'}</h2> */}
                </div>
                <div className='flex items-center gap-3'>
                    <span className='w-3 h-3 bg-[#82C6F6] rounded-full'></span>
                    <h2 className='text-caption1_400 text-gray_160'>단백질 {(proteinG / (carbohydratesG + fatG + proteinG)) ? `${Math.round(proteinG*4 / (carbohydratesG*4 + proteinG*4 + fatG*9) * 100)}%` : '0'}</h2>
                    {/* <h2 className='text-caption1_400 text-gray_160'>단백질 {(proteinG / (carbohydratesG + fatG + proteinG)) ? `${Math.round(proteinG / (carbohydratesG + proteinG + fatG) * 100)}%` : '0'}</h2> */}
                </div>
                <div className='flex items-center gap-3'>
                    <span className='w-3 h-3 bg-[#9E87FB] rounded-full'></span>
                    <h2 className='text-caption1_400 text-gray_160'>지방 {carbohydratesG && fatG && proteinG ? `${100 - (Math.round(carbohydratesG*4 / (carbohydratesG*4 + proteinG*4 + fatG*9) * 100) + Math.round(proteinG*4 / (carbohydratesG*4 + proteinG*4 + fatG*9) * 100))}%` : '0'}</h2>
                    {/* <h2 className='text-caption1_400 text-gray_160'>지방 {carbohydratesG && fatG && proteinG ? `${100 - (Math.round(carbohydratesG / (carbohydratesG + proteinG + fatG) * 100) + Math.round(proteinG / (carbohydratesG + proteinG + fatG) * 100))}%` : '0'}</h2> */}
                </div>
            </div>
        </div>
  );
};

export default NutritionGraph;
