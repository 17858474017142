import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";
import { toast } from "react-toastify";
import toastCommonProps from "../../common/toast";

export const fetchQuestion = createAsyncThunk(
  "questionFood/fetchQuestion",
  async () => {
    const res = await api.get(`/base/getDietLifeQuestion`);
    return res.data;
  }
);

export const saveAnswer = createAsyncThunk(
  "questionFood/saveAnswer",
  async (params) => {
    const res = await api.post(`/user/inputUserDietLife`, params);
    return res.data;
  }
);

export const fetchAnswerList = createAsyncThunk(
  "questionFood/fetchAnswerList",
  async (params) => {
    const res = await api.get(`/user/searchForUserId/${params}`);
    if (res.data.ok) {
      return res.data.user.userDietLife;
    }
  }
);

// export const fetchSurveyResult = createAsyncThunk("questionFood/fetchSurveyResult", 나중에 삭제
//     async (params) => {
//         const res = await api.get(`/user/getUserHealthQuestionResult/${params}`);
//         return res.data
//     })

export const fetchQuestionResult = createAsyncThunk(
  "questionFood/fetchQuestionResult",
  async (params) => {
    const res = await api.get(`/user/getUserDietLifeQuestionResult/${params}`);
    return res.data;
  }
);

const initialState = {
  questionLists: [],
  i: 0,
  prevI: [0],
  gettingAnswers: {},
  questionListsStatus: null,
  // resultLists: null, //나중에 삭제
  questionResultLists: null,
};

const questionFoodSlice = createSlice({
  name: "식생활 설문",
  initialState,
  reducers: {
    increment: (state) => {
      state.i += 1;
      state.prevI = [...state.prevI, state.i];
    },
    decrement: (state) => {
      if (state.i <= 0) {
        toast(
          <p>1번 문항입니다</p>,
          toastCommonProps("bottom-center", "toast_alert")
        );
      } else {
        state.i -= 1;
        state.prevI = [...state.prevI, state.i];
      }
    },
    incrementByAmount: (state, action) => {
      state.i += action.payload;
      state.prevI = [...state.prevI, state.i];
    },
    goBackByAmount: (state) => {
      const filter = state.prevI.filter(
        (el, idx) => idx < state.prevI.length - 1
      ); //마지막 번호 삭제
      state.prevI = [...filter];
      state.i = state.prevI[state.prevI.length - 1];
    },
    resetI: (state) => {
      state.i = 0;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchQuestion.pending, (state) => {
      state.questionListsStatus = "pending";
    });
    builder.addCase(fetchQuestion.fulfilled, (state, action) => {
      state.questionListsStatus = "success";
      state.questionLists = action.payload.questionList;
      state.otherCancerLists = action.payload.otherCancerQuestion?.answerList;
    });
    builder.addCase(fetchQuestion.rejected, (state) => {
      state.questionListsStatus = "rejected";
    });
    builder.addCase(fetchAnswerList.fulfilled, (state, action) => {
      state.gettingAnswers = action.payload;
    });
    // builder.addCase(fetchSurveyResult.fulfilled, (state, action) => {
    //   state.resultLists = action.payload;
    // }); //나중에 삭제
    builder.addCase(fetchQuestionResult.fulfilled, (state, action) => {
      state.questionResultLists = action.payload;
    });
  },
  // {
  //   [fetchQuestion.pending]: (state) => {
  //     state.questionListsStatus = "pending";
  //   },
  //   [fetchQuestion.fulfilled]: (state, action) => {
  //     state.questionListsStatus = "success";
  //     state.questionLists = action.payload.questionList;
  //     state.otherCancerLists = action.payload.otherCancerQuestion?.answerList;
  //   },
  //   [fetchQuestion.rejected]: (state) => {
  //     state.questionListsStatus = "rejected";
  //   },
  //   [fetchAnswerList.fulfilled]: (state, action) => {
  //     state.gettingAnswers = action.payload;
  //   },
  //   // [fetchSurveyResult.fulfilled]: (state, action) => { state.resultLists = action.payload; }, //나중에 삭제
  //   [fetchQuestionResult.fulfilled]: (state, action) => {
  //     state.questionResultLists = action.payload;
  //   },
  // },
});

export const {
  increment,
  decrement,
  incrementByAmount,
  goBackByAmount,
  resetI,
} = questionFoodSlice.actions;

export default questionFoodSlice.reducer;
