import React, { useEffect, useState } from "react";
import HeadCont from "../../components/common/HeadCont";
import { useNavigate, useLocation } from "react-router-dom";
import SearchHospital from "./searchHospital";
import { useDispatch, useSelector } from "react-redux";
import { searchHospitalInfo } from "../../../../features/mobile/infoSlice";
import { mobileTypeFn } from "../../components/logic";
import ListHospitalDropdownBtn from "./listHospitalDropdownBtn";

const ListHospital = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // 병원 정보 검색 리스트
  const { hospitalInfoList } = useSelector((state) => state.info);

  const targetCancer = location.state?.targetCancer;
  const targetArea = location.state?.targetArea;
  const tapSelect = location.state?.tapSelect;
  const hospitalCancerList = location.state?.hospitalCancerList;
  const hospitalAreaList = location.state?.hospitalAreaList;
  const nursingHospitalAreaList = location.state?.nursingHospitalAreaList;

  const [inputHospital, setInputHospital] = useState("");
  const [cancerName, setCancerName] = useState(targetCancer);
  const [areaName, setAreaName] = useState(targetArea);

  //   병원이름 검색창 입력값에 의한 실시간 필터링
  useEffect(() => {
    if (inputHospital !== "") {
      searchInfoHospital();
    } else if (inputHospital === "") {
      searchInfoHospital();
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputHospital, areaName, cancerName]);

  const searchInfoHospital = async () => {
    // 병원 정보 검색
    let params = {
      name: inputHospital,
      area: areaName,
      cancer: cancerName,
      type: tapSelect === "cancerTreatmentCenter" ? 1 : 2,
    };
    await dispatch(searchHospitalInfo(params))
      .unwrap()
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  // 암종, 지역 변경 버튼
  //   const filterBtn = (value) => {
  //     return (
  //       <button className="flex h-11 w-[120px] shrink-0 items-center justify-between gap-1 rounded-[44px] border-[1.5px] border-orange_120 p-[10px] ">
  //         <span className="text-caption2_700 text-orange_120">{value}</span>
  //         <svg
  //           xmlns="http://www.w3.org/2000/svg"
  //           width="20"
  //           height="20"
  //           viewBox="0 0 20 20"
  //           fill="none"
  //         >
  //           <path
  //             fillRule="evenodd"
  //             clipRule="evenodd"
  //             d="M3.23017 6.20938C3.52875 5.92228 4.00353 5.93159 4.29063 6.23017L10 12.1679L15.7094 6.23017C15.9965 5.93159 16.4713 5.92228 16.7698 6.20938C17.0684 6.49647 17.0777 6.97125 16.7906 7.26983L10.5406 13.7698C10.3992 13.9169 10.204 14 10 14C9.79599 14 9.60078 13.9169 9.45938 13.7698L3.20938 7.26983C2.92228 6.97125 2.93159 6.49647 3.23017 6.20938Z"
  //             fill="#FD7E14"
  //           />
  //         </svg>
  //       </button>
  //     );
  //   };

  // 암 1등급 여부 반복 ui
  const cancerRating = (cancer) => {
    //  ho 현재 위치부터 마지막까지 true값이 하나라도 있는지 확인
    const hasNextValidValue = (index) => {
      for (let j = index + 1; j < cancer.length; j++) {
        if (cancer[j].value) {
          return true;
        }
      }
      return false;
    };

    // hasNextValidValue로 svg 이미지 출력 조절
    return cancer.map((list, i) =>
      list.value ? (
        <React.Fragment key={i}>
          <p className="text-center text-new3_700 text-gray_180">
            {list.name} <span className="text-new3_700 text-main">1등급</span>
          </p>
          {i < cancer.length - 1 && hasNextValidValue(i) ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="18"
              viewBox="0 0 2 18"
              fill="none"
            >
              <path d="M1 1V17" stroke="#A5A5A5" strokeLinecap="round" />
            </svg>
          ) : null}
        </React.Fragment>
      ) : null
    );
  };
  // 병원 url 이동
  const mobileType = mobileTypeFn(); // Get the mobile type outside the function
  const handleSlideClick = async (url) => {
    // ho 웹, 모바일 환경 체크
    if (mobileType !== "pc") {
      window.flutter_inappwebview.callHandler("linkUrl", url);
    } else {
      // Web
      window.open(url, "_blank");
    }
  };

  return (
    <div className="bg-white">
      <section>
        <HeadCont
          title={"병원 정보 찾기"}
          transparent={"white"}
          topline={true}
          onLeftAction={() => navigate(-1)}
        />
      </section>

      <section className="mt-[57px]">
        <SearchHospital setInputHospital={setInputHospital} />
        <div className="flex items-start gap-2 p-4 border-b border-gray_60">
          {/* {targetCancer ? filterBtn(targetCancer) : null}
          {filterBtn(targetArea)} */}
          {tapSelect === "cancerTreatmentCenter" ? (
            <ListHospitalDropdownBtn
              options={hospitalCancerList}
              setTarget={targetCancer}
              changeTarget={setCancerName}
            />
          ) : null}

          <ListHospitalDropdownBtn
            options={
              tapSelect === "cancerTreatmentCenter"
                ? hospitalAreaList
                : nursingHospitalAreaList
            }
            setTarget={targetArea}
            changeTarget={setAreaName}
          />
        </div>
      </section>

      <section>
        {hospitalInfoList?.map((list, i) => {
          return (
            <div
              key={list.id}
              className="flex flex-col items-start justify-center gap-[6px] border-b px-4 py-3"
            >
              <div className="flex flex-col items-start w-full">
                <div className="flex items-center justify-between w-full">
                  <p className="flex flex-col justify-center text-center shrink-0 text-new2_600 text-gray_120">
                    {list.codeName}
                  </p>
                  <div className="flex items-center justify-center gap-2 px-3 py-1">
                    <a href={"tel:" + list.tel}>
                      <img
                        src="/images/mobile/icon/call.svg"
                        alt="통화 아이콘"
                        onClick={() => {
                          window.dataLayer.push({
                            event: "hospital_phone",
                            value: list.name,
                          });
                        }}
                      />
                    </a>
                    {list.url !== "" ? (
                      <img
                        src="/images/mobile/icon/home.svg"
                        alt="홈 아이콘"
                        onClick={() => {
                          handleSlideClick(list.url);
                          window.dataLayer.push({
                            event: "hospital_web",
                            value: list.name,
                          });
                        }}
                      />
                    ) : null}
                  </div>
                </div>
                <p className="text-center text-caption1_700">{list.name}</p>
                <p className="text-left text-caption2_700 text-gray_120">
                  {list.address.indexOf("()") === -1
                    ? list.address
                    : list.address.slice(0, list.address.indexOf("()"))}
                </p>
              </div>

              <div className="flex flex-col items-start w-full">
                {list.type === 1 ? (
                  <p className="text-right text-new2_600 text-gray_180">
                    평가 우수 암 진료 분야
                  </p>
                ) : list.type === 2 && list.twoTimes ? (
                  <p className="text-right text-new2_600 text-gray_180">
                    <span className="text-right text-main">2회 연속</span> 평가
                    우수 요양병원
                  </p>
                ) : null}

                <div className="flex w-full items-center justify-around gap-[6px] ">
                  {cancerRating([
                    { name: "위암", value: list.gastricCancer },
                    { name: "폐암", value: list.lungCancer },
                    { name: "대장암", value: list.colonCancer },
                    { name: "유방암", value: list.breastCancer },
                  ])}
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default ListHospital;
