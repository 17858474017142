import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import {
  fetchAnswerList,
  increment,
  resetI,
  searchHospital,
  searchMedicine,
} from "../../../../features/mobile/questionDiseaseSlice";
import {
  changeStateArray,
  saveAnswer,
} from "../../../../features/mobile/questionHealthSlice";
import BtnForQuestion from "../common/BtnForQuestion";
import InputSelectBox from "../common/InputSelectBox";
import TitleBox from "../common/TitleBox";
import AnswerCard from "../question_disease/AnswerCard";
import AnswerCard2 from "../question_disease/AnswerCard2";
import { searchAndHighlight } from "../logic";

const QuestionForm = ({ onLeftAction }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const {
    questionListsStatus,
    questionLists,
    i,
    gettingAnswers,
    // hospitalLists,
    // medicineList,
  } = useSelector((state) => state.questionDisease);
  const [hospitalLists, setHospitalLists] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [hospital, setHospital] = useState(null);
  const [drugName, setDrugName] = useState(null);
  const [drugListLoading, setDrugListLoading] = useState(false);
  const [showFoundDrugList, setShowFoundDrugList] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [resetPlaceHolder, setResetPlaceHolder] = useState(false);

  const onCheckOne = (one) => {
    if (questionLists[i]?.saveUserColumn === "chronicDisease") {
      //만성질환
      if (one === "CD999") {
        setCheckedItems([one]);
      } else {
        if (checkedItems.includes(one)) {
          const arr = checkedItems.filter((el) => el !== one);
          setCheckedItems([...arr]);
        } else {
          if (checkedItems.includes("CD999")) {
            const arr = checkedItems.filter((el) => el !== "CD999");
            setCheckedItems([...arr, one]);
          } else setCheckedItems([...checkedItems, one]);
        }
      }
    } else if (questionLists[i]?.saveUserColumn === "medicineDrugs") {
      //복약
      setCheckedItems([one]);
      setDrugName(null);
      setShowFoundDrugList(false);
      setMedicineList([]);
      dispatch(saveAnswer({ userId: loginInfo.userId, medicineDrugs: [one] }))
        .unwrap()
        .then((res) => {
          if (res.ok) dispatch(fetchAnswerList(loginInfo.userId));
          else
            toast(
              <p>{res.error}</p>,
              toastCommonProps("bottom-center", "toast_alert", 1000)
            );
        })
        .then((error) => console.log(error));
    }
  };

  const onChangeHospitalName = (e) => {
    setHospital(e.target.value);
    dispatch(searchHospital({ name: e.target.value }))
      .unwrap()
      .then((res) => {
        if (res.ok && res.hospitalList !== undefined) {
          let tempList = res.hospitalList.filter((value) =>
            value?.name?.includes(e.target.value)
          );
          if (tempList.length === 0) setHospitalLists([]);
          else setHospitalLists(tempList);
        }
      });
  };
  const onChangeDrugName = (e) => {
    // When input changes, delete the existing list
    setMedicineList([]);
    setShowFoundDrugList(false);

    setDrugName(e.target.value);

    // let tempList = medicineList.filter((value) =>
    //   value?.name?.includes(e.target.value)
    // );
    // if (tempList.length === 0) setMedicineList([]);
    // else setMedicineList(tempList);
  };

  const onSaveAnswer = useCallback(
    (type, answer) => {
      let params = { userId: loginInfo.userId, [type]: answer };
      dispatch(saveAnswer(params))
        .unwrap()
        .then((res) => {
          if (res.ok) dispatch(fetchAnswerList(loginInfo.userId));
          else
            toast(
              <p>{res.error}</p>,
              toastCommonProps("bottom-center", "toast_alert", 1000)
            );
        });
      if (type === "hospitalName") {
        //병원검색
        // setHospital(null);
      }
    },
    [dispatch, loginInfo.userId]
  );

  const onSaveAnswerAndNext = useCallback(
    (type, answer) => {
      let params = { userId: loginInfo.userId, [type]: answer };
      dispatch(saveAnswer(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            if (type !== "medicineDrugs")
              dispatch(fetchAnswerList(loginInfo.userId));
            dispatch(increment());
            setCheckedItems([]);
          } else
            toast(
              <p>{res.error}</p>,
              toastCommonProps("bottom-center", "toast_alert", 1000)
            );
        })
        .catch((err) => console.log(err));
    },
    [dispatch, loginInfo.userId]
  );

  const onSaveAnswerAndMakeList = (type, answer) => {
    if (type === "medicineDrugs") {
      /// there exists checkedItems filled with 없음 or 몰름
      /// add onto checked items
      /// delete medincineDrugs from backend
      if (
        checkedItems?.filter((el) => el === "TM999" || el === "TM997")?.length >
        0
      ) {
        setCheckedItems((checkedItems) => {
          return [
            ...checkedItems?.filter(
              (ar) => ar !== answer && ar !== "TM999" && ar !== "TM997"
            ),
            answer,
          ];
        });
        let params = { userId: loginInfo.userId, medicineDrugs: [] };
        dispatch(saveAnswer(params))
          .unwrap()
          .then((res) => {
            if (res.ok) {
              dispatch(fetchAnswerList(loginInfo.userId));
              //if (checkedItems?.length > 0) setCheckedItems([])
            } else
              toast(
                <p>{res.error}</p>,
                toastCommonProps("bottom-center", "toast_alert", 1000)
              );
          })
          .catch((err) => console.log(err));
      } else {
        /// if no checked list exists, add onto checked items.
        setCheckedItems((checkedItems) => {
          return [...checkedItems?.filter((ar) => ar !== answer), answer];
        });
      }
      setDrugName(null);
      setMedicineList([]);
      setShowFoundDrugList(false);
    }
  };

  const onDeleteMedicineList = (el) => {
    const filtered = checkedItems?.filter((thiselem) => thiselem !== el);
    let params = { userId: loginInfo.userId, medicineDrugs: filtered };
    dispatch(saveAnswer(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          dispatch(fetchAnswerList(loginInfo.userId));
          setCheckedItems(filtered);
        } else
          toast(
            <p>{res.error}</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
      })
      .catch((err) => console.log(err));
  };
  const onMoveToNext = () => {
    //다음버튼
    if (questionLists[i]?.saveUserColumn === "chronicDisease") {
      onSaveAnswerAndNext("chronicDisease", checkedItems);
    } else if (questionLists[i].saveUserColumn === "medicineDrugs") {
      onSaveAnswerAndNext("medicineDrugs", checkedItems);
    } else dispatch(increment());
  };
  const onClickScrollBy = () =>
    window.scrollBy({ top: 160, behavior: "smooth" });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (questionLists[i]?.saveUserColumn === "hospitalName") {
      if (gettingAnswers[questionLists[i]?.saveUserColumn] !== null) {
        if (gettingAnswers[questionLists[i]?.saveUserColumn]?.length > 0) {
          document.body.scrollTop = document.body.scrollHeight;
        }
      }
    } else if (questionLists[i]?.saveUserColumn === "chronicDisease") {
      if (gettingAnswers[questionLists[i]?.saveUserColumn] !== null) {
        if (gettingAnswers[questionLists[i]?.saveUserColumn]?.length > 0) {
          setCheckedItems([
            ...gettingAnswers[questionLists[i]?.saveUserColumn],
          ]);
        }
      }
    } else if (questionLists[i]?.saveUserColumn === "medicineDrugs") {
      if (gettingAnswers[questionLists[i]?.saveUserColumn] !== null) {
        if (gettingAnswers[questionLists[i]?.saveUserColumn]?.length > 0) {
          document.body.scrollTop = document.body.scrollHeight;
          setCheckedItems(gettingAnswers[questionLists[i]?.saveUserColumn]);
        }
      }
    } else if (
      questionListsStatus === "success" &&
      i >= questionLists?.length
    ) {
      //설문 종료
      dispatch(resetI());
      dispatch(changeStateArray(0));
      navigate("/healthinfo");
    }
    // eslint-disable-next-line
  }, [i, dispatch, loginInfo.userId, navigate, questionListsStatus]);

  const onSearchMedicine = (name) => {
    if (drugListLoading) return;
    if (!name) return;
    setShowFoundDrugList(true);
    setDrugListLoading(true);

    dispatch(searchMedicine({ name: name }))
      .unwrap()
      .then((res) => {
        if (res) {
          setDrugListLoading(false);
          if (res.medicineList?.length > 0) {
            setShowFoundDrugList(true);
            let tempList = res.medicineList.map((item) => ({
              viewName: item.name,
              similarName: "",
            }));
            setMedicineList(searchAndHighlight(tempList, name));
          } else {
            setShowFoundDrugList(false);
            setMedicineList([]);
          }
        } else {
          /// remote (not ours) API failed
          toast(
            <p>
              일시적인 오류가 발생했습니다.
              <br /> 다시 검색해 주시기 바랍니다.
            </p>,
            toastCommonProps("bottom-center", "toast_alert")
          );
          console.log("failed");
        }
      })
      .catch((error) => {
        setDrugListLoading(false);
        console.log(error);
      });
  };
  return (
    <>
      {questionListsStatus === "success" ? (
        <>
          <TitleBox
            option={3}
            title={questionLists[i]?.question}
            subTitle={questionLists[i]?.subTitle1}
            subTitle2={questionLists[i]?.subTitle2}
          />
          {questionLists[i]?.saveUserColumn === "hospitalName" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              <InputSelectBox
                label={"직접입력"}
                nameTitle={"hospitalName"}
                setResetPlaceHolder={setResetPlaceHolder}
                placeholder={
                  gettingAnswers.hospitalName
                    ? resetPlaceHolder
                      ? "병원 이름을 검색해 주세요."
                      : !gettingAnswers.hospitalName.includes("HN")
                      ? gettingAnswers.hospitalName
                      : "병원 이름을 검색해 주세요."
                    : "병원 이름을 검색해 주세요."
                }
                onChangeAction={(e) => onChangeHospitalName(e)}
                onClickAction={onClickScrollBy}
                value={hospital}
                setValue={setHospital}
                active={hospital ? "on" : "ready"}
                datas={hospitalLists}
                onSaveAnswer={onSaveAnswer}
              />
              {/* 모름박스 추가*/}
              {questionLists[i]?.answerList.map((answer, idx) => (
                <ul key={idx}>
                  <AnswerCard
                    title={answer.name}
                    onAction={onSaveAnswer}
                    answer={answer.dCode}
                    type={questionLists[i]?.saveUserColumn}
                  />
                </ul>
              ))}
              {/* <div className="h-[100px]"></div> */}
            </div>
          ) : questionLists[i]?.saveUserColumn === "chronicDisease" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              <ul className="flex flex-wrap gap-2">
                {questionLists[i]?.answerList.map((list, idx) => (
                  <AnswerCard2
                    key={idx}
                    list={list}
                    onCheckOne={onCheckOne}
                    checkedItems={checkedItems}
                  />
                ))}
              </ul>
            </div>
          ) : questionLists[i]?.saveUserColumn === "medicineDrugs" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              <InputSelectBox
                search={true}
                label={"직접입력"}
                nameTitle={"medicineDrugs"}
                placeholder={
                  drugName === null || drugName === undefined || drugName === ""
                    ? "복용중인 약을 검색해 주세요."
                    : drugName
                }
                onChangeAction={(e) => onChangeDrugName(e)}
                onClickAction={onClickScrollBy}
                value={drugName}
                setValue={setDrugName}
                active={drugName ? "on" : "ready"}
                removeSpace={true}
                onSearch={onSearchMedicine}
              />
              {showFoundDrugList &&
                (!drugListLoading ? (
                  <ul className="flex flex-col gap-y-2">
                    {medicineList?.map((el, idx) => (
                      <li
                        onClick={() =>
                          onSaveAnswerAndMakeList(
                            "medicineDrugs",
                            el?.details?.viewName
                          )
                        }
                        key={idx}
                        className="flex items-center justify-between p-4 border border-black cursor-pointer medicineList rounded-2xl text-btn_700 text-gray_200"
                      >
                        <span className="block w-4/5 break-words">
                          {el?.result}
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="flex flex-col items-center justify-center gap-3">
                    <div className="loader h-8 w-8 rounded-full border-[2px] border-t-[2px] border-gray-200 ease-linear " />
                    <img
                      className="h-24"
                      src="/images/mobile/character/coach.gif"
                      alt="coach"
                    />
                  </div>
                ))}
              {checkedItems?.length > 0 &&
                !(
                  checkedItems[0] === "TM999" || checkedItems[0] === "TM997"
                ) && (
                  <ul className="flex flex-col gap-y-2">
                    {checkedItems
                      ?.filter(
                        (drug) => !(drug === "TM999" || drug === "TM997")
                      )
                      ?.map((el, idx) => (
                        <li
                          key={idx}
                          className="flex items-center justify-between p-4 border border-black cursor-pointer medicineList rounded-2xl text-btn_700 text-gray_200"
                        >
                          <span className="block w-4/5 break-words">{el}</span>
                          <img
                            onClick={() => onDeleteMedicineList(el)}
                            src="/images/mobile/icon/close.svg"
                            alt="닫기 아이콘"
                          />
                        </li>
                      ))}
                  </ul>
                )}

              <ul className="flex flex-col flex-wrap gap-2 ">
                {questionLists[i]?.answerList.map((list, idx) => (
                  <AnswerCard2
                    key={idx}
                    list={list}
                    onCheckOne={onCheckOne}
                    checkedItems={checkedItems}
                  />
                ))}
              </ul>
            </div>
          ) : null}
          <BtnForQuestion
            i={i}
            active={
              questionLists[i]?.saveUserColumn === "hospitalName"
                ? gettingAnswers?.hospitalName
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn === "chronicDisease"
                ? checkedItems?.length > 0 || gettingAnswers?.chronicDisease
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn === "medicineDrugs"
                ? checkedItems?.length > 0 ||
                  gettingAnswers?.medicineDrugs?.length > 0
                  ? "on"
                  : "ready"
                : ""
            }
            onAction1={onLeftAction}
            onAction2={onMoveToNext}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default QuestionForm;
