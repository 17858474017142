import React from "react";
// import { useSelector } from "react-redux";

const InfoCard = ({
  id,
  thumbnail,
  category,
  title,
  marked,
  onOpenInfoPost,
}) => {
  return (
    <div className="min-w-fit pb-5" onClick={() => onOpenInfoPost(id)}>
      <div
        className="bgCover relative h-[180px] overflow-hidden rounded bg-gray-50"
        // style={{
        //   background:
        //     "url(" +
        //     `${
        //       thumbnail !== null
        //         ? thumbnail
        //         : "/images/mobile/image/no_thumbnail.svg"
        //     }` +
        //     ") no-repeat center",
        // }}
      >
        <img src={thumbnail} alt="" className="h-full w-full" />
        <img
          className="absolute bottom-2 right-2"
          src={
            marked
              ? "/images/mobile/icon/bookmark_marked.svg"
              : "/images/mobile/icon/bookmark_unmarked.svg"
          }
          alt="북마크 아이콘"
        />
      </div>
      <div className="pt-2 text-caption2_400 text-[#5C8EF4]">{category}</div>
      <div>
        <span className="ellipsis break-all text-body3_700 text-gray_180">
          {title}
        </span>
      </div>
    </div>
  );
};

export default InfoCard;
