import React from "react";

const SimilarFoodCard = ({ chosenOne, setChosenOne, food }) => {
  return (
    <div
      onClick={() =>
        setChosenOne({
          id: food.id,
          eyemesurementnumber: food.eyemesurementnumber,
          foodName: food.foodname,
        })
      }
      className={
        "flex justify-between gap-[2px] rounded-[16px] border px-5 py-4 " +
        (chosenOne.id === food.id
          ? "border-main text-main"
          : "border-gray_160 text-gray_180")
      }
    >
      <h2 className="text-body3_700">{food.foodname}</h2>
      <div className="flex gap-1">
        <h5 className="text-caption1_500">{food.eyemesurementnumber}</h5>
        <span>{food.eyemesurementunit}</span>
      </div>
    </div>
  );
};

export default SimilarFoodCard;
