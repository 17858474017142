import React from 'react'
import HeadCont from '../../components/common/HeadCont'

const Version = () => {
  const pleaseupdate = false;
  return (
    <>
      <HeadCont rightIcon={'no icon'} transparent={'on'} title={"버전 관리"} underline={true} />
      <section className='mt-14 mb-6 relative px-6 flex flex-col items-center  text-body1_700 text-gray_160'>
        <img src='/images/mobile/home/landing_rev.svg' alt='버전 이미지' className='pt-[94px]' />
        <span className='pt-[42px]'>{pleaseupdate ? "V.1.0" : "V.2.0"}</span>
        <span className='pt-4 pb-12'>{pleaseupdate ? "업데이트가 필요합니다." : "최신 버전 입니다."}</span>
      </section>
    </>
  )
}

export default Version