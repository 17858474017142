import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
const AddedEatList = () => {
  const { eatDayLists, weekDatas } = useSelector((state) => state.management);
  const headList = [
    "No.",
    "작성일",
    "식사기록",
    "총 섭취 칼로리(kcal) / 단백질(g)",
  ];
  // Index를 startDate로 찾음
  const startDate = dayjs(weekDatas[weekDatas?.length - 1].startDate);
  return (
    <table className="w-full border-separate table-auto" cellSpacing={0}>
      <thead>
        <tr className="bg-gray_60">
          {headList?.map((head, idx) => (
            <th
              key={idx}
              className="px-2 py-4 font-semibold text-black border text-caption_1 border-y border-gray_60 first:rounded-tl-lg first:border-l last:rounded-tr-lg last:border-r"
            >
              {head}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {eatDayLists?.map((data, idx) => (
          <tr
            key={idx}
            className={
              "px-6 py-3 hover:bg-orange_10 " +
              (idx % 2 === 1 ? "bg-gray_95" : "bg-gray_10")
            }
          >
            <td
              className={
                "text-caption_1 border-b border-l border-gray_60 py-4 text-center text-black " +
                (idx === eatDayLists?.length - 1 ? "rounded-bl-lg" : "")
              }
            >
              {-startDate.diff(data.mealDay, "day") + 1}
            </td>
            <td className="py-4 text-center text-black border-b text-caption_1 border-gray_60">
              {data.mealDay}
            </td>
            <td className="py-4 text-center text-black border-b text-caption_1 border-gray_60">
              {data.eatList?.length > 0
                ? data.eatList?.map((el, idx) => (
                    <span key={idx}>
                      {el.notEat !== true &&
                        (el.mealType === "B"
                          ? " 아침밥,"
                          : el.mealType === "L"
                          ? " 점심밥,"
                          : el.mealType === "D"
                          ? " 저녁밥,"
                          : el.mealType === "BS"
                          ? " 아침간식,"
                          : el.mealType === "LS"
                          ? " 점심간식,"
                          : el.mealType === "DS"
                          ? " 저녁간식"
                          : "")}
                    </span>
                  ))
                : "-"}
            </td>

            <td
              className={
                "text-caption_1 border-b border-r border-gray_60 py-4 text-center text-black " +
                (idx === eatDayLists?.length - 1 ? "rounded-br-lg" : "")
              }
            >
              {parseInt(data.kcal) !== 0 && parseInt(data.proteinG) !== 0
                ? parseInt(data.kcal) + " / " + data.proteinG?.toFixed(1)
                : "-"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AddedEatList;
