import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  handlePageChange,
  onSetSubSection,
} from "../../../../../features/admin/commonSlice";
import {
  deleteAllTestGPT,
  getALLCancerGPT,
  searchCancerGPT,
} from "../../../../../features/admin/communitySlice";
import { setPageArr } from "../../../../../features/admin/paginationSlice";
import CsvModal from "../../../components/board/CsvModal";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../../components/common/ButtonCustom";
import CommonOption from "../../../components/common/CommonOption";
import PaginationLayout from "../../../components/common/pagination/PaginationLayout";
import SearchBox from "../../../components/common/SearchBox";
import { CancerGPTExcelHeadList } from "../../../components/common/TableHeadList";
import CancerGPTMemberList from "../CancerGPTQuestionList";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../../common/toast";
import { questionTypeOption } from "../CancerGPTOptionList";
const CancerGPT = ({ showEachNoticeModal }) => {
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const select_list = [
    "분류 전체",
    "식이",
    "운동",
    "수술",
    "의사 검색",
    "간병인 검색",
    "요양원 검색",
    "기능성 식품",
    "재활",
    "부작용",
    "증상",
    "약제",
    "감정",
    "암관련 통계",
    "암",
    "인사",
    "기타",
  ];
  const select_list2 = ["작성자", "질문", "답변"];
  const [searchinput, setSearchInput] = useState("");
  const { pagePerList } = useSelector((state) => state.pagination);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [csvDown, setCsvDown] = useState(false);
  const { questionList, totalPage, allCancerGPT } = useSelector(
    (state) => state.commu
  );

  const onChangeInput = (e) => {
    setSearchInput(e.target.value);
  };
  const showCSVdown = useCallback(() => {
    setCsvDown(!csvDown);
  }, [setCsvDown, csvDown]);
  // eslint-disable-next-line
  const onClickSearch = useCallback(async (pageNum, searchinput) => {
    dispatch(handlePageChange(pageNum));
    //1. select가져오기
    const gptSelect1 = document.getElementById("gptSelect1");
    const gptSelect2 = document.getElementById("gptSelect2");
    let gptSelect1Val = gptSelect1?.options[gptSelect1.selectedIndex].value;
    let gptSelect2Val = gptSelect2?.options[gptSelect2.selectedIndex].value;

    //2. Information.js참고하여 로직짜기
    //3. CommuTabs에 tabclick시 default로 되도록 로직수정
    let param = {
      page: pageNum,
      countPerPage: pagePerList,
      userId: gptSelect2Val === "작성자" ? searchinput : "",
      question: gptSelect2Val === "질문" ? searchinput : "",
      answer: gptSelect2Val === "답변" ? searchinput : "",
      category: gptSelect1Val === "분류 전체" ? "" : gptSelect1Val,
    };

    const res = await dispatch(searchCancerGPT(param)).unwrap();
    if (res.ok) {
      const defaultarr = Array(res.totalPage)
        .fill()
        ?.map((v, i) => {
          return i + 1;
        });
      if (pagePerList >= pageNum) {
        //1~10사이일경우 pagePerList 10
        dispatch(setPageArr(defaultarr.slice(0, pagePerList)));
      } else {
        //11~
        dispatch(
          setPageArr(
            defaultarr.slice(
              (Math.ceil(pageNum / pagePerList) - 1) * pagePerList,
              Math.ceil(pageNum / pagePerList) * pagePerList
            )
          )
        );
      }
    }
  });
  const handlePageFn = (clickPage) => {
    dispatch(handlePageChange(clickPage));
    onClickSearch(clickPage, searchinput);
  };
  const getAllData = () => {
    let param = { userId: "" };
    dispatch(getALLCancerGPT(param));
  };
  const deleteCustomerCAnswer = async () => {
    const res = await dispatch(deleteAllTestGPT()).unwrap();
    try {
      if (res.ok) {
        onClickSearch(1, "");
        toast(
          <p>test gpt 내역이 삭제되었습니다.</p>,
          toastCommonProps("top-right", "toast_alert")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (loginInfo?.adminId) {
      onClickSearch(1, "");
      dispatch(onSetSubSection("CancerGPT"));
    } else {
      navigate("/admin/signin");
    }
    // eslint-disable-next-line
  }, [dispatch, loginInfo.adminId]);

  return (
    <>
      {csvDown && (
        <CsvModal
          showCSVdown={showCSVdown}
          lists={allCancerGPT}
          headers={CancerGPTExcelHeadList}
          tab={"CancerGPT"}
        />
      )}
      <div className="order-1 flex h-full w-full flex-none grow-0 flex-col items-start justify-start self-stretch p-0">
        <div className="order-1 flex h-full w-auto flex-none grow-0 flex-col items-start justify-start self-stretch">
          <div className="flex w-full flex-col items-start rounded-b-[24px] rounded-r-3xl bg-[#FCFCFC] p-[24px] shadow-md">
            <div className="order-0 flex w-auto flex-none grow-0 flex-col items-start self-stretch">
              <div className="flex w-full justify-end gap-x-2 pb-4">
                <BGButtonCustom
                  onClick={() => {
                    showCSVdown();
                    getAllData();
                  }}
                  content={"엑셀파일로 내려받기"}
                  bgcolor={"bg-orange_100"}
                  textcolor={"text-gray_10"}
                  textsize={"text-caption1_600"}
                  width={"160px"}
                />
                <CommonOption
                  lists={["분류 전체", ...Object.keys(questionTypeOption)]}
                  ver={1}
                  selectid={"gptSelect1"}
                  mywidth={148}
                />
                <CommonOption
                  lists={select_list2}
                  ver={1}
                  selectid={"gptSelect2"}
                  mywidth={148}
                />
                <SearchBox
                  value={searchinput || ""}
                  onChange={onChangeInput}
                  onClick={() => onClickSearch(1, searchinput)}
                  placeholder={"닉네임을 입력하세요."}
                  width={296}
                />
              </div>
              <PaginationLayout
                totalPage={totalPage}
                onClick={(clickpage) => handlePageFn(clickpage)}
                pagePerList={pagePerList}
              >
                <CancerGPTMemberList
                  showEachNoticeModal={showEachNoticeModal}
                  filteredUsers={questionList}
                />
              </PaginationLayout>
              <div className="flex w-full flex-col items-end ">
                <BorderButtonCustom
                  onClick={deleteCustomerCAnswer}
                  content={"test용 삭제"}
                  bgcolor={""}
                  textcolor={"text-orange_100"}
                  bordercolor={"border-orange_100"}
                  textsize={"text-caption1_600"}
                  xpadding={"12px"}
                  width={120}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancerGPT;
