import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveRequestToAddFood } from "../../../../features/mobile/mealSlice";
import { useNavigate } from "react-router-dom";
import HeadCont from "../../components/common/HeadCont";
import MenuBar from "../../components/common/MenuBar";
import PhotoCard from "../../components/common/PhotoCard";
import Btn from "../../components/common/Btn";
import Dialog from "../../components/common/Dialog";
import { resizeFile } from "../../../../common/util/base";

const RequestToAddFood = () => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const navigate = useNavigate();
  const [addFoodData, setAddFoodData] = useState({
    foodName: "",
    memo: "",
    camera: [],
  });
  const [loadedImage, setLoadedImage] = useState(null);
  const onAddFoodData = (e) =>
    setAddFoodData({ ...addFoodData, [e.target.name]: e.target.value });
  const [openDialog, setOpenDialog] = useState(false);

  const titleFn = (title) => {
    return <h2 className="text-caption1_600 text-gray_160">{title}</h2>;
  };

  const descFn = (desc) => {
    return <h2 className="text-caption1_400 text-gray_160">{desc}</h2>;
  };

  const onAddThumbnail = async (e) => {
    let reader = new FileReader(); // FileReader API로 이미지 인식
    let resize = await resizeFile(e.target.files[0]); //reader에게 file을 먼저 읽히고
    reader.readAsDataURL(resize);
    setAddFoodData({ ...addFoodData, camera: [resize] }); //reader에게 file을 먼저 읽히고
    reader.onload = (e) => {
      // 사진 올리고 나서 처리하는 event
      setLoadedImage(e.target.result); //데이터를 img src 넣어 이미지 미리보기 가능
      e.target.value = ""; //같은 파일을 올리면 인지못해서 초기화
    };
  };

  const onSaveRequestToAddFood = () => {
    let params = {
      userId: loginInfo.userId,
      title: addFoodData.foodName,
      content: addFoodData.memo,
      files: addFoodData.camera,
    };
    dispatch(saveRequestToAddFood(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          setOpenDialog(true);
          setAddFoodData({ foodName: "", memo: "", camera: [] });
          setLoadedImage(null);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    // prettier-ignore
    <>
        <HeadCont leftIcon={'no icon'} rightIcon={'close'} onRightAction={() => navigate(-1)} />
        <section className='px-6 py-4 mt-14 mb-28'>
            <h2 className='text-subtitle3 text-gray_180'>없는 음식 알려주기</h2>
            <p className='text-body3_400 text-gray_160'>아직 등록되지 않은 음식을 알려주세요.</p>
            <div className='mt-8 space-y-8'>
                <div className='space-y-6'>
                    <div>
                        {titleFn('음식명')}
                        <textarea onChange={(e) => onAddFoodData(e)} className='w-full h-[66px] appearance-none border border-gray_80 rounded-md pl-4 py-4 focus:outline-none text-body2_500l text-black'
                            name='foodName' placeholder='음식명을 입력해 주세요'>
                        </textarea>
                        {descFn('예시) 오뚜기 신라면')}
                    </div>
                    <div>
                        {titleFn('내용')}
                        <textarea onChange={(e) => onAddFoodData(e)} className='w-full h-[66px] appearance-none border border-gray_80 rounded-md pl-4 py-4 focus:outline-none text-body2_500l text-black'
                            name='memo' placeholder='내용을 입력해 주세요'>
                        </textarea>
                    </div>
                    <div>
                        {!loadedImage && <>
                            {titleFn('사진 첨부')}
                            <PhotoCard onAddThumbnail={onAddThumbnail} option={true} />
                        </>}
                        {!loadedImage && descFn('음식 또는 영양정보 사진을 첨부해주시면 더욱 정확한 정보로 반영됩니다.')}
                        {loadedImage &&
                            <div id='image_container' className='relative max-w-[327px] rounded-md text-body_2 text-gray_40 border'>
                                <img src={loadedImage} alt='음식 이미지' className='w-[100%] h-auto' />
                                <span className='absolute w-8 h-8 top-2 right-2'>
                                    <img onClick={() => { setAddFoodData({ ...addFoodData, camera: [] }); setLoadedImage(null); }} src='/images/mobile/icon/close_circle.svg' alt="닫기 버튼" /></span>
                            </div>}
                    </div>
                </div>
                <Btn bgColor={'bg-main'} textColor={'text-white'} borderColor={'border border-main'} title={'저장'} active={!addFoodData.foodName ? 'ready' : 'on'} onAction={onSaveRequestToAddFood} />
            </div>
        </section>
        {openDialog && <Dialog innerClass={'py-4 pl-6 pr-5'} btnClass={'mt-[17px]'} title={'음식 알려주기 완료!'} desc={'소중한 의견 감사드립니다.<br/>알려주신 내용은 음식 데이터<br/>업데이트 시 반영될 예정입니다.'}
            onRightBtnAction={() => { window.scrollTo(0, 0); navigate(-1) }} onCoverAction={() => { window.scrollTo(0, 0); navigate(-1) }} />}
        <MenuBar />
    </>
  );
};

export default RequestToAddFood;
