import React from 'react';
import { useNavigate } from "react-router-dom";

const QuickNav = () => {
    const navigate = useNavigate()
    const datas = [
        { id: 1, title: <h2 className='text-body3_700 text-green_180'>건강기록<br />쓰기</h2>, textColor: 'text-green_180', bgColor: 'bg-green_10', borderColor: 'border-green_80', linkTo: '/diary' },
        { id: 2, title: <h2 className='text-body3_700 text-yellow_180'>추천 식단<br />보기</h2>, textColor: 'text-yellow_180', bgColor: 'bg-yellow_10', borderColor: 'border-yellow_80', linkTo: '/recommend' },
        { id: 3, title: <h2 className='text-body3_700 text-orange_180'>식사<br />기록 하기</h2>, textColor: 'text-orange_180', bgColor: 'bg-orange_10', borderColor: 'border-orange_80', linkTo: '/meal/record' },
    ]

    return (
        <div className='p-4 border-b border-gray_40'>
            <h2 className='text-caption1_500 text-gray_160 mb-2'>바로가기</h2>
            <ul className='flex justify-between'>
                {datas.map(data =>
                    <li onClick={() => navigate(`${data.linkTo}`)} key={data.id} className={'px-4 py-3 text-center border rounded-md ' + `${data.textColor} ${data.bgColor} ${data.borderColor} `}> {data.title}</li>)
                }
            </ul>
        </div >
    );
};

export default QuickNav;