import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onSetCustomerCenterIdName } from "../../../../features/mobile/settingSlice";
import Btn from "../../components/common/Btn";
import HeadCont from "../../components/common/HeadCont";
import TabCommon from "../../components/common/TabCommon";
import FAQ from "./contact/FAQ";
import UsageInquiry from "./contact/UsageInquiry";

const Contact = () => {
  const [tab, setTab] = useState("이용문의");
  const tabs = ["이용문의", "자주 묻는 질문"];

  const dispatch = useDispatch();
  const { customerCenterQ, customerCenterId } = useSelector(
    (state) => state.setting
  );
  const navigate = useNavigate();

  return (
    <>
      <HeadCont
        rightIcon={"no icon"}
        transparent={"bg-back_ground"}
        title={"이용 문의"}
        onLeftAction={() => navigate("/nav")}
      />
      <section className="mb-6 mt-14">
        <TabCommon tabs={tabs} setTab={setTab} tab={tab} />
        {tab === "이용문의" ? (
          <div>
            <UsageInquiry
              customerCenterQ={customerCenterQ}
              customerCenterId={customerCenterId}
            />
          </div>
        ) : (
          <FAQ />
        )}
        {tab === "이용문의" && (
          <div className="px-6 pt-4 maxW">
            <Btn
              bgColor={"bg-main"}
              textColor={"text-white font-semibold"}
              borderColor={"border border-main"}
              title={"문의하기"}
              active={"on"}
              onAction={() => {
                navigate("/contact/usageId");
                dispatch(onSetCustomerCenterIdName(null));
              }}
            />
          </div>
        )}
      </section>
    </>
  );
};

export default Contact;
