import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import { useNavigate } from "react-router-dom";
import {
  headList,
  tableList1,
  tableList1_Patient,
  tableList1_Protector,
  tableList2,
  tableList3,
  tableList3_Grid1,
  tableList3_Grid2BT,
  tableList4,
  tableList4_Grid2,
  tableList4_Grid2_2,
  tableList4_Grid2_3,
  tableList4_Grid2_4,
  tableList5_Grid2BT,
} from "./Value";

// import Paging from "../../components/common/Paging";
import dayjs from "dayjs";
import {
  addUserMemo,
  deleteUserMemo,
  fetchUserInfo,
  getUserMemo,
} from "../../../../features/admin/accountSlice";
import Memos from "../../components/account/Memos";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../components/common/ButtonCustom";
import { onClickManagementDetailState } from "../../../../features/admin/managementSlice";
import { handlePageChange } from "../../../../features/admin/commonSlice";
import PaginationLayout from "../../components/common/pagination/PaginationLayout";
import { setPageArr } from "../../../../features/admin/paginationSlice";

const MemberList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const [memo, setMemo] = useState("");
  const memberobj = useSelector(
    (state) => state.management.mangementDetailState
  );
  //todo
  const { totalPage } = useSelector((state) => state.accountadmin);
  const { memoList } = useSelector((state) => state.accountadmin);
  const { pagePerList5 } = useSelector((state) => state.pagination);
  const [userInfo, setUserInfo] = useState([]);
  const { page } = useSelector((state) => state.common);
  const [idList, setIdList] = useState([]);

  const submitData = async () => {
    let param = {
      memo: memo,
      userId: memberobj.userId,
      adminId: loginInfo?.adminId,
    };
    const res = await dispatch(addUserMemo(param)).unwrap();
    if (res.data.ok) {
      toast(
        <p>댓글이 등록되었습니다.</p>,
        toastCommonProps("top-right", "toast_alert")
      );
      setMemo("");
      getMemos();
    } else {
      console.log(res, ">>res");
    }
  };

  // * PaginationLayout 관련
  const defaultPageShow = useCallback(async () => {
    let params = {
      userId: memberobj.userId,
      page: 1,
      countPerPage: pagePerList5,
    };
    const res = await dispatch(getUserMemo(params)).unwrap();
    if (res.data.ok) {
      const defaultarr = Array(res.data.totalPage)
        .fill()
        ?.map((v, i) => {
          return i + 1;
        });
      dispatch(setPageArr(defaultarr.slice(0, pagePerList5)));
    }
  }, [dispatch, memberobj.userId, pagePerList5, setPageArr]);
  //
  useEffect(() => {
    if (loginInfo?.adminId) {
      defaultPageShow();
      dispatch(handlePageChange(1));
      const getUserInfo = async () => {
        let param = memberobj.userId;
        const res = await dispatch(fetchUserInfo(param)).unwrap();
        try {
          if (res.data) {
            setUserInfo(res.data.user);
          } else {
            console.log(res);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getMemos();
      getUserInfo();
    } else {
      navigate("/admin/signin");
    }
  }, [dispatch, loginInfo.adminId, memberobj.userId]);

  const onClickSearch = useCallback(
    async (pageNum) => {
      dispatch(handlePageChange(pageNum));
      let params = {
        userId: memberobj.userId,
        page: pageNum,
        countPerPage: pagePerList5,
      };
      const res = await dispatch(getUserMemo(params)).unwrap();
      if (res.data.ok) {
        const defaultarr = Array(res.data.totalPage)
          .fill()
          ?.map((v, i) => {
            return i + 1;
          });
        dispatch(
          setPageArr(
            defaultarr.slice(
              (Math.ceil(pageNum / pagePerList5) - 1) * pagePerList5,
              Math.ceil(pageNum / pagePerList5) * pagePerList5
            )
          )
        );
      }
    },
    [dispatch, pagePerList5]
  );

  const getMemos = useCallback(async () => {
    await dispatch(
      getUserMemo({
        userId: memberobj.userId,
        page: page,
        countPerPage: pagePerList5,
      })
    ).unwrap();
  }, [dispatch, memberobj.userId, page, pagePerList5]);

  const onChangeMemo = (e) => {
    setMemo(e.target.value);
  };

  const deleteMemos = async () => {
    const result = await dispatch(deleteUserMemo({ ids: idList })).unwrap();
    try {
      if (result.data.ok) {
        toast(
          <p>댓글이 삭제되었습니다.</p>,
          toastCommonProps("top-right", "toast_alert")
        );
        getMemos();
        defaultCheckbox();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const defaultCheckbox = useCallback(() => {
    let allinputboxcheck = document.querySelectorAll(".checkMemoId");
    for (let i = 0; allinputboxcheck?.length > i; i++) {
      allinputboxcheck[i].checked = false;
    }
  }, []);
  console.log(userInfo);

  const makeHeadList = (param) => {
    return (
      <label className="col-span-12 mt-[16px] flex items-center rounded-t-[8px] border border-gray_60 bg-gray_30 px-[16px] py-[2px] text-sm font-bold text-[#05040D]">
        {param}
      </label>
    );
  };

  const ifArray = (something) => {
    if (typeof something === "object" && something?.length > 0) {
      return something?.map(
        (el, idx) => el + (something?.length === idx + 1 ? "" : ",")
      );
    } else {
      return something;
    }
  };
  const makeTrList = (tableLi, last, BrRound, userDiet) => {
    //BrRound : BottomRight Round 여부 //userDiet =>param값중 userDietLife obj가 있는 필드 있을 경우 true
    return (
      <div
        className={
          "grid w-full grid-cols-4 border border-t-0 border-gray_60 bg-gray_30 " +
          (last ? "rounded-b-xl" : "")
        }
      >
        {tableLi?.map((el, idx) => {
          return (
            <div
              key={idx}
              className={
                "flex h-[35px] w-full text-sm font-semibold " +
                (idx === tableLi?.length - 1 ||
                idx === tableLi?.length - 2 ||
                idx === tableLi?.length - 3 ||
                idx === tableLi?.length - 4
                  ? " "
                  : " border-b-[1px] border-gray_60 ")
              }
            >
              <label
                className={"flex w-1/3 items-center justify-center text-center"}
              >
                {el.title}
              </label>
              <p
                className={
                  "flex w-2/3 items-center justify-center bg-white text-center" +
                  " border-gray_60 " +
                  (idx % 4 === 3 ? " border-l-[1px] " : " border-x-[1px] ") +
                  (idx + 1 === tableLi?.length && !BrRound
                    ? " rounded-br-xl "
                    : "")
                }
              >
                {ifArray(
                  el.subtit === "createdAt"
                    ? dayjs(userInfo[el.subtit]).format("YYYY-MM-DD")
                    : el.subtit === "operationDate"
                    ? userInfo[el.subtit] === "OD997"
                      ? "모름"
                      : userInfo[el.subtit]
                    : el.subtit === "userType"
                    ? `${userInfo[el.subtit]}(${
                        userInfo["userHospitalMemberName"]
                      }${
                        userInfo["registHospitalName"] !== undefined
                          ? "/" + userInfo["registHospitalName"]
                          : ""
                      })`
                    : userDiet && userInfo?.userDietLife
                    ? userInfo?.userDietLife[el.subtit]?.length !== 0
                      ? userInfo?.userDietLife[el.subtit]
                      : "-"
                    : userInfo[el.subtit]
                    ? userInfo[el.subtit]
                    : "-"
                )}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const makeTrList_OneRow = (tableLi) => {
    //한줄짜리
    return (
      <div className={"flex h-[35px] w-full text-sm font-semibold"}>
        {tableLi?.map((el, idx) => {
          return (
            <div key={idx} className={"flex h-full w-full text-sm"}>
              <label
                className={
                  "box-content flex w-1/12 items-center justify-center border border-t-0 border-gray_60 bg-gray_30 text-center"
                }
              >
                {el.title}
              </label>
              <p
                className={
                  " flex w-11/12 items-center justify-center border-b border-r border-gray_60 bg-white p-2"
                }
              >
                {ifArray(userInfo[el.subtit] ? userInfo[el.subtit] : "-")}
              </p>
            </div>
          );
        })}
      </div>
    );
  };
  const makeTrList_Grid2 = (tableLi, userDiet) => {
    //grid 2개 중간 //userDiet =>param값중 userDietLife obj가 있는 필드 있을 경우 true
    return (
      <div
        className={
          "flex h-[35px] w-full border-[1px] border-t-0 border-gray_60 text-sm font-semibold"
        }
      >
        {tableLi?.map((el, idx) => {
          return (
            <div
              key={idx}
              className={"flex h-full w-full text-sm font-semibold"}
            >
              <label
                className={
                  "flex w-1/6 items-center justify-center  bg-gray_30 text-center leading-tight"
                }
              >
                {el.title}
              </label>
              <p
                className={
                  "flex w-5/6 items-center justify-center border-l-[1px] border-gray_60 bg-white p-2 text-center leading-tight" +
                  (idx === 0 ? " border-r-[1px] border-gray_60 " : "")
                }
              >
                {ifArray(
                  userDiet && userInfo?.userDietLife
                    ? userInfo?.userDietLife[el.subtit]?.length !== 0
                      ? userInfo?.userDietLife[el.subtit]
                      : "-"
                    : userInfo[el.subtit]
                    ? userInfo[el.subtit]
                    : "-"
                )}
              </p>
            </div>
          );
        })}
      </div>
    );
  };
  const makeTrList_Grid2_Bottom = (tableLi, userDiet) => {
    //grid 2개인 하단  //userDiet =>param값중 userDietLife obj가 있는 필드 있을 경우 true
    return (
      <div
        className={
          "flex h-[35px] w-full rounded-b-[8px] border border-t-0 border-gray_60 text-sm font-semibold leading-none"
        }
      >
        {tableLi?.map((el, idx) => {
          return (
            <div
              key={idx}
              className={"flex h-full w-full text-sm font-semibold"}
            >
              <label
                className={
                  " flex w-1/6 items-center justify-center bg-gray_30 text-center " +
                  (idx === 0 ? " rounded-bl-[8px] " : "")
                }
              >
                {el.title}
              </label>
              <p
                className={
                  " flex w-5/6 items-center justify-center border-l-[1px] border-gray_60 bg-white p-2 " +
                  (idx === 0
                    ? " border-r-[1px] border-gray_60 "
                    : " rounded-br-[8px] ")
                }
              >
                {ifArray(
                  userDiet && userInfo?.userDietLife
                    ? userInfo?.userDietLife[el.subtit]?.length !== 0
                      ? userInfo?.userDietLife[el.subtit]
                      : "-"
                    : userInfo[el.subtit]
                    ? userInfo[el.subtit]
                    : "-"
                )}
              </p>
            </div>
          );
        })}
      </div>
    );
  };
  const handlePageFn = (clickPage) => {
    dispatch(handlePageChange(clickPage));
    onClickSearch(clickPage);
  };
  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="grid grid-cols-1 flex-row gap-[16px] space-x-4 p-[24px] pl-[12px]">
          <div className="flex h-[48px] w-full flex-row items-center justify-between gap-[4px] pl-[24px]">
            <div className="flex h-[28px] w-fit items-center justify-center gap-[4px]">
              <img
                src={"/images/admin/icon/users.svg"}
                alt="community"
                className="h-[28px] w-[28px]"
              />
              <p className="w-[140px] px-3 text-xl font-bold text-[#463F38]">
                회원 정보 상세
              </p>
            </div>
            <div className="flex gap-[8px]">
              <BGButtonCustom
                onClick={() => {
                  navigate(`/admin/management/detail/${memberobj.name}`);
                  dispatch(onClickManagementDetailState(memberobj));
                }}
                content={"건강기록보기"}
                icon={"/images/admin/icon/arrow_prev.svg"}
                bgcolor={"bg-orange_100"}
                textcolor={"text-gray_10"}
                textsize={"caption1_600"}
                xpadding={"12px"}
              />
              <BGButtonCustom
                onClick={() => navigate(-1)}
                content={"뒤로"}
                icon={"/images/admin/icon/arrow_prev.svg"}
                bgcolor={"bg-orange_100"}
                textcolor={"text-gray_10"}
                textsize={"caption1_600"}
                xpadding={"12px"}
              />
              <BGButtonCustom
                onClick={() => navigate("/admin/account")}
                content={"회원목록으로 돌아가기"}
                icon={"/images/admin/icon/arrow_prev.svg"}
                bgcolor={"bg-orange_100"}
                textcolor={"text-gray_10"}
                textsize={"caption1_600"}
                xpadding={"12px"}
              />
            </div>
          </div>
          <div className="flex w-full flex-row items-start gap-[16px] rounded-[24px]">
            <div className="flex w-full flex-col rounded-[24px] bg-[#FCFCFC] p-[24px] pb-[36px] shadow-md">
              <p className="text-body2_600 text-black ">
                {userInfo?.name}님 상세정보
              </p>
              <div>
                {/* 기본정보 */}
                {userInfo?.userType === "보호자" ? (
                  <>
                    {makeHeadList(headList[0] + "-가입자")}
                    {makeTrList(tableList1_Protector, true)}
                    {makeHeadList(headList[0] + "-환자")}
                    {makeTrList(tableList1_Patient, true)}
                  </>
                ) : (
                  <>
                    {makeHeadList(headList[0])}
                    {makeTrList(tableList1, true)}
                  </>
                )}
                {/* 신체계측 */}
                {makeHeadList(headList[1])}
                {makeTrList(tableList2, true)}
                {/* 질병정보 */}
                {makeHeadList(headList[2])}
                {makeTrList(tableList3, false, true)}
                {makeTrList_OneRow(tableList3_Grid1, true)}
                {makeTrList_Grid2_Bottom(tableList3_Grid2BT)}
                {/* 식습관정보 */}
                {makeHeadList(headList[3])}
                {makeTrList_Grid2(tableList4_Grid2, true)}
                {makeTrList_Grid2(tableList4_Grid2_2, true)}
                {makeTrList_Grid2(tableList4_Grid2_3, true)}
                {makeTrList_Grid2(tableList4_Grid2_4, true)}
                {makeTrList(tableList4, true, false, true)}
                {/* 유입경로 */}
                {makeHeadList(headList[4])}
                {makeTrList_Grid2_Bottom(tableList5_Grid2BT)}
              </div>
            </div>
            {/* <div className="flex h-full w-2/5 flex-col gap-[16px]">
              <div className="flex h-[184px] w-full flex-col items-center justify-center gap-[16px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
                <div className="flex h-[24px] w-full items-center justify-between">
                  <p className="caption1_600 h-[24px] font-semibold text-gray_100">
                    메모 작성
                  </p>
                  <div className="flex h-[18px] items-start gap-[8px] ">
                    <p className="text-sm text-[#463F38]">
                      {dayjs().format("YYYY.MM.DD")}
                    </p>
                    <div className="h-[18px] border-r border-[#463F38]"></div>
                    <p className="text-sm text-[#463F38]">
                      {loginInfo?.adminId}
                    </p>
                  </div>
                </div>
                <div className="jsutify-center flex h-[96px] w-full flex-col items-start gap-[8px]">
                  <input
                    onChange={onChangeMemo}
                    value={memo}
                    placeholder="댓글 내용을 입력하세요"
                    className="h-[48px] w-full gap-[16px] rounded-[4px] border border-[#A39F9B] bg-[#FCFCFC] px-[20px] py-[12px] outline-none"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") submitData();
                    }}
                  />
                  <BGButtonCustom
                    onClick={memo?.length > 0 ? submitData : undefined}
                    content={"메모 작성"}
                    bgcolor={
                      memo?.length > 0 ? "bg-orange_100" : "bg-orange_80"
                    }
                    textcolor={"text-gray_10"}
                    textsize={"caption1_600"}
                  />
                </div>
              </div>
              <div className="flex w-full flex-col items-start justify-center gap-[16px] rounded-[24px]  bg-[#FCFCFC] p-[24px] shadow-md">
                <div className="flex h-[32px] w-full items-start justify-between gap-[16px]">
                  <p className="font-semibold caption1_600">메모</p>
                  <BorderButtonCustom
                    onClick={idList?.length > 0 ? deleteMemos : undefined}
                    content={"선택글 삭제"}
                    bgcolor={""}
                    textcolor={
                      idList?.length > 0 ? "text-orange_100" : "text-orange_80"
                    }
                    bordercolor={
                      idList?.length > 0
                        ? "border-orange_100"
                        : "border-orange_80"
                    }
                    textsize={"caption1_600"}
                    width={90}
                  />
                </div>
                <div className="mt-[20px] flex h-full w-full flex-row items-start  gap-[1px] rounded-[8px] border border-gray_60 bg-[#FCFCFC] ">
                  {memoList?.length === 0 ? (
                    <p className="flex w-full justify-center py-[16px]">
                      메모를 입력해주세요.
                    </p>
                  ) : (
                    <PaginationLayout
                      totalPage={totalPage}
                      pagePerList={pagePerList5}
                      onClick={(clickpage) => handlePageFn(clickpage)}
                      className="mb-[15px]"
                    >
                      {memoList?.map((data, idx) => {
                        return (
                          <Memos
                            data={data}
                            key={idx}
                            idx={idx}
                            idList={idList}
                            setIdList={setIdList}
                          />
                        );
                      })}
                    </PaginationLayout>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberList;
