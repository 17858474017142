import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  handlePageChange,
  onSetSubSection,
} from "../../../../../features/admin/commonSlice";
import {
  getNotice,
  onClickNewWrite,
} from "../../../../../features/admin/communitySlice";
import { BGButtonCustom } from "../../../components/common/ButtonCustom";
import ContentLayout from "./ContentLayout";
import PaginationLayout from "../../../components/common/pagination/PaginationLayout";
import NoticeList from "../NoticeList";
import { setPageArr } from "../../../../../features/admin/paginationSlice";

const Notice = ({ showWriteModal, showEachNoticeModal }) => {
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const { totalPage } = useSelector((state) => state.commu);
  const { pagePerList } = useSelector((state) => state.pagination);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 페이지 번호를 인자로 받아서 공지사항 목록을 조회
  const onClickSearch = useCallback(async (pageNum) => {
    dispatch(handlePageChange(pageNum)); // 현재 페이지 번호를 변경
    let param = {
      page: pageNum,
      countPerPage: pagePerList,
    };
    const res = await dispatch(getNotice(param)).unwrap(); // getNotice 액션을 실행 공지사항 목록을 조회
    // await 키워드를 사용해서 getNotice 액션의 결과를 기다림
    // getNotice 액션의 결과는 res 변수에 저장
    if (res.ok) {
      //true이면 함수는 defaultarr 변수를 생성, defaultarr 변수는 공지사항 목록의 총 페이지 수를 요소로 가지고 있다.
      const defaultarr = Array(res.totalPage)
        .fill()
        ?.map((v, i) => {
          return i + 1;
        });

      //  pagePerList이 변수는 한 페이지에 표시할 공지사항의 수를 나타낸다.
      if (pagePerList >= pageNum) {
        //1~10사이일경우 pagePerList 10
        dispatch(setPageArr(defaultarr.slice(0, pagePerList))); // defaultarr 변수를 slice 함수로 조작해서 현재 페이지에 표시할 공지사항의 번호를 가져온다.
      } else {
        //11~
        dispatch(
          setPageArr(
            defaultarr.slice(
              (Math.ceil(pageNum / pagePerList) - 1) * pagePerList,
              Math.ceil(pageNum / pagePerList) * pagePerList
            )
          )
        );
      }
    }
  });

  // 현재 페이지 번호를 변경
  const handlePageFn = (clickPage) => {
    dispatch(handlePageChange(clickPage));
    onClickSearch(clickPage);
  };
  // const getAllData = () => {
  //   let param = { userId: "" };
  //   dispatch(allNotices(param));
  // };

  useEffect(() => {
    if (loginInfo?.adminId) {
      // const getAllNotice = async () => {
      //   await dispatch(getNotice()).unwrap();
      // };
      // getAllNotice();
      onClickSearch(1, "");
      dispatch(onSetSubSection("공지사항"));
    } else {
      navigate("/admin/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loginInfo.adminId]);

  return (
    <ContentLayout>
      <div className="pb-6">
        <BGButtonCustom
          onClick={() => {
            showWriteModal();
            dispatch(onClickNewWrite(true));
          }}
          content={"공지사항 작성하기"}
          bgcolor={"bg-orange_100"}
          textcolor={"text-gray_10"}
          textsize={"text-caption1_600"}
          xpadding={"12px"}
        />
      </div>
      <PaginationLayout
        totalPage={totalPage}
        onClick={(clickpage) => handlePageFn(clickpage)}
        pagePerList={pagePerList}
      >
        <NoticeList showEachNoticeModal={showEachNoticeModal} />
      </PaginationLayout>
    </ContentLayout>
  );
};

export default Notice;
