import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * IdCard (MyPage)
 *
 * @params {Object} myInfo - Object containing both 보호자 and 환자 data (except for 보호자's image).
 * @params {Object} loginInfo - Object used only to bring in 보호자's image. (refactoring required)
 * @params {boolean} [patientCard=false] - Optional. Boolean indicating whether to display the HealthCard=>IdCard or MyPage=>IdCard display. Default is false (MyPage=>IdCard).
 */
const IdCard = ({ myInfo, loginInfo, patientCard = false }) => {
  const navigate = useNavigate();
  const candidates = ["rabbit_blue", "rabbit_pink"];
  const chosen = Math.floor(Math.random() * candidates.length);
  return patientCard ? (
    // 환자  (within HealthCard)
    <div className={"flex justify-between items-center py-6 px-4 gap-6 "}>
      <ul className="space-y-1 text-left">
        <li className="text-caption2_700 text-correct">환자</li>
        <li className="text-body1_700 text-black">{myInfo?.nickNamePatient}</li>
        <li className="text-caption2_400 text-black">
          {myInfo
            ? `만 ${myInfo?.age}세 / ${
                myInfo?.gender === "M" ? "남성" : "여성"
              }`
            : " - / - "}
        </li>
      </ul>
      <div className="w-24 h-24 bg-white relative overflow-hidden rounded-[24px] shrink-0">
        <span
          className="absolute top-[12px] left-[15px] w-[66px] h-[110px] bgCover"
          style={{
            background:
              "url(/images/mobile/character/" +
              `${myInfo?.patientCharacterPhoto}` +
              ".svg) no-repeat center",
            WebkitTransform: "scaleX(-1)",
            transform: "scaleX(-1)",
          }}
        ></span>
      </div>
    </div>
  ) : (
    // 환자 / 보호자 (within MyPage)
    <div className={"flex items-center bg-white p-6 gap-6 "}>
      <div
        onClick={() => navigate("/account/profile")}
        className="w-[72px] h-[72px] bg-orange_40 relative overflow-hidden rounded-[24px] shrink-0"
      >
        <span
          className="absolute top-[4px] left-[7.5px] w-14 h-24 bgCover"
          style={{
            background:
              "url(/images/mobile/character/" +
              `${
                loginInfo?.characterPhoto
                  ? loginInfo?.characterPhoto
                  : candidates[chosen]
              }` +
              ".svg) no-repeat center",
          }}
        ></span>
      </div>
      <ul className="space-y-1 text-left">
        <li className="text-caption2_700 text-correct">{myInfo?.userType}</li>
        <li className="text-body1_700 text-black">
          {myInfo?.userTypeCode === "UT101"
            ? myInfo?.nickNameRegist
            : myInfo?.nickName}
        </li>
        <li className="text-caption2_400 text-black">
          {myInfo?.userTypeCode
            ? myInfo?.userTypeCode === "UT101"
              ? ""
              : `만 ${myInfo?.age}세 / ${
                  myInfo?.gender === "M" ? "남성" : "여성"
                }`
            : ""}
          {}
        </li>
      </ul>
    </div>
  );
};

export default IdCard;
