import React from 'react';

const LevelCard = ({ activity, rating }) => {

    return (
        <div className='grid grid-cols-2 gap-2'>
            <div className='flex flex-col gap-2 justify-center items-center bg-green_5 rounded-xl p-4'>
                <h2 className='text-caption1_700 text-gray_160'>활동지수</h2>
                <div className='flex gap-4 items-center'>
                    <img src='/images/mobile/icon/level/지수.svg' alt='지수 아이콘' />
                    <h3 className='text-body2_700 text-green_100'>{activity || '-'}</h3>
                </div>
            </div>
            <div className='flex flex-col gap-2 justify-center items-center bg-green_5 rounded-xl p-4'>
                <h2 className='text-caption1_700 text-gray_160'>등급</h2>
                <div className='flex gap-4 items-center'>
                    {rating && <img src={rating.includes('새싹') ? '/images/mobile/icon/level/새싹.svg' : rating.includes('가지') ? '/images/mobile/icon/level/가지.svg'
                        : rating.includes('열매') ? '/images/mobile/icon/level/열매.svg' : rating.includes('나무') ? '/images/mobile/level/나무.svg' : '/images/mobile/icon/level/숲.svg'} alt='레벨 아이콘' />}
                    <h3 className='text-body2_700 text-green_100'>{rating || '-'}</h3>
                </div>
            </div>
        </div>
    );
};

export default LevelCard;