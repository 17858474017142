import React, { useEffect, useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import { useLocation, useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import DontEditAccountInput from "../../components/account/DontEditAccountInput";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../components/common/ButtonCustom";
import ConfirmModal from "../../components/common/ConfirmModal";
import { onSetAccountOneInfo } from "../../../../features/admin/accountSlice";

const AccountApprov = () => {
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const { accountInfo } = useSelector((state) => state.accountadmin);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!loginInfo?.adminId) {
      navigate("/admin/signin");
    }
  }, []);
  const [confirmModal, setConfirmModal] = useState(false);

  const showConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex w-auto min-w-min flex-col items-start justify-start gap-[16px] p-[24px]">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="flex h-[112px] w-[882px] items-center justify-between gap-[48px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              <div className="flex h-[64px] items-center justify-between gap-[24px] bg-gray_10">
                <div className="relative flex h-[64px] w-[72px]">
                  {accountInfo?.photo === null ? (
                    <span className="absolute left-0 top-0 flex h-[64px] w-[64px] items-center justify-center rounded-full bg-gray_30 font-bold">
                      {accountInfo?.name.slice(0, 1)}
                    </span>
                  ) : (
                    <img
                      className="absolute left-0 top-0 h-[64px] w-[64px] rounded-full"
                      src={accountInfo?.photo}
                    />
                  )}
                </div>
                <div className="flex h-[48px] flex-col justify-center ">
                  <h3 className="text-xl font-bold text-black">
                    {accountInfo?.name === null ? "null" : accountInfo?.name}
                  </h3>
                  <h4 className=" text-sm text-gray_100	">
                    {accountInfo?.authority === "00"
                      ? "헤링스 슈퍼관리자"
                      : accountInfo?.authority === "01"
                      ? "헤링스 일반관리자"
                      : accountInfo?.authority === "10"
                      ? "병원 슈퍼관리자"
                      : "병원 일반관리자"}
                  </h4>
                </div>
              </div>
              <p className="h-[18px] w-[125px] text-sm text-[#05040D]">
                since {dayjs(accountInfo?.createdAt).format("YYYY-MM-DD")}
              </p>
            </div>
            <div className="mt-[16px] flex h-[800px] w-[882px] flex-col items-center justify-center gap-[24px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              {/* 표 */}
              <div className="flex w-[340px] flex-col items-center justify-start gap-[8px]">
                <DontEditAccountInput
                  labelname="아이디"
                  value={accountInfo?.adminId}
                />
                <DontEditAccountInput
                  labelname="이름"
                  value={accountInfo?.name !== null ? accountInfo?.name : ""}
                />
                <DontEditAccountInput
                  labelname="성별"
                  value={
                    accountInfo?.gender !== null
                      ? accountInfo?.gender === "M"
                        ? "남성"
                        : "여성"
                      : ""
                  }
                />
                {!(
                  accountInfo?.authority === "00" ||
                  accountInfo?.authority === "01"
                ) && (
                  <DontEditAccountInput
                    labelname="소속기관(병원코드)"
                    value={
                      accountInfo?.hospitalCode !== null &&
                      accountInfo?.hospitalName !== null
                        ? `${accountInfo?.hospitalName}(${accountInfo?.hospitalCode})`
                        : ""
                    }
                  />
                )}
                {
                  // admin 병원 일반관리자 구분
                  //   1 : 의사
                  //   2 : 간호사
                  //   3 : 영양사
                  accountInfo?.authority === "11" && (
                    <DontEditAccountInput
                      labelname="구분"
                      value={
                        accountInfo?.division !== null
                          ? accountInfo?.division === "1"
                            ? "의사"
                            : accountInfo?.division === "2"
                            ? "간호사"
                            : accountInfo?.division === "3"
                            ? "영양사"
                            : ""
                          : ""
                      }
                    />
                  )
                }
                <DontEditAccountInput
                  labelname="이메일 주소"
                  value={accountInfo?.email !== null ? accountInfo?.email : ""}
                />
                <DontEditAccountInput
                  labelname="휴대폰"
                  value={
                    accountInfo?.phoneNumber !== null
                      ? accountInfo?.phoneNumber
                      : ""
                  }
                />
                <DontEditAccountInput
                  labelname="권한"
                  value={
                    accountInfo?.authority !== null
                      ? accountInfo?.authority === "00"
                        ? "헤링스 슈퍼"
                        : accountInfo?.authority === "01"
                        ? "헤링스 일반"
                        : accountInfo?.authority === "10"
                        ? "병원 슈퍼"
                        : accountInfo?.authority === "11"
                        ? "병원 일반"
                        : ""
                      : ""
                  }
                />
                <DontEditAccountInput
                  labelname="승인"
                  value={
                    accountInfo?.approvalDate !== null
                      ? dayjs(accountInfo?.approvalDate).format("YYYY.MM.DD")
                      : ""
                  }
                />
              </div>
              <div className="flex w-[340px] gap-[8px]">
                <BorderButtonCustom
                  onClick={showConfirmModal}
                  content={"삭제"}
                  bgcolor={""}
                  textcolor={"text-orange_100"}
                  bordercolor={"border-orange_100"}
                  textsize={"text-caption1_600"}
                  width={"20%"}
                />
                <BorderButtonCustom
                  onClick={() => navigate("/admin/account")}
                  content={"목록"}
                  bgcolor={""}
                  textcolor={"text-orange_100"}
                  bordercolor={"border-orange_100"}
                  textsize={"text-caption1_600"}
                  width={"20%"}
                />
                <BGButtonCustom
                  onClick={(e) => {
                    navigate("/admin/account/editadmin");
                    dispatch(onSetAccountOneInfo(accountInfo));
                  }}
                  content={"수정"}
                  bgcolor={"bg-orange_100"}
                  textcolor={"text-gray_10"}
                  textsize={"text-caption1_600"}
                  width={"60%"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {confirmModal ? (
        <ConfirmModal
          showConfirmModal={showConfirmModal}
          flag="adminDelete"
          state={accountInfo}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AccountApprov;
