import React from "react";

const MobileFourthPart = () => {
  return (
    <section className="my-[48px] text-center">
      <h3 className="mb-6 text-2xl font-extrabold">힐리어리 연구활동</h3>
      <p className="text-center text-[15px] font-semibold text-[#3E3E3E]">
        힐리어리의 식단 제공 알고리즘은
        <br />
        2022년 특허 등록 되었으며
        <br />
        암환자 관련 학회 발표 및
        <br />
        연구과제를 지속적으로 수행하고 있습니다.
      </p>

      <div className="no-scrollbar mt-[48px] flex h-fit items-start gap-7 overflow-x-scroll px-[130px] pb-2 text-center text-xs leading-5 text-gray_160">
        <div className="w-fit shrink-0">
          <img
            src="/images/mobile/landing/특허.png"
            alt="특허"
            className="w-32 h-44"
          />
          <p className="w-32">암환자를 위한 식단 제공 시스템 및 방법</p>
        </div>
        <div className="shrink-0">
          <img
            src="/images/mobile/landing/학회.png"
            alt="학회"
            className="h-44 "
          />
          <p className="w-32 mx-auto text-center">
            암환자를 위한
            <br />
            디지털 기반 식단제안
            <br />
            프로그램 활용
          </p>
        </div>
        <div className="w-fit shrink-0">
          <img
            src="/images/mobile/landing/임상연구.png"
            alt="임상연구"
            className="w-32 h-44"
          />

          <p className="w-32">
            직장암 환자의 치료 후 증상관리 프로그램 개발 및 임상시험 활용성 검증
          </p>
        </div>
      </div>
    </section>
  );
};

export default MobileFourthPart;
