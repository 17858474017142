export const navTitle = [
  { id: 1, title: "홈", section: "admin", link: "/admin" },
  {
    id: 2,
    title: "건강관리",
    section: "management",
    link: "/admin/management",
  },
  // {
  //   id: 3,
  //   title: "컨텐츠 관리",
  //   section: "contents",
  //   link: "/admin/contents",
  // },
  { id: 4, title: "게시판 관리", section: "board", link: "/admin/board" },
  { id: 5, title: "계정 관리", section: "account", link: "/admin/account" },
  // {
  //   id: 6,
  //   title: "병원 등록.관리",
  //   section: "hospital",
  //   link: "/admin/hospital",
  // },
  {
    id: 7,
    title: "대시 보드",
    section: "dashboard",
    link: undefined,
    realLink:
      "http://ec2-3-34-133-24.ap-northeast-2.compute.amazonaws.com:49153/",
    // realLink:"http://ec2-13-125-156-193.ap-northeast-2.compute.amazonaws.com:49153/" // * 변경 전 url
  },
];

export const navTitle10 = [
  { id: 1, title: "홈", section: "admin", link: "/admin" },
  {
    id: 2,
    title: "건강관리",
    section: "management",
    link: "/admin/management",
  },
  { id: 3, title: "계정 관리", section: "account", link: "/admin/account" },
];

export const subNavTitleCommu = [
  {
    id: 1,
    title: "소식",
    section: "community",
    link: "/admin/board",
  },
  {
    id: 2,
    title: "1:1 이용문의",
    section: "customercenter",
    link: "/admin/board",
  },
  {
    id: 3,
    title: "정보",
    section: "information",
    link: "/admin/board",
  },
  {
    id: 4,
    title: "음식DB요청",
    section: "dbrequest",
    link: "/admin/board",
  },
  {
    id: 5,
    title: "CancerGPT",
    section: "cancerGPT",
    link: "/admin/board",
  },
  {
    id: 6,
    title: "공지사항",
    section: "notice",
    link: "/admin/board",
  },
];
export const subNavTitleContents = [
  {
    id: 1,
    title: "종합 가이드",
    section: "fullguide",
    link: "/admin/contents",
  },
  {
    id: 2,
    title: "식사 가이드",
    section: "foodguide",
    link: "/admin/contents",
  },
  {
    id: 3,
    title: "체중 가이드",
    section: "weightguide",
    link: "/admin/contents",
  },
  {
    id: 4,
    title: "증상 가이드",
    section: "symptomguide",
    link: "/admin/contents",
  },
  {
    id: 5,
    title: "FAQ",
    section: "faq",
    link: "/admin/contents",
  },
];
export const subNavTitleAccount = [
  {
    id: 1,
    title: "회원",
    section: "user",
    link: "/admin/account",
  },
  {
    id: 2,
    title: "관리자",
    section: "admin",
    link: "/admin/account",
  },
];
export const subNavTitleAccount01 = [
  {
    id: 1,
    title: "회원",
    section: "user",
    link: "/admin/account",
  },
];
