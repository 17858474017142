import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import toastCommonProps from "../../../common/toast";
import { adminFindPwd } from "../../../features/admin/adminSlice";
import EditAdminPwd from "../components/signup/EditAdminPwd";
import CommonSquareBox from "../components/common/CommonSquareBox";
import { checkEmail, checkName } from "../commonfunc/Reg";
import {
  errorInputRing,
  errorMsgColor,
  normalInputRing,
  normalMsgColor,
} from "../data/TailwindCommon";
import { BGButtonCustom } from "../components/common/ButtonCustom";
import { LabelAdminInput } from "../components/common/LabelAdminInput";
const FindPwd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [findInput, setFindInput] = useState({
    name: "",
    adminId: "",
    email: "",
  });
  const [findAdminId, setFindAdminId] = useState("");
  // const [findCode, setFindCode] = useState(false);
  const [stage, setStage] = useState(false);
  const checkNameFc = findInput.name === "" || checkName(findInput.name);
  const checkEmailFc = findInput.email === "" || checkEmail(findInput.email);
  const checkInputStringNone =
    findInput.name === "" ||
    findInput.adminId === "" ||
    findInput.email === "" ||
    !checkNameFc ||
    !checkEmailFc;

  const onChangeFind = (e) => {
    const { name, value } = e.target;
    setFindInput({ ...findInput, [name]: value });
  };

  const FindPwdIn = async () => {
    let response = await dispatch(adminFindPwd(findInput)).unwrap();
    try {
      if (response.data.ok === true) {
        showEditPwd();
        setFindAdminId(response.data.adminId);
      } else {
        toast(
          <p className="whitespace-pre-line">
            일치하는 결과가 없습니다. <br />
            확인 후 다시 시도해주세요.
          </p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showEditPwd = () => {
    setStage(!stage);
  };
  const goSignin = () => {
    navigate("/admin/signin");
  };

  return (
    <div className="flex items-center">
      {stage ? (
        <EditAdminPwd findAdminId={findAdminId} />
      ) : (
        <div className=" mb-16 flex h-[585px] w-[416px] flex-col items-center ">
          <CommonSquareBox>
            <img
              src="/images/admin/icon/힐리어리비밀번호찾기.svg"
              alt="힐리어리 비밀번호찾기 이미지"
              className="mb-[37px] items-center"
            />
            <div className="flex flex-col items-end gap-11">
              <div className="flex flex-col items-end gap-[20px]">
                <LabelAdminInput
                  title={"이름"}
                  name={"name"}
                  type={"text"}
                  value={findInput.name}
                  placeholderVal={"이름을 입력하세요"}
                  inputcss={
                    checkNameFc
                      ? "w-[360px]" + normalInputRing
                      : "w-[360px]" + errorInputRing
                  }
                  onChangeAction={(e) => onChangeFind(e)}
                  onKeyPressAction={FindPwdIn}
                  checkCss={!checkNameFc ? errorMsgColor : ""}
                  checkVal={!checkNameFc && "이름 형식을 체크해주세요."}
                />
                <LabelAdminInput
                  title={"아이디"}
                  name={"adminId"}
                  type={"text"}
                  value={findInput.name}
                  placeholderVal={"아이디를 입력하세요"}
                  inputcss={"w-[360px]" + normalInputRing}
                  onChangeAction={(e) => onChangeFind(e)}
                  onKeyPressAction={FindPwdIn}
                />
                <LabelAdminInput
                  title={"이메일 주소"}
                  name={"email"}
                  type={"text"}
                  value={findInput.email}
                  placeholderVal={"이메일 주소를 입력해주세요"}
                  inputcss={
                    checkEmailFc
                      ? "w-[360px]" + normalInputRing
                      : "w-[360px]" + errorInputRing
                  }
                  onChangeAction={(e) => onChangeFind(e)}
                  onKeyPressAction={FindPwdIn}
                  checkCss={!checkEmailFc ? errorMsgColor : normalMsgColor}
                  checkVal={
                    !checkEmailFc
                      ? "이메일 형식에 맞게 작성해주세요."
                      : "가입시 등록했던 이메일 주소를 입력해주세요."
                  }
                />
              </div>
              <div className="flex flex-col items-start gap-2  self-stretch">
                <BGButtonCustom
                  onClick={checkInputStringNone ? undefined : FindPwdIn}
                  content={"다음"}
                  bgcolor={
                    checkInputStringNone ? "bg-orange_80" : "bg-orange_100"
                  }
                  textcolor={"text-gray_10"}
                  textsize={"text-caption1_600"}
                />
                <BGButtonCustom
                  onClick={goSignin}
                  content={"홈으로"}
                  bgcolor={"bg-gray_120"}
                  textcolor={"text-gray_10"}
                  textsize={"text-caption1_600"}
                />
              </div>
            </div>
          </CommonSquareBox>
        </div>
      )}
    </div>
  );
};

export default FindPwd;
