import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const makeRecommendMeal = createAsyncThunk(
  "recommand/makeRecommendMeal", //식단 만들기
  async (params) => {
    const res = await api.post(`/eat/makeRecommendMeal`, params);
    return res.data;
  }
);

export const fetchRecommendMeal = createAsyncThunk(
  "recommand/fetchRecommendMeal", //식단 뿌리기
  async (params) => {
    const res = await api.post(`/eat/getRecommendMealLast`, params);
    return res.data;
  }
);

export const fetchRecommendMealForShare = createAsyncThunk(
  "recommand/fetchRecommendMealForShare", //공유용 식단 뿌리기
  async (params) => {
    const res = await api.get(`/eat/getRecommendMeal/${params}`);
    return res.data;
  }
);

//식단 기능
export const onSetLikeDay = createAsyncThunk(
  "recommand/setLikeDay", //식단 좋아요
  async (params) => {
    const res = await api.post(`/eat/setLikeDay`, params);
    return res.data;
  }
);

export const onSetBookmarkMeal = createAsyncThunk(
  "recommand/setBookmarkMeal", //식단 즐겨찾기
  async (params) => {
    const res = await api.post(`/eat/setBookmarkMeal`, params);
    return res.data;
  }
);

//음식 기능
export const onSetLikeFood = createAsyncThunk(
  "recommand/setLikeFood", //음식 좋아요
  async (params) => {
    const res = await api.post(`/eat/setLikeFood`, params);
    return res.data;
  }
);

export const onSetBookmarkFood = createAsyncThunk(
  "recommand/setBookmarkFood", //음식 즐겨찾기
  async (params) => {
    const res = await api.post(`/eat/setBookmarkFood`, params);
    return res.data;
  }
);

//레시피 기능
export const onSetBookmarkRecipe = createAsyncThunk(
  "recommand/setBookmarkRecipe", //레시피 즐겨찾기
  async (params) => {
    const res = await api.post(`/eat/setBookmarkRecipe`, params);
    return res.data;
  }
);

export const fetchFoodRecipe = createAsyncThunk(
  "recommand/fetchFoodRecipe", //레시피 조회
  async (params) => {
    const res = await api.post(`/food/getFoodRecipe`, params);
    return res.data;
  }
);

export const fetchFoodDetail = createAsyncThunk(
  "recommand/fetchFoodDetail",
  async (params) => {
    const res = await api.post(`/eat/getRecommendMealFood`, params);
    return res.data;
  }
);

export const fetchFoodInfo = createAsyncThunk(
  "recommand/fetchFoodInfo", //마이페이지 좋아요와 싫어요용 음식 세부 정보
  async (params) => {
    const res = await api.post(`/food/getFoodInfo`, params);
    return res.data;
  }
);

export const fetchSimilarFoodSearch = createAsyncThunk(
  "recommand/fetchSimilarFoodSearch", //메뉴 바꾸기
  async (params) => {
    const res = await api.post(`/food/similarFoodSearch`, params);
    return res.data;
  }
);

export const changeRecommendFood = createAsyncThunk(
  "recommand/changeRecommendFood",
  async (params) => {
    const res = await api.post(`/eat/changeRecommendFood`, params);
    return res.data;
  }
);

export const searchFoodRecipe = createAsyncThunk(
  "recommend/searchFoodRecipe",
  async (params) => {
    const res = await api.post(`/food/searchFoodRecipeNew`, params);
    return res.data;
  }
);

export const getBanner = createAsyncThunk(
  "recommend/getBanner",
  async (params) => {
    const res = await api.post(`banner/getBanner`, { menu: 1 });
    return res.data;
  }
);

const initialState = {
  recommendMealLists: [],
  guideLists: [],
  mealComposition: {},
  recipeListsStatus: null,
  recipeLists: null,
  recommendListsForShare: [],
  recommendMealListsStatus: null,
  recommendForShareStatus: null,
  foodDetailStatus: null,
  foodDetail: null,
  isExistRecipe: null,
  similarFoodLists: [],
  recipeFoodList: [],
  recipePageCount: null,
  recipeTotalPageCount: null,
  recipeSearchedValue: null,
  recipeSearchPage: null,
};

const recommendSlice = createSlice({
  name: "추천식단",
  initialState,
  reducers: {
    onResetFoodDetail: (state) => {
      state.foodDetail = null;
    },
    onResetSimilarFoodLists: (state) => {
      state.similarFoodLists = [];
    },
    onSetRecipeFoodList: (state, action) => {
      state.recipeFoodList = action.payload;
    },
    onSetPageCount: (state, action) => {
      state.recipePageCount = {
        currentPage: action.payload.currentPage,
        totalPage: action.payload.totalPage,
      };
    },
    onSetSearchedValue: (state, action) => {
      state.recipeSearchedValue = action.payload;
    },
    onSetSearchPage: (state, action) => {
      state.recipeSearchPage = action.payload;
    },
    onChangePage: (state, action) => {
      state.recipeFoodList = action.payload.foodList;
      state.recipePageCount = action.payload.pageCount;
      state.recipeSearchedValue = action.payload.searchedValue;
      state.recipeSearchPage = action.payload.searchPage;
    },
    onResetRecipePage: (state) => {
      state.recipeFoodList = [];
      state.recipePageCount = null;
      state.recipeSearchedValue = null;
      state.recipeSearchPage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchRecommendMeal.pending, (state) => {
      state.recommendMealListsStatus = "pending";
    });
    builder.addCase(fetchRecommendMeal.fulfilled, (state, action) => {
      state.recommendMealListsStatus = "success";
      state.recommendMealLists = action.payload.recommendMealHs;
      state.guideLists = action.payload.eatGuides;
      state.mealComposition = action.payload.mealComposition;
    });
    builder.addCase(fetchRecommendMeal.rejected, (state) => {
      state.recommendMealListsStatus = "rejected";
    });
    builder.addCase(fetchRecommendMealForShare.pending, (state) => {
      state.recommendForShareStatus = "pending";
    });
    builder.addCase(fetchRecommendMealForShare.fulfilled, (state, action) => {
      state.recommendForShareStatus = "success";
      state.recommendListsForShare = action.payload.recommendMealHs;
    });
    builder.addCase(fetchRecommendMealForShare.rejected, (state) => {
      state.recommendForShareStatus = "rejected";
    });
    builder.addCase(fetchFoodRecipe.pending, (state) => {
      state.recipeListsStatus = "pending";
    });
    builder.addCase(fetchFoodRecipe.fulfilled, (state, action) => {
      state.recipeListsStatus = "success";
      state.recipeLists = action.payload.recipe;
    });
    builder.addCase(fetchFoodDetail.pending, (state) => {
      state.foodDetailStatus = "pending";
    });
    builder.addCase(fetchFoodDetail.fulfilled, (state, action) => {
      state.foodDetailStatus = "success";
      state.foodDetail = action.payload.recommendMealDetail;
      state.isExistRecipe = action.payload.isExistRecipe;
    });
    builder.addCase(fetchFoodDetail.rejected, (state) => {
      state.foodDetailStatus = "rejected";
    });
    builder.addCase(fetchFoodInfo.pending, (state) => {
      state.foodDetailStatus = "pending";
    });
    builder.addCase(fetchFoodInfo.fulfilled, (state, action) => {
      state.foodDetailStatus = "success";
      state.foodDetail = action.payload.foodDetail;
      state.isExistRecipe = action.payload.isExistRecipe;
    });
    builder.addCase(fetchFoodInfo.rejected, (state) => {
      state.foodDetailStatus = "rejected";
    });
    builder.addCase(fetchSimilarFoodSearch.fulfilled, (state, action) => {
      state.similarFoodLists = action.payload.foodList;
    });
  },
  // {
  //     [fetchRecommendMeal.pending]: (state) => { state.recommendMealListsStatus = "pending" },
  //     [fetchRecommendMeal.fulfilled]: (state, action) => {
  //         state.recommendMealListsStatus = "success";
  //         state.recommendMealLists = action.payload.recommendMealHs;
  //         state.guideLists = action.payload.eatGuides;
  //         state.mealComposition = action.payload.mealComposition;
  //     },
  //     [fetchRecommendMeal.rejected]: (state) => { state.recommendMealListsStatus = "rejected" },
  //     [fetchRecommendMealForShare.pending]: (state) => { state.recommendForShareStatus = "pending" },
  //     [fetchRecommendMealForShare.fulfilled]: (state, action) => {
  //         state.recommendForShareStatus = "success";
  //         state.recommendListsForShare = action.payload.recommendMealHs;
  //     },
  //     [fetchRecommendMealForShare.rejected]: (state) => { state.recommendForShareStatus = "rejected" },
  //     [fetchFoodRecipe.fulfilled]: (state, action) => {
  //         state.recipeLists = action.payload.recipe;
  //     },
  //     [fetchFoodDetail.pending]: (state) => { state.foodDetailStatus = "pending" },
  //     [fetchFoodDetail.fulfilled]: (state, action) => {
  //         state.foodDetailStatus = "success";
  //         state.foodDetail = action.payload.recommendMealDetail;
  //         state.isExistRecipe = action.payload.isExistRecipe;
  //     },
  //     [fetchFoodDetail.rejected]: (state) => { state.foodDetailStatus = "rejected" },
  //     [fetchFoodInfo.pending]: (state) => { state.foodDetailStatus = "pending" },
  //     [fetchFoodInfo.fulfilled]: (state, action) => {
  //         state.foodDetailStatus = "success";
  //         state.foodDetail = action.payload.foodDetail;
  //         state.isExistRecipe = action.payload.isExistRecipe;
  //     },
  //     [fetchFoodInfo.rejected]: (state) => { state.foodDetailStatus = "rejected" },
  //     [fetchSimilarFoodSearch.fulfilled]: (state, action) => { state.similarFoodLists = action.payload.foodList },
  // },
});

export const {
  onResetFoodDetail,
  onResetSimilarFoodLists,
  onSetRecipeFoodList,
  onSetPageCount,
  onSetSearchedValue,
  onSetSearchPage,
  onChangePage,
  onResetRecipePage,
} = recommendSlice.actions;

export default recommendSlice.reducer;
