import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onSetAccountTab } from "../../../../features/admin/accountSlice";
import {
  onSetSection,
  onSetSubSection,
} from "../../../../features/admin/commonSlice";
import {
  onSetCommTab,
  onSetToggleCommunity,
} from "../../../../features/admin/communitySlice";
import { onSetContTab } from "../../../../features/admin/contentsSlice";

const SideInnerTitle = ({ item, navBooleen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { section } = useSelector((state) => state.common);
  const onSetNav = (title, link) => {
    dispatch(onSetToggleCommunity(false)); //toggle off
    if (title === "컨텐츠 관리") {
      dispatch(onSetContTab("종합 가이드"));
      dispatch(onSetSubSection("종합 가이드"));
    } else if (title === "게시판 관리") {
      dispatch(onSetCommTab("소식"));
      dispatch(onSetSubSection("소식"));
    } else if (title === "계정 관리") {
      dispatch(onSetAccountTab("회원"));
      dispatch(onSetSubSection("회원"));
    }
    dispatch(onSetSection(title));
    navigate(link);
  };

  const repeatDiv = (navBoolean) => {
    const commoncss =
      " flex items-center my-4 cursor-pointer rounded-md transition duration-700 ease-in-out hover:bg-orange_10 p-[12px] ";
    const repeatImageTitle = () => {
      return (
        <>
          <img
            alt="메뉴 아이콘"
            src={
              section === item.title
                ? `/images/admin/icon/${item.title}_active.svg`
                : `/images/admin/icon/${item.title}.svg`
            }
          />
          {!navBoolean && (
            <h2
              className={
                "text-body_2 font-semibold " +
                (section === item.title ? "text-black " : "text-gray_140")
              }
            >
              {item.title}
            </h2>
          )}
        </>
      );
    };
    return (
      <>
        {item.title === "대시 보드" ? (
          <a
            href={item.realLink}
            target="_blank"
            rel="noopener noreferrer"
            className={
              commoncss +
              (section === item.title ? " bg-orange_10 " : " bg-white ") +
              (navBoolean ? " justify-center " : " gap-2 ")
            }
          >
            {repeatImageTitle()}
          </a>
        ) : (
          <div
            onClick={() => onSetNav(item.title, item.link)}
            className={
              commoncss +
              (section === item.title ? " bg-orange_10 " : " bg-white ") +
              (navBoolean ? " justify-center " : " gap-2 ")
            }
          >
            {repeatImageTitle()}
          </div>
        )}
      </>
    );
  };
  return <>{repeatDiv(navBooleen)}</>;
};

export default SideInnerTitle;
