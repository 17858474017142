import React from "react";
import { ReactComponent as More } from "../../../../svg/arrow_right_com.svg";

const RecommendCard = ({ id, list, onSetOneFood }) => {
  const meals = list.recommendMeals;

  return (
    <div className="mt-4 space-y-3">
      {meals &&
        meals.map((meal) => (
          <div
            id={id}
            key={meal.id}
            className={
              "rounded-2xl px-4 py-3 " +
              (meal.mealType.includes("S")
                ? "bg-[#E6F0FC] "
                : meal.mealType === "B"
                ? "bg-yellow_10 "
                : meal.mealType === "L"
                ? "bg-orange_10 "
                : "bg-green_10 ") +
              (meal.detailList?.length > 0 ? "block" : "hidden")
            }
          >
            {meal.detailList?.length > 0 ? (
              <>
                <h1 className="mb-1 text-black text-caption1_700">
                  {meal.mealType.includes("S")
                    ? "간식"
                    : meal.mealType === "B"
                    ? "아침"
                    : meal.mealType === "L"
                    ? "점심"
                    : "저녁"}
                </h1>
                <ul className="flex flex-col gap-[10px]">
                  {meal.detailList.map((detail) => (
                    <div key={detail.id} className="flex items-center gap-3">
                      <span>
                        {detail.foodType
                          ? detail.foodType.includes("밥")
                            ? "🍚"
                            : detail.foodType.includes("국")
                            ? "🍲"
                            : detail.foodType.includes("단백질")
                            ? "🍖"
                            : detail.foodType.includes("채소")
                            ? "🥗"
                            : detail.foodType.includes("김치")
                            ? "🥬"
                            : "🍎"
                          : "🍎"}
                      </span>
                      <div
                        onClick={() => {
                          // 음식클릭 GA 이벤트
                          window.dataLayer.push({
                            event: "view_food_name",
                            food_id: detail.foodId,
                            food_name: detail.foodName,
                          });

                          onSetOneFood(
                            detail.id,
                            detail.foodId,
                            meal.mealDate,
                            meal.mealType
                          );
                        }}
                        className="flex justify-between flex-1 p-3 bg-white rounded-xl"
                      >
                        {/* <h2 className="text-body3_700 text-yellow_160"> */}
                        <h2
                          // className={
                          //   meal.mealType.includes("S")
                          //     ? "text-body3_700 text-[#2B58AD]"
                          //     : "text-body3_700 text-yellow_160"
                          // }
                          className="text-black text-body3_700"
                        >
                          {detail.foodName.length > 7
                            ? detail.foodName.slice(0, 7) + "..."
                            : detail.foodName}
                        </h2>
                        <div className="flex items-center gap-1">
                          <h2 className="text-caption2_400 text-gray_80">
                            {detail.eyemesurementnumber}{" "}
                            {detail.eyemesurementunit}
                          </h2>
                          <More
                            fill={
                              meal.mealType.includes("S")
                                ? "#5AC2E4"
                                : "#417FFF"
                            }
                            className="w-5 h-5"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
          </div>
        ))}
    </div>
  );
};

export default RecommendCard;
