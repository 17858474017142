import React, { useState } from "react";
import { useSelector } from "react-redux";
import Parser from "html-react-parser";
import Dialog from "./Dialog";

const TitleBox = ({
  option,
  title,
  subTitle,
  subTitle2,
  onAction,
  font,
  textColor,
  saveAnswereDatas,
}) => {
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { gettingAnswers } = useSelector((state) => state.questionHealth);
  const [openDialog, setOpenDialog] = useState(false);
  const onClickOpenDialog = (state) => setOpenDialog(state);

  return (
    <div className="">
      {option === 1 ? (
        <h1 className="text-body1_700 text-black">{title}</h1>
      ) : option === 2 ? (
        <p className="text-[20px] font-extrabold text-gray_180">{title}</p>
      ) : option === 3 ? (
        <div className="sticky top-14 bg-back_ground">
          <div
            className={
              "text-subtitle3 text-black" +
              (subTitle2 !== "" ? " flex gap-x-4" : "")
            }
          >
            <p>
              {title && saveAnswereDatas
                ? Parser(
                    title.replace(
                      "{nickName}",
                      `${
                        saveAnswereDatas !== null &&
                        saveAnswereDatas.userType === "UT100"
                          ? loginInfo.nickName
                          : saveAnswereDatas !== null &&
                            saveAnswereDatas.patientNickName
                      }`
                    )
                  )
                : title && gettingAnswers !== null
                ? Parser(
                    title.replace(
                      "{nickName}",
                      `${
                        gettingAnswers !== null &&
                        gettingAnswers.userType === "UT100"
                          ? loginInfo.nickName
                          : gettingAnswers !== null &&
                            gettingAnswers.patientNickName
                      }`
                    )
                  )
                : ""}
            </p>
            {subTitle2 && subTitle2 !== "" && (
              <img
                src={"/images/mobile/icon/modal.svg"}
                alt="modal 아이콘"
                onClick={() => onClickOpenDialog(true)}
              />
            )}
          </div>

          <span className="text-caption1_400 text-gray_160">
            {subTitle ? Parser(subTitle) : ""}
          </span>
        </div>
      ) : option === 4 ? (
        <p className="text-body3_700 text-gray_160">{title}</p>
      ) : option === 5 ? (
        <h1 className="text-subtitle1 text-gray_180">
          {title ? Parser(title) : ""}
        </h1>
      ) : option === 6 ? (
        <div className="space-y-2">
          <h1 className="text_black text-body2_700">{title}</h1>
          {subTitle && subTitle === "오늘의 목표도달을 축하합니다!" ? (
            <p className="text-caption2_400 text-orange_160">{subTitle}</p>
          ) : (
            <p className="text-caption2_400 text-gray_160">{subTitle}</p>
          )}
        </div>
      ) : option === 7 ? (
        <div className="mx-6 my-[22px] flex justify-between">
          <h1 className="text-body3_700 text-gray_160">{title}</h1>
          <img
            onClick={onAction}
            src="/images/mobile/icon/close.svg"
            alt="닫기 아이콘"
          />
        </div>
      ) : (
        <h1 className={`${font} ${textColor}`}>{title}</h1>
      )}
      {openDialog && (
        <Dialog
          innerClass={"p-6"}
          btnClass={"mt-7"}
          desc={subTitle2}
          onRightBtnAction={() => onClickOpenDialog(false)}
          onCoverAction={() => onClickOpenDialog(false)}
        />
      )}
    </div>
  );
};

export default TitleBox;
