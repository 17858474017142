import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import {
  addReply,
  deleteReply,
  getAllRepliesOfOnePosting,
  handleReplySliceArr,
} from "../../../../features/admin/communitySlice";
import {
  toggleBoardModal,
  toggleCommuHide,
} from "../../../../features/admin/toggleSlice";
import ProfilePicture from "../../../mobile/components/common/ProfilePicture";
import {
  BGButtonCustom,
  BorderButtonCustom,
  CommentBtn,
} from "../common/ButtonCustom";

import CustomCenterModal from "../common/CustomCenterModal";
const exit = "/images/admin/icon/exit.svg";
const CommuReplyLayout = ({ reply, childui, postingId }) => {
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const pageReplyCount = 5;
  const { page } = useSelector((state) => state.common);

  const updatedDayJS = dayjs(reply.updatedAt);
  const replyDate =
    updatedDayJS.format("YYYY-MM-DD") +
    " " +
    updatedDayJS.format("A") +
    " " +
    (updatedDayJS.format("HH") > 12
      ? updatedDayJS.format("HH") - 12
      : updatedDayJS.format("HH")) +
    ":" +
    updatedDayJS.format("MM");
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  // ho 상태에 따라 수정화면 출력 미출력
  const [editState, setEditState] = useState(false);
  // ho 댓글 수정내용 입력 저장 -> 기본값이 페이지 나갔다오면 초기화 되길래 reply.content로 값이 비는걸 방지함.
  const [editNewsInput, setEditNewsInput] = useState(reply.content);

  const deleteConfirm = () => {
    setConfirm(!confirm);
  };
  // console.log(reply.writerAdmin.name, "reply.writerAdminAdminId");
  // console.log(loginInfo.name, "loginInfo.name");
  // console.log(
  //   loginInfo.name === reply.writerAdmin.name,
  //   "loginInfo.name === reply.writerAdmin.name"
  // );

  // 댓글 삭제하기
  const deleteData = async (reply) => {
    const res = await dispatch(deleteReply({ id: reply.id })).unwrap();
    try {
      if (res.ok) {
        toast(
          <p>댓글 삭제되었습니다.</p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
        //show EachModal
        dispatch(toggleBoardModal(true));
        const param = {
          postingId: postingId,
          userId: loginInfo?.adminId,
        };
        try {
          const getPostingRes = await dispatch(
            getAllRepliesOfOnePosting(param)
          ).unwrap();
          if (getPostingRes.ok) {
            //페이지 오픈시 공개 비공개여부 초깃값 설정
            dispatch(toggleCommuHide(getPostingRes.posting.isHide));
            if (
              getPostingRes.totalReplies === undefined ||
              getPostingRes.totalReplies?.length === 0
            ) {
              dispatch(handleReplySliceArr([]));
            } else {
              const slicePages = getPostingRes.totalReplies.slice(
                (page - 1) * pageReplyCount,
                page * pageReplyCount
              );
              dispatch(handleReplySliceArr(slicePages));
            }
          } else {
            console.log(getPostingRes);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ho 댓글 수정하기
  const editData = async (reply) => {
    let param = {
      // writerUserId: loginInfo?.adminId,
      id: reply.id,
      content: editNewsInput,
    };
    const res = await dispatch(addReply(param)).unwrap();
    try {
      if (res.ok) {
        toast(
          <p>댓글 수정되었습니다.</p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
        //show EachModal
        dispatch(toggleBoardModal(true));

        const param = {
          postingId: postingId,
          userId: loginInfo?.adminId,
        };
        try {
          const getPostingRes = await dispatch(
            getAllRepliesOfOnePosting(param)
          ).unwrap();
          if (getPostingRes.ok) {
            //페이지 오픈시 공개 비공개여부 초깃값 설정
            dispatch(toggleCommuHide(getPostingRes.posting.isHide));
            if (
              getPostingRes.totalReplies === undefined ||
              getPostingRes.totalReplies?.length === 0
            ) {
              dispatch(handleReplySliceArr([]));
            } else {
              const slicePages = getPostingRes.totalReplies.slice(
                (page - 1) * pageReplyCount,
                page * pageReplyCount
              );
              dispatch(handleReplySliceArr(slicePages));
            }
          } else {
            console.log(getPostingRes);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {confirm && (
        <CustomCenterModal
          onClickFc={deleteConfirm}
          customlayout={"bg-white rounded-xl p-[24px] w-[400px]"}
        >
          <div className="flex w-full justify-end">
            <img
              src={exit}
              alt="exit"
              onClick={deleteConfirm}
              className="block h-[16px] w-[16px] cursor-pointer"
            />
          </div>
          <div className="flex justify-center py-4">
            정말로 댓글을 삭제하시겠습니까?
          </div>
          <div className="flex justify-center gap-x-2 py-2">
            <BGButtonCustom
              onClick={() => {
                deleteData(reply);
                deleteConfirm(false);
              }}
              width={100}
              content={"확인"}
              bgcolor={"bg-orange_100"}
              textcolor={"text-gray_10"}
              textsize={"text-caption1_600"}
            />
            <BorderButtonCustom
              onClick={deleteConfirm}
              width={100}
              content={"취소"}
              bgcolor={""}
              textcolor={"text-primary_100"}
              bordercolor={"border-primary_100"}
              textsize={"text-body_2"}
              xpadding={"12px"}
            />
          </div>
        </CustomCenterModal>
      )}

      <div className={"border-b px-10 "}>
        <div className="my-1 flex min-h-[40px] w-full flex-col gap-[4px] px-6 py-4 ">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center">
              <ProfilePicture location="post" writer={reply.writer} />
              <span
                className={
                  "flex items-center justify-center px-[12px] py-[6px] text-body2_700 " +
                  (childui ? " text-gray_160 " : " text-orange_140 ")
                }
              >
                {reply.writer === null
                  ? reply.writerAdminAdminId
                  : reply.writer.nickName}
              </span>
              {reply?.report?.length > 0 && (
                <span className="font-bold text-red-500">신고된 댓글</span>
              )}
            </div>
            <div className="flex flex-col items-end">
              <img
                src={exit}
                alt="exit"
                onClick={deleteConfirm}
                className=" block h-[16px] w-[16px] cursor-pointer"
              />
              {loginInfo.name === reply.writerAdmin?.name ? (
                <div className="flex justify-end pt-6">
                  {editState === false ? (
                    <CommentBtn
                      onClickAction={() => setEditState(true)}
                      title="수정하기"
                      bgcolor="bg-white"
                      txtcolor="text-orange_100"
                      bordercolor="border border-orange_100"
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div className="mx-[12px] flex max-w-[600px] items-center whitespace-pre-wrap text-body1_500 text-gray_180">
            <div>
              <span className="pr-2 text-gray_140">
                {reply.tagNickName ? "@" + reply.tagNickName : ""}
              </span>
              <p className="break-all">{reply.content}</p>
            </div>
          </div>

          {/* ho 댓글 수정 */}
          {editState && (
            <div className="flex w-full items-start justify-center gap-3 rounded-[4px] p-5">
              <p className="text-body_1 flex w-[90px] items-center justify-center font-semibold text-black ">
                댓글수정
              </p>
              <div className="flex items-end gap-[8px]">
                <textarea
                  onChange={(e) => {
                    setEditNewsInput(e.target.value);
                  }}
                  placeholder="댓글 내용을 입력하세요"
                  className={
                    "flex h-[300px] w-[440px] flex-row items-start gap-[16px] overflow-y-scroll rounded-[4px] border border-[#A39F9B] bg-[#FCFCFC] px-[20px] py-[12px] text-sm outline-none"
                  }
                  value={editNewsInput}
                />

                <div className="flex flex-col gap-y-3 ">
                  <CommentBtn
                    // onClickAction={editNewsInput !== "" ? submitReply : undefined}
                    title="확인"
                    bgcolor={
                      editNewsInput !== "" ? "bg-orange_100" : "bg-orange_60"
                    }
                    txtcolor="text-white"
                    onClickAction={() => {
                      editData(reply);
                      setEditState(false);
                    }}
                  />
                  <CommentBtn
                    // onClickAction={showReply}
                    title="취소"
                    bordercolor="border border-orange_100"
                    txtcolor="text-orange_100"
                    onClickAction={() => setEditState(false)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="w-full text-right text-gray_140">{replyDate}</div>
        </div>
      </div>
    </>
  );
};

export default CommuReplyLayout;
