import React from "react";
import Parser from "html-react-parser";

const PersonalInfo = () => {
  const datas = [
    {
      id: 1,
      title: "제1조 총칙",
      contents: `1. 개인정보란 생존하는 개인에 관한 정보로서 성명, 주민등록번호 등에 의하여 당해 개인을 알아볼 수 있는 부호, 문자, 음성, 음향, 영상 및 생체 특성 등에 관한 정보(당해 정보만으로는 특정 개인을 알아볼 수 없는 경우에도 다른 정보와 용이하게 결합하여 알아볼 수 있는 것을 포함)를 말합니다.<br/>        2. 주식회사 헤링스(이하 "회사"라 한다)는 회원의 개인정보를 중요시하며, 개인정보 보호 관련 각종 법규를 준수하고 있습니다.<br/>
        3. 회사는 개인정보처리방침을 통하여 회원의 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.<br/>
        4. 회사의 개인정보처리방침은 관련 법령 및 내부 운영 방침의 변경에 따라 개정될 수 있습니다. 개인정보처리방침이 개정되는 경우에는 시행일자 등을 부여하여 개정된 내용을 홈페이지<a className='font-bold' href='http://heringsglobal.com'>(http://heringsglobal.com)</a> 공지사항 등에 지체 없이 공지합니다.<br/>
        5. 영업의 전부 또는 일부를 양도하거나 합병 등으로 개인정보를 이전하는 경우 서면 전자우편 등을 통하여 회원께 개별적으로 통지하고, 회사의 과실 없이 회원의 연락처를 알 수 없는 경우에 해당하여 서면, 전자우편 등으로 통지할 수 없는 경우에는 홈페이지<a className='font-bold' href='http://heringsglobal.com'>(http://heringsglobal.com)</a> 공지사항 등에서 식별할 수 있도록 표기하여 30일 이상 그 사실을 공지합니다. 단, 천재지변 등 정당한 사유로 홈페이지 게시가 곤란한 경우에는 2곳 이상의 중앙일간지(회원의 대부분이 특정 지역에 거주하는 경우에는 그 지역을 보급구역으로 하는 일간지로 할 수 있습니다.)에 1회 이상 공고하는 것으로 갈음합니다.<br/>
        `,
      table: false,
    },
    {
      id: 2,
      title: "제2조 개인정보의 수집항목 및 수집/이용 목적",
      contents: `1. 회사는 회원가입, 상담, '힐리어리' 서비스(이하 "서비스"라 한다.) 제공 등을 위하여 필요한 범위에서 최소한의 개인정보만을 수집합니다.<br/>
            2. 사상, 신념, 가족 및 친인척관계, 학력(學歷)·병력(病歷), 기타 사회활동 경력 등 회원의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 개인정보는 수집하지 않습니다. 다만, 회원이 수집에 동의하시거나 다른 법률에 따라 특별히 수집 대상 개인정보로 허용된 경우에는 필요한 범위에서 최소한으로 위 개인정보를 수집할 수 있습니다.<br/>
            3. 회사는 최초 회원 가입 또는 서비스 이용 시 회원으로부터 아래와 같은 개인정보를 수집하며, 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/>
            개인정보처리방침과 서비스 내 개인정보 수집ㆍ이용 동의와 상충되는 부분이 있다면 서비스 내 개인정보 수집ㆍ이용 동의서의 내용이 우선합니다.
        `,
      table: true,
      tableHead1: "구분",
      tableHead2: "처리항목",
      tableHead3: "처리목적",

      tableMain: {
        tableTitle: [
          "회원가입(개인) 본인인증으로 가입",
          "나의 질병정보",
          "나의 신체, 식습관 정보",
          "커뮤니티 이용",
          "서비스 이용 시 자동 생성되는 정보",
          "이벤트 응모 및 상담신청",
        ],

        tableContent: [
          "(필수) 이름, 나이, 성별, 휴대전화번호, 사용자의 분류, 필요한 서비스의 분류",
          " 암의 치료 단계, 암종, 암의 병기, 수술 여부, 수술 종류, 수술 날짜, 항암치료 여부, 항암치료 종류, 항암치료 차수, 투약 항암제, 항암제 투약 경로, 치료 의료기관, 만성질환 이환, 기타 약물 복용, 일주일 간 발생하는 신체증상",
          "키, 체중, 식사시 문제점, 알레르기 여부, 유당불내증 여부, 섭취 가능한 유제품 여부, 하루 식사 횟수(아침 식사 여부), 하루 간식 횟수, 식사 담당 여부, 건강기능식품 섭취, 흡연, 음주, 식사 섭취 속도, 음식 선호도, 식사, 간식 섭취 기록, 수분섭취, 기분",
          "게시글",
          "서비스 이용 기록, 접속 로그, 쿠키, 접속IP 주소, 기기고유번호(등록번호, 디바이스 아이디 또는 IMEI), 불량 이용 기록, 광고식별자, 사용 이동통신사",
          `(필수) 이름, 휴대전화번호 <br/>
           (선택) 성별, 생년월일`,
        ],
        tablePurpose: [
          "회원 가입의사 확인, 식별∙인증, 회원자격 유지∙관리, 본인확인, 서비스 부정이용 확인 및 방지, 각종 고지∙통지, 고충처리, 서비스 제공 및 상담, 만 14세 이상 확인, 만족도 조사",
          "건강관리 등 제반 서비스 이용 시 맞춤형 건강관리 서비스 제공",
          "건강관리 등 제반 서비스 이용 시 맞춤형 건강관리 서비스 제공",
          "회원의 서비스 만족도 평가, 회원 정보 통계 및 분석",
          "서비스/상품 이용 정보 통계 및 분석",
          " 이벤트 및 혜택 알림 정보 등 마케팅 ∙ 광고 활용, 서비스/신상품이나 이벤트 정보 안내, 이벤트 경품 배송 ",
        ],
      },

      additionalTitle: `
      ※ 위 항목은 가입 당시 정보 및 앱 사용 시 수집된 정보와 수정으로 변경된 정보를 모두 포함합니다.<br/>
      4. 서비스는 아래의 경우에 한하여 회원님의 동의 없이 회원님의 개인정보를 수집ㆍ이용할 수 있습니다.<br/>
        가. 회원님과의 계약을 이행하기 위하여 필요한 개인정보로서 경제적ㆍ기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우<br/>
        나. 서비스의 제공에 따른 요금정산을 위하여 필요한 경우<br/>
        다. 관련 법령에 특별한 규정이 있는 경우<br/>
        `,
    },
    {
      id: 3,
      title: "제3조 개인정보의 수집방법",
      contents: `회사는 다음과 같은 방법으로 개인정보를 수집합니다.<br/>
            가. 가입신청서, 명의변경 신청서 등 각종 서면 작성, 애플리케이션/홈페이지/전화/Fax 등을 통한 회원/서비스 가입, 전화나 인터넷을 통한 상담, 경품 행사 응모, 배송 요청 등의 과정에서 수집<br/>
            나. 서비스를 이용 함으로써 자동 수집(서비스 이용 시 기기로부터 측정되거나 자동 생성되는 정보도 포함)<br/>
            다. 서비스 가입이나 사용 중 회원의 자발적 제공을 통한 수집<br/>
            라. 서비스 이용 중 개인정보 추가 수집에 대한 동의를 통한 수집<br/>
            마. 제휴사나 제3자로부터의 서비스 이벤트 응모를 통한 수집<br/>  
            `,
      table: false,
    },
    {
      id: 4,
      title: "제4조 개인정보의 보관 및 이용기간",
      contents: `1. 회사는 회원의 개인정보를 서비스를 제공하는 기간 또는 분쟁처리 기간 동안에 한하여 보관하고 이를 이용합니다.<br/>
            가. 개인정보 보관기간(가입일 ~ 해지 후 12개월간)<br/>
            나. 서비스 이용과 관련된 민원/분쟁이 계속될 경우에는 민원/분쟁 해결 시까지 보관(단, 관계 법령에 특별한 규정이 있을 경우 관련 법령에 따라 보관)<br/>
            다. 회원의 요청에 의해 개인위치정보를 이용하여 서비스를 제공하였을 경우 증빙 등을 목적으로 서비스 제공에 대한 자료를 해지일로부터 12개월간 보유.<br/>
            2. 상법, 국세기본법, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률, 신용정보의 이용 및 보호에 관한 법률 등 관계법령에 따라 보존할 필요가 있는 경우 회사는 관계법령에서 정한 기간 동안 회원의 개인정보를 보관하며, 그 기간은 다음과 같습니다.<br/>
            `,
      table: true,
      tableHead1: "보존 근거",
      tableHead2: "보존 대상",
      tableHead3: "보존 기간",

      tableMain: {
        tableTitle: [
          "상법",
          "국세기본법",
          "통신비밀보호법",
          "전자상거래 등에서의 소비자보호에 관한 법률",
          "신용정보의 이용 및 보호에 관한 법률",
          "이벤트 응모 및 상담신청",
        ],

        tableContent: [
          `상업장부와 영업에 관한 중요서류에 포함된 개인정보
          전표 또는 이와 유사한 서류에 포함된 개인정보`,
          "성명, 주민번호, 전화번호, 주소 등 거래에 관한 장부 및 증거서류",
          `통신사실확인자료 제공에 필요한 성명, 주민번호, 전화번호 및 가입자의 전기통신일시, 전기통신개시ㆍ종료시간, 발ㆍ착신 통신번호 등 상대방의 가입자번호, 사용도수, 발신기지국의 위치정보
           컴퓨터통신 또는 인터넷의 로그기록자료, 정보통신기기의 위치를 확인할 수 있는 접속지의 추적자료`,
          ` 계약 또는 청약철회 등에 관한 기록 
            대금결제 및 재화 등의 공급에 관한 기록 
            소비자의 불만 또는 분쟁처리에 관한 기록
            표시/광고에 관한 기록 `,
          "신용정보의 수집/처리 및 이용 등에 관한 기록",
        ],
        tablePurpose: [
          `10년
           5년`,
          " 5년 ",
          `12개월 단, 시외/시내전화 역무와 관련된 자료: 6개월
           3개월`,
          ` 5년 
            5년 
            3년 
            6개월 `,
          " 3년 ",
        ],
      },
    },
    {
      id: 5,
      title: "제5조 개인정보의 파기절차 및 방법",
      contents: `회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
            1. 파기절차<br/>
            가. 회원의 개인정보는 수집 및 이용목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유(보유 및 이용기간 참조)에 따라 일정 기간 저장된 후 파기됩니다.<br/>
            나. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우와 민원/분쟁 처리를 위하여 확인하는 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.<br/>
            ※ 회원님께서 애플리케이션을 삭제하더라도 개인정보를 완전히 삭제하기 위해서는 회원탈퇴 등 별도 요청이 필요 합니다.<br/>
            2. 파기방법<br/>
            가. 종이(서면)에 작성·출력된 개인정보: 분쇄하거나 소각 등의 방법으로 파기<br/>
            나. DB 등 전자적 파일 형태로 저장된 개인정보: 재생할 수 없는 기술적 방법으로 삭제
            `,
      table: false,
    },
    {
      id: 6,
      title: "제6조 수집한 개인정보의 공유 및 제공",
      contents: `1. 회사는 회원의 개인정보를 '서비스 이용약관' 또는 '개인정보처리방침'에서 명시한 범위 내에서 이용하며, 명시한 범위를 넘어서 제3자와 공유하거나 제공하지 않습니다. 다만, 회원의 사전동의가 있거나, 관련 법령(통신비밀보호법, 전기통신사업법, 위치정보의 보호 및 이용 등에 관한 법률, 국세기본법 등)의 특별한 규정이 있는 경우, 법령에 정해진 규정과 절차에 따라 제공하는 경우는 예외로 합니다.<br/>
            2. 제공 정보의 이용 및 보유기간: 서비스 가입기간(가입일~해지일) 동안 이용하고, 해지 후 12개월까지 보유할 수 있으며, 민원처리 등 문제가 있을 경우 해결 시까지 보유 (단, 법령에 특별한 규정이 있을 경우 관련 법령에 따라 보관)<br/>
            `,
      table: false,
    },
    {
      id: 7,
      title: "제7조 회원 및 법정대리인의 권리와 그 행사 방법",
      contents: `1. 회원께서는 언제든지 개인정보, 개인정보를 이용하거나 제3자에게 제공한 현황, 개인정보 수집ㆍ이용ㆍ제공 등의 동의를 한 현황(이하 '개인정보 등'이라 합니다)에 대한 열람이나 제공을 요구하실 수 있고, 오류가 있는 경우에는 그 정정을 요구하실 수 있으며, 개인정보의 수집ㆍ이용ㆍ제공에 대한 동의를 철회를 하실 수 있습니다.<br/>
            2. 회원의 개인정보 등에 대한 열람 및 정정을 위해서는 회원(법정대리인)께서 회사를 직접 방문하시거나 회원센터로 전화 문의 후 정정을 하실 수 있습니다. 가입 해지를 위해서는 애플리케이션 내에 해지신청 메뉴를 활용하여 '회원탈퇴'를 클릭하여 본인 확인 절차를 거치신 후 탈퇴가 가능합니다.<br/>
            3. 회원이 본인의 개인정보 등에 대한 열람이나 정정을 요구하시거나 개인정보 수집ㆍ이용ㆍ제공 등의 동의를 철회하시는 경우 회원의 신분을 증명할 수 있는 주민등록증, 여권, 운전면허증(신형) 등의 신분증명(사본)을 제시 받아 본인 여부를 확인합니다.<br/>
            4. 회원의 대리인이 회원의 개인정보 등에 대한 열람이나 정정을 요구하거나 회원의 개인정보의 수집ㆍ이용ㆍ제공에 대한 동의를 철회하는 경우에는 대리 관계를 나타내는 위임장, 명의회원의 인감증명서와 대리인의 신분증명서 등의 증표를 제시 받아 적법한 대리인인지 여부를 확인합니다.<br/>
            5. 회원은 개인정보 등의 열람이나 제공을 요청하실 수 있으며, 회사는 이러한 요청에 지체 없이 필요한 조치를 취합니다.<br/>
            6. 회원이 개인정보 등의 오류에 대한 정정을 요청하신 경우에는 지체 없이 그 오류를 정정하거나 정정하지 못하는 사유를 회원에게 알리는 등 필요한 조치를 하고, 필요한 조치를 할때까지는 당해 개인정보를 이용 또는 제공하지 않습니다. 다만, 다른 법률에 따라 개인정보의 제공을 요청 받은 경우에는 그 개인정보를 제공하거나 이용할 수 있습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br/>
            7. 서비스는 회원의 요청에 의해 해지 또는 삭제된 개인정보를 "개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br/>
            8. 서비스는 원칙적으로 만 14세 미만 아동이 가입을 할 수 없는 서비스 이므로 개인정보 수집 이용 제공 동의를 할 수 없으며, 법정 대리인의 동의가 있더라도 가입이 되지 않습니다.
            `,
      table: false,
    },
    {
      id: 8,
      title: "제8조 개인정보의 기술적, 관리적 보호",
      contents: `회사는 회원님의 개인정보가 분실, 도난, 유출, 위조ㆍ변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적ㆍ관리적 대책을 마련하고 있습니다.
            `,
      table: false,
    },
    {
      id: 9,
      title: "[기술적 보호대책]",
      contents: `1. 회원님의 개인정보를 암호화하여 저장하며, 정보통신망을 통해 회원님의 개인정보 및 인증정보를 송ㆍ수신 하는 경우에는 안전한 보안서버 구축 등의 조치를 통해 암호화 하여 보호하고 있습니다.<br/>
            2. 백신 소프트웨어를 이용하여 컴퓨터바이러스 등에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신 소프트웨어는 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 도입, 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다.<br/>
            3. 회원님의 개인정보에 대한 불법적인 접근을 차단하기 위해, 외부로부터 접근이 통제된 구역에 시스템을 설치하고, 침입을 차단하는 장치를 이용하고 있으며, 아울러 침입탐지시스템을 설치하여 24시간 침입을 감시하고 있습니다.
            `,
      table: false,
    },
    {
      id: 10,
      title: "[관리적 보호대책]",
      contents: `1. 회사는 회원님의 개인정보를 안전하게 처리하기 위한 내부관리계획을 마련하여 소속 직원이 이를 숙지하고 준수하도록 하고 있습니다.<br/>
            2. 회사는 회원님의 개인정보를 처리할 수 있는 자를 최소한으로 제한하고 접근 권한을 관리하며, 새로운 보안 기술 습득 및 개인정보보호 의무 등에 관해 정기적인 사내 교육과 외부 위탁교육을 통하여 법규 및 정책을 준수할 수 있도록 하고 있습니다. 회원님의 개인정보를 처리하는 자는 다음과 같습니다.<br/>
            가. 회원님을 직·간접적으로 상대하여 마케팅 업무를 수행하는 자<br/>
            나. 개인정보 보호책임자 및 개인정보 보호담당자 등 개인정보 관리 및 개인정보보호 업무를 담당하는 자<br/>
            다. 기타 업무상 개인정보의 처리가 불가피한 자<br/>
            3. 전 임직원이 정보보호서약서에 서명하게 함으로써 직원에 의한 정보(개인정보 포함)유출을 사전에 방지하고, 수시로 개인정보보호 의무를 상기시키며 준수 여부를 감사하기 위한 내부 절차를 마련하여 시행하고 있습니다.<br/>
            4. 개인정보 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이루어지도록 하여, 입사 및 퇴사 후 개인정보 침해사고에 대한 책임을 명확하게 규정하고 있습니다.<br/>
            5. 전산실 및 자료보관실 등을 통제구역으로 설정하여 출입을 통제합니다.<br/>
            6. 서비스 이용계약 체결 또는 서비스 제공을 위하여 회원님의 은행결제계좌, 신용카드번호 등 대금결제에 관한 정보를 수집하거나 회원님께 제공하는 경우 해당 회원님이 본인임을 확인하기 위하여 필요한 조치를 취하고 있습니다.<br/>
            7. 회사는 회원님 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원님의 개인정보를 보호하기 위해서 자신의 ID와 비밀번호를 철저히 관리하고 책임을 져야 합니다.
            `,
      table: false,
    },
    {
      id: 11,
      title: "제9조 개인정보보호책임 부서 및 연락처",
      contents: `회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만 및 문의사항을 처리하기 위하여 아래와 같이 관련 부서를 지정하여 운영하고 있습니다. 또한 회원의 의견을 매우 소중하게 생각합니다. 회원이 서비스의 개인정보 관련 문의사항이 있을 경우 아래 개인정보 보호책임자 및 담당자에게 문의하시면 신속하고 성실하게 답변을 드리겠습니다.
            1. 개인정보보호책임자 부서 및 연락처<br>
            - 개인정보 보호책임자: 탁틴<br> 
            - 담당부서/직위: Product Team/차장<br>
            - 이메일 주소: product@heringsglobal.com<br><br>
            2. 개인정보 보호책임자 역할<br>
            가. 개인정보보호 관련 주요 지침/정책/제도 수립 시행<br>
            나. 개인정보 유출, 오남용 방지를 위한 내부통제시스템 구축<br>
            다. 개인정보보호 분야 관리실태 감사 및 지도점검<br>
            라. 개인정보보호 교육 계획 수립 시행<br>
            마. 개인정보처리방침 수립 개정<br>
            바. 개인정보 침해사고 예방 대응<br>
            사. 기타 기타정보보호를 위하여 필요한 업무<br><br>
            3. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br>
            가. 개인정보침해신고센터: http://privacy.kisa.or.kr / (국번없이)118<br>
            나. 대검찰청 사이버수사과: http://www.spo.go.kr / 02-3480-3571<br>
            다. 경찰청 사이버안전국: http://www.netan.go.kr / 1566-0112
            
            `,
      table: false,
    },
    {
      id: 12,
      title: "제10조 개인정보처리방침 시행일자",
      contents: `개인정보처리방침 버전: v1.0<br>
            개인정보처리방침 시행일자: [2022/08/01]            
            `,
      table: false,
    },
  ];
  return (
    <div className="text-gray2 keepAll">
      <h1 className="mb-4 text-body3_700 text-gray_160">
        힐리어리 개인정보 처리방침
      </h1>
      <ul>
        {datas.map((data) => (
          <li key={data.id} className="mb-2">
            <h2 className="text-caption2_700 text-gray_160">{data.title}</h2>
            <p className="text-caption2_400 text-gray_120">
              {Parser(data.contents)}
            </p>
            {data.table ? (
              <table className="border border-black text-caption2_400 text-gray_120">
                <thead className="text-center ">
                  <tr className="border border-black">
                    <th className="border-r border-black">{data.tableHead1}</th>
                    <th className="border-r border-black">{data.tableHead2}</th>
                    <th className="">{data.tableHead3}</th>
                  </tr>
                </thead>
                {/* <tbody className="border border-black ">
                  {data.tableMain?.tableTitle.map((list, i) => (
                    <tr className="border border-black">
                      <td className="border-r border-black">{list}</td>
                      <td className="col-span-4 border-r border-black">
                        {Parser(data.tableMain.tableContent[i])}
                      </td>
                      <td className="border-r border-black">
                        {Parser(data.tableMain.tablePurpose[i])}
                      </td>
                    </tr>
                  ))}
                </tbody> */}
                <tbody className="border border-black text-center ">
                  {/* data.tableMain.tableContent 배열에서 각 항목(list)와 해당 인덱스(i)를 가져옵니다. */}
                  {data.tableMain?.tableContent.map((list, i) => {
                    if (data.id === 4) {
                      //   현재 id가 4인 경우 (특정 조건을 만족하는 경우)

                      const contents =
                        data.tableMain.tableContent[i].split("\n");
                      const purposes =
                        data.tableMain.tablePurpose[i].split("\n");

                      return contents.map((content, j) => (
                        //    {/* 새로운 행 생성 */}
                        <tr className="border border-black">
                          {/* 첫 번째 열에 제목 출력 (첫 번째 내용인 경우에만 제목 출력하고, 그 외의 경우에는 제외합니다.) 즉 제목은 한 행만 크게 차지하게 함 */}
                          {j === 0 && (
                            <td
                              rowSpan={contents.length}
                              className="border-r border-black"
                            >
                              {data.tableMain.tableTitle[i]}
                            </td>
                          )}
                          {/* 두 번째 열에 내용 출력 */}

                          <td className="border-r border-black">
                            {Parser(content)}
                          </td>
                          {/* 세 번째 열에 목적 출력 (해당 목적이 있으면 출력하고, 없으면 제외합니다.) */}

                          {purposes[j] && (
                            <td className="border-r border-black">
                              {Parser(purposes[j])}
                            </td>
                          )}
                        </tr>
                      ));
                    } else {
                      // 목표 id가 아닌 경우(다른 조건, 여기선 id===4), 기존 방식대로 한 줄에 제목, 내용, 목적 순서대로 출력

                      return (
                        <tr className="border border-black">
                          <td className="border-r border-black">
                            {data.tableMain.tableTitle[i]}
                          </td>

                          <td className="border-r border-black ">
                            {Parser(data.tableMain.tableContent[i])}
                          </td>

                          <td>{Parser(data.tableMain.tablePurpose[i])}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            ) : null}
            {data.additionalTitle ? (
              <p className="text-caption2_400 text-gray_120">
                {Parser(data.additionalTitle)}
              </p>
            ) : (
              ""
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PersonalInfo;
