import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const editPass = createAsyncThunk("account/editPass", async (params) => {
  const res = await api.post(`/user/changepass`, params);
  if (res) {
    return res.data;
  }
});

export const chkPass = createAsyncThunk("account/chkPass", async (params) => {
  const res = await api.post(`/user/checkPass`, params);
  if (res) {
    return res.data;
  }
});

export const fetchAlarmLists = createAsyncThunk(
  "others/fetchAlarmLists", //알람 리스트 가져오기
  async (params) => {
    const res = await api.get(`/user/getAlarm/${params}`);
    if (res.data.ok) {
      return res.data.alarmList;
    }
  }
);

const initialState = {
  alarmLists: [],
  status: null,
  mobileStatus: {},
  questionsCompleteCheck: {},
  userInfo: [],
};

const accoutSlice = createSlice({
  name: "기타",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchAlarmLists.fulfilled, (state, action) => {
      state.alarmLists = action.payload;
    });
  },

  // {
  //   [fetchAlarmLists.fulfilled]: (state, action) => {
  //     state.alarmLists = action.payload;
  //   },
  // },
});

//export const { } = accoutSlice.actions

export default accoutSlice.reducer;
