import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchAnswerList,
  fetchQuestion,
  decrement,
  goBackByAmount,
  resetI,
  changeStateArray,
} from "../../../../features/mobile/questionHealthSlice";
import HeadCont from "../../components/common/HeadCont";
// import QuestionForm from "../../components/question_health/QuestionForm";
import QuestionForm from "../../components/question_health/QuestionForm";
import Message from "../../components/common/Message";

const QuestionHealth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { i, prevI } = useSelector((state) => state.questionHealth);
  const closeIcon = useMemo(
    () => ({
      background: "url('/images/mobile/icon/close.svg') no-repeat center",
    }),
    []
  );
  const [openMessage, setOpenMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const onLeftAction = () => {
    if (i <= 0) {
      navigate(-1);
      window.scrollTo(0, 0);
    } else {
      if (prevI.length >= 2) {
        // console.log(goBackByAmount, "goBackByAmount");
        dispatch(goBackByAmount());
      } else if (prevI.length === 1) {
        //i가 1일때
        // console.log(decrement, "decrement");

        dispatch(decrement());
      }
    }
  };

  const onClickClose = () => {
    navigate(location.state ? -1 : "/home");
    dispatch(resetI());
    dispatch(changeStateArray(0));
  };

  useEffect(() => {
    // console.log(resetI, "resetI");

    if (!loginInfo?.userId) navigate(`/sign`);
    else {
      window.scrollTo(0, 0);
      dispatch(resetI());
      dispatch(fetchAnswerList(loginInfo.userId));
      dispatch(fetchQuestion());
    }
  }, [dispatch, loginInfo.userId, navigate]);

  return (
    <>
      {!openMessage ? (
        <>
          {/* onRightAction: 
                처음 가입하고 설문하는 사람 관련 이슈 (이전 페이지가 recommend 이거나 healthinfo일 경우 -1이 먹히지 않음) 때문에 api user/questionCheck 쓰려고 했으나, 일단 홈으로 이동 */}
          <HeadCont
            option={"progress"}
            onLeftAction={onLeftAction}
            rightIcon={closeIcon}
            onRightAction={onClickClose}
            idName={"healthSurveyChurn"}
            i={i}
          />
          <section className="px-6 pt-2 mb-24 mt-14">
            <QuestionForm
              onLeftAction={onLeftAction}
              setOpenMessage={setOpenMessage}
              setLoading={setLoading}
            />
          </section>
        </>
      ) : (
        <Message
          option={"health"}
          imgUrl={"/images/mobile/image/doctor_moniter.svg"}
          loading={loading}
        />
      )}
    </>
  );
};

export default QuestionHealth;
