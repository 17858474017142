import React from "react";

const AnswerCard2 = ({ list, onCheckOne, checkedItems }) => {
  return (
    <li
      onClick={() => onCheckOne(list.dCode)}
      className={
        "rounded-2xl border border-gray_200 p-4 text-center " +
        (checkedItems.find((item) => item === list.dCode) ? "bg-main" : "")
      }
    >
      <h2
        className={
          "text-btn_700 " +
          (checkedItems.find((item) => item === list.dCode)
            ? "text-white"
            : "text-gray_200")
        }
      >
        {list.name}
      </h2>
    </li>
  );
};

export default AnswerCard2;
