import React from "react";
import MenuInner from "./MenuInner";

const MenuBar = ({ setMoveMenu }) => {
  return (
    <ul className="maxW fixed bottom-0 z-10 flex w-full items-center justify-between border-t border-gray_40 bg-white px-[20px] py-[10px]">
      <MenuInner setMoveMenu={setMoveMenu} />
    </ul>
  );
};
export default MenuBar;
