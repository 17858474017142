export const normalInputRing =
" rounded-[4px] px-[16px] py-[12px] bg-transparent border border-gray_100 focus:outline-none focus:border-black outline-none text-body_2 text-black";
export const errorInputRing =
" rounded-[4px] px-[16px] py-[12px] bg-gray_10 border border-red-500 focus:outline-none  outline-none text-body_2 text-black";
export const correctInputRing = 
" rounded-[4px] px-[16px] py-[12px] bg-gray_10 border focus:outline-none text-body_2 border-blue-500 outline-none focus:ring-1 ";
export const errorMsgColor = "flex items-center text-caption2_400 text-red-500"
export const normalMsgColor = "flex items-center text-caption2_400 text-gray_120";
export const correctMsgColor = "flex items-center text-caption2_400 text-blue-500";
export const listheight = "flex flex-col w-full";
export const labeltext = "font-normal text-caption2_400 text-gray_170";
export const commonInputcss ="flex items-center justify-center w-[100px]  gap-[10px] p-[12px] rounded-[4px] text-body_2 font-semibold	text-gray_10"