import React from "react";

const InfoPageLoading = () => {
    return (
        <>
            <section className="mt-14 p-6 animate-pulse">
                <div className="flex flex-col items-center mb-6">
                    <div className="w-[30%] h-5 bg-gray_20 mb-2 rounded-xl" />
                    <div className="w-[90%] h-8 bg-gray_20 mb-1 rounded-xl" />
                    <div className="w-[70%] h-8 bg-gray_20 rounded-xl" />
                </div>
                <div className="flex gap-1 mb-6">
                    <div className="w-[52px] h-[52px] bg-gray_20 rounded-[24px]"/>
                    <div className="flex flex-col gap-1">
                        <div className="w-16 h-6 bg-gray_20 rounded-xl" />
                        <div className="w-40 h-6 bg-gray_20 rounded-xl" />
                    </div>
                </div>
                <div className="flex flex-col py-6 gap-6">
                    <div className="w-full h-60 bg-gray_20 rounded"/>
                    <div className="w-full h-60 bg-gray_20 rounded"/>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="w-full h-6 bg-gray_20 rounded-xl"/>
                    <div className="w-[90%] h-6 bg-gray_20 rounded-xl"/>
                    <div className="w-[70%] h-6 bg-gray_20 rounded-xl"/>
                </div>

            </section>
        </>
    );
}

export default InfoPageLoading