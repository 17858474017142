import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const searchHospital = createAsyncThunk(
  "hospital/search",
  async () => {
    try {
      const res = await api.get("/admin/hospital/search");
      return res;
    } catch (e) {
      console.log(e);
    }
  }
);

export const searchHospitalDrop = createAsyncThunk(
  "hospital/search",
  async () => {
    try {
      const res = await api.get("/admin/hospital/searchForDropBox");
      return res;
    } catch (e) {
      console.log(e);
    }
  }
);

export const addHospital = createAsyncThunk(
  "hospital/add",
  async(params) =>{
    try {
      const res = await api.post("/admin/hospital/add",params)
      return res;
    } catch (e) {
      console.log(e);
    }
  }
)

export const editHospital = createAsyncThunk(
  "hospital/edit",
  async(params) =>{
    try {
      const res = await api.post("/admin/hospital/edit",params)
      return res;
    } catch (error) {
      console.log(error)
    }
  }
)

export const deleteHospital = createAsyncThunk(
  "hospital/delete",
  async(params)=>{
    try {
      const res = await api.post("/admin/hospital/delete",params)
      return res
    } catch (error) {
      console.log(error)
    }
  }
)
const initialState = {
  i: 0,
  status: null,

};

const hospitalSlice = createSlice({
  name: "병원",
  initialState,
  reducers: {

  },
  extraReducers: {

 
  },
});


export default hospitalSlice.reducer;
