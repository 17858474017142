import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import {
  fetchAnswerList,
  increment,
  resetI,
  saveAnswer,
  incrementByAmount,
} from "../../../../features/mobile/questionFoodAddSlice";
import BtnForQuestion from "../common/BtnForQuestion";
import SelectBox from "../common/SelectBox";
import TitleBox from "../common/TitleBox";
import AnswerCard from "./AnswerCard";
import AnswerCard2 from "./AnswerCard2";
import AnswerCardArr from "./AnswerCardArr";
import Parser from "html-react-parser";
import InputBox from "../common/InputBox";
import { regDecimal } from "../logic";
const QuestionForm = ({ onLeftAction }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { questionListsStatus, questionLists, i, gettingAnswers } = useSelector(
    (state) => state.questionFoodAdd
  );
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedNoItems, setCheckedNoItems] = useState([]);
  const filterPT995 = questionLists[i]?.answerList?.filter(
    (el) => el.dCode !== "PT995"
  );
  const PT995 = questionLists[i]?.answerList?.filter(
    (el) => el.dCode === "PT995"
  );
  const waterLists = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [checkWeight, setCheckWeight] = useState(null);
  const [weightValue, setWeightValue] = useState("");

  const onCheckOne = (one) => {
    //----------------->없음이나 모름이 있는 경우
    if (questionLists[i]?.saveUserColumn === "dietProblem") {
      //식사문제
      if (one === "DP999") {
        setCheckedItems([one]);
      } else {
        if (checkedItems.includes("DP999")) {
          //default값으로 DP999가 배열에 있을 경우
          const arr = checkedItems.filter((el) => el !== "DP999");
          setCheckedItems([...arr, one]);
        } else {
          if (checkedItems.includes(one)) {
            const filterCheck = checkedItems.filter((itm) => one !== itm);
            setCheckedItems([...filterCheck]);
          } else {
            setCheckedItems([...checkedItems, one]);
          }
        }
      }
    }
  };

  const onClickOpenInput = (type, answer) => {
    setCheckWeight(answer.dCode);
    if (answer.dCode === "CW999") {
      setWeightValue(0);
    } else {
      setWeightValue("");
    }
  };

  const onSaveAnswer = useCallback(
    (type, answer) => {
      let params = { userId: loginInfo.userId, [type]: answer };
      dispatch(saveAnswer(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            dispatch(fetchAnswerList(loginInfo.userId));
          } else
            toast(
              <p>{res.error}</p>,
              toastCommonProps("bottom-center", "toast_alert", 1000)
            );
        })
        .catch((err) => console.log(err));
    },
    [dispatch, loginInfo.userId]
  );

  const onSaveAnswerAndNext = useCallback(
    (type, answer) => {
      let params = { userId: loginInfo.userId, [type]: answer };
      dispatch(saveAnswer(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            dispatch(fetchAnswerList(loginInfo.userId));
            dispatch(increment());
            setCheckedItems([]);
          } else
            toast(
              <p>{res.error}</p>,
              toastCommonProps("bottom-center", "toast_alert", 1000)
            );
        })
        .catch((err) => console.log(err));
    },
    [dispatch, loginInfo.userId]
  );

  const onSaveAnswerAndNext2 = useCallback(
    (type, answer, code) => {
      let params = {
        userId: loginInfo.userId,
        [type]: code === "CW100" ? answer : -answer,
      };
      dispatch(saveAnswer(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            dispatch(fetchAnswerList(loginInfo.userId));
            dispatch(increment());
            setCheckWeight(null);
            setCheckedItems([]);
            setWeightValue("");
          } else
            toast(
              <p>{res.error}</p>,
              toastCommonProps("bottom-center", "toast_alert", 1000)
            );
        })
        .catch((err) => console.log(err));
    },
    [dispatch, loginInfo.userId]
  );

  const onMoveToNext = () => {
    //다음버튼
    if (questionLists[i]?.saveUserColumn === "dietProblem") {
      onSaveAnswerAndNext("dietProblem", checkedItems);
    } else if (questionLists[i]?.saveUserColumn === "dietPreferType") {
      if (
        !(
          checkedItems?.length === 0 &&
          gettingAnswers?.[questionLists[i]?.saveUserColumn]?.filter(
            (el) => el === "PT995"
          )?.length === 1
        )
      ) {
        //'상관없음'아닐 시 checkitems save
        onSaveAnswerAndNext(
          "dietPreferType",
          checkedItems.map((el) => el.dCode)
        );
      } else {
        dispatch(increment());
      }
    } else if (questionLists[i]?.saveUserColumn === "changeWeight") {
      onSaveAnswerAndNext2("changeWeight", Number(weightValue), checkWeight);
    } else if (
      questionLists[i]?.saveUserColumn === "alcoholNumber" &&
      gettingAnswers.alcoholNumber === "AN100"
    ) {
      dispatch(incrementByAmount(2));
    } else dispatch(increment());
    setCheckedItems([]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (questionLists[i]?.saveUserColumn === "dietProblem") {
      if (gettingAnswers !== null) {
        if (gettingAnswers[questionLists[i]?.saveUserColumn]?.length > 0) {
          setCheckedItems([
            ...gettingAnswers[questionLists[i]?.saveUserColumn],
          ]);
        }
      }
    } else if (questionLists[i]?.saveUserColumn === "dietPreferType") {
      if (gettingAnswers !== null) {
        if (gettingAnswers[questionLists[i]?.saveUserColumn]?.length > 0) {
          const answerFilter995 = gettingAnswers[
            questionLists[i]?.saveUserColumn
          ]?.filter((el) => el !== "PT995");
          if (answerFilter995?.length > 0) {
            //db dietPreferType 있는 경우 - PT995제외
            let arr = []; //check된 리스트
            let nocheckarr = filterPT995; //check 안된 리스트
            for (let k = 0; answerFilter995?.length > k; k++) {
              arr.push(
                filterPT995?.filter((el) => el.dCode === answerFilter995[k])[0]
              );
              const indexClick = nocheckarr?.indexOf(
                nocheckarr?.filter((el) => el.dCode === answerFilter995[k])[0]
              );
              nocheckarr.splice(indexClick, 1);
            }
            setCheckedItems(arr);
            setCheckedNoItems(nocheckarr);
          } else {
            //db dietPreferType 빈 경우
            setCheckedNoItems(filterPT995);
            setCheckedItems([]);
          }
        }
      }
    } else if (questionLists[i]?.saveUserColumn === "changeWeight") {
      if (gettingAnswers !== null) {
        const checkPositive = Math.sign(
          gettingAnswers[questionLists[i]?.saveUserColumn]
        );
        const changeAbs = Math.abs(
          gettingAnswers[questionLists[i]?.saveUserColumn]
        );
        if (checkPositive === 0) {
          setCheckWeight("CW999");
        } else if (checkPositive === 1) {
          setCheckWeight("CW100");
        } else {
          setCheckWeight("CW101");
        }
        setWeightValue(changeAbs);
      }
    } else if (
      questionListsStatus === "success" &&
      i >= questionLists?.length
    ) {
      //설문 종료
      dispatch(resetI());
      navigate("/healthinfo");
    }
  }, [i, setCheckedItems, questionLists[i], navigate, gettingAnswers]);

  return (
    <>
      {questionListsStatus === "success" ? (
        <>
          <TitleBox
            option={3}
            title={questionLists[i]?.question}
            subTitle={questionLists[i]?.subTitle1}
            subTitle2={questionLists[i]?.subTitle2}
          />
          {questionLists[i]?.saveUserColumn === "dietProblem" ? (
            <div className="mt-8 space-y-4">
              <ul className="flex flex-wrap gap-2">
                {questionLists[i]?.answerList.map((list, idx) => (
                  <AnswerCard2
                    key={idx}
                    list={list}
                    onCheckOne={onCheckOne}
                    checkedItems={checkedItems}
                  />
                ))}
              </ul>
            </div>
          ) : questionLists[i]?.saveUserColumn === "dietRate" ? (
            <div className="mt-8 space-y-4">
              {questionLists[i]?.answerList.map((answer, idx) => (
                <ul key={idx}>
                  <AnswerCard
                    title={answer.name}
                    onAction={onSaveAnswer}
                    answer={answer.dCode}
                    type={questionLists[i]?.saveUserColumn}
                  />
                </ul>
              ))}
            </div>
          ) : questionLists[i]?.saveUserColumn === "dietPreferType" ? (
            <div className="mt-8 space-y-4 pb-[136px]">
              <AnswerCardArr
                answerLists={filterPT995}
                setCheckedItems={setCheckedItems}
                checkedItems={checkedItems}
                checkedNoItems={checkedNoItems}
                onSaveAnswer={onSaveAnswer}
                checkGettingAnswer={
                  gettingAnswers[questionLists[i]?.saveUserColumn]
                }
              />
              {/* 상관없음 추가*/}
              {PT995.map((answer, idx) => (
                <ul key={idx}>
                  <AnswerCard
                    option={0}
                    title={answer.name}
                    onAction={onSaveAnswer}
                    answer={[answer.dCode]}
                    type={questionLists[i]?.saveUserColumn}
                  />
                </ul>
              ))}
            </div>
          ) : questionLists[i]?.saveUserColumn === "waterGlassCount" ? (
            <div className="mt-8 space-y-4">
              <SelectBox
                option={100}
                placeholder={
                  gettingAnswers.waterGlassCount === null
                    ? "1"
                    : gettingAnswers.waterGlassCount
                }
                type={"waterGlassCount"}
                datas={waterLists}
                onSaveAnswer={onSaveAnswer}
              />
            </div>
          ) : questionLists[i]?.saveUserColumn === "changeWeight" ? (
            <div className="mt-8 space-y-4">
              {questionLists[i].answerList.map((answer, idx) => (
                <ul key={idx}>
                  <li
                    onClick={() =>
                      onClickOpenInput(questionLists[i]?.saveUserColumn, answer)
                    }
                    className={
                      "cursor-pointer rounded-2xl border px-4 py-4 " +
                      (checkWeight === answer.dCode
                        ? "bg-gray_180 "
                        : "border border-gray_200 ") +
                      "text-center"
                    }
                  >
                    <p
                      className={
                        checkWeight === answer.dCode
                          ? "text-btn_700 text-white"
                          : "text-btn_700 text-gray_200"
                      }
                    >
                      {answer.name ? Parser(answer.name) : ""}
                    </p>
                  </li>
                  {checkWeight === answer.dCode && checkWeight !== "CW999" && (
                    <div className="flex items-center mt-4 gap-x-4">
                      <InputBox
                        option="changeWeight"
                        placeholder={"0.0"}
                        type={"number"}
                        inputM={"decimal"}
                        //소숫점첫째
                        onChangeAction={(e) => {
                          if (regDecimal(e.target.value)) {
                            setWeightValue(Number(e.target.value.slice(0, 4)));
                          }
                        }}
                        value={weightValue || ""}
                        setValue={setWeightValue}
                        active={"on"}
                      />
                      <p className="w-2/3 text-btn_700 text-gray_200">
                        kg {checkWeight === "CW100" ? "증가" : "감소"}했어요.
                      </p>
                    </div>
                  )}
                </ul>
              ))}
            </div>
          ) : questionLists[i]?.saveUserColumn === "healthFood" ? (
            <div className="mt-8 space-y-4">
              {questionLists[i]?.answerList.map((answer, idx) => (
                <ul key={idx}>
                  <AnswerCard
                    title={answer.name}
                    onAction={onSaveAnswer}
                    answer={answer.dCode}
                    type={questionLists[i]?.saveUserColumn}
                  />
                </ul>
              ))}
            </div>
          ) : questionLists[i]?.saveUserColumn === "smoke" ? (
            <div className="mt-8 space-y-4">
              {questionLists[i]?.answerList.map((answer, idx) => (
                <ul key={idx}>
                  <AnswerCard
                    title={answer.name}
                    onAction={onSaveAnswer}
                    answer={answer.dCode}
                    type={questionLists[i]?.saveUserColumn}
                  />
                </ul>
              ))}
            </div>
          ) : questionLists[i]?.saveUserColumn === "alcoholNumber" ? (
            <div className="mt-8 space-y-4">
              {questionLists[i]?.answerList.map((answer, idx) => (
                <ul key={idx}>
                  <AnswerCard
                    title={answer.name}
                    onAction={onSaveAnswer}
                    answer={answer.dCode}
                    type={questionLists[i]?.saveUserColumn}
                  />
                </ul>
              ))}
            </div>
          ) : questionLists[i]?.saveUserColumn === "alcoholAmount" ? (
            <div className="mt-8 space-y-4">
              {questionLists[i]?.answerList.map((answer, idx) => (
                <ul key={idx}>
                  <AnswerCard
                    title={answer.name}
                    onAction={onSaveAnswer}
                    answer={answer.dCode}
                    type={questionLists[i]?.saveUserColumn}
                  />
                </ul>
              ))}
            </div>
          ) : (
            ""
          )}
          <BtnForQuestion
            i={i}
            active={
              questionLists[i]?.saveUserColumn === "dietProblem"
                ? checkedItems?.length > 0 || gettingAnswers?.dietProblem
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn === "dietPreferType"
                ? checkedItems?.length === 4 ||
                  gettingAnswers?.[questionLists[i]?.saveUserColumn]?.filter(
                    (el) => el === "PT995"
                  )?.length > 0
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn === "changeWeight"
                ? !(checkWeight === "" || weightValue === "") ||
                  (gettingAnswers?.changeWeight && weightValue !== "")
                  ? "on"
                  : "ready"
                : gettingAnswers?.[questionLists[i]?.saveUserColumn]
                ? "on"
                : "ready"
            }
            onAction1={onLeftAction}
            onAction2={onMoveToNext}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default QuestionForm;
