import React, { useState } from "react";
import Parser from "html-react-parser";

const FAQ = () => {
  const [answerIndex, setAnswerIndex] = useState(null);
  const [toggle, setToggle] = useState(false);

  const datas = [
    {
      id: 1,
      question: "가입시 입력했던 설문을 다시 할 수 있나요?",
      answer: `’건강 정보’ 메뉴에서 가능합니다. 
        ‘건강 정보’ 화면에서 ‘신체 및 질병정보’와 ‘식습관 정보’의 수정 버튼을 선택하시면 설문을 다시 입력하실 수 있습니다. 
        입력하신 내용은 영양 관리 목표와 추천 식단에 반영됩니다. `,
    },
    {
      id: 2,
      question: "체중이 바뀌면 추천 식단도 바뀌나요?",
      answer: `체중을 입력하시면 현재 체중에 맞는 새로운 식단을 받아 보실 수 있습니다. 
        체중은 ‘건강 기록’에서 오늘 체중을 입력하시면 영양 관리 목표와 추천 식단에 반영됩니다. 
        또한 ‘건강 정보’에서 설문을 다시 입력하셔도 반영됩니다. `,
    },
  ];
  const FAQList = (el, idx) => {
    return (
      <>
        <div className="flex items-start">
          <span className="pr-2 text-body3_600 text-orange_180">Q.</span>
          <p className="text-body3_600">{el.question}</p>
        </div>
        <img
          src="/images/mobile/icon/arrow_right.svg"
          className={
            "transition duration-150 ease-linear" +
            (answerIndex === idx && toggle ? " rotate-90" : " rotate-0")
          }
          alt="right_arrow"
        />
      </>
    );
  };
  return (
    <div>
      <ul>
        {datas?.map((el, idx) => (
          <li key={el.id}>
            <div
              className="flex p-6 bg-white border-b gap-x-2"
              key={el.id}
              onClick={() => {
                setAnswerIndex(idx);
                if (toggle && answerIndex === idx) {
                  setToggle(false);
                } else {
                  setToggle(true);
                }
              }}
            >
              {FAQList(el, idx)}
            </div>
            {answerIndex === idx && toggle && (
              <div className="p-6 bg-gray_95">
                <div className="flex items-start">
                  <span className="pr-2 text-body3_600 text-correct">A.</span>
                  <p className="text-body3_600">
                    {el.answer ? Parser(el.answer) : null}
                  </p>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;
