export const SearchTargetOption = {
  위암: "CT100",
  대장암: "CT101",
  폐암: "CT102",
  유방암: "CT103",
  간암: "CT104",
  전립선암: "CT105",
  갑상선암: "CT106",
  췌장암: "CT107",
  "담낭암 및 기타담도암": "CT108",
  신장암: "CT109",
  자궁경부암: "CT110",
  자궁내막암: "CT111",
  자궁육종: "CT112",
  난소암: "CT113",
  기타암: "CT998",
  수술: "OY100",
  비수술: "OY101",
  치료중: "TY100",
  "치료 종료": "TY101",
  "치료 안함": "TY102",
  화학항암: "TT100",
  방사선: "TT101",
  호르몬: "TT102",
  방사선요오드: "TT103",
  면역항암: "TT104",
  모름: "TT997",
  "30세 미만": "1",
  "30대": "2",
  "40대": "3",
  "50대": "4",
  "60대": "5",
  "70대 이상": "6",
};
export const CancerTypes = {
  CT100: "위암",
  CT101: "대장암",
  CT102: "폐암",
  CT103: "유방암",
  CT104: "간암",
  CT105: "전립선암",
  CT106: "갑상선암",
  CT107: "췌장암",
  CT108: "담낭암 및 기타담도암",
  CT109: "신장암",
  CT110: "자궁경부암",
  CT111: "자궁내막암",
  CT112: "자궁육종",
  CT113: "난소암",
  CT998: "기타암",
};

export const 암종 = [
  "위암",
  "대장암",
  "폐암",
  "유방암",
  "간암",
  "전립선암",
  "갑상선암",
  "췌장암",
  "담낭암 및 기타담도암",
  "신장암",
  "자궁경부암",
  "자궁내막암",
  "자궁육종",
  "난소암",
  "기타암",
];
export const 수술여부 = ["수술", "비수술"];
export const 항암치료여부 = ["치료중", "치료 종료", "치료 안함"];
export const 항암치료종류 = [
  "화학항암",
  "방사선",
  "호르몬",
  "방사성요오드",
  "면역항암",
  "모름",
];
export const 나이 = ["30세 미만", "30대", "40대", "50대", "60대", "70대 이상"];
