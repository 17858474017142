import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import api from "../../common/api";
import { PURGE } from "redux-persist";

export const onAddUser = createAsyncThunk(
  "common/onAddUser", //회원가입
  async (params) => {
    const res = await api.post(`/user/add`, params);
    return res.data;
  }
);

export const fetchCurrnetNickname = createAsyncThunk(
  "others/fetchCurrnetNickname", //최신 로그인 정보(닉네임, 캐릭터)
  async (params) => {
    const res = await api.get(`/user/getNickNamePhotoInfo/${params}`);
    return res.data;
  }
);

export const onSetLike = createAsyncThunk(
  "common/onSetLike",
  async (params) => {
    const res = await api.post(`/posting/clickLike`, params);
    return res.data;
  }
);

export const addReporting = createAsyncThunk(
  "common/addReporting",
  async (params) => {
    const res = await api.post(`/posting/addReporting`, params);
    return res.data;
  }
);

export const addReply = createAsyncThunk("common/addReply", async (params) => {
  const res = await api.post(`/posting/addReply`, params);
  return res.data;
});

export const addReReply = createAsyncThunk(
  "common/addReReply",
  async (params) => {
    const res = await api.post(`/posting/addReReply`, params);
    return res.data;
  }
);

export const deleteReply = createAsyncThunk(
  "common/deleteReply",
  async (params) => {
    const res = await api.post(`/posting/deleteReply`, params);
    return res.data;
  }
);

export const blockUser = createAsyncThunk(
  "common/blockUser",
  async (params) => {
    const res = await api.post(`/posting/blockUser`, params);
    return res.data;
  }
);

export const getBlockUserList = createAsyncThunk(
  "common/getBlockUserList",
  async (params) => {
    const res = await api.post(`/posting/blockUserList`, params);
    return res.data;
  }
);

export const getMyReplies = createAsyncThunk(
  "common/getMyReplies",
  async (params) => {
    const res = await api.post(`/posting/getPageMyReplies`, params);
    return res.data;
  }
);

const initialState = {
  loginInfo: {},
  currentUser: {},
  tutorialEnabled: true,
  // myPage
  navTab: true,
  rangeMonth: null,
  prevReplyList: [],
  existNextReply: null,
  searchedValue: null,

  // myPage listener
  isModified: false,
  modifiedId: null,
  modifiedContent: null,
  modifyType: null,

  chosenDate: null,
};

const commonSlice = createSlice({
  name: "섹션",
  initialState,
  reducers: {
    saveChosenDate: (state, action) => {
      state.chosenDate = action.payload;
    },
    saveLoginInfo: (state, action) => {
      //수동으로 저장, 상태값 변동 안됨
      state.loginInfo = action.payload;
    },
    addLoginInfo: (state, action) => {
      state.loginInfo = { ...state.loginInfo, ...action.payload };
    },
    removePatientInfo: (state) => {
      delete state.loginInfo.patientNickName;
      delete state.loginInfo.patientCharacterPhoto;
    },
    removeLoginInfo: (state) => {
      //로그아웃 후 사용자 정보 삭제하기(토큰은 남겨야 함-탈퇴 후 재가입용을 위해)
      state.loginInfo = {};
    },
    removeCurrentInfo: (state) => {
      state.currentUser = {};
    },
    disableTutorial: (state) => {
      state.tutorialEnabled = false;
    },
    changeNavTab: (state, action) => {
      state.navTab = action.payload;
    },
    onSetPrevReplyList: (state, action) => {
      state.prevReplyList = action.payload;
    },
    onResetPrevReplies: (state) => {
      state.prevReplyList = [];
    },
    onSetExistNextReply: (state, action) => {
      state.existNextReply = action.payload;
    },
    onSetRangeMonth: (state, action) => {
      state.rangeMonth = action.payload;
    },
    onSetSearchedValue: (state, action) => {
      state.searchedValue = action.payload;
    },
    onResetAll: (state, action) => {
      state.searchedValue = null;
      state.rangeMonth = null;
      state.navTab = true;
      state.prevReplyList = [];
      state.prevScrollPosition = null;
    },
    onResetListeners: (state) => {
      state.isModified = false;
      state.modifiedId = null;
      state.modifyType = null;
      state.modifiedContent = null;
    },
    extraReducers: (builder) => {
      builder.addCase(PURGE, () => initialState);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCurrnetNickname.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
    builder.addCase(getMyReplies.fulfilled, (state, action) => {
      state.prevReplyList = action.payload.replyList;
    });
    builder.addMatcher(
      isAnyOf(addReply.fulfilled, addReReply.fulfilled, deleteReply.fulfilled),
      (state, action) => {
        // addReply acts as both addition and edit of comments
        // addReReply only allows for addition
        console.log(action);
        if (action.payload.ok) {
          state.isModified = true;
          // Delete
          if (action.type === "common/deleteReply/fulfilled") {
            return;
            // state.modifyType = "Delete";
            // state.modifiedId = action.meta.arg.id;
          }
          // Edit
          else if (action.meta.arg.id) {
            return;
            // state.modifyType = "Edit";
            // state.modifiedId = action.meta.arg.id;
            // state.modifiedContent = action.meta.arg.content;
          }
          // Add (reload, unless we put belong to post/ reply user Id and character photo)
          else if (
            action.meta.arg.belongToPostId ||
            action.type === "common/addReReply/fulfilled"
          ) {
            state.modifyType = "Add";
            state.modifiedContent = action.meta.arg.content;
          }
        }
      }
    );
  },

  // {
  //   [fetchCurrnetNickname.fulfilled]: (state, action) => {
  //     state.currentUser = action.payload;
  //   },
  //   [getMyReplies.fulfilled]: (state, action) => {
  //     state.prevReplyList = action.payload.replyList;
  //   },
  // },
});

export const {
  saveChosenDate,
  saveLoginInfo,
  addLoginInfo,
  removeLoginInfo,
  removePatientInfo,
  removeCurrentInfo,
  disableTutorial,
  changeNavTab,
  onSetPrevReplyList,
  onResetPrevReplies,
  onSetExistNextReply,
  onSetRangeMonth,
  onSetSearchedValue,
  onResetAll,
  onResetListeners,
} = commonSlice.actions;

export default commonSlice.reducer;
