import { mobileTypeFn } from "../mobile/components/logic";

const DrugDiary = () => {
  if (mobileTypeFn() === "iphone") {
    window.location.replace(
      "https://apps.apple.com/kr/app/id6443573631",
      "_blank"
    );
  } else {
    window.location.replace(
      `market://details?id=com.herings.adhreport`,
      "_blank"
    );
  }
};

export default DrugDiary;
