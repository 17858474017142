import { useSelector } from "react-redux";
import { subNavTitleAccount, subNavTitleAccount01, subNavTitleCommu, subNavTitleContents } from "../../data/NavTxt";
import SideInnerMapping from "./SideInnerMapping";
import SideInnerTitle from "./SideInnerTitle";

const SideInner = ({
  item,
  navBooleen,
  user
}) => {

  const { section } = useSelector((state) => state.common);
  return (
    <li key={item.id}>
      {/* 네비게이션 아이콘 */}
      {navBooleen ? (
        <SideInnerTitle item={item} navBooleen={navBooleen} />
      ) : (
        // 네비게이션 실제메뉴
        <SideInnerTitle item={item} />
      )}
      {/* 네비게이션 실제메뉴 - sub */}
      {!navBooleen &&
        (
          section === "게시판 관리" && item.title === "게시판 관리" ? (
            <SideInnerMapping mappingData={subNavTitleCommu} />
          ) :
            section === "컨텐츠 관리" && item.title === "컨텐츠 관리" ? (
              <SideInnerMapping mappingData={subNavTitleContents} />
            ) :
              section === "계정 관리" && item.title === "계정 관리" ? (
                <>
                  {user.authority === "00" || user.authority === "10" ?
                    <SideInnerMapping mappingData={subNavTitleAccount} />
                    : user.authority === "01" ?
                      <SideInnerMapping mappingData={subNavTitleAccount01} />
                      : ""
                  }
                </>
              )
                : ""
        )
      }
    </li>
  );
};

export default SideInner;
