import React from 'react'
import CommuReplyLayout from './CommuReplyLayout'

const CommunityDetailReply = ({el,postingId}) => {
  const childui = true

  return (
    <>
      <CommuReplyLayout reply={el.parentReply } postingId={postingId}/>
      {el.rereplies?.length>0 &&
        el.rereplies?.map((childReply, idx)=>(
          <CommuReplyLayout reply={childReply} key = {idx} childui ={childui} postingId={postingId}/>
        ))
      }
    </>
  )
}

export default CommunityDetailReply