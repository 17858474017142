import React, { useEffect, useState } from "react";
import HeadCont from "../../components/common/HeadCont";
import { useNavigate } from "react-router-dom";
import {
  blockUser,
  getBlockUserList,
} from "../../../../features/mobile/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import ProfilePicture from "../../components/common/ProfilePicture";
import DialogCover from "../../components/common/DialogCover";
import Dialog from "../../components/common/Dialog";
import Btn from "../../components/common/Btn";

const MyBlockUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const [blockUserList, setBlockUserList] = useState([]);

  // status -> read : 리스트 확인(default), cancel : 차단해제 전 팝업, complete : 차단해제 완료
  const [status, setStatus] = useState("read");
  // 차단해제하려는 유저
  const [user, setUser] = useState({
    nickName: "",
    userId: "",
  });

  useEffect(() => {
    let params = { userId: loginInfo.userId };
    if (status === "read") {
      dispatch(getBlockUserList(params))
        .unwrap()
        .then((res) => {
          console.log(res);
          if (res.ok) {
            setBlockUserList(res.blockUserList);
          } else {
            console.log(res);
          }
        });
    }
  }, [status]);

  const cancelBlock = (blockUsersId) => {
    let params = { userId: loginInfo.userId, blockUsersId: blockUsersId };
    dispatch(blockUser(params))
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res.ok) {
          setStatus("complete");
        }
      });
  };

  return (
    <>
      <HeadCont
        title={false}
        onLeftAction={() => {
          navigate(-1);
        }}
      />

      <div className="mt-14" />
      <h1 className="mx-6 mb-2.5 mt-2 text-subtitle1">차단한 회원</h1>

      {/* Search Tab */}
      <section
        className={`w-full border-gray_60 ${
          blockUserList.length === 0 ? "border-t" : "border-y"
        }`}
      >
        {/* Main Content */}

        <ul className="w-full divide-y divide-gray_60">
          {blockUserList.length !== 0 ? (
            blockUserList?.map((user) => (
              <div key={user.userId} className="flex gap-3 bg-white px-8 py-4">
                <ProfilePicture writer={user} location={"post"} />
                <div>
                  <span
                    className={
                      "flex text-body3_600 " +
                      (user?.userId === loginInfo.userId
                        ? "text-orange_140 "
                        : "text-black")
                    }
                  >
                    {user.nickName}
                  </span>
                  <span className="">
                    {user.cancer && user.userType
                      ? `${user.cancer} / ${user.userType}`
                      : `${user.cancer || user.userType || ""}`}
                  </span>
                </div>

                <button
                  className="my-auto ml-auto shrink-0 rounded-xl bg-orange_120 p-2 text-white"
                  onClick={() => {
                    setUser({
                      userId: user.userId,
                      nickName: user.nickName,
                    });
                    setStatus("cancel");
                  }}
                >
                  차단 해제
                </button>
              </div>
            ))
          ) : (
            <p className="mt-60 text-center text-body3_600 text-gray_160">
              차단한 회원이 없습니다.
            </p>
          )}
        </ul>
        {status === "cancel" ? (
          <DialogCover
            innerClass={
              "z-50 bg-white rounded-md w-full mx-6 max-[320px]:max-h-[360px] overflow-y-scroll no-scrollbar  shadow-[0_0_12px_rgba(0,0,0,0.1)]"
            }
          >
            <div className="px-4 py-6">
              <h2 className="mb-4 text-subtitle2 text-black">
                차단을 해제하시겠습니까?
              </h2>
              <p className="text-body2_500l">
                {user.nickName}님을 차단 해제하시겠습니까?
              </p>
            </div>
            <div className="m-4 flex items-center justify-between gap-2">
              <Btn
                innerClass={"w-fulll w-1/2"}
                bgColor={"bg-white"}
                textColor={"text-main"}
                borderColor={"border border-main"}
                title={"취소"}
                active={"on"}
                onAction={() => {
                  setStatus("read");
                }}
              />
              <Btn
                innerClass={"w-fulll w-1/2"}
                bgColor={"bg-main"}
                textColor={"text-white"}
                borderColor={"border border-main"}
                title={"차단해제"}
                active={"on"}
                onAction={() => cancelBlock(user.userId)}
              />
            </div>
          </DialogCover>
        ) : status === "complete" ? (
          <Dialog
            innerClass={"py-4 px-6"}
            btnClass={"mt-8"}
            title={"차단 해제"}
            desc={`차단이 해제되었습니다. <br/> 지금부터 ${user.nickName}님이 작성한 게시글과 댓글을 확인할 수 있습니다.`}
            onRightBtnAction={() => setStatus("read")}
            onCoverAction={() => setStatus("read")}
          />
        ) : null}
      </section>
    </>
  );
};

export default MyBlockUsers;
