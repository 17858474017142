import React, { useEffect, useState } from "react";
import HeadCont from "../components/common/HeadCont";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../common/api";
import Loading from "../components/common/Loading";

const Event = () => {
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const [applied, setApplied] = useState({ isApplied: false, id: 0 });
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadedImage, setLoadedImage] = useState([]);
  const [data, setData] = useState({
    userId: loginInfo?.userId,
    answer: "",
    reviewId: "",
    reviewImage: [],
    remainingFiles: [],
  });

  const onChangeFile = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setData({ ...data, reviewImage: [file] });
      setLoadedImage([reader.result]);
    };

    e.target.value = null;
  };

  const apply = async () => {
    setFinished(true);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("type", 1);
    data.reviewImage.forEach((file) => formdata.append("files", file));
    formdata.append("userId", data.userId);
    formdata.append("value1", data.answer);
    formdata.append("value2", data.reviewId);
    formdata.append("value4", "");
    formdata.append("value5", "");
    const res = await api.post(`/event/addEvent`, formdata, config);
  };

  const edit = async () => {
    setFinished(true);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("type", 1);
    formdata.append("id", applied.id);
    formdata.append("userId", data.userId);
    if (data.reviewImage.length !== 0) {
      data.reviewImage.forEach((file) => formdata.append("files", file));
    } else if (data.reviewImage.length === 0 && loadedImage.length !== 0) {
      loadedImage.forEach((file) => formdata.append("remainingFiles", file));
    }
    formdata.append("value1", data.answer);
    formdata.append("value2", data.reviewId);
    formdata.append("value3", "");
    formdata.append("value4", "");
    formdata.append("value5", "");
    const res = await api.post(`/event/editEvent`, formdata, config);
  };

  useEffect(() => {
    setLoading(true);
    const res = api
      .post(`/event/checkEvent`, {
        userId: data.userId,
        type: 1,
      })
      .then((res) => {
        console.log(res);
        if (res.data.isExist) {
          setFinished(true);
          const { id, files, value1, value2 } = res.data.event;
          console.log(res.data.event);
          setApplied({ isApplied: true, id });
          setData({
            ...data,
            answer: value1,
            reviewId: value2,
          });
          setLoadedImage(
            files !== null && files?.length !== 0 ? [files[0]] : []
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, []);

  return (
    <div>
      <HeadCont title={"이벤트 참여"} />
      <div className="relative mt-14 h-64 overflow-hidden bg-[#FEF3D2] pt-9 font-jalan">
        <img src="/images/mobile/event-bg.png" alt="배경 이미지" />

        <h3
          className="absolute top-[60%] mx-auto w-full text-center text-[1.7rem] text-[#693D22]"
          style={{
            textShadow:
              "-1.5px 0px white, 0px 1.5px white, 1.5px 0px white, 0px -1.5px white",
          }}
        >
          <p>힐리어리 이벤트에</p>
          <p>응모해 주셔서 감사합니다!</p>
        </h3>
      </div>
      <div className="w-full bg-[#FDDD7C] pb-12 pt-4 ">
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="relative z-[2] mx-auto flex w-[85%] flex-col gap-3 rounded-2xl bg-[#BF8245] p-5 pt-8 text-base before:absolute before:-top-4 before:left-10 before:h-[37px]  before:w-[16px] before:rounded-3xl before:bg-[#FFF3D2] before:text-[#FFF3D2] after:absolute after:-top-4 after:right-10 after:h-[37px]  after:w-[16px] after:rounded-3xl after:bg-[#FFF3D2] after:text-[#FFF3D2]">
              {!finished ? (
                <>
                  <p className="text-white font-jalan">1. 초성퀴즈의 정답은?</p>
                  <input
                    type="text"
                    className="w-full rounded-[5px] p-3 placeholder:font-bold placeholder:text-[#9C9C9C] focus:outline-none"
                    placeholder="입력하기"
                    onChange={(e) =>
                      setData({ ...data, answer: e.target.value })
                    }
                    value={data.answer}
                  />
                  <p className="text-white font-jalan">
                    2. 구글플레이 / 앱스토어 리뷰작성 ID
                  </p>
                  <input
                    type="text"
                    className="w-full rounded-[5px] p-3 placeholder:font-bold placeholder:text-[#9C9C9C] focus:outline-none"
                    placeholder="입력하기"
                    onChange={(e) =>
                      setData({ ...data, reviewId: e.target.value })
                    }
                    value={data.reviewId}
                  />
                  <p className="text-white font-jalan">
                    3. 구글플레이 / 앱스토어 리뷰 이미지
                  </p>
                  {loadedImage.length === 0 || loadedImage[0] === undefined ? (
                    <label
                      htmlFor="input_file"
                      className="flex h-full w-full cursor-pointer items-center justify-center gap-3 rounded-[5px] bg-white  text-base font-bold"
                    >
                      <img
                        src="/images/mobile/event-photo.svg"
                        alt="파일 선택"
                      />
                      <span className="my-10 text-[#9C9C9C]">
                        이미지 첨부하기
                      </span>
                      <input
                        type="file"
                        id="input_file"
                        className="hidden"
                        onChange={(e) => onChangeFile(e)}
                        accept="image/*"
                      />
                    </label>
                  ) : (
                    <div className="relative mt-2">
                      <img
                        src={loadedImage[0]}
                        alt="업로드 이미지"
                        className="h-full w-full rounded-[5px]"
                      />
                      <img
                        src="/images/admin/icon/close_circle.svg"
                        alt="삭제 아이콘"
                        className="absolute w-12 h-12 cursor-pointer right-4 top-4"
                        onClick={() => {
                          setData({ ...data, reviewImage: [] });
                          setLoadedImage([]);
                        }}
                      />
                    </div>
                  )}
                  {applied.isApplied ? (
                    <button
                      className="mx-auto my-5 disabled:opacity-40 disabled:grayscale"
                      onClick={edit}
                      disabled={data.answer === "" ? true : false}
                    >
                      <img
                        src="/images/mobile/event-edit-2.svg"
                        alt="수정하기"
                      />
                    </button>
                  ) : (
                    <button
                      className="mx-auto my-5 disabled:opacity-40 disabled:grayscale"
                      onClick={apply}
                      disabled={data.answer === "" ? true : false}
                    >
                      <img
                        src="/images/mobile/event-apply.svg"
                        alt="응모하기 버튼"
                      />
                    </button>
                  )}
                </>
              ) : (
                <div className="flex flex-col items-center gap-8 my-16">
                  <p className="text-lg text-center text-white font-jalan">
                    응모가 완료되었습니다!
                    <br />
                    기쁜 소식이 있길 바라겠습니다 :)
                  </p>
                  <div className="flex flex-col items-center gap-5">
                    <button>
                      <img
                        src="/images/mobile/event-edit.svg"
                        alt="응모내용 수정하기"
                        onClick={() => setFinished(false)}
                      />
                    </button>
                    <button>
                      <img
                        src="/images/mobile/event-home.svg"
                        alt="홈으로"
                        onClick={() => navigate("/home")}
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="mx-auto h-12 w-[85%] -translate-y-8 rounded-2xl bg-[#80552F]"></div>
          </>
        )}
      </div>
    </div>
  );
};

export default Event;
