import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const initialState = {
  pagearr:[],
  pagePerList:10,
  pagePerList7:7,
  pagePerList5:5
};

const paginationSlice = createSlice({
  name: "페이지네이션",
  initialState,
  reducers: {
    setPageArr: (state, action) => {
      state.pagearr = action.payload;
    },

  },

  extraReducers: {

  },
});

export const { setPageArr} =
paginationSlice.actions;

export default paginationSlice.reducer;
