import dayjs from 'dayjs';
import React from 'react'


const PagingInnerTable = ({data,idx,showEditModal,
  cellStyle,EachPage,pageUserCount,page,symptom,faq}) => {

  return (
    <tr
    onClick={() => {
      showEditModal(data);
    }}
    key={idx}
    className={
      "px-6 py-3 cursor-pointer hover:bg-primary_10  " +
      (idx % 2 === 1 ? "bg-gray_95" : "bg-gray_10")
    }
  >
      <td
        className={
          cellStyle +
          (idx === EachPage?.length - 1 ? "rounded-bl-lg" : "")
        }
      >
        {idx + 1 + pageUserCount * (page - 1)}
      </td>
      {symptom?
      <td className={cellStyle}>
        {data.symptom}
      </td>
      :
      faq?
      <>
        <td className={cellStyle}>
            {data.category}
        </td>
        <td className={cellStyle}>
            {data.title}
        </td>
      </>

      :
      <>
        <td className={cellStyle}>
          {data.lcategory}
        </td>
        <td className={cellStyle}>
          {data.mcategory}
        </td>
        <td className={cellStyle}>
          {data.scategory}
        </td>
      </>
      }

      <td className={cellStyle + " underline "}>보기</td>
      <td className={cellStyle}>
        {data.addAdmin === null ? "-" : data.addAdmin?.name}
      </td>
      <td className={cellStyle}>
        {dayjs(data.createdAt).format("YYYY-MM-DD")}
      </td>
      <td className={cellStyle}>
        {data.editAdmin === null ? "-" : data.editAdmin?.name}
      </td>
      <td
        className={
          cellStyle +
          " border-r " +
          (idx === EachPage?.length - 1 ? "rounded-br-lg" : "")
        }
      >
        {dayjs(data.updatedAt).format("YYYY-MM-DD")}
      </td>
    </tr>
  )
}

export default PagingInnerTable