import React, { useState, useEffect, useRef } from "react";
import { regPass } from "../../components/logic";
import Btn from "./Btn";
import AgreementDialog from "../sign/AgreementDialog";
import InputBox from "./InputBox";

const PassInput = ({ onSaveEach, onSaveAgreement }) => {
  const [pwd, setPwd] = useState({ pass: "", again: "" });
  const [active1, setActive1] = useState("ready");
  const [active2, setActive2] = useState("ready");
  const [errorMsg, setErrorMsg] = useState({
    msg1: "영문, 숫자 조합 6자리 이상으로 입력해주세요",
    msg2: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const passRef = useRef(null);
  const againRef = useRef(null);

  const onChangeInput1 = (e) => {
    setPwd({ ...pwd, pass: e.target.value });
    if (!regPass(e.target.value)) {
      setErrorMsg({
        msg1: "영문, 숫자 조합 6자리 이상으로 입력해주세요",
        msg2: "",
      });
      setActive1("ready");
      setActive2("ready");
    } else {
      setErrorMsg({ msg1: "", msg2: "" });
      setActive1("on");
    }
  };

  const onChangeInput2 = (e) => {
    setPwd({ ...pwd, again: e.target.value });
    if (pwd.pass !== e.target.value) {
      setErrorMsg({
        ...errorMsg,
        msg2: "비밀번호 확인을 위해 다시 입력해주세요",
      });
      setActive2("ready");
    } else {
      if (active1 === "on") {
        setErrorMsg({ ...errorMsg, msg2: "비밀번호가 일치합니다" });
        setActive2("on");
      } else setActive2("ready");
    }
  };

  const onDeleteValue = (type) => {
    if (type === "pass") {
      setPwd({ pass: "", again: "" });
      setErrorMsg({ ...errorMsg, msg1: "" });
      passRef.current?.focus();
      setActive1("ready");
      setActive2("ready");
    } else {
      setActive2("ready");
      setPwd({ ...pwd, again: "" });
      setErrorMsg({ ...errorMsg, msg2: "" });
      againRef.current?.focus();
    }
  };

  const onSavePass = (e) => {
    e.preventDefault();
    onSaveEach("pass", pwd.pass);
    setOpenDialog(true);
    setPwd({ pass: "", again: "" });
  };

  useEffect(() => {
    let focused = true;
    if (focused) passRef.current?.focus();
    return () => (focused = false);
  }, [passRef]);

  return (
    <>
      {/* 동의창 다이얼로그 나가는 것 일부러 없앰 */}
      <div
        className={
          openDialog
            ? "fixed left-0 top-0 z-10 h-full w-full bg-black opacity-70"
            : "hidden"
        }
      ></div>
      <form
        onSubmit={
          active1 === "on" && active2 === "on"
            ? onSavePass
            : (e) => e.preventDefault()
        }
        className={"space-y-6 " + (openDialog ? "hidden" : "relative")}
      >
        <InputBox
          option="pass"
          refName={passRef}
          id={"pass"}
          nameTitle={"pass"}
          placeholder={"비밀번호"}
          onChangeAction={(e) => onChangeInput1(e)}
          value={pwd.pass || ""}
          active={active1}
          onClickAction={() => onDeleteValue("pass")}
          errorMsg={errorMsg.msg1}
        />
        <InputBox
          option="pass"
          refName={againRef}
          id={"again"}
          nameTitle={"again"}
          placeholder={"비밀번호 확인"}
          onChangeAction={(e) => onChangeInput2(e)}
          value={pwd.again || ""}
          active={active2}
          onClickAction={() => onDeleteValue("again")}
          errorMsg={errorMsg.msg2}
        />
        <Btn
          option={"bottom"}
          title={"비밀번호 저장"}
          bgColor={"bg-main"}
          textColor={"text-white"}
          borderColor={"border border-main"}
          active={active1 === "on" && active2 === "on" ? "on" : "ready"}
        />
      </form>
      <AgreementDialog
        openDialog={openDialog}
        onSaveAgreement={onSaveAgreement}
      />
    </>
  );
};

export default PassInput;
