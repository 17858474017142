import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../../src/common/toast";
import { deleteAdminInfo } from "../../../../features/admin/adminSlice";
import { deleteHospital } from "../../../../features/admin/hospitalSlice";
import { BGButtonCustom } from "../../components/common/ButtonCustom";
import CustomCenterModal from "./CustomCenterModal";

const ConfirmModal = ({ showConfirmModal, flag, state, defaultAnswer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {loginInfo} = useSelector((state)=>state.adminLogin)

  //admin id 삭제
  const deleteAdmin =  () => {
    const postAdmin = { adminId: state.adminId };
     dispatch(deleteAdminInfo(postAdmin));
    navigate("/admin/account");
  };

  //hospital id 삭제
  const deleteHospitalId = async() =>{
    const deleteParam = {
      loginedAdminID: loginInfo?.adminId,
      code: state.code
    }
    const res = await dispatch(deleteHospital(deleteParam)).unwrap()
    try {
      if(res.data.ok){
        toast(<p>삭제 되었습니다.</p>,toastCommonProps("top-right", "toast_alert",1000));
        setTimeout(()=>navigate("/admin/hospital"),2000)
      }else{
        toast(<p>{res.data.error}</p>,toastCommonProps("top-right", "toast_alert",1000));
        setTimeout(()=>navigate("/admin/hospital"),2000)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onClickConfirm = () =>{
    if(flag === "customerdetail"){
      defaultAnswer()
      showConfirmModal()
    }else if(flag === "adminDelete"){
      deleteAdmin()
    }else if(flag === "hospitalDelete"){
      deleteHospitalId()
    }
  }
  return (
      <CustomCenterModal
      onClickFc={showConfirmModal}
      customlayout={"p-[32px] rounded-md bg-gray_10 overflow-auto space-y-4 p-2"}>
        <span>
          {flag === "customerdetail"
            ? "작성 중인 답변을 삭제하시겠습니까?"
            : "정말 삭제하시겠습니까?"}
        </span>
        <div className="pt-[24px] flex gap-x-[12px]">
          <BGButtonCustom
            onClick={showConfirmModal}
            content={"취소"}
            bgcolor={"bg-gray_70"}
            textcolor={"text-gray_100"}
            textsize={"text-body_2"}
            width={'50%'}
          />
          <BGButtonCustom
            onClick={onClickConfirm}
            content={"확인"}
            bgcolor={"bg-gray_70"}
            textcolor={"text-gray_100"}
            textsize={"text-body_2"}
            width={'50%'}
          />
        </div>
      </CustomCenterModal>      
  );
};

export default ConfirmModal;
