import React from 'react'

const ContentLayout = ({children}) => {
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-none order-1 w-full h-full p-0 grow-0">
      <div className="flex flex-col items-start p-[24px] bg-[#FCFCFC] rounded-b-[24px] rounded-r-3xl w-full shadow-md">
        {children}
      </div>
    </div>
  )
}

export default ContentLayout