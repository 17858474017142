import { useLocation } from "react-router-dom";
const MainMemberCard = ({
  mainText,
  subText1,
  subText2,
  totalUserCount,
  newUserCount,
  onClick,
}) => {
  const path = useLocation().pathname;
  const divideText = (text,count,paddingSide) =>{
    return(
      <div className={"w-1/2 "+paddingSide}>
      <h3 className="text-caption1_600 text-gray_100 py-2">
        {text}
      </h3>
      <div className="flex justify-end items-center">
        <h3 className="text-h1 h1 font-black text-black">
          {count}
        </h3>
        <span className="text-subtitle_1 font-semibold text-black">
          명
        </span>
      </div>
    </div>
    )
  }
  return (
    <div className="col-span-2 bg-gray_10 rounded-3xl p-6 shadow-md cursor-pointer"
    onClick={onClick}
    >
      <div className="flex justify-between">
      <h2 className="text-body1_600 text-black">{mainText} 수</h2>
      {!path.includes("hospital")&&
      <div className="flex items-center gap-2 cursor-pointer">
        <span className="text-body2_400 text-black">더보기</span>
        <img
          src="/images/admin/icon/arrow_next.svg"
          alt="더보기 아이콘"
          className="p-1"
        />
        </div>
        }
      </div>
      <div className="flex justify-between divide-x">
        {divideText(subText1,totalUserCount," pr-4 ")}
        {divideText(subText2,newUserCount," pl-4 ")}
      </div>
    </div>
  );
};
export default MainMemberCard;
