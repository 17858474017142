import React from "react";

const InfoSkeleton = ({ info, reply = false }) => {
  //40
  const bg = reply ? "bg-gray_40" : "bg-gray_20";
  return (
    <>
      <div
        className={
          "grid grid-cols-2 gap-3 justify-items-stretch animate-pulse " +
          (info ? "" : "p-4")
        }
      >
        <div className="flex flex-col gap-2 overflow-hidden">
          {/* <div className="absolute bg-gradient-to-r from-transparent via-gray_30 to-transparent h-[296px] w-[100px] -translate-x-full animate-[shimmer_1.5s_infinite]"></div> */}
          <div className={`${bg} rounded h-[180px] `}></div>
          <div className="flex flex-col gap-1 w-full">
            <div className={`${bg} w-[40%] h-4 rounded-xl `}></div>
            <div className={`${bg} w-full h-6 rounded-xl`}></div>
            <div className={`${bg} w-[80%] h-6 rounded-xl`}></div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className={`${bg} rounded h-[180px] `}></div>
          <div className="flex flex-col gap-1 w-full">
            <div className={`${bg} w-[40%] h-4 rounded-xl `}></div>
            <div className={`${bg} w-full h-6 rounded-xl`}></div>
            <div className={`${bg} w-[80%] h-6 rounded-xl`}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoSkeleton;
