import { AnimatePresence, motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FloatingMenu = ({ openFloating }) => {
  const navigate = useNavigate();
  const datas = [
    {
      id: 1,
      idName: "meal",
      title: "식단",
      linkTo: "/meal/record",
    },
    {
      id: 2,
      idName: "weight",
      title: "체중",
      linkTo: "/weight/record",
    },
    {
      id: 3,
      idName: "symptom",
      title: "증상",
      linkTo: "/symptom/record",
    },
    {
      id: 4,
      idName: "medicine",
      title: "복약",
      linkTo: "/drugalarm",
    },
    {
      id: 5,
      idName: "treatment",
      title: "진료",
      linkTo: "/treatment",
    },
  ];

  const backgroundControls = useAnimation();
  const menuControls = useAnimation();
  const circlesControls = useAnimation();

  const sequence = async () => {
    if (openFloating === true) {
      await backgroundControls.start({
        display: "flex",
        top: "-88px",
        transition: {
          duration: 0.1,
        },
      });
      await backgroundControls.start({
        width: "288px",
        transition: {
          duration: 0.1,
        },
      });
      await circlesControls.start((i) => ({
        left: `${Number(i - 2) * 58 + 143}px`,
        transition: {
          duration: 0.15,
        },
      }));
      await circlesControls.start({
        width: 0,
        height: 0,
        opacity: 0,
        transition: {
          duration: 0.2,
        },
      });
      await menuControls.start({
        width: "full",
        height: "full",
        opacity: 100,
        transition: {
          duration: 0.1,
        },
      });
    } else {
      await menuControls.start({
        opacity: 0,
        transition: {
          duration: 0.1,
        },
      });
      await circlesControls.start({
        width: "44px",
        height: "44px",
        opacity: 100,
        transition: {
          duration: 0.2,
        },
      });
      await circlesControls.start((i) => ({
        left: "50%",
        transition: {
          duration: 0.15,
        },
      }));
      await backgroundControls.start({
        width: "64px",
        transition: {
          duration: 0.1,
        },
      });
      await backgroundControls.start({
        top: "-20px",
        zIndex: -10,
        transition: {
          duration: 0.02,
        },
      });
      backgroundControls.start({
        display: "none",
      });
    }
  };

  useEffect(() => {
    sequence();
  }, [openFloating]);

  return (
    <>
      <motion.div
        animate={backgroundControls}
        className="absolute left-1/2 top-0 hidden h-fit w-16 -translate-x-1/2 items-center justify-between rounded-[54px] bg-main px-2 py-2"
        // initial={{ width: 0, top: 0 }}
        // animate={{ width: "80%", top: "-88px" }}
        // exit={{ width: 0, top: 0, zIndex: -10, height: 0 }}
        // transition={{
        //   width: {
        //     type: "spring",
        //     stiffness: 800,
        //     damping: 50,
        //     repeatDelay: 50,
        //   },
        //   top: { type: "spring", stiffness: 800, damping: 50, duration: 50 },
        // }}
      >
        <ul className="h-11">
          {[...Array(5)].map((val, index) => (
            <motion.p
              key={index}
              animate={circlesControls}
              custom={index}
              className="absolute -translate-x-1/2 -translate-y-1/2 bg-white rounded-full left-1/2 top-1/2 h-11 w-11"
            ></motion.p>
          ))}
        </ul>

        {datas.map((data) => (
          <motion.div
            key={data.id}
            animate={menuControls}
            className="flex-col items-center justify-between gap-0 m-auto text-white opacity-0"
            onClick={() => navigate(`${data.linkTo}`)}
          >
            <img
              className="w-6 h-6"
              src={`/images/mobile/icon/menu/${data.idName}.svg`}
              alt={`${data.title}기록`}
            />
            <p className="text-[13px] font-semibold">{data.title}</p>
          </motion.div>
        ))}
      </motion.div>
    </>
  );
};

export default FloatingMenu;
