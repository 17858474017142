import React from "react";
import { useSelector } from "react-redux";
import OthersGraph from "./graph/OthersGraph";
import OthersFeelGraph from "./graph/OthersFeelGraph";
import TableHeader from "./common/TableHeader";
import OthersAreaGraph from "./graph/OthersAreaGraph";

const OthersGraphBox = ({ dayNo }) => {
  const etcWater = useSelector((state) => state.management.etcStaticsWater);
  const etcAlcohol = useSelector((state) => state.management.etcStaticsAlcohol);
  const etcFeel = useSelector((state) => state.management.etcStaticsFeel);
  const etcSmoke = useSelector((state) => state.management.etcStaticsSmoke);

  return (
    <>
      <div className="grid grid-cols-2 gap-4 ">
        <div className="space-y-4 ">
          <TableHeader title={"물 섭취량(ml)"} />
          {dayNo === "최근 90일" ? (
            <OthersAreaGraph
              barHColor={"blue_h"}
              graphOption={dayNo}
              measureUnit="ml"
              dataLists={etcWater}
              color="#A9EAFF"
            />
          ) : (
            <OthersGraph
              barHColor={"blue_h"}
              graphOption={dayNo}
              measureUnit="ml"
              dataLists={etcWater}
              color="#A9EAFF"
            />
          )}
        </div>
        <div className="space-y-4 ">
          <TableHeader title={"기분"} />
          <OthersFeelGraph
            barHColor={"yellow_h"}
            graphOption={dayNo}
            measureUnit="placeHolder"
            dataLists={etcFeel}
          />
        </div>
        <div className="space-y-4 ">
          <TableHeader title={"술 섭취량(ml)"} />
          {dayNo === "최근 90일" ? (
            <OthersAreaGraph
              barHColor={"gray_h"}
              graphOption={dayNo}
              measureUnit="ml"
              dataLists={etcAlcohol}
              color="#A39F9B"
            />
          ) : (
            <OthersGraph
              barHColor={"gray_h"}
              graphOption={dayNo}
              measureUnit="ml"
              dataLists={etcAlcohol}
              color="#A39F9B"
            />
          )}
        </div>
        <div className="space-y-4 ">
          <TableHeader title={"담배(개)"} />
          {dayNo === "최근 90일" ? (
            <OthersAreaGraph
              barHColor={"gray_h"}
              graphOption={dayNo}
              measureUnit="개"
              dataLists={etcSmoke}
              color="#A39F9B"
            />
          ) : (
            <OthersGraph
              barHColor={"gray_h"}
              graphOption={dayNo}
              measureUnit="개"
              dataLists={etcSmoke}
              color="#A39F9B"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OthersGraphBox;
