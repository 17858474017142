import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchSurveyResult } from "../../../../features/mobile/questionHealthSlice";
import { checkQuestionsComplete } from "../../../../features/mobile/otherSlice";
import { makeRecommendMeal } from "../../../../features/mobile/recommendSlice";
import { pointFn } from "../../components/logic";
import HeadCont from "../../components/common/HeadCont";
import MenuBar from "../../components/common/MenuBar";
import TitleBox from "../../components/common/TitleBox";
import ResultCard from "../../components/result/ResultCard";
import IdCard from "../../components/result/IdCard";
// import HealthCard from "../../components/result/HealthCard";
import Btn from "../../components/common/Btn";
import Dialog from "../../components/common/Dialog";
import useGAEvent from "../../hooks/useGAEvent";

// type of weightInfo
const weightInfoType = 4;

// index of each info within resultLists.datas
const weightInfoIdx = 4;
const foodInfoIdx = 0;
const proteinInfoIdx = 1;
const foodBalanceInfoIdx = 2;
const cationFoodInfoIdx = 3;

const selectionArr = [
  { name: "체중관리", type: 4 },
  { name: "영양 섭취 목표", type: 1 },
  { name: "영양 밸런스", type: 3 },
  { name: "단백질 섭취 안내", type: 2 },
  { name: "주의식품", type: 5 },
  { name: "맞춤 식단", type: 0 },
];

const Result = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef([]);

  const { sendSingleEvent } = useGAEvent();

  const { loginInfo } = useSelector((state) => state.commonApp);
  const { resultLists } = useSelector((state) => state.questionHealth);
  const { questionsCompleteCheck } = useSelector((state) => state.others);
  const [errorMsg, setErrorMsg] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  // const resultDatas = resultLists?.datas || null;
  const [resultDatas, setResultDatas] = useState(null);
  const [makeRecommendMealReady, setmakeRecommendedMealReady] = useState(true);
  const [openAll, setOpenAll] = useState(false);

  const [clickMenu, setClickMenu] = useState(false);
  const [moveMenu, setMoveMenu] = useState("");
  const [moveRecommend, setmoveRecommend] = useState("");

  // stores the selected categories type. (initial type: 4)
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const datas = [
    {
      id: 1,
      title: "하루 필요 열량",
      data: resultLists?.needKcal,
      unit: "kcal",
    },
    {
      id: 2,
      title: "하루 필요 단백질",
      data: resultLists?.needProtein,
      unit: "g",
    },
    { id: 3, title: "목표 체중", data: resultLists?.weightState },
  ];

  const handleSelection = (data, idx) => {
    // check if it will openAll
    if (selectedTypes.length === 5 && !selectedTypes?.includes(data.type)) {
      setOpenAll(true);
    } else {
      setOpenAll(false);
    }
    // toggle
    if (!(selectedTypes.length === 1 && selectedTypes?.includes(data.type))) {
      setSelectedTypes([data.type]);
      // setSelectedTypes((prev) =>
      //   prev.includes(data.type)
      //     ? prev.filter((type) => type !== data.type)
      //     : [...prev, data.type]
      // );

      // scroll
      if (ref.current && ref.current.length > 1) {
        setTimeout(
          () =>
            ref.current[idx]?.scrollIntoView({
              inline: "center",
              behavior: "smooth",
            }),
          100
        );
      }
    }
    // send GA event

    sendSingleEvent({
      event: "nutrition_goal_detail",
      label: "nutrition_detail_button_name",
      value: data.name,
    });
  };

  useEffect(() => {
    return () => {
      console.log("a;lkdfja;slkdfja;lsk");
      console.log(moveRecommend);
      if (moveMenu !== "") {
        navigate(moveMenu);
        return;
      }
      if (moveRecommend !== "") {
        // onMakeRecommend();

        navigate(moveRecommend);
        return;
      }
      if (location.pathname === "/result") {
        navigate(-1); // 직접 url로 경로를 주게 되면 그 전 히스토리가 쌓이기때문에 이동한 뒤 뒤로가기 버튼을 사용하면 다시 설문조사로 돌아가게 됨 / 그래서 -1로 사용
      }
      setClickMenu(false);
    };
  }, [location, moveMenu, moveRecommend]);

  const onMakeRecommend = () => {
    // setmoveRecommend("/recommend");

    if (
      !questionsCompleteCheck.healthComplete ||
      !questionsCompleteCheck.dietLifeComplete
    ) {
      console.log("여기는");
      setmoveRecommend("/recommend");
      navigate(`/recommend`);
    } else {
      setmakeRecommendedMealReady(false);
      dispatch(makeRecommendMeal({ userId: loginInfo.userId })) //이 식단 불러오기 로직 - 맞춤식단 받기 버튼 눌렀을때만 부르기
        .unwrap()
        .then((res) => {
          if (res.ok) setmoveRecommend("/recommend");
          else {
            setErrorMsg(res.error);
            setOpenDialog(true);
            setmakeRecommendedMealReady(true);
          }
        })
        .catch((err) => {
          setmakeRecommendedMealReady(true);
          console.log(err);
        });
    }
  };

  const separateUnitAndText = (text) => {
    const updatedText = text?.replace(/([^ ])\(/g, "$1 (");
    return updatedText;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(checkQuestionsComplete(loginInfo.userId));
    dispatch(fetchSurveyResult(loginInfo.userId))
      .unwrap()
      .then((res) => {
        setResultDatas(res.datas);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const closeAllButtonHeight = 28;
    const headerHeight = 56;
    if (openAll) {
      // select all the types
      setTimeout(
        window.scrollTo({
          behavior: "smooth",
          top: ref.current[0].offsetTop - closeAllButtonHeight - headerHeight,
        }),
        300
      );
    }
  }, [openAll]);

  // initializing sortedData and SelectedType with Weight info
  useEffect(() => {
    // when the fetchSurveyResults succeeds
    if (sortedData.length === 0 && resultDatas) {
      // initializing it with 체중관리. (its the 4th idx)
      setSortedData([
        resultDatas[weightInfoIdx],
        resultDatas[foodInfoIdx],
        resultDatas[foodBalanceInfoIdx],
        resultDatas[proteinInfoIdx],
        resultDatas[cationFoodInfoIdx],
      ]);
      setSelectedTypes([weightInfoType]);
    }
  }, [resultDatas, sortedData]);

  return (
    <>
      <HeadCont
        rightIcon={"no icon"}
        onLeftAction={() => navigate(location.state ? -1 : -1)}
      />
      <section className={`h-fit pb-16 pt-16`}>
        {questionsCompleteCheck && questionsCompleteCheck.healthComplete ? (
          <div
            className={`flex flex-col justify-between`}
            style={{
              minHeight: window.innerHeight - 77 - 56, //77: menuBar, 56: header, 30: toggleButton
            }}
          >
            <article>
              <div className="ml-6">
                <TitleBox option={5} title={"영양 관리 목표"} />
              </div>
              <div className="mx-6 mt-1 space-y-[28px] rounded-2xl bg-orange_10 px-6 py-3">
                <ul className="space-y-3">
                  {datas.map((data) => (
                    <li key={data.id} className="flex justify-between">
                      <span className="shrink-0 text-body3_700 text-gray_160">
                        {data.title}
                      </span>
                      <span className="text-right text-body3_700 text-main">
                        {/* Seperate the text and 목표 체중  */}
                        {isNaN(data.data)
                          ? separateUnitAndText(data.data)
                          : pointFn(data.data)}
                        {data.unit}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mx-7 flex flex-wrap justify-center gap-2.5 pt-2.5 ">
                {selectionArr.map((data, idx) => (
                  <div
                    className={`mb-2.5 px-2.5 py-3.5 ${
                      selectedTypes.includes(data.type) ? "bg-main" : "bg-white"
                    } shrink rounded-[20px]`}
                    style={{ boxShadow: "0px 3px 7px #EBE7C9" }}
                    key={idx}
                    onClick={() => handleSelection(data, idx)}
                  >
                    <span
                      className={`text-caption1_700 ${
                        selectedTypes.includes(data.type)
                          ? "text-white"
                          : "text-gray_160"
                      }`}
                    >
                      {data.name}
                    </span>
                  </div>
                ))}
              </div>
            </article>

            <div>
              <div
                className="mb-1.5 mr-8 flex items-center justify-end gap-1.5"
                onClick={(e) => {
                  if (openAll) {
                    setSelectedTypes([weightInfoType]);
                  } else {
                    setSelectedTypes([0, 1, 2, 3, 4, 5]);
                  }
                  setOpenAll(!openAll);
                  sendSingleEvent({
                    event: "nutrition_goal_detail",
                    label: "nutrition_detail_button_name",
                    value: "모두열기",
                  });
                }}
              >
                <span
                  className={`text-caption2_700 ${
                    openAll ? "text-gray_120" : "text-gray_170"
                  }`}
                >
                  {openAll ? "모두닫기" : "모두열기"}
                </span>
                {openAll ? (
                  <img
                    alt="openAll"
                    src="/images/mobile/icon/checkbox_thick_gray.svg"
                  />
                ) : (
                  <img
                    alt="openAll"
                    src="/images/mobile/icon/checkbox_thick_dark.svg"
                  />
                )}
              </div>
              <div className="h- rounded-t-[32px] bg-white shadow-[0_0_12px_rgba(0,0,0,0.1)]">
                {sortedData?.length > 0 ? (
                  <ul className="overflow-auto">
                    {sortedData
                      // .filter((data) => selectedTypes.includes(data.type))
                      ?.map((data, idx) => (
                        <li
                          key={idx}
                          ref={(e) => (ref.current[idx] = e)}
                          className={`${
                            selectedTypes.includes(data?.type) ? "" : "hidden"
                          }`}
                        >
                          <ResultCard data={data} />
                        </li>
                      ))}

                    <li
                      className={`space-y-4 px-6 pb-9 pt-10 text-center ${
                        selectedTypes?.includes(0) ? "" : "hidden"
                      }`}
                      ref={(e) => (ref.current[5] = e)}
                    >
                      <div className="text-black text-subtitle3">
                        어떻게 드셔야 할지 막막하신가요?
                      </div>
                      <p className="text-body3_700 text-gray_160">
                        힐리어리가 추천하는 식단을 받아보세요.
                        <br />
                        환자분의 건강상태와
                        <br />
                        식습관에 맞춘 식단을 드립니다.
                      </p>
                      <Btn
                        id={"checkMealPlan"}
                        bgColor={"bg-main"}
                        textColor={"text-white"}
                        borderColor={"border border-main"}
                        title={"맞춤 추천 식단 확인하기"}
                        active={makeRecommendMealReady ? "on" : "ready"}
                        onAction={onMakeRecommend}
                      />
                    </li>
                  </ul>
                ) : (
                  <div className="h-10">
                    <div>{/* <  /> / */}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center bg-white p-9">
            <IdCard resultLists={resultLists} />
          </div>
        )}
      </section>
      <MenuBar setMoveMenu={setMoveMenu} />
      {openDialog && (
        <Dialog
          innerClass={"py-4 pl-6 pr-5"}
          btnClass={"mt-8"}
          title={"알림"}
          desc={errorMsg}
          onRightBtnAction={() => setOpenDialog(false)}
          onCoverAction={() => setOpenDialog(false)}
        />
      )}
    </>
  );
};

export default Result;
