import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkCloseTutorial } from "../../../features/mobile/otherSlice";

const Tutorial = ({ setTutorialState }) => {
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { questionsCompleteCheck } = useSelector((state) => state.others);
  const navigate = useNavigate();

  // const [status, setStatus] = useState(1);
  const dispatch = useDispatch();

  // useEffect(() => {
  //       dispatch(checkCloseTutorial(loginInfo.userId));
  // }, [status, dispatch, loginInfo.userId, navigate]);

  // const onChangeExplain = (e) => {
  //   if (e.target.checked) dispatch(checkCloseTutorial(loginInfo.userId));
  // };
  const onCloseGuide = async () => {
    try {
      const res = await dispatch(checkCloseTutorial(loginInfo.userId)).unwrap();

      if (res) {
        setTutorialState(false);
        window.location.reload();
      } else {
        console.log("가이드 닫기 실패!");
      }
    } catch (error) {
      console.error("가이드 닫기 중 오류 발생: ", error);
    }
  };

  return (
    <div className="h-auto w-full rounded-tl-[28px] bg-back_ground px-[11px] pb-[100px] ">
      <div className="flex flex-col pt-[30px]">
        {/* <div className="fixed z-10 flex items-center mx-auto text-white maxW bottom-24 right-4 text-body1_600 gap-x-2">
        <span>설명 다시 보지 않기</span>
        <input
          type="checkbox"
          className="tutorial"
          onChange={onChangeExplain}
        />
      </div> */}

        {/* 식사기록 */}
        <div className="py-2.5">
          <div className="absolute flex flex-row px-2.5">
            <div className=" flex w-[180px] flex-col items-start gap-2">
              <p className="text-xl font-bold text-black">식사기록</p>
              <span className="text-[15px] font-medium leading-[18px] text-neutral-500">
                오늘 드신 음식을 기록하고
                <br /> 건강관리를 시작해요!
              </span>
              <button
                className="w-[107px] justify-center gap-2.5 rounded-3xl bg-main p-2.5"
                onClick={() => {
                  if (questionsCompleteCheck?.healthComplete) {
                    navigate("/meal/record");
                  } else {
                    navigate("/question/health");
                  }
                }}
              >
                <span className="text-base font-semibold leading-[18px] tracking-tight text-white">
                  기록하기
                </span>
              </button>
            </div>
            <div className="relative flex bottom-5">
              <img
                src="/images/mobile/home/guide_meal.svg"
                alt="식사기록 가이드"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 items-start gap-2.5 pb-2.5 pt-[180px]">
            {/* 체중기록 */}
            <div
              className="flex w-full items-start gap-2.5"
              onClick={() => {
                if (questionsCompleteCheck?.healthComplete) {
                  navigate("/weight/record");
                } else {
                  navigate("/question/health");
                }
              }}
            >
              <div className="flex h-[218px] w-full flex-col items-start rounded-xl border bg-white px-4 py-2.5">
                <div className="flex flex-col items-start w-full gap-2">
                  <div className="text-xl font-bold text-black">체중 기록</div>

                  <div className="text-sm font-medium text-neutral-500">
                    현재 체중이 <br /> 얼마나되나요?
                  </div>
                  <div className="flex items-center justify-center w-full">
                    <img
                      src="/images/mobile/home/guide_weight.svg"
                      alt="체중기록 가이드"
                      className="translate-x-[-10px] translate-y-[-2px] transform"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* 증상기록 */}
            <div
              className="flex w-full items-start gap-2.5"
              onClick={() => {
                if (questionsCompleteCheck?.healthComplete) {
                  navigate("/symptom/record");
                } else {
                  navigate("/question/health");
                }
              }}
            >
              <div className="flex h-[218px]  w-full flex-col items-start rounded-xl border bg-white px-4 py-2.5">
                <div className="flex flex-col items-start w-full gap-2">
                  <div className="text-xl font-bold text-black">증상 기록</div>

                  <div className="text-sm font-medium text-neutral-500">
                    불편한 증상은 <br /> 없으셨나요?
                  </div>
                  <div className="flex items-center justify-center w-full">
                    <img
                      src="/images/mobile/home/guide_symptom.svg"
                      alt="증상기록 가이드"
                      className="translate-y-[-5px] transform"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 items-start gap-2.5 pb-5">
            {/* 걸음수 */}
            <div
              className="flex w-full items-start gap-2.5"
              onClick={() => navigate("/step")}
            >
              <div className="flex h-[218px] w-full flex-col items-start rounded-xl border bg-white">
                <div className="flex flex-col items-start gap-2 px-4 py-2.5">
                  <div className="text-xl font-bold text-black">걸음수</div>
                  <div className="text-sm font-medium text-neutral-500">
                    오늘 나의 걸음수는?
                  </div>
                </div>
                <img
                  src="/images/mobile/home/guide_step.svg"
                  alt="걸음수 가이드"
                  className="translate-x-[-10px] transform"
                />
              </div>
            </div>
            {/* 복약알림 */}
            <div
              className="flex w-full items-start gap-2.5"
              onClick={() => navigate("/drugalarm")}
            >
              <div className="flex h-[218px] w-full flex-col items-start rounded-xl border bg-white">
                <div className="flex flex-col items-start gap-2 px-4 py-2.5">
                  <div className="text-xl font-bold text-black">복약 알림</div>

                  <div className="text-sm font-medium text-neutral-500">
                    약 먹을 시간을 놓치고 <br /> 있지는 않나요?
                  </div>
                </div>

                <img
                  src="/images/mobile/home/guide_drug.svg"
                  alt="복약알림 가이드"
                  className="translate-x-[30px] translate-y-[-6px] transform"
                />
              </div>
            </div>
          </div>
          <div className="pt-[10px]" onClick={() => navigate("/treatment")}>
            <div className="flex h-[109px] shrink-0 flex-row items-center justify-between gap-2.5 rounded-xl border bg-white px-4">
              <img
                src="/images/mobile/home/guide_treatment.svg"
                alt="진료일정 가이드"
                className="translate-x-[10px] translate-y-[-10px] transform"
              />
              <div className="flex flex-col items-end gap-2 text-end">
                <p className="text-xl font-semibold text-black">
                  진료일정 알림
                </p>
                <span className="text-[15px] font-medium leading-[18px] text-neutral-500">
                  예정된 진료 일정을 기록하고
                  <br /> 알림 받으세요!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="ml-auto flex h-[38px] w-[95px] items-end gap-2.5 rounded-lg border border-main bg-white p-2.5"
          onClick={() => onCloseGuide()}
        >
          <button className="text-base font-medium leading-[18px] tracking-tight text-main">
            가이드 닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
