import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { onSetSection } from "../features/admin/commonSlice";
import { Footer } from "../screens/admin/components/common/Footer";
import UseAndPolicyModal from "../screens/admin/components/common/UseAndPolicyModal";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
const Layout = ({ children }) => {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const backgroundColor = [
  //   "/nav",
  //   "/setting",
  //   "/alarm",
  //   "/recommend",
  //   "/account",
  //   "/meal/record",
  //   "/mypage",
  //   "/bookmark/meal",
  //   "/bookmark/recipe",
  //   "/like",
  //   "/dislike",
  //   "/info",
  //   "/recommend/recipe",
  //   "/bookmark/meal",
  //   "/recommend/share",
  //   "/community",
  //   // "/healthinfo",
  // ].includes(path)
  //   ? // path.includes(`/recommend/recipe`) ||
  //     // path.includes(`/bookmark/meal`) ||
  //     // path.includes(`/recommend/share`) ||
  //     // path.includes(`/info`) ||
  //     // path.includes(`/community`)
  //     " bg-white "
  //   : " bg-back_ground min-h-screen "; //||  ? ' bg-white ' : 'bg-back_ground min-h-screen ')

  const backgroundColor =
    [
      "/nav",
      "/setting",
      "/alarm",
      "/recommend",
      "/account",
      "/meal/record",
      "/mypage",
      "/healthinfo",
      "/treatment",
      "/weight/record",
      "/step",
      "/symptom/record",
      // "/bookmark/meal",
      // "/bookmark/recipe",
      // "/like",
      // "/dislike",
    ].includes(path) ||
    path.includes(`/recommend/recipe`) ||
    path.includes(`/bookmark/meal`) ||
    path.includes(`/recommend/share`) ||
    path.includes(`/info`) ||
    path.includes(`/community`) ||
    path.includes(`/recommend/findrecipe`)
      ? " bg-white "
      : " bg-back_ground min-h-screen "; //||  ? ' bg-white ' : 'bg-back_ground min-h-screen ')

  //이용약관 개인정보처리방침 모달
  const FooterModalTitle = useSelector((state) => state.toggleAdmin.footerM);

  useEffect(() => {
    let pageOpen = false;
    if (!pageOpen) {
      if (path === "/admin") {
        dispatch(onSetSection("홈"));
      }
      if (path.includes("/admin/management")) {
        dispatch(onSetSection("건강관리"));
      }
      if (path === "/admin/contents") {
        dispatch(onSetSection("컨텐츠 관리"));
      }
      if (path === "/admin/board") {
        dispatch(onSetSection("게시판 관리"));
      }
      if (path === "/admin/account") {
        dispatch(onSetSection("계정 관리"));
      }
    }
    return () => {
      pageOpen = true;
    };
  }, [path, dispatch]);

  useEffect(() => {
    // 푸시 종류에 따라 페이지 이동
    window.movePage = (params) => {
      const object = JSON.parse(params);
      switch (object.movePage) {
        case "eatRecord":
          navigate("/meal/record");
          break;
        case "inputDiary":
          navigate("/diary");
          break;
        case "communityPosting":
          if (path.includes("community"))
            navigate(`/community/${object.id}`, { state: true, replace: true });
          else navigate(`/community/${object.id}`);
          break;
        case "infoPosting":
          if (path.includes("info"))
            navigate(`/info/${object.id}`, { state: true, replace: true });
          else navigate(`/info/${object.id}`);
          break;
        case "recommendMeal":
          navigate("/recommend");
          break;
        case "notice":
          navigate("/notice");
          break;
        case "drug":
          navigate("/drugalarm");
          break;
        case "treatment":
          navigate("/treatment");
          break;
        default:
          console.log(object.movePage);
          break;
      }
    };

    window.flutterCalled = (params) => {
      const object = JSON.parse(params);
      const functionName = object.function;
      const values = object.value.split("/");

      if (functionName === "movePage") {
        switch (values[0]) {
          case "event":
            navigate("event");
            break;
          default:
            console.log(object.movePage, values[0]);
            break;
        }
      }
    };
  });

  return (
    <>
      {path.includes(`/admin`) ? (
        <>
          {FooterModalTitle !== "" && <UseAndPolicyModal />}
          <main className="flex h-screen justify-center bg-gray_15 font-sans antialiased">
            {children}
            {path === "/admin/signin" && <Footer />}
          </main>
        </>
      ) : path.includes(`/landing`) || path === "/" ? (
        <main>{children}</main>
      ) : (
        <main
          className={
            "main maxW mx-auto flex flex-col font-sans antialiased " +
            backgroundColor
          }
        >
          {children}
        </main>
      )}
    </>
  );
};

export default Layout;
