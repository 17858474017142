import React from "react";

const BtnForQuestion = ({ active, onAction1, onAction2, i }) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 z-10 px-4 py-4 mx-auto text-center maxW bg-back_ground">
      <div className="flex gap-x-[8px]">
        <button
          onClick={onAction1}
          disabled={i === 0}
          className={
            "w-1/3 rounded-2xl py-4 " +
            (i === 0
              ? " border bg-[#dedede] text-btn_700 text-white"
              : " border border-main text-btn_700 text-main")
          }
        >
          이전
        </button>
        <button
          onClick={active === "on" || active === undefined ? onAction2 : null}
          className={
            `w-2/3 rounded-2xl py-4 text-btn_700 text-white ` +
            (active === "ready"
              ? "bg-blue-300"
              : active === "disabled"
              ? "bg-gray_60 "
              : "bg-main active:bg-blue-600")
          }
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default BtnForQuestion;
