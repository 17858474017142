import React from "react";
import { pointFn } from "../../components/logic";

const ProgressBar = ({
  title,
  data,
  data2,
  unit,
  bgColor,
  innerColor,
  textColor,
}) => {
  return (
    <div className="my-3 space-y-2">
      <div className="flex justify-between">
        <h1 className="text-center text-base font-semibold tracking-tight text-neutral-600">
          {title}
          <span>
            {" "}
            {data ? pointFn(data) : "0"}
            {unit}
          </span>
        </h1>
        {data && data2 ? (
          (data2 / data) * 100 >= 100 && (data2 / data) * 100 < 110 ? (
            <span
              className={
                "rounded-r-full rounded-t-full px-3 py-1 text-caption2_700 text-white " +
                (unit === "kcal" ? "bg-main" : "bg-[#51CCF3]")
              }
            >
              {"목표 성공!"}
            </span>
          ) : (
            <span className="rounded-r-full rounded-t-full bg-gray_30 px-4 py-2 text-[13px] font-semibold tracking-tight text-neutral-600">
              {parseInt((data2 / data) * 100) + "%"}
            </span>
          )
        ) : (
          <span className="rounded-r-full rounded-t-full bg-gray_30 px-4 py-2 text-[13px] font-semibold tracking-tight text-neutral-600">
            0%
          </span>
        )}
      </div>
      <div className="mx-auto w-full">
        <div
          className={"flex h-[35px] overflow-hidden rounded-full " + bgColor}
        >
          {/* <div style={{ minWidth: (data && data2) ? (data2 / data * 100) < 25 ? 70 : parseInt(data2 / data * 100) + "%" : null }} className={'relative flex items-center rounded-full '
                        + innerColor + (parseInt(data2 / data * 100) > 100 ? ' justify-end ' : ' justify-end ') + (data2 ? 'min-w-[50px] p-3' : '')}>
                        <h2 className={'absolute text-caption1_700 ' + textColor + ((data > 0 && data2 > 0 && data >= data2) ? ' right-3' : ' left-4')}>{data2 ? pointFn(data2) : '0'}</h2>
                    </div> */}
          <div
            style={{
              minWidth:
                data && data2
                  ? (data2 / data) * 100 < 15
                    ? 58
                    : (data2 / data) * 100 > 100
                    ? 100 + "%"
                    : parseInt((data2 / data) * 100) + "%"
                  : null,
            }}
            className={
              "relative flex items-center justify-end rounded-full " +
              innerColor +
              (data2 ? " min-w-[50px] p-3" : "")
            }
          >
            <h2
              className={
                "absolute my-0 text-caption1_700 " +
                textColor +
                (data > 0 && data2 > 0 ? " right-3" : " left-4")
              }
            >
              {data2 ? pointFn(data2) : "0"}
              {/* {unit === "kcal" ? "Kcal" : "g"} */}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
