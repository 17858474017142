import React from "react";

const ProfilePicture = ({ onClickPostInner, writer, location }) => {
  // location: 'post' || 'comment'
  return (
    <>
      <div
        className={
          (location === "post" ? "h-12 w-12 " : "h-9 w-9 ") +
          " mr-1 overflow-hidden rounded-[48px] pt-3 " +
          (writer ? "bg-gray_30 " : "bg-main") +
          "shrink-0"
        }
        onClick={onClickPostInner}
      >
        <img
          className={
            (location === "post"
              ? "h-[56px] w-[32px] "
              : "h-[45px] w-[27px] ") + " mx-auto mt-[-5px]"
          }
          src={`/images/mobile/character/${
            writer ? writer?.characterPhoto : "doctor_to_right"
          }.svg`}
          alt="writer icon"
        />
      </div>
    </>
  );
};

export default ProfilePicture;
