import { CenterHeadlist } from "../../components/common/TableHeadList";
import dayjs from "dayjs";

const CenterMemberList = ({ onMemberDetail, filteredUsers }) => {
  const repeatTd = (idx, data) => {
    const commoncss =
      "text-center py-4 text-caption_1 text-black px-4 border-gray_80 border-b";
    const 이용문의리스트 = [
      {
        tdclass:
          "border-b border-l border-gray_80 text-black text-center " +
          (idx + 1 === filteredUsers?.length ? " rounded-bl-lg" : ""),
        tdcontent: data.no,
      },

      {
        tdclass:
          "text-center py-4 text-caption_1 px-4 " +
          (data.title
            ? " text-black border-gray_80 border-b "
            : " text-gray-400 italic "),
        tdcontent:
          data.title?.length > 50
            ? data.title.slice(0, 50) + "..."
            : data.title?.length !== 0
            ? data.title
            : "제목 없음",
      },

      {
        tdclass: commoncss,
        tdcontent:
          data.nickName && data.nickNameProtector === data.nickName ? (
            <p>{data.nickName}</p>
          ) : (
            <>
              <p>{data.nickName}</p>
              {data.nickNameProtector ? (
                <p>(보:{data.nickNameProtector})</p>
              ) : (
                ""
              )}
            </>
          ),
      },

      { tdclass: commoncss, tdcontent: data.createdAt.slice(0, 10) },

      {
        tdclass: commoncss,
        tdcontent: (
          <span
            className={
              "flex items-start justify-center rounded-[2px] border border-gray_10 px-[6px] py-[2px] " +
              (data.aWaiting ? " bg-gray_100 " : " bg-gray_30 ")
            }
          >
            {data.aWaiting ? "답변 완료" : "답변 대기"}
          </span>
        ),
      },

      {
        tdclass:
          " border-r px-4 font-semibold text-black border-b border-gray_80 text-center" +
          (idx + 1 === filteredUsers?.length ? " rounded-br-lg" : ""),
        tdcontent: data.aWaiting
          ? dayjs(data.answerDate).format("YYYY.MM.DD(dd)")
          : "-",
      },
    ];
    return (
      <>
        {이용문의리스트?.map((el, index) => (
          <td className={el.tdclass} key={index}>
            {el.tdcontent}
          </td>
        ))}
      </>
    );
  };
  return (
    <>
      <table
        className="w-full col-span-3 border-separate table-auto 3xl:col-span-2"
        cellSpacing={0}
      >
        <thead>
          <tr className="bg-gray_60">
            {CenterHeadlist?.map((head, idx) => (
              <th
                key={idx}
                className={
                  "border-y border-gray_80 p-4 text-black first:rounded-tl-lg first:border-l last:rounded-tr-lg last:border-r" +
                  (idx === 0 ? " w-[80px] " : "")
                }
              >
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredUsers !== undefined &&
            filteredUsers?.map((data, idx) => (
              <tr
                key={idx}
                onClick={() => onMemberDetail(data)}
                className={
                  "cursor-pointer px-6  py-3 hover:bg-primary_10 " +
                  (idx % 2 === 1 ? "bg-gray_95" : "bg-gray_10")
                }
              >
                {repeatTd(idx, data)}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default CenterMemberList;
