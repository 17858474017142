import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import ShareBtn from "./ShareBtn";

const HeadCont = ({
  option,
  title,
  leftIcon,
  rightIcon,
  rightIcon2,
  onLeftAction,
  onRightAction,
  rightTitle,
  id,
  rightTitleAction,
  rightIcon2Action,
  transparent,
  topline,
  underline,
  idName,
  i,
  foodId,
  loading,
}) => {
  const prevIcon = useMemo(
    () => ({
      background: "url('/images/mobile/icon/arrow_left.svg') no-repeat center",
    }),
    []
  );
  const closeIcon = useMemo(
    () => ({
      background: "url('/images/mobile/icon/close.svg') no-repeat center",
    }),
    []
  );
  const searchIcon = useMemo(
    () => ({
      background: "url('/images/admin/icon/search.svg') no-repeat center",
    }),
    []
  );

  const homeIcon = useMemo(
    () => ({
      background: "url('/images/mobile/icon/to_home.svg') no-repeat center",
    }),
    []
  );

  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const iconSet = (icon) => {
    switch (icon) {
      case "no icon":
        return null;
      case "close":
        return closeIcon;
      case "search":
        return searchIcon;
      case "home":
        return homeIcon;
      default:
        return icon;
    }
  };
  return (
    <header
      className={
        "maxW fixed top-0 z-10 w-full p-2 " +
        (transparent === "on"
          ? "bg-transparent"
          : transparent === "white"
          ? "bg-white"
          : "bg-back_ground") +
        (underline ? " border-b " : " ") +
        (topline ? " border-t " : " ")
      }
    >
      {option === "recommend" ? (
        <div className="flex items-center justify-between w-full mx-auto">
          <span
            onClick={onLeftAction ? onLeftAction : goBack}
            className="w-10 h-10"
            style={leftIcon || prevIcon}
          ></span>
          <div className="flex items-center gap-2">
            {rightTitle && (
              <button
                id={id}
                onClick={rightTitleAction}
                className="rounded-full border border-gray_120 px-3 py-[6px] text-caption2_700 text-gray_120"
                disabled={loading ? false : true}
              >
                {rightTitle}
              </button>
            )}
            <ShareBtn option="day" foodId={foodId} />
            {rightIcon2 && (
              <span
                onClick={rightIcon2Action}
                className="inline-block w-12 border rounded-full h-9 border-gray_120"
                style={rightIcon2}
              ></span>
            )}
          </div>
        </div>
      ) : (
        <div className={"mx-auto flex w-full items-center justify-between"}>
          {leftIcon === "no icon" || i === 0 ? (
            <span className="w-10 h-10"></span>
          ) : (
            <span
              onClick={onLeftAction ? onLeftAction : goBack}
              className="w-10 h-10"
              style={leftIcon || prevIcon}
            ></span>
          )}
          {title && <h2 className="text-black text-body3_600">{title}</h2>}
          {option === "progress" && <ProgressBar option={"header"} />}
          <span
            id={idName}
            onClick={
              onRightAction
                ? onRightAction
                : rightIcon === "no icon"
                ? null
                : null
            }
            className="w-10 h-10 rounded-full"
            style={rightIcon ? iconSet(rightIcon) : null}
          ></span>
        </div>
      )}
    </header>
  );
};

export default HeadCont;
