import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  searchWeeklyMeal,
  addOrEditFood,
  deleteFood,
  fetchExistDatasOfYear,
  onResetChosenDate,
  onResetScrollNum,
} from "../../../../features/mobile/mealSlice";
import { checkQuestionsComplete } from "../../../../features/mobile/otherSlice";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import HeadCont from "../../components/common/HeadCont";
// import MenuBar from "../../components/common/MenuBar";
import TitleBox from "../../components/common/TitleBox";
import ProgressBar from "../../components/meal/ProgressBar";
import ToggleBar from "../../components/common/ToggleBar";
import InputBox from "../../components/meal/InputBox";
import FoodCard from "../../components/meal/FoodCard";
import FoodInputBox from "../../components/meal/FoodInputBox";
import Btn from "../../components/common/Btn";
import NotEatCard from "../../components/meal/NotEatCard";
import DialogCover from "../../components/common/DialogCover";
import CalendarHeader from "../../components/common/CalendarHeader";
import { saveChosenDate } from "../../../../features/mobile/commonSlice";

const Record = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  dayjs.locale("ko");
  const { loginInfo, chosenDate } = useSelector((state) => state.commonApp);
  const { questionsCompleteCheck } = useSelector((state) => state.others);
  const {
    weeklyMealLists,
    weeklyMealStatus,
    chosenDateOfAnalysis,
    existDaysOfYear,
    scrollNum,
  } = useSelector((state) => state.meal);
  const [openModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [status, setStatus] = useState({ option: "show", type: null }); //show: 보여주기, add: 입력하기
  const filtered = weeklyMealLists?.filter(
    (list) => list.mealDay === (chosenDate || startDate)
  )[0];

  const sectionRef = useRef([]);
  const datas = [
    { id: 1, mealType: "B", title: "아침 식사" },
    { id: 2, mealType: "BS", title: "아침 간식" },
    { id: 3, mealType: "L", title: "점심 식사" },
    { id: 4, mealType: "LS", title: "점심 간식" },
    { id: 5, mealType: "D", title: "저녁 식사" },
    { id: 6, mealType: "DS", title: "저녁 간식" },
  ];
  console.log(filtered);
  const onSaveAddorEdit = (info) => {
    let params = {
      userId: loginInfo.userId,
      mealType: status.type,
      mealDate: dayjs(chosenDate || startDate).format("YYYY-MM-DD"),
      food: info,
    };
    console.log(params);
    dispatch(addOrEditFood(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          let params = {
            userId: loginInfo.userId,
            mealDate: dayjs(chosenDate || startDate).format("YYYY-MM-DD"),
          };
          console.log(params);
          dispatch(searchWeeklyMeal(params));
          dispatch(
            fetchExistDatasOfYear({
              userId: loginInfo.userId,
              year: dayjs(chosenDate || startDate).format("YYYY"),
            })
          );
          setStatus({ option: "show", type: null });
        } else
          toast(
            <p>{res.error}</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
      })
      .catch((err) => console.log(err));
  };

  const onSaveNotEat = (type, answer) => {
    let params = {
      userId: loginInfo.userId,
      mealType: type,
      mealDate: dayjs(chosenDate || startDate).format("YYYY-MM-DD"),
      notEat: answer,
    };
    dispatch(addOrEditFood(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          let params = {
            userId: loginInfo.userId,
            mealDate: dayjs(chosenDate || startDate).format("YYYY-MM-DD"),
          };
          dispatch(searchWeeklyMeal(params));
          dispatch(
            fetchExistDatasOfYear({
              userId: loginInfo.userId,
              year: dayjs(chosenDate).format("YYYY"),
            })
          );
          setStatus({ option: "show", type: null });
        } else
          toast(
            <p>{res.error}</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
      })
      .catch((err) => console.log(err));
  };
  const onDelete = (food) => {
    let params = {
      userId: loginInfo.userId,
      mealType: status.type,
      mealDate: dayjs(chosenDate || startDate).format("YYYY-MM-DD"),
      foodId: food,
    };
    dispatch(deleteFood(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          let params = {
            userId: loginInfo.userId,
            mealDate: dayjs(chosenDate || startDate).format("YYYY-MM-DD"),
          };
          dispatch(searchWeeklyMeal(params));
          dispatch(
            fetchExistDatasOfYear({
              userId: loginInfo.userId,
              year: dayjs(chosenDate || startDate).format("YYYY"),
            })
          );
          setStatus({ option: "show", type: null });
        } else
          toast(
            <p>{res.error}</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (chosenDate > dayjs().format("YYYY-MM-DD")) {
      // 오늘 이후 날짜 선택못하도록 막기
      dispatch(saveChosenDate(dayjs().format("YYYY-MM-DD")));
    } else {
      // 아니면 해당 주간 식사기록 가져오기
      let params = {
        userId: loginInfo.userId,
        mealDate: dayjs(chosenDate || startDate).format("YYYY-MM-DD"),
      };
      dispatch(searchWeeklyMeal(params));
    }
  }, [chosenDate]);

  useEffect(() => {
    if (status.option === "show" && scrollNum) {
      sectionRef.current[scrollNum]?.scrollIntoView({
        behavior: "smooth",
        top: sectionRef.current[scrollNum].offsetTop,
      });
    }
  }, [scrollNum, status.option]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let params = {
      userId: loginInfo.userId,
      year: dayjs(startDate).format("YYYY"),
    };
    dispatch(fetchExistDatasOfYear(params));
  }, [dayjs(startDate).format("YYYY"), chosenDate]);

  return (
    <>
      <HeadCont
        title={"식사기록"}
        transparent={"white"}
        onLeftAction={() =>
          status.option === "add"
            ? setStatus({ option: "show", type: null })
            : navigate(-1)
        }
      />

      {status.option === "show" ? (
        <div>
          <CalendarHeader
            type={"식사기록"}
            startDate={startDate}
            setStartDate={setStartDate}
            chosenDate={chosenDate}
            // setChosenDate={setChosenDate}
            existDaysOfYear={existDaysOfYear}
          />

          <div className="-mt-[28px] rounded-tl-[28px] bg-white">
            <div className="border-b border-gray_60 px-6 pb-3 pt-6">
              <TitleBox
                option={6}
                title={"하루 목표 대비 식사 섭취량"}
                subTitle={
                  filtered?.eatList?.length === 0 || !filtered?.eatList
                    ? "아직 기록이 없습니다. 드신 식사를 기록해보세요."
                    : filtered?.kcal > 0 &&
                      filtered?.needKcal > 0 &&
                      filtered?.kcal >= filtered?.needKcal &&
                      filtered?.proteinG >= filtered?.needProteinG
                    ? "오늘의 목표도달을 축하합니다!"
                    : ""
                }
              />
              <div className="mt-5">
                <div className="flex flex-col gap-2">
                  <ProgressBar
                    title={"열량 목표"}
                    data={filtered?.needKcal}
                    data2={filtered?.kcal}
                    unit={"kcal"}
                    progress={50}
                    bgColor={"bg-[#C9DBFF]"}
                    innerColor={"bg-[#8744E1]"}
                    textColor={"text-white"}
                  />
                  <ProgressBar
                    title={"단백질 목표"}
                    data={filtered?.needProteinG}
                    data2={filtered?.proteinG}
                    unit={"g"}
                    progress={10}
                    bgColor={"bg-[#ABE7FA]"}
                    innerColor={"bg-[#51CCF3]"}
                    textColor={"text-white"}
                  />
                </div>
              </div>
            </div>
            <ul>
              {datas.map((data, idx) => (
                //음식별 카드
                <li
                  ref={(el) => (sectionRef.current[idx + 1] = el)}
                  key={data.id}
                  className="flex flex-col gap-3 border-b border-gray_60 p-6"
                >
                  <div className="flex items-center justify-between">
                    <h1 className="text-body2_700 text-black">{data.title}</h1>
                    <div className="flex gap-1">
                      <span className="text-caption1_700 text-black">
                        안 먹음
                      </span>
                      <ToggleBar
                        setStatus={setStatus}
                        type={data.mealType}
                        onSaveNotEat={onSaveNotEat}
                        checkStatus={
                          filtered?.eatList?.filter(
                            (eat) => eat.mealType === data.mealType
                          )[0]?.notEat
                        }
                      />
                    </div>
                  </div>
                  {filtered?.eatList.filter(
                    (eat) => eat.mealType === data.mealType
                  )[0]?.notEat ? (
                    <NotEatCard />
                  ) : (
                    <>
                      {filtered?.eatList.filter(
                        (eat) => eat.mealType === data.mealType
                      )[0]?.detailList.length > 0
                        ? filtered?.eatList
                            .filter((eat) => eat.mealType === data.mealType)[0]
                            ?.detailList.map((one, idx) => (
                              <FoodCard
                                key={idx}
                                data={one}
                                type={data.mealType}
                                setStatus={setStatus}
                                onSaveAddorEdit={onSaveAddorEdit}
                                onDelete={onDelete}
                              />
                            ))
                        : null}
                      <InputBox
                        setStatus={setStatus}
                        type={data.mealType}
                        scrollNum={idx + 1}
                      />
                    </>
                  )}
                </li>
              ))}
            </ul>
            <div className="mb-0 p-6">
              <Btn
                id={"mealAnalysis"}
                bgColor={"bg-main"}
                textColor={"text-white font-bold"}
                borderColor={"border border-main"}
                title={"오늘 나의 식사 분석 받기"}
                active={filtered?.eatList.length > 0 ? "on" : "ready"}
                onAction={() => {
                  if (questionsCompleteCheck.healthComplete) {
                    window.scrollTo(0, 0);
                    navigate(`/meal/analysis/${chosenDate || startDate}`);
                  } else {
                    setOpenModal(true);
                    document.body.style.overflow = "hidden";
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-14 h-screen bg-back_ground px-6 py-4">
          <FoodInputBox onSaveAddorEdit={onSaveAddorEdit} />
        </div>
      )}

      {/* {status.option === "show" && <MenuBar />} */}
      {openModal && (
        <DialogCover
          innerClass={
            "z-50 bg-white rounded-md w-full mx-6 max-[320px]:max-h-[360px] overflow-y-scroll no-scrollbar  shadow-[0_0_12px_rgba(0,0,0,0.1)]"
          }
        >
          <div className="px-4 py-6">
            <h2 className="mb-6 text-subtitle2 text-black">식사 분석</h2>
            <div className={"flex w-full items-center gap-2 text-body3_500 "}>
              식사 분석은 건강 정보를 입력하셔야 분석이 가능합니다. 건강
              설문으로 이동 하시겠습니까?
            </div>
          </div>
          <div className="m-4 flex items-center justify-between gap-2">
            <Btn
              innerClass={"w-fulll w-1/2"}
              bgColor={"bg-white"}
              textColor={"text-main font-semibold"}
              borderColor={"border border-main"}
              title={"취소"}
              active={"on"}
              onAction={() => {
                document.body.style.overflow = "unset";
                setOpenModal(false);
              }}
            />
            <Btn
              innerClass={"w-fulll w-1/2"}
              bgColor={"bg-main"}
              textColor={"text-white font-semibold"}
              borderColor={"border border-main"}
              title={"확인"}
              active={"on"}
              onAction={() => {
                navigate("/question/health");
                document.body.style.overflow = "unset";
                setOpenModal(false);
              }}
            />
          </div>
        </DialogCover>
      )}
    </>
  );
};

export default Record;
