import React from 'react'


const RadioBtn = ({htmlfor,commonname,korean, onChangeFunc,thekey }) => {
  return (
    <div className='contentsRadio '>
      <input id = {htmlfor} name ={commonname}  type='radio'
      className='radio cursor-pointer' onChange = {onChangeFunc}
      ></input>
      <label htmlFor ={htmlfor} className="flex gap-x-4 cursor-pointer">
        <span
        className={
          thekey  === korean
          ?'bg-[#fa8f69] rounded-full border-gray-400 w-5 h-5 mt-[2px] float-left'
          :'bg-white border rounded-full border-gray-400 w-5 h-5 mt-[2px] float-left'
        }
        >
          {thekey === korean?
            <img src="/images/admin/icon/check.svg"/>
            :""
          }
        </span>
        <p>{korean}</p></label>
    </div>
  )
}

export default RadioBtn