import React from 'react'
import { motion } from "framer-motion";

const CustomCenterModal = ({children,onClickFc,customlayout}) => {
  return (
        <div
        className="fixed top-0 left-0 z-30 flex items-center justify-center w-full h-full bg-black bg-opacity-30"
        onClick={onClickFc}
        >
     "   <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          onClick={e => e.stopPropagation()}
          className={customlayout} >
          {children}
          </motion.div>"
        </div>
  )
}

export default CustomCenterModal