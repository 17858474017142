import React from "react";
import { Quill } from "react-quill";

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

const CustomHr = () => <p>😀</p>;

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}
function addHr() {
  this.quill.insertText(0, "<hr/>");
}

// Add sizes to whitelist and register them
const Size = Quill.import("attributors/style/size");
Size.whitelist = ["16px", "20px", "22px"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  // "arial",
  // "comic-sans",
  // "courier-new",
  // "georgia",
  // "helvetica",
  // "lucida",
  "nanum-gothic",
  "nanum-myeongjo",
  "nanum-pen-script",
  "noto-sans-KR",
  "gamja-flower",
];
Quill.register(Font, true);

const BlockEmbed = Quill.import("blots/block/embed");

class DividerBlot extends BlockEmbed {
  static blotName = "divider";
  static tagName = "hr";
}

export function handleDivider() {
  if (!this.quill) return;
  let range = this.quill.getSelection(true);
  this.quill.insertText(range.index, "\n", "user");
  this.quill.insertEmbed(range.index + 1, "divider", true, "user");
  this.quill.setSelection(range.index + 2, 0, "user");
}
Quill.register(DividerBlot);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      divider: handleDivider,
    },
  },
  // history: {
  //   delay: 500,
  //   maxStack: 100,
  //   userOnly: true,
  // },
};
// export const modules = {
//   toolbar: {
//     container: [
//       [{ header: [1, 2, 3, false] }],
//       [{ size: ["16px", "20px", "22px"] }],
//       ["bold", "italic", "underline", "strike", "blockquote"],
//       [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
//       ["image"],
//       [{ color: [] }, { background: [] }],
//     ],
//     // handlers: { image: imageHandler },
//   },
// };

// Formats objects for setting up the Quill editor
export const formats = [
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "blockquote",
  "align",
  "color",
  "background",
  "image",
  "divider",
];

// Quill Toolbar component
export const TextEditorToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-size">
        <option value="16px">16px</option>
        <option value="20px">20px</option>
        <option value="22px">22px</option>
      </select>
      {/* <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select> */}
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      {/* <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" /> */}
      <button className="ql-blockquote" />
      <button className="ql-divider" title="Divider">
        --
      </button>
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-image" />
    </span>
    {/* <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span> */}
  </div>
);

export default TextEditorToolbar;
