import React from "react";

export const partners = [
  "서울대학교병원",
  "강남세브란스병원",
  "이대목동병원 이대서울병원",
  "충남대학교병원",
  "계명대학교 동산병원",
  "삼성서울병원",
  "대한항암요법연구회",
  "사단법인 대한부인종양연구회",
  "한동대학교",
  "부산대학교 의과대학",
];

const FifthPart = () => {
  return (
    <>
      <section className="my-[45px] flex flex-col items-center px-[80px] md:px-[130px] xl:my-[80px] xl:px-[200px]">
        <h3 className="text-2xl font-extrabold leading-[68px] sm:mb-[12px] xl:mb-[32px] xl:text-[50px]">
          힐리어리 연구활동
        </h3>
        <p className="text-center text-lg font-semibold text-gray_170 md:text-2xl xl:text-[30px] xl:leading-[53px]">
          힐리어리의 식단 제공 알고리즘은 2022년 특허 등록 되었으며
          <br />
          암환자 관련 학회 발표 및 연구과제를 지속적으로 수행하고 있습니다.
        </p>
        <div className="mt-[32px] w-fit xl:mt-[100px]">
          <div className="grid grid-cols-7 justify-items-center gap-y-5 text-center text-sm font-semibold text-gray_160 xl:text-lg xl:leading-[35px] 3xl:text-[24px]">
            <img
              src="/images/mobile/landing/특허.png"
              alt=""
              className="col-span-2 h-full"
            />
            <img
              src="/images/mobile/landing/학회.png"
              alt=""
              className="col-span-3 h-full"
            />
            <img
              src="/images/mobile/landing/임상연구.png"
              alt=""
              className="col-span-2 h-full"
            />
            <p className="col-span-2">암환자를 위한 식단 제공 시스템 및 방법</p>
            <p className="col-span-3">
              암환자를 위한 디지털 기반 식단제안
              <br />
              프로그램 활용
            </p>
            <p className="col-span-2">
              직장암 환자의 치료 후 증상관리 프로그램
              <br />
              개발 및 임상시험 활용성 검증
            </p>
          </div>
        </div>
      </section>
      {/* px-[80px] py-8 xl:px-[200px] */}
      <section className="flex w-full flex-col items-center bg-back_ground px-[80px] py-[27px] xl:px-[100px] ">
        <h3 className="my-[12px] text-2xl font-extrabold xl:mb-[32px] xl:text-[50px] xl:leading-[68px]">
          PARTNERS
        </h3>
        <p className="text-center text-base font-semibold text-gray_160 xl:text-[30px] xl:leading-[53px]">
          힐리어리와 의료기관의 협업을 통해 암환자 식단관리를 위한 최적화된
          서비스를 제공하고 있습니다.
        </p>

        <div className="mt-[32px] grid w-full grid-cols-4 gap-5 bg-white px-[100px] py-8 lg:grid-cols-5 xl:mt-[66px] xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5">
          {partners.map((partner) => (
            <div
              className="border-1 flex h-20  border border-gray_20 bg-white px-[20%] text-center lg:h-32 xl:h-[11.5rem]"
              key={partner}
            >
              <img
                src={`/images/mobile/landing/partners/${partner}.png`}
                alt={partner}
                className="m-auto h-auto w-full"
              />
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default FifthPart;
