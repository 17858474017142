import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import { mobileTypeFn } from "../logic";

// prettier-ignore
const InputBox = ({ id, nameTitle, type, inputM, option, label, placeholder, comments, errorMsg, onChangeInput,setErrorMsg, value, setValue, active, time, refName, onChangeAction, onChangeKeyDown, onClickAction, onSaveAnswer, login }) => {
  const inputRef = useRef(null);
  const { gettingAnswers } = useSelector((state) => state.questionHealth);

  const onDeleteValue = () => {
    setValue("");
    if (setErrorMsg) setErrorMsg(null);
    inputRef.current?.focus();
    if (nameTitle === "operationName" && gettingAnswers?.operationName) {
      //수술명 기타입력 기 입력건 있을떄 데이터 삭제
      if (!gettingAnswers?.operationName?.includes("ON")) {
        onSaveAnswer("operationName", null);
      }
    }
  };

  // ios input buffer 기능으로 인해 한글 초과 입력시 발생되는 버그 해결하기 위함
  const deleteBuffer = (e) => {
    // input의 focus를 임시로 생성한 곳에 옮기고 다시 원래 input으로 옮겨온 뒤 임시는 삭제
    if (e.keyCode === 8 && nameTitle === "nickName" && value.length === 8) {
      const hiddenInp = document.createElement("input");
      hiddenInp.setAttribute("type", "text");

      document.body.prepend(hiddenInp);
      hiddenInp.focus();

      setTimeout(() => {
        inputRef.current?.focus();
        hiddenInp.remove();
      }, 1);
    }
  };

  useEffect(() => {
    let focused = true;
    if (focused && inputRef) inputRef?.current?.focus();
    return () => (focused = false);
  }, [inputRef]);

  return (
    <>
      {option === "common" ? (
        <div className="relative">
          <input
            ref={inputRef}
            type={type || "text"}
            inputMode={inputM}
            id={id}
            name={nameTitle}
            placeholder={placeholder}
            onChange={onChangeInput}
            onKeyDown={mobileTypeFn() === "iphone" && nameTitle === "nickName" ? deleteBuffer : onChangeKeyDown}
            value={value || ""}
            onClick={onClickAction}
            disabled={active === "disabled"}
            className={
              "h-16 w-full appearance-none rounded border border-gray_80 p-4 text-body2_500l text-black focus:border-gray_160 focus:outline-none focus:ring-0" +
              (errorMsg ? " focus:border-red-500 " : "")
            }
          /> 
          <div className="absolute flex gap-1 top-5 right-4">
          {time && (
            <p 
            // className="absolute right-16 top-5 text-caption1_400 text-gray_80"
            >{`${Math.floor(
              time / 60
              )}:${time % 60 < 10 ? "0" + (time % 60) : time % 60}`}</p>
              )}
              {errorMsg && (
            <img 
            src="/images/mobile/icon/input_error.svg"
            alt="에러 아이콘"
            // className="absolute right-4 top-5"
            />
            )}
          {nameTitle !== "info" &&
            nameTitle !== "community" &&
            active !== "off"  &&   (
              <img
              onClick={onDeleteValue}
              src="/images/mobile/icon/input_close.svg"
              alt="닫기 아이콘"
              // className={
              //   "absolute top-5 " +
              //   (nameTitle === "info" ? " right-3" : " right-10")
              // }
              />
              )}
          
          {active === "on" && (
            <img
            src="/images/mobile/icon/input_correct.svg"
            alt="성공 아이콘"
            // className="absolute right-4 top-5"
            />
            )}
          </div>
          
          {errorMsg && (
            <p className="text-caption1_400 text-error">{errorMsg}</p>
            )}
          {!errorMsg && comments && (  
            <p
              className={
                " text-caption1_400  text-gray_160 " 
                // +
                // (active === "on" ? " text-gray_160 " : " text-error ")
              }
            >
              {comments}
            </p>
          )}
        </div>
      ) : option === "pass" ? (
        <div className="relative">
          <input
            ref={refName}
            type={"password"}
            autoComplete="on"
            id={id}
            name={nameTitle}
            placeholder={"비밀번호"}
            onChange={onChangeAction}
            value={value || ""}
            className="w-full h-16 py-4 pl-4 text-black border rounded appearance-none border-gray_80 text-body2_500l focus:border-gray_160 focus:outline-none focus:ring-0"
          />
          <img
            src={
              active === "on"
                ? "/images/mobile/icon/input_correct.svg"
                : "/images/mobile/icon/input_error.svg"
            }
            alt="성공/에러 아이콘"
            className="absolute right-4 top-5"
          />
          <img
            onClick={onClickAction}
            src={"/images/mobile/icon/input_close.svg"}
            alt="닫기 아이콘"
            className="absolute right-10 top-5"
          />
          {errorMsg && (
            <p className="text-caption1_400 text-gray_160">{errorMsg}</p>
          )}
        </div>
      ) : option === "label" ? (
        <div className="overflow-y-scroll">
          {label && (
            <label
              htmlFor={nameTitle}
              className="text-caption1_600 text-gray_160"
            >
              {label}
            </label>
          )}
          <div className="relative">
            <input
              ref={refName}
              type={type || "text"}
              inputMode={inputM || "text"}
              id={id}
              name={nameTitle}
              //안드로이드 최신기종 scrollTop, srollintoview 안먹음
              onFocus={() => {
                $("body, html").animate(
                  { scrollTop: document.body.scrollHeight },
                  // {scrollTo: {top: 40}},
                  600
                );
                // refName.scrollIntoView({behavior: "smooth", block: "start"});
              }}
              placeholder={placeholder}
              onChange={onChangeAction}
              onKeyDown={onChangeKeyDown}
              value={value || ""}
              className={
                `${login? "h-10 border-b-2 bg-back_ground " : "h-16 appearance-none rounded border py-4 pl-4 text-body2_500l text-black "}` + "focus:border-gray_160 border-gray_80 focus:outline-none focus:ring-0 w-full " +
                (active === "ready" ? " focus:border-red-500" : "")
              }
            />
            {active === "ready" && !login &&(
              <img
                src="/images/mobile/icon/input_error.svg"
                alt="에러 아이콘"
                className="absolute right-4 top-5"
              />
            )}
            {active === "on" && !login && (
              <img
                src="/images/mobile/icon/input_correct.svg"
                alt="성공 아이콘"
                className="absolute right-4 top-5"
              />
            )}
            {active === "ready" && (
              <img
                onClick={onClickAction || onDeleteValue}
                src={"/images/mobile/icon/input_close.svg"}
                alt="닫기 아이콘"
                className={login ? "absolute right-1 top-2" : "absolute right-10 top-5"}
              />
            )}
            {time && (
              <p className="absolute right-16 top-5 text-caption1_400 text-gray_80">{`${Math.floor(
                time / 60
              )}:${time % 60 < 10 ? "0" + (time % 60) : time % 60}`}</p>
            )}
          </div>
          {comments && active === "ready" && value === "" && (
            <p className="text-caption1_400 text-gray_160">{comments}</p>
          )}
          {errorMsg && (
            <p className="text-caption1_400 text-error">{errorMsg}</p>
          )}
          {label === "직접입력" && nameTitle === "anticancerTreatmentType" && (
            <div className="h-[150px]"></div>
          )}
        </div>
      ) : option === "passlabel" ? (
        <div>
          {label && (
            <label
              htmlFor={nameTitle}
              className="text-caption1_600 text-gray_160"
            >
              {label}
            </label>
          )}
          <div className="relative">
            <input
              ref={refName}
              type={"password"}
              autoComplete="on"
              id={id}
              name={nameTitle}
              placeholder={placeholder}
              onChange={onChangeAction}
              value={value || ""}
              disabled={active === "disabled"}
              className="w-full h-10 border-b-2 border-gray_80 focus:border-gray_160 focus:outline-none bg-back_ground"
            />
            {/* {active === "ready" && (
              <img
                src="/images/mobile/icon/input_error.svg"
                alt="에러 아이콘"
                className="absolute right-4 top-5"
              />
            )}
            {active === "on" && (
              <img
                src="/images/mobile/icon/input_correct.svg"
                alt="성공 아이콘"
                className="absolute right-4 top-5"
              />
            )} */}
            {active === "ready" && (
              <img
                onClick={onClickAction}
                src="/images/mobile/icon/input_close.svg"
                alt="닫기 아이콘"
                className="absolute right-4 top-5"
              />
            )}
          </div>
          {comments && active === "ready" && value === "" && (
            <p className="text-caption1_400 text-gray_160">{comments}</p>
          )}
          {errorMsg && (
            <p className="text-caption1_400 text-error">{errorMsg}</p>
          )}
        </div>
      ) : option === "changeWeight" ? (
        <div className="relative">
          <input
            ref={inputRef}
            type={type || "text"}
            inputMode={inputM || "text"}
            id={id}
            name={nameTitle}
            placeholder={placeholder}
            onChange={onChangeAction}
            onKeyDown={onChangeKeyDown}
            value={value || ""}
            className={
              "h-16 w-full appearance-none rounded border border-gray_80 py-4 pl-4 text-body2_500l text-black focus:border-gray_160 focus:outline-none focus:ring-0"
            }
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default InputBox;
