import { useDispatch, useSelector } from 'react-redux'
import { toggleFooter } from '../../../../features/admin/toggleSlice'
import CustomCenterModal from './CustomCenterModal'


const UseAndPolicyModal = () => {
  const dispatch = useDispatch()
  const usePolTbs = ["서비스 이용약관","개인정보 처리방침"]
  const FooterModalTitle = useSelector((state)=>state.toggleAdmin.footerM)
  const closeModal = ()=>{
    dispatch(toggleFooter(""))
  }
  return (
    <CustomCenterModal
    onClickFc={closeModal}
    customlayout={"absolute top-1/2 left-1/2 transform-50 bg-gray_10 rounded-xl z-30 min-w-[680px] shadow-md ]"}
    > 
      <div className='flex justify-between'>
        <div className='flex cursor-pointer'>
          {usePolTbs?.map((el)=>
            <span 
            key={el}
            className=
            {FooterModalTitle ===el?
              'px-2 py-4 bg-white  rounded-tl-lg rounded-tr-lg'
              :'px-2 py-4 bg-gray-200 rounded-tl-lg rounded-tr-lg'
            }
            onClick={()=> {dispatch(toggleFooter(el))}}
            >{el}</span>
          )}
        </div>
        <img 
          src="/images/admin/icon/close.svg"
          alt="아래 화살표 아이콘"
          className="p-1 cursor-pointer"
          onClick={closeModal}
          />
      </div>
      <div className="bg-white p-4 flex flex-col max-h-[400px] overflow-auto keepall rounded-br-lg rounded-bl-lg">
        <span className='font-bold py-2'>{FooterModalTitle}</span>
      {FooterModalTitle ==="서비스 이용약관"?
        <ul >
          <li>제1장 총칙</li>
          <li>제1조(목적)</li>
          <li>본 약관은 주식회사 헤링스(이하 “회사”라 합니다)가 운영하는 '힐리어리' 서비스(이하 “서비스”라 합니다)를 회원이 이용함에 있어 회사와 회원간의 권리, 의무 및 책임사항 및 회원의 게시물에 대한 내용 등을 규정함을 목적으로 합니다.</li>
          <li className='pt-[10px]'>제2조(약관의 효력 및 변경)</li>
          <li>① 본 약관은 서비스를 이용하고자 본 약관에 동의한 모든 회원에 대하여 그 효력을 발생합니다.</li>
          <li>② 회사는 약관의 규제에 관한 법률 전자상거래 등에서의 소비자보호에 관한 법률, 정보통신망이용촉진 및 정보보호 등에 관한 법률, 소비자기본법 등 관련법령을 위반하지 않는 범위 내에서 필요 시 약관을 변경할 수 있으며, 약관을 변경할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하게 변경되거나 중요한 내용을 변경하는 경우에는 30일 이전부터 적용일자 전일까지 상기 방식으로 공지하며 공지 외 SMS, MMS 발송, 로그인시 동의팝업 알림, 이메일 발송 등 전자적 수단을 통해 별도로 통지합니다.</li>
          <li>③ 회사가 제2항에 따라 변경된 약관을 공지 또는 통지하면서 회원에게 약관 변경 적용일까지 거부의사를 표시하지 아니할 경우 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하면, 회원이 변경 약관에 동의한 것으로 간주됩니다.</li>
          <li>④ 변경 약관에 동의하지 않는 회원은 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.</li>
          <li>⑤ 본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련법령을 적용합니다.</li>
          <li className='pt-[10px]'>제3조(용어의 정의)</li>
          <li>① 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.</li>
          <li>1. 서비스: 스마트폰에서 네트워크를 이용하여 접속하는 애플리케이션으로 웨어러블 기기(스마트 밴드), IOT 기기 또는 의료기기로부터 측정된 값을 수신 받아 실시간 운동정보 및 건강관리에 필요한 정보를 제공하는 일련의 서비스를 말합니다.</li>
          <li>2. 회원: 본 서비스를 이용하기 위하여 본 약관에 동의하고, 회사와 서비스 이용계약을 체결한 자를 의미합니다.</li>
          <li>3. 애플리케이션: 스마트폰에서 회원이 본 서비스를 이용할 수 있도록 구성된 프로그램을 말합니다.</li>
          <li>4. 개인정보: 개인정보보호법 제2조 제1호에 규정된 “개인정보”를 말합니다.</li>
          <li>5. 결제: “회사”가 제공하는 “유료서비스”를 이용하기 위하여 각종 지불수단을 통하여 일정 금액을 “회사” 또는 “회사”가 정한 제3자에 지불하는 것을 의미합니다.</li>
        </ul>
        :
        <ul>
          <li>힐리어리 개인정보 처리방침</li>
          <li>제1조 총칙</li>
          <li>
            1. 개인정보란 생존하는 개인에 관한 정보로서 성명, 주민등록번호 등에 의하여 당해 개인을 알아볼 수 있는 부호, 문자, 음성, 음향, 영상 및 생체 특성 등에 관한 정보(당해 정보만으로는 특정 개인을 알아볼 수 없는 경우에도 다른 정보와 용이하게 결합하여 알아볼 수 있는 것을 포함)를 말합니다.
          </li>
          <li>
          2. 주식회사 헤링스(이하 "회사"라 한다)는 회원의 개인정보를 중요시하며, 개인정보 보호 관련 각종 법규를 준수하고 있습니다.
          </li>
          <li>
          3. 회사는 개인정보처리방침을 통하여 회원의 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
          </li>
          <li className='pt-[10px]'>
          제2조 개인정보의 수집항목 및 수집/이용 목적
          </li>
          <li>
          1. 회사는 회원가입, 상담, '힐리어리' 서비스(이하 "서비스"라 한다.) 제공 등을 위하여 필요한 범위에서 최소한의 개인정보만을 수집합니다.
          </li>
          <li>
          2. 사상, 신념, 가족 및 친인척관계, 학력(學歷)·병력(病歷), 기타 사회활동 경력 등 회원의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 개인정보는 수집하지 않습니다. 다만, 회원이 수집에 동의하시거나 다른 법률에 따라 특별히 수집 대상 개인정보로 허용된 경우에는 필요한 범위에서 최소한으로 위 개인정보를 수집할 수 있습니다.
          </li>
          <li>
          3. 회사는 최초 회원 가입 또는 서비스 이용 시 회원으로부터 아래와 같은 개인정보를 수집하며, 수집한 개인정보를 다음의 목적을 위해 활용합니다.
          개인정보처리방침과 서비스 내 개인정보 수집ㆍ이용 동의와 상충되는 부분이 있다면 서비스 내 개인정보 수집ㆍ이용 동의서의 내용이 우선합니다.
          </li>
        </ul>
        }
      </div>
    </CustomCenterModal>
  )
}

export default UseAndPolicyModal