// hooks/useCommunity.js
// dont use this for general use !!!!
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fetchInfoPostings,
  onResetAll,
  onSaveInfoPage,
  onSetExistNextPosting,
  onSetIsLike,
  onSetPrevBookmark,
  onSetPrevPostList,
  onSetRangeMonth,
  onSetSearchedValue,
  onSetTotalPosts,
} from "../../../features/mobile/infoSlice";

/**
 * Info hook containing functions and \
 * variables shared among the Info Components
 * @date 6/21/2023 - 2:51:41 PM
 *
 * @export
 * @param {string} page "main", "post", "search"
 * @param {number} loginInfo
 * @param {string} tabTitle
 * @return {void}
 */
export default function useInfo(page, loginInfo, tabTitle, subTabTitle) {
  // hook declaration
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // redux info states
  const {
    prevBookmark,
    prevPostList,
    prevTabTitle,
    prevSubTabTitle,
    searchedValue,
    totalPosts,
    existNextPosting,
    isLike,
    rangeMonth,
  } = useSelector((state) => state.info);

  // State declaration
  // prettier-ignore
  const [postList, setPostList] = useState( prevPostList?.length > 0 ? prevPostList : [] );
  const [popularPosts, setPopularPosts] = useState([]); //리스트 내 인기글
  const [bookmark, setBookmark] = useState(
    prevBookmark?.length > 0 ? prevBookmark : []
  ); //List for bookmark
  const [isMorePosting, setIsMorePosting] = useState(existNextPosting);
  const [loading, setLoading] = useState(true);

  /**
   * onBringPosting: /posting/getPageInfoPostings
   *
   * Dispatched:
   * - onSetExistNextPosting
   * - onSetPrevLikedList
   *
   * @date 6/21/2023 - 2:41:04 PM
   *
   * @param {{ lastId: number; qty: number; category: string; subCategory: string | undefined; searchValue: string | undefined; setSearchValue: React.function; setLocalTotalPosts: React.function; writerUserId: string | undefined; isLike: boolean | undefined; rangeMonth: number | undefined;  }}
   * @param {number} params.lastId Used for 인기 tab. Describes the last fetched id
   * @param {number} params.qty Variable describing how many posts to fetch
   * @param {string} params.category
   * @param {string} params.subCategory
   * @param {string} params.searchValue State of a search keyword (or search word)
   * @param {Function} params.setSearchValue State mutator for a search keyword (or search word)
   * @param {Function} params.setLocalTotalPosts State mutator for total post count
   * @param {string | undefined} params.writerUserId Used to get all the posts (or liked posts) of writerUserId
   * @param {boolean | undefined} params.isLike Used with writerUserId (for liked posts)
   * @param {number | undefined} params.rangeMonth Used with writerUserId (for setting the range)
   * @returns {void}
   */
  const onBringPostings = ({
    lastId = 0,
    qty = 10,
    category,
    subCategory,
    searchValue,
    setSearchValue,
    setLocalTotalPosts,

    // My Page (myPosts, myLikedPosts, myComments)
    writerUserId,
    isLike,
    rangeMonth,
  }) => {
    return new Promise((resolve, reject) => {
      let params = {
        userId: loginInfo.userId,
        countPerPage: qty,
        searchText: searchValue,
        category: category || tabTitle,
        subCategory: subCategory,
      };

      /////////////////////////////
      // adding MyPage variables //
      /////////////////////////////
      if (isLike) {
        params.isLike = isLike;
        dispatch(onSetIsLike(isLike));
      }
      if (rangeMonth) {
        params.rangeMonth = rangeMonth;
        dispatch(onSetRangeMonth(rangeMonth));
      }

      dispatch(fetchInfoPostings(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            if (page === "search") {
              dispatch(onSetTotalPosts(res.totalSearhCount));
              dispatch(onSetSearchedValue(searchValue));
              setSearchValue("");
              if (res?.popularPostingList) {
                setPostList([]);
                setPopularPosts([...res.popularPostingList]);
              } else {
                setPostList([...res.postingList]);
                setBookmark(
                  res.postingList.map((value) => ({
                    id: value.id,
                    bookmark: value.bookmark,
                  }))
                );
                setLocalTotalPosts(res.totalSearhCount);
              }
            } else {
              setPostList(res.postingList && [...res.postingList]);
              setBookmark(
                res.postingList?.map((value) => ({
                  id: value.id,
                  bookmark: value.bookmark,
                }))
              );
              if (page === "myPage") {
                dispatch(onSetSearchedValue(searchValue));
              }
            }
            setIsMorePosting(res.isExistNextPosting);
            dispatch(onSetExistNextPosting(res.isExistNextPosting));

            setLoading(true);
            resolve(true);
          } else {
            reject(new Error(res?.error));
          }
        })
        .catch((error) => reject({ ...error, location: "useInfo.js:140:1" }));
    });
  };

  /**
   * onFetchMorePosts: /posting/getPageInfoPostings
   *
   * Dispatched:
   * - onSetExistNextPosting
   * - onSetPrevLikedList
   *
   * @date 6/21/2023 - 2:47:05 PM
   */
  const onFetchMorePosts = () => {
    let params = {
      userId: loginInfo.userId,
      currentListCount: postList.length,
      countPerPage: 10,
      searchText: searchedValue,
      isLike: isLike,
      rangeMonth: rangeMonth,
      category: tabTitle,
    };
    // if (tabTitle) params.category = tabTitle;
    if (subTabTitle === "인기") {
      params.isPopular = true;
      params.subCategory = subTabTitle;
    }
    if (subTabTitle === "My") params.subCategory = subTabTitle;

    if (isMorePosting) {
      dispatch(fetchInfoPostings(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            setIsMorePosting(res.isExistNextPosting);
            setPostList(postList.concat(res.postingList));
            setBookmark(
              bookmark.concat(
                res.postingList.map((value) => ({
                  id: value.id,
                  bookmark: value.bookmark,
                }))
              )
            );
            dispatch(onSetExistNextPosting(res.isExistNextPosting));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * Navigate to Post
   *
   * @date 6/21/2023 - 2:47:45 PM
   *
   * @param {number} lastId Post ID \
   * @type {callback}
   * @type {*}
   */
  const onClickPost = useCallback(
    (id) => {
      if (page === "main") {
        dispatch(
          onSaveInfoPage({
            postList: postList,
            tabTitle: tabTitle,
            subTabTitle: subTabTitle,
            bookmark: bookmark,
          })
        );
        navigate(`/info/` + id);
        return;
      } else {
        dispatch(onSetPrevPostList(postList));
        dispatch(onSetPrevBookmark(bookmark));
      }
      navigate(`/info/` + id);
    },
    [postList, bookmark, tabTitle, subTabTitle, dispatch, navigate, page]
  );

  /**
   * Reset postList, bookMark, isMorePosting and loading states
   * Reset redux states (onResetAll() => infoSlice.js)
   * @date 6/21/2023 - 2:48:26 PM
   */
  const onResetInfo = () => {
    setPostList([]);
    setBookmark([]);
    setIsMorePosting(true);
    setLoading(false);
    dispatch(onResetAll());
  };

  return {
    // variables
    postList,
    popularPosts,
    loading,
    isMorePosting,
    bookmark,
    totalPosts,
    searchedValue,
    prevTabTitle,
    prevSubTabTitle,
    rangeMonth,
    // functions
    onBringPostings,
    onFetchMorePosts,
    onClickPost,
    // state function
    setLoading,
    onResetInfo,
    // etc.,
    dispatch,
    navigate,
  };
}
