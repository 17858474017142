import React from 'react';

const Loading = () => {

    return (
        <div className='h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-white'>
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray_20 h-10 w-10"></div>
        </div>
    );
};

export default Loading;