import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../../src/common/api";
import toastCommonProps from "../../common/toast";

export const postFullGuide = createAsyncThunk(
  "contents/postFullGuide",
  async (params) => {
    try {
      const res = await api.post("/fullGuide", params);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const postFoodGuide = createAsyncThunk(
  "contents/postFoodGuide",
  async (params) => {
    try {
      const res = await api.post("/foodGuide", params);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const saveFullGuide = createAsyncThunk(
  "contents/saveFullGuide",
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formdata = new FormData();
    formdata.append("addAdmin", params.addAdmin);
    formdata.append("kcalIntake", params.kcalIntake);
    formdata.append("proteinIntake", params.proteinIntake);
    formdata.append("content", params.content);
    //추후 사진정보 필요하면 파일 추가
    const res = await api.post("/fullGuide/addFullGuide", formdata, config);
    return res;
  }
);

export const editFullGuide = createAsyncThunk(
  "contents/editFullGuide",
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formdata = new FormData();
    formdata.append("id", params.id);
    formdata.append("kcalIntake", params.kcalIntake);
    formdata.append("proteinIntake", params.proteinIntake);
    formdata.append("content", params.content);
    formdata.append("editAdmin", params.editAdmin);
    //추후 사진정보 필요하면 파일 추가
    const res = await api.post("/fullGuide/editFullGuide", formdata, config);
    return res;
  }
);

export const saveFoodGuide = createAsyncThunk(
  "contents/saveFoodGuide",
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formdata = new FormData();
    formdata.append("addAdmin", params.addAdmin);
    formdata.append("lcategory", params.lcategory);
    formdata.append("mcategory", params.mcategory);
    formdata.append("scategory", params.scategory);
    formdata.append("content", params.content);
    //추후 사진정보 필요하면 파일 추가
    const res = await api.post("/foodGuide/addFoodGuide", formdata, config);
    return res;
  }
);

export const editFoodGuide = createAsyncThunk(
  "contents/editFoodGuide",
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formdata = new FormData();
    formdata.append("id", params.id);
    formdata.append("editAdmin", params.editAdmin);
    formdata.append("lcategory", params.lcategory);
    formdata.append("mcategory", params.mcategory);
    formdata.append("scategory", params.scategory);
    formdata.append("content", params.content);
    //추후 사진정보 필요하면 파일 추가
    const res = await api.post("/foodGuide/editFoodGuide", formdata, config);
    return res;
  }
);

export const saveWeightGuide = createAsyncThunk(
  "contents/saveWeightGuide",
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formdata = new FormData();
    formdata.append("addAdmin", params.addAdmin);
    formdata.append("lcategory", params.lcategory);
    formdata.append("mcategory", params.mcategory);
    formdata.append("scategory", params.scategory);
    formdata.append("content", params.content);
    //추후 사진정보 필요하면 파일 추가
    const res = await api.post("/weightGuide/addWeightGuide", formdata, config);
    return res;
  }
);

export const editWeightGuide = createAsyncThunk(
  "contents/editWeightGuide",
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formdata = new FormData();
    formdata.append("id", params.id);
    formdata.append("editAdmin", params.editAdmin);
    formdata.append("lcategory", params.lcategory);
    formdata.append("mcategory", params.mcategory);
    formdata.append("scategory", params.scategory);
    formdata.append("content", params.content);
        //추후 사진정보 필요하면 파일 추가
    const res = await api.post("/weightGuide/editWeightGuide",formdata,config);
    return res;
  }
);

export const saveSymptomGuide = createAsyncThunk(
  "contents/saveSymptomGuide",
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formdata = new FormData();
    formdata.append("addAdmin", params.addAdmin);
    formdata.append("symptom", params.symptom);
    formdata.append("explanation", params.explanation);
    formdata.append("copingSummary", params.copingSummary);
    formdata.append("content", params.content);
    params.files && params.files.forEach((file) => formdata.append("files", file))
    const res = await api.post("/symptomGuide/addSymptom", formdata, config);
    return res;
  }
);

export const editSymptomGuide = createAsyncThunk(
  "contents/editSymptomGuide",
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formdata = new FormData();
      formdata.append("id",params.id);
      formdata.append("editAdmin", params.editAdmin);
      formdata.append("symptom", params.symptom);
      formdata.append("copingSummary", params.copingSummary);
      formdata.append("explanation", params.explanation);
      formdata.append("content", params.content);
      params.files && params.files.forEach((file) => formdata.append("files", file))
      const res = await api.post("/symptomGuide/editSymptom", formdata, config);
      return res;
    }
);

export const saveFaq = createAsyncThunk("contents/saveFaq", async (params) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const formdata = new FormData();
  formdata.append("addAdmin", params.addAdmin);
  formdata.append("category", params.category);
  formdata.append("title", params.title);
  formdata.append("content", params.content);

  const res = await api.post("/faq/addFAQ", formdata, config);
  if (res.data.ok) {
    toast(<p>저장 완료되었습니다</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
    window.location.reload();
    return res.data;
  } else {
    console.log(res);
  }
});

export const editFaq = createAsyncThunk("contents/editFaq", async (params) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const formdata = new FormData();

  formdata.append("id", params.id);
  formdata.append("editAdmin", params.editAdmin);

  formdata.append("category", params.category);
  formdata.append("title", params.title);
  formdata.append("content", params.content);
  const res = await api.post("/faq/editFAQ", formdata, config);
  if (res.data.ok) {
    toast(<p>수정 완료되었습니다</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
    window.location.reload();
    return res.data;
  } else {
    console.log(res);
  }
});

export const deleteFullGuide = createAsyncThunk(
  "contents/deleteFullGuide",
  async (params) => {
    const res = await api.post("/fullGuide/delete", { id: params });
    return res;
  }
);

export const deleteFoodGuide = createAsyncThunk(
  "contents/deleteFoodGuide",
  async (params) => {
    const res = await api.post("/foodGuide/delete", { id: params });
    return res;
  }
);

export const deleteWeightGuide = createAsyncThunk(
  "contents/deleteWeightGuide",
  async (params) => {
    const res = await api.post("/weightGuide/delete", { id: params });
    return res;
  }
);
export const deleteSymptomGuide = createAsyncThunk(
  "contents/deleteSymptomGuide",
  async (params) => {
    const res = await api.post("/symptomGuide/delete", { id: params });
    return res;
  }
);
export const deleteFaq = createAsyncThunk(
  "contents/deleteFaq",
  async (params) => {
    const res = await api.post("/faq/delete", { id: params });
    if (res.data.ok) {                        
      toast(<p>FAQ가 삭제되었습니다.</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
      window.location.reload();
    } else {
      console.log(res, "FAQ 삭제 실패..ㅠ");
    }
  }
);

export const searchFoodGuide = createAsyncThunk(
  "contents/searchFoodGuide",
  async (params) => {
    try {
      const res = await api.post("/foodGuide/search", params);
      if (res.data.ok) {
        return res.data;
      }
    } catch (e) {}
  }
);

export const searchWeightGuide = createAsyncThunk(
  "contents/searchWeightGuide",
  async (params) => {
    try {
      const res = await api.post("/weightGuide/search", params);
      if (res.data.ok) {
        return res.data;
      }
    } catch (e) {}
  }
);

export const searchSymptomGuide = createAsyncThunk(
  "contents/searchSymptomGuide",
  async (params) => {
    try {
      const res = await api.post("/symptomGuide/search", params);
      if (res.data.ok) {
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }
);
export const getFullGuide = createAsyncThunk(
  "contents/getFullGuide",
  async () => {
    const res = await api.get("/fullGuide");
    return res.data;
  }
);
export const getFoodGuide = createAsyncThunk(
  "contents/getFoodGuide",
  async () => {
    const res = await api.get("/foodGuide");
    return res.data;
  }
);
export const getWeightGuide = createAsyncThunk(
  "contents/getWeightGuide",
  async () => {
    const res = await api.get("/weightGuide");
    return res.data;
  }
);
export const getSymptomGuide = createAsyncThunk(
  "contents/getSymptomGuide",
  async () => {
    const res = await api.get("/symptomGuide");
    return res.data;
  }
);
export const getFAQ = createAsyncThunk("contents/FAQ", async () => {
  const res = await api.get("/faq");
  return res.data;
});

const initialState = {
  i: 0,
  status: null,
  eachGuideList:[],
  fullGuide: [],
  foodGuide: [],
  weightGuide: [],
  symptomGuide: [],
  faq: [],
  clickData: [],
  clickTab: "",

};

const contentsSlice = createSlice({
  name: "콘텐츠",
  initialState,
  reducers: {
    onClickGuide: (state, action) => {
      state.clickData = action.payload;
    },
    onClickGuideList :(state,action) =>{
      state.eachGuideList = action.payload
    },
    onSetContTab:(state,action) =>{
      state.clickTab = action.payload
    },
  },
  extraReducers: {
    [postFullGuide.pending]: (state) => {
      state.status = "pending";
    },
    [postFullGuide.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [postFullGuide.rejected]: (state) => {
      state.status = "rejected";
    },

    [getFullGuide.fulfilled]: (state, action) => {
      state.status = "success";
      state.fullGuide = action.payload.fullGuideList;
    },
    [getFoodGuide.fulfilled]: (state, action) => {
      state.status = "success";
      state.foodGuide = action.payload.foodGuideList;
    },
    [getWeightGuide.fulfilled]: (state, action) => {
      state.status = "success";
      state.weightGuide = action.payload.weightGuideList;
    },
    [getSymptomGuide.fulfilled]: (state, action) => {
      state.status = "success";
      state.symptomGuide = action.payload.symptomGuideList;
    },
    [getFAQ.fulfilled]: (state, action) => {
      state.status = "success";
      state.faq = action.payload.faqList;
    },

    [searchFoodGuide.pending]: (state) => {
      state.status = "pending";
    },
    [searchFoodGuide.fulfilled]: (state, action) => {
      state.status = "success";
      state.foodGuide = action.payload.foodGuideList;
    },
    [searchWeightGuide.pending]: (state) => {
      state.status = "pending";
    },
    [searchWeightGuide.fulfilled]: (state, action) => {
      state.status = "success";
      state.weightGuide = action.payload.weightGuideList;
    },
    [searchSymptomGuide.pending]: (state) => {
      state.status = "pending";
    },
    [searchSymptomGuide.fulfilled]: (state, action) => {
      state.status = "success";
      state.symptomGuide = action.payload.symptomGuideList;
    },
  },
});

export const { onClickGuide, onSetContTab , onClickGuideList} = contentsSlice.actions;

export default contentsSlice.reducer;
