import { CancerGPTHEadList } from "../../components/common/TableHeadList";
import dayjs from "dayjs";
import { questionTypeOption } from "./CancerGPTOptionList";
import { onClickGetCancerGpt } from "../../../../features/admin/communitySlice";
import { useDispatch } from "react-redux";

const CancerGPTMemberList = ({ showEachNoticeModal, filteredUsers }) => {
  const dispatch = useDispatch();

  const repeatTd = (idx, data) => {
    const commoncss =
      "text-center py-4 text-caption_1 text-black px-4 border-gray_80 border-b";
    const gpt리스트 = [
      {
        tdclass:
          "border-b border-l border-gray_80 text-black text-center " +
          (idx + 1 === filteredUsers?.length ? " rounded-bl-lg" : ""),
        tdcontent: data?.no,
      },

      {
        tdclass:
          "text-center py-4 text-caption_1 px-4 " +
          (data?.questionType
            ? " text-black border-gray_80 border-b "
            : " text-gray-400 italic "),
        tdcontent: questionTypeOption[data?.questionType],
      },

      {
        tdclass: commoncss,
        tdcontent:
          data.nickName && data.nickNameProtector === data.nickName ? (
            <p>{data.nickName}</p>
          ) : (
            <>
              <p>{data.nickName}</p>
              {data.nickNameProtector ? (
                <p>(보:{data.nickNameProtector})</p>
              ) : (
                ""
              )}
            </>
          ),
      },
      { tdclass: commoncss, tdcontent: data.userId },
      {
        tdclass: commoncss,
        tdcontent:
          data.question?.length > 16
            ? data.question?.slice(0, 16) + "..."
            : data.question,
      },
      {
        tdclass: commoncss,
        tdcontent:
          data.answer?.length > 40
            ? data.answer?.slice(0, 40) + "..."
            : data.answer,
      },
      {
        tdclass:
          " border-r px-4 font-semibold text-black border-b border-gray_80 text-center" +
          (idx + 1 === filteredUsers?.length ? " rounded-br-lg" : ""),
        tdcontent: data.createdAt
          ? dayjs(data.createdAt).format("YYYY.MM.DD(dd)")
          : "-",
      },
    ];
    return (
      <>
        {gpt리스트?.map((el, index) => (
          <td className={el.tdclass} key={index}>
            {el.tdcontent}
          </td>
        ))}
      </>
    );
  };
  return (
    <>
      <table
        className="w-full col-span-3 border-separate table-auto 3xl:col-span-2"
        cellSpacing={0}
      >
        <thead>
          <tr className="bg-gray_60">
            {CancerGPTHEadList?.map((head, idx) => (
              <th
                key={idx}
                className={
                  "border-y border-gray_80 p-4 text-black first:border-l last:border-r first:rounded-tl-lg last:rounded-tr-lg" +
                  (idx === 0 ? " w-[80px] " : "")
                }
              >
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredUsers !== undefined &&
            filteredUsers?.map((data, idx) => (
              <tr
                key={idx}
                onClick={() => {
                  showEachNoticeModal(data, "CancerGPT");
                  dispatch(onClickGetCancerGpt(data));
                }}
                className={
                  "px-6 py-3  cursor-pointer hover:bg-primary_10 " +
                  (idx % 2 === 1 ? "bg-gray_95" : "bg-gray_10")
                }
              >
                {repeatTd(idx, data)}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default CancerGPTMemberList;
