import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const searchHospital = createAsyncThunk(
  "setting/hospitalSearch", //병원목록조회
  async () => {
    const res = await api.get("admin/hospital/searchForDropBox");
    return res;
  }
);

export const registerHospitalMember = createAsyncThunk(
  "setting/registHospitalMember", //사용자 병원회원 등록
  async (params) => {
    const res = await api.post("user/registHospitalMember", params);
    return res;
  }
);

export const unregistHospitalMember = createAsyncThunk(
  "setting/unregistHospitalMember", //사용자 병원회원 탈퇴
  async (params) => {
    const res = await api.post("user/unregistHospitalMember", params);
    return res;
  }
);

export const getPushAlarm = createAsyncThunk(
  "setting/getAlarmSetting", //사용자 알람 현재 조회 //push 페이지
  async (params) => {
    const res = await api.get(`/user/getAlarmSetting/${params}`);
    return res.data;
  }
);

export const setPushAlarm = createAsyncThunk(
  "setting/setAlarmSetting", //사용자 알람 설정변경 //push 페이지
  async (params) => {
    const res = await api.post("/user/setAlarmSetting", params);
    return res;
  }
);

export const setProfile = createAsyncThunk(
  "setting/setProfile",
  async (params) => {
    const res = await api.post("/user/setProfile", params);
    return res;
  }
);

export const getProfile = createAsyncThunk(
  `setting/getProfile`,
  async (param) => {
    const res = await api.get(`/user/getProfile/${param}`);
    return res;
  }
);

export const getCustomerCenterQuestion = createAsyncThunk(
  "setting/getCustomerCenterQuestion",
  async (params) => {
    const res = await api.post("/customerCenter/getUserQuestion", params);
    return res.data;
  }
);

export const getCustomerCenterQuestionOne = createAsyncThunk(
  "setting/getCustomerCenterQuestionOne",
  async (params) => {
    const res = await api.post("/customerCenter/getUserQuestionOne", params);
    return res;
  }
);

export const customerCenterDelteQ = createAsyncThunk(
  "/setting/customerDeleteQ",
  async (param) => {
    const res = await api.post("/customerCenter/deleteQuestion", param);
    return res;
  }
);
export const customerCenteraddQ = createAsyncThunk(
  "/setting/customerAddQ",
  async (param) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("userId", param.userId);
    formdata.append("title", param.title);
    formdata.append("content", param.content);
    param.files.forEach((file) => formdata.append("files", file));
    const res = await api.post("/customerCenter/addQuestion", formdata, config);
    return res;
  }
);

export const customerCenterEditQ = createAsyncThunk(
  "/setting/customerEditQ",
  async (param) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("id", param.id);
    formdata.append("userId", param.userId);
    formdata.append("title", param.title);
    formdata.append("content", param.content);
    if (
      !(param.remainingFiles?.length === 0 || param.remainingFiles === null)
    ) {
      //remainFile 있을 경우만 보내줌
      param.remainingFiles.forEach((each) =>
        formdata.append("remainingFiles", each)
      );
    }
    if (param.files?.length !== 0) {
      param.files.forEach((file) => formdata.append("files", file));
    }

    const res = await api.post(
      "/customerCenter/editQuestion",
      formdata,
      config
    );
    return res;
  }
);

export const getNotice = createAsyncThunk("/setting/notice", async (params) => {
  const res = await api.post("/posting/getNotice", params);
  return res.data;
});

const initialState = {
  customerCenterQ: [],
  customerCenterId: null,
  noticeList: [],
  customerCQuestionOne: [],
  totalPage: 0,
  totalSearchCount: 0,
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    onSetCustomerCenterIdName: (state, action) => {
      state.customerCenterId = action.payload;
    },
  },

  // mapping extraReducers will be deprecated. changing to builder objects
  extraReducers: (builder) => {
    builder.addCase(getCustomerCenterQuestion.fulfilled, (state, action) => {
      state.customerCenterQ = action.payload.questionList;
    });
    builder.addCase(getNotice.fulfilled, (state, action) => {
      state.noticeList = action.payload.postingList;
      state.totalPage = action.payload.totalPage;
    });
  },
  // {
  //     [getCustomerCenterQuestion.fulfilled]: (state, action) => {
  //         state.customerCenterQ = action.payload.questionList
  //     },
  //     [getNotice.fulfilled]: (state, action) => {
  //         state.noticeList = action.payload.postingList
  //     },
  // },
});

export const { onSetCustomerCenterIdName } = settingSlice.actions;

export default settingSlice.reducer;
