
const ToggleBtn = ({ isOn, onToggle, title, justifyB }) => {

  return (
    <div className={"flex gap-x-[8px] items-center text-black font-semibold text-body3_700 " + (justifyB ? "justify-between" : "")}>
      <p>{title}</p>
      <div className="toggle" onClick={onToggle}>
        <div className={`toggle-container ${isOn ? "toggle--checked" : ""}`} />
        <div className={`toggle-circle ${isOn ? "toggle--checked" : ""}`} />
      </div>
    </div>
  );
};

export default ToggleBtn;
