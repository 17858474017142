import { useCallback, useEffect, useRef } from "react";

const useOutsideClick = (callback) => {
  const ref = useRef();
  const handleClick = useCallback(
    (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.id !== "three_dot_menu"
      ) {
        callback();
      }
    },
    [callback]
  );
  useEffect(() => {
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref, handleClick, callback]);

  return ref;
};

export default useOutsideClick;
