import React from "react";
import Parser from "html-react-parser";
import TitleBox from "../common/TitleBox";

const CautionFood = ({ myinfo, handleToggle }) => {
  return (
    <div className="space-y-[28px] rounded-lg bg-orange_10 p-6">
      <div onClick={handleToggle} className="flex flex-row justify-between">
        <TitleBox option={1} title={"주의식품"} />
        <img
          alt={"arrowRight"}
          src="/images/mobile/icon/arrow_right.svg"
          className="rotate-90 transform"
        />
      </div>
      <div className="text-center">{Parser(myinfo.cautionFood)}</div>
      {myinfo.cautionFoodList?.length > 0 && (
        <div className="rounded-md bg-back_ground p-4 text-left">
          <div className="mx-auto w-fit">
            {myinfo.cautionFoodList.map((food, idx) => (
              <p className="text-body2_500 text-black" key={idx}>
                {idx + 1}. {food}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CautionFood;
