import React, { useEffect, useState } from "react";

const ToggleBar = ({ type, checkStatus, onSaveNotEat }) => {
  const [isChecked, setIsChecked] = useState(null);

  const onCheckToggle = (e) => {
    setIsChecked(!isChecked);
    onSaveNotEat(type, !isChecked);
  };

  useEffect(() => setIsChecked(checkStatus), [checkStatus]);

  return (
    <div className="inline-block h-6 w-[40px]">
      <label
        className={
          "relative block h-6 cursor-pointer rounded-full " +
          (isChecked ? "bg-main" : " bg-gray_70")
        }
      >
        <input
          type="checkbox"
          onChange={(e) => onCheckToggle(e)}
          className={
            "absolute left-1 top-1 block h-4 w-4 cursor-pointer appearance-none rounded-full bg-white duration-200 ease-in " +
            (isChecked ? "translate-x-full " : "")
          }
        />
      </label>
    </div>
  );
};

export default ToggleBar;
