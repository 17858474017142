import React from "react";

const Btn = ({
  active,
  id,
  title,
  onAction,
  option,
  innerClass,
  bgColor,
  textColor,
  borderColor,
}) => {
  //action:ready, on, pressed, option:bottom(아래 고정)

  return (
    <>
      <div
        className={
          "text-center " +
          (option === "bottom"
            ? "maxW fixed bottom-4 left-4 right-4 z-30 mx-auto "
            : " ") +
          innerClass
        }
      >
        {onAction ? (
          <button
            id={id ? id : null}
            onClick={active === "on" ? onAction : null}
            disabled={!active || active === "on" ? false : true}
            className={
              `w-full py-4 ` +
              (option === "gpt"
                ? "rounded px-4 text-body1_500l "
                : "rounded-2xl leading-7 tracking-[0.24px] ") +
              (active === "on"
                ? `${bgColor} ${borderColor} ${textColor}`
                : active === "ready"
                ? bgColor === "bg-main"
                  ? ` bg-[#90B5FF] text-white `
                  : "" + borderColor === " border border-main"
                  ? ` border border-[#90B5FF] text-[#90B5FF]`
                  : "bg-[#939393] text-white"
                : "bg-green_100 active:bg-green_120")
            }
          >
            {id !== "checkMealPlan"
              ? title
              : !active || active === "on"
              ? title
              : "식단 생성 중입니다. 잠시만 기다려주세요!"}
          </button>
        ) : (
          <button
            type="submit"
            className={
              `w-full rounded-2xl py-4 text-body3_700  ` +
              (active === "on"
                ? `${bgColor} ${borderColor} ${textColor}`
                : active === "ready"
                ? bgColor === "bg-main"
                  ? ` bg-[#90B5FF] text-white `
                  : "" + borderColor === " border border-main"
                  ? ` border border-[#90B5FF] text-[#90B5FF]`
                  : ""
                : "bg-green_100 active:bg-green_120")
            }
          >
            {title}
          </button>
        )}
      </div>
    </>
  );
};

export default Btn;
