import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const fetchMainInfo = createAsyncThunk(
  "mainAdmin/fetchmaininfo",
  async (param) => {
    const res = await api.post("/admin/home/mainData", param);
    if (res.data.ok) {
      return res.data;
    }
  }
);

// 어드민 홈에 유입경로 더보기 페이지
export const fetchRegistSurveyRank = createAsyncThunk(
  "admin/registSurveyRank",
  async (param) => {
    const res = await api.post("/admin/home/registSurveyRank", param);
    if (res.data.ok) {
      return res.data;
    }
  }
);

const initialState = {
  i: 0,
  status: null,
  userList: [],
  communityList: [],
  customerCenterList: [],
  registSurveyRank: [],
  newUserCount: 0,
  customerCenterNewCount: 0,
  totalAdminCount: 0,
  totalUserCount: 0,
  approvalWaitCount: 0,
  totalSurveyRankCount: 0,
  registSurveyRankDetail: [],
};
const mainSlice = createSlice({
  name: "메인",
  initialState,
  extraReducers: {
    [fetchMainInfo.fulfilled]: (state, action) => {
      state.status = "success";
      state.userList = action.payload.userList;
      state.communityList = action.payload.postingList;
      state.customerCenterList = action.payload.QList;
      state.registSurveyRank = action.payload.registSurveyRank;
      state.newUserCount = action.payload.newUserCount;
      state.customerCenterNewCount = action.payload.customerCenterNewCount;
      state.totalUserCount = action.payload.totalUserCount;
      state.totalAdminCount = action.payload.totalAdminCount;
      state.approvalWaitCount = action.payload.approvalWaitCount;
    },
    [fetchRegistSurveyRank.fulfilled]: (state, action) => {
      state.totalSurveyRankCount = action.payload.count;
      state.registSurveyRankDetail = action.payload.registSurveyRank;
    },
  },
});

export default mainSlice.reducer;
