import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFoodRegistrationRequest,
  getFoodRegistrationRequestShowAll,
} from "../../../../../features/admin/communitySlice";
import { BGButtonCustom } from "../../../components/common/ButtonCustom";
import { CSVLink } from "react-csv";
import PaginationLayout from "../../../components/common/pagination/PaginationLayout";
import {
  handlePageChange,
  onSetSubSection,
} from "../../../../../features/admin/commonSlice";
import { useNavigate } from "react-router-dom";
import DbRequestList from "../DbRequestList";
import { setPageArr } from "../../../../../features/admin/paginationSlice";
import CsvModal from "../../../components/board/CsvModal";
import { RequestDBExcelHeadList } from "../../../components/common/TableHeadList";
import ContentLayout from "./ContentLayout";
const RequestDB = ({ showEachNoticeModal }) => {
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const { pagePerList } = useSelector((state) => state.pagination);
  const { foodRegisterAllList, totalPage } = useSelector(
    (state) => state.commu
  );
  const [csvDown, setCsvDown] = useState(false);
  const dispatch = useDispatch();

  const showCSVdown = useCallback(() => {
    setCsvDown(!csvDown);
  }, [setCsvDown, csvDown]);
  const getAllData = () => {
    dispatch(getFoodRegistrationRequestShowAll());
  };

  const handlePageFn = async (clickPage) => {
    const param = {
      page: clickPage,
      countPerPage: pagePerList,
    };
    dispatch(handlePageChange(clickPage));
    const res = await dispatch(getFoodRegistrationRequest(param)).unwrap();
    if (res.ok) {
      const defaultarr = Array(res.totalPage)
        .fill()
        ?.map((v, i) => {
          return i + 1;
        });
      if (pagePerList >= clickPage) {
        //1~10사이일경우 pagePerList 10
        dispatch(setPageArr(defaultarr.slice(0, pagePerList)));
      } else {
        //11~
        dispatch(
          setPageArr(
            defaultarr.slice(
              (Math.ceil(clickPage / pagePerList) - 1) * pagePerList,
              Math.ceil(clickPage / pagePerList) * pagePerList
            )
          )
        );
      }
    }
  };
  useEffect(() => {
    if (loginInfo?.adminId) {
      handlePageFn(1);
      dispatch(handlePageChange(1));
      dispatch(onSetSubSection("음식DB요청"));
    } else {
      navigate("/admin/signin");
    }
  }, [dispatch, loginInfo.adminId]);

  return (
    <>
      {csvDown && (
        <CsvModal
          showCSVdown={showCSVdown}
          lists={foodRegisterAllList}
          headers={RequestDBExcelHeadList}
          tab={"음식DB요청"}
        />
      )}
      <ContentLayout>
        <div className="flex w-full justify-end pb-4">
          <BGButtonCustom
            onClick={() => {
              showCSVdown();
              getAllData();
            }}
            content={"엑셀파일로 내려받기"}
            bgcolor={"bg-orange_100"}
            textcolor={"text-gray_10"}
            textsize={"text-caption1_600"}
            width={"160px"}
          />
        </div>
        <PaginationLayout
          totalPage={totalPage}
          pagePerList={pagePerList}
          onClick={(clickpage) => handlePageFn(clickpage)}
        >
          <DbRequestList showEachNoticeModal={showEachNoticeModal} />
        </PaginationLayout>
      </ContentLayout>
    </>
  );
};

export default RequestDB;
