import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrnetNickname } from "../../../../features/mobile/commonSlice";
import { fetchQuestionResult } from "../../../../features/mobile/questionFoodSlice";
import Parser from "html-react-parser";
import ProgressBar from "../common/ProgressBar";

const Message = ({ option, imgUrl, loading }) => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { questionResultLists } = useSelector((state) => state.questionFood);
  const navigate = useNavigate();
  const [titleForTooltip, setTitleForTooltip] = useState("");
  // const [nickname, setNickname] = useState("");
  const [i, setI] = useState(1);
  const timeouts = useRef([]);
  const timeouts2 = useRef([]);
  const saveNickname = useRef("");
  const datas = [
    { id: 1, title: "음식 섭취 불편", data: "dietInconvenient" },
    { id: 2, title: "식품 알레르기", data: "allergie" },
    { id: 3, title: "유당불내증", data: "lactoseIntolerance" },
    { id: 5, title: "아침식사 여부", data: "breakfastEat" },
    { id: 6, title: "간식 횟수", data: "snackPreferCount" },
  ];

  useEffect(() => {
    if (option === "health" || option === "disease") {
      // saveNickname.current = nickname; // nickname이 재렌더링 되어서 깜빡이는 현상 방지
      if (i === 1) {
        timeouts.current[i] = setTimeout(() => {
          setTitleForTooltip(
            `${saveNickname.current}님의<br/>답변을 읽고 있어요.`
          );
          setI(2);
        }, 1500);
      } else if (i === 2) {
        timeouts.current[i] = setTimeout(() => {
          setTitleForTooltip(
            `${saveNickname.current}님의<br/>건강정보가 거의 확인 되었어요!`
          );
          setI(3);
        }, 1500);
      } else if (
        // (loading) {
        //   navigate(`/result`);
        // }
        i === 3
      ) {
        timeouts.current[i] = setTimeout(() => {
          if (loading) {
            // ! 여기에요 뱅구버
            // navigate(`/result`);
            navigate(`/home`, {
              state: { healthResult: true },
            });
          }
        }, 1500);
      }
      // return () => timeouts.current.forEach((v) => clearTimeout(v));
    }
  }, [dispatch, navigate, i, saveNickname, loading]);

  useEffect(() => {
    if (option === "food") {
      for (let i = 0; i <= 100; i++) {
        timeouts2.current[i] = setTimeout(() => {
          setI(i);
        }, i * 80);
      }
      const timeouts3 = setTimeout(() => navigate(`/home`), 8000);
      return () => {
        timeouts2.current.forEach((v) => clearTimeout(v));
        clearTimeout(timeouts3);
      };
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (option === "food") {
      dispatch(fetchQuestionResult(loginInfo.userId));
    } else {
      dispatch(fetchCurrnetNickname(loginInfo.userId))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            setTitleForTooltip(
              `${res?.patientNickName || res?.nickName}님의<br/>${
                option !== "food" ? "건강정보" : "식습관 정보"
              }를 확인 중이에요.`
            );
            // setNickname(res?.patientNickName || res?.nickName);
            saveNickname.current = res?.patientNickName || res?.nickName; //  nickname이 재렌더링 되어서 깜빡이는 현상 방지
            setTimeout(() => setI(1), 500);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <>
      {option === "food" ? (
        <>
          {questionResultLists ? (
            <section className="mx-6 mb-24 mt-20">
              <div className="relative text-center">
                <div className="bottom-full inline-block rounded-2xl bg-green_20 px-6 py-4 text-body3_600">
                  <div className="flex justify-between">
                    <h1 className="text-body3_700 text-gray_160">
                      {questionResultLists.title}
                    </h1>
                    <span className="text-body3_700 text-green_120">
                      {questionResultLists.operationAfter}
                    </span>
                  </div>
                  <p className="mt-6 text-caption1_600 text-green_180">
                    {questionResultLists.comment}
                  </p>
                </div>
                <svg
                  className="absolute left-0 top-full h-4 w-full text-green_20"
                  x="0px"
                  y="0px"
                  viewBox="0 0 255 255"
                >
                  <polygon
                    className="fill-current"
                    points="0,0 127.5,127.5 255,0"
                  />
                </svg>
              </div>
              <img
                src={imgUrl}
                alt="컴포넌트 이미지"
                className="mx-auto my-6"
              />
              <div className="mx-auto">
                <ProgressBar barProgress={i} option={"question"} />
              </div>
              <div className="mt-6 space-y-[28px] overflow-hidden rounded-lg bg-yellow_10 p-6">
                <div className="space-y-3">
                  {datas.map((el) => (
                    <ul key={el.id} className="flex justify-between">
                      <li className="w-[180px] text-body3_700 text-gray_160">
                        {el.title}
                      </li>
                      <li className="text-right text-body3_700 text-main">
                        {questionResultLists[el.data]
                          ? el.data === "dietInconvenient"
                            ? questionResultLists[el.data]?.slice(0, 8) + "..."
                            : el.data === "allergie"
                            ? questionResultLists[el.data]?.length > 0
                              ? questionResultLists[el.data]?.map((li, idx) => (
                                  <span key={idx}>
                                    {li}
                                    {idx + 1 ===
                                    questionResultLists[el.data]?.length
                                      ? ""
                                      : ","}
                                  </span>
                                ))
                              : "없음"
                            : questionResultLists[el.data]
                          : "-"}
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </section>
          ) : null}
        </>
      ) : (
        <>
          <div className="relative left-0 right-0 top-[108px] text-center">
            <h2 className="bottom-full inline-block rounded-full bg-green_100 px-12 py-4 text-body3_600 text-white">
              {titleForTooltip ? Parser(titleForTooltip) : ""}
            </h2>
            <svg
              className="absolute left-0 top-full h-4 w-full text-green_100"
              x="0px"
              y="0px"
              viewBox="0 0 255 255"
            >
              <polygon
                className="fill-current"
                points="0,0 127.5,127.5 255,0"
              />
            </svg>
          </div>
          <img
            src={imgUrl}
            alt="컴포넌트 이미지"
            className="absolute left-0 right-0 top-[300px] mx-auto"
          />
          <div className="absolute left-0 right-0 top-[600px] mx-auto w-full">
            <ProgressBar
              barProgress={Math.floor((i / 3) * 100)}
              option={"question"}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Message;
