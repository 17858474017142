import React, { useState } from "react";
import Btn from "./Btn";
import DialogCover from "./DialogCover";
import Dialog from "./Dialog";

const PostReportDialog = ({
  onCloseDialog,
  onReportReply,
  reportStatus,
  setReportStatus,
  post,
  nickName,
}) => {
  const datas = [
    { id: 1, title: "개인 정보 노출, 유포, 거래" },
    { id: 2, title: "비방 · 비하 · 욕설 등" },
    { id: 3, title: "홍보, 영리 목적" },
    { id: 4, title: "허위조작정보 · 악성코드" },
    { id: 5, title: "불법 · 음란 · 청소년 유해" },
  ];
  const [reason, setReason] = useState("");
  const reportCard = (data) => {
    return (
      <>
        <input
          id={data.id}
          type="radio"
          name="blockReason"
          value={data.title}
          onChange={(e) => setReason(e.target.value)}
          className={"info"}
        />
        <label htmlFor={data.id} className="flex justify-between break-all">
          <span className="text-body3_500 text-black">{data.title}</span>
        </label>
      </>
    );
  };

  return (
    <>
      {reportStatus === "report" ? (
        <DialogCover
          onAction={onCloseDialog}
          innerClass={
            "z-90 bg-white rounded-md w-full mx-6 max-[320px]:max-h-[360px] overflow-y-scroll no-scrollbar  shadow-[0_0_12px_rgba(0,0,0,0.1)]"
          }
        >
          <div className="px-4 py-6">
            <h2 className="mb-6 text-subtitle2 text-black">신고 사유 선택</h2>
            {datas.map((data) => (
              <div
                key={data.id}
                className={
                  "flex w-full items-center gap-2 rounded-md bg-gray_20 p-4 " +
                  (data.id === datas?.[datas.length - 1]?.id ? "" : "mb-3 ")
                }
              >
                {reportCard(data)}
              </div>
            ))}
          </div>
          <div className="m-4 flex items-center justify-between gap-2">
            <Btn
              innerClass={"w-fulll w-1/2"}
              bgColor={"bg-white"}
              textColor={"text-main"}
              borderColor={"border border-main"}
              title={"취소"}
              active={"on"}
              onAction={onCloseDialog}
            />
            <Btn
              innerClass={"w-fulll w-1/2"}
              bgColor={"bg-main"}
              textColor={"text-white"}
              borderColor={"border border-main"}
              title={"신고"}
              active={reason?.length > 0 ? "on" : "ready"}
              onAction={() => {
                onReportReply(reason);
                setReason("");
              }}
            />
          </div>
        </DialogCover>
      ) : reportStatus === "blocking" ? (
        <DialogCover
          onAction={onCloseDialog}
          innerClass={
            "z-50 bg-white rounded-md w-full mx-6 max-[320px]:max-h-[360px] overflow-y-scroll no-scrollbar  shadow-[0_0_12px_rgba(0,0,0,0.1)]"
          }
        >
          <div className="px-4 py-6">
            <h2 className="mb-4 text-subtitle2 text-black">
              차단하시겠습니까?
            </h2>
            <p className="text-body2_500l">
              {nickName}님을 차단하시겠습니까? 차단하면 차단한 회원이 작성한
              글과 댓글은 보이지 않습니다.
            </p>
          </div>
          <div className="m-4 flex items-center justify-between gap-2">
            <Btn
              innerClass={"w-fulll w-1/2"}
              bgColor={"bg-main"}
              textColor={"text-white"}
              borderColor={"border border-main"}
              title={"차단"}
              active={"on"}
              onAction={() => {
                setReportStatus("block");
              }}
            />
            <Btn
              innerClass={"w-fulll w-1/2"}
              bgColor={"bg-white"}
              textColor={"text-main"}
              borderColor={"border border-main"}
              title={"취소"}
              active={"on"}
              onAction={onCloseDialog}
            />
          </div>
        </DialogCover>
      ) : reportStatus === "block" ? (
        <Dialog
          innerClass={"py-4 px-6 "}
          btnClass={"mt-8"}
          title={"작성자 차단"}
          desc={`작성자 ${nickName}님을 차단했습니다. <br/> 차단한 회원은 마이페이지에서 확인 가능합니다.`}
          onRightBtnAction={onCloseDialog}
          onCoverAction={onCloseDialog}
        />
      ) : reportStatus === "complete" ? (
        <Dialog
          innerClass={"py-4 px-6"}
          btnClass={"mt-8"}
          title={(post ? "게시글" : "댓글") + "신고 완료"}
          desc={`${
            post ? "게시글" : "댓글"
          } 신고가 완료되었습니다.<br/>소중한 의견 감사합니다.`}
          onRightBtnAction={onCloseDialog}
          onCoverAction={onCloseDialog}
        />
      ) : null}
    </>
  );
};

export default PostReportDialog;
