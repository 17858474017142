import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addLoginInfo,
  removePatientInfo,
} from "../../../../features/mobile/commonSlice";
import {
  saveAnswer,
  fetchAnswerList,
  increment,
  incrementByAmount,
  getNickname,
  resetI,
  changeStateArray,
} from "../../../../features/mobile/questionHealthSlice";
import { checkNickname } from "../../../../features/mobile/otherSlice";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import toastCommonProps from "../../../../common/toast";
import TitleBox from "../common/TitleBox";
import AnswerCard from "./AnswerCard";
import BtnForQuestion from "../common/BtnForQuestion";
import InputBox from "../common/InputBox";
import CharacterInput from "../sign/CharacterInput";
import SelectBox from "../common/SelectBox";
import InputCancerBox from "../common/InputCancerBox";
import { searchAndHighlight } from "../logic";

const QuestionForm = ({ onLeftAction, setOpenMessage, setLoading }) => {
  const dispatch = useDispatch();
  const heigthRef = useRef(null);
  const { loginInfo } = useSelector((state) => state.commonApp);
  let {
    questionListsStatus,
    questionLists,
    i,
    dummyNickname,
    gettingAnswers,
    mainCancerList,
    allCancerList,
  } = useSelector((state) => state.questionHealth);
  const [saveAnswereDatas, setSaveAnswerDatas] = useState([]);
  // gettingAnswers
  // userId: gettingAnswers.userId,
  // userType: gettingAnswers.userType,
  // patientName: gettingAnswers.patientName,
  // patientNickName: gettingAnswers.patientNickName,
  // patientCharacterPhoto: gettingAnswers.patientCharacterPhoto,
  // gender: gettingAnswers.gender,
  // birth: gettingAnswers.birth,
  // height: gettingAnswers.height,
  // weight: gettingAnswers.weight,
  // currentWeight: gettingAnswers.currentWeight,
  // cancerType: gettingAnswers.cancerType,
  // cancerId: gettingAnswers.cancerId,
  // cancerName: gettingAnswers.cancerName,
  // cancerStaging: gettingAnswers.cancerStaging,
  // operationYN: gettingAnswers.operationYN,
  // operationName: gettingAnswers.operationName,
  // operationDate: gettingAnswers.operationDate,
  // anticancerTreatmentYN: gettingAnswers.anticancerTreatmentYN,
  // anticancerTreatmentType: gettingAnswers.anticancerTreatmentType,
  // otherTreatmentYN: gettingAnswers.otherTreatmentYN,
  // hospitalName: gettingAnswers.hospitalName,
  // chronicDisease: [gettingAnswers.chronicDisease],
  // medicineDrugs: [gettingAnswers.medicineDrugs],
  const [nickname, setNickname] = useState(null);
  const [errorMsg, setErrorMsg] = useState({ height: null, weight: null });
  const [errorNameMsg, setErrorNameMsg] = useState(null);
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [cancer, setCancer] = useState(null);
  const [foundCancerList, setFoundCancerList] = useState(null);
  const [showFoundCancerList, setShowFoundCancerList] = useState(false);
  const [otherOperationName, setOtherOperationName] = useState(null);
  const [operationDateDatas, setOperationDateDatas] = useState({
    year: null,
    month: null,
    day: null,
  });

  const [active, setActive] = useState("ready"); //ready, on

  const [treatment, setTreatment] = useState(null);
  const [selectCount, setSelectCount] = useState(0);
  const birthYears = [];
  const operationYears = [];
  const lastYear = new Date().getFullYear() - 10;
  const lastYear2 = new Date().getFullYear();
  const base = 1930;
  const base2 = 1980;
  const filterSubQ = questionLists[10]?.answerList
    .filter((el) => {
      const pCode = saveAnswereDatas?.cancerType?.includes("CT")
        ? saveAnswereDatas?.cancerType
        : "";
      if (el.name !== "모름") {
        return el.pCode === pCode;
      }
    })
    .map((el) => {
      return el.name;
    }); //anticancerTreatmentYN: cancerType별 치료 filtering
  for (let i = 0; i <= lastYear - base; i++) {
    birthYears.push(base + i);
  }
  for (let i = 0; i <= lastYear2 - base2 + 1; i++) {
    operationYears.push(base2 + i);
  }
  const months = Array(12)
    .fill()
    .map((v, i) => (v = i + 1));
  const days = Array(31)
    .fill()
    .map((v, i) => (v = i + 1));

  // console.log(gettingAnswers, "gettingAnswers");
  // console.log(saveAnswereDatas, "saveAnswereDatas");
  // console.log(questionLists, "questionLists");

  // 렌더링시 gettingAnswers값 불러와서 저장하기
  useEffect(() => {
    // console.log("새롭게 값을 업데이트 했어???");
    // setSaveAnswerDatas({
    //   ...saveAnswereDatas,
    //   operationDate: "",
    // });
    if (gettingAnswers === null) {
      setSaveAnswerDatas({});
    } else {
      setSaveAnswerDatas(gettingAnswers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gettingAnswers]);

  const onSaveAnswer = (type, answer) => {
    // console.log("onSaveAnswer 호출됨!!!!!!");
    //useSelector 값을 못가져와서 useCallback 삭제
    let params = { userId: loginInfo.userId, [type]: answer };
    if (type === "operationDate" && answer === "OD997") {
      //모름 누를때 입력값 수정
      setOperationDateDatas({ year: null, month: null, day: null });
      // dispatch(saveAnswer({ userId: loginInfo.userId, operationDate: "OD997" }))
      //   .unwrap()
      //   .then((res) => {
      //     if (res.ok) {
      //       dispatch(fetchAnswerList(loginInfo.userId));
      //     } else
      //       toast(
      //         <p>{res.error}</p>,
      //         toastCommonProps("bottom-center", "toast_alert", 1000)
      //       );
      //   })
      //   .catch((err) => console.log(err));
      setSaveAnswerDatas({
        ...saveAnswereDatas,
        userId: loginInfo.userId,
        operationDate: "OD997",
      });
    } else {
      if (
        type === "userType" &&
        answer === "UT100" &&
        loginInfo.patientNickName
      ) {
        dispatch(removePatientInfo());
      }
      if (type === "patientCharacterPhoto") {
        dispatch(addLoginInfo({ patientCharacterPhoto: answer })); //보호자 이미지 내부 저장
        // dispatch(saveAnswer(params))
        //   .unwrap()
        //   .then((res) => {
        //     if (res.ok) dispatch(fetchAnswerList(loginInfo.userId));
        //     else
        //       toast(
        //         <p>{res.error}</p>,
        //         toastCommonProps("bottom-center", "toast_alert", 1000)
        //       );
        //   })
        //   .catch((err) => console.log(err));
        setSaveAnswerDatas({
          ...saveAnswereDatas,
          ...params,
        });
      }
      setSaveAnswerDatas({
        ...saveAnswereDatas,
        ...params,
      });
    }
  };
  console.log(saveAnswereDatas);

  // const onSaveAnswerAndNext = useCallback(
  //   (type, answer, answer2) => {
  //     // let params = { userId: loginInfo.userId, [type]: answer };
  //     // let params2 = {
  //     //   userId: loginInfo.userId,
  //     //   height: answer,
  //     //   weight: answer2,
  //     // };
  //     // dispatch(saveAnswer(type === "height,weight" ? params2 : params))
  //     //   .unwrap()
  //     //   .then((res) => {
  //     //     if (res.ok) {
  //     //       dispatch(fetchAnswerList(loginInfo.userId));
  //     //       dispatch(increment());
  //     //     } else
  //     //       toast(
  //     //         <p>{res.error}</p>,
  //     //         toastCommonProps("bottom-center", "toast_alert", 1000)
  //     //       );
  //     //   })
  //     //   .catch((err) => console.log(err));
  //     // dispatch(increment());
  //   },
  //   [dispatch, loginInfo.userId]
  // );

  // const onSaveAnswer2 = (params) => {
  //   console.log("onSaveAnswer2 호출됨!!!!!!");
  //   setSaveAnswerDatas({
  //     ...saveAnswereDatas,
  //     ...params,
  //   });
  //   dispatch(saveAnswer(params))
  //     .unwrap()
  //     .then((res) => {
  //       if (res.ok) dispatch(fetchAnswerList(loginInfo.userId));
  //       else
  //         toast(
  //           <p>{res.error}</p>,
  //           toastCommonProps("bottom-center", "toast_alert", 1000)
  //         );
  //     })
  //     .catch((err) => console.log(err));
  // };

  const onSaveAnswer2AndNext = (params, option) => {
    // console.log(params, "onSaveAnswer2AndNext");
    // console.log(option, "onSaveAnswer2AndNext option");
    setSaveAnswerDatas({
      ...saveAnswereDatas,
      ...params,
    });
    if (option) {
      dispatch(incrementByAmount(option));
    } else {
      dispatch(increment());
    }

    // dispatch(saveAnswer(params))
    //     dispatch(saveAnswer(params))
    //     .unwrap()
    //     .then((res) => {
    //       if (res.ok) {
    //         dispatch(fetchAnswerList(loginInfo.userId));
    //         if (option) dispatch(incrementByAmount(option));
    //         else dispatch(increment());
    //       } else
    //         toast(
    //           <p>{res.error}</p>,
    //           toastCommonProps("bottom-center", "toast_alert", 1000)
    //         );
    //     })
    //     .catch((err) => console.log(err));
    // },
  };

  const resultAnswer = async (option) => {
    setOpenMessage(true);
    dispatch(resetI());
    dispatch(changeStateArray(0));

    await dispatch(saveAnswer(saveAnswereDatas))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          setLoading(true);
          dispatch(fetchAnswerList(loginInfo.userId));
          if (option) dispatch(incrementByAmount(option));
          else dispatch(increment());
        } else {
          toast(
            <p>{res.error}</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
          // 에러 토스트 후 메시지창에서 화면이 멈춰서 아래 로직 추가함
          setTimeout(() => {
            setOpenMessage(false);
          }, 1500);
        }
      })
      .catch((err) => console.log(err));
  };

  const onChangeHeight = (e) => {
    setHeight(Number(e.target.value.slice(0, 5)));
    if (Number(e.target.value) < 100 || Number(e.target.value) > 200) {
      //키는 100-200 사이
      setErrorMsg({ ...errorMsg, height: "키를 다시 입력해주세요" });
    } else {
      setErrorMsg({ ...errorMsg, height: null });
    }
  };

  const onChangeWeight = (e) => {
    setWeight(Number(e.target.value.slice(0, 5)));
    if (Number(e.target.value) < 20 || Number(e.target.value) > 150) {
      //몸무게는 20-150 사이
      setErrorMsg({ ...errorMsg, weight: "몸무게를 다시 입력해주세요" });
    } else {
      setErrorMsg({ ...errorMsg, weight: null });
    }
  };
  const selectCountSave = useCallback(
    (param) => {
      // console.log("selectCountSave 호출됨!!!!!!");

      setSelectCount(selectCount + 1); // 입력시마다 axios 불리는것 방지
      if (selectCount === 0) {
        // dispatch(saveAnswer(param)) //onChange 입력시 card 해제
        //   .unwrap()
        //   .then((res) => {
        //     if (res.ok) {
        //       dispatch(fetchAnswerList(loginInfo.userId));
        //     } else
        //       toast(
        //         <p>{res.error}</p>,
        //         toastCommonProps("bottom-center", "toast_alert", 1000)
        //       );
        //   })
        //   .catch((err) => console.log(err));
        setSaveAnswerDatas({ ...saveAnswereDatas, ...param });
      }
    },
    // eslint-disable-next-line
    [
      setSelectCount,
      selectCount,
      dispatch,
      saveAnswer,
      fetchAnswerList,
      saveAnswereDatas,
    ]
  );

  const changeOtherCancerBtn = (e) => {
    // console.log("changeOtherCancerBtn 호출됨!!!!!!");

    setCancer({ id: null, name: e.target.value });
    setFoundCancerList(searchAndHighlight(allCancerList, e.target.value));
    e.target.value === ""
      ? setShowFoundCancerList(false)
      : setShowFoundCancerList(true);
    /**
     * The below code tries to nullify the cancer type
     * and operation type, since the user is writing
     * their diagnosed cancer (indicating they did not
     * find their specific cancer)
     */
    // let param = {
    //   userId: loginInfo.userId,
    //   cancerId: null,
    //   // cancerType: null,
    //   operationName: null,
    // }; //onChange 입력시 card 해제, 암종 변경으로 수술명 삭제됌
    // selectCountSave(param);
  };
  const onChangeAntiCancerTreatment = (e, keyname) => {
    // console.log("onChangeAntiCancerTreatment 호출됨!!!!!!");

    setTreatment(e.target.value);
    let param = { userId: loginInfo.userId, [keyname]: null };
    selectCountSave(param);

    // setSaveAnswerDatas({ ...saveAnswereDatas, ...param });
  };
  const onChangeOtherOperation = (e, keyname) => {
    // console.log("onChangeOtherOperation 호출됨!!!!!!");
    setOtherOperationName(e.target.value);
    let param = { userId: loginInfo.userId, [keyname]: null };
    selectCountSave(param);
    // setSaveAnswerDatas({ ...saveAnswereDatas, ...param });
  };
  const onClickOtherCancertype = (value, main) => {
    // console.log("onClickOtherCancertype 호출됨!!!!!!");

    var params;
    // console.log(value);
    if (value === null) {
      setCancer({ id: 0, name: null });
      params = {
        userId: loginInfo.userId,
        operationName: null,
        cancerId: 0,
        cancerName: null,
        cancerType: null,
      }; //operationName null 이전 암종관련 수술명 삭제
    } else {
      main
        ? setCancer({ id: value.id, name: value.viewName })
        : setCancer({ id: value.details.id, name: value.details.viewName });

      params = {
        userId: loginInfo.userId,
        operationName: null,
        cancerId: main ? value.id : value.details.id, // To do: change it to id
        cancerName: main ? value.viewName : value.details.viewName,
        cancerType: main ? value.cancerType : value.details.cancerType,
      }; //operationName null 이전 암종관련 수술명 삭제
    }
    setSelectCount(0);
    // dispatch(saveAnswer(params))
    //   .unwrap()
    //   .then((res) => {
    //     if (res.ok) {
    //       dispatch(fetchAnswerList(loginInfo.userId));
    //     } else
    //       toast(
    //         <p>{res.error}</p>,
    //         toastCommonProps("bottom-center", "toast_alert", 1000)
    //       );
    //   })
    //   .catch((err) => console.log(err));
    setSaveAnswerDatas({
      ...saveAnswereDatas,
      ...params,
    });
  };

  const onClickAntiCancerTreatment = (type, answer) => {
    // console.log("onClickAntiCancerTreatment 호출됨!!!!!!");
    onSaveAnswer(type, answer);
    setTreatment(null);
  };

  const onClickOperationName = (type, answer) => {
    // console.log("onClickOperationName 호출됨!!!!!!");
    onSaveAnswer(type, answer);
    setOtherOperationName(null);
    setSelectCount(0);
  };

  const onMoveToNext = () => {
    //다음 버튼
    if (
      questionLists[i]?.saveUserColumn === "userType" &&
      saveAnswereDatas.userType === "UT100"
    ) {
      //본인 등록일때, 보호자 입력건 건너띄기
      dispatch(incrementByAmount(3));
      if (loginInfo?.patientNickName) dispatch(removePatientInfo());
    } else if (questionLists[i]?.saveUserColumn === "patientNickName") {
      if (nickname) {
        //새로 입력시
        // onSaveAnswerAndNext("patientNickName", nickname);

        dispatch(checkNickname(nickname)) //닉네임 중복 확인
          .unwrap()
          .then((res) => {
            console.log(res);
            if (res.ok) {
              setSaveAnswerDatas({
                ...saveAnswereDatas,
                patientNickName: nickname,
              });

              dispatch(increment());
              dispatch(addLoginInfo({ patientNickName: nickname })); //닉네임 내부 저장

              setErrorNameMsg("");
              setActive("on");
            } else {
              setErrorNameMsg(res.error);
              setActive("ready");
            }
          })
          .catch((err) => console.log(err));
      } else if (
        !nickname &&
        saveAnswereDatas?.[questionLists?.[i]?.saveUserColumn]
      ) {
        //기존 닉네임있을때
        dispatch(increment());
      } else {
        //자동입력 닉네임 저장
        // onSaveAnswerAndNext("patientNickName", dummyNickname);

        dispatch(checkNickname(dummyNickname)) //닉네임 중복 확인
          .unwrap()
          .then((res) => {
            console.log(res);
            if (res.ok) {
              setSaveAnswerDatas({
                ...saveAnswereDatas,
                patientNickName: dummyNickname,
              });

              dispatch(increment());
              dispatch(addLoginInfo({ patientNickName: nickname })); //닉네임 내부 저장

              setErrorNameMsg("");
              setActive("on");
            } else {
              setErrorNameMsg(res.error);
              setActive("ready");
            }
          })
          .catch((err) => console.log(err));
      }
    } else if (questionLists[i]?.saveUserColumn === "height,weight") {
      if (
        saveAnswereDatas.height &&
        saveAnswereDatas.weight &&
        !height &&
        !weight
      )
        dispatch(increment()); //db 있을때
      else if (weight || height) {
        //새로 입력할때
        if (weight && height) {
          //db 상관없이 둘다 새로 입력 덮어쓰기
          // onSaveAnswerAndNext("height,weight", height, weight);
          setSaveAnswerDatas({
            ...saveAnswereDatas,
            height: height,
            weight: weight,
          });
          dispatch(increment());

          setHeight(null);
          setWeight(null);
        } else if (height && saveAnswereDatas.weight) {
          //height 입력
          // onSaveAnswerAndNext("height", height);
          setSaveAnswerDatas({
            ...saveAnswereDatas,
            height: height,
          });
          dispatch(increment());

          setHeight(null);
        } else if (weight && saveAnswereDatas.height) {
          //weight만 입력
          // onSaveAnswerAndNext("weight", weight);
          setSaveAnswerDatas({
            ...saveAnswereDatas,
            weight: weight,
          });
          dispatch(increment());

          setWeight(null);
        }
      }
    } else if (questionLists[i]?.saveUserColumn === "cancerType") {
      // There exist an ID => User Selected
      // There exist an ID === 0 => No Cancer Found
      // There id no ID => User Written
      // console.log("암설문 시작");
      if (cancer?.id) {
        // console.log("암설문 1");

        dispatch(increment());
      } else if (saveAnswereDatas.cancerId === 0) {
        // 암 진단 받은 적 없음 -> 결과
        // console.log("암설문 2");
        resultAnswer(6);

        // dispatch(incrementByAmount(6));
      } else if (
        // 암설문 선택안하고 바로 다음 넘길때
        saveAnswereDatas.cancerId !== null &&
        saveAnswereDatas.cancerType !== null &&
        cancer?.name === undefined
      ) {
        // console.log("암설문 선택안하고 바로 다음 넘길때");

        dispatch(increment());
      } else {
        // console.log("암설문 3");

        //직접 입력시
        // onSaveAnswerAndNext("cancerName", cancer?.name);
        setSaveAnswerDatas({
          ...saveAnswereDatas,
          cancerName: cancer?.name,
          cancerId: null,
          cancerType: null,
          operationName: null,
        });
        dispatch(increment());
      }
    }
    // 수술안함, 치료안함 -> 결과로 이동
    // 수술안함, 치료중/종료 -> 10번
    // 수술예정 / 함, 치료 안함 -> 결과
    // 함, 함 -> 차례대로
    else if (
      questionLists[i]?.saveUserColumn ===
      "operationYN,anticancerTreatmentYN,otherTreatmentYN"
    ) {
      // console.log("암설문 4");

      if (
        saveAnswereDatas.operationYN === "OY101" &&
        saveAnswereDatas.anticancerTreatmentYN === "TY102"
      ) {
        // 수술안함 / 치료안함 / 치료중, 치료종료, 치료안함
        // console.log("암설문 5");

        //수술안함, 치료안함 -> 결과로 이동
        resultAnswer(4);
        // dispatch(incrementByAmount(4));
      } else if (
        saveAnswereDatas.operationYN === "OY101" &&
        saveAnswereDatas.anticancerTreatmentYN !== "TY102"
      ) {
        // 수술안함 / 치료중, 치료종료 / 치료중
        // console.log("암설문 6");

        //수술안함, 치료중/종 료 -> 10번
        dispatch(incrementByAmount(3));
      }
      // 수술예정,수술함/ 치료중 / 치료중
      // 수술예정 / 치료종료, 치료안함 / 치료중,
      // 수술예정 / 치료중 / 치료종료,치료안함
      // 수술함 / 치료종료 / 치료중, 치료종료, 치료안함
      // 수술예정 / 치료종료 / 치료종료, 치료안함
      else {
        // console.log("암설문 7");

        dispatch(increment());
      } // 함, 함 -> 차례대로
    } else if (questionLists[i]?.saveUserColumn === "operationName") {
      // console.log("암설문 8");

      if (otherOperationName) {
        // console.log("암설문 9");

        //직접 입력시
        // onSaveAnswerAndNext("operationName", otherOperationName);
        setSaveAnswerDatas({
          ...saveAnswereDatas,
          operationName: otherOperationName,
        });
        dispatch(increment());
      } else if (saveAnswereDatas.operationName) {
        // console.log("암설문 10");

        //기존 입력값 있을때
        dispatch(increment());
      }
    } else if (questionLists[i]?.saveUserColumn === "operationDate") {
      // console.log("암설문 11");

      // 아이폰에서 아래 dayjs 코드로 보낼시 invalid date 오류 발생하여 코드 교체
      // let params = { userId: loginInfo.userId, operationDate: dayjs(`${operationDateDatas.year}, ${operationDateDatas.month}, ${operationDateDatas.day}`).format('YYYY-MM-DD') }
      let params = {
        userId: loginInfo.userId,
        operationDate: `${operationDateDatas.year}-${operationDateDatas.month}-${operationDateDatas.day}`,
      };
      console.log(params);
      const result = dayjs().add(6, "month").format("YYYY-MM-DD");
      const over6Month =
        new Date(result) <
        dayjs(
          `${operationDateDatas.year}-${operationDateDatas.month}-${operationDateDatas.day}`
        ); //미래 6개월 이상일때 toast
      if (
        operationDateDatas.year &&
        operationDateDatas.month &&
        operationDateDatas.day
      ) {
        // console.log("암설문 12");

        //새로 입력시
        if (over6Month) {
          // console.log("암설문 13");

          toast(
            <p>6개월 미만의 수술날짜를 선택해주세요.</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
        } else {
          // console.log("암설문 14");

          if (
            saveAnswereDatas.operationYN !== "OY101" &&
            saveAnswereDatas.anticancerTreatmentYN === "TY102"
          ) {
            // console.log("암설문 15");

            //수술예정/함, 치료 안함 -> 결과
            // setSaveAnswerDatas({
            //   ...saveAnswereDatas,
            //   operationDate: params.operationDate,
            // });
            saveAnswereDatas.operationDate = params.operationDate;
            // console.log(saveAnswereDatas);
            // onSaveAnswer2AndNext(params);
            resultAnswer(2);
          } else {
            // console.log("암설문 16");

            onSaveAnswer2AndNext(params);
          }
        }
        setOperationDateDatas({ year: null, month: null, day: null });
      } else if (saveAnswereDatas.operationDate) {
        // console.log("암설문 17");

        //기존 입력값 있을때
        if (
          !operationDateDatas.year &&
          !operationDateDatas.month &&
          !operationDateDatas.day
        ) {
          // console.log("암설문 18");

          //새로 입력할 값 없을때 건너띄기
          if (
            saveAnswereDatas.operationYN !== "OY101" &&
            saveAnswereDatas.anticancerTreatmentYN === "TY102"
          ) {
            // console.log("암설문 19");

            //수술예정/함, 치료 안함 -> 결과
            // dispatch(incrementByAmount(2));
            resultAnswer(2);
          } else {
            // console.log("암설문 20");

            dispatch(increment());
          }
        } else if (
          (operationDateDatas.year &&
            (!operationDateDatas.month || !operationDateDatas.day)) ||
          (operationDateDatas.month &&
            (!operationDateDatas.year || !operationDateDatas.day)) ||
          (operationDateDatas.day &&
            (!operationDateDatas.year || !operationDateDatas.month))
        ) {
          toast(
            <p>모든 항목을 입력해주세요.</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
        }
      }
    } else if (questionLists[i]?.saveUserColumn === "anticancerTreatmentType") {
      // console.log("암설문 21");

      if (treatment) {
        // console.log("암설문 22");

        //직접 입력시
        // onSaveAnswerAndNext("anticancerTreatmentType", treatment);
        // setSaveAnswerDatas({
        //   ...saveAnswereDatas,
        //   anticancerTreatmentType: treatment,
        // });
        // dispatch(increment());
        saveAnswereDatas.anticancerTreatmentType = treatment;

        resultAnswer();
      } else if (saveAnswereDatas.anticancerTreatmentType) {
        // console.log("암설문 23");
        // 마지막 다음 버튼
        //기존 입력값 있을때

        resultAnswer();
        // dispatch(increment());
      }
    } else {
      // console.log("암설문 24");

      dispatch(increment());
    }
  };

  const onSaveAnswers = (type, answer) => {
    setOperationDateDatas({ ...operationDateDatas, [type]: answer });
    // console.log("onSaveAnswers 호출됨!!!!!!");
  };

  useEffect(() => {
    dispatch(getNickname({ maxLength: 8 }));
  }, []);

  useEffect(() => {
    if (questionLists[i]?.saveUserColumn === "anticancerTreatmentType") {
      //어떤치료 직접입력시
      if (saveAnswereDatas.anticancerTreatmentType) {
        let params = {
          userId: loginInfo.userId,
          // eslint-disable-next-line
          anticancerTreatmentType: treatment,
        };
        // onSaveAnswer2(params);
        setSaveAnswerDatas({
          ...saveAnswereDatas,
          ...params,
        });

        // dispatch(saveAnswer(params))
        //   .unwrap()
        //   .then((res) => {
        //     if (res.ok) dispatch(fetchAnswerList(loginInfo.userId));
        //     else
        //       toast(
        //         <p>{res.error}</p>,
        //         toastCommonProps("bottom-center", "toast_alert", 1000)
        //       );
        //   })
        //   .catch((err) => console.log(err));
      }
    }
    // eslint-disable-next-line
  }, [treatment]);
  // console.log(saveAnswereDatas);
  useEffect(() => {
    window.scrollTo(0, 0);
    setSelectCount(0);
    if (
      questionLists[i]?.saveUserColumn === "patientNickName" &&
      !saveAnswereDatas.patientNickName
    ) {
      setActive("on");
      // dispatch(getNickname({ maxLength: 8 }))
      //   .unwrap()
      // .then(res => console.log(res))
      // .catch((err) => console.log(err));
    } else if (questionLists[i]?.saveUserColumn === "operationDate") {
      setOperationDateDatas({ year: null, month: null, day: null });
    } else if (
      questionLists[i]?.saveUserColumn ===
      "operationYN,anticancerTreatmentYN,otherTreatmentYN"
    ) {
      //null값일경우 초기값으로 저장
      if (
        saveAnswereDatas.operationYN === null &&
        saveAnswereDatas.anticancerTreatmentYN === null &&
        saveAnswereDatas.otherTreatmentYN === null
      ) {
        // onSaveAnswer2({
        //   userId: loginInfo.userId,
        //   operationYN: "OY101",
        //   anticancerTreatmentYN: "TY102",
        //   otherTreatmentYN: "OT102",
        // });
        setSaveAnswerDatas({
          ...saveAnswereDatas,
          userId: loginInfo.userId,
          operationYN: "OY101",
          anticancerTreatmentYN: "TY102",
          otherTreatmentYN: "OT102",
        });
      }
    } else if (
      questionListsStatus === "success" &&
      i >= questionLists?.length
    ) {
      console.log("설문 끝");

      console.log(questionLists, questionListsStatus, i);

      //설문 종료
      // ho
      if (saveAnswereDatas.userType === "UT101") {
        //설문 내용 내부 저장
        let params = {
          patientNickName: saveAnswereDatas.patientNickName,
          patientCharacterPhoto: saveAnswereDatas.patientCharacterPhoto,
        };
        dispatch(addLoginInfo(params));
      } else if (
        saveAnswereDatas.userType === "UT100" &&
        loginInfo.patientNickName
      ) {
        // console.log("here");
        dispatch(removePatientInfo());
      }
      setOpenMessage(true);
      dispatch(resetI());
      dispatch(changeStateArray(0));
    }
  }, [
    i,
    questionLists[i]?.saveUserColumn,
    questionListsStatus,
    questionLists?.length,
    nickname,
  ]);

  const notFound = (
    <li
      onClick={() => onClickOtherCancertype(null, true)}
      key={"cancer_empty"}
      className={
        questionLists[i]?.saveUserColumn +
        " w-full cursor-pointer rounded-2xl border border-gray_200 p-4 text-center " +
        (saveAnswereDatas?.["cancerId"] === 0 && "bg-gray_180")
      }
    >
      <h2
        className={
          "text-btn_700 " +
          (saveAnswereDatas?.["cancerId"] === 0
            ? "text-white"
            : "text-gray_200")
        }
      >
        암 진단 받은 적 없음
      </h2>
    </li>
  );

  return (
    <>
      {questionListsStatus ? (
        <>
          <TitleBox
            option={3}
            title={questionLists[i]?.question}
            subTitle={questionLists[i]?.subTitle1}
            subTitle2={questionLists[i]?.subTitle2}
            saveAnswereDatas={saveAnswereDatas}
          />
          {questionLists[i]?.saveUserColumn === "userType" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              {questionLists[i].answerList.map((answer, idx) => (
                <ul key={idx}>
                  <AnswerCard
                    title={answer.name}
                    onAction={onSaveAnswer}
                    answer={answer.dCode}
                    type={questionLists[i]?.saveUserColumn}
                    saveAnswereDatas={saveAnswereDatas}
                  />
                </ul>
              ))}
            </div>
          ) : questionLists[i]?.saveUserColumn === "patientNickName" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              <InputBox
                option="common"
                placeholder={
                  saveAnswereDatas[questionLists[i]?.saveUserColumn] &&
                  saveAnswereDatas[questionLists[i]?.saveUserColumn]?.length !==
                    ""
                    ? saveAnswereDatas[questionLists[i]?.saveUserColumn]
                    : dummyNickname
                }
                comments={"1~8자 한글, 영어, 숫자 입력이 가능합니다."}
                onChangeInput={(e) => {
                  setErrorNameMsg(null);
                  setNickname(e.target.value?.slice(0, 8));
                }}
                value={nickname}
                setValue={setNickname}
                // active={dummyNickname || nickname ? "on" : "ready"}
                active={active}
                errorMsg={nickname?.length > 0 ? errorNameMsg : null}
                setErrorMsg={setErrorNameMsg}
              />
            </div>
          ) : questionLists[i]?.saveUserColumn === "patientCharacterPhoto" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              <CharacterInput
                type={questionLists[i]?.saveUserColumn}
                onSaveAnswer={onSaveAnswer}
                character={saveAnswereDatas[questionLists[i]?.saveUserColumn]}
              />
            </div>
          ) : questionLists[i]?.saveUserColumn === "gender,birth" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              <div>
                <label className="mb-1 text-caption1_500 text-gray_160">
                  {questionLists[i].subQuestions[0].question}
                </label>
                <ul className="grid grid-cols-2 gap-2">
                  {questionLists[i].subQuestions[0].answerList.map(
                    (answer, idx) => (
                      <div key={idx}>
                        <AnswerCard
                          option={2}
                          type={"gender"}
                          title={answer.name}
                          answer={answer.dCode}
                          onAction={onSaveAnswer}
                          saveAnswereDatas={saveAnswereDatas}
                        />
                      </div>
                    )
                  )}
                </ul>
              </div>
              <div>
                <label className="mb-1 text-caption1_500 text-gray_160">
                  {questionLists[i].subQuestions[1].question}
                </label>
                <SelectBox
                  option={1}
                  type={"birth"}
                  datas={birthYears.reverse()}
                  onSaveAnswer={onSaveAnswer}
                  saveAnswereDatas={saveAnswereDatas}
                  setSaveAnswerDatas={setSaveAnswerDatas}
                />
              </div>
            </div>
          ) : questionLists[i]?.saveUserColumn === "height,weight" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              <InputBox
                refName={heigthRef}
                option="label"
                label={"키 (cm)"}
                type={"number"}
                inputM={"decimal"}
                placeholder={saveAnswereDatas.height || "000.0"}
                onChangeAction={onChangeHeight}
                value={height}
                setValue={setHeight}
                active={!(height === null || height === "")}
                errorMsg={errorMsg.height}
              />
              <InputBox
                option="label"
                label={"체중 (kg)"}
                type={"number"}
                inputM={"decimal"}
                placeholder={saveAnswereDatas.weight || "00.0"}
                onChangeAction={onChangeWeight}
                value={weight}
                setValue={setWeight}
                active={!(weight === null || weight === "")}
                errorMsg={errorMsg.weight}
              />
            </div>
          ) : questionLists[i]?.saveUserColumn === "cancerType" ? (
            <div className="mt-8 space-y-4 mb-28">
              <InputCancerBox
                label={"직접입력"}
                nameTitle={"cancerType"}
                placeholder={
                  saveAnswereDatas !== {}
                    ? saveAnswereDatas.cancerName
                    : "암 이름을 입력해주세요"
                }
                onChangeAction={(e) => changeOtherCancerBtn(e)}
                value={cancer?.name}
                setValue={setCancer}
                onDeleteValue={() => {
                  setFoundCancerList(null);
                  setShowFoundCancerList(false);
                }}
                active={cancer?.name ? "on" : "ready"}
                onSaveAnswer={onSaveAnswer}
              />
              <ul
                className="flex flex-wrap gap-2"
                id={questionLists[i].saveUserColumn}
              >
                {!showFoundCancerList ? (
                  <>
                    {mainCancerList.map((value) => (
                      <li
                        onClick={() => onClickOtherCancertype(value, true)}
                        key={value.id}
                        className={
                          questionLists[i]?.saveUserColumn +
                          " w-full cursor-pointer rounded-2xl border border-gray_200 p-4 text-center " +
                          (saveAnswereDatas?.["cancerId"] === value.id &&
                            "bg-gray_180")
                        }
                      >
                        <h2
                          className={
                            "text-btn_700 " +
                            (saveAnswereDatas?.["cancerId"] === value.id
                              ? "text-white"
                              : "text-gray_200")
                          }
                        >
                          {value.viewName}
                        </h2>
                      </li>
                    ))}
                    {notFound}
                  </>
                ) : foundCancerList?.length > 0 ? (
                  foundCancerList?.map((value) => (
                    <li
                      onClick={() => {
                        onClickOtherCancertype(value);
                      }}
                      key={value.details.id}
                      className={
                        questionLists[i]?.saveUserColumn +
                        " w-full cursor-pointer rounded-2xl border border-gray_200 p-4 text-center " +
                        (saveAnswereDatas?.["cancerId"] === value.details.id &&
                          "bg-gray_180")
                      }
                    >
                      <h2
                        className={
                          "text-btn_700 " +
                          (saveAnswereDatas?.["cancerId"] === value.details.id
                            ? "text-white"
                            : "text-gray_200")
                        }
                      >
                        {saveAnswereDatas?.["cancerId"] === value.details.id
                          ? value.details.viewName
                          : value.result}
                      </h2>
                    </li>
                  ))
                ) : (
                  notFound
                )}
              </ul>

              {/* imhere */}
            </div>
          ) : questionLists[i]?.saveUserColumn === "cancerStaging" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              {questionLists[i].answerList.map((answer, idx) => (
                <ul key={idx}>
                  <AnswerCard
                    title={answer.name}
                    onAction={onSaveAnswer}
                    answer={answer.dCode}
                    type={questionLists[i]?.saveUserColumn}
                    saveAnswereDatas={saveAnswereDatas}
                  />
                </ul>
              ))}
            </div>
          ) : questionLists[i]?.saveUserColumn ===
            "operationYN,anticancerTreatmentYN,otherTreatmentYN" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              {questionLists[i].subQuestions.map((sub, idx) => (
                <div key={idx}>
                  <label className="mb-1 text-caption1_500 text-gray_160">
                    {idx === 1
                      ? filterSubQ.map(
                          (el, indx) =>
                            el + (indx + 1 === filterSubQ.length ? "" : ", ")
                        )
                      : sub.question}
                  </label>
                  <ul className="grid grid-cols-3 gap-2">
                    {sub.answerList.map((answer, idx) => (
                      <div key={idx}>
                        <AnswerCard
                          option={3}
                          type={sub.saveUserColumn}
                          title={answer.name}
                          answer={answer.dCode}
                          onAction={onSaveAnswer}
                          saveAnswereDatas={saveAnswereDatas}
                        />
                      </div>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ) : questionLists[i]?.saveUserColumn === "operationName" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              {questionLists[i]?.answerList
                .filter(
                  (answer) =>
                    answer.pCode === saveAnswereDatas.cancerType ||
                    answer.dCode === "ON997"
                )
                .map((answer, idx) => (
                  <ul key={idx}>
                    {/* 어떤 수술인가요? */}
                    <AnswerCard
                      title={answer.name}
                      onAction={() =>
                        onClickOperationName(
                          questionLists[i]?.saveUserColumn,
                          answer.dCode
                        )
                      }
                      answer={answer.dCode}
                      type={questionLists[i]?.saveUserColumn}
                      saveAnswereDatas={saveAnswereDatas}
                    />
                  </ul>
                ))}
              {questionLists[i]?.answerList.filter(
                (answer) =>
                  answer.pCode === saveAnswereDatas.cancerType ||
                  answer.dCode === "ON997"
              )?.length === 1 &&
                questionLists[i]?.answerList.filter(
                  (answer) => answer.pCode === saveAnswereDatas.cancerType
                )?.length === 0 && ( //수술명: 해당 암종에 대한 필터링 + answerList가 모름만 있을 경우 직접입력
                  <InputBox
                    option="label"
                    label={"직접입력"}
                    nameTitle={"operationName"}
                    placeholder={
                      saveAnswereDatas.operationName === "ON997" ||
                      !saveAnswereDatas.operationName
                        ? "수술명을 입력해주세요."
                        : saveAnswereDatas?.operationName
                    }
                    onChangeAction={(e) =>
                      onChangeOtherOperation(
                        e,
                        questionLists[i]?.saveUserColumn
                      )
                    }
                    value={otherOperationName}
                    setValue={setOtherOperationName}
                    onSaveAnswer={onSaveAnswer}
                  />
                )}
            </div>
          ) : questionLists[i]?.saveUserColumn === "operationDate" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              {/* saveAnswereDatas.operationDate가 null(초기값)이거나 OD997물고 있으면 날짜 각각 초기값으로 변경 */}
              <SelectBox
                option={2}
                type={"year"}
                value={operationDateDatas.year}
                name={"년"}
                placeholder={
                  saveAnswereDatas.operationDate === null ||
                  saveAnswereDatas.operationDate === undefined ||
                  saveAnswereDatas.operationDate === "OD997"
                    ? dayjs().format("YYYY")
                    : operationDateDatas.year
                    ? operationDateDatas.year
                    : saveAnswereDatas.operationDate.split("-")[0]
                }
                datas={operationYears.reverse()}
                onSaveAnswers={onSaveAnswers}
                setSelectCount={setSelectCount}
                selectCount={selectCount}
                stateDate={operationDateDatas}
                setSaveAnswerDatas={setSaveAnswerDatas}
                saveAnswereDatas={saveAnswereDatas}
              />
              <div className="grid grid-cols-2 gap-2">
                <SelectBox
                  option={2}
                  type={"month"}
                  value={operationDateDatas.month}
                  name={"월"}
                  placeholder={
                    saveAnswereDatas.operationDate === null ||
                    saveAnswereDatas.operationDate === undefined ||
                    saveAnswereDatas.operationDate === "OD997"
                      ? "01"
                      : operationDateDatas.month
                      ? operationDateDatas.month
                      : saveAnswereDatas.operationDate.split("-")[1]
                  }
                  datas={months}
                  onSaveAnswers={onSaveAnswers}
                  setSelectCount={setSelectCount}
                  selectCount={selectCount}
                  stateDate={operationDateDatas}
                  setSaveAnswerDatas={setSaveAnswerDatas}
                  saveAnswereDatas={saveAnswereDatas}
                />
                <SelectBox
                  option={2}
                  type={"day"}
                  value={operationDateDatas.day}
                  name={"일"}
                  placeholder={
                    saveAnswereDatas.operationDate === null ||
                    saveAnswereDatas.operationDate === undefined ||
                    saveAnswereDatas.operationDate === "OD997"
                      ? "01"
                      : operationDateDatas.day
                      ? operationDateDatas.day
                      : saveAnswereDatas.operationDate.split("-")[2]
                  }
                  datas={days}
                  onSaveAnswers={onSaveAnswers}
                  setSelectCount={setSelectCount}
                  selectCount={selectCount}
                  stateDate={operationDateDatas}
                  setSaveAnswerDatas={setSaveAnswerDatas}
                  saveAnswereDatas={saveAnswereDatas}
                />
              </div>
              {/* 모름박스 추가*/}
              {questionLists[i].answerList
                .filter((answer) => answer.dCode === "OD997")
                .map((answer, idx) => (
                  <ul key={idx}>
                    <AnswerCard
                      title={answer.name}
                      onAction={onSaveAnswer}
                      answer={answer.dCode}
                      type={questionLists[i]?.saveUserColumn}
                      saveAnswereDatas={saveAnswereDatas}
                    />
                  </ul>
                ))}
            </div>
          ) : questionLists[i]?.saveUserColumn === "anticancerTreatmentType" ? (
            <div className="mt-8 space-y-4">
              {!saveAnswereDatas.cancerType?.includes("CT")
                ? questionLists[i].answerList
                    .filter((answer) => answer.pCode === "")
                    .map((answer, idx) => (
                      <ul key={idx} className={questionLists[i].saveUserColumn}>
                        <AnswerCard
                          title={answer.name}
                          onAction={() =>
                            onClickAntiCancerTreatment(
                              questionLists[i]?.saveUserColumn,
                              answer.dCode
                            )
                          }
                          answer={answer.dCode}
                          type={questionLists[i]?.saveUserColumn}
                          saveAnswereDatas={saveAnswereDatas}
                        />
                      </ul>
                    ))
                : questionLists[i].answerList
                    .filter(
                      (answer) => answer.pCode === saveAnswereDatas.cancerType
                    )
                    .map((answer, idx) => (
                      <ul key={idx}>
                        <AnswerCard
                          title={answer.name}
                          onAction={() =>
                            onClickAntiCancerTreatment(
                              questionLists[i]?.saveUserColumn,
                              answer.dCode
                            )
                          }
                          answer={answer.dCode}
                          type={questionLists[i]?.saveUserColumn}
                          saveAnswereDatas={saveAnswereDatas}
                        />
                      </ul>
                    ))}
              <InputBox
                option="label"
                label={"직접입력"}
                placeholder={
                  saveAnswereDatas?.anticancerTreatmentType &&
                  !saveAnswereDatas?.anticancerTreatmentType?.includes("TT")
                    ? saveAnswereDatas?.anticancerTreatmentType
                    : "치료 직접 입력"
                }
                onChangeAction={(e) =>
                  onChangeAntiCancerTreatment(
                    e,
                    questionLists[i]?.saveUserColumn
                  )
                }
                value={treatment}
                setValue={setTreatment}
                nameTitle={"anticancerTreatmentType"}
              />
            </div>
          ) : (
            ""
          )}

          <BtnForQuestion
            i={i}
            active={
              questionLists[i]?.saveUserColumn === "patientNickName"
                ? "on"
                : questionLists[i]?.saveUserColumn === "gender,birth"
                ? saveAnswereDatas.gender && saveAnswereDatas.birth
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn === "height,weight"
                ? errorMsg.height || errorMsg.weight
                  ? "ready"
                  : (saveAnswereDatas.height && saveAnswereDatas.weight) ||
                    (saveAnswereDatas.height && weight) ||
                    (saveAnswereDatas.weight && height) ||
                    weight ||
                    height
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn === "cancerType"
                ? saveAnswereDatas.cancerId ||
                  saveAnswereDatas.cancerId === 0 ||
                  cancer ||
                  saveAnswereDatas.cancerName
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn ===
                  "operationYN,anticancerTreatmentYN,otherTreatmentYN"
                ? saveAnswereDatas.operationYN &&
                  saveAnswereDatas.anticancerTreatmentYN &&
                  saveAnswereDatas.otherTreatmentYN
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn === "operationName"
                ? saveAnswereDatas.operationName || otherOperationName
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn === "operationDate"
                ? saveAnswereDatas.operationDate ||
                  (operationDateDatas.year &&
                    operationDateDatas.month &&
                    operationDateDatas.day)
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn === "anticancerTreatmentType"
                ? saveAnswereDatas.anticancerTreatmentType || treatment
                  ? "on"
                  : "ready"
                : saveAnswereDatas?.[questionLists[i]?.saveUserColumn]
                ? "on"
                : "ready"
            }
            onAction1={onLeftAction}
            onAction2={onMoveToNext}
          />
        </>
      ) : (
        "일시적인 오류입니다. 오류가 지속된다면 문의사항을 통해 알려주세요."
      )}
    </>
  );
};

export default QuestionForm;
