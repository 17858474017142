import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/common/Navbar";
import Sidebar from "../../components/common/Sidebar";
import DaumPostcodeEmbed from "react-daum-postcode";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import { addHospital } from "../../../../features/admin/hospitalSlice";
import { checkName } from "../../commonfunc/Reg";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../components/common/ButtonCustom";
import CustomCenterModal from "../../components/common/CustomCenterModal";
import { LabelAdminInput } from "../../components/common/LabelAdminInput";
const HospitalAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  useEffect(() => {
    if (!loginInfo?.adminId) {
      navigate("/admin/signin");
    }
  }, []);
  //daum postcode - 주소찾기기능
  const [postCode, setPostCode] = useState(false);
  const [hospitalInput, setHospitalInput] = useState({
    code: "", //병원 코드
    name: "", //병원 이름
    address: "", //주소
    addressDetail: "", //상세 주소
    loginedAdminID: loginInfo?.adminId,
  });
  const errorMsgColor = "text-caption_1 text-system_error mx-2";
  const noAccepttoClick =
    hospitalInput.address === "" ||
    hospitalInput.code === "" ||
    hospitalInput.loginedAdminID === "";

  //onChange함수
  const onChangeAction = (e) => {
    const { name, value } = e.target;
    setHospitalInput({ ...hospitalInput, [name]: value });
  };

  const handleComplete = (data) => {
    setPostCode(false);
    setHospitalInput({ ...hospitalInput, address: data.address });
  };
  const showPostCodeModal = () => {
    setPostCode(!postCode);
  };

  const submitData = async () => {
    //전체 input 정규식 값이 true인지 확인
    if (checkName(hospitalInput.name)) {
      const result = await dispatch(addHospital(hospitalInput)).unwrap();
      try {
        if (result.data.ok) {
          toast(
            <p>병원등록 완료.</p>,
            toastCommonProps("top-right", "toast_alert", 1000)
          );
          setTimeout(() => navigate("/admin/hospital"), 2000);
        } else {
          toast(
            <p>{result.data.error}</p>,
            toastCommonProps("top-right", "toast_alert", 1000)
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {postCode && (
        <CustomCenterModal
          onClickFc={showPostCodeModal}
          customlayout={"w-[400px] rounded-xl bg-white"}
        >
          <div className="relative pb-[20px] pt-[50px]">
            <span
              className="absolute right-3 top-3 block flex h-[28px] w-[28px] items-center justify-center rounded-full bg-[#F3F3F2] "
              onClick={showPostCodeModal}
            >
              <img
                src="/images/admin/icon/close.svg"
                className="cursor-pointer"
              />
            </span>
            <DaumPostcodeEmbed onComplete={handleComplete} />
          </div>
        </CustomCenterModal>
      )}
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="py-6">
          <div className="flex w-full flex-col items-center">
            <div className="flex w-[768px] items-center justify-center rounded-[24px] bg-[#FCFCFC] p-[20px] shadow-md">
              <div>
                <p className="py-2 text-lg font-semibold tracking-wide text-[#05040D]">
                  병원 등록
                </p>
                <div className="flex w-[340px] flex-col gap-y-[8px]">
                  <LabelAdminInput
                    title={"병원명"}
                    name={"name"}
                    type={"text"}
                    value={hospitalInput.name}
                    placeholderVal={"병원명을 입력해주세요"}
                    inputcss={
                      "w-full p-[12px] gap-[10px] bg-[#FCFCFC] border-[#A39F9B] rounded-[4px] border placeholder:text-sm focus:outline-none "
                    }
                    onChangeAction={(e) => onChangeAction(e)}
                    onKeyPressAction={submitData}
                  />
                  <LabelAdminInput
                    title={"병원 코드"}
                    name={"code"}
                    type={"text"}
                    value={hospitalInput.name}
                    placeholderVal={"병원 코드:병원 영문명+등록일"}
                    inputcss={
                      "w-full p-[12px] gap-[10px] bg-[#FCFCFC] border-[#A39F9B] rounded-[4px] border placeholder:text-sm focus:outline-none "
                    }
                    onChangeAction={(e) => onChangeAction(e)}
                    onKeyPressAction={submitData}
                  />
                  <div>
                    <label className="text-xs font-normal text-[#463F38]">
                      병원 주소
                    </label>
                    <div className="flex gap-x-[10px]">
                      <input
                        className="w-full gap-[10px] rounded-[4px] border border-[#A39F9B] bg-[#FCFCFC] p-[12px] placeholder:text-sm focus:outline-none "
                        name="adress"
                        placeholder="주소를 입력해 주세요"
                        value={hospitalInput.address}
                        disabled
                      />
                      <span
                        className="flex w-[100px] cursor-pointer items-center  justify-center gap-[10px] rounded-[4px] bg-orange_100 p-[12px] text-xs	font-semibold	text-[#FCFCFC]"
                        onClick={showPostCodeModal}
                      >
                        주소 찾기
                      </span>
                    </div>
                    <input
                      className="mt-[10px] w-full gap-[10px] rounded-[4px] border border-[#A39F9B] bg-[#FCFCFC] p-[12px] placeholder:text-sm focus:outline-none "
                      name="addressDetail"
                      placeholder="상세주소를 입력해 주세요"
                      defaultValue={hospitalInput.addressDetail}
                      onChange={(e) => onChangeAction(e)}
                    />
                  </div>
                  <div className="flex w-[340px] gap-[10px] pt-[24px]">
                    <BorderButtonCustom
                      onClick={() => navigate(-1)}
                      content={"취소"}
                      bgcolor={""}
                      textcolor={"text-orange_100"}
                      bordercolor={"border-orange_100"}
                      textsize={"text-caption1_600"}
                      width={"30%"}
                    />
                    <BGButtonCustom
                      onClick={noAccepttoClick ? undefined : submitData}
                      content={"저장하기"}
                      bgcolor={
                        noAccepttoClick ? "bg-orange_60" : "bg-orange_100"
                      }
                      textcolor={"text-white"}
                      textsize={"text-caption1_600"}
                      width={"70%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HospitalAdd;
