export const regMobile = (value) => {
  let mobile = /^010([0|1|6|7|8|9]?)-?([0-9]{4})-?([0-9]{4})$/;
  if (mobile.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const regPass = (value) => {
  //영문, 숫자만 포함 6자리-20자리
  let regPass1 = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,20}$/; //영문, 숫자 포함 6자리 이상
  // eslint-disable-next-line
  let regPass2 = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g; //특수 문자 체크
  if (regPass1.test(value) && !regPass2.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const pointFn = (no) => {
  return Number(no)?.toLocaleString("en");
};

export const mobileTypeFn = () => {
  const uagent = navigator.userAgent.toLowerCase();
  const android_agent = uagent.search("android");
  const iphone = uagent.search("iphone");
  const ipad = uagent.search("ipad");

  if (android_agent > -1) {
    //안드로이드
    return "android";
  } else if (iphone > -1 || ipad > -1) {
    //아이폰
    return "iphone";
  } else return "pc";
};

export const regDecimal = (value) => {
  //소수점 1째 자리까지 기록
  let decimal = /^[\d]*\.?[\d]{0,1}$/;
  if (decimal.test(value)) {
    return true;
  } else {
    return false;
  }
};

const randomMessages = [
  "올바른 영양 섭취는 빠른 회복의 지름길이에요.",
  "힐리어리가 든든한 동반자가 되어 줄게요.",
  "이번 주에 산책은 얼마나 하셨나요?",
  "퇴원 후 영양 관리, 식단 관리는 힐리어리가 도와드릴게요.",
  "복잡하고 어려운 암 환자의 식단, 힐리어리에게 맡겨보세요.",
  "오늘은 어떤 하루였나요? 떠오르는 감정들을 기록해보세요.",
  "매일매일 뭔가 작지만 새로운 일들을 시작해보세요.",
  "외로움은 사실 내가 더 큰 사람이 될 수 있다는 내 마음의 성장 메시지에요.",
  "다른 사람들처럼 당신도 나을 수 있을 거에요.",
  "힘든 치료 과정을 잘 견뎌 내는 당신이 자랑스러워요.",
  "치료에 전념할 수 있도록 힐리어리가 도울게요.",
  "오늘도 힘 내줘서 고마워요!",
  "우리 꼭 같이 이겨내요.",
  "좋아하는 음식으로 즐겁게 식사하세요.",
  "모든 음식은 꼭꼭 씹어서 천천히 삼키세요.",
  "주위 사람들에게 감정을 솔직하게 이야기해보세요. 두려움, 슬픔, 외로움 등 어떤 감정이나 다 좋아요.",
  "눈을 감고 심호흡을 크게 해보세요. 온몸을 편안하게 이완시켜 보세요.",
  "가벼운 걷기나 스트레칭을 하면 피로를 줄이고 기분이 나아질 수 있어요.",
  "명상은 마음에서 부정적인 생각을 지우고 긍정적인 시각을 유지하는 데 도움이 돼요.",
  "나에게 맞춘 추천 식단을 가족에게 공유해보세요.",
  "특별히 좋아하는 음식이 있나요? 음식과 레시피를 즐겨찾기 할 수 있어요.",
  "체중에 변화가 있으신가요? 체중이 변하면 영양 관리 목표와 추천 식단이 바뀌어요!",
  "힐리어리가 더 궁금하다면 블로그로 놀러 오세요.",
  "곧 커뮤니티에서도 다양한 소식과 함께 만나요!",
  "밥은 꼭 챙겨 드세요!",
  "오늘 충분히 영양 섭취하셨나요?",
];

export const randomMsgFn = () => {
  const chosenNum = Math.floor(Math.random() * randomMessages.length);
  return randomMessages[chosenNum];
};

export const checkCancerType = (str) => {
  if (
    str?.substring(0, 2) === "CT" &&
    str?.length === 5 &&
    !isNaN(str?.substring(2))
  ) {
    return true;
  } else {
    return false;
  }
};

export const formatStringWithLinks = (str, main, postId, action, handlers) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = str?.split(urlRegex);
  if (main) {
    return [str];
  } else {
    return parts?.map((part, index) => {
      if (part.match(urlRegex)) {
        // const uniqueString = `/community/elink/${postId}/${encodeURIComponent(
        //   part
        // )}/`;
        return (
          <span
            {...handlers}
            onClick={(e) => {
              if (mobileTypeFn() === "iphone" || mobileTypeFn() === "android") {
                window.flutter_inappwebview?.callHandler("linkUrl", part);
              } else {
                // handlers.onClick(e, uniqueString);
                window.open(part, "_blank");
              }
            }}
            key={index}
            className={handlers.className()}
          >
            {part}
          </span>
        );
      } else {
        return part;
      }
    });
  }
};

// for scroll on focus logic visit => src/screens/mobile/components/common/InputSelectBox.js

/**
 * QuestionForm (health => search cancer)
 *
 * arr => list containing viewName and similar word
 *        [{viewName: "Search Context", similarWord: "Similar word to Search Context", ...},
 *         ...
 *        ]
 * query => word to search
 *        "any word to search"
 *
 * Regex Cleaned means that the string will only contain (Korean or English) letters and numbers
 */
export const searchAndHighlight = (arr = [], query = "", showAll = false) => {
  // Regex Patterns
  const regexCleanedPattern =
    /[^a-z0-9\uac00-\ud7a3\u3131-\u314e\u314f-\u3163]/gi;

  const result = [];
  const noMatchResult = []; // when show all is true

  // => processing and converting the query to regex
  // escape all special characters using the RegExp constructor and the replace method
  const escapedString = query.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");

  // \uac00-\ud7a3 => 가 ~ 힣
  // \u3131-\u314e => ㄱ ~ ㅎ
  // \u314f-\u3163 => ㅏ ~ ㅣ
  const cleanedString = escapedString.replace(regexCleanedPattern, "");
  // If query does not contain either (numbers, English alphabet or Korean Alphabet)
  // terminate the search as there is nothing that will match the query
  if (cleanedString === "") return { result: [], details: [] };

  // Creating a regex to match all occurrences of the search query
  const regex = new RegExp(escapedString, "gi");
  const regexCleaned = new RegExp(cleanedString, "gi");

  for (let i = 0; i < arr.length; i++) {
    // => processing and converting (viewName, similarWord, Category 1~3)
    const str = arr[i].viewName;
    const strCleaned = str.replace(regexCleanedPattern, "");

    const similarStr = arr[i].similarWord?.replace(regexCleanedPattern, "");

    const category1 = arr[i].category1?.replace(regexCleanedPattern, "");
    const category2 = arr[i].category2?.replace(regexCleanedPattern, "");
    const category3 = arr[i].category3?.replace(regexCleanedPattern, "");

    // => matching processed strings with regexs
    const isMatchCleaned =
      category1?.match(regexCleaned) ||
      category2?.match(regexCleaned) ||
      category3?.match(regexCleaned);

    const isMatch = str.match(regex);
    const isMatchViewNameCleaned = strCleaned.match(regexCleaned);

    if (
      similarStr?.match(regexCleaned) ||
      isMatchCleaned ||
      isMatchViewNameCleaned
    ) {
      let parts;
      const highlighted = [];

      // 1) => if ViewName is exact match with query
      if (isMatch && isMatch.length > 0) {
        parts = str.split(regex);
        for (let j = 0; j < parts.length; j++) {
          if (j > 0 || parts[j] !== "") {
            // skip the empty string at the beginning
            highlighted.push(parts[j]);
          }
          if (isMatch && isMatch[j]) {
            highlighted.push(
              <span className="text-main" key={arr[i].id + "-" + j}>
                {isMatch[j]}
              </span>
            );
          }
        }
      } else if (isMatchViewNameCleaned && isMatchViewNameCleaned.length > 0) {
        parts = strCleaned.split(regexCleaned);
        const highlightIndex = [];
        // array that has been cut with the found str
        const cutArr = [];
        for (let j = 0; j < parts.length; j++) {
          if (j > 0 || parts[j] !== "") {
            // skip the empty string at the beginning
            cutArr.push(parts[j]);
            highlightIndex.push(0);
          }
          if (isMatchViewNameCleaned && isMatchViewNameCleaned[j]) {
            cutArr.push(isMatchViewNameCleaned[j]);
            highlightIndex.push(1);
          }
        }
        let copyStr = str;
        let copyStrIndex = 0;
        const final = [];
        // console.log("cutArr >>> ", cutArr);
        for (let word of cutArr) {
          let tmpWord = "";
          let wordIndex = 0;

          for (let j = 0; j < copyStr.length; j++) {
            if (wordIndex === word.length) {
              copyStrIndex += j;
              if (regexCleanedPattern.test(copyStr[j])) {
                tmpWord += copyStr[j];
              }
              break;
            }
            if (regexCleanedPattern.test(copyStr[j])) {
              tmpWord += copyStr[j];
            } else if (copyStr[j] === word[wordIndex]) {
              tmpWord += copyStr[j];
              wordIndex++;
            }
          }
          // console.log("copyStr >>> ", copyStr);
          copyStr = str.slice(copyStrIndex, str.length);
          final.push(tmpWord);
          // console.log("tmpWord >>> ", tmpWord);
        }
        for (const [i, highlight] of highlightIndex.entries()) {
          if (highlight) {
            highlighted.push(
              <span className="text-red-500" key={arr[i].id + "-" + i}>
                {final[i]}
              </span>
            );
          } else {
            highlighted.push(final[i]);
          }
        }

        // highlighted.push(str);
      } else {
        highlighted.push(str);
      }

      result.push({ result: highlighted, details: arr[i] });
    } else if (showAll) {
      noMatchResult.push({ result: [str], details: arr[i] });
    }
  }
  result.push(...noMatchResult);
  return result;
};

// ios에서 input창 focus되어도 키보드가 나타나지 않는 문제 해결
// 임시 input태그에 포커스줬다 원하는 element에 포커스를 가도록
export const focusAndOpenKeyboard = (el, timeout) => {
  if (!timeout) {
    timeout = 50;
  }
  if (el) {
    // Align temp input element approximately where the input element is
    // so the cursor doesn't jump around
    const __tempEl__ = document.createElement("input");
    __tempEl__.style.position = "absolute";
    __tempEl__.style.top = el.offsetTop + 7 + "px";
    __tempEl__.style.left = el.offsetLeft + "px";
    __tempEl__.style.height = 0;
    __tempEl__.style.opacity = 0;
    // Put this temp element as a child of the page <body> and focus on it
    document.body.appendChild(__tempEl__);
    __tempEl__.focus();

    // The keyboard is open. Now do a delayed focus on the target element
    setTimeout(function () {
      el.focus();
      el.click();
      // Remove the temp element
      document.body.removeChild(__tempEl__);
    }, timeout);
  }
};

export const checkMinVersion = (curVersion, minVersion) => {
  var needUpdate = false;
  if (curVersion !== null && curVersion !== "") {
    var curVersionList = curVersion.split(".");
    var minVersionList = minVersion.split(".");
    if (curVersionList.length === 3) {
      var curVersion1 = Number(curVersionList[0]);
      var minVersion1 = Number(minVersionList[0]);
      var curVersion2 = Number(curVersionList[1]);
      var minVersion2 = Number(minVersionList[1]);
      var curVersion3 = Number(curVersionList[2]);
      var minVersion3 = Number(minVersionList[2]);

      if (curVersion1 < minVersion1) {
        needUpdate = true;
      }
      if (
        !needUpdate &&
        curVersion1 === minVersion1 &&
        curVersion2 < minVersion2
      ) {
        needUpdate = true;
      }
      if (
        !needUpdate &&
        curVersion1 === minVersion1 &&
        curVersion2 === minVersion2 &&
        curVersion3 < minVersion3
      ) {
        needUpdate = true;
      }
    } else {
      needUpdate = true;
    }
  } else {
    needUpdate = true;
  }
  return needUpdate;
};
