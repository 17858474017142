import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const fetchInfoPostings = createAsyncThunk(
  "info/fetchInfoPostings",
  async (params) => {
    const res = await api.post(`/posting/getPageInfoPostings`, params);
    return res.data;
  }
);

export const fetchInfoPostingDetails = createAsyncThunk(
  "info/fetchInfoPostingDetails",
  async (params) => {
    const res = await api.post(
      `/posting/getAllRepliesOfOneInfoPosting`,
      params
    );
    return res.data;
  }
);

export const onSetBookmarkInfo = createAsyncThunk(
  "info/onSetBookmarkInfo",
  async (params) => {
    const res = await api.post(`/posting/setBookmarkInfoPosting`, params);
    return res.data;
  }
);

// 일시적 암 유형 api
export const getCancerType = createAsyncThunk(
  "info/getCancerType",
  async (params) => {
    const res = await api.get(`/user/getCancerType/${params}`);
    return res.data;
  }
);

// 병원정보 조회 메인 데이터
export const getHospitalInfo = createAsyncThunk(
  "info/getHospitalInfo",
  async () => {
    const res = await api.get(`/hospitalInfo/getHospitalInfoMain`);
    return res.data;
  }
);

// 병원 정보 검색
export const searchHospitalInfo = createAsyncThunk(
  "info/searchHospitalInfo",
  async (params) => {
    const res = await api.post(`/hospitalInfo/search`, params);
    return res.data;
  }
);

const initialState = {
  postingDetailsStatus: null,
  existNextPosting: null,
  postingDetails: null,
  totalReplies: null,
  searchedValue: null,
  totalPosts: null,
  prevTabTitle: null,
  prevSubTabTitle: null,
  prevPostList: null,
  prevBookmark: null,
  cancerType: null,
  hospitalInfoList: null,

  // myPage
  isLike: null,
  rangeMonth: null, // null/unknown === 전체
};

const infoSlice = createSlice({
  name: "정보",
  initialState,
  reducers: {
    onSetSearchedValue: (state, action) => {
      state.searchedValue = action.payload;
    },
    onSavePrevTabTitle: (state, action) => {
      state.prevTabTitle = action.payload.tabTitle;
      state.prevSubTabTitle = action.payload.subTabTitle;
    },
    onResetPrevTabTitle: (state) => {
      state.prevTabTitle = null;
      state.prevSubTabTitle = null;
    },

    ////////////////////////////
    // new info page reducers //
    ////////////////////////////

    onSaveInfoPage: (state, action) => {
      // save all the contents of current page to redux
      state.prevPostList = action.payload.postList;
      state.prevTabTitle = action.payload.tabTitle;
      state.prevSubTabTitle = action.payload.subTabTitle;
      state.prevBookmark = action.payload.bookmark;
    },
    onSetPrevBookmark: (state, action) => {
      state.prevBookmark = action.payload;
    },
    onResetPrevBookmark: (state) => {
      state.prevBookmark = [];
    },
    onSetTotalPosts: (state, action) => {
      state.totalPosts = action.payload;
    },
    onResetTotalPosts: (state) => {
      state.totalPosts = null;
    },
    onSetPrevPostList: (state, action) => {
      state.prevPostList = action.payload;
    },
    onResetAll: (state) => {
      state.prevBookmark = [];
      state.prevPostList = [];
      state.searchedValue = null;
      state.totalPosts = null;

      // myPage
      state.isLike = null;
      state.rangeMonth = null;
    },
    onResetPosts: (state) => {
      state.prevBookmark = [];
      state.prevPostList = [];
    },
    onSetExistNextPosting: (state, action) => {
      state.existNextPosting = action.payload;
    },
    // myPage

    onSetIsLike: (state, action) => {
      state.isLike = action.payload;
    },
    onSetRangeMonth: (state, action) => {
      state.rangeMonth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInfoPostingDetails.pending, (state) => {
      state.postingDetailsStatus = false;
    });
    builder.addCase(fetchInfoPostingDetails.fulfilled, (state, action) => {
      state.postingDetailsStatus = true;
      state.postingDetails = action.payload;
      state.totalReplies = action.payload.totalReplies;
    });
    builder.addCase(searchHospitalInfo.fulfilled, (state, action) => {
      state.hospitalInfoList = action.payload.hospitalInfoList;
    });
  },

  // {
  //   [fetchInfoPostingDetails.pending]: (state) => {
  //     state.postingDetailsStatus = false;
  //   },
  //   [fetchInfoPostingDetails.fulfilled]: (state, action) => {
  //     state.postingDetailsStatus = true;
  //     state.postingDetails = action.payload;
  //     state.totalReplies = action.payload.totalReplies;
  //   },
  // },
});

export const {
  onResetPrevTabTitle,
  onSetExistNextPosting,
  onSetPrevPostList,
  onSetSearchedValue,
  onSavePrevTabTitle,
  onSetPrevBookmark,
  onResetPrevBookmark,
  onResetAll,
  onResetPosts,
  onSetTotalPosts,
  onSaveInfoPage,
  onSetIsLike,
  onSetRangeMonth,
} = infoSlice.actions;

export default infoSlice.reducer;
