import React from "react";
import MenuBar from "../components/common/MenuBar";
import { useNavigate } from "react-router-dom";
import HeadCont from "../components/common/HeadCont";

const Setting = () => {
  const navigate = useNavigate();
  const datas = [
    // { id: 1, subMenu: [{ id: 1, title: '계정 설정', sublink: 'account' }, { id: 2, title: '알림 설정', sublink: 'push' }, { id: 3, title: '병원 회원 등록', sublink: 'hospital/register' }] },
    {
      id: 1,
      subMenu: [
        { id: 1, title: "계정 설정", sublink: "account" },
        { id: 2, title: "알림 설정", sublink: "push" },
      ],
    },
    {
      id: 2,
      subMenu: [
        { id: 1, title: "약관 및 정책", sublink: "policy" },
        { id: 2, title: "출처", sublink: "source" },
        { id: 3, title: "버전 관리", sublink: "version" },
      ],
    },
  ];

  const navSublink = (link) => {
    navigate(`/${link}`);
  };
  return (
    <>
      <HeadCont transparent="white" title={"설정"} />
      <section className="mt-14">
        {datas.map((data) => (
          <div key={data.id} className="p-4 border-b border-gray_40">
            <ul>
              {data.subMenu.map((menu) => (
                <li
                  key={menu.id}
                  className={
                    "flex justify-between rounded-md p-3 active:bg-gray_20"
                  }
                  onClick={() => navSublink(menu.sublink)}
                >
                  <h2 className="text-body3_700 text-gray_180">{menu.title}</h2>
                  <img
                    src="/images/mobile/icon/right.svg"
                    alt="더보기 아이콘"
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </section>
      <MenuBar />
    </>
  );
};

export default Setting;
