import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handlePageChange } from "../../../features/admin/commonSlice";
import {
  fetchEatStatistics,
  fetchEatRecord,
  fetchWeight,
  userInfoSimple,
  fetchETCDiaryRecord,
  fetchETCStatistics,
  fetchSymptomList,
  fetchSymptomInputDayList,
  fetchSymptomOneDay,
  coachReportWeekList,
  searchCoachReport,
  onSelectCoachWeekStart,
  onSelectCoachWeekEnd,
  fetchWeightRecord,
} from "../../../features/admin/managementSlice";
import { useLocation, useNavigate } from "react-router";

/*component*/
import Sidebar from "../components/common/Sidebar";
import Navbar from "../components/common/Navbar";
import ContentHeader from "../components/common/ContentHeader";
import ManagementSubTab from "../components/common/ManagementSubTab";
import EatGraphBox from "../components/management/EatGraphBox";
import EatDetailBox from "../components/management/EatDetailBox";
import WeightGraphBox from "../components/management/WeightGraphBox";
import WeightDetailBox from "../components/management/WeightDetailBox";
import WeeklySymptomBox from "../components/management/WeeklySymptomBox";
import DailySymptomBox from "../components/management/DailySymptomBox";
import OthersGraphBox from "../components/OthersGraphBox";
import OthersDetailBox from "../components/OthersDetailBox";
import Loading from "../components/common/Loading";
import ReportList from "../components/management/ReportList";
import UserSimple from "../components/management/UserSimple";
import { BGButtonCustom } from "../components/common/ButtonCustom";
import { setPageArr } from "../../../features/admin/paginationSlice";

const ManagementDetail = () => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const { status } = useSelector((state) => state.management);
  // 증상,일별증상기록보기 select된 날짜
  const daySelect = useSelector((state) => state.management.daySelect);
  const navigate = useNavigate();
  const memberobj = useSelector(
    (state) => state.management.mangementDetailState
  );
  const userId = memberobj.userId;
  const { pagePerList7 } = useSelector((state) => state.pagination);
  // 증상주석처리
  // const tabs = ["식사", "체중", "증상", "기타"];
  const tabs = [
    "식사",
    "체중",
    // "다이어리"
  ];
  const subTabs1 = ["통계 그래프 보기", "식사 기록"];
  const subTabs2 = ["통계 그래프 보기", "체중 기록 보기"];
  const subTabs3 = ["주간 증상 설문 보기", "일별 증상 기록 보기"];
  const subTabs4 = ["통계 그래프 보기", "다이어리 기록 보기"];
  const days = ["최근 7일", "최근 30일", "최근 90일"];
  // const sideTabs = ["주간 리포트 미리보기(가이드)"];
  const [tabNo, setTabNo] = useState("식사");
  const [subTabNo, setSubTabNo] = useState("통계 그래프 보기");
  const [dayNo, setDayNo] = useState("최근 7일");
  // const [sideTabNo, setSideTabNo] = useState("주간 리포트 미리보기(가이드)");
  //주간리포트 미리보기 날짜리스트
  // const coachWeekList = useSelector((state) => state.management.coachWeekList);
  // const { coachWeeklyReport } = useSelector((state) => state.management);
  const { selectCoachWeekStart } = useSelector((state) => state.management);
  const { selectCoachWeekEnd } = useSelector((state) => state.management);

  const onSetTab = useCallback(
    (tab) => {
      setDayNo("최근 7일");
      let param = { userId: userId };
      let param2 = Object.assign(param, {
        type: dayNo === "최근 7일" ? 1 : dayNo === "최근 30일" ? 2 : 3,
      });
      setTabNo(tab);
      if (tab === "증상") {
        dispatch(fetchSymptomList(param2));
        dispatch(fetchSymptomInputDayList(param));
        setSubTabNo("주간 증상 설문 보기");
      } else if (tab === "체중") {
        dispatch(fetchWeight(param2));
        setSubTabNo("통계 그래프 보기");
      } else if (tab === "다이어리") {
        dispatch(fetchETCStatistics(param2));
        setSubTabNo("통계 그래프 보기");
      } else {
        // dispatch(fetchETCStatistics(params));
        setSubTabNo("통계 그래프 보기");
      }
    },

    [setDayNo, userId, dayNo, dispatch, daySelect]
  );

  // const onSetWeekCoach = (e) => {
  //   e.preventDefault();
  //   const { value } = e.target;
  //   // console.log(value);
  //   const array = value.split(",");
  //   dispatch(onSelectCoachWeekStart(array[0]));
  //   dispatch(onSelectCoachWeekEnd(array[1]));
  //   let params = { userId: userId, startDate: array[0], endDate: array[1] };
  //   // setSelectedCoach({ startDate: array[0], endDate: array[1] });
  //   dispatch(searchCoachReport(params));
  // };
  const onSetSubTab = (tab) => {
    let params = { userId };
    if (tab === "식사 기록") {
      dispatch(fetchEatRecord(params));
    }
    setSubTabNo(tab);
  };

  useEffect(() => {
    if (loginInfo?.adminId) {
      let param = { userId: userId };
      let paramType = Object.assign(param, {
        type: dayNo === "최근 7일" ? 1 : dayNo === "최근 30일" ? 2 : 3,
      });
      let paramDay = Object.assign(param, { day: daySelect });
      let paramNoDate = Object.assign(param, { startDate: "", endDate: "" });
      let paramDate = Object.assign(param, {
        startDate: selectCoachWeekStart,
        endDate: selectCoachWeekEnd,
      });

      dispatch(fetchEatRecord(paramNoDate));
      dispatch(userInfoSimple(param));
      dispatch(coachReportWeekList(param));
      dispatch(searchCoachReport(paramDate));
      dispatch(onSelectCoachWeekStart(selectCoachWeekStart));
      dispatch(onSelectCoachWeekEnd(selectCoachWeekEnd));
      if (tabNo === "식사" && subTabNo === "통계 그래프 보기") {
        dispatch(fetchEatStatistics(paramType));
      } else if (tabNo === "체중") {
        if (subTabNo === "통계 그래프 보기") {
          dispatch(fetchWeight(paramType)); //통계그래프에 들어가는 리스트
        }
      } else if (tabNo === "증상") {
        dispatch(fetchSymptomList(paramType));
        dispatch(fetchSymptomInputDayList(param));
        dispatch(fetchSymptomOneDay(paramDay));
        dispatch(handlePageChange(1));
      } else if (tabNo === "다이어리") {
        if (subTabNo === "통계 그래프 보기") {
          dispatch(fetchETCStatistics(paramType));
        }
        // dispatch(fetchWeight(params)); //통계그래프에 들어가는 리스트
        // dispatch(fetchETCDiaryRecord(params));
        // dispatch(handlePageChange(1));
      } else if (!tabNo) {
        dispatch(fetchEatStatistics(paramType));
      }
    } else {
      navigate("/admin/signin");
    }
  }, [dispatch, dayNo, tabNo, subTabNo, userId]);

  const goManagement = () => {
    navigate("/admin/management");
  };

  const onClickChange = useCallback(
    async (pageNum, dayNo, tabName) => {
      dispatch(handlePageChange(pageNum));
      let params_search = {
        userId: userId,
        type: dayNo === "최근 7일" ? 1 : dayNo === "최근 30일" ? 2 : 3,
        page: pageNum,
        countPerPage: pagePerList7,
      };
      const fetchAPI =
        tabName === "체중"
          ? fetchWeightRecord
          : tabName === "식사"
          ? fetchEatStatistics
          : null;

      const res = await dispatch(fetchAPI(params_search)).unwrap();

      if (res.ok) {
        const defaultarr = Array(res.totalPage)
          .fill()
          ?.map((v, i) => {
            return i + 1;
          });
        if (pagePerList7 >= pageNum) {
          // pageNum보다 작으면 초기화
          dispatch(setPageArr(defaultarr.slice(0, pagePerList7)));
        } else {
          // pageNum보다 클때 ex) pagePerList7 3이고 pageNum이 4이면 4,5,6
          dispatch(
            setPageArr(
              defaultarr.slice(
                (Math.ceil(pageNum / pagePerList7) - 1) * pagePerList7,
                Math.ceil(pageNum / pagePerList7) * pagePerList7
              )
            )
          );
        }
      }
    },
    [dispatch, pagePerList7, setPageArr]
  );

  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        {status === "success" ? (
          <div className="flex flex-col gap-4 p-6">
            <div className="flex justify-between">
              <ContentHeader title={"회원 상세 정보"} />
              <BGButtonCustom
                onClick={goManagement}
                content={"회원목록으로 돌아가기"}
                bgcolor={"bg-orange_100"}
                textcolor={"text-gray_10"}
                textsize={"text-caption1_600"}
                icon={"/images/admin/icon/arrow_prev.svg"}
                xpadding={"12px"}
              />
            </div>
            <UserSimple />
            <div className="grid grid-cols-4 gap-4">
              {/* 주간리포트분할 */}
              {/* <div className="col-span-4 xl:col-span-3"> */}
              <div className="col-span-4 xl:col-span-4">
                <ul className="flex">
                  {tabs?.map((tab, idx) => (
                    <li
                      key={idx}
                      onClick={() => onSetTab(tab)}
                      className={
                        "text-body_2 w-20 cursor-pointer rounded-t-3xl py-4 text-center font-semibold " +
                        (tabNo === tab
                          ? "bg-gray_10  text-black"
                          : "bg-gray_60  text-gray_100")
                      }
                    >
                      {tab}
                    </li>
                  ))}
                </ul>
                <div className="rounded-b-3xl rounded-tr-3xl bg-gray_10 p-4 ">
                  <ManagementSubTab
                    tabNo={tabNo}
                    subTabs={
                      tabNo === "식사"
                        ? subTabs1
                        : tabNo === "체중"
                        ? subTabs2
                        : tabNo === "증상"
                        ? subTabs3
                        : tabNo === "다이어리"
                        ? subTabs4
                        : null
                    }
                    onSetSubTab={onSetSubTab}
                    subTabNo={subTabNo}
                    days={days}
                    setDayNo={setDayNo}
                    dayNo={dayNo}
                    onClickChange={onClickChange}
                    // onClickChangeDiary={onClickChangeDiary}
                  />
                  {/* 메인 컨텐츠 */}
                  <div className="space-y-12 p-4 pt-8">
                    {tabNo === "식사" ? (
                      subTabNo === "통계 그래프 보기" ? (
                        <EatGraphBox dayNo={dayNo} />
                      ) : (
                        <EatDetailBox />
                      )
                    ) : tabNo === "체중" ? (
                      subTabNo === "통계 그래프 보기" ? (
                        <WeightGraphBox dayNo={dayNo} />
                      ) : (
                        <WeightDetailBox
                          onClickChange={onClickChange}
                          dayNo={dayNo}
                          tabName={tabNo}
                        />
                      )
                    ) : tabNo === "증상" ? (
                      subTabNo === "주간 증상 설문 보기" ? (
                        <WeeklySymptomBox dayNo={dayNo} />
                      ) : (
                        <DailySymptomBox dayNo={dayNo} />
                      )
                    ) : tabNo === "다이어리" ? (
                      subTabNo === "통계 그래프 보기" ? ( //다이어리
                        <OthersGraphBox dayNo={dayNo} />
                      ) : (
                        <OthersDetailBox
                          onClickChange={onClickChange}
                          dayNo={dayNo}
                          tabName={tabNo}
                        />
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              {/* 주간리포트미리보기 */}
              {/* <div className="col-span-4 xl:col-span-1">
                    <ul className="flex">
                      {sideTabs?.map((tab, idx) => (
                        <li
                          key={idx}
                          onClick={() => {
                            setSideTabNo(tab);
                          }}
                          className={
                            "xl:last:mr-6 xl:w-3/4 w-1/5 text-center 2xl:text-base xl:text-sm rounded-t-3xl py-4 text-body_2 font-semibold cursor-pointer " +
                            (sideTabNo === tab
                              ? "bg-gray_100  text-gray_10"
                              : "bg-gray_90  text-gray_40")
                          }
                        >
                          {tab}
                        </li>
                      ))}
                    </ul>
                    <div className="bg-gray_100 rounded-b-3xl rounded-tr-3xl p-[12px]">
                      <CommonOption ver={4}
                        lists={coachWeekList}
                        selectid={"coachWeekList"}onChangeFunction={onSetWeekCoach}
                        weekstart={selectCoachWeekStart} weekend={selectCoachWeekEnd}
                      />
                      <ul className="px-4 border-t ">
                        {coachWeeklyReport ===undefined
                        ? <li className="pt-4">기록이 없습니다.</li>
                        :
                        <>
                          <ReportList title={"종합가이드"} cont1 = {coachWeeklyReport.comprehensiveEvaluation}
                          cont2 = {coachWeeklyReport.comprehensiveEvaluationEat}
                          cont3={coachWeeklyReport.comprehensiveEvaluationEatComment}
                          />
                          <ReportList title={"식사"} cont1 = {coachWeeklyReport.eatReport}
                          cont2 ={coachWeeklyReport.eatIntakeComment}
                          cont3 ={coachWeeklyReport.eatOperationComment}
                          />
                          <ReportList title={"체중"} cont1 = {coachWeeklyReport.weightReport}
                          cont2={coachWeeklyReport.weightComment}
                          />
                          <ReportList title={"증상"} cont1 = {coachWeeklyReport.symptomReport}
                          cont2 = {coachWeeklyReport.symptomComment}
                          />
                        </>
                        }
                        <li className="pb-4"></li>
                      </ul>
                    </div>
                  </div> */}
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default ManagementDetail;
