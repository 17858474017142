import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";

const InputSelectBox = ({
  triangle,
  search,
  label,
  nameTitle,
  value,
  setValue,
  datas,
  placeholder,
  removeSpace,
  onChangeAction,
  onSaveAnswer,
  onSaveAnswerAndMakeList,
  prevMedicineDrugs,
  onSearch,
  setResetPlaceHolder,
}) => {
  const inputRef = useRef(null);
  const [tempHeight, setTempHeight] = useState(0);
  const boxRef = useRef(null);
  const { gettingAnswers } = useSelector((state) => state.questionHealth);
  const [openBox, setOpenBox] = useState(false);

  const onDeleteValue = () => {
    setValue("");
    setResetPlaceHolder && setResetPlaceHolder(true);
    setOpenBox(false);
    inputRef.current?.focus();
    if (nameTitle === "cancerType" && gettingAnswers?.cancerType) {
      //기타암 기타입력 기 입력건 있을떄 데이터 삭제
      if (!gettingAnswers?.cancerType?.includes("CT")) {
        onSaveAnswer("cancerType", null);
      }
    }
  };

  const closeLists = (nameTitle) => {
    //복용 약
    if (nameTitle === "meddicineDrugs") {
      setOpenBox(!openBox);
      inputRef.current?.focus();
    }
  };

  const onAction = (e) => {
    if (onChangeAction) {
      onChangeAction(e);
    } else {
      setValue(e.target.value);
    }
    /// state change while onAction is running will not affect datas
    /// resulting in datas from previous state to be used.
    setOpenBox(true);
  };
  const onSetValue = (title, code, name) => {
    setValue(name);
    setResetPlaceHolder && setResetPlaceHolder(false);
    setOpenBox(false);

    if (title === "hospitalName") {
      onSaveAnswer(title, name);
    } else if (title === "medicineDrugs") {
      onSaveAnswerAndMakeList(title, name, prevMedicineDrugs);
      setValue("");
    } else if (title === "cancerType") {
      return false; //직접입력의 경우 코드 안보내고, value 그대로 보냄
    } else {
      onSaveAnswer(title, code);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (boxRef.current && !boxRef.current.contains(e.target)) {
        setOpenBox(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [boxRef]);
  return (
    <div>
      <div>
        {label && (
          <label
            htmlFor={nameTitle}
            className="text-caption1_600 text-gray_160"
          >
            {label}
          </label>
        )}
        <div className="relative">
          <input
            type={"text"}
            id={nameTitle}
            name={nameTitle}
            placeholder={placeholder}
            onFocus={(e) => {
              // setOpenBox(true);
              if (
                window.innerHeight / 2 - 130 <= 300 &&
                !removeSpace &&
                e.target.value !== "" // 검색목록 ui 출력 안될때도 모름버튼 아래로 이동되는 문제 방지
              ) {
                setTempHeight(300); // 300 is completely arbitrary
              }
              let absolutePostion =
                inputRef.current?.getBoundingClientRect().top - (56 + 24);
              $("body, html").animate(
                {
                  scrollTop: window.scrollY + absolutePostion,
                },
                300
              );
            }}
            onBlur={() => {
              setTempHeight(0);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setOpenBox(true);
                if (search) {
                  onSearch(value);
                  e.target.blur();
                }
              }
            }}
            onChange={onAction}
            value={value || ""}
            ref={inputRef}
            className="w-full h-16 py-4 pl-4 pr-12 text-black border rounded appearance-none border-gray_80 text-body2_500l focus:border-gray_160 focus:outline-none focus:ring-0"
          />
          {triangle ? (
            <img
              onClick={() => closeLists("meddicineDrugs")}
              src="/images/mobile/icon/reverse_tri.svg"
              alt="닫기 아이콘"
              className="absolute right-4 top-5"
            />
          ) : search ? (
            <img
              onClick={() => {
                console.log(value);
                onSearch(value);
              }}
              src="/images/admin/icon/search.svg"
              alt="Search Icon"
              className="absolute right-4 top-5"
            />
          ) : (
            <img
              onClick={onDeleteValue}
              src="/images/mobile/icon/input_close.svg"
              alt="닫기 아이콘"
              className="absolute right-4 top-5"
            />
          )}
        </div>
      </div>
      {datas && datas?.length > 0 ? (
        <ul
          className={
            "absolute z-[10] h-min max-h-[196px] w-[calc(100%-48px)] flex-col overflow-y-scroll border border-gray_80 bg-gray_10 " +
            (datas
              ? openBox
                ? "h-full rounded-md border opacity-100"
                : "hidden"
              : "hidden")
          }
          ref={boxRef}
        >
          {datas.map((data, idx) => (
            <li
              onClick={() => onSetValue(nameTitle, data.dCode, data.name)}
              key={idx}
              className="flex overflow-x-clip break-all px-[12px] py-[11px] text-body2_500l text-black active:bg-orange_10"
            >
              {data.name}
            </li>
          ))}
        </ul>
      ) : null}
      {/* {nameTitle === "cancerType" && <div className="h-[150px]"></div>} */}
      <div style={{ height: `${tempHeight}px` }} />
    </div>
  );
};

export default InputSelectBox;
