import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMyinfo,
  fetchMyActivity,
  checkQuestionsComplete,
  checkOpenTutorial,
} from "../../../../features/mobile/otherSlice";
import LevelCard from "../../components/setting/LevelCard";
import TitleBox from "../../components/common/TitleBox";
import MenuBar from "../../components/common/MenuBar";
import { ReactComponent as More } from "../../../../svg/arrow_right_com.svg";
import IdCard from "../../components/mypage/IdCard";
import dayjs from "dayjs";

import { fetchSurveyResult } from "../../../../features/mobile/questionHealthSlice";

import { onResetAll as onResetPostsComm } from "../../../../features/mobile/commSlice";
import { onResetAll as onResetPostsInfo } from "../../../../features/mobile/infoSlice";
import {
  onResetAll as onResetComment,
  changeNavTab,
} from "../../../../features/mobile/commonSlice";

const MyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const myInfo = useSelector((state) => state.others.userInfo?.user);
  const { resultLists } = useSelector((state) => state.questionHealth);
  const [openStates, setOpenStates] = useState({ 0: false });

  const datasNew = [
    {
      id: 1,
      title: "내 정보",
      content: [{ title: "내 프로필", link: `/account/profile`, type: 0 }],
    },
    {
      id: 2,
      title: "건강관리",
      content: [
        { title: "건강 정보", link: `/healthinfo`, type: 1 },
        { title: "추천 식단", link: `/recommend`, type: 2 },
        {
          title: "식사 분석",
          link: `/meal/analysis/${dayjs().format("YYYY-MM-DD")}`,
          type: 3,
        },
        { title: "레시피 찾기", link: `/recommend/findrecipe`, type: 4 },
      ],
    },
    {
      id: 3,
      title: "생활",
      content: [
        { title: "정보", link: `/info`, type: 1 },
        { title: "병원 정보 찾기", link: `/hospitalinfo`, type: 2 },
      ],
    },
    {
      id: 4,
      title: "내 활동",
      content: [
        { title: "나의 댓글", link: `/mypage/comments`, type: -1 },
        { title: "북마크", link: `/mypage/bookmark`, type: -1 },
      ],
    },
    {
      id: 5,
      title: "나의 음식",
      content: [
        { title: "좋아요한 음식", link: `/like`, type: 3 },
        { title: "싫어요한 음식", link: `/dislike`, type: 4 },
        { title: "즐겨찾는 레시피", link: `/bookmark/recipe`, type: 5 },
      ],
    },
    {
      id: 6,
      title: "고객센터",
      content: [
        { title: "공지 사항", link: `/notice`, type: 6 },
        { title: "이용문의 및 FAQ", link: `/contact`, type: 7 },
        { title: "가이드 다시보기", link: `/home`, type: 10 },
        { title: "설정", link: `/setting`, type: 9 },
      ],
    },
  ];

  const onOpenToDetail = (link, type) => {
    if (type < 1) {
      if (type === -1) {
        dispatch(onResetPostsInfo());
        dispatch(onResetComment());
        dispatch(changeNavTab(true));
      }
      navigate(`${link}`);
    } else if (type === 10) {
      dispatch(checkOpenTutorial(loginInfo.userId));
      navigate(`${link}`);
    } else {
      let params = { userId: loginInfo.userId, type };
      dispatch(fetchMyActivity(params));
      navigate(`${link}`);
    }
  };
  const dataFn = (title, link, type) => {
    return (
      <div
        key={type === -1 ? Math.random() : type}
        onClick={() => onOpenToDetail(link, type)}
        className="flex justify-between p-3 rounded-lg hover:bg-gray_20 active:bg-gray_20"
      >
        <h2 className="text-body3_700 text-gray_180 ">{title}</h2>
        <More fill="#C1C1C1" className="w-6 h-6" />
      </div>
    );
  };

  // console.log(resultLists);
  useEffect(() => {
    dispatch(fetchMyinfo(loginInfo.userId));
    dispatch(fetchSurveyResult(loginInfo.userId));
  }, [dispatch, loginInfo.userId]);

  return (
    <>
      <section className="pb-20 mb-6 ">
        <IdCard myInfo={myInfo} loginInfo={loginInfo} />
        <div className="flex flex-col gap-6 px-6 pb-6 border-b border-gray_40">
          <LevelCard activity={myInfo?.activity} rating={myInfo?.rating} />
        </div>

        {datasNew.map((value, idx) => (
          <div key={idx} className="p-4 space-y-2 border-b border-gray_40">
            <TitleBox
              title={value.title}
              font={"text-caption1_600"}
              textColor={"text-gray_160"}
            />
            {value.content.map((data) =>
              dataFn(data.title, data.link, data.type)
            )}
          </div>
        ))}
      </section>
      <MenuBar />
    </>
  );
};

export default MyPage;
