import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../common/api";

export const fetchCommPostings = createAsyncThunk(
  "comm/fetchCommPostings",
  async (params) => {
    const res = await api.post(`/posting/getPagePostings`, params);
    return res.data;
  }
);

export const fetchCommPostingDetails = createAsyncThunk(
  "comm/fetchCommPostingDetails",
  async (params) => {
    const res = await api.post("/posting/getAllRepliesOfOnePosting", params);
    return res.data;
  }
);

export const addPosting = createAsyncThunk(
  "comm/addPosting",
  async (params) => {
    const config = { header: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("writerUserId", params.writerUserId);
    formdata.append("title", params.title);
    formdata.append("content", params.content);
    params.files.forEach((file) => formdata.append("files", file));
    if (params.keyword) formdata.append("keyword", params.keyword);
    const res = await api.post(`/posting/addPosting`, formdata, config);
    return res.data;
  }
);

export const editPosting = createAsyncThunk(
  "comm/editPosting",
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("id", params.id);
    formdata.append("title", params.title);
    formdata.append("content", params.content);
    formdata.append("writerUserId", params.writerUserId);
    if (params.keyword) formdata.append("keyword", params.keyword);
    if (params.files !== null && params.files !== undefined) {
      params.files.forEach((file) => formdata.append("files", file)); // new added files
    }
    if (params.remainingFiles !== null && params.remainingFiles !== undefined) {
      params.remainingFiles.forEach((file) =>
        formdata.append("remainingFiles", file)
      ); //previous saved files
    }
    const res = await api.post(`/posting/editPosting`, formdata, config);
    return res.data;
  }
);

export const deletePosting = createAsyncThunk(
  "comm/deletePosting", // Deletes post or posts
  async (params) => {
    const res = await api.post("/posting/delete", params);
    return res.data;
  }
);

export const getNoticeTop = createAsyncThunk(
  "comm/createNoticeTop",
  async (params) => {
    const res = await api.post("/posting/getNoticeTop", params);
    return res.data;
  }
);

export const getBanners = createAsyncThunk(
  "comm/getBanners",
  async (params) => {
    const res = await api.post("/banner/getBanner", params);
    return res.data;
  }
);

export const bannerShowCount = createAsyncThunk(
  "comm/bannerShowCount",
  async (params) => {
    const res = await api.post(`/banner/addBannerShow`, params);
    return res.data;
  }
);

export const bannerClickCount = createAsyncThunk(
  "comm/bannerClickCount",
  async (params) => {
    const res = await api.post(`/banner/addBannerClick`, params);
    return res.data;
  }
);

const initialState = {
  postingDetailsStatus: null,
  existNextPosting: null,
  postingDetails: null,
  totalReplies: null,
  totalPosts: null,
  prevTabTitle: null,
  searchedValue: null,
  prevPostList: [],
  prevLikedList: [], // {id, isLiked, likeCount, replyCount}: dictionary, saved locally
  prevListView: true,

  // myPage
  writerUserId: null, // same value loginInfo.userId (may change in the future)
  isLike: null,
  rangeMonth: null, // null/unknown === 전체

  // search (포스팅 태그 클릭한 경우 true, 유저가 직접 검색어 입력한 경우 false)
  isKeyword: false,
};

const commSlice = createSlice({
  name: "커뮤니티",
  initialState,
  reducers: {
    onResetAll: (state) => {
      state.prevLikedList = [];
      state.prevPostList = [];
      state.searchedValue = null;
      state.totalPosts = null;

      // myPage
      state.writerUserId = null;
      state.isLike = null;
      state.rangeMonth = null;

      // search
      state.isKeyword = false;
    },
    onSaveCommunityPage: (state, action) => {
      // save all the contents of current page to redux
      state.prevPostList = action.payload.postList;
      state.prevTabTitle = action.payload.tabTitle;
      state.prevLikedList = action.payload.likedList;
    },
    onResetCommunityPage: (state) => {
      state.prevPostList = [];
      state.prevTabTitle = null;
      state.prevLikedList = [];
    },
    onSetPrevPostList: (state, action) => {
      state.prevPostList = action.payload;
    },
    onResetPostingDetails: (state) => {
      state.postingDetails = null;
    },
    onSetPrevLikedList: (state, action) => {
      state.prevLikedList = action.payload;
    },
    onResetPrevLikedLists: (state) => {
      state.prevLikedList = [];
    },
    onSetSearchedValue: (state, action) => {
      state.searchedValue = action.payload;
    },
    onSetTotalPosts: (state, action) => {
      state.totalPosts = action.payload;
    },
    onSetExistNextPosting: (state, action) => {
      state.existNextPosting = action.payload;
    },
    onListView: (state, action) => {
      state.prevListView = action.payload;
    },

    // myPage
    onSetWriterUserId: (state, action) => {
      state.writerUserId = action.payload;
    },
    onSetIsLike: (state, action) => {
      state.isLike = action.payload;
    },
    onSetRangeMonth: (state, action) => {
      state.rangeMonth = action.payload;
    },

    //search
    onSetIsKeyword: (state, action) => {
      state.isKeyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCommPostingDetails.pending, (state) => {
      state.postingDetailsStatus = false;
    });
    builder.addCase(fetchCommPostingDetails.fulfilled, (state, action) => {
      state.postingDetailsStatus = true;
      state.postingDetails = action.payload;
      state.totalReplies = action.payload.totalReplies;
    });
  },

  // {
  //   [fetchCommPostingDetails.pending]: (state) => {
  //     state.postingDetailsStatus = false;
  //   },
  //   [fetchCommPostingDetails.fulfilled]: (state, action) => {
  //     state.postingDetailsStatus = true;
  //     state.postingDetails = action.payload;
  //     state.totalReplies = action.payload.totalReplies;
  //   },
  // },
});

export const {
  onSetPrevPostList,
  onSetPrevLikedList,
  onResetPrevLikedLists,
  onResetAll,
  onSetSearchedValue,
  onSetTotalPosts,
  onResetPostingDetails,
  onSaveCommunityPage,
  onResetCommunityPage,
  onSetExistNextPosting,
  onListView,
  onSetWriterUserId,
  onSetIsLike,
  onSetRangeMonth,
  onSetIsKeyword,
} = commSlice.actions;
export default commSlice.reducer;
