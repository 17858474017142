import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkQuestionsComplete } from "../../../features/mobile/otherSlice";
import { onResetAll } from "../../../features/mobile/infoSlice";
import MenuBar from "../components/common/MenuBar";
import TagManager from "react-gtm-module";
import { mobileTypeFn } from "../../mobile/components/logic";
import { checkMinVersion } from "../../mobile/components/logic";
import ProgressBar from "../components/meal/ProgressBar";
import KcalGraph from "../components/common/KcalGraph";
import Btn from "../components/common/Btn";
import HealthInfoComponent from "./question/HealthInfoComponent";
import dayjs from "dayjs";
import BarChart from "../components/common/BarChart";
import { useSwipeable } from "react-swipeable";
import { CalorieSwipe, ProteinSwipe, WeightSwipe } from "./SwipePage";
import Tutorial from "./Tutorial";
import CalendarHeader from "../components/common/CalendarHeader";
import {
  fetchMain,
  fetchMainPoints,
  onSaveTodayStep,
} from "../../../features/mobile/mainSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  fetchDrugSchedule,
  scheduleCheck,
} from "../../../features/mobile/drugSlice";
import Dialog from "../components/common/Dialog";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { loginInfo, tutorialEnabled, chosenDate } = useSelector(
    (state) => state.commonApp
  );
  const { questionsCompleteCheck } = useSelector((state) => state.others);
  // console.log(questionsCompleteCheck, "questionsCompleteCheck");
  const { prevPostList } = useSelector((state) => state.info);
  const healthInfoRef = useRef(null);

  // const [step, setStep] = useState(0);

  const [startDate, setStartDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const {
    todayStep,
    steps,
    weights,
    weight,
    symptoms,
    medicines,
    treatments,
    eat,
    existMainPointOfYear,
  } = useSelector((state) => state.mainData);
  const { dayDrugLists } = useSelector((state) => state.drug);

  const now = new Date();
  const month = now.toLocaleString("ko-KR", { month: "long" });

  const [tutorialState, setTutorialState] = useState(false);

  const [healthResult, setHealthResult] = useState(
    location.state?.healthResult
  );
  const [isLoading, setIsLoading] = useState(true); // kcal 그래프 상태
  const [openDialog, setOpenDialog] = useState(false); // 걸음수 팝업 상태

  const [checkStep, setCheckStep] = useState({ step: false, type: "" });
  // const [checkAosStep, setCheckAosStep] = useState(false);
  const [checkNoti, setCheckNoti] = useState(false);

  // 스와이프 현재 인덱스
  const [currentIndex, setCurrentIndex] = useState(0);
  const healthTotalScreens = 3;
  // 스와이프 화면 배열
  const screens = [
    <WeightSwipe
      currentIndex={currentIndex}
      totalScreens={healthTotalScreens}
      weight={weight}
      weights={weights}
    />,
    <CalorieSwipe
      currentIndex={currentIndex}
      totalScreens={healthTotalScreens}
      kcals={eat?.kcals}
      recommendedCalories={eat?.recommendedCalories}
    />,
    <ProteinSwipe
      currentIndex={currentIndex}
      totalScreens={healthTotalScreens}
      proteinGs={eat?.proteinGs}
      recommendedProteinGs={eat?.recommendedProteinGs}
    />,
  ];

  // 스와이프 핸들 로직
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // 마지막 스크린에서 오른쪽으로 스와이프하면 첫 번째 스크린으로
      const newIndex =
        currentIndex === healthTotalScreens - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    },
    onSwipedRight: () => {
      // 첫 번째 스크린에서 왼쪽으로 스와이프하면 마지막 스크린으로
      const newIndex =
        currentIndex === 0 ? healthTotalScreens - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    },
  });

  // 완료 및 알람 해제된 복약알람 필터링
  const filterDrugLists = dayDrugLists.filter(
    (arr) => arr.check === false && arr.alarm === true
  );

  useEffect(() => {
    setIsLoading(true); // 데이터 요청 전 로딩 상태로 설정
    // dispatch(
    //   fetchMain({
    //     userId: loginInfo.userId,
    //     day: chosenDate || startDate,
    //   })
    // );
    // dispatch(
    //   fetchDrugSchedule({
    //     userId: loginInfo.userId,
    //     date: chosenDate || startDate,
    //   })
    // );
    // Promise - 병렬 처리 작업 완료 대기 / 애니메이션 정상 작동하게 수정
    async function fetchData() {
      await Promise.all([
        dispatch(
          fetchMain({
            userId: loginInfo.userId,
            day: chosenDate || startDate,
          })
        ),
        dispatch(
          fetchDrugSchedule({
            userId: loginInfo.userId,
            date: chosenDate || startDate,
          })
        ),
      ])
        .then(() => {
          setIsLoading(false); // 모든 데이터가 로딩된 후 로딩 상태 해제
        })
        .catch((error) => {
          console.error("메인 데이터 or 복약스케줄 불러오기 실패:", error);
        });
    }
    fetchData();
  }, [chosenDate]);

  useEffect(() => {
    window.nowStep = (params) => {
      const object = JSON.parse(params);
      const value = object.value;
      dispatch(onSaveTodayStep(value));
    };

    if (location.state?.healthResult) {
      window.scrollTo({
        top: healthInfoRef?.current?.offsetTop - 20,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(questionsCompleteCheck)?.length === 0) {
      //설문여부 체크
      dispatch(checkQuestionsComplete(loginInfo.userId));
    }

    // eslint-disable-next-line
  }, [dispatch, loginInfo.userId]);

  useEffect(() => {
    dispatch(
      fetchMainPoints({
        userId: loginInfo.userId,
        year: dayjs(chosenDate || startDate).format("YYYY"),
      })
    );
  }, [chosenDate, dayjs(startDate).format("YYYY")]);

  let firebaseToken = window.sessionStorage.getItem("token");

  useEffect(() => {
    const tagManagerArgs = {
      //태그 매니저
      gtmId: "GTM-N3XGGSS",
      dataLayer: {
        event: "login_token",
        value: firebaseToken || loginInfo.snsId || null,
      },
      dataLayerName: "dataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);
    TagManager.dataLayer({
      gtmId: "GTM-MHZ2ZXDV",
      dataLayer: {
        event: "login_token",
        value: firebaseToken || loginInfo.snsId || null,
      },
      dataLayerName: "dataLayer",
    });

    // console.log(questionsCompleteCheck);
    if (!loginInfo?.userId) navigate(`/sign`);
    else {
      if (prevPostList?.length > 0) dispatch(onResetAll());
      console.log(questionsCompleteCheck, "questionsCompleteCheck");
      if (
        (questionsCompleteCheck &&
          Object.keys(questionsCompleteCheck)?.length === 0) ||
        (questionsCompleteCheck && questionsCompleteCheck?.openTutorial3) ||
        (questionsCompleteCheck && !questionsCompleteCheck?.eatRecordComplete)
      ) {
        // Check whether to show survey or not
        // showSurvey();
        //설문여부 체크 안했을때 여부 확인(체크했는데 튜토리얼 다녀왔을때와 식사기록 했을 경우 대비하여 다시 부르기)
        dispatch(checkQuestionsComplete(loginInfo?.userId))
          .unwrap()
          .then((res) => {
            if (res.ok) {
              // 홈에서 유저고유키값 태그매니저 보내주기
              const gaKey = res.gaKey;
              window.dataLayer.push({
                event: "login",
                user_id: gaKey,
              });
              if (res.openTutorial3) {
                //튜토리얼 회원가입 후 3일간 보여주기

                if (tutorialEnabled) {
                  setTutorialState(true);
                }
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        if (questionsCompleteCheck && questionsCompleteCheck?.openTutorial3) {
          //튜토리얼 회원가입 후 3일간 보여주기
          if (tutorialEnabled) {
            setTutorialState(true);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, loginInfo.userId, navigate, tutorialState, setTutorialState]);

  useEffect(() => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler("setUserId", loginInfo.userId);
    }

    if (mobileTypeFn() === "iphone") {
      window.flutter_inappwebview
        ?.callHandler("getIosVersion")
        .then(function (result) {
          // alert(result);
          window.flutter_inappwebview.callHandler("mainLoaded");
          const needUpdate = checkMinVersion(result, "2.0.21");
          if (!needUpdate) {
            window.flutter_inappwebview
              ?.callHandler("isAuthStep")
              .then((res) => {
                if (res) {
                  setCheckStep({ ...checkStep, step: true, type: "ios" });
                } else {
                  setCheckStep({ ...checkStep, step: false, type: "ios" });
                }
              });
          }
        });
    }

    if (mobileTypeFn() === "android") {
      window.flutter_inappwebview
        ?.callHandler("getAndroidVersion")
        .then(function (result) {
          // alert(result);
          window.flutter_inappwebview?.callHandler("mainLoaded");
          const needUpdate = checkMinVersion(result, "2.0.21");
          if (!needUpdate) {
            // alert("14이상");
            window.flutter_inappwebview
              ?.callHandler("isAuthStep")
              .then((res) => {
                if (res) {
                  setCheckStep({ ...checkStep, step: true, type: "aos" });
                } else {
                  setCheckStep({ ...checkStep, step: false, type: "aos" });
                }
              });
            window.flutter_inappwebview
              ?.callHandler("isNotification")
              .then((res) => {
                if (res) {
                  setCheckNoti(true);
                } else {
                  setCheckNoti(false);
                }
              });
          }
        });
    }
  }, []);

  window?.flutter_inappwebview?.callHandler("getIosVersion").then((res) => {
    if (Number(res.split(".")[2]) >= 21) {
      window.flutterResumeEvent = () => {
        if (mobileTypeFn() === "iphone") {
          window.flutter_inappwebview?.callHandler("isAuthStep").then((res) => {
            if (res) {
              setCheckStep({ ...checkStep, step: true, type: "ios" });
            } else {
              setCheckStep({ ...checkStep, step: false, type: "ios" });
            }
          });
        }

        if (mobileTypeFn() === "android") {
          window.flutter_inappwebview?.callHandler("isAuthStep").then((res) => {
            if (res) {
              setCheckStep({ ...checkStep, step: true, type: "aos" });
            } else {
              setCheckStep({ ...checkStep, step: false, type: "aos" });
            }
          });
          window.flutter_inappwebview
            ?.callHandler("isNotification")
            .then((res) => {
              if (res) {
                setCheckNoti(true);
              } else {
                setCheckNoti(false);
              }
            });
        }
        return "되라!!!!!!!!!!!!";
      };
    }
  });

  // 앱 설정 이동
  const moveSetting = () => {
    if (window?.flutter_inappwebview) {
      if (checkStep.type === "aos" && checkNoti === false) {
        window?.flutter_inappwebview?.callHandler("setNotification");
      } else if (checkStep.type === "aos" && checkStep.step === false) {
        window?.flutter_inappwebview?.callHandler("appSetting");
      } else if (checkStep.type === "ios" && checkStep.step === false) {
        window?.flutter_inappwebview?.callHandler("appSettingIOS");
      }
    }
    setOpenDialog(false);
  };

  // 시간:분 스트링 변환후 시간으로 포맷 - 복약알림
  const timeIn12HourFormat = (time) => {
    let [hour, minute] = time.split(":").map(Number);
    const ampm = hour >= 12 ? "오후" : "오전";
    hour = hour % 12 || 12; // 0시는 12시(AM)으로, 12시 이상은 PM으로 표시
    return `${ampm} ${hour}시 ${minute.toString().padStart(2, "0")}분 `;
  };

  return (
    <>
      <CalendarHeader
        type={"기록완료"}
        startDate={startDate}
        setStartDate={setStartDate}
        chosenDate={chosenDate === null ? startDate : chosenDate}
        // setChosenDate={setChosenDate}
        existDaysOfYear={[...existMainPointOfYear]}
      />

      <div className="-mt-7 h-auto w-full rounded-tl-[28px] bg-back_ground p-[10px] pb-[120px] ">
        {questionsCompleteCheck?.openTutorial3 !== true ? (
          <>
            <div className="mb-4 flex w-full flex-col gap-[10px] p-[10px]">
              <div className="text-xl font-bold text-black">식사기록</div>

              <ProgressBar
                title={"열량 목표"}
                data={eat?.recommendedCalorie}
                data2={eat?.kcal}
                unit={"kcal"}
                // progress={50}
                bgColor={"bg-[#C9DBFF]"}
                innerColor={"bg-[#8744E1]"}
                textColor={"text-white"}
              />
              <ProgressBar
                title={"단백질 목표"}
                data={eat?.recommendedProteinG}
                data2={eat?.proteinG}
                unit={"g"}
                // progress={50}
                bgColor={"bg-[#ABE7FA]"}
                innerColor={"bg-[#51CCF3]"}
                textColor={"text-white"}
              />

              <div className="flex w-full flex-col items-start gap-[10px]">
                <div className="w-full">
                  <span className="text-center font-semibold tracking-tight text-gray_160">
                    식사별 섭취 칼로리
                  </span>
                  <span className="ml-2 text-sm font-medium tracking-tight text-[#7A7A7A]">
                    (단위 kcal)
                  </span>
                </div>
                <div className="flex w-full items-center gap-[10px] py-[10px]">
                  <KcalGraph
                    isLoading={isLoading}
                    datas={{
                      bkcal: eat?.bkcal || 0,
                      bskcal: eat?.bskcal || 0,
                      lkcal: eat?.lkcal || 0,
                      lskcal: eat?.lskcal || 0,
                      dkcal: eat?.dkcal || 0,
                      dskcal: eat?.dskcal || 0,
                    }}
                  />
                </div>
              </div>
              <div className="mx-2">
                <Btn
                  id={"mealRecord"}
                  bgColor={"bg-main"}
                  textColor={"text-white font-bold"}
                  borderColor={"border border-main"}
                  title={"식사기록하기"}
                  active={"on"}
                  onAction={() => {
                    if (questionsCompleteCheck?.healthComplete) {
                      navigate("/meal/record");
                    } else {
                      navigate("/question/health");
                    }
                  }}
                />
              </div>
            </div>
            {questionsCompleteCheck?.healthComplete ? (
              <div ref={healthInfoRef}>
                <HealthInfoComponent
                  healthResult={healthResult}
                  setHealthResult={setHealthResult}
                />
              </div>
            ) : null}

            <div className="mt-4 grid grid-cols-2 items-start gap-2.5 pb-5 pt-4">
              {/* 체중, 열량, 단백질 */}

              <div {...handlers} className="w-full overflow-hidden">
                <div
                  className="flex w-full transition-transform duration-300"
                  style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                  {screens.map((Screen, index) => (
                    <div key={index} className="w-full flex-shrink-0">
                      {Screen}
                    </div>
                  ))}
                </div>
              </div>

              {/* 증상 */}
              <div
                className="h-full rounded-xl bg-white px-4 py-2.5"
                onClick={() => {
                  if (questionsCompleteCheck?.healthComplete) {
                    navigate("/symptom/record");
                  } else {
                    navigate("/question/health");
                  }
                }}
              >
                <div className="flex w-full justify-between">
                  <div className="text-xl font-bold text-black ">증상</div>
                  <div className="text-[15px] font-semibold text-neutral-400">
                    {symptoms?.length === 0 ? null : symptoms?.length + "개"}
                  </div>
                </div>

                {symptoms?.length > 0 ? (
                  <div className="flex w-full flex-row flex-wrap gap-2.5 pt-4">
                    {symptoms.map((arr, index) => {
                      return index <= 3 ? (
                        <div
                          key={arr}
                          className="items-center justify-center rounded-lg bg-indigo-400 4sm:p-[5px] 3sm:p-[10px] 2sm:p-[8px]"
                        >
                          <p className="font-['NanumSquare Neo'] flex text-xs font-bold text-white">
                            {arr}
                          </p>
                        </div>
                      ) : index === 4 ? (
                        <div
                          key={arr}
                          className="flex items-center justify-center gap-2.5 rounded-lg bg-indigo-400 p-2.5"
                        >
                          <div className="font-['NanumSquare Neo'] flex text-xs font-bold text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="5"
                              viewBox="0 0 20 5"
                              fill="none"
                            >
                              <circle cx="2" cy="2.5" r="2" fill="white" />
                              <circle cx="10" cy="2.5" r="2" fill="white" />
                              <circle cx="18" cy="2.5" r="2" fill="white" />
                            </svg>
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                ) : (
                  <>
                    <p className="mt-2 text-[15px] font-medium leading-[18px] text-gray_140">
                      불편한 증상은
                      <br /> 없으셨나요?
                    </p>
                    <div className="flex h-full w-full justify-center pt-8">
                      <img
                        src={"/images/mobile/icon/empty_img.svg"}
                        alt="값 비어있을때 출력되는 이미지"
                        className="h-12 w-12"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 items-start gap-2.5 pb-5">
              {/* 걸음수 */}
              <div className="flex w-full items-start gap-2.5">
                {(checkStep.type === "ios" && checkStep.step === false) ||
                (checkStep.type === "aos" &&
                  (checkStep.step === false || checkNoti === false)) ? (
                  <div className="flex h-[218px] w-full flex-col items-start gap-9 overflow-hidden rounded-xl bg-white px-4 py-2.5">
                    <div className="flex flex-col">
                      <p className="text-xl font-bold text-black">걸음수</p>
                      <p className="mr-2 text-sm font-medium text-neutral-500">
                        걸음수 연동이 끊겼어요!
                      </p>
                    </div>
                    <button
                      className="mt-4 w-full items-center justify-center gap-2.5 rounded-md bg-[#FE718E] p-2.5"
                      onClick={() => setOpenDialog(true)}
                      // onClick={() => navigate("/step")}
                    >
                      <p className="text-lg font-semibold text-white">
                        연결하기
                      </p>
                    </button>
                  </div>
                ) : (
                  <>
                    <div
                      className="flex h-[218px] w-full flex-col items-start gap-9 overflow-hidden rounded-xl bg-white px-4 py-2.5"
                      onClick={() => navigate("/step")}
                    >
                      <div className="text-xl font-bold text-black">
                        걸음수
                        <div className="flex gap-1">
                          <p className="text-[22px] font-semibold leading-[29px] text-black">
                            {todayStep || 0}
                          </p>
                          <p className="line flex items-end text-[16px] font-semibold text-black">
                            걸음
                          </p>
                        </div>
                        <div className="flex ">
                          <p className="mr-2 text-sm font-medium text-neutral-500">
                            어제보다
                          </p>
                          <img
                            src={"/images/mobile/icon/upIconRed.svg"}
                            alt="upIcon"
                            className={`mr-1 ${
                              todayStep - steps[steps?.length - 1] < 0 &&
                              "rotate-180"
                            }`}
                          />
                          <div className="text-sm font-medium text-[#FF4646]">
                            {Math.abs(todayStep - steps[steps?.length - 1])}
                          </div>
                        </div>
                      </div>

                      <div className="-ml-8 mt-5 flex h-14 w-full items-end gap-2">
                        <BarChart
                          dataName={"걸음수"}
                          actualData={steps}
                          step={todayStep}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* 복약알림 */}
              <div
                className="flex w-full items-start gap-2.5"
                onClick={() => navigate("/drugalarm")}
              >
                <div className="flex h-[218px] w-full flex-col items-start gap-9 rounded-xl bg-white px-4 py-2.5">
                  <div className="flex w-full flex-col items-start gap-2">
                    <div className="flex w-full flex-col">
                      <div className="text-xl font-bold text-black">
                        복약 알림
                      </div>

                      {filterDrugLists?.length > 0 ? (
                        <Swiper
                          spaceBetween={50}
                          slidesPerView={1}
                          className="my-8 h-full w-full"
                        >
                          {filterDrugLists?.map((medicine) => (
                            <SwiperSlide
                              key={
                                medicine.schedule_id + medicine.id + medicine.n
                              }
                              className="flex h-full w-full flex-col items-center gap-2.5"
                            >
                              <div className="w-full truncate text-center text-xl font-bold text-neutral-700">
                                {medicine.name}
                              </div>
                              <div className="flex w-full flex-row justify-center">
                                <img
                                  src={"/images/mobile/icon/alarm_clock.svg"}
                                  alt="알람시계"
                                  className="mr-2"
                                />
                                <div className="font-neo text-[15px]  text-neutral-400">
                                  {timeIn12HourFormat(
                                    `${medicine.hour}:${medicine.minute}`
                                  )}
                                </div>
                              </div>
                              <div
                                className={`flex w-[80px] items-center justify-center gap-2.5 rounded-[60px]  px-2.5 py-1.5 font-neo text-sm ${
                                  medicine.check
                                    ? "bg-[#01B68B] text-white"
                                    : "border border-[#01B68B] bg-white text-[#01B68B] active:bg-[#01B68B] active:text-white"
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(
                                    scheduleCheck({
                                      userId: loginInfo.userId,
                                      id: medicine.id,
                                      date: chosenDate || startDate,
                                      n: medicine.n,
                                      check: !medicine.check,
                                    })
                                  )
                                    .unwrap()
                                    .then((res) => {
                                      if (res.ok) {
                                        dispatch(
                                          fetchDrugSchedule({
                                            userId: loginInfo.userId,
                                            date: chosenDate || startDate,
                                          })
                                        );
                                      }
                                    });
                                }}
                              >
                                {medicine.check ? "완료" : "예정"}
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      ) : (
                        <>
                          <p className="mt-2 text-[15px] font-medium leading-[18px] text-gray_140">
                            약 먹을 시간을 놓치고
                            <br /> 있지는 않나요?
                          </p>
                          <div className="flex h-full w-full justify-center pt-8">
                            <img
                              src={"/images/mobile/icon/empty_img.svg"}
                              alt="값 비어있을때 출력되는 이미지"
                              className="h-12 w-12"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex flex-col items-center gap-[14px] rounded-xl bg-white px-4 py-2.5"
              onClick={() => navigate("/treatment")}
            >
              <div className="flex w-full items-center justify-between">
                <div className="text-xl font-bold text-black">
                  {treatments?.length > 0 ? "진료알림" : "진료일정 알림"}
                </div>
                <div className="text-[15px] font-semibold text-neutral-400">
                  {treatments?.length > 0 ? `${treatments?.length}개` : null}
                </div>
              </div>

              <div className="flex-start flex w-full gap-2">
                {treatments?.length > 0 ? (
                  <Swiper
                    // modules={[Pagination]}
                    spaceBetween={50}
                    slidesPerView={1}
                    className="h-full w-full"
                    // pagination={{ clickable: true }}
                  >
                    {treatments?.map((treatment, index) => (
                      <SwiperSlide
                        key={index}
                        className="flex h-full w-full gap-2.5"
                      >
                        <div className="h-[75px] w-1.5 rounded-[5px] bg-sky-400" />
                        <div className="flex-start flex w-full flex-col gap-0">
                          <span className="text-lg font-semibold text-black">
                            {treatment.name}에서
                            <br />
                            {treatment.hour > 12
                              ? `오후 ${treatment.hour - 12}`
                              : `오전 ${treatment.hour}`}
                            시 {treatment.minute}분에 예정된 진료가 있어요!
                          </span>
                          <span className="text-base font-semibold text-neutral-400">
                            {dayjs(chosenDate || startDate).format(
                              "YYYY년 M월 D일 ddd요일"
                            )}
                          </span>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  <div className="my-2 flex w-full justify-between">
                    <p className="text-[15px] font-medium leading-[18px] text-gray_140">
                      예정된 진료 일정을 기록하고
                      <br /> 알림 받으세요!
                    </p>
                    <img
                      src={"/images/mobile/icon/empty_img.svg"}
                      alt="값 비어있을때 출력되는 이미지"
                      className="-mt-7 mr-6 h-12 w-12"
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <Tutorial setTutorialState={setTutorialState} />
        )}
      </div>
      {/* 걸음수 팝업 */}
      {
        openDialog &&
          // IOS
          (checkStep.type === "ios" && checkStep.step === false ? (
            <Dialog
              innerClass={"p-6"}
              title={"걸음수 연동 동의"}
              desc={
                '<p className="text-xl font-medium leading-loose tracking-tight text-stone-950"><span className="font-bold ">설정 - 건강 - 데이터 접근 및 기기 - 힐리어리 - 걸음</span>을 활성화 해주세요.</p>'
              }
              leftBtnTitle={"확인"}
              rightBtnTitle={"닫기"}
              onLeftBtnAction={() => moveSetting()}
              onRightBtnAction={() => setOpenDialog(false)}
              onCoverAction={() => setOpenDialog(false)}
            />
          ) : checkStep.type === "aos" && checkNoti === false ? (
            <Dialog
              innerClass={"p-6"}
              title={"앱 알림설정 동의"}
              desc={"걸음수 연동을 위해 필요해요."}
              leftBtnTitle={"동의하기"}
              rightBtnTitle={"닫기"}
              onLeftBtnAction={() => moveSetting()}
              onRightBtnAction={() => {
                setOpenDialog(false);
              }}
              onCoverAction={() => {
                setOpenDialog(false);
              }}
            />
          ) : checkStep.type === "aos" && checkStep.step === false ? (
            <Dialog
              innerClass={"p-6"}
              title={"신체 활동정보 동의"}
              desc={"걸음수 연동을 위해 필요해요."}
              leftBtnTitle={"동의하기"}
              rightBtnTitle={"닫기"}
              onLeftBtnAction={() => moveSetting()}
              onRightBtnAction={() => {
                setOpenDialog(false);
              }}
              onCoverAction={() => {
                setOpenDialog(false);
              }}
            />
          ) : null)

        // AOS
      }

      <MenuBar />
      {/* {askSurvey && (
      <SurverDialog
        nickName={loginInfo?.nickName}
        onSurveyAction={onSurveyAction}
      />
    )} */}
    </>
  );
};

export default Home;
