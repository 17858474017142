const Indicator = ({ currentIndex, totalScreens }) => {
  return (
    <div className="flex justify-center">
      {Array.from({ length: totalScreens }, (_, index) => (
        <span
          key={index}
          className={`mx-1 h-1.5 w-1.5 rounded-full ${
            currentIndex === index ? "bg-blue-500" : "bg-gray-300"
          }`}
        ></span>
      ))}
    </div>
  );
};
export default Indicator;
