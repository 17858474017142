import React from 'react'

const ModalSelect = ({datas,onChangeFunc,name,categories}) => {
  return (
    <div className="rounded w-full border border-gray_60 px-[8px] py-[12px] mb-[4px]">
      {categories=== undefined?
        <select
        id={datas.id}
        name={name} 
        className="w-full outline-none bg-transparent text-gray_60 focus:text-gray_100  cursor-pointer"
        onChange={onChangeFunc}
        >
          {datas.options?.map((el,idx)=>(
            <option 
            key={idx} >
              {el}
            </option>
          ))}
        </select>:
        <select
        id={datas.id}
        value={name ==="lcategory"?categories.lcategory:
        name ==="mcategory"?categories.mcategory:categories.scategory}
        name={name}
        className="w-full outline-none bg-transparent text-gray_60 focus:text-gray_100  cursor-pointer"
        onChange={onChangeFunc}
        >
          {datas.options?.map((el,idx)=>(
            <option 
            key={idx} >
              {el}
            </option>
          ))}
        </select>
      }
    </div>
  )
}

export default ModalSelect