import React from 'react';

const AnswerCard2 = ({ list, onCheckOne, checkedItems }) => { 
    return (
        <li onClick={() => onCheckOne(list.dCode)} className={'cursor-pointer border border-gray_200 rounded-2xl p-4 text-center ' + (checkedItems?.find(item => item === list.dCode) ? 'bg-gray_180' : '')}>
            <h2 className={'text-btn_700 ' + (checkedItems?.find(item => item === list.dCode) ? 'text-white' : 'text-gray_200')}>
            {list.name}
            </h2>
        </li>
    );
};

export default AnswerCard2;