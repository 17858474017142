import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  adminLogin,
  removeLoginInfo,
} from "../../../features/admin/adminloginSlice";
import { toast } from "react-toastify";
import toastCommonProps from "../../../common/toast";
import CommonSquareBox from "../components/common/CommonSquareBox";
import { BGButtonCustom } from "../components/common/ButtonCustom";
import { normalInputRing } from "../data/TailwindCommon";
import {
  autoLoginSave,
  getAdminIdInfo,
} from "../../../features/admin/adminloginSlice";
import { LabelAdminInput } from "../components/common/LabelAdminInput";

const AdminSignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.adminLogin);

  const [loginInput, setLoginInput] = useState({
    adminId: "",
    password: "",
  });
  const checkRef = useRef();
  const checkInputStringNone =
    loginInput.adminId === "" || loginInput.password === "";
  const userIdInLocalStorage = JSON.parse(window.localStorage.getItem("admin"));
  const sampleId = [
    { role: "슈퍼어드민(헤링스)", adminId: "healiary01", pass: "qwer1234!" },
    { role: "일반어드민(헤링스)", adminId: "healiaryn01", pass: "qwer1234!" },
    { role: "슈퍼어드민(병원)", adminId: "seoul01", pass: "qwer1234!" },
    { role: "일반어드민(병원)", adminId: "hanyang01", pass: "qwer1234!" },
  ];

  const onChangeLogin = (e) => {
    const { name, value } = e.target;
    setLoginInput({ ...loginInput, [name]: value });
  };

  const Loginin = async () => {
    let noBlankparam = {
      adminId: loginInput.adminId.replace(" ", ""),
      pass: loginInput.password.replace(" ", ""),
    };
    const response = await dispatch(adminLogin(noBlankparam)).unwrap();
    try {
      if (response.ok === true) {
        dispatch(autoLoginSave(response.admin));
        if (checkRef.current.checked) {
          window.localStorage.setItem("admin", JSON.stringify(response.admin));
        } else {
          window.localStorage.removeItem("admin");
        }
        toast(
          <p>로그인 성공</p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );

        navigate("/admin");
      } else {
        toast(
          <p>{response.error}</p>,
          toastCommonProps("top-right", "toast_alert")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const navigateAdminSignup = () => {
    navigate("/admin/signup");
  };
  const onAutoLogin = useCallback(() => {
    if (userIdInLocalStorage !== null && userIdInLocalStorage !== "") {
      //localStorage에 정보있을때
      dispatch(getAdminIdInfo(userIdInLocalStorage))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            dispatch(autoLoginSave(res.admin));
            navigate("/admin");
          } else {
            console.log("자동로그인 실패~~~~~~~~~~~~~~~~~");
          }
        });
    }
  }, []);

  const findIdPw = (navigatePath, txt) => {
    return (
      <p
        className="flex cursor-pointer flex-row items-start gap-1 py-0.5 text-caption2_400 text-gray_170"
        onClick={() => {
          navigate(navigatePath);
        }}
      >
        {txt}{" "}
      </p>
    );
  };
  useEffect(() => {
    //localStorage에 userId있으면 오토로그인
    if (loginInfo?.adminId) dispatch(removeLoginInfo());
    if (userIdInLocalStorage !== null && userIdInLocalStorage !== "")
      onAutoLogin();
  }, []);

  return (
    <>
      <div className="flex items-center">
        <div>
          <div className=" mb-16 flex h-[554px] w-[416px] flex-col items-center ">
            <CommonSquareBox>
              <img
                src="/images/admin/icon/힐리어리로그인.svg"
                alt="힐리어리 로그인 이미지"
                className="mb-[24px] items-center"
              />
              <div className="flex w-[350px] flex-col gap-y-[20px]">
                <LabelAdminInput
                  title={"아이디"}
                  name={"adminId"}
                  type={"text"}
                  value={loginInput.adminId}
                  placeholderVal={"아이디를 입력하세요"}
                  inputcss={normalInputRing + " w-full "}
                  onChangeAction={(e) => onChangeLogin(e)}
                  onKeyPressAction={Loginin}
                />
                <LabelAdminInput
                  title={"비밀번호"}
                  name={"password"}
                  type={"password"}
                  value={loginInput.password}
                  placeholderVal={"비밀번호를 입력하세요"}
                  inputcss={normalInputRing + " w-full "}
                  onetimeuse={true}
                  onChangeAction={(e) => onChangeLogin(e)}
                  onKeyPressAction={Loginin}
                />
              </div>
              <div className="flex w-full items-center gap-x-2 py-4 text-caption2_400 text-gray_170">
                <input type="checkbox" id="check" ref={checkRef} />
                <label htmlFor="check">자동로그인</label>
              </div>
              <div className="w-full">
                <BGButtonCustom
                  onClick={checkInputStringNone ? undefined : Loginin}
                  content={"로그인"}
                  bgcolor={
                    checkInputStringNone ? "bg-orange_80" : "bg-orange_100"
                  }
                  textcolor={"text-gray_10"}
                  textsize={"text-body_2"}
                />
              </div>
              <div className="mt-10 flex flex-row items-center justify-center gap-2 text-gray_100">
                {findIdPw("/admin/findid", "아이디 찾기")}|
                {findIdPw("/admin/findadminpwd", "비밀번호 찾기")}
              </div>
            </CommonSquareBox>
            <div className="mt-7 flex h-[70px] w-full  flex-none flex-col items-center gap-2 px-8">
              <BGButtonCustom
                onClick={navigateAdminSignup}
                content={"관리자 회원 가입 신청"}
                bgcolor={"bg-gray_120"}
                textcolor={"text-gray_10"}
                textsize={"text-body_2"}
              />
              <p className="flex items-center  text-caption2_400 font-normal not-italic text-gray_140">
                가입 신청 후, 승인 완료된 계정으로만 사용 가능합니다
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSignIn;
