import React from 'react'

const ImageClick = ({postings}) => {
  return (
    <>
    {postings?.files?.length>0 &&
      <div className="flex flex-col pt-4">
        <p className='font-bold pb-2'>이미지 클릭하여 보기</p>
        <ul className="text-blue-500 flex flex-col">
        {postings?.files?.map((el,idx)=><a key={idx} href={el} target="_blank" className="underline">image {idx+1}</a>)}
        </ul>
      </div>
    }
    </>

  )
}

export default ImageClick
