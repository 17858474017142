import React, { useEffect, useRef, useState } from "react";
import HeadCont from "../components/common/HeadCont";
import { useNavigate } from "react-router-dom";
import CalendarHeader from "../components/common/CalendarHeader";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExistWeightDataOfYear,
  fetchWeeklyDiary,
  saveDiary,
} from "../../../features/mobile/diarySlice";
import { saveChosenDate } from "../../../features/mobile/commonSlice";
import { regDecimal } from "../components/logic";

const Weight = () => {
  dayjs.locale("ko");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const { weeklyDiaryLists, existWeightOfYear } = useSelector(
    (state) => state.diary
  );
  const [filteredData, setFilteredData] = useState([]);
  const [dataExist, setDataExist] = useState(false);
  const inputRef = useRef(null);
  const [value, setValue] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const onChangeInput = (e) => {
    setValue(e.target.value.slice(0, 4));
    if (regDecimal(e.target.value)) {
      if (
        (e.target.value.length > 0 && Number(e.target.value) < 20) ||
        (e.target.value.length > 0 && Number(e.target.value) > 150)
      ) {
        //몸무게는 20-150 사이
        setErrorMsg("몸무게를 다시 입력해주세요.");
      } else {
        setErrorMsg(null);
      }
    }
  };
  const { loginInfo, chosenDate } = useSelector((state) => state.commonApp);

  // const onOpenAction = () => {
  //   const body = document.querySelector("body");
  //   body.classList.add("no-scroll");
  //   setOpenDialog(true);
  // };

  const onSaveDiary = (type, answer) => {
    let params = {
      userId: loginInfo.userId,
      inputDay: chosenDate || startDate,
      [type]: answer,
    };
    setFilteredData({ ...filteredData, weight: answer });
    setValue(null);
    dispatch(saveDiary(params))
      .unwrap()
      .then(() => {
        const timer = setTimeout(() => window.scrollTo(0, 0), 50);
        let params = {
          userId: loginInfo.userId,
          standardDate: dayjs(chosenDate || startDate).format("YYYY-MM-DD"),
        };
        dispatch(
          fetchExistWeightDataOfYear({
            userId: loginInfo.userId,
            year: dayjs(startDate).format("YYYY"),
          })
        );
        dispatch(fetchWeeklyDiary(params));
        return () => {
          clearTimeout(timer);
        };
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // 처음 렌더링 혹은 값 수정 및 저장으로 새로 fetch한 경우 해당일자 몸무게 업데이트
    if (chosenDate) {
      setFilteredData(
        weeklyDiaryLists?.filter((list) => list.day === chosenDate)[0]
      );
      setDataExist(existWeightOfYear?.includes(chosenDate));
    } else {
      setFilteredData(
        weeklyDiaryLists?.filter((list) => list.day === startDate)[0]
      );
      setDataExist(existWeightOfYear?.includes(startDate));
    }
  }, [weeklyDiaryLists]);

  useEffect(() => {
    setValue(null);
    if (chosenDate > dayjs().format("YYYY-MM-DD")) {
      // 오늘 이후날짜 선택 방지
      dispatch(saveChosenDate(dayjs().format("YYYY-MM-DD")));
    } else {
      // 날짜변경시 새로운 일주일 데이터 가져오기
      dispatch(
        fetchWeeklyDiary({
          userId: loginInfo.userId,
          standardDate: chosenDate || startDate,
        })
      );
    }
  }, [chosenDate]);

  useEffect(() => {
    dispatch(
      fetchExistWeightDataOfYear({
        userId: loginInfo.userId,
        year: dayjs(startDate).format("YYYY"),
      })
    );
  }, [dayjs(startDate).format("YYYY"), chosenDate]);
  console.log(dataExist);
  return (
    <>
      <HeadCont
        title={"체중기록"}
        transparent={"white"}
        onLeftAction={() => navigate(-1)}
        onRightAction={() => navigate("/home")}
        rightIcon={"home"}
      />

      <CalendarHeader
        type={"체중기록"}
        startDate={startDate}
        setStartDate={setStartDate}
        chosenDate={chosenDate}
        existDaysOfYear={[...existWeightOfYear]}
      />
      <div className="-mt-[28px] h-screen rounded-tl-[28px] bg-white px-6 py-4">
        <div className="p-[10px]">
          <p className=" text-xl font-bold text-[#05040D]">
            오늘의 체중을 입력해 주세요!
          </p>
          <p className="mb-10 text-[#313131]">
            체중이 변경되면 새로운 식단이 제공됩니다.
          </p>

          <div className="flex w-full items-center gap-2">
            <input
              ref={inputRef}
              type="number"
              inputMode="decimal"
              pattern="[0-9]*"
              className={`w-full appearance-none rounded-md border border-gray_80 bg-gray_10 py-4 pl-4 text-body2_500l text-black focus:outline-none focus:ring-0 ${
                dataExist && "placeholder:text-black"
              }`}
              onChange={(e) => onChangeInput(e)}
              value={value || ""}
              placeholder={filteredData?.weight}
              onFocus={(e) => {
                window.scrollTo(30, 0);
                if (dataExist) {
                  setValue(filteredData?.weight);
                }
              }}
              onBlur={() => {
                window.scrollTo(0, 0);
              }}
            />
            <span className="text-body2_700 text-black">kg</span>
          </div>
          <span className="text-caption1_400 text-gray_160">
            소숫점 1의자리까지 기록하실 수 있습니다.
          </span>
          {errorMsg && (
            <p className="text-caption1_400 text-error">{errorMsg}</p>
          )}
          <button
            className={`fixed bottom-4 left-4 right-4 z-30 mx-auto rounded-2xl border px-4 py-4 text-white ${
              value && !errorMsg
                ? "border-gray_210 bg-gray_210 "
                : "bg-[#939393]"
            }`}
            type="submit"
            disabled={value && !errorMsg ? false : true}
            onClick={() => onSaveDiary("weight", value)}
          >
            기록완료
          </button>
        </div>
      </div>
    </>
  );
};

export default Weight;
