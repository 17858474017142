import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  decrement,
  fetchAnswerList,
  fetchQuestion,
  // goBackByAmount,
  resetI,
} from "../../../../features/mobile/questionDiseaseSlice";
import HeadCont from "../../components/common/HeadCont";
import QuestionForm from "../../components/question_disease/QuestionForm";

const QuestionDisease = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { i, prevI } = useSelector((state) => state.questionDisease);
  const closeIcon = useMemo(
    () => ({
      background: "url('/images/mobile/icon/close.svg') no-repeat center",
    }),
    []
  );

  const onLeftAction = () => {
    if (i <= 0) {
      navigate(-1);
      window.scrollTo(0, 0);
    } else {
      dispatch(decrement());
    }
  };

  useEffect(() => {
    if (!loginInfo?.userId) navigate(`/sign`);
    else {
      window.scrollTo(0, 0);
      dispatch(resetI());
      dispatch(fetchAnswerList(loginInfo.userId));
      dispatch(fetchQuestion());
    }
  }, [dispatch, loginInfo.userId, navigate]);

  return (
    <>
      {/* onRightAction: 
            처음 가입하고 설문하는 사람 관련 이슈 (이전 페이지가 recommend 이거나 healthinfo일 경우 -1이 먹히지 않음) 때문에 api user/questionCheck 쓰려고 했으나, 일단 홈으로 이동 */}
      <HeadCont
        option={"progress"}
        onLeftAction={onLeftAction}
        rightIcon={closeIcon}
        onRightAction={() => navigate(location.state ? -1 : "/home")}
        i={i}
      />
      <section className="px-6 pt-2 mt-14">
        <QuestionForm onLeftAction={onLeftAction} />
      </section>
    </>
  );
};

export default QuestionDisease;
