import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInfoPostingDetails,
  onResetPosts,
  onSetPrevBookmark,
  onSetPrevPostList,
} from "../../../../features/mobile/infoSlice";
import { onSetLike } from "../../../../features/mobile/commonSlice";
import HeadCont from "../../components/common/HeadCont";
import BookmarkBtn from "../../components/common/BookmarkBtn";
import PostDetailsCard from "../../components/information/PostDetailsCard";
// import { ReactComponent as Like } from "../../../../svg/like2.svg";

import InfoPageLoading from "../../components/loading/InfoPageLoading";
import { mobileTypeFn } from "../../../mobile/components/logic";
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";

const InfoDetail = ({ myPage = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  // Redux State Variables
  const { loginInfo } = useSelector((state) => state.commonApp);
  const {
    postingDetails,
    postingDetailsStatus,
    isLike,
    prevPostList,
    prevBookmark,
  } = useSelector((state) => state.info);

  // Local State Variables
  const [likeStatus, setLikeStatus] = useState({
    likeCount: 0,
    isLiked: false,
  });
  const [isWriteReply, setIsWriteReply] = useState(false);

  const bringPostingDetails = useCallback(async () => {
    let params = { userId: loginInfo.userId, postingId: id };
    const res = await dispatch(fetchInfoPostingDetails(params)).unwrap();

    if (res.ok) {
      // 정보글 보여주는 에디터 변경으로 필요없어짐
      // if (res.posting.category !== null) {
      //   let viewer = new Viewer({
      //     el: document.querySelector("#viewer"),
      //   });
      //   let brchange = res.posting.content?.replaceAll(
      //     '"false"><br>',
      //     '"false"><p><br></p>'
      //   );
      //   viewer.setMarkdown(brchange);
      //   viewer.setMarkdown(res.posting.content);
      // }
      setIsWriteReply(res.isWriteReply ? true : false);
      setLikeStatus({ likeCount: res.posting.likeCount, isLiked: res.like });
    }
    // eslint-disable-next-line
  }, [id]);

  const onLikePost = () => {
    // 처리 시마다 좋아요수 때문에 계속 api 불러와야 해서 LikeBtns 컴포넌트 안씀
    let params = { likeUser: loginInfo.userId, belongId: id };

    dispatch(onSetLike(params))
      .unwrap()
      .then((res) => {
        ///////////////////////
        // If we are within myPage (when isLike is not null) either reset the post
        // or take out the disliked post
        if (res.ok) {
          if (res.message === "likeTrue") {
            setLikeStatus({ likeCount: res.likeCount, isLiked: true });
            if (isLike) {
              // window.scrollTo(0, 1);
              dispatch(onResetPosts());
            }
          } else {
            setLikeStatus({ likeCount: res.likeCount, isLiked: false });
            if (isLike) {
              dispatch(
                onSetPrevBookmark(
                  prevBookmark.filter((value) => value.id !== parseInt(id))
                )
              );
              dispatch(
                onSetPrevPostList(
                  prevPostList.filter((value) => value.id !== parseInt(id))
                )
              );
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  // share functionality
  //eslint-disable-next-line
  const onOpenShare = () => {
    if (mobileTypeFn() === "iphone") {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview
          .callHandler("getIosVersion")
          .then(function (result) {
            checkVersion(result);
          });
      } else {
        goWebShare();
      }
    } else if (mobileTypeFn() === "android") {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview
          .callHandler("getAndroidVersion")
          .then(function (result) {
            checkVersion(result);
          });
      } else {
        goWebShare();
      }
    } else {
      goWebShare();
    }
  };
  const goWebShare = () => {
    if (navigator.share) {
      //공유하기 웹api 출력 가능시-아이폰
      navigator.share({
        title: "힐리어리가 추천해 드리는 정보입니다.",
        text: postingDetails.posting.title,
        url: `https://www.healiary.com/info/share/${id}/`,
      });
    }
  };
  const checkVersion = (curVersion) => {
    var isUpdate = false;
    var shareMinVersion = "2.0.10";
    if (curVersion !== null && curVersion !== "") {
      var curVersionList = curVersion.split(".");
      var shareMinVersionList = shareMinVersion.split(".");
      if (curVersionList.length === 3) {
        var curVersion1 = Number(curVersionList[0]);
        var shareMinVersion1 = Number(shareMinVersionList[0]);
        var curVersion2 = Number(curVersionList[1]);
        var shareMinVersion2 = Number(shareMinVersionList[1]);
        var curVersion3 = Number(curVersionList[2]);
        var shareMinVersion3 = Number(shareMinVersionList[2]);

        if (curVersion1 < shareMinVersion1) {
          isUpdate = true;
        }
        if (
          !isUpdate &&
          curVersion1 === shareMinVersion1 &&
          curVersion2 < shareMinVersion2
        ) {
          isUpdate = true;
        }
        if (
          !isUpdate &&
          curVersion1 === shareMinVersion1 &&
          curVersion2 === shareMinVersion2 &&
          curVersion3 < shareMinVersion3
        ) {
          isUpdate = true;
        }
      } else {
        isUpdate = true;
      }
    } else {
      isUpdate = true;
    }
    if (isUpdate) {
      goUpdate();
    } else {
      let title = "힐리어리가 추천해 드리는 정보입니다.";
      let tag = postingDetails.posting.title;
      let url = `https://www.healiary.com/info/share/${id}/`;
      window.flutter_inappwebview.callHandler("share", title, tag, url);
    }
  };
  const goUpdate = () => {
    let isOk = window.confirm(
      "공유하기 기능을 사용하시려면 버전 업데이트가 필요합니다. 확인을 누르시면 설치 페이지로 이동합니다."
    );
    if (isOk) {
      if (mobileTypeFn() === "iphone") {
        window.location.replace(
          "https://apps.apple.com/kr/app/id1636424503",
          "_blank"
        );
      } else {
        window.location.replace(
          `market://details?id=com.herings.healiary&pli=1`,
          "_blank"
        );
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    bringPostingDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.state) {
      window.scrollTo(0, 0);
      bringPostingDetails();
    }
    // eslint-disable-next-line
  }, [location.state, bringPostingDetails]);

  return (
    <>
      <HeadCont
        // rightIcon={shareIcon}
        idName={"infoShare"}
        underline={true}
        transparent={"white"}
        title={"정보"}
        // onRightAction={onOpenShare}
      />
      {/* <HeadCont underline={true} transparent={"white"} title={"정보"} /> */}
      {!postingDetailsStatus ? (
        <InfoPageLoading />
      ) : postingDetails ? (
        <>
          <section className="z-[1] mt-14">
            <PostDetailsCard data={postingDetails.posting} />
          </section>
          <div
            className={`maxW ${
              myPage ? "border-b" : "fixed"
            } bottom-0 z-10 flex w-full flex-row bg-white px-6 py-4`}
          >
            <ul className="flex gap-1 py-1">
              <li
                onClick={() => navigate(`/info/comment/${id}`)}
                className="flex items-center rounded-[36px] bg-gray_20 px-3 py-2"
              >
                <img
                  className="w-6 h-6 m-auto"
                  src={`/images/mobile/icon/comment_blue${
                    isWriteReply ? "_filled" : ""
                  }.svg`}
                  alt="Comment 아이콘"
                />
                <span className="text-caption2_700 text-main">
                  {postingDetails?.posting?.replyCount}
                </span>
              </li>
            </ul>
            <BookmarkBtn
              option={"info"}
              checkStatus={postingDetails?.bookmark}
              id={postingDetails?.posting?.id}
              title={"즐겨찾기"}
            />
          </div>
        </>
      ) : (
        <InfoPageLoading />
      )}
    </>
  );
};

export default InfoDetail;
