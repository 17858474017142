import { useDispatch, useSelector } from "react-redux";
import ManagerUserList from "../ManagerUserList";
import CommonOption from "../../../components/common/CommonOption";
import SearchBox from "../../../components/common/SearchBox";
import PaginationLayout from "../../../components/common/pagination/PaginationLayout";
import { handlePageChange } from "../../../../../features/admin/commonSlice";

const Manager = ({
  user,
  filterInfo,
  onChangeNameUserId,
  onMemberDetail,
  onClickSearch,
  tabName,
  onChangeInput,
  pagePerList,
}) => {
  const dispatch = useDispatch();
  // const BGcategory = ["전체","헤링스","병원"]
  const MDcategory = ["전체", "슈퍼관리자", "일반관리자", "대기"];
  const heringsAuthority = user.authority === "00" || user.authority === "01";
  const { totalPage } = useSelector((state) => state.management);
  const onePageManagers = useSelector(
    (state) => state.management.searchAdminList
  );

  const handlePageFn = (clickPage) => {
    dispatch(handlePageChange(clickPage));
    onClickSearch("관리자", clickPage);
  };
  return (
    <div className="flex w-full flex-col items-start rounded-b-[24px] rounded-r-[24px] bg-[#FCFCFC] px-[14px] pt-[16px] shadow-md">
      <div className="flex w-full flex-col items-start gap-[16px] px-[20px]">
        <div
          className={
            "flex h-[48px] w-full flex-row items-start gap-[8px] " +
            (heringsAuthority ? " justify-between " : " justify-end ")
          }
        >
          {heringsAuthority ? (
            <div className="flex gap-[8px]">
              {/* <CommonOption
                lists={BGcategory}
                ver={1}
                selectid={"group"}
                onChangeFunction={onChangeNameUserId}
                mywidth={114}
              /> */}
              <CommonOption
                lists={MDcategory}
                ver={1}
                selectid={"authorityType"}
                onChangeFunction={onChangeNameUserId}
                mywidth={114}
              />
            </div>
          ) : (
            ""
          )}
          <SearchBox
            value={filterInfo.searchNameOrID}
            onChange={onChangeInput}
            onClick={() => {
              onClickSearch(tabName, 1);
              dispatch(handlePageChange(1));
            }}
            placeholder={"이름 또는 아이디를 검색하세요."}
            width={296}
          />
        </div>
      </div>
      <div className="w-full gap-[16px] p-[20px]">
        <PaginationLayout
          totalPage={totalPage}
          pagePerList={pagePerList}
          onClick={(clickpage) => handlePageFn(clickpage)}
        >
          <ManagerUserList
            onMemberDetail={onMemberDetail}
            filteredUsers={onePageManagers}
            tab={tabName}
          />
        </PaginationLayout>
      </div>
    </div>
  );
};

export default Manager;
