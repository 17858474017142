import dayjs from "dayjs"

export const ThreeConceptReport = ({whatGuideEach}) =>{
  return(
    <>
      <div className='flex gap-x-[12px] items-center'>
        <span className='pr-2 '>최초 작성</span>
        <span className='border border-gray_20 h-[16px] inline-block'></span>
        <span className='font-bold px-2'>{whatGuideEach.addAdmin !== undefined && whatGuideEach.addAdmin.adminId}</span>
        <span className='pl-2'>{dayjs(whatGuideEach.createdAt).format("YYYY-MM-DD")}</span>
      </div>
    {!(whatGuideEach.editAdmin === undefined || whatGuideEach.editAdmin === null) &&
      <div className='flex gap-x-[12px] items-center'>
        <span className='pr-2'>최초 업데이트</span>
        <span className='border border-gray_20 h-[16px] inline-block'></span>
        <span className='font-bold px-2'>{whatGuideEach.editAdmin.adminId}</span>
        <span className='pl-2'>{
          dayjs(whatGuideEach.updatedAt).format("YYYY-MM-DD")}</span>
      </div> 
    }
    </>
  )
}

export const TwoConceptReport = ({user}) =>{
  return(
    <div className='flex gap-x-4'>
      <span className='font-bold'>{user.name}</span>
      <span>{dayjs().format("YYYY-MM-DD")}</span>
    </div>
  )
}