import React, { useCallback, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useSelector } from "react-redux";
import FoldPosts from "../community/FoldPosts";
import CommListSkeleton from "../loading/CommListSkeleton";
import PostReportDialog from "../common/PostReportDialog";
const CommunityContainer = ({
  commParams,
  loginInfo,
  pageType = null,
  navTab,
}) => {
  const { prevLikedList } = useSelector((state) => state.commMobile);
  const [openListViewBtn, setOpenListViewBtn] = useState(false);
  const timeSpan = {
    전체: null,
    "1개월": 1,
    "3개월": 3,
    "6개월": 6,
    "1년": 12,
  };
  // const [currentTimeSpan, setCurrentTimeSpan] = useState({ "1개월": 1 });
  const matchTimeSpan = (rangeMonth) => {
    const [foundKey] =
      Object.entries(timeSpan).find(([key, value]) => value === rangeMonth) ||
      [];
    if (foundKey) return { [foundKey]: rangeMonth };
    else return { 전체: null };
  };
  const [currentTimeSpan, setCurrentTimeSpan] = useState(
    commParams.rangeMonth
      ? matchTimeSpan(commParams.rangeMonth)
      : { 전체: null }
  );
  const [optionOpen, setOptionOpen] = useState({ open: false, id: null });
  const [searchText, setSearchText] = useState(
    commParams.searchedValue || null
  );
  const ref = useOutsideClick(
    useCallback(() => {
      setOpenListViewBtn(false);
    }, [setOpenListViewBtn])
  );

  // community Hook variables
  // prettier-ignore
  const {
    // variables
    postList, loading, isMorePosting, openDialog, reportStatus, currentUser,
    // functions
    onBringPostings, onFetchMorePosts, onClickPost, onClickWritePost, onDeletePost, onAddReporting, onCloseDialog, onReportReply, // onBlockUser,
    // state function
    setLoading, setOpenDialog, setCurrentUser, setReportStatus,
    // etc.,
    navigate,
  } = commParams;

  // community parameters
  const parameters = {
    postList: postList,
    onFetchMorePosts: onFetchMorePosts,
    isMorePosting: isMorePosting,
    prevLikedList: prevLikedList,
    onClickPost: onClickPost,
    onDeletePost: onDeletePost,
    onClickWritePost: onClickWritePost,
    onAddReporting: onAddReporting,
    // onBlockUser: onBlockUser,
    setOpenDialog: setOpenDialog,
    setCurrentUser: setCurrentUser,
    setReportStatus: setReportStatus,
    setOptionOpen: setOptionOpen,
    optionOpen: optionOpen,
    myPage: pageType,
  };

  useEffect(() => {
    if (!loginInfo?.userId) navigate(`/sign`);
    else {
      if (postList?.length === 0) {
        setLoading(false);
        onBringPostings({
          writerUserId: pageType === "like" ? null : loginInfo?.userId,
          rangeMonth: commParams.rangeMonth,
          textValue: commParams.searchedValue,
          isLike: pageType === "like" ? true : null,
        })
          .then((res) => {
            if (res) window.scroll(0, 1);
          })
          .catch((error) => console.log(error));
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section>
        {/* Search Tab */}
        <div className="flex flex-col gap-2.5 p-4 pt-2.5 ">
          <span className="text-caption1_700 text-gray_160">
            키워드로 검색하기
          </span>
          <div className="flex items-center gap-1">
            <input
              placeholder={"키워드를 입력해주세요."}
              className="no-scrollbar h-[66px] w-full resize-none appearance-none rounded border border-gray_80 p-4 text-body2_500l text-black focus:border-gray_160 focus:outline-none focus:ring-0"
              value={searchText || ""}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <figure
              className="h-full shrink-0"
              onClick={() => {
                onBringPostings({
                  writerUserId: pageType === "like" ? null : loginInfo?.userId,
                  rangeMonth: Object.values(currentTimeSpan)[0],
                  textValue: searchText,
                  isLike: pageType === "like" ? true : null,
                }).catch((error) => console.log(error));
              }}
            >
              <img
                alt="search"
                src="/images/admin/icon/search.svg"
                className="h-9 w-9"
              />
            </figure>
          </div>
        </div>
        {/* Main Content */}
        <div className="flex justify-end border-y border-gray_60 bg-white px-4 py-2.5">
          <div
            className="flex gap-1.5"
            onClick={() => setOpenListViewBtn(!openListViewBtn)}
          >
            <span className="text-caption2_700" id="three_dot_menu">
              {Object.keys(currentTimeSpan)[0]}
            </span>
            <div className="h-6 w-6 shrink-0">
              <img
                alt="viewMore"
                src="/images/mobile/icon/list_more.svg"
                id="three_dot_menu"
              />
            </div>
          </div>
        </div>
        <AnimatePresence>
          {openListViewBtn && (
            <motion.ul
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, y: 0 }}
              transition={{ ease: "easeOut", duration: 0.2 }}
              ref={ref}
              className={
                "absolute right-[24px] z-[6] mt-[-15px] flex h-min flex-col rounded-xl bg-[#FFFFFF] px-5 py-4 opacity-100 shadow-[0_0_12px_rgba(0,0,0,0.1)] "
              }
            >
              {Object.entries(timeSpan)?.map(([key, value]) => (
                <li key={key} className="flex flex-col gap-2">
                  <div
                    className={
                      "w-full text-center text-caption1_700 text-black focus:bg-primary_20 focus:outline-none"
                    }
                    onClick={() => {
                      setLoading(false);
                      setCurrentTimeSpan({ [key]: value });
                      setOpenListViewBtn(false);
                      onBringPostings({
                        writerUserId:
                          pageType === "like" ? null : loginInfo?.userId,
                        rangeMonth: value,
                        textValue: searchText,
                        isLike: pageType === "like" ? true : null,
                      }).catch((error) => console.log(error));
                    }}
                  >
                    {key}
                  </div>
                  {key !== "1년" && (
                    <div className="mb-2 border-b border-gray_40" />
                  )}
                </li>
              ))}

              {/* </div> */}
            </motion.ul>
          )}
        </AnimatePresence>
        {loading ? (
          <FoldPosts parameters={parameters} />
        ) : (
          <>
            <CommListSkeleton />
            <CommListSkeleton />
            <CommListSkeleton />
            <CommListSkeleton />
            <CommListSkeleton />
          </>
        )}
        {openDialog && (
          <PostReportDialog
            onCloseDialog={onCloseDialog}
            onReportReply={onReportReply}
            reportStatus={reportStatus}
            setReportStatus={setReportStatus}
            post={true}
            nickName={currentUser?.writer?.nickName} // writer.nickname
          />
        )}
      </section>
    </>
  );
};

export default CommunityContainer;
