import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import HeadCont from "../../components/common/HeadCont";
import {
  fetchExistSymptomDataOfYear,
  fetchSymptomLists,
  fetchWeeklyDiary,
  saveDiary,
} from "../../../../features/mobile/diarySlice";
import InputDialog from "../../components/diary/InputDialog";
import CalendarHeader from "../../components/common/CalendarHeader";
import { saveChosenDate } from "../../../../features/mobile/commonSlice";

const SymptomRecord = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  dayjs.locale("ko");
  const { loginInfo, chosenDate } = useSelector((state) => state.commonApp);

  const { weeklyDiaryLists, existSymptomOfYear } = useSelector(
    (state) => state.diary
  );
  // const [chosenDate, setChosenDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [status, setStatus] = useState({ option: "show", type: null }); //show: 보여주기, add: 입력하기
  const [openDialog, setOpenDialog] = useState(false);
  const [openAction, setOpenAction] = useState(false);

  const [symptomArr, setSymptomArr] = useState({
    arr1: [],
    arr2: [],
    arr3: [],
  });
  const [option, setOption] = useState("");

  //   const filtered = weeklyMealLists?.filter(
  //     (list) => list.mealDay === chosenDate
  //   )[0];
  const [filteredData, setFilteredData] = useState([]);
  // const filtered = weeklyDiaryLists?.filter(
  //   (list) => list.day === chosenDate || startDate
  // )[0];

  useEffect(() => {
    if (
      option === "symptomCodeList" ||
      (option === "memo" && openAction === true)
    ) {
      onOpenAction();
    }

    // // 클린업 함수
    // return () => {
    //   setOption("");
    // };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, openAction]);

  useEffect(() => {
    if (chosenDate > dayjs().format("YYYY-MM-DD")) {
      // 오늘 이후날짜 선택 방지
      dispatch(saveChosenDate(dayjs().format("YYYY-MM-DD")));
    } else {
      let params = {
        userId: loginInfo.userId,
        standardDate: chosenDate ? chosenDate : dayjs().format("YYYY-MM-DD"),
      };
      dispatch(fetchWeeklyDiary(params));
    }
  }, [chosenDate]);

  useEffect(() => {
    dispatch(
      fetchExistSymptomDataOfYear({
        userId: loginInfo.userId,
        year: dayjs(startDate).format("YYYY"),
      })
    );
  }, [chosenDate, status.option, dayjs(startDate).format("YYYY")]);

  const onOpenAction = (inputOption) => {
    // setOption(inputOption);
    console.log(option, "euqwoiuqowieuqwioe");
    const body = document.querySelector("body");
    body.classList.add("no-scroll");
    // if (option !== "") {
    if (option === "symptomCodeList") {
      dispatch(fetchSymptomLists())
        .unwrap()
        .then((res) => {
          console.log(res, "Res");
          setOpenDialog(true);
          let arrs1 = [];
          let arrs2 = [];
          let arrs3 = [];
          res.slice(0, 8).map((one) => {
            return arrs1.push(one);
          });
          res.slice(8, 17).map((one) => {
            return arrs2.push(one);
          });
          res.slice(17).map((one) => {
            return arrs3.push(one);
          });
          return setSymptomArr({
            arr1: [...arrs1],
            arr2: [...arrs2],
            arr3: [...arrs3],
          });
        })
        .catch((err) => console.log(err));
    } else setOpenDialog(true);
    setOpenAction(false);
    // }
  };

  useEffect(() => {
    // 처음 렌더링 혹은 값 수정 및 저장으로 새로 fetch한 경우 해당일자 증상 업데이트
    if (chosenDate) {
      setFilteredData(
        weeklyDiaryLists?.filter((list) => list.day === chosenDate)[0]
      );
    } else {
      setFilteredData(
        weeklyDiaryLists?.filter((list) => list.day === startDate)[0]
      );
    }
  }, [weeklyDiaryLists]);

  const onSaveDiary = (type, answer) => {
    let params = {
      userId: loginInfo.userId,
      inputDay: chosenDate || startDate,
      [type]: answer,
    };
    dispatch(saveDiary(params))
      .unwrap()
      .then(() => {
        const timer = setTimeout(() => window.scrollTo(0, 0), 50);
        let params = {
          userId: loginInfo.userId,
          standardDate: dayjs(chosenDate || startDate).format("YYYY-MM-DD"),
        };
        dispatch(
          fetchExistSymptomDataOfYear({
            userId: loginInfo.userId,
            year: dayjs(startDate).format("YYYY"),
          })
        );
        dispatch(fetchWeeklyDiary(params));
        return () => {
          clearTimeout(timer);
        };
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <HeadCont
        title={"증상기록"}
        transparent={"white"}
        onLeftAction={() =>
          status.option === "add"
            ? setStatus({ option: "show", type: null })
            : navigate(-1)
        }
        onRightAction={() => navigate("/home")}
        rightIcon={"home"}
      />
      <section>
        {/* <div className="inline-flex h-[160px] w-full flex-col items-start"> */}
        {/* <div className="flex w-full items-center justify-between bg-[#847CE9] px-6 py-3">
            <span className="text-xl font-semibold text-center text-white">
              {dayjs(chosenDate).format("YYYY. MM. DD")}
            </span>
            <img src="/images/mobile/home/new_calendar.svg" alt="달력 아이콘" />
          </div> */}

        {/* <div className="flex w-full items-start justify-between px-6 pt-[10px]">
            <DateStrip
              startDate={startDate}
              setStartDate={setStartDate}
              chosenDate={chosenDate}
              handleChange={handleChange}
              existDates={[...existDaysOfYear]}
            />
          </div> */}
        <CalendarHeader
          type={"증상기록"}
          startDate={startDate}
          setStartDate={setStartDate}
          chosenDate={chosenDate}
          // setChosenDate={setChosenDate}
          existDaysOfYear={[...existSymptomOfYear]}
        />
        {/* </div> */}
        <div className="-mt-[28px] flex h-auto flex-col rounded-tl-[28px]  bg-white">
          <div className="h-auto w-full flex-col items-center justify-center gap-4 border-b border-b-gray_60 px-[10px] py-2.5">
            <div className="items-start gap-2 p-2.5">
              <div className=" pb-2 text-xl font-bold leading-[30px] tracking-tight text-gray-950">
                오늘 있었던 증상을 선택해주세요!
              </div>
              <div className="flex flex-wrap gap-2.5 py-2.5">
                {filteredData?.symptomCodeList?.length > 0 &&
                  filteredData.symptomCodeList.map((one, idx) => (
                    <div
                      key={idx}
                      className="flex rounded-lg border-[2px] border-[#847CE9] bg-[#847CE9] px-2.5 py-2"
                    >
                      <span className="text-base font-bold leading-[18px] tracking-tight text-white">
                        {one.name}
                      </span>
                    </div>
                  ))}
                <div
                  className="flex rounded-lg border-[2px] border-gray_210 px-2.5 py-2"
                  onClick={() => {
                    // onOpenAction("symptomCodeList")
                    setOpenAction(true);

                    setOption("symptomCodeList");
                  }}
                >
                  <span className="text-base font-bold leading-[18px] tracking-tight text-gray_210">
                    추가하기
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex shrink-0 flex-col items-start gap-2 px-5 py-3">
            <span className="text-xl font-bold leading-[30px] tracking-tight text-black">
              메모추가
            </span>
            <div
              className="w-full gap-2.5 rounded-lg border border-[#DFDFDF] p-4"
              onClick={
                // () => onOpenAction("memo")
                () => {
                  setOption("memo");
                  setOpenAction(true);
                }
              }
            >
              <span className="flex h-[189px] pb-[150px] text-lg font-bold leading-[30px] tracking-tight text-zinc-500">
                {filteredData?.memo ||
                  "오늘의 기분 또는 증상을 자유롭게 기록해주세요."}
                {/* <div
                  className={
                    "mt-4 w-full appearance-none whitespace-pre-line break-words rounded-md border border-gray_80 px-4 py-4 text-body1_500l " +
                    (filtered?.[option] ? "text-black" : "text-gray_80")
                  }
                >
                  {filtered?.[option] ||
                    "병원방문이나 약 복용 등을 입력해주세요"}
                </div> */}
              </span>
            </div>
          </div>
        </div>

        <InputDialog
          option={option}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          chosenDate={chosenDate}
          onSaveDiary={onSaveDiary}
          savedDatas={filteredData}
          symptomArr={symptomArr}
          // feelings={feelings}
        />
      </section>
    </>
  );
};
export default SymptomRecord;
