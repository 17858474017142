import React from 'react'

const ContentsGuideListLayout = ({tabName,children,title}) => {
  return (
    <>
      {tabName ==="종합 가이드"?
        <div className=
        {title==="내용"?'flex pt-[16px] w-full items-start':'flex pt-[32px] w-full items-center'}>
          <span className='w-[160px]'>{title}</span>
          <div className='flex gap-x-4 w-full items-end'>
            {children}
          </div>
        </div>
        :
        <div className=
        {title==="내용"?'flex pt-[4px] w-full items-start':'flex pt-[4px] w-full items-center'}>
          <span className='w-[160px]'>{title}</span>
          <div className='flex gap-x-4 w-full items-end'>
            {children}
          </div>
        </div>  
      }
    </>

  )
}

export default ContentsGuideListLayout