import React from 'react'
import { useLocation } from 'react-router-dom'
const CustomerCenterDetailForm = ({title,children}) => {
  const path = useLocation().pathname
  return (
     <div className="flex flex-col justify-center bg-[#FCFCFC] rounded-[8px] border border-[#D1CFCD] w-full ">
        <span className="flex items-center p-[24px] gap-[4px] w-full h-[40px] bg-[#F3F3F2] border-b border-[#A39F9B] rounded-t-[8px] font-bold text-xl text-[#463F38]">
          {title}
        </span>
        <div className={"flex flex-col w-full p-6 "+(path==="/admin/information/detail"?" items-center ":"")}>
          {children}
        </div>
      </div>
  )
}

export default CustomerCenterDetailForm