import React, { useState } from "react";
import { useSelector } from "react-redux";
import { onResetAll } from "../../../../features/mobile/infoSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import Btn from "../../components/common/Btn";
import HeadCont from "../../components/common/HeadCont";
import InfoCard from "../../components/information/InfoCard";
import InfoSkeleton from "../../components/loading/InfoSkeleton";
import useInfo from "../../hooks/useInfo";

const InfoSearch = () => {
  // redux states
  const { loginInfo } = useSelector((state) => state.commonApp);

  const {
    // variables
    postList,
    popularPosts,
    // loading,
    isMorePosting,
    bookmark,
    totalPosts,
    searchedValue,
    // functions
    onBringPostings,
    onFetchMorePosts,
    onClickPost,
    // state function
    // setLoading,
    // etc.,
    dispatch,
    navigate,
  } = useInfo("search", loginInfo);

  // local states
  const [value, setValue] = useState("");
  const [localTotalPosts, setLocalTotalPosts] = useState(
    totalPosts ? totalPosts : 0
  );

  return (
    <>
      <HeadCont
        rightIcon={``}
        transparent={"white"}
        underline={true}
        title={"정보검색"}
        onRightAction={() => window.scrollTo(0, 0)}
        onLeftAction={() => {
          dispatch(onResetAll());
          navigate(-1);
        }}
      />
      <section className="z-[1] mx-6 mb-28 mt-14">
        <div className="mt-4">
          <input
            onChange={(e) => setValue(e.target.value)}
            id={"infoSearch"}
            name={"info"}
            value={value || ""}
            placeholder={"찾으시는 정보를 검색해주세요."}
            className="no-scrollbar h-[66px] w-full resize-none appearance-none rounded border border-gray_80 p-4 text-body2_500l text-black focus:border-gray_160 focus:outline-none focus:ring-0"
          />
        </div>
        {postList?.length > 0 || popularPosts?.length > 0 ? (
          postList && postList?.length > 0 ? (
            <div className="mt-2">
              <span className="break-all text-body3_500 text-gray_160">
                '{searchedValue || ""}'에 대한 정보글 {localTotalPosts}건
                있습니다.
              </span>
              <InfiniteScroll
                dataLength={postList.length}
                next={onFetchMorePosts}
                hasMore={isMorePosting}
                loader={<InfoSkeleton info={true} />}
              >
                <ul className="grid grid-cols-2 gap-3 mt-8 overflow-y-auto bg-white">
                  {postList?.length > 0 &&
                    postList.map((list, idx) => (
                      <li key={idx}>
                        <InfoCard
                          id={list.id}
                          title={list.title}
                          category={list.category}
                          thumbnail={list.thumbnail}
                          marked={bookmark[idx]?.bookmark}
                          onOpenInfoPost={onClickPost}
                        />
                      </li>
                    ))}
                </ul>
              </InfiniteScroll>
            </div>
          ) : popularPosts && popularPosts?.length > 0 ? (
            <div className="flex flex-col">
              <span className="mx-auto my-24 text-center break-all text-body3_500 text-gray_160">
                '{searchedValue || ""}'에 대한 검색결과가 없습니다.
              </span>
              <div>
                <span className="text-body2_600 text-gray_200">
                  오늘의 인기 글
                </span>
                <ul className="grid grid-cols-2 gap-x-[12px] overflow-y-auto bg-white py-3">
                  {popularPosts.map((list) => (
                    <li key={list.id}>
                      <InfoCard
                        id={list.id}
                        title={list.title}
                        category={list.category}
                        thumbnail={list.thumbnail}
                        marked={list.bookmark}
                        onOpenInfoPost={onClickPost}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <p className="mx-4 mt-4 text-body2_700 text-gray_160">
              정보글이 없습니다.
            </p>
          )
        ) : null}
      </section>
      <div className="mt-4">
        <Btn
          option={"bottom"}
          bgColor={"bg-main"}
          textColor={"text-white font-semibold"}
          borderColor={"border border-main"}
          title={"검색"}
          active={value?.length > 0 ? "on" : "ready"}
          onAction={() => {
            onBringPostings({
              searchValue: value,
              setSearchValue: setValue,
              setLocalTotalPosts: setLocalTotalPosts,
            }).catch((error) => console.log(error));
          }}
        />
      </div>
    </>
  );
};

export default InfoSearch;
