import React from 'react'
import { useSelector } from 'react-redux'

const RadioBtnDisabled = ({label,engtit}) => {
  const allGuideEach = useSelector((state)=>state.contents.eachGuideList)

  return (
    <>
      {engtit ==="kcalIntake"?
        <li className='flex gap-x-4'>
          <span
            className=
            {label === allGuideEach.kcalIntake?
              'bg-white rounded-full bg-[#fa8f69] w-5 h-5 mt-[2px] float-left'
              :'bg-white border rounded-full border-gray-400 w-5 h-5 mt-[2px] float-left'
            }
          >
            {
              label === allGuideEach.kcalIntake &&
              <img src="/images/admin/icon/check.svg" alt="ckeck"/>
            }
          </span>
          <p>{label}</p>
        </li>
        :
        engtit ==="proteinIntake"?
        <li className='flex gap-x-4'>
          <span
            className=
            {label === allGuideEach.proteinIntake?
              'bg-white rounded-full bg-[#fa8f69] w-5 h-5 mt-[2px] float-left'
              :'bg-white border rounded-full border-gray-400 w-5 h-5 mt-[2px] float-left'
            }
          >
            {
              label === allGuideEach.proteinIntake &&
              <img src="/images/admin/icon/check.svg" alt="ckeck"/>
            }
          </span>
          <p>{label}</p>
        </li>
        :
        <li className='flex gap-x-4'>
        <span
          className=
          {label === allGuideEach.lcategory?
            'bg-white rounded-full bg-[#fa8f69] w-5 h-5 mt-[2px] float-left'
            :'bg-white border rounded-full border-gray-400 w-5 h-5 mt-[2px] float-left'
          }
        >
          {
            label === allGuideEach.lcategory &&
            <img src="/images/admin/icon/check.svg" alt="ckeck"/>
          }
        </span>
        <p>{label}</p>
      </li>
      }
    </>

  )
}

export default RadioBtnDisabled