import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

const AnimateCard = ({
  id,
  openStates,
  contentComponent,
  closedComponent,
  height = 80,
}) => {
  const ref = useRef(null);
  const contentRef = useRef(null);

  const containerVariants = {
    open: { height: ref ? ref.current?.offsetHeight : "auto" },
    close: {
      height: height,
    },
  };

  const crossfadeTransition = {
    duration: 0.3,
    ease: "easeInOut",
  };

  return (
    <>
      {/* Pre-render the contentComponent to get the height */}
      <div
        className="fixed invisible w-screen px-6"
        ref={ref}
        style={{ transform: "translateX(-9000px)" }}
      >
        {contentComponent}
      </div>
      <motion.div
        initial={openStates[id] ? "close" : "open"}
        animate={openStates[id] ? "open" : "close"}
        exit="close"
        variants={containerVariants}
        transition={crossfadeTransition}
        // className={`${color} ${rounded} `}
        ref={contentRef}
      >
        {openStates[id] ? contentComponent : closedComponent}
      </motion.div>
    </>
  );
};

export default AnimateCard;
