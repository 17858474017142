import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import "./slide.css";

const MobileThirdPart = () => {
  const contents = [
    {
      title: "식사기록•분석",
      contents: [
        "하루 동안 섭취한 식단을 기록하여",
        "식사 분석과 추천식단을 받아보세요!",
      ],
      loopedSlides: 5,
      slideImg: "식사기록",
      peopleImg: ["식사기록"],
    },
    {
      title: "증상•체중기록",
      contents: [
        "증상과 체중을 기록하여 매일 나의",
        "건강상태를 모니터링 해보세요!",
      ],
      loopedSlides: 4,
      slideImg: "증상체중기록",
      peopleImg: ["증상기록", "체중기록"],
    },
    {
      title: "복약•진료알림",
      contents: [
        "복약과 진료 일정을 입력하고",
        "알림을 통해 스케줄 관리를 해보세요!",
      ],
      loopedSlides: 4,
      slideImg: "복약진료알림",
      peopleImg: ["복약알림", "진료알림"],
    },
    {
      title: "걸음수 측정",
      contents: ["하루동안 측정된", "나의 걸음수를 체크해보세요!"],
      loopedSlides: 4,
      slideImg: "걸음수",
      peopleImg: ["걸음수-m", "걸음수-w"],
    },
  ];

  return (
    <section className="flex w-screen flex-col items-center justify-center gap-6 bg-[#F8FBFF] px-6 py-12">
      <h3 className="text-2xl font-bold text-center">
        힐리어리의 All-In-One 케어
      </h3>
      <p className="text-center text-[15px] leading-[22px] text-[#3E3E3E]">
        식단 기록부터 복약 알림까지
        <br />
        한데 모아 암 환자를 위한
        <br />
        All-In-One 헬스케어 어플리케이션입니다.
        <br />
        모든 암 환자와 보호자를 위한
        <br />
        희망과 지지의 공간,
        <br />
        힐리어리와 시작해 보세요.
      </p>

      <div className="flex flex-col items-center w-full gap-20">
        {contents.map((content) => (
          <div className="w-full text-center" key={content.title}>
            <h2 className="mb-2">
              <span className="relative before:absolute before:-bottom-2 before:left-0 before:right-0 before:top-1/2 before:h-1/2 before:bg-[#417FFF24]">
                <span className="relative text-lg font-bold text-center">
                  {content.title}
                </span>
              </span>
            </h2>

            {content.contents.map((val) => (
              <p
                key={val}
                className="text-[13px] font-bold leading-[20px] text-[#606365]"
              >
                {val}
              </p>
            ))}

            <div className="relative">
              <Swiper
                modules={[Pagination]}
                centeredSlides={true} // 슬라이드 가운데 정렬
                loopedSlides={4}
                slidesPerView={1} // 한 페이지에 슬라이드 출력 개수
                className="flex w-full pb-20 mt-3 landing-swiper"
                pagination={{
                  clickable: true,
                  bulletClass: "mobile-landing-swiper-bullet",
                  bulletActiveClass: "mobile-landing-swiper-bullet-active",
                  horizontalClass: "mobile-landing-swiper-pagination",
                }}
              >
                {[...Array(content.loopedSlides)].map((_, num) => (
                  <SwiperSlide key={content.slideImg + num} className="w-full">
                    <img
                      src={`/images/mobile/landing/all-in-one/${
                        content.slideImg
                      }-${Number(num + 1)}.png`}
                      alt={content.slideImg + num}
                      className={`mx-auto h-[333px] w-[161px]`}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              {content.peopleImg.map((img, index) => (
                <img
                  key={index}
                  className={`absolute z-10  ${
                    img === "식사기록"
                      ? "bottom-8 left-3 h-[149px]"
                      : img === "증상기록"
                      ? "bottom-10 left-11 h-[116px]"
                      : img === "체중기록"
                      ? "bottom-14 right-8 h-[128px]"
                      : img === "복약알림"
                      ? "bottom-11 left-1 h-[125px]"
                      : img === "진료알림"
                      ? "bottom-14 right-7 h-[110px]"
                      : img === "걸음수-m"
                      ? "bottom-12 left-8 h-[125px]"
                      : "bottom-10 right-10 h-[122px]"
                  }`}
                  src={`/images/mobile/landing/all-in-one/${img}.svg`}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MobileThirdPart;
