import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPushAlarm, setPushAlarm } from '../../../../features/mobile/settingSlice';
import HeadCont from '../../components/common/HeadCont';
import ToggleBtn from '../../components/common/Toggle';

const Push = () => {
    const dispatch = useDispatch()
    const { loginInfo } = useSelector((state) => state.commonApp)
    const arr = ["전체알림", "공지사항", "식사기록 알림", "건강기록 알림", "새 글 알림", "댓글 알림"]
    const [toggle, setToggle] = useState({ pushOnAll: false, pushOnNotice: false, pushOnEatRecord: false, pushOnDiary: false, pushOnNewPost: false, pushOnNewReply: false })

    const Rename = (param) => {
        if (param === "전체알림") return "pushOnAll";
        if (param === "공지사항") return "pushOnNotice";
        if (param === "식사기록 알림") return "pushOnEatRecord";
        if (param === "건강기록 알림") return "pushOnDiary";
        if (param === "새 글 알림") return "pushOnNewPost";
        if (param === "댓글 알림") return "pushOnNewReply";

    }

    const onToggle = (param) => {
        const par1 = { userId: loginInfo.userId, pushOnAll: !toggle.pushOnAll }
        const par2 = { userId: loginInfo.userId, pushOnNotice: !toggle.pushOnNotice }
        const par3 = { userId: loginInfo.userId, pushOnEatRecord: !toggle.pushOnEatRecord }
        const par4 = { userId: loginInfo.userId, pushOnDiary: !toggle.pushOnDiary }
        const par5 = { userId: loginInfo.userId, pushOnNewPost: !toggle.pushOnNewPost }
        const par6 = { userId: loginInfo.userId, pushOnNewReply: !toggle.pushOnNewReply }

        setToggle({ ...toggle, pushOnAll: !toggle.pushOnAll })

        if (param === "전체알림") {
            if (toggle.pushOnAll) { //on -> off
                setToggle({
                    pushOnAll: false,
                    pushOnNotice: false,
                    pushOnEatRecord: false,
                    pushOnDiary: false,
                    pushOnNewPost: false,
                    pushOnNewReply: false
                })
            } else { //off -> on
                setToggle({
                    pushOnAll: true,
                    pushOnNotice: true,
                    pushOnEatRecord: true,
                    pushOnDiary: true,
                    pushOnNewPost: true,
                    pushOnNewReply: true
                })
            }
            dispatch(setPushAlarm(par1))
        } else if (param === "공지사항") {
            setToggle({ ...toggle, pushOnNotice: !toggle.pushOnNotice })
            dispatch(setPushAlarm(par2))
        } else if (param === "식사기록 알림") {
            setToggle({ ...toggle, pushOnEatRecord: !toggle.pushOnEatRecord })
            dispatch(setPushAlarm(par3))
        } else if (param === "건강기록 알림") {
            setToggle({ ...toggle, pushOnDiary: !toggle.pushOnDiary })
            dispatch(setPushAlarm(par4))
        } else if (param === "새 글 알림") {
            setToggle({ ...toggle, pushOnNewPost: !toggle.pushOnNewPost })
            dispatch(setPushAlarm(par5))
        } else {
            setToggle({ ...toggle, pushOnNewReply: !toggle.pushOnNewReply })
            dispatch(setPushAlarm(par6))
        }
    }

    useEffect(() => {
        dispatch(getPushAlarm(loginInfo.userId))
            .unwrap()
            .then(res => {
                if (res.ok) {
                    setToggle(res)
                }
            })
            .catch(err => console.log(err))
    }, [dispatch, loginInfo.userId])

    return (
        <>
            <HeadCont rightIcon={'no icon'} transparent={'on'} title={"알림 설정"} underline={true} />
            <section className='relative px-6 mb-6 mt-14'>
                <ul>
                    {arr.map((el, idx) => (
                        <li className='p-4' key={idx}>
                            <ToggleBtn isOn={toggle[Rename(el)]} onToggle={() => onToggle(el)} title={el} justifyB={true} />
                        </li>
                    ))}
                </ul>
            </section>
        </>
    );
};

export default Push;