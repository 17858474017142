import React, { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import AccountDialog from "./AccountDialog";

const Navbar = ({ user }) => {
  const { section, subSection } = useSelector((state) => state.common);
  const [alarmStatus, setAlarmStatus] = useState(false);
  const [openAccountDialog, setOpenAccountDialog] = useState(false);
  const HeaderList = [
    {title:section, licss:"text-caption1_600 text-black"},
    // {title:subSection, licss:"text-body_2 text-gray_100"},
    // {title:"관리메뉴얼", licss:"text-body_2 text-gray_100 border-b border-gray_20"},
    // {title:"암종별가이드", licss:"text-body_2 text-gray_100 border-b border-gray_20"}
  ]
  const userAuth = user.authority === "00" ? "헤링스 슈퍼관리자" : user.authority === "01" ? "헤링스 일반관리자" : user.authority === "10" ? "병원 슈퍼관리자" : "병원 일반관리자"
  const onSetAccountDialog = () => setOpenAccountDialog(!openAccountDialog)
  dayjs.locale("ko")

  return (
    <>
      {user &&
        <header className="flex justify-between items-center border-b border-gray_80 px-6 py-1">
          <ul className="flex gap-4">
            {HeaderList?.map((el,idx)=>
              <li className={el.licss} key={idx}>{el.title}</li>
            )}
          </ul>
          <nav className="flex gap-4 items-center">
            <div className="text-caption1_400">
              {dayjs().format(`YYYY${"년"} MM${"월"} DD${"일"} dddd HH:mm`)}
            </div>
            {/* <img
              src={
                alarmStatus === true
                  ? `/images/admin/icon/alert_active.svg`
                  : `/images/admin/icon/alert.svg`
              }
              alt="알람 아이콘"
              className="cursor-pointer"
            /> */}
            <div
              onClick={onSetAccountDialog}
              className="flex justify-center items-center gap-2 cursor-pointer bg-white rounded-full p-2"
            >
              {user.photo === null ?
                <span className="w-[40px] h-[40px] bg-gray-100 rounded-full flex justify-center items-center font-bold">{user.name.slice(0, 1)}</span>
                :
                <img className="w-[40px] h-[40px] rounded-full" src={user.photo} />
              }
              <div className="flex flex-col">
                <h3 className="text-caption2_700 text-black ">
                  {user.name}
                </h3>
                <h4 className="text-sm text-gray_140">
                  {userAuth}
                </h4>
              </div>
              <img
                src="/images/admin/icon/arrow_down.svg"
                alt="아래 화살표 아이콘"
                className="p-1"
              />
              {openAccountDialog && <AccountDialog user={user} onSetAccountDialog={onSetAccountDialog} />}
            </div>
          </nav>
        </header>
      }
    </>
  );
};

export default Navbar;
