import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BGButtonCustom } from "../../../components/common/ButtonCustom";
import Paging from "../../../components/common/Paging";
import HospitalList from "./HospitalList";

const HospitalTable = ({ dataList }) => {
  const { page } = useSelector((state) => state.common);
  const navigate = useNavigate();
  const { pagePerList } = useSelector((state) => state.pagination);
  let EachPage =
    dataList?.length > 0
      ? dataList.slice((page - 1) * pagePerList, page * pagePerList)
      : [];
  return (
    <div className="flex w-full flex-col items-start rounded-[24px] bg-[#FCFCFC] px-[14px] pt-[16px] shadow-md">
      <div className="flex w-full flex-col items-start gap-[16px] px-[20px]">
        <div className="flex w-full flex-none flex-row items-center justify-between gap-[44px]">
          <div className="flex w-full justify-end">
            <BGButtonCustom
              onClick={() => navigate("/admin/hospital/add")}
              content={"병원 등록하기"}
              bgcolor={"bg-orange_100"}
              textcolor={"text-gray_10"}
              textsize={"text-caption2_600"}
              xpadding={"12px"}
            />
          </div>
        </div>
        <div className="w-full gap-[16px]">
          <HospitalList postPerPage={pagePerList} filteredUsers={EachPage} />
          <div className="flex justify-center py-4">
            <Paging
              totalSearchCount={dataList?.length}
              pagePerList={pagePerList}
              pageRangeDisplayed={5}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalTable;
