import ApexChart from "react-apexcharts";
import dayjs from "dayjs";

const OthersFeelGraph = ({
  barHColor,
  dataLists,
  graphOption,
  measureUnit,
  color,
}) => {
  const len =
    graphOption === "최근 7일" ? 7 : graphOption === "최근 30일" ? 30 : 90;
  let feeling = dataLists.slice(dataLists?.length - len, dataLists?.length);

  const xData = feeling ? feeling?.map((el) => (el.feel ? el.feel : 0)) : [];
  const yDate = feeling
    ? feeling?.map((el) => dayjs(el.day).format("MM.DD"))
    : [];
  const feelList = [
    "아주 행복해요",
    "좋아요",
    "나쁘지 않아요",
    "별로예요",
    "아주 슬퍼요",
  ];

  const customToolTip = (series, seriesIndex, dataPointIndex, end) => {
    return (
      `<div class="flex">` +
      `<div class="text-gray-400" >` +
      parseInt(yDate[dataPointIndex].slice(0, 2)) +
      "월" +
      parseInt(yDate[dataPointIndex].slice(3, 5)) +
      "일&nbsp;" +
      "</div>" +
      `<div class="font-bold">` +
      (series[seriesIndex][dataPointIndex] === 0
        ? "데이터 없음"
        : feelList[5 - series[seriesIndex][dataPointIndex]]) +
      `</div>` +
      "</div>"
    );
  };
  return (
    <div className="admin-graphs">
      <div id={barHColor} className="border rounded-md border-gray_60">
        {graphOption === "최근 90일" || graphOption === "최근 30일" ? (
          <ApexChart
            series={[{ data: xData }]}
            options={{
              colors: ["#FFE9A0"],
              fill: {
                type: "solid",
              },
              chart: {
                selection: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                  autoSelected: undefined,
                },
              },
              stroke: {
                curve: "smooth",
                width: 0,
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: yDate,
                tickPlacement: "on",
                position: "bottom",
                labels: {
                  rotate: 0,
                  hideOverlappingLabels: true,
                },
                axisBorder: {
                  show: true,
                },
                axisTicks: {
                  show: false,
                },
                tickAmount: 7,
                crosshairs: {
                  //bar background
                  show: true,
                  position: "back",
                  width: 1,
                  stroke: {
                    color: "#A39F9B",
                    opacity: 1,
                    width: 1,
                    dashArray: 5,
                  },
                },
                tooltip: {
                  enabled: false,
                },
              },

              yaxis: {
                max: 5,
                tickAmount: 5,

                labels: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              states: {
                hover: {
                  filter: {
                    type: "none",
                  },
                },
                active: {
                  filter: {
                    type: "none",
                  },
                },
              },
              legend: {
                show: false,
              },
              tooltip: {
                enabled: true,
                custom: ({ series, seriesIndex, dataPointIndex }) =>
                  customToolTip(
                    series,
                    seriesIndex,
                    dataPointIndex,
                    measureUnit
                  ),
              },
              grid: {
                show: false,
                padding: {
                  right: -5,
                  left: 15,
                },
              },
            }}
            type="area"
            height={350}
          />
        ) : (
          <ApexChart
            series={[{ data: xData }]}
            options={{
              colors: ["#FFE9A0"],
              chart: {
                selection: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                  autoSelected: undefined,
                },
                stacked: true,
              },

              annotations: {
                points: feeling?.map((el) => ({
                  x: dayjs(el.day).format("MM.DD"),

                  y: el.feel,
                  //marker: { size: 0 },

                  image: {
                    width: 28,
                    height: 28,
                    path:
                      el.feel === 1
                        ? "/images/admin/feel/face2_1_active.svg"
                        : el.feel === 2
                        ? "/images/admin/feel/face2_2_active.svg"
                        : el.feel === 3
                        ? "/images/admin/feel/face2_3_active.svg"
                        : el.feel === 4
                        ? "/images/admin/feel/face2_4_active.svg"
                        : el.feel === 5
                        ? "/images/admin/feel/face2_5_active.svg"
                        : "",
                  },
                })),
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: yDate,
                tickPlacement: "on",
                position: "bottom",
                labels: {
                  rotate: 0,
                  hideOverlappingLabels: true,
                },
                axisBorder: {
                  show: true,
                },
                axisTicks: {
                  show: false,
                },
                tickAmount: 7,
                crosshairs: {
                  //bar background
                  show: true,
                  position: "back",
                  width: 1,
                  stroke: {
                    color: "#A39F9B",
                    opacity: 1,
                    width: 1,
                    dashArray: 5,
                  },
                },
                tooltip: {
                  enabled: false,
                },
              },
              yaxis: {
                max: 5,
                tickAmount: 5,
                labels: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              legend: {
                show: false,
              },
              tooltip: {
                enabled: true,
                enabledOnSeries: [1],
                custom: ({ series, seriesIndex, dataPointIndex }) =>
                  customToolTip(
                    series,
                    seriesIndex,
                    dataPointIndex,
                    measureUnit
                  ),
              },
              grid: {
                show: false,
                padding: {
                  right: 5,
                  left: 15,
                },
              },
            }}
            type="line"
            height={350}
          />
        )}
      </div>
    </div>
  );
};

export default OthersFeelGraph;
