import React, { useEffect, useState } from "react";
import HeadCont from "../components/common/HeadCont";
// import CalendarHeader from "../components/common/CalendarHeader";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ApexChart from "react-apexcharts";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStep,
  fetchStepPoints,
  onSetStepList,
} from "../../../features/mobile/stepSlice";
// import customToolTip from "../../admin/components/graph/customToolTipGraph";

const Step = () => {
  dayjs.locale("ko");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [chosenDate, setChosenDate] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [option, setOption] = useState("1주");
  const { loginInfo, chosenDate } = useSelector((state) => state.commonApp);
  const { stepList, group, steps, stepStatus } = useSelector(
    (state) => state.step
  );
  const { todayStep } = useSelector((state) => state.mainData);
  const [newList, setNewList] = useState([]);
  const [stepAvg, setStepAvg] = useState("");

  let tooltip = [];
  if (group) {
    for (let obj of group) {
      for (let i = 0; i < obj.cols; i++) {
        tooltip.push(`${obj.title} ${i + 1}주차`);
      }
    }
  }
  const optionObj = {
    "1주": 1,
    "한 달": 2,
    "6개월": 3,
    "1년": 4,
  };

  useEffect(() => {
    let params = {
      userId: loginInfo.userId,
      standardDate: startDate,
      type: optionObj[option],
    };
    dispatch(fetchStep(params));
  }, [option, startDate]);

  useEffect(() => {
    let params = {
      userId: loginInfo.userId,
      year: dayjs().format("YYYY"),
    };
    dispatch(fetchStepPoints(params));
  }, []);

  useEffect(() => {
    // if (
    //   option === "1주" &&
    //   chosenDate !== dayjs().format("YYYY-MM-DD") &&
    //   chosenDate !== null
    // ) {
    //   setNewList(
    //     stepList.map((list) => {
    //       return {
    //         x: list.name,
    //         y: list.step,
    //       };
    //     })
    //   );
    // }
    if (stepStatus === "success") {
      if (option === "1주") {
        let newArray = stepList?.map((list) => {
          return {
            x: list.name,
            y: list.step,
          };
        });
        newArray?.push({ x: dayjs().format("YYYY-MM-DD"), y: todayStep });
        setNewList(newArray);
      } else if (option === "6개월") {
        setNewList(
          steps.map((step) => {
            return {
              x: "",
              y: step,
            };
          })
        );
      } else {
        setNewList(
          stepList.map((list) => {
            return {
              x: list.name,
              y: list.step,
            };
          })
        );
      }
    }
    stepAverage();
  }, [stepList, steps, group, stepStatus]);

  // 걸음수 평균 구하기
  const stepAverage = () => {
    if (stepList) {
      const stepArr = stepList
        ?.map((arr) => arr.step)
        .filter((arr) => arr !== 0);

      const stepSum = stepArr.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      if (stepSum === 0 || stepArr.length === 1) {
        setStepAvg(0);
      } else {
        setStepAvg(parseInt(stepSum / stepArr.length));
      }

      return;
    } else if (steps) {
      const filterSteps = steps.filter((arr) => arr !== 0);

      const stepSum = steps.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      if (stepSum === 0) {
        setStepAvg(0);
      } else {
        setStepAvg(parseInt(stepSum / filterSteps.length));
      }
      return;
    } else {
      setStepAvg(0);
      return;
    }
  };
  return (
    <>
      <HeadCont
        title={"걸음수"}
        transparent={"white"}
        onLeftAction={() => navigate(-1)}
        onRightAction={() => navigate("/home")}
        rightIcon={"home"}
        underline={true}
      />

      {/* <CalendarHeader
        type={"걸음수"}
        startDate={startDate}
        setStartDate={setStartDate}
        chosenDate={chosenDate}
        // setChosenDate={setChosenDate}
        existDaysOfYear={[...existStepOfYear]}
      /> */}
      <div className="mt-[86px] rounded-tl-[28px] bg-white p-6">
        <p className="my-[10px] text-xl font-bold text-[#05040D]">
          얼마나 많이 걸었나요?
        </p>

        <div
          className={
            `mt-7 flex h-fit w-full items-center rounded-lg bg-[#F4F4F4] px-10 py-[14px]` +
            (stepAvg !== 0 ? " justify-between " : " justify-center ")
          }
        >
          <p className="text-xl font-semibold leading-tight tracking-tight">
            <span className="mb-0.5 inline-block h-1.5 w-1.5 rounded-full bg-rose-400" />{" "}
            <span className="text-sm font-bold tracking-tight">오늘</span>{" "}
            {todayStep}{" "}
            <span className="text-sm font-normal leading-tight tracking-tight">
              걸음
            </span>
          </p>
          {stepAvg !== 0 ? (
            <p className="text-xl font-semibold leading-tight tracking-tight">
              <span className="mb-0.5 inline-block h-1.5 w-1.5 rounded-full bg-neutral-500" />{" "}
              <span className="text-sm font-bold tracking-tight">평균</span>{" "}
              {stepAvg}{" "}
              <span className="text-sm font-normal leading-tight tracking-tight">
                걸음
              </span>
            </p>
          ) : null}
        </div>
        <AnimatePresence>
          <ul className="mt-3 flex h-fit w-full items-center justify-between gap-1 rounded-lg bg-[#F4F4F4] p-1">
            {["1주", "한 달", "6개월", "1년"].map((val) => (
              <li
                key={val}
                onClick={() => setOption(val)}
                className={`relative h-9 w-1/4 rounded-lg px-[10px] py-1 text-center  duration-100 ease-in ${
                  val === option ? " text-[#FE718E]" : " text-[#9F9F9F]"
                }`}
              >
                <p className="absolute inset-x-0 z-[2] text-lg font-semibold">
                  {val}
                </p>
                {val === option ? (
                  <motion.div
                    className="absolute inset-0 rounded-lg bg-[#FFFFFF]"
                    layoutId="bg"
                  />
                ) : null}
              </li>
            ))}
          </ul>
        </AnimatePresence>
        {option === "1주" ? (
          <>
            <ApexChart
              type="bar"
              options={{
                colors: "#FE718E",
                xaxis: {
                  type: "category",
                  position: "bottom",
                  axisTicks: {
                    show: false,
                  },
                  labels: {
                    // show: option === "한 달" ? false : true,
                    show: false,
                    formatter: function (val) {
                      return ``;
                    },
                  },
                },
                yaxis: {
                  axisBorder: {
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  },
                  labels: {
                    show: true,
                    padding: 10,
                  },
                },

                dataLabels: {
                  enabled: false,
                },
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                grid: {
                  show: true,
                },
                tooltip: {
                  x: {
                    formatter: function (val, opts) {
                      return `${val}`;
                    },
                  },
                },
              }}
              series={[
                {
                  name: "걸음수",
                  data: newList,
                },
              ]}
              height={300}
            />
            <div></div>
          </>
        ) : (
          <ApexChart
            type="bar"
            options={{
              colors: "#FE718E",
              xaxis: {
                type: "category",
                position: "bottom",
                axisTicks: {
                  show: false,
                },
                labels: {
                  show: false,
                  formatter: function (val) {
                    if (option === "한 달" || option === "6개월") {
                      return "";
                    } else if (option === "1년") {
                      return val;
                    }
                  },
                  style: {
                    cssClass: "relative",
                  },
                },
                group: {
                  show: false,
                  style: {
                    fontSize: "12px",
                    fontWeight: 400,
                    cssClass: "absolute left-0",
                  },
                  groups: group || [],
                },
              },
              yaxis: {
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
                labels: {
                  show: true,
                  padding: 10,
                },
              },
              dataLabels: {
                enabled: false,
              },
              chart: {
                toolbar: {
                  show: false,
                },
              },
              grid: {
                show: true,
              },
              tooltip: {
                x: {
                  formatter: function (val, opts) {
                    if (option === "한 달") {
                      return `${val}`;
                    } else if (option === "1년") {
                      return `${val} 평균`;
                    } else if (option === "6개월") {
                      return `${tooltip[opts.dataPointIndex]}`;
                    } else {
                      return `${val}`;
                    }
                  },
                },
              },
            }}
            series={[
              {
                name: `${option} `,
                data: newList,
              },
            ]}
            height={300}
          />
        )}
      </div>
    </>
  );
};

export default Step;
