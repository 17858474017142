import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchFoodRecipe } from "../../../../features/mobile/recommendSlice";
import HeadCont from "../../components/common/HeadCont";
import TitleBox from "../../components/common/TitleBox";
import BookmarkBtn from "../../components/common/BookmarkBtn";
// import ShareBtn from "../../components/common/ShareBtn";

const Recipe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useParams();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { recipeLists, recipeListsStatus } = useSelector(
    (state) => state.recommend
  );
  const [errorMsg, SetErrorMsg] = useState(null);

  const ingredientCard = (title, data) => {
    return (
      <li className="flex items-center justify-between px-2">
        <h2 className="text-caption1_600 text-gray_180">{title}</h2>
        <h3 className="text-black text-end text-body3_500">
          {data ? data : "-"}
        </h3>
      </li>
    );
  };
  useEffect(() => {
    if (type) {
      let params = { userId: loginInfo.userId, foodId: type };
      dispatch(fetchFoodRecipe(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            SetErrorMsg(null);
          } else SetErrorMsg(res.error);
        })
        .catch((err) => console.log(err));
    }
  }, [type, loginInfo.userId, dispatch]);

  return (
    <>
      <HeadCont
        title={"레시피"}
        onRightAction={() => navigate(-1)}
        rightIcon={"close"}
        transparent="white"
      />
      <section className="mb-6 mt-14">
        {errorMsg ? (
          <div className="flex items-center justify-center h-screen">
            {errorMsg}
          </div>
        ) : (
          recipeListsStatus === "success" && (
            <>
              {recipeLists.representativeImage ? (
                <span
                  className="bgCover block h-[320px] w-full"
                  style={{
                    background: `url(${recipeLists.representativeImage}) no-repeat center`,
                  }}
                ></span>
              ) : null}

              {/* {recipeListsStatus === "success" &&
            recipeLists.representativeImage ? (
              <img
                src={recipeLists.representativeImage}
                alt="레시피 이미지"
                width="100%"
                loading="lazy"
              />
            ) : null} */}
              <div className="space-y-4">
                <h1
                  id="recipeTitle"
                  className="my-4 text-center text-black text-subtitle1"
                >
                  {recipeLists.foodname}
                </h1>
                <ul className="flex justify-center gap-4 px-6 py-5 border-t border-b border-gray_40">
                  <BookmarkBtn
                    id={"recipeBookmark"}
                    option={"recipe"}
                    checkStatus={recipeLists.bookmark}
                    foodId={recipeLists.foodId}
                    title={"레시피 즐겨찾기"}
                  />
                  {/* <ShareBtn
                  id={"shareRecipe"}
                  option={"recipe"}
                  foodId={recipeLists.foodId}
                /> */}
                </ul>
                <div className="mx-[28px] my-4 space-y-3">
                  <TitleBox title={"재료"} font={"text-body1_700 text-black"} />
                  {ingredientCard("주재료", recipeLists.ingredientMain)}
                  {ingredientCard("부재료", recipeLists.ingredientSub)}
                  <div className="px-4 py-4 rounded-xl bg-back_ground">
                    <div className="flex items-center justify-between">
                      <h2 className="text-caption1_600 text-gray_180">
                        전체 재료
                      </h2>
                      <span className="px-3 py-1 bg-white rounded-full text-caption1_600 text-main">
                        {recipeLists.persons}인분기준
                      </span>
                    </div>
                    <p className="mt-3 text-body2_500l text-gray_120">
                      {recipeLists.ingredientAll}
                    </p>
                  </div>
                </div>
                <div className="mx-[28px] my-4 space-y-3">
                  <TitleBox
                    title={"레시피"}
                    font={"text-body1_700 text-black"}
                  />
                  <ul className="space-y-3">
                    {recipeLists.recipe &&
                      recipeLists.recipe.map((list, idx) => (
                        <li key={idx} className="flex gap-2 px-1">
                          <span className="h-[32px] w-[32px] rounded-full bg-gray_20 p-1 text-center text-caption1_600 text-gray_180">
                            {idx + 1}
                          </span>
                          <p className="flex-1 text_black text-body2_500">
                            {list}
                          </p>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </>
          )
        )}
      </section>
    </>
  );
};

export default Recipe;
