import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BGButtonCustom } from "../components/common/ButtonCustom";
import CommonSquareBox from "../components/common/CommonSquareBox";
import SignupCheckBox from "../components/signup/SignupCheckBox";

const AdminSignUp = () => {
  const navigate = useNavigate();

  //여기 수정하면 AdminSignUp2도 수정해야함 ~
  // "병원 수퍼관리자","병원 일반관리자" 제외
  const userrole = ["힐리어리 일반관리자"];
  const AuthClickedCss =
    "flex box-border items-center py-4 px-5 gap- rounded-lg border-[1px]  border-gray_60 flex-grow signupCheckbox gap-x-1 cursor-pointer opacity-50";
  const [authoritycheck, setAuthorityCheck] = useState("");
  const checkAuthorityFc = authoritycheck === "";
  //1차 관리자 유형 선택
  const onChangeAuthority = (e) => {
    const eTarget = e.target;
    commonAllDeleteAuth();
    //tag값에 따라 eTarget값이 다르므로 targetName으로 조건문설정
    //check img, check값 변경
    //innerText주입
    if (eTarget.tagName === "INPUT") {
      eTarget.parentNode.className = AuthClickedCss;
      eTarget.checked = true;
      setAuthorityCheck(eTarget.parentNode.childNodes[1].innerText);
    } else if (eTarget.tagName === "P") {
      eTarget.parentNode.className = AuthClickedCss;
      eTarget.parentNode.firstChild.checked = true;
      setAuthorityCheck(eTarget.innerText);
    } else {
      eTarget.className = AuthClickedCss;
      eTarget.firstChild.checked = true;
      setAuthorityCheck(eTarget.childNodes[1].innerText);
    }
  };

  //관리자유형 select 전체 삭제
  const commonAllDeleteAuth = useCallback(() => {
    let allAuthorityCheckbox = document.querySelectorAll(".authority");
    for (let i = 0; allAuthorityCheckbox?.length > i; i++) {
      allAuthorityCheckbox[i].checked = false;
      allAuthorityCheckbox[i].parentNode.className =
        "flex box-border items-center py-4 px-5 gap- rounded-lg border-[1px]  border-gray_60 flex-grow signupCheckbox gap-x-1 cursor-pointer";
    }
  }, []);
  const goSignin = () => navigate("/admin/signin");
  const clickChangePage = () =>
    navigate("/admin/signup2", { state: authoritycheck });

  return (
    <CommonSquareBox>
      <div
        className={`space-y-gap-y-[48px] flex w-[800px] flex-col items-center `}
      >
        <div className="flex flex-col items-center gap-y-4">
          <img
            src="/images/admin/icon/관리자 회원가입 신청.svg"
            alt="관리자 회원가입 신청 이미지"
            className="items-center"
          />
          <p className=" text-body1_400 font-normal tracking-[0.02em] text-gray_170">
            Step 1. 관리자 회원 유형을 선택해주세요.
          </p>
        </div>
        <div className=" flex flex-col items-center gap-y-6  py-[120px]">
          <div className="flex flex-col items-center justify-center gap-x-1">
            <div className="flex flex-row items-start gap-1 ">
              {userrole?.map((el) => (
                <SignupCheckBox
                  key={el}
                  label={el}
                  onChangeAuthority={onChangeAuthority}
                />
              ))}
            </div>
          </div>
          <p className=" tracking-[0.02em] text-gray_170">
            {/* * 수퍼 관리자는 해당 소속 기관 계정을 관리 할 수 있습니다. */}
          </p>
        </div>
        <div className=" flex w-[440px] max-w-[440px] gap-x-[10px] ">
          <BGButtonCustom
            onClick={goSignin}
            content={"취소"}
            bgcolor={"bg-gray_120"}
            textcolor={"text-white"}
            textsize={"text-caption1_600"}
            width={"25%"}
          />
          <BGButtonCustom
            onClick={checkAuthorityFc ? undefined : clickChangePage}
            content={"저장 후 다음"}
            bgcolor={checkAuthorityFc ? "bg-orange_80" : "bg-orange_100"}
            textcolor={"text-white"}
            textsize={"text-caption1_600"}
            width={"75%"}
          />
        </div>
      </div>
    </CommonSquareBox>
  );
};

export default AdminSignUp;
