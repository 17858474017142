// import React, { useEffect, useState } from "react";
import ApexChart from "react-apexcharts";
// import customToolTip from "./customToolTipGraph";
import dayjs from "dayjs";

const CalorieProteinGraph = ({
  datas,
  dayNo,
  option,
  areaNo,
  barHColor,
  calorie,
}) => {
  const data = datas ? datas?.map((el) => (el.data ? el.data : 0)) : [];
  const areaTotal = Math.max(...data) !== 0 ? areaNo / 2 + areaNo + 1 : 1;
  const areaOption = areaTotal < Math.max(...data);
  const yDate = datas?.map((el) => dayjs(el.day).format("MM.DD"));
  const needData = [];
  // 데이타가 없을때 nullData 로 false-chart를 render 하기
  // const nullData = data
  //   ? data?.map((el) =>
  //       el === null
  //         ? Math.max(...data) / 90
  //           ? Math.max(...data) / 90
  //           : 0.01
  //         : 0
  //     )
  //   : [];
  for (let i = 0; i < data.length; i++) {
    needData.push(areaNo);
  }

  return (
    <div className="admin-graphs">
      <div id={barHColor}>
        {dayNo === "최근 90일" ? (
          <ApexChart
            series={[
              // {
              //   name: option === 1 ? "하루 섭취 열량" : "하루 섭취 단백질양",
              //   type: "line",
              //   data: data,
              // },
              // {
              // name: option === 1 ? "하루 섭취 열량" : "하루 섭취 단백질양",
              //   type: "line",
              //   data: nullData,
              // },
              {
                name: option === 1 ? "하루 필요 열량" : "하루 필요 단백질양",
                data: needData,
              },
              {
                name: option === 1 ? "하루 섭취 열량" : "하루 섭취 단백질양",
                data: data,
              },
            ]}
            options={{
              chart: {
                type: "line",
                selection: {
                  enabled: true,
                },
                toolbar: {
                  show: false,
                  autoSelected: undefined,
                },
                stacked: false,
              },
              colors:
                option === 1 ? ["#FFD8A8", "#FA8F69"] : ["#B3E6BC", "#61BE82"],
              //   type: "solid",
              // },
              stroke: {
                curve: "smooth",
                width: [4, 4],
              },
              // annotations: {
              //   position: "back",
              //   yaxis: [
              //     {
              //       y: 0,
              //       y2: areaNo?.toFixed(1),
              //       fillColor: option === 1 ? "#FFF4F0" : "#E3FCEB",
              //       opacity: 0.5,
              //       borderColor: "white",
              //     },
              //   ],
              // },

              dataLabels: {
                enabled: false,
                enabledOnSeries: [1], // series[1] 값만 데이터 레이블 출력

                dropShadow: {
                  enabled: true,
                  top: 1,
                  left: 1,
                  blur: 1,
                  color: "#000",
                  opacity: 0.45,
                },
                offsetY: -1,
                style: {
                  fontSize: "14px",
                  fontWeight: "600",
                  colors:
                    option === 1
                      ? ["#FFD8A8", "#FA8F69"]
                      : ["#B3E6BC", "#61BE82"],
                },
                formatter: function (val) {
                  return val !== 0
                    ? calorie
                      ? Math.ceil(val).toLocaleString("ko-KR")
                      : val?.toFixed(1).toLocaleString("ko-KR")
                    : " ";
                },
              },
              xaxis: {
                categories: yDate, //?.map((el, idx) => idx%10 === 0 ? el : ""),
                tickAmount: 5,
                tickPlacement: "on",
                labels: {
                  rotate: 0,
                  hideOverlappingLabels: true,
                },
                position: "bottom",
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
                // crosshairs: {
                //   //bar background
                //   show: true,
                //   position: "back",
                //   width: 1,
                //   stroke: {
                //     color: "#A39F9B",
                //     opacity: 1,
                //     width: 1,
                //     dashArray: 5,
                //   },
                // },
                tooltip: {
                  enabled: false,
                },
              },
              yaxis: {
                min: 0,
                max: areaOption ? undefined : areaTotal,
                tickAmount: 5,
                labels: {
                  show: true,
                  formatter: function (val) {
                    if (val <= 1) return Math.round(val * 5);
                    return Math.round(val).toLocaleString("ko-KR");
                  },
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
              },
              legend: {
                show: true,
                showForSingleSeries: true,
                markers: {
                  width: 24,
                  height: 12,
                  radius: 2,
                  // fillColors:
                  //   option === 1
                  //     ? ["#FFF4F0", "#FA8F69"]
                  //     : ["#E3FCEB", "#61BE82"],
                },
                customLegendItems:
                  option === 1
                    ? ["하루 필요 열량", "하루 섭취 열량"]
                    : ["하루 필요 단백질양", "하루 섭취 단백질양"],
                floating: false,
              },
              // tooltip: {
              //   enabled: true,
              //   custom: ({ series, seriesIndex, dataPointIndex }) =>
              //     customToolTip(
              //       series,
              //       seriesIndex,
              //       dataPointIndex,
              //       option === 1 ? "kcal" : "g",
              //       yDate
              //     ),
              // },
              grid: {
                padding: {
                  right: 5,
                  left: 15,
                },
              },
            }}
            type="line"
            height={350}
          />
        ) : (
          // * 기존 막대 그래프
          // <ApexChart
          // series={[
          //   { type: "column", data: nullData },
          //   {
          //     name: option === 1 ? "하루 섭취 열량" : "하루 섭취 단백질양",
          //     type: "column",
          //     data: data,
          //   },
          // ]}
          // options={{
          //   colors: option === 1 ? ["#FA8F69"] : ["#61BE82"],
          //   fill: {
          //     opacity: 1,
          //   },
          //   annotations: {
          //     position: "back",
          //     yaxis: [
          //       {
          //         y: 0,
          //         y2: areaTotal === 1 ? 0.8 : areaNo?.toFixed(0),
          //         fillColor: option === 1 ? "#FFF4F0" : "#E3FCEB",
          //         opacity: 0.5,
          //         borderColor: "white",
          //       },
          //     ],
          //   },
          //   chart: {
          //     selection: {
          //       enabled: false,
          //     },
          //     toolbar: {
          //       show: false,
          //       autoSelected: undefined,
          //     },
          //     stacked: true,
          //   },
          //   plotOptions: {
          //     bar: {
          //       columnWidth: dayNo === "최근 7일" ? "15%" : "50%",
          //       borderRadius: dayNo === "최근 7일" ? [7, 7] : [5, 5],
          //       dataLabels: {
          //         position: "top",
          //         hideOverflowingLabels: false,
          //       },
          //     },
          //   },
          //   dataLabels: {
          //     enabled: true,
          //     enabledOnSeries: [1],
          //     formatter: function (val) {
          //       return val !== null
          //         ? calorie
          //           ? val?.toFixed(0).toLocaleString("ko-KR")
          //           : val?.toFixed(1).toLocaleString("ko-KR")
          //         : "0";
          //     },
          //     offsetY: -20,
          //     style: {
          //       fontSize: "12px",
          //       colors: ["#746f6a"],
          //       fontWeight: "400",
          //     },
          //   },
          //   xaxis: {
          //     categories: yDate,
          //     position: "bottom",
          //     tickPlacement: "on",
          //     tickAmount: 7,
          //     labels: {
          //       rotate: 0,
          //       hideOverlappingLabels: true,
          //     },
          //     axisBorder: {
          //       show: false,
          //     },
          //     axisTicks: {
          //       show: false,
          //     },
          //     crosshairs: {
          //       //bar background
          //       show: true,
          //       position: "back",
          //       width: 1,

          //       stroke: {
          //         color: "#A39F9B",
          //         opacity: 1,
          //         width: 1,
          //         dashArray: 5,
          //       },
          //     },
          //     tooltip: {
          //       enable: false,
          //     },
          //   },

          //   yaxis: {
          //     max: areaOption ? undefined : areaTotal,
          //     tickAmount: 5,
          //     labels: {
          //       show: dayNo === "최근 30일",
          //       formatter: function (val) {
          //         if (val <= 1) return Math.round(val * 5);
          //         return Math.round(val).toLocaleString("ko-KR");
          //       },
          //     },
          //     axisBorder: {
          //       show: false,
          //     },
          //     axisTicks: {
          //       show: false,
          //     },
          //   },
          //   states: {
          //     hover: {
          //       filter: {
          //         type: "none",
          //       },
          //     },
          //     active: {
          //       allowMultipleDataPointsSelection: false,
          //       filter: {
          //         type: "none",
          //       },
          //     },
          //   },
          //   legend: {
          //     show: true,
          //     showForSingleSeries: true,
          //     markers: {
          //       width: 24,
          //       height: 12,
          //       radius: 2,
          //       fillColors:
          //         option === 1
          //           ? ["#FFF4F0", "#FA8F69"]
          //           : ["#E3FCEB", "#61BE82"],
          //     },
          //     customLeg//   tooltip: {
          //     enabled: true,
          //     enabledOnSeries: [1],
          //     custom: ({ series, seriesIndex, dataPointIndex }) =>
          //       customToolTip(
          //         series,
          //         seriesIndex,
          //         dataPointIndex,
          //         option === 1 ? "kcal" : "g",
          //         yDate
          //       ),
          //   },endItems:
          //       option === 1
          //         ? ["하루 필요 열량", "하루 섭취 열량"]
          //         : ["하루 필요 단백질양", "하루 섭취 단백질양"],
          //   },

          //   grid: {
          //     padding: {
          //       right: 0,
          //       left: dayNo === "최근 7일" ? 0 : 10,
          //     },
          //   },
          // }}
          // type="bar"
          // height={350}
          // />
          // * 여기부터 변경 코드
          <ApexChart
            series={[
              {
                name: option === 1 ? "하루 필요 열량" : "하루 필요 단백질양",
                data: needData,
              },
              {
                name: option === 1 ? "하루 섭취 열량" : "하루 섭취 단백질양",
                data: data,
              },
              //   { type: "column", data: nullData },
              //   {
              //     name: option === 1 ? "하루 섭취 열량" : "하루 섭취 단백질양",
              //     type: "column",
              //     data: data,
              //   },
            ]}
            options={{
              colors:
                option === 1 ? ["#FFD8A8", "#FA8F69"] : ["#B3E6BC", "#61BE82"],
              //   colors: option === 1 ? ["#FA8F69"] : ["#61BE82"],

              chart: {
                selection: {
                  enabled: true,
                },
                type: "line",

                // height: 350,
                // dropShadow: {
                //   enabled: true,
                //   color: "#000",
                //   top: 18,
                //   left: 7,
                //   blur: 10,
                //   opacity: 0.2,
                // },

                toolbar: {
                  show: false,
                  autoSelected: undefined,
                },
                stacked: false,
              },

              // annotations: {
              //   position: "back",
              //   yaxis: [
              //     {
              //       y: 0,
              //       y2: areaTotal === 1 ? 0.8 : areaNo?.toFixed(0),
              //       fillColor: option === 1 ? "#FFF4F0" : "#E3FCEB",
              //       opacity: 0.5,
              //       borderColor: "white",
              //     },
              //   ],
              // },

              dataLabels: {
                enabled: true, // 데이터 레이블 표시
                enabledOnSeries: [1], // series[1] 값만 데이터 레이블 출력
                // background: false,
                dropShadow: {
                  enabled: true,
                  top: 1,
                  left: 1,
                  blur: 1,
                  color: "#000",
                  opacity: 1.45,
                },
                offsetY: -1,
                style: {
                  fontSize: "12px",
                  fontWeight: "600",
                  colors:
                    option === 1
                      ? ["#FFD8A8", "#FA8F69"]
                      : ["#B3E6BC", "#61BE82"],
                },
                formatter: function (val) {
                  return val !== 0
                    ? calorie
                      ? Math.ceil(val).toLocaleString("ko-KR")
                      : val?.toFixed(1).toLocaleString("ko-KR")
                    : "";
                },
              },
              stroke: {
                curve: "smooth",
                width: [5, 5],
              },

              grid: {
                borderColor: "#e7e7e7",
                row: {
                  colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                  opacity: 0.5,
                },
                padding: {
                  right: 0,
                  left: dayNo === "최근 7일" ? 0 : 10,
                },
              },

              xaxis: {
                categories: yDate,

                position: "bottom",
                labels: {
                  rotate: 0,
                  hideOverlappingLabels: true,
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
                tooltip: {
                  enable: false,
                },
              },
              yaxis: {
                // title: {
                //   text: "Temperature",
                // },
                // min: 5,
                // max: 50,
                tickAmount: 5,
                labels: {
                  show: dayNo === "최근 30일",
                  formatter: function (val) {
                    if (val <= 1) return Math.round(val * 5);
                    return Math.round(val).toLocaleString("ko-KR");
                  },
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
                max: areaOption ? undefined : areaTotal,
              },

              legend: {
                show: true,
                showForSingleSeries: true,
                markers: {
                  size: 1,
                  width: 24,
                  height: 12,
                  radius: 2,
                  // fillColors:
                  //   option === 1
                  //     ? ["#FFF4F0", "#FA8F69"]
                  //     : ["#E3FCEB", "#61BE82"],
                },
                position: "bottom",
                // horizontalAlign: "right",
                customLegendItems:
                  option === 1
                    ? ["하루 필요 열량", "하루 섭취 열량"]
                    : ["하루 필요 단백질양", "하루 섭취 단백질양"],

                floating: false,
              },
              // tooltip: {
              //   enabled: true,
              //   // enabledOnSeries: [1],
              //   custom: ({ series, seriesIndex, dataPointIndex }) =>
              //     customToolTip(
              //       series,
              //       seriesIndex,
              //       dataPointIndex
              //       option === 1 ? "kcal" : "g",
              //       yDate
              //     ),
              // },
              grid: {
                padding: {
                  right: 5,
                  left: 10,
                },
              },
            }}
            type="line"
            height={350}
          />
        )}
      </div>
    </div>
  );
};

export default CalorieProteinGraph;
