import React from "react";
import HeadCont from "../../components/common/HeadCont";
import { useSelector } from "react-redux";
import InfoContainer from "../../components/mypage/InfoContainer";
import useInfo from "../../hooks/useInfo";

const Bookmark = () => {
  const { loginInfo, navTab } = useSelector((state) => state.commonApp);

  const infoParams = useInfo("myPage", loginInfo, "북마크");

  return (
    <>
      <HeadCont />
      <div className="mt-14" />
      <h1 className="mx-4 mb-2.5 mt-2 text-subtitle1">북마크</h1>

      {/* Post Container from MyPage (Different from Post Container from Community) */}

      <InfoContainer infoParams={infoParams} loginInfo={loginInfo} />
    </>
  );
};

export default Bookmark;
