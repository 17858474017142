import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const searchWeeklyMeal = createAsyncThunk(
  "meal/searchWeeklyMeal",
  async (params) => {
    const res = await api.post(`/eat/searchWeek`, params);
    return res.data;
  }
);

export const searchForFood = createAsyncThunk(
  "meal/searchForFood", //키워드 음식 검색
  async (params) => {
    const res = await api.post(`/food/search`, params);
    return res;
  }
);

export const searchForRecentFood = createAsyncThunk(
  "meal/searchForRecentFood", //최근 입력한 음식
  async (params) => {
    const res = await api.post(`/food/recentFoodsearch`, params);
    return res.data;
  }
);

export const addOrEditFood = createAsyncThunk(
  "meal/addOrEditFood", //식사 입력
  async (params) => {
    const res = await api.post(`/eat/addOrEditFood`, params);
    return res.data;
  }
);

export const deleteFood = createAsyncThunk(
  "meal/deleteFood", //식사 삭제
  async (params) => {
    const res = await api.post(`/eat/deleteFood`, params);
    return res.data;
  }
);

export const fetchMealAnalysis = createAsyncThunk(
  "meal/fetchMealAnalysis", //식사 분석-영양소
  async (params) => {
    const res = await api.post(`/eat/eatReport`, params);
    return res.data;
  }
);

export const fetchMealStatistics = createAsyncThunk(
  "meal/fetchMealStatistics", //식사 분석-통계
  async (params) => {
    const res = await api.post(`/eat/eatReportStatistics`, params);
    return res.data;
  }
);

export const fetchExistDatasOfYear = createAsyncThunk(
  "meal/fetchExistDatasOfYear",
  async (params) => {
    const res = await api.post(`/user/getExistEatDataDayOfYear`, params);
    if (res.data.ok) {
      return res.data.dayList;
    }
  }
);

export const saveRequestToAddFood = createAsyncThunk(
  "addMeal/saveRequestToAddFood", //음식 등록 요청
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formdata = new FormData();
    formdata.append("userId", params.userId);
    formdata.append("title", params.title);
    formdata.append("content", params.content);
    params.files.forEach((file) => formdata.append("files", file));
    const res = await api.post(
      `/food/foodRegistrationRequest`,
      formdata,
      config
    );
    return res.data;
  }
);

const initialState = {
  weeklyMealStatus: null,
  weeklyMealLists: [],
  searchedFoodStatus: null,
  recentFoodLists: [],
  analysisLists: [],
  chosenDateOfAnalysis: null,
  statisticsStatus: null,
  statisticsLists: null,
  existDaysOfYear: [],
  scrollNum: null,
};

const mealSlice = createSlice({
  name: "식사기록",
  initialState,
  reducers: {
    onSetChosenDate: (state, action) => {
      state.chosenDateOfAnalysis = action.payload;
    },
    onResetChosenDate: (state) => {
      state.chosenDateOfAnalysis = null;
    },
    onSetScrollNum: (state, action) => {
      state.scrollNum = action.payload;
    },
    onResetScrollNum: (state, action) => {
      state.scrollNum = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(searchWeeklyMeal.pending, (state) => {
      state.weeklyMealStatus = "pending";
    });
    builder.addCase(searchWeeklyMeal.fulfilled, (state, action) => {
      state.weeklyMealStatus = "success";
      state.weeklyMealLists = action.payload.eatDayList;
    });
    builder.addCase(searchWeeklyMeal.rejected, (state) => {
      state.weeklyMealStatus = "rejected";
    });
    builder.addCase(searchForFood.pending, (state) => {
      state.searchedFoodStatus = "pending";
    });
    builder.addCase(searchForFood.fulfilled, (state) => {
      state.searchedFoodStatus = "success";
    });
    builder.addCase(searchForFood.rejected, (state) => {
      state.searchedFoodStatus = "rejected";
    });
    builder.addCase(searchForRecentFood.fulfilled, (state, action) => {
      state.recentFoodLists = action.payload.foodList;
    });
    builder.addCase(fetchMealAnalysis.fulfilled, (state, action) => {
      state.analysisLists = action.payload;
    });
    builder.addCase(fetchMealStatistics.pending, (state) => {
      state.statisticsStatus = "pending";
    });
    builder.addCase(fetchMealStatistics.fulfilled, (state, action) => {
      state.statisticsStatus = "success";
      state.statisticsLists = action.payload;
    });
    builder.addCase(fetchMealStatistics.rejected, (state) => {
      state.statisticsStatus = "rejected";
    });
    builder.addCase(fetchExistDatasOfYear.fulfilled, (state, action) => {
      state.existDaysOfYear = action.payload;
    });
  },

  // {
  //   [searchWeeklyMeal.pending]: (state) => {
  //     state.weeklyMealStatus = 'pending';
  //   },
  //   [searchWeeklyMeal.fulfilled]: (state, action) => {
  //     state.weeklyMealStatus = 'success';
  //     state.weeklyMealLists = action.payload.eatDayList;
  //   },
  //   [searchWeeklyMeal.rejected]: (state) => {
  //     state.weeklyMealStatus = 'rejected';
  //   },
  //   [searchForFood.pending]: (state) => {
  //     state.searchedFoodStatus = 'pending';
  //   },
  //   [searchForFood.fulfilled]: (state) => {
  //     state.searchedFoodStatus = 'success';
  //   },
  //   [searchForFood.rejected]: (state) => {
  //     state.searchedFoodStatus = 'rejected';
  //   },
  //   [searchForRecentFood.fulfilled]: (state, action) => {
  //     state.recentFoodLists = action.payload.foodList;
  //   },
  //   [fetchMealAnalysis.fulfilled]: (state, action) => {
  //     state.analysisLists = action.payload;
  //   },
  //   [fetchMealStatistics.pending]: (state) => {
  //     state.statisticsStatus = 'pending';
  //   },
  //   [fetchMealStatistics.fulfilled]: (state, action) => {
  //     state.statisticsStatus = 'success';
  //     state.statisticsLists = action.payload;
  //   },
  //   [fetchMealStatistics.rejected]: (state) => {
  //     state.statisticsStatus = 'rejected';
  //   },
  //   [fetchExistDatasOfYear.fulfilled]: (state, action) => {
  //     state.existDaysOfYear = [...state.existDaysOfYear, ...action.payload];
  //   },
  // },
});

export const {
  onSetChosenDate,
  onResetChosenDate,
  onSetScrollNum,
  onResetScrollNum,
} = mealSlice.actions;

export default mealSlice.reducer;
