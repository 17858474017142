import CustomCenterModal from "../../common/CustomCenterModal";

const ContentsModal = ({ tabName,  children, setEditModal,  setNewModal,subTab}) => {
  const closeModal = () =>{
    setEditModal(false); setNewModal(false)
  }
  return (
    <CustomCenterModal
    onClickFc={closeModal}
    customlayout={"absolute top-1/2 left-1/2 transform-50 bg-gray_10 rounded-xl z-30 min-w-[680px] shadow-md ]"}
    >       
      <div className='flex items-center justify-between p-[8px]  border-b border-gray_80'>
      <div className='flex text-lg font-bold pl-[16px]'>
        <span>
          {tabName}
        </span>
        {subTab !==undefined &&
          <img src={"/images/admin/icon/rightArrow.svg"} alt="rightarrow" className='w-[24px]' />
        }
        <span>{subTab}</span>
      </div>
      <img src={"/images/admin/icon/close.svg"} alt="close"
        className='cursor-pointer'
        onClick={closeModal} />
      </div>
      <div className='px-8 py-6 w-full'>
        {children}
      </div>
    </CustomCenterModal>
);
};
export default ContentsModal;
