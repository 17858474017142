import axios from "axios";

export default axios.create({ baseURL: " https://back3.healiary.com/" }); //라이브 서버
// export default axios.create({ baseURL: "https://backdev.healiary.com/" }); //dev 서버

// export default axios.create({ baseURL: "http://192.168.0.68:4002/" }); //모스코님
// export default axios.create({ baseURL: "http://192.168.0.95:4002/" }); // 산토리니님
//export default axios.create({ secondURL: "http://3.34.219.128:4001" }) //원래 서버
// export default axios.create({ baseURL: "http://intentionalErro:4001/" }); //모스코님

export const common = {
  baseURL: "https://back3.healiary.com/",
  // baseURL: "https://backdev.healiary.com/", // dev 서버
  // baseURL: "http://192.168.0.68:4002/", //모스코님
  // baseURL: "http://192.168.0.95:4002/", // 산토리니님
  // baseURL: "http://3.34.219.128:4001/"
  // baseURL:"http://localhost:4001/",
  // baseURL: "http://intentionalErro:4001/", //모스코님
};
