import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../common/api";

export const dupIdCheck = createAsyncThunk(
  "accout/dupIdCheck",
  async (param) => {
    const res = await api.get(`/admin/dupIdCheck/${param}`);
    return res;
  }
);

export const addUserMemo = createAsyncThunk(
  "account/addUserMemo",
  async (param) => {
    const res = await api.post("/user/memo/addUserMemo", param);
    return res;
  }
);

export const getUserMemo = createAsyncThunk(
  "account/getUserMemo",
  async (param) => {
    const res = await api.post("/user/memo/getUserMemo", param);
    return res;
  }
);

export const deleteUserMemo = createAsyncThunk(
  "account/deleteUserMemo",
  async (param) => {
    const res = await api.post("/user/memo/deleteUserMemo", param);
    return res;
  }
);

export const fetchUserInfo = createAsyncThunk(
  "/account/fetchMyInfo",
  async (params) => {
    const res = await api.get(`/user/myInfo/${params}`);
    return res;
  }
);


const initialState = {
  i: 0,
  status: null,
  totalPage: 0,
  memoList: [],
  clickAccountTab: "",
  accountInfo:[]
  // loginInfo:{}
};
const accountSlice = createSlice({
  name: "accountadmin",
  initialState,
  reducers: {
    onSetAccountTab: (state, action) => {
      state.clickAccountTab = action.payload;
    },
    onSetAccountOneInfo: (state, action) =>{
      state.accountInfo = action.payload
    }
  },
  extraReducers: {
    [getUserMemo.fulfilled]: (state, action) => {
      state.status = "success";
      state.totalPage = action.payload.data.totalPage;
      state.memoList = action.payload.data.memoList;
    },
  },
});

export const { onSetAccountTab,onSetAccountOneInfo } = accountSlice.actions;

export default accountSlice.reducer;
