import React from "react";

const PhotoCard = ({ onAddThumbnail, option }) => { // option === true ? only single pictures : multiple plictures
    return (
        <>
            {option ? (
                <input
                    name="camera"
                    type="file"
                    id="camera-image"
                    accept="image/*"
                    onChange={(e) => onAddThumbnail(e)}
                    className="hidden"
                />
            ) : (
                <input
                    name="camera"
                    type="file"
                    multiple
                    id="camera-image"
                    accept="image/*"
                    onChange={(e) => onAddThumbnail(e)}
                    className="hidden"
                />
            )}

            <label htmlFor="camera-image" className="w-cursor-pointer">
                <span className="bg-gray_10 inline-block border border-gray_90 p-[20px] rounded-md">
                    <img
                        src="/images/mobile/icon/camera.svg"
                        alt="카메라 아이콘"
                    />
                </span>
            </label>
        </>
    );
};

export default PhotoCard;
