import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";

const SignUpFail = () => {
    const { type } = useParams() //type 1, 2는 버전 1의 휴대폰 패스인증시 에러이므로 버전2에서 안씀. 3만 사용
    const navigate = useNavigate()

    return (
        <>
            {type === '3' ?
                <motion.div className='h-screen flex flex-col justify-center items-center px-8 py-4'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 1 }}>
                    <img src='/images/mobile/image/lock.svg' alt='자물쇠 이미지' className='flex flex-col justify-center items-center mx-auto' />
                    <p className='text-subtitle_1 font-bold text-gray_160 mt-6 mb-24'>인증 실패!<br />
                    휴대폰 번호가 없는 계정입니다.<br /> 다른 수단으로 회원가입을 시도해주세요☺️</p>
                    <p onClick={() => navigate(`/sign`)} className='flex justify-center items-center gap-2 text-caption_1 font-semibold text-gray_160 mt-4'>
                        <span>처음으로 돌아가기</span>
                        <img src='/images/mobile/icon/arrow_right.svg' alt='화살표 아이콘' className='w-3 h-3' />
                    </p>
                </motion.div>
                : <motion.div className='h-screen flex flex-col justify-center items-center px-8 py-4'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 1 }}>
                    <img src='/images/mobile/image/lock.svg' alt='자물쇠 이미지' className='flex flex-col justify-center items-center mx-auto' />
                    <p className='text-subtitle_1 font-bold text-gray_160 mt-6 mb-24'>이미 가입된 회원입니다.😅<br />로그인 해주세요.</p>
                    <p onClick={() => navigate(`/sign`)} className='flex justify-center items-center gap-2 text-caption_1 font-semibold text-gray_160 mt-4'>
                        <span>처음으로 돌아가기</span>
                        <img src='/images/mobile/icon/arrow_right.svg' alt='화살표 아이콘' className='w-3 h-3' />
                    </p>
                </motion.div>}
        </>

    );
};

export default SignUpFail;