import React from 'react'

const PhotoCompRemain = ({editData, setEditData,loadedImage, setLoadedImage}) => {

  const deleteRemainImage = (image,no) => {
    setLoadedImage([...loadedImage?.filter((el) => el !== image)]);
    const newEditData =  [...editData.remainingFiles]
    newEditData.splice(no, 1) //클릭한 사진 제거

    setEditData({ ...editData, remainingFiles: newEditData });
  };
  return (
    <>
      {editData?.remainingFiles &&
        editData?.remainingFiles?.map((el, idx) => (
          <div
            key={idx}
            className={"w-[80px] h-[80px] relative"} 
          >
            <img
              key={idx}
              src={el}
              alt={`image${idx}`}
              className="w-full h-full rounded"
            />
            <img
              src="/images/admin/icon/close_circle.svg"
              alt="닫기 아이콘"
              className="w-[17px] h-[17px] absolute top-1 right-1 cursor-pointer"
              onClick={() => deleteRemainImage(el, idx)}
            />
          </div>
      ))}
    </>
  )
}

export default PhotoCompRemain