import React, { useEffect, useState } from "react";
import Sidebar from "../components/common/Sidebar";
import Navbar from "../components/common/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { funnelsHeadList } from "../components/common/TableHeadList";
import dayjs from "dayjs";
import { fetchRegistSurveyRank } from "../../../features/admin/mainSlice";
import { useNavigate } from "react-router-dom";
import api from "../../../common/api";

const Funnels = () => {
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const mainInfo = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [range, setRange] = useState({
    start: dayjs(new Date()).subtract(6, "days").format("YYYY-MM-DD"),
    end: dayjs(new Date()).format("YYYY-MM-DD"),
  });
  // const [totalCount, setTotalCount] = useState(0);
  // const [rankData, setRankData] = useState([]);

  const changeRange = (e, type) => {
    if (type === "start") {
      setRange({ ...range, start: e.target.value });
    } else {
      setRange({ ...range, end: e.target.value });
    }
  };

  useEffect(() => {
    if (loginInfo?.adminId) {
      dispatch(
        fetchRegistSurveyRank({ startDate: range.start, endDate: range.end })
      );
    } else {
      navigate("/admin/signin");
    }
  }, [range, dispatch, loginInfo.adminId]);

  console.log(mainInfo);

  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-hidden">
        <Navbar user={loginInfo} />
        <div className="flex h-full w-auto min-w-min flex-col items-start justify-start gap-[16px] p-[24px]">
          <div className="mb-6 flex max-h-[90%] w-full flex-col items-start rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
            <div className="flex h-full w-full flex-col items-start gap-[16px]">
              <div>유입경로 순위</div>
              <div className="flex w-full items-center justify-between gap-2">
                <div className="flex items-center gap-1">
                  <span className="mr-5 text-gray_100">기간 설정</span>
                  <input
                    type="date"
                    className="text-body_2 rounded-[4px] border border-gray_100 bg-transparent px-3 py-2 text-black focus:outline-none"
                    value={range.start}
                    onChange={(e) => changeRange(e, "start")}
                    max={range.end}
                  />{" "}
                  ~
                  <input
                    type="date"
                    className="text-body_2 rounded-[4px] border border-gray_100 bg-transparent px-3 py-2 text-black focus:outline-none"
                    value={range.end}
                    onChange={(e) => changeRange(e, "end")}
                    min={range.start}
                    max={dayjs(new Date()).format("YYYY-MM-DD")}
                  />
                </div>

                <div className="mr-3">
                  해당 기간 가입자 : 총 {mainInfo?.totalSurveyRankCount} 명
                </div>
              </div>
              <div className="h-full w-full overflow-auto rounded-lg border">
                <table className="w-full " cellSpacing={0}>
                  <thead>
                    <tr className="rounded-t-lg font-semibold text-black">
                      {funnelsHeadList.map((head, idx) =>
                        head.label === "위" ? (
                          Array.from({ length: 9 }, (v, i) => i + 1).map(
                            (v, i) => (
                              <th
                                key={head.label + i}
                                className={
                                  "sticky top-0 bg-gray_60 p-4 first:rounded-tl-lg last:rounded-tr-lg"
                                }
                              >
                                {v + head.label}
                              </th>
                            )
                          )
                        ) : (
                          <th
                            key={head.label + idx}
                            className={
                              "sticky top-0 bg-gray_60 p-4 first:rounded-tl-lg last:rounded-tr-lg"
                            }
                          >
                            {head.label}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {mainInfo?.registSurveyRankDetail?.map((data, i) => {
                      return (
                        <tr key={data.day} className="text-center">
                          <td className="py-4">{data?.day}</td>
                          {Array.from({ length: 9 }, (_, i) => i).map((i) => {
                            return (
                              <td key={data.day + i}>
                                {data.registSurveyRank[i]
                                  ? `${data.registSurveyRank[i]?.answer} (${data.registSurveyRank[i]?.count})`
                                  : "-"}
                              </td>
                            );
                          })}
                          <td>{data?.count}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Funnels;
