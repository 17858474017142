import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handlePageChange,
  onSetSection,
  onSetSubSection,
} from "../../../../features/admin/commonSlice";
import {
  getFoodRegistrationRequest,
  getNotice,
  onSetCommTab,
  onSetToggleCommunity,
  searchCancerGPT,
  searchCustomerCenter,
  searchInformation,
  searchManagementCommunity,
} from "../../../../features/admin/communitySlice";
import { toggleBoardModal } from "../../../../features/admin/toggleSlice";

const CommuTabs = ({ COMMUTABS }) => {
  const dispatch = useDispatch();
  const { pagePerList } = useSelector((state) => state.pagination);
  const commuTabName = useSelector((state) => state.commu.clickCommuTab);
  return (
    <>
      {COMMUTABS?.map((tab, idx) => (
        <div
          key={idx}
          onClick={() => {
            dispatch(onSetCommTab(tab));
            dispatch(handlePageChange(1));
            dispatch(onSetToggleCommunity(false)); //toggle off
            dispatch(onSetSection("게시판 관리"));
            dispatch(onSetSubSection(tab));
            dispatch(toggleBoardModal(false)); //eachmodal off
            // let param = {
            //   page: 1,
            //   countPerPage: pagePerList,
            //   title: "",
            //   content: "",
            // }
            // let params1 = Object.assign(param,{writerUserId: ""})
            // let params2 = Object.assign(param,{userId: "",isAwaiting:false})
            // let params3 = Object.assign(param,{writerUserId: "",category:""})
            // let params4 = {
            //   page: 1,
            //   countPerPage: pagePerList,
            //   userId: '',
            //   question: '',
            //   searchinput: '',
            //   answer: '',
            //   category: ''
            // }

            // if (tab === "소식") {
            //   dispatch(searchManagementCommunity(params1));
            // } else if(tab ==="1:1 이용문의") {
            //   dispatch(searchCustomerCenter(params2));
            // } else if(tab ==="정보"){
            //   dispatch(searchInformation(params3))
            // }else if(tab ==="음식DB요청"){
            //   dispatch(getFoodRegistrationRequest())
            // }else if(tab === "CancerGPT"){
            //   dispatch(searchCancerGPT(params4))
            // }else{
            //   // dispatch(getNotice())
            // }
          }}
          className={
            "h-15 w-32 cursor-pointer rounded-t-2xl py-4 text-center text-body3_600 " +
            (commuTabName === tab
              ? "bg-white text-black "
              : "bg-gray_30 text-gray_140 ")
          }
        >
          {tab}
        </div>
      ))}
    </>
  );
};

export default CommuTabs;
