import React from "react";

const UserMessage = ({ characterPhoto, message }) => {
  return (
    <>
      <div className="flex mx-6 mt-14">
        <div className="grow h-min bg-green_20 rounded-2xl mr-[-3px] p-4">
          <span className="text-left break-all text-body2_500l text-gray_180 ">
            {message}
          </span>
        </div>
        <div className="pt-2 pr-4 shrink-0">
          <img
            src={"/images/mobile/icon/chat_wing_right.svg"}
            alt="Chat Right Wing"
          />
        </div>
        <div className={`shrink-0 `}>
          <img
            src={`/images/mobile/character/` + characterPhoto + `.svg`}
            alt="User 챗봇"
            className="scale-x-[-1]"
          />
        </div>
      </div>
    </>
  );
};

export default UserMessage;
