import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addINfoCOntentImage,
  addInfoPosting,
  editInfoPosting,
} from "../../../../features/admin/communitySlice";
import CustomerCenterDetailForm from "../../components/board/CustomerCenterDetailForm";
import { BGButtonCustom } from "../../components/common/ButtonCustom";
import PhotoComp from "../../components/common/PhotoComp";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import { useNavigate } from "react-router-dom";
import { resizeFile } from "../../../../common/util/base";
import {
  SearchTargetOption,
  암종,
} from "../../components/management/SearchTargetOption";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextEditorToolbar, {
  formats,
  modules,
} from "../../components/board/TextEditorToolbar";

export const ReapetLabel = ({ title, children }) => {
  return (
    <div
      className={
        "flex  w-full" +
        (title === "암종" || title === "내용"
          ? " items-start "
          : " items-center")
      }
    >
      <p className="flex w-[70px] text-[#463F38]">{title}</p>
      {children}
    </div>
  );
};

const InformationPosting = () => {
  const dispatch = useDispatch();
  const { postingIdList, isInInfoId } = useSelector((state) => state.commu);
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const [editorPost, setEditorPost] = useState(
    isInInfoId === null
      ? {
          category: "",
          title: "",
          thumbnail: [],
          content: "",
          infoCancerTypes: [],
          writerAdminAdminId: loginInfo.adminId,
        }
      : {
          category: postingIdList.category,
          title: postingIdList.title,
          thumbnail: postingIdList.thumbnail,
          content: postingIdList.content,
          infoCancerTypes: postingIdList.infoCancerTypes,
          writerAdminAdminId: postingIdList.writerAdminAdminId,
        }
  );
  const [wantNewthumnail, setwantNewthumnail] = useState(false);
  const blankall =
    editorPost.category === "" ||
    editorPost.title === "" ||
    editorPost.thumbnail?.length === 0 ||
    editorPost.content === "" ||
    editorPost.content === "<p><br></p>";
  const [loadedImage, setLoadedImage] = useState([]);
  const select_list2 = [
    "카테고리 전체",
    "암과 생활",
    "암종별 정보",
    "항암치료 관리",
    "국가 지원제도",
  ];
  const editorRef = useRef();
  const navigate = useNavigate();
  const alarmRef = useRef(false);
  const isHideRef = useRef(false);

  const handleClickContent = async () => {
    const ifEditTrue = isInInfoId !== null;
    const refParam = {
      isHide: isHideRef.current.checked,
      alarmRef: alarmRef.current.checked,
    };

    const editorPostPlus = Object.assign(editorPost, refParam);

    const res = ifEditTrue
      ? await dispatch(
          editInfoPosting({ ...editorPostPlus, id: isInInfoId.id })
        ).unwrap()
      : await dispatch(addInfoPosting(editorPostPlus)).unwrap();
    try {
      if (res.data.ok) {
        const successMsg = ifEditTrue
          ? "게시글 수정이 완료되었습니다."
          : "게시글 작성이 완료되었습니다.";
        toast(
          <p>{successMsg}</p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
        navigate("/admin/board");
      }
    } catch (error) {
      toast(<p>{res.error}</p>, toastCommonProps("top-right", "toast_alert"));
    }
  };

  const deleteImage = (image) => {
    setLoadedImage([...loadedImage?.filter((el) => el !== image)]);
    setEditorPost({ ...editorPost, thumbnail: [] });
  };

  const onChangeFile = async (e) => {
    let fileUrls = [];
    let cameraArr = [];
    for (let i = 0; i < e.target.files?.length; i++) {
      let reader = new FileReader();
      let resize = await resizeFile(e.target.files[i]);
      reader.readAsDataURL(resize);
      cameraArr.push(resize);
      setEditorPost({
        ...editorPost,
        thumbnail: [...cameraArr],
      });
      reader.onload = async () => {
        fileUrls[i] = reader.result;
        loadedImage?.length > 0
          ? setLoadedImage(loadedImage.concat([...fileUrls]))
          : setLoadedImage([...fileUrls]);
      };
    }
    e.target.value = "";
  };
  const onChangeOption = (e) => {
    const { name, value } = e.target;
    setEditorPost({ ...editorPost, [name]: value?.slice(0, 20) });
  };
  const checkboxList = (title, inputName, refName, onClickFunciton, label) => {
    return (
      <ReapetLabel title={title}>
        <div className="flex items-center w-full ">
          <input
            name={inputName}
            ref={refName}
            onClick={onClickFunciton}
            type="checkbox"
            className=" m-[6px] h-[18px] w-[18px]"
          />
          <span className="flex h-[24px] flex-row items-center justify-center rounded-[2px]  py-[2px] text-base">
            {label}
          </span>
        </div>
      </ReapetLabel>
    );
  };

  const checkAlarm = () => {
    if (isHideRef.current.checked) {
      alarmRef.current.checked = false;
    }
    setEditorPost({ ...editorPost, sendingAlarm: alarmRef.current.checked });
  };
  const checkIsHide = () => {
    alarmRef.current.checked = false;
    setEditorPost({ ...editorPost, isHide: isHideRef.current.checked });
  };

  const onClickCheckboxFilter = (e) => {
    const opt = SearchTargetOption[e.target.id];
    const deleteAndSet = editorPost.infoCancerTypes?.filter((el) => el !== opt);
    const addSet = editorPost.infoCancerTypes?.concat([opt]);
    if (editorPost.infoCancerTypes?.includes(opt)) {
      setEditorPost({ ...editorPost, infoCancerTypes: deleteAndSet });
    } else {
      console.log("here");
      setEditorPost({ ...editorPost, infoCancerTypes: addSet });
    }
  };

  const filterFn = (type) => {
    return (
      <div className="flex flex-wrap pl-6">
        {type?.map((item, idx) => {
          return (
            <div
              key={idx}
              className={`managementCheck mx-2 my-1 flex items-center justify-center text-sm`}
            >
              <input
                type="checkbox"
                id={item}
                className="checkbox"
                onClick={(e) => {
                  onClickCheckboxFilter(e);
                }}
              />
              <label
                htmlFor={item}
                className={`relative block h-[36px] w-max cursor-pointer rounded-[20px] bg-gray_20 px-4 pt-2 text-center text-caption1_400 text-sm hover:shadow`}
              >
                {item}
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (isInInfoId && postingIdList) {
      // console.log({ ...postingIdList });
      // setEditorPost({
      //   ...editorPost,
      //   thumbnail: postingIdList.thumbnail,
      //   category: postingIdList.category,
      //   title: postingIdList.title,
      //   infoCancerTypes:
      //     postingIdList.infoCancerTypes === null
      //       ? []
      //       : postingIdList?.infoCancerTypes,
      //   content: postingIdList.content,
      // });
      // editorPost.thumbnail = postingIdList?.thumbnail;
      // editorPost.category = postingIdList?.category;
      // editorPost.title = postingIdList?.title;
      // editorPost.infoCancerTypes =
      //   postingIdList?.infoCancerTypes === null
      //     ? []
      //     : postingIdList?.infoCancerTypes;
      const allCancerButton = document.querySelectorAll(
        ".managementCheck input"
      );
      allCancerButton.forEach((el) => {
        if (editorPost.infoCancerTypes?.includes(SearchTargetOption[el.id])) {
          el.checked = true;
        }
      });
      // const whenEnterPlusBr = postingIdList?.content.replaceAll(
      //   '"false"><br>',
      //   "&nbsp;"
      // );

      // // editorRef?.current?.getInstance().setHTML(whenEnterPlusBr);
      // editorPost.content = whenEnterPlusBr;
      isHideRef.current.checked = postingIdList?.isHide;
      alarmRef.current.checked = postingIdList?.sendingAlarm;
    }
  }, []);
  console.log(postingIdList);
  return (
    <CustomerCenterDetailForm title={"게시글 작성"}>
      <div className="flex flex-col ">
        <div className="flex w-[480px] flex-col gap-y-6 bg-white p-6">
          <ReapetLabel title="카테고리">
            <div className="box-border h-[48px] w-full cursor-pointer rounded-[4px] border border-gray_100 bg-[#FCFCFC] p-[12px]">
              <select
                className="w-full cursor-pointer bg-[#FCFCFC] text-gray_100 outline-none  focus:text-black"
                name="category"
                id="category"
                onChange={onChangeOption}
                value={editorPost?.category}
              >
                {select_list2?.map((e, idx) => (
                  <option key={idx} value={e}>
                    {e}
                  </option>
                ))}
              </select>
            </div>
          </ReapetLabel>
          <ReapetLabel title="제목">
            <input
              name="title"
              onChange={onChangeOption}
              value={editorPost?.title}
              placeholder="제목을 입력하세요. (최대 20자)"
              className="w-full rounded-[4px] border border-[#A39F9B] bg-transparent px-[20px] py-[12px] outline-none "
            />
          </ReapetLabel>
          <ReapetLabel title="암종">{filterFn(암종)}</ReapetLabel>
          <ReapetLabel title="썸네일">
            {isInInfoId && !wantNewthumnail ? (
              <div className="relative h-[80px] w-[80px] overflow-hidden rounded">
                <img
                  src={editorPost?.thumbnail}
                  className="w-full"
                  alt="infoThumbnail"
                />
                <img
                  src="/images/admin/icon/close_circle.svg"
                  alt="닫기 아이콘"
                  className="absolute right-1 top-1 h-[17px] w-[17px] cursor-pointer"
                  onClick={() => {
                    setEditorPost({ ...editorPost, thumbnail: [] });
                    setwantNewthumnail(true);
                  }}
                />
              </div>
            ) : (
              <PhotoComp
                onChangeFile={onChangeFile}
                deleteImage={deleteImage}
                loadedImage={loadedImage}
                option={1}
              />
            )}
          </ReapetLabel>
          <ReapetLabel title="내용">
            <div className="mb-20 h-[400px] w-[360px]" id="">
              <TextEditorToolbar />
              <ReactQuill
                className="w-full h-full"
                modules={modules}
                formats={formats}
                placeholder={"작성해주세요"}
                value={editorPost?.content}
                onChange={(content, delta, source, editor) => {
                  setEditorPost({ ...editorPost, content: editor.getHTML() });
                }}
                // hooks={{
                //   addImageBlobHook: async (blob, callback) => {
                //     const res = await dispatch(
                //       addINfoCOntentImage({ contentFile: blob })
                //     ).unwrap();
                //     if (res.data.ok) {
                //       callback(res.data.path, "informationImg");
                //     }
                //   },
                // }}
                ref={editorRef}
              />
            </div>
          </ReapetLabel>
          {checkboxList(
            "공개여부",
            "isHide",
            isHideRef,
            checkIsHide,
            "게시글 비공개로 전환"
          )}
          {checkboxList(
            "알림 여부",
            "alarm",
            alarmRef,
            checkAlarm,
            "유저에게 알림보내기(비공개 해제시 선택가능)"
          )}
          <div className="flex justify-end w-full">
            <BGButtonCustom
              onClick={blankall ? undefined : handleClickContent}
              content={"저장"}
              bgcolor={blankall ? "bg-orange_80" : "bg-orange_100"}
              textcolor={"text-gray_10"}
              textsize={"text-body_2"}
              xpadding={"12px"}
            />
          </div>
        </div>
      </div>
    </CustomerCenterDetailForm>
  );
};

export default InformationPosting;
