import LineChart from "../components/common/LineChart";
import Indicator from "../components/common/Indicator";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

export function WeightSwipe({ currentIndex, totalScreens, weight, weights }) {
  const navigate = useNavigate();
  const { questionsCompleteCheck } = useSelector((state) => state.others);
  const { chosenDate } = useSelector((state) => state.commonApp);
  const gap = (
    weights?.[weights?.length - 1] - weights?.[weights?.length - 2]
  ).toFixed(1);

  let data = [];
  for (let _weight of weights) {
    if (_weight) {
      data.push(_weight);
    } else {
      data.push(weight);
    }
  }

  return (
    <div
      className="flex w-full items-start gap-2.5"
      onClick={() => {
        if (questionsCompleteCheck?.healthComplete) {
          navigate("/weight/record");
        } else {
          navigate("/question/health");
        }
      }}
    >
      <div className="flex h-[218px] w-full flex-col items-start gap-9 rounded-xl  bg-white px-4 pt-2.5">
        <div className="flex flex-col items-start gap-0">
          <div className="flex flex-col ">
            <div className="text-xl font-bold text-black">체중</div>
            <div className="flex flex-row">
              <div className="text-[22px] font-semibold text-black">
                {weight}
              </div>
              <div className="ml-1 flex items-end text-base">kg</div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="mr-2 text-sm font-medium text-neutral-500">
              {chosenDate === null ||
              chosenDate === dayjs().format("YYYY-MM-DD")
                ? "어제보다"
                : "전날보다"}
            </div>
            <img
              src={"/images/mobile/icon/upIcon.svg"}
              alt="upIcon"
              className={`mr-1 ${gap < 0 && "rotate-180"}`}
            />
            <div className="text-sm font-medium text-yellow-400">
              {Math.abs(gap)}
            </div>
          </div>
          <div className="-ml-2 w-full">
            <LineChart dataName={"체중"} data={data || [0, 0, 0, 0, 0, 0, 0]} />
          </div>
          <div className="w-full">
            <Indicator
              currentIndex={currentIndex}
              totalScreens={totalScreens}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function CalorieSwipe({
  currentIndex,
  totalScreens,
  kcals,
  recommendedCalories,
}) {
  const navigate = useNavigate();
  const { questionsCompleteCheck } = useSelector((state) => state.others);
  const { chosenDate } = useSelector((state) => state.commonApp);
  const gap = kcals?.[kcals?.length - 1] - kcals?.[kcals?.length - 2];

  return (
    <div
      className="flex w-full items-start gap-2.5"
      onClick={() => {
        if (questionsCompleteCheck?.healthComplete) {
          navigate(
            `/meal/analysis/${chosenDate || dayjs().format("YYYY-MM-DD")}`
          );
        } else {
          navigate("/question/health");
        }
      }}
    >
      <div className="flex h-[218px] w-full flex-col items-start gap-9 rounded-xl  bg-white px-4 pt-2.5">
        <div className="flex flex-col items-start">
          <div className="flex flex-col ">
            <div className="text-xl font-bold text-black">열량</div>
            <div className="flex flex-row">
              <div className="text-[22px] font-semibold text-black">
                {kcals?.[6] || 0}
              </div>
              <div className="ml-1 flex items-end text-base">kcal</div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="mr-2 text-sm font-medium text-neutral-500">
              {chosenDate === null ||
              chosenDate === dayjs().format("YYYY-MM-DD")
                ? "어제보다"
                : "전날보다"}
            </div>
            <img
              src={"/images/mobile/icon/upIcon.svg"}
              alt="upIcon"
              className={`mr-1 ${gap < 0 && "rotate-180"}`}
            />
            <div className="text-sm font-medium text-yellow-400">
              {Math.abs(gap)}
            </div>
          </div>
          <div className="-ml-2 w-full">
            <LineChart
              dataName={"열량"}
              data={kcals || [0, 0, 0, 0, 0, 0, 0]}
              recommended={recommendedCalories || [0, 0, 0, 0, 0, 0, 0]}
            />
          </div>
          <div className="w-full">
            <Indicator
              currentIndex={currentIndex}
              totalScreens={totalScreens}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function ProteinSwipe({
  currentIndex,
  totalScreens,
  proteinGs,
  recommendedProteinGs,
}) {
  const navigate = useNavigate();
  const { questionsCompleteCheck } = useSelector((state) => state.others);
  const { chosenDate } = useSelector((state) => state.commonApp);
  const gap =
    proteinGs?.[proteinGs?.length - 1] - proteinGs?.[proteinGs?.length - 2];

  return (
    <div
      className="flex w-full items-start gap-2.5"
      onClick={() => {
        if (questionsCompleteCheck?.healthComplete) {
          navigate(
            `/meal/analysis/${chosenDate || dayjs().format("YYYY-MM-DD")}`
          );
        } else {
          navigate("/question/health");
        }
      }}
    >
      <div className="flex h-[218px] w-full flex-col items-start gap-9 rounded-xl bg-white px-4 pt-2.5">
        <div className="flex flex-col items-start">
          <div className="flex flex-col ">
            <div className="text-xl font-bold text-black">단백질</div>
            <div className="flex flex-row">
              <div className="text-[22px] font-semibold text-black">
                {proteinGs?.[6] || 0}
              </div>
              <div className="ml-1 flex items-end text-base">g</div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="mr-2 text-sm font-medium text-neutral-500">
              {chosenDate === null ||
              chosenDate === dayjs().format("YYYY-MM-DD")
                ? "어제보다"
                : "전날보다"}
            </div>
            <img
              src={"/images/mobile/icon/upIcon.svg"}
              alt="upIcon"
              className={`mr-1 ${gap < 0 && "rotate-180"}`}
            />
            <div className="text-sm font-medium text-yellow-400">
              {Math.abs(gap)}
            </div>
          </div>
          <div className="-ml-2 w-full">
            <LineChart
              dataName={"단백질"}
              data={proteinGs || [0, 0, 0, 0, 0, 0, 0]}
              recommended={recommendedProteinGs || [0, 0, 0, 0, 0, 0, 0]}
            />
          </div>
          <div className="w-full">
            <Indicator
              currentIndex={currentIndex}
              totalScreens={totalScreens}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
