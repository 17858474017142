import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { onSetSubSection } from "../../../../features/admin/commonSlice";
import {
  onSetCommTab,
  onSetToggleCommunity,
} from "../../../../features/admin/communitySlice";
import { onSetContTab } from "../../../../features/admin/contentsSlice";
import { onSetAccountTab } from "../../../../features/admin/accountSlice";
import { useNavigate } from "react-router-dom";
import { toggleBoardModal } from "../../../../features/admin/toggleSlice";
const SideInnerMapping = ({ mappingData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subSection } = useSelector((state) => state.common);
  const onSetSubNav = (title, link) => {
    dispatch(onSetToggleCommunity(false)); //toggle off
    dispatch(toggleBoardModal(false)); //eachmodal off
    dispatch(onSetSubSection(title));

    navigate(link);

    if (
      title === "소식" ||
      title === "1:1 이용문의" ||
      title === "음식DB요청" ||
      title === "정보" ||
      title === "CancerGPT" ||
      title === "공지사항"
    )
      dispatch(onSetCommTab(title));
    else if (title.includes("가이드") || title === "FAQ")
      dispatch(onSetContTab(title));
    else dispatch(onSetAccountTab(title));
  };
  return (
    <motion.ul
      className="flex flex-col gap-4 p-4 mb-4 -mt-4 rounded-md bg-gray_95"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeOut", duration: 1 }}
    >
      {mappingData?.map((el, idx) => (
        <li
          key={idx}
          onClick={() => {
            onSetSubNav(el.title, el.link);
          }}
          className={
            "cursor-pointer px-2 text-caption1_600 " +
            (subSection === el.title ? "text-black" : "text-gray_140")
          }
        >
          {el.title}
        </li>
      ))}
    </motion.ul>
  );
};

export default SideInnerMapping;
