import React from "react";
import { mobileTypeFn } from "../../mobile/components/logic";

export const information = [
  {
    title: "ADD.",
    content: "14F, 560, Eonju-ro, Gangnam-gu, Seoul, Republic of Korea",
  },
  { title: "TEL.", content: "+82.02.6949.3516" },
  {
    title: "FAX.",
    content: "+82.02.6949.3517",
  },
  { title: "R.", content: "Corporate registration number 516-87-00127" },
];

export const sns = [
  { title: "blog", link: "https://blog.naver.com/healiary" },
  { title: "kakao", link: "https://pf.kakao.com/_xlPYExj" },
  {
    title: "youtube",
    link: "https://www.youtube.com/channel/UC1LLVoPjPLmu3kjLqJCB_ZA",
  },
  { title: "facebook", link: "https://www.facebook.com/heringsglobal" },
];

export const appDownload = () => {
  if (mobileTypeFn() === "iphone") {
    window.open("https://apps.apple.com/kr/app/id1636424503", "_blank");
  } else {
    window.open(
      `https://play.google.com/store/apps/details?id=com.herings.healiary&hl=ko`
    );
  }
  window.dataLayer.push({
    event: "app_download_start",
  });
};

const SixthPart = () => {
  return (
    <>
      <section
        className="flex flex-col items-start justify-center gap-3 py-14 xl:gap-14 xl:py-28"
        style={{
          backgroundImage: "url('/images/mobile/landing/landing_main6-1.png')",
          backgroundSize: "cover",
        }}
      >
        <div className="pl-[200px]">
          <p className="max-w-[50%] text-start text-4xl text-black md:max-w-[40%] lg:max-w-[30%] lg:leading-[50px] xl:max-w-[50%] xl:text-[65px] xl:leading-[99px] 2xl:max-w-[40%] 3xl:text-[75px] ">
            <strong className="text-main">힐리어리</strong>와 함께 건강관리의
            <strong className="font-extrabold"> 첫 스텝</strong>을 함께
            해보세요.
          </p>
          <button
            onClick={appDownload}
            className="mt-4 flex items-center justify-around gap-3 rounded-full bg-main px-5 py-3 text-base font-bold tracking-wide text-white hover:bg-orange_80 xl:gap-6 xl:px-10 xl:py-4 xl:text-2xl 3xl:px-14 3xl:py-8 3xl:text-4xl"
          >
            <img
              src="/images/mobile/landing/download.svg"
              alt="다운로드"
              className="w-8 pb-[1px]"
            />
            <span>힐리어리 시작하기</span>
          </button>
        </div>
      </section>
      <section
        className="relative flex flex-col items-center justify-center py-72 text-white"
        style={{
          backgroundImage: "url('/images/mobile/landing/landing_main6-2.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <p className="z-10 text-5xl font-bold">Always with you</p>
        <p className="z-10 mb-2 mt-5 text-3xl font-light">
          HERINGS will be always with you
        </p>
        <p className="z-10 text-3xl font-light">through your journey</p>
        <button
          onClick={() => window.open("https://www.heringsglobal.com/")}
          className="z-10 mt-3 flex items-center rounded-full border-[3px] border-white px-7 py-1 text-sm font-bold text-white hover:scale-110 xl:mt-16 xl:px-10 xl:py-3 xl:text-[24px] xl:leading-[24px] xl:tracking-wide"
        >
          헤링스 홈페이지
        </button>
        <div className="absolute inset-0 h-full w-full bg-black bg-opacity-20"></div>
      </section>
      <footer className="flex flex-col justify-center gap-4 bg-[#222] px-[80px] py-8 text-xs font-light text-white xl:px-[150px] 3xl:px-[200px]">
        <div className="flex justify-between">
          <div className="flex flex-col gap-6">
            <div className="flex gap-3">
              <a
                href="https://www.heringsglobal.com/privacypolicy/healiary"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                {"Privacy Policy  &  Terms And Conditions"}
              </a>
            </div>
            <div className="flex flex-wrap gap-3 text-gray_100">
              {information.map((v) => (
                <div key={v.title}>
                  <span className="mr-1 text-orange_160">{v.title}</span>
                  {v.content}
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-wrap justify-end gap-6 2xl:flex-wrap">
            {sns.map((value) => (
              <a
                key={value.title}
                className="w-10"
                target="_blank"
                rel="noopener noreferrer"
                href={value.link}
              >
                <img
                  src={`/images/mobile/landing/footer/${value.title}.svg`}
                  alt={value.title}
                  className=""
                />
              </a>
            ))}
          </div>
        </div>

        <div className="text-gray_100">
          Copyright © {new Date().getFullYear()} HERINGS. All rights reserved.
        </div>
      </footer>
    </>
  );
};

export default SixthPart;
