import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addReply,
  getAllRepliesOfOnePosting,
  handlePageChangeCommu,
  handleReplySliceArr,
  onClickCommentOpen,
} from "../../../../features/admin/communitySlice";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import { useLocation } from "react-router-dom";
import { CommentBtn } from "../common/ButtonCustom";
const Comment = () => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const { page } = useSelector((state) => state.common);
  const { postingIdList } = useSelector((state) => state.commu);
  const [editNewsInput, setEditNewsInput] = useState("");
  const pageReplyCount = 5;
  const path = useLocation().pathname;

  const submitReply = async () => {
    const inputEnterInsert = editNewsInput.replace(/\n/g, "\n");
    let param = {
      content: inputEnterInsert,
      belongToPostingId: postingIdList.id,
      writerAdminAdminId: loginInfo?.adminId,
    };

    const res = await dispatch(addReply(param)).unwrap();
    if (res.ok) {
      const param = {
        postingId: postingIdList.id,
      };
      const secondres = await dispatch(
        getAllRepliesOfOnePosting(param)
      ).unwrap();
      if (secondres.ok) {
        try {
          if (
            secondres.totalReplies === undefined ||
            secondres.totalReplies?.length === 0
          ) {
            dispatch(handleReplySliceArr([]));
          } else {
            toast(
              <p>댓글이 저장되었습니다.</p>,
              toastCommonProps("top-right", "toast_alert", 1000)
            );
            setEditNewsInput("");
            const slicePages = secondres.totalReplies.slice(
              (page - 1) * pageReplyCount,
              page * pageReplyCount
            );
            dispatch(handleReplySliceArr(slicePages));
            dispatch(handlePageChangeCommu(1));
            dispatch(onClickCommentOpen(true));
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(secondres);
      }
    } else {
      console.log(res);
    }
  };
  const showReply = () => {
    dispatch(onClickCommentOpen(true));
  };

  return (
    <div className="flex flex-col items-start gap-[20px] rounded-b-[8px] bg-[#FCFCFC]">
      <div className="flex w-full items-start justify-center rounded-[4px] p-5">
        <p className="text-body_1 flex w-[90px] items-center justify-center font-semibold text-black">
          운영자
        </p>
        <div className="flex items-end gap-[8px]">
          <textarea
            onChange={(e) => {
              setEditNewsInput(e.target.value);
            }}
            placeholder="댓글 내용을 입력하세요"
            className={
              "flex flex-row items-start gap-[16px] overflow-y-scroll rounded-[4px] border border-[#A39F9B] bg-[#FCFCFC] px-[20px] py-[12px] text-sm outline-none" +
              (path.includes("information")
                ? " h-[180px] w-full "
                : " h-[300px] w-[440px] ")
            }
            value={editNewsInput}
          />
          <div className="flex flex-col gap-y-3 ">
            <CommentBtn
              onClickAction={showReply}
              title="댓글보기"
              bordercolor="border border-orange_100"
              txtcolor="text-orange_100"
            />
            <CommentBtn
              onClickAction={editNewsInput !== "" ? submitReply : undefined}
              title="댓글입력"
              bgcolor={editNewsInput !== "" ? "bg-orange_100" : "bg-orange_60"}
              txtcolor="text-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
