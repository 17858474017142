import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { questionTypeOption } from "../../pages/Board/CancerGPTOptionList";

const CommonOption = ({
  //common params
  lists,
  ver,
  selectid,
  onChangeFunction,
  mywidth,
  //date params
  weekstart,
  weekend,
}) => {
  const divcommoncss =
    "box-border flex flex-row justify-between items-center p-[12px] gap-[16px] bg-[#FCFCFC] border border-gray_100 rounded-[4px] h-[48px] cursor-pointer";
  const selectcommoncss =
    "w-full bg-[#FCFCFC] outline-none text-gray_100 focus:text-black  cursor-pointer";
  const optioncommoncss =
    "w-[42px] h-[24px] text-base font-normal flex items-center bg-[#FCFCFC] hover:bg-[#FFF4F0]";
  return (
    // ver1 width :custom / ver2 width:full / ver3 width:custom / ver4 width:full
    <>
      {ver === 3 || ver === 4 ? (
        <div
          className={divcommoncss}
          style={
            ver === 3
              ? { width: `${mywidth}px` }
              : ver === 4
              ? { width: "100%" }
              : ""
          }
        >
          <select
            className={selectcommoncss}
            name={selectid}
            id={selectid}
            value={`${weekstart},${weekend}`}
            onChange={onChangeFunction}
          >
            {lists &&
              lists?.map((data, idx) => (
                <option
                  key={idx}
                  value={`${data.startDate},${data.endDate}`}
                  className={optioncommoncss}
                >
                  [{data.num}주차] {data.startDate} ~ {data.endDate}
                </option>
              ))}
          </select>
        </div>
      ) : ver === 1 || ver === 2 ? (
        <div
          className={divcommoncss}
          style={
            ver === 1
              ? { width: `${mywidth}px` }
              : ver === 2
              ? { width: "100%" }
              : ""
          }
        >
          <select
            className={selectcommoncss}
            name={selectid}
            id={selectid}
            onChange={onChangeFunction}
          >
            {lists?.map((e, idx) => (
              <option
                key={idx}
                // account 페이지에서 병원목록 한글표기명을 코드로 변환
                value={
                  selectid === "filterHospitalCode" ? e && lists[idx].code : e
                }
                className={optioncommoncss}
              >
                {selectid === "gptSelect1"
                  ? e === "분류 전체"
                    ? "분류 전체"
                    : questionTypeOption[e]
                  : e}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div
          className={divcommoncss}
          style={
            mywidth === undefined
              ? { width: "100%" }
              : { width: `${mywidth}px` }
          }
        >
          <select
            name={selectid}
            id={selectid}
            className={selectcommoncss}
            onChange={onChangeFunction}
          >
            {lists?.map((el, idx) => (
              <option className={optioncommoncss} key={idx}>
                {el}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};

export default CommonOption;
