import React, { useEffect, useState } from "react";
import FirstPart from "./component/FirstPart";
import FourthPart from "./component/FourthPart";
import SecondPart from "./component/SecondPart";
import ThirdPart from "./component/ThirdPart";
import FifthPart from "./component/FifthPart";
import MobileFirstPart from "./component/MobileFirstPart";
import MobileFourthPart from "./component/MobileFourthPart";
import MobileSecondPart from "./component/MobileSecondPart";
import MobileThirdPart from "./component/MobileThirdPart";
import MobileFifthPart from "./component/MobileFifthPart";
import SixthPart from "./component/SixthPart";
import MobileSixthPart from "./component/MobileSixthPart";
import Header from "./component/Header";
import { mobileTypeFn } from "../mobile/components/logic";

const Landing = () => {
  const [resize, setResize] = useState();
  const [tab, setTab] = useState(1);

  // 마운트시 화면 가로 크기 구함
  useEffect(() => {
    window.addEventListener("resize", () => {
      // addEventListener 새로고침 안된 상태에서 화면 넓이가 달라질 때 CSS 적용
      setResize(window.innerWidth);
    });

    // setTimeout 새로고침 시 처음에 화면 넓이를 가져옴
    const time = setTimeout(() => {
      //   console.log(window.innerWidth);
      setResize(window.innerWidth);
    }, 0.0000000000000000001);

    return () => {
      window.removeEventListener("resize", () => {
        setResize(window.innerWidth);
      });

      clearTimeout(time);
    };
  }, []);

  // 메타 광고 추적용 코드
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    const scriptCode = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '681743433450268');
      fbq('track', 'PageView');
      `;
    const noscript = document.createElement("noscript");
    const noscriptCode = `<img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=681743433450268&ev=PageView&noscript=1"
    />`;

    script.innerHTML = scriptCode;
    noscript.innerHTML = noscriptCode;
    head.appendChild(script);
    head.appendChild(noscript);

    return () => {
      head.removeChild(script);
      head.removeChild(noscript);
    };
  }, []);

  // 이벤트 응모하기 버튼
  // 앱 있으면 앱실행, 없으면 앱스토어/구글플레이로 이동
  let isPage = true;
  function goApp() {
    const userAgent = navigator.userAgent.toLowerCase();
    const device = mobileTypeFn();
    const url = "healiary://open?function=movePage&value=event";

    const now = new Date().valueOf();

    // ios
    if (device === "iphone" && isPage) {
      setTimeout(function () {
        if (new Date().valueOf() - now > 100) return;
        if (isPage) {
          window.location.href = "https://apps.apple.com/kr/app/id1636424503";
        }
      }, 50);
    }
    // android
    if (device === "android" && isPage) {
      setTimeout(function () {
        if (new Date().valueOf() - now > 1500) return;
        if (isPage) {
          window.location.href =
            "market://details?id=com.herings.healiary&pli=1";
        }
      }, 1000);
    }

    // 아이폰에서 safari 브라우저 일때는 앱스토어로만 이동, 나머지는 어플 부르기
    if (
      device === "iphone" &&
      userAgent.indexOf("safari") > -1 &&
      !userAgent.match(/naver|crios/)
    ) {
      setTimeout(function () {
        window.location.href = "https://apps.apple.com/kr/app/id1636424503";
      }, 0);
    } else if (device === "pc") {
      alert(`모바일 기기에서 "힐리어리" 어플 설치 후 응모해 주세요 😊`);
    } else {
      window.location.href = url;
    }
  }

  window.onblur = function () {
    isPage = false;
  };

  window.onfocus = function () {
    isPage = true;
  };

  return (
    <div className={`flex flex-col overflow-x-hidden`}>
      <Header tab={tab} setTab={setTab} />
      {tab === 1 ? (
        resize >= 800 ? (
          <>
            <FirstPart />
            <SecondPart />
            <ThirdPart screenSize={resize} />
            {/* <FourthPart /> */}
            <FifthPart />
            <SixthPart />
          </>
        ) : (
          <>
            <MobileFirstPart />
            <MobileSecondPart />
            <MobileThirdPart />
            <MobileFourthPart />
            <MobileFifthPart />
            <MobileSixthPart />
          </>
        )
      ) : (
        <div className="flex justify-center h-fit">
          <div className="flex flex-col justify-center max-w-5xl ">
            {new Date() > new Date("2023-10-31 09:00:00") ? (
              <>
                <img
                  src="/images/mobile/landing/event-6.png"
                  alt="이벤트 당첨자 발표"
                />
              </>
            ) : (
              <>
                <img
                  src="/images/mobile/landing/event-end.png"
                  alt="이벤트 당첨자 발표는 10월 31일"
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Landing;
