import React from 'react';
import { pointFn } from '../logic';

const NutritionInner = ({ title, data, imgUrl, unit }) => {
    return (
        <div className='flex items-center gap-4 py-3'>
            <span className='w-[38px] h-[38px] rounded-full text-center bg-orange_10'>
                <img className='w-8 h-8 m-auto' src={imgUrl} alt='열량 관련 이미지' />
            </span>
            <div>
                <h1 className='text-caption1_600 text-gray_160'>{title}</h1>
                <h2 className={'text-body2_700 ' + (unit === 'kcal' ? 'text-orange_180' : 'text-green_180')}>{pointFn(data)} {unit}</h2>
            </div>
        </div >
    );
};

export default NutritionInner;