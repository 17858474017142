import React, { useEffect, useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import DontEditAccountInput from "../../components/account/DontEditAccountInput";
import EditSelect from "../../components/account/EditSelect";
import { editAdmin, editPhoto } from "../../../../features/admin/adminSlice";
import toastCommonProps from "../../../../common/toast";
import { toast } from "react-toastify";
import AccountImgChange from "../../components/account/AccountImgChange";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../components/common/ButtonCustom";
import { onSetAccountOneInfo } from "../../../../features/admin/accountSlice";

const AccountPrev = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state?.adminLogin);
  const { accountInfo } = useSelector((state) => state?.accountadmin);
  const [photo, setPhoto] = useState([]);
  //권한,승인select
  const authoritySelect =
    loginInfo?.authority === "10"
      ? ["일반관리자(병원)", "슈퍼관리자(병원)"]
      : [
          "일반관리자(병원)",
          "슈퍼관리자(병원)",
          "일반관리자(헤링스)",
          "슈퍼관리자(헤링스)",
        ];
  const approvalSelect = ["대기", "승인"];
  const [editForm, setEditForm] = useState({
    adminId: accountInfo?.adminId,
    authority:
      accountInfo?.authority === "00"
        ? "슈퍼관리자(헤링스)"
        : accountInfo?.authority === "01"
        ? "일반관리자(헤링스)"
        : accountInfo?.authority === "10"
        ? "슈퍼관리자(병원)"
        : "일반관리자(병원)",
    approval: "대기",
  });

  useEffect(() => {
    if (!loginInfo?.adminId) {
      navigate("/admin/signin");
    }
  }, []);

  const onChangeSelect = (e) => {
    const { name, value } = e.target;
    setEditForm({ ...editForm, [name]: value });
  };
  const onClickApproval = async () => {
    let param = {
      adminId: editForm.adminId,
      authority:
        editForm.authority === "일반관리자(병원)"
          ? "11"
          : editForm.authority === "슈퍼관리자(병원)"
          ? "10"
          : editForm.authority === "일반관리자(헤링스)"
          ? "01"
          : "00",
      approval: editForm.approval === "대기" ? false : true,
    };
    let response = await dispatch(editAdmin(param)).unwrap();
    try {
      if (response.data.ok) {
        //변경된 사진이 있을때 작동
        if (photo?.length !== 0) {
          let photoParam = {
            adminId: editForm.adminId,
            file: photo,
          };
          let secondResult = await dispatch(editPhoto(photoParam)).unwrap();
        }
        toast(
          <p>정보가 변경되었습니다.</p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
        if (response.data.admin.approval) {
          setTimeout(
            () =>
              navigate("/admin/account/approv", { state: response.data.admin }),
            2000
          );
        } else {
          setTimeout(() => navigate("/admin/account/prevapprov"), 2000);
        }
        dispatch(onSetAccountOneInfo(response.data.admin));
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex w-auto min-w-min flex-col items-start justify-start gap-[16px] p-[24px]">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="flex h-[112px] w-[882px] items-center justify-between gap-[48px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              <div className="flex h-[64px] items-center justify-between gap-[24px]">
                <div className="relative flex h-[64px] w-[72px]">
                  <AccountImgChange state={accountInfo} setPhoto={setPhoto} />
                </div>
                <div className="flex h-[48px] flex-col justify-center ">
                  <h3 className="text-xl font-bold text-black">
                    {accountInfo?.name === null ? "null" : accountInfo?.name}
                  </h3>
                  <h4 className=" text-sm text-gray_100	">
                    {accountInfo?.authority === "00"
                      ? "헤링스 슈퍼관리자"
                      : accountInfo?.authority === "01"
                      ? "헤링스 일반관리자"
                      : accountInfo?.authority === "10"
                      ? "병원 슈퍼관리자"
                      : "병원 일반관리자"}
                  </h4>
                </div>
              </div>
              <p className="h-[18px] w-[125px] text-sm text-[#05040D]">
                since {dayjs(accountInfo?.createdAt).format("YYYY-MM-DD")}
              </p>
            </div>

            <div className="mt-[16px] flex h-[800px] w-[882px] flex-col items-center justify-center gap-[24px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              {/* 표 */}
              <div className="flex w-[340px] flex-col items-center justify-start gap-[8px]">
                <DontEditAccountInput
                  labelname="아이디"
                  value={accountInfo?.adminId}
                />
                <DontEditAccountInput
                  labelname="이름"
                  value={accountInfo?.name !== null ? accountInfo?.name : ""}
                />
                <DontEditAccountInput
                  labelname="성별"
                  value={
                    accountInfo?.gender !== null
                      ? accountInfo?.gender === "M"
                        ? "남성"
                        : "여성"
                      : ""
                  }
                />
                {!(
                  accountInfo?.authority === "00" ||
                  accountInfo?.authority === "01"
                ) && (
                  <DontEditAccountInput
                    labelname="소속기관(병원코드)"
                    value={
                      !(
                        accountInfo?.hospitalCode === null ||
                        accountInfo?.hospitalName === null
                      ) &&
                      `${accountInfo?.hospitalName}(${accountInfo?.hospitalCode})`
                    }
                  />
                )}
                {
                  // admin 병원 일반관리자 구분
                  //   1 : 의사
                  //   2 : 간호사
                  //   3 : 영양사
                  accountInfo?.authority === "11" && (
                    <DontEditAccountInput
                      labelname="구분"
                      value={
                        accountInfo?.division !== null
                          ? accountInfo?.division === "1"
                            ? "의사"
                            : accountInfo?.division === "2"
                            ? "간호사"
                            : accountInfo?.division === "3"
                            ? "영양사"
                            : ""
                          : ""
                      }
                    />
                  )
                }
                <DontEditAccountInput
                  labelname="이메일 주소"
                  value={accountInfo?.email !== null ? accountInfo?.email : ""}
                />
                <DontEditAccountInput
                  labelname="휴대폰"
                  value={
                    accountInfo?.phoneNumber !== null
                      ? accountInfo?.phoneNumber
                      : ""
                  }
                />
                <EditSelect
                  selects={authoritySelect}
                  idname={"authority"}
                  label={"권한"}
                  onChangeSelect={onChangeSelect}
                  editForm={editForm}
                  state={accountInfo}
                />
                <EditSelect
                  selects={approvalSelect}
                  idname={"approval"}
                  label={"승인"}
                  onChangeSelect={onChangeSelect}
                  editForm={editForm}
                  state={accountInfo}
                />
              </div>
              <div className="flex w-[340px] gap-[8px]">
                <div className="flex w-[340px] gap-[10px]">
                  <BorderButtonCustom
                    onClick={() => navigate("/admin/account")}
                    content={"목록"}
                    bgcolor={""}
                    textcolor={"text-orange_100"}
                    bordercolor={"border-orange_100"}
                    textsize={"text-caption1_600"}
                    width={"30%"}
                  />
                  <BGButtonCustom
                    onClick={onClickApproval}
                    content={"저장하기"}
                    bgcolor={"bg-orange_100"}
                    textcolor={"text-gray_10"}
                    textsize={"text-caption1_600"}
                    width={"70%"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountPrev;
