import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  handlePageChange,
  onSetSection,
  onSetSubSection,
} from "../../../../features/admin/commonSlice";
import {
  // fetchEatRecord,
  fetchRecommendMeal,
  onClickManagementDetailState,
  onClickMember,
  searchManagementUsers,
} from "../../../../features/admin/managementSlice";

/*component*/

import ContentHeader from "../common/ContentHeader";
import RecommendMealDialog from "../common/RecommendMealDialog";
import { AnimatePresence } from "framer-motion";
import {
  getAllRepliesOfOnePosting,
  handleReplySliceArr,
  onClickCustomerCenterState,
  onSetCommTab,
  searchCustomerCenter,
  searchManagementCommunity,
} from "../../../../features/admin/communitySlice";
import {
  toggleBoardModal,
  toggleCommuHide,
} from "../../../../features/admin/toggleSlice";
import MemberList from "../graph/MemberList";
import Paging from "../common/Paging";
import PaginationLayout from "../common/pagination/PaginationLayout";
import { setPageArr } from "../../../../features/admin/paginationSlice";

const MainContent = ({ user }) => {
  const pageReplyCount = 5;
  const { page } = useSelector((state) => state.common);
  //---이번주차 식사기록 및 추천식단보기 default 날짜계산
  const [selected, setSelected] = useState({
    mealD: null,
    gro: null,
  });
  //---이번주차 식사기록 및 추천식단보기 default 날짜계산
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerCenterList, communityList, userList } = useSelector(
    (state) => state.main
  );
  const { totalPage } = useSelector((state) => state.management);
  const { pagePerList } = useSelector((state) => state.pagination);
  const [openDialog, setOpenDialog] = useState(false);
  const heringsAuth = user.authority === "00" || user.authority === "01"; //권한 헤링스
  //회원목록 페이지네이션 초기값
  const [filterInfo, setFilterInfo] = useState({
    page: page,
    countPerPage: pagePerList,
    searchNameOrID: "",
    filterCancerType: [],
    filterOperationYN: [],
    filterAntiCancerYN: [],
    filterAntiCancerType: [],
    filterAge: [],
    sortRegistDay: "",
    sortAge: "",
    filterUserType: "",
    loginedAdminID: user.adminId,
  });
  const onePageUsers = useSelector((state) => state.management.searchUserList);

  const goMembers = () => {
    dispatch(onSetSection("건강관리"));
    navigate("/admin/management");
  };
  const goCommu = (title) => {
    dispatch(onSetSection("게시판 관리"));
    dispatch(onSetSubSection(title));
    dispatch(onSetCommTab(title));
    navigate("/admin/board");
    let param = { page: 1, countPerPage: pagePerList, title: "", content: "" };
    let param1 = Object.assign(param, { userId: "" });
    let param2 = Object.assign(param, { writerUserId: "" });
    if (title === "1:1 이용문의") {
      dispatch(searchCustomerCenter(param1));
    } else {
      dispatch(searchManagementCommunity(param2));
    }
  };
  const onMemberDetail = (member, e) => {
    //회원목록 detail
    //1.클릭한 tr의 e.target.innerText가 "보기"인 경우만 모달보이기
    if (e.target.innerText === "보기") {
      dispatch(onClickMember(member)); //멤버 userId update
      dispatch(fetchRecommendMeal({ userId: member.userId }));
      setOpenDialog(true);
    } else {
      //2.management/detail페이지로 넘어가기
      navigate(`/admin/management/detail/${member.name}`);
      dispatch(onClickManagementDetailState(member));
    }
  };
  const onBoardDetail = (list, title) => {
    //소식, 이용문의 detail
    dispatch(onSetSection("게시판 관리"));
    dispatch(onSetSubSection(title));
    dispatch(onSetCommTab(title));
    if (title === "1:1 이용문의") {
      dispatch(onClickCustomerCenterState(list.id));
      navigate("/admin/customercenter/detail", { state: list.id });
    } else {
      navigate("/admin/board", { state: list.id });
      //show EachModal
      dispatch(toggleBoardModal(true));
      const fetchModalInfo = async () => {
        const param = {
          postingId: list.id,
          userId: user.adminId,
        };
        try {
          const getPostingRes = await dispatch(
            getAllRepliesOfOnePosting(param)
          ).unwrap();
          if (getPostingRes.ok) {
            //페이지 오픈시 공개 비공개여부 초깃값 설정
            dispatch(toggleCommuHide(getPostingRes.posting.isHide));
            const noReplies =
              !getPostingRes.totalReplies ||
              getPostingRes.totalReplies?.length === 0;
            const slicePages = getPostingRes.totalReplies.slice(
              (page - 1) * pageReplyCount,
              page * pageReplyCount
            );
            dispatch(handleReplySliceArr(noReplies ? [] : slicePages));
          } else {
            console.log(getPostingRes);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchModalInfo();
    }
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onClickSearch = useCallback(
    async (pageNum) => {
      dispatch(handlePageChange(pageNum));
      let params = {
        page: pageNum,
        countPerPage: pagePerList,
        searchNameOrID: filterInfo.searchNameOrID,
        filterCancerType: filterInfo.filterCancerType,
        filterOperationYN: filterInfo.filterOperationYN,
        filterAntiCancerYN: filterInfo.filterAntiCancerYN,
        filterAntiCancerType: filterInfo.filterAntiCancerType,
        filterAge: filterInfo.filterAge,
        sortRegistDay: filterInfo.sortRegistDay,
        sortAge: filterInfo.sortAge,
        filterUserType: filterInfo.filterUserType,
        loginedAdminID: loginInfo?.adminId,
      };

      const res = await dispatch(searchManagementUsers(params)).unwrap();
      if (res.ok) {
        const defaultarr = Array(res.totalPage)
          .fill()
          ?.map((v, i) => {
            return i + 1;
          });
        dispatch(
          setPageArr(
            defaultarr.slice(
              (Math.ceil(pageNum / pagePerList) - 1) * pagePerList,
              Math.ceil(pageNum / pagePerList) * pagePerList
            )
          )
        );
      }
    },
    [filterInfo, dispatch, pagePerList, setPageArr]
  );

  const handlePageFn = (clickPage) => {
    dispatch(handlePageChange(clickPage));
    onClickSearch(clickPage);
  };
  useEffect(() => {
    if (user?.adminId) {
      //첫화면에서 users 불러오기(전체 userlist) , search된 users불러오기(1페이지에서의 userlist)
      let params = {
        page: 1,
        countPerPage: pagePerList,
        searchNameOrID: filterInfo.nameUserId,
        filterCancerType: [],
        filterOperationYN: [],
        filterAntiCancerYN: [],
        filterAntiCancerType: [],
        filterAge: [],
        sortRegistDay: "",
        sortAge: "",
        filterUserType: "",
        loginedAdminID: user.adminId,
      };
      dispatch(searchManagementUsers(params));
      dispatch(handlePageChange(1));
    } else {
      navigate("/admin/signin");
    }
  }, [dispatch, pagePerList, filterInfo.nameUserId, user.adminId]);

  return (
    <>
      <div className="flex flex-col gap-6 p-6">
        <section className="min-w-screen rounded-3xl bg-gray_10 p-6 shadow-md">
          <div className="mb-6 flex justify-between">
            <ContentHeader title={"회원목록"} />
            <div
              className="mb-4 flex cursor-pointer items-center gap-1"
              onClick={goMembers}
            >
              <span className="text-caption_1 font-gray_10">더보기</span>
              <img
                src="/images/admin/icon/arrow_next.svg"
                alt="회원목록 아이콘"
              />
            </div>
          </div>
          {heringsAuth ? (
            userList?.length !== 0 && (
              <MemberList
                onClickDetail={onMemberDetail}
                filteredUsers={userList}
                title={"회원목록"}
              />
            )
          ) : (
            <PaginationLayout
              totalPage={totalPage}
              pagePerList={pagePerList}
              onClick={(clickpage) => handlePageFn(clickpage)}
            >
              <MemberList
                onClickDetail={onMemberDetail}
                filteredUsers={onePageUsers}
                title={"회원목록"}
              />
            </PaginationLayout>
          )}
        </section>
        {heringsAuth ? (
          <div className="flex gap-6">
            <section className="min-w-screen w-1/2 rounded-3xl bg-gray_10 p-6 shadow-md">
              <div className="mb-6 flex justify-between">
                <ContentHeader title={"소식"} commu={true} />
                <div
                  className="mb-4 flex cursor-pointer items-center gap-1"
                  onClick={() => goCommu("소식")}
                >
                  <span className="text-caption_1 font-gray_10">더보기</span>
                  <img
                    src="/images/admin/icon/arrow_next.svg"
                    alt="회원목록 아이콘"
                  />
                </div>
              </div>
              {communityList?.length !== 0 && (
                <MemberList
                  title={"소식"}
                  onClickDetail={onBoardDetail}
                  filteredUsers={communityList}
                  pageUserCount={pagePerList}
                />
              )}
            </section>
            <section className="min-w-screen w-1/2 rounded-3xl bg-gray_10 p-6 shadow-md">
              <div className="mb-6 flex justify-between">
                <ContentHeader title={"1:1 이용문의"} commu={true} />
                <div
                  className="mb-4 flex cursor-pointer items-center gap-1"
                  onClick={() => goCommu("1:1 이용문의")}
                >
                  <span className="text-caption_1 font-gray_10">더보기</span>
                  <img
                    src="/images/admin/icon/arrow_next.svg"
                    alt="회원목록 아이콘"
                  />
                </div>
              </div>
              {customerCenterList?.length !== 0 && (
                <MemberList
                  title={"1:1 이용문의"}
                  onClickDetail={onBoardDetail}
                  filteredUsers={customerCenterList}
                />
              )}
            </section>
          </div>
        ) : (
          ""
        )}
      </div>
      <AnimatePresence initial={false} mode="wait">
        {openDialog && (
          <RecommendMealDialog
            onCloseDialog={onCloseDialog}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default MainContent;
