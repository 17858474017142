import React, { useEffect, useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { editAdmin, editPhoto } from "../../../../features/admin/adminSlice";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import DontEditAccountInput from "../../components/account/DontEditAccountInput";
import { onSetCommTab } from "../../../../features/admin/communitySlice";
import EditSelect from "../../components/account/EditSelect";
import AccountImgChange from "../../components/account/AccountImgChange";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../components/common/ButtonCustom";
const EditAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountInfo } = useSelector((state) => state.accountadmin);
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const authoritySelect = [
    "슈퍼관리자(병원)",
    "일반관리자(병원)",
    "슈퍼관리자(헤링스)",
    "일반관리자(헤링스)",
  ];
  const [photo, setPhoto] = useState([]);

  useEffect(() => {
    if (!loginInfo?.adminId) {
      navigate("/admin/signin");
    }
  }, []);
  const [editForm, setEditForm] = useState({
    adminId: accountInfo?.adminId,
    authority:
      accountInfo?.authority === "00"
        ? "슈퍼관리자(헤링스)"
        : accountInfo?.authority === "01"
        ? "일반관리자(헤링스)"
        : accountInfo?.authority === "10"
        ? "슈퍼관리자(병원)"
        : "일반관리자(병원)",
  });

  const onChangeSelect = (e) => {
    const { name, value } = e.target;
    setEditForm({ ...editForm, [name]: value });
  };

  const submitData = async () => {
    let param = {
      adminId: editForm.adminId,
      authority:
        editForm.authority === "일반관리자(병원)"
          ? "11"
          : editForm.authority === "슈퍼관리자(병원)"
          ? "10"
          : editForm.authority === "일반관리자(헤링스)"
          ? "01"
          : "00",
    };
    let result = await dispatch(editAdmin(param)).unwrap();
    try {
      if (result.data.ok) {
        //변경된 사진이 있을때 작동
        if (photo?.length !== 0) {
          let photoParam = {
            adminId: editForm.adminId,
            file: photo,
          };
          let secondResult = await dispatch(editPhoto(photoParam)).unwrap();
        }
        if (loginInfo?.adminId === accountInfo?.adminId) {
          //로그인시 남아있는 유저 (자동로그인, 로그인 인풋) 정보가 변경되지 않음
          toast(
            <p>
              저장되었습니다. <br />
              다시 로그인해주세요.
            </p>,
            toastCommonProps("top-right", "toast_alert", 1000)
          );
          setTimeout(() => navigate("/admin/signin", {}), 1000);
        } else {
          toast(
            <p>관리자 수정 완료.</p>,
            toastCommonProps("top-right", "toast_alert", 1000)
          );
          setTimeout(() => navigate("/admin/account", {}), 2000);
          dispatch(onSetCommTab("관리자"));
        }
      } else {
        console.log(result, ">");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onClickGoAccount = () => {
    navigate("/admin/account");
    dispatch(onSetCommTab("관리자"));
  };
  console.log(accountInfo);
  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex w-auto min-w-min flex-col items-start justify-start gap-[16px] p-[24px]">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="flex h-[112px] w-[882px] items-center justify-between gap-[48px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              <div className="flex h-[64px] items-center  justify-between gap-[24px]">
                <div className="relative flex h-[64px] w-[72px]">
                  <AccountImgChange state={accountInfo} setPhoto={setPhoto} />
                </div>
                <div className="flex flex-col justify-center ">
                  <h3 className="text-xl font-bold">
                    {accountInfo?.adminId || accountInfo?.name}
                  </h3>
                  <h4 className="text-sm text-gray_100">
                    {accountInfo?.authority === "00"
                      ? "헤링스 슈퍼관리자"
                      : accountInfo?.authority === "01"
                      ? "헤링스 일반관리자"
                      : accountInfo?.authority === "10"
                      ? "병원 슈퍼관리자"
                      : "병원 일반관리자"}
                  </h4>
                </div>
              </div>
              <p className="text-sm text-[#05040D]">
                since {dayjs(accountInfo?.createdAt).format("YYYY-MM-DD")}
              </p>
            </div>
            <div className="mt-[16px] flex w-[882px] flex-col items-center gap-[24px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              {/* 표 */}
              <div className="flex w-[340px] flex-col items-center justify-start gap-[8px]">
                <DontEditAccountInput
                  labelname="아이디"
                  value={accountInfo?.adminId}
                />
                <DontEditAccountInput
                  labelname="이름"
                  value={accountInfo?.name !== null ? accountInfo?.name : ""}
                />
                <DontEditAccountInput
                  labelname="성별"
                  value={
                    accountInfo?.gender !== null
                      ? accountInfo?.gender === "M"
                        ? "남성"
                        : "여성"
                      : ""
                  }
                />
                {!(
                  accountInfo?.authority === "00" ||
                  accountInfo?.authority === "01"
                ) && (
                  <DontEditAccountInput
                    labelname="소속기관(병원코드)"
                    value={
                      accountInfo?.hospitalCode !== null &&
                      accountInfo?.hospitalName !== null
                        ? `${accountInfo?.hospitalName}(${accountInfo?.hospitalCode})`
                        : ""
                    }
                  />
                )}
                {
                  // admin 병원 일반관리자 구분
                  //   1 : 의사
                  //   2 : 간호사
                  //   3 : 영양사
                  accountInfo?.authority === "11" && (
                    <DontEditAccountInput
                      labelname="구분"
                      value={
                        accountInfo?.division !== null
                          ? accountInfo?.division === "1"
                            ? "의사"
                            : accountInfo?.division === "2"
                            ? "간호사"
                            : accountInfo?.division === "3"
                            ? "영양사"
                            : ""
                          : ""
                      }
                    />
                  )
                }
                <DontEditAccountInput
                  labelname="이메일 주소"
                  value={accountInfo?.email !== null ? accountInfo?.email : ""}
                />
                <DontEditAccountInput
                  labelname="휴대폰"
                  value={
                    accountInfo?.phoneNumber !== null
                      ? accountInfo?.phoneNumber
                      : ""
                  }
                />
                <EditSelect
                  selects={authoritySelect}
                  idname={"authority"}
                  label={"권한"}
                  onChangeSelect={onChangeSelect}
                  editForm={editForm}
                  state={accountInfo}
                />
                <DontEditAccountInput
                  labelname="승인"
                  value={
                    accountInfo?.approvalDate !== null
                      ? dayjs(accountInfo?.approvalDate).format("YYYY.MM.DD")
                      : ""
                  }
                />
              </div>
              <div className="flex w-[340px] gap-[10px]">
                <BorderButtonCustom
                  onClick={onClickGoAccount}
                  content={"목록"}
                  bgcolor={""}
                  textcolor={"text-orange_100"}
                  bordercolor={"border-orange_100"}
                  textsize={"text-caption1_600"}
                  width={"30%"}
                />
                <BGButtonCustom
                  onClick={submitData}
                  content={"저장하기"}
                  bgcolor={"bg-orange_100"}
                  textcolor={"text-gray_10"}
                  textsize={"text-caption1_600"}
                  width={"70%"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdmin;
