import React from 'react'
import { CSVLink } from 'react-csv'


const CsvModal = ({headers, showCSVdown, lists,tab}) => {

  return (
    <>
    <div
      className="w-full h-full fixed top-0 left-0 bg-black opacity-30 z-100"
      onClick={showCSVdown}
    ></div>
    <div
      className="w-80 h-40 p-4 fixed top-1/2 left-1/2 transform-50 bg-white rounded-lg opacity-100 z-110
    flex justify-center items-center flex-col gap-y-4 text-center"
    >
      <div className="text-lg">데이터를 다운 받으시겠습니까?</div>
      <div className="flex w-full gap-x-4">
        {lists?.length>0?
          <CSVLink
          className="w-1/2 block bg-gray-100 rounded px-3 py-1 text-lg hover:bg-gray-200 cursor-pointer"
          headers={headers}
          data={lists}
          filename={tab==="음식DB요청"?"healiary_addDB":"CancerGPT_addDB"}
          target="_blank"
        >
          확인
        </CSVLink>:
        <span
        className="w-1/2 block bg-yellow-400 rounded px-3 py-1 text-lg hover:bg-gray-500 "
        >
          다운중..
        </span>
        }
        <span
          className=
          {lists?.length>0?
            "w-1/2 block rounded bg-gray-100 px-3 py-1 text-lg hover:bg-gray-200 cursor-pointer"
            :"w-full block rounded bg-gray-100 px-3 py-1 text-lg hover:bg-gray-200 cursor-pointer"
          }
          
          onClick={showCSVdown}
        >
          취소
        </span>
      </div>
    </div>
    </>
  )
}

export default CsvModal