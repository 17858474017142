import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecommendMealForShare } from '../../../../features/mobile/recommendSlice';
import { Swiper, SwiperSlide } from "swiper/react";
import HeadCont from '../../components/common/HeadCont';
import RecommendCard from '../../components/recommend/RecommendCard';
import NutritionInner from '../../components/common/NutritionInner';
import NutritionGraph from '../../components/common/NutritionGraph';

const BookmarkedMealDetail = () => {
    const dispatch = useDispatch()
    const { type } = useParams()
    const { recommendForShareStatus, recommendListsForShare } = useSelector((state) => state.recommend)
    const [tabNo, setTabNo] = useState(1)
    const tabs = [{ id: 1, title: '추천식단' }]

    const onSetTabNo = (no) => {
        setTabNo(no)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(fetchRecommendMealForShare(type))
    }, [dispatch, type])

    return (
        <>
            <HeadCont transparent={'white'} />
            <section className='mb-28'>
                {recommendForShareStatus === 'success' ?
                    <>
                        <div className='px-6 border-b border-gray_60'>
                            <ul className='flex'>
                                {tabs.map(tab =>
                                    <li key={tab.id} className={'pt-2 pb-3 w-1/3 text-center text-body3_700 ' + (tabNo === tab.id ? 'text-gray_180 border-b-[2px] border-black' : 'text-gray_100')}
                                        onClick={() => onSetTabNo(tab.id)}
                                    >{tab.title}</li>)}
                            </ul>
                        </div>
                        <div className='pt-4'>
                            <Swiper
                                direction={"horizontal"}
                                spaceBetween={16}
                                slidesPerView={1.15}
                                slideToClickedSlide={true}
                                centeredSlides={true}
                            >
                                {recommendListsForShare[tabNo - 1]?.recommendMealH.mealGroups.map((list, idx) => (
                                    <SwiperSlide key={idx}>
                                        <RecommendCard list={list} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div className='w-full h-[1px] bg-gray_40 my-6'></div>
                        <div className='px-6'>
                            <h1 className='text-body2_700 text-black mb-5'>하루 영양 정보</h1>
                            <NutritionInner title={'총 열량'} data={recommendListsForShare[tabNo - 1]?.recommendMealH.kcal} imgUrl={'/images/mobile/image/rice.svg'} unit={'kcal'} />
                            <div className='w-full h-[1px] bg-gray_40 my-4'></div>
                            <NutritionInner title={'총 단백질'} data={recommendListsForShare[tabNo - 1]?.recommendMealH.proteinG} imgUrl={'/images/mobile/image/fish.svg'} unit={'g'} />
                            <NutritionGraph datas={recommendListsForShare[tabNo - 1]?.recommendMealH} />
                            <p className='text-caption2_400 text-gray_160 mt-4'>추천식단대로 드신다면 위와 같이 영양소를 골고루<br />섭취하실 수 있습니다.</p>
                        </div>
                    </>
                    : null}
            </section>
        </>
    );
};

export default BookmarkedMealDetail;