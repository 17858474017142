import React, { useRef, useState } from "react";
import { mobileTypeFn } from "../logic";

//클릭시 option 뜨는 select 박스 / input 동시에 쓸수 있음
const InputSelectBox2 = ({
  optionLists,
  labelN,
  htmlforN,
  nameTitle,
  type,
  setValue,
  value,
  placeH,
  textA,
  textAHeight,
  onClickAction,
  inputCount,
}) => {
  const mainInputRef = useRef(null);
  const [openBox, setOpenBox] = useState(false);
  const closeLists = () => {
    setValue("");
    setOpenBox(!openBox);
  };
  const onSetValue = (name) => {
    setValue(name);
    setOpenBox(false);
  };

  const onChangeAction = (e, labelN) => {
    if (labelN === "닉네임") {
      e.target.value = e.target.value.slice(0, 8);
      setValue(e.target.value);

      // if (e.target.value.length > MAX_LENGTH) {
      //   deleteAll();
      // }
      // if (e.target.value.length <= MAX_LENGTH) {
      //   e.target.value = e.target.value.slice(0, MAX_LENGTH);
      //   setValue(e.target.value);
      // }
    } else if (labelN === "자기소개") {
      setValue(e.target.value.slice(0, 20));
    } else if (labelN === "문의 내용") {
      setValue(e.target.value.slice(0, 2000));
    } else {
      setValue(e.target.value);
    }
  };

  // ios input buffer 기능으로 인해 한글 초과 입력시 발생되는 버그 해결하기 위함
  const deleteBuffer = () => {
    // input의 focus를 임시로 생성한 곳에 옮기고 다시 원래 input으로 옮겨온 뒤 임시는 삭제

    const hiddenInp = document.createElement("input");
    hiddenInp.setAttribute("type", "text");

    document.body.prepend(hiddenInp);
    hiddenInp.focus();

    setTimeout(() => {
      mainInputRef.current?.focus();
      hiddenInp.remove();
    }, 1);
  };

  const onDeleteBuffer = (e) => {
    if (labelN === "닉네임") {
      e.keyCode === 8 && inputCount >= 8 && deleteBuffer();
    } else if (labelN === "자기소개") {
      e.keyCode === 8 && inputCount >= 20 && deleteBuffer();
    } else if (labelN === "문의 내용") {
      e.keyCode === 8 && value.lengtn >= 2000 && deleteBuffer();
    }
  };
  return (
    <div>
      <label htmlFor={htmlforN} className="text-caption1_500 text-gray_160">
        {labelN}
      </label>
      <div className="relative">
        {textA ? (
          <textarea
            type={type || "text"}
            id={nameTitle}
            name={nameTitle}
            ref={mainInputRef}
            placeholder={placeH}
            onChange={(e) => onChangeAction(e, labelN)}
            onKeyDown={(e) =>
              mobileTypeFn() === "iphone" ? onDeleteBuffer(e) : null
            }
            onClick={onClickAction}
            value={value || ""}
            className={
              "w-full appearance-none rounded border border-gray_80 p-4 text-body2_500l text-black focus:border-gray_160 focus:outline-none focus:ring-0 " +
              textAHeight
            }
          />
        ) : (
          <>
            <input
              type={type || "text"}
              id={nameTitle}
              name={nameTitle}
              ref={mainInputRef}
              placeholder={placeH}
              onChange={(e) => onChangeAction(e, labelN)}
              onKeyDown={(e) =>
                mobileTypeFn() === "iphone" ? onDeleteBuffer(e) : null
              }
              onClick={onClickAction}
              value={value || ""}
              className="w-full p-4 text-black border rounded appearance-none border-gray_80 text-body2_500l focus:border-gray_160 focus:outline-none focus:ring-0"
            />
          </>
        )}

        {optionLists !== undefined && (
          <img
            onClick={closeLists}
            src="/images/mobile/icon/reverse_tri.svg"
            alt="닫기 아이콘"
            className="absolute right-4 top-5"
          />
        )}
      </div>
      {optionLists !== undefined && (
        <ul
          className={
            "z-50 max-h-[196px] flex-col overflow-y-scroll border border-gray_80 bg-gray_10 " +
            (openBox ? "h-full rounded-md border opacity-100" : "hidden")
          }
        >
          {optionLists !== null &&
            optionLists.map((data, idx) => (
              <li
                key={idx}
                className="flex px-[12px] py-[11px] text-body2_500l text-black active:bg-orange_10"
                onClick={() => onSetValue(data.name, data.code)}
              >
                {data.name}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default InputSelectBox2;
