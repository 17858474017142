import React, { useEffect, useState } from "react";

const FilterTab = ({ text, filterNamed, onClick }) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(filterNamed === text);
  }, [filterNamed, text]);

  const handleClick = () => {
    if (isSelected) {
      // 이미 클릭한 탭 클릭시 실행 onClick에 빈값 보내서 필터 초기화 및 텍스트 컬러 초기화
      onClick("");
      setIsSelected(false);
    } else {
      // 새 탭 클릭시 실행
      onClick(text);
      setIsSelected(true);
    }
  };

  return (
    <li
      className={
        "flex justify-center px-2 cursor-pointer text-gray_140 text-caption1_600 " +
        (isSelected ? " text-orange_80 w-[120px] " : "")
      }
      onClick={handleClick}
    >
      {isSelected && (
        <img
          src="/images/admin/icon/checked_primary.svg"
          className="px-1"
          alt="체크 아이콘"
        />
      )}
      {text}
    </li>
  );
};

export default FilterTab;

// 기존 나이 순 탭
// const FilterTab = ({ text, filterNamed, onClick }) => {
//   const checkOk = filterNamed === text;

//   return (
//     <li
//       className={
//         "flex justify-center px-2 cursor-pointer text-gray_140 text-caption1_600 " +
//         (checkOk ? " text-orange_80 w-[120px] " : "")
//       }
//       onClick={onClick}
//     >
//       {checkOk && (
//         <img
//           src="/images/admin/icon/checked_primary.svg"
//           className="px-1"
//           alt="체크 아이콘"
//         />
//       )}
//       {text}
//     </li>
//   );
// };
// export default FilterTab;
