import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMyActivity,
  onResetBookmarkedStatus,
} from "../../../../features/mobile/otherSlice";
import {
  onSetLikeFood,
  fetchFoodInfo,
} from "../../../../features/mobile/recommendSlice";
import HeadCont from "../../components/common/HeadCont";
import MenuBar from "../../components/common/MenuBar";
import TitleBox from "../../components/common/TitleBox";
import MealCard from "../../components/mypage/MealCard";
import NoData from "../../components/mypage/NoData";
import Dialog from "../../components/common/Dialog";
import FoodDetailDialog from "../../components/recommend/FoodDetailDialog";

const DisLike = () => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { bookmarkedStatus, dislikeFoods } = useSelector(
    (state) => state.others
  );
  const [openDialog, setOpenDialog] = useState({ status: false, id: null });
  const [openFoodDialog, setOpenFoodDialog] = useState({
    open: false,
    foodId: null,
  });

  const onDelete = () => {
    let params = {
      userId: loginInfo.userId,
      foodId: openDialog.id,
      like: null,
    };
    dispatch(onSetLikeFood(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          setOpenDialog({ status: false, id: null });
          dispatch(fetchMyActivity({ userId: loginInfo.userId, type: 4 }));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let params = { userId: loginInfo.userId, type: 4 };
    if (!dislikeFoods || dislikeFoods?.length === 0)
      dispatch(fetchMyActivity(params));
    return () => dispatch(onResetBookmarkedStatus());
    // eslint-disable-next-line
  }, [dispatch, loginInfo.userId]);

  return (
    <>
      <HeadCont />
      <section
        className={
          "mb-6 mt-14 px-6 " + (openFoodDialog.open ? "no-scroll" : "")
        }
      >
        <TitleBox
          title={"싫어요한 음식"}
          font={"text-subtitle1"}
          textColor={"text-gray_180"}
        />
        <ul className="mb-28 mt-[40px] flex flex-col gap-4">
          {bookmarkedStatus === "success" || dislikeFoods?.length > 0 ? (
            dislikeFoods && dislikeFoods.length > 0 ? (
              dislikeFoods.map((list, idx) => (
                <MealCard
                  key={idx}
                  type={list.foodType}
                  image={list.representativeImage}
                  name={list.foodname}
                  bgColor={"bg-white"}
                  onAction={() => {
                    dispatch(
                      fetchFoodInfo({
                        userId: loginInfo.userId,
                        foodId: list.foodId,
                      })
                    );
                    setOpenFoodDialog({ open: true, foodId: list.foodId });
                  }}
                  onDeleteAction={(e) => {
                    e.stopPropagation();
                    setOpenDialog({ status: true, id: list.foodId });
                  }}
                />
              ))
            ) : (
              <NoData
                imgUrl={"/images/mobile/image/bookmark_like.png"}
                title={"싫어하는 음식이"}
                title2={"싫어하는 음식을"}
              />
            )
          ) : null}
        </ul>
      </section>
      {openDialog.status && (
        <Dialog
          innerClass={"p-4"}
          title={"싫어요 삭제"}
          desc={"해당 음식을 목록에서 삭제할까요? "}
          leftBtnTitle={"네, 삭제할게요"}
          rightBtnTitle={"아니요"}
          onLeftBtnAction={onDelete}
          onRightBtnAction={() => setOpenDialog({ status: false, id: null })}
          onCoverAction={() => setOpenDialog({ status: false, id: null })}
        />
      )}
      <FoodDetailDialog
        openFoodDialog={openFoodDialog}
        setOpenFoodDialog={setOpenFoodDialog}
        recipeHidden={true}
      />
      <MenuBar />
    </>
  );
};

export default DisLike;
