import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveLoginInfo } from "../../../../features/mobile/commonSlice";
import { regMobile } from "../../components/logic";
import api from "../../../../common/api";
import HeadCont from "../../components/common/HeadCont";
import InputBox from "../../components/common/InputBox";
import TitleBox from "../../components/common/TitleBox";
import Btn from "../../components/common/Btn";
import Dialog from "../../components/common/Dialog";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const [loginDatas, setLoginDatas] = useState({ id: "", pass: "", token: "" });
  const [active1, setActive1] = useState("default");
  const [active2, setActive2] = useState("default");
  const [errorMsg, setErrorMsg] = useState({ msg1: "", msg2: "" });
  const [openDialog, setOpenDialog] = useState(false);
  const idRef = useRef(null);
  const passRef = useRef(null);

  const onChangeInput1 = (e) => {
    let len = e.target.value.length;
    setLoginDatas({ ...loginDatas, id: e.target.value.replaceAll("-", "") });
    if (len === 3 || len === 8) {
      setLoginDatas({ ...loginDatas, id: e.target.value + " " });
    }
  };

  const onChangeKeyDown1 = (e) => {
    let len = e.target.value.length;
    if (e.keyCode === 8 && (len === 9 || len === 4)) {
      setLoginDatas({
        ...loginDatas,
        id: e.target.value.slice(0, e.target.value.length - 1),
      });
    }
  };

  const onChangeInput2 = (e) => {
    setLoginDatas({ ...loginDatas, pass: e.target.value });

    if (e.target.value?.length >= 1) setActive2("on");
    // else setActive2("ready");
  };

  const onDeleteValue = (type) => {
    if (type === "id") {
      setLoginDatas({ ...loginDatas, id: "" });
      setErrorMsg({ ...errorMsg, msg1: "" });
      setActive1("default");
      idRef.current?.focus();
    } else {
      setLoginDatas({ ...loginDatas, pass: "" });
      setErrorMsg({ ...errorMsg, msg2: "" });
      setActive2("default");
      passRef.current?.focus();
    }
  };

  const onLogin = async (e) => {
    e.preventDefault();
    const sessionToken = window.sessionStorage.getItem("token");
    if (active1 === "on" && active2 === "on") {
      let params = {
        userId: loginDatas.id.replaceAll(" ", "").replaceAll("-", ""),
        pass: loginDatas.pass,
        firebaseToken: sessionToken,
      };
      const res = await api.post(`/user/login`, params);
      try {
        console.log(res);
        if (res.data.ok) {
          setOpenDialog(true);
          const main = document.querySelector(".main");
          main.style.overflow = "hidden";
          main.style.width = "100%";
          dispatch(saveLoginInfo({ ...res.data, existPass: true }));
          setLoginDatas({ id: "", pass: "", token: "" });
          idRef.current.value = "";
          passRef.current.value = "";
        } else {
          setErrorMsg({ ...errorMsg, msg2: res.data.error });
          setLoginDatas({ id: "", pass: "", token: "" });
          setActive2("");
          idRef.current.focus();
          idRef.current.value = "";
          passRef.current.value = "";
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    let focused = true;
    if (focused) idRef.current?.focus();
    return () => (focused = false);
  }, [idRef]);

  useEffect(() => {
    if (loginDatas.id?.length > 7) {
      if (regMobile(loginDatas.id.replaceAll(" ", ""))) {
        setErrorMsg({ msg1: "", msg2: "" });
        setActive1("on");
      } else {
        setErrorMsg({ msg1: "잘못된 형식의 휴대폰번호 입니다", msg2: "" });
        setActive1("ready");
      }
    } else if (loginDatas.id?.length === 0) {
      setActive1("default");
    } else if (loginDatas.pass?.length > 1) {
      setActive2("on");
    } else if (loginDatas.pass?.length === 0) {
      setErrorMsg({ ...errorMsg, msg2: "" });
      setActive1("default");
    }
    // eslint-disable-next-line
  }, [loginDatas.id, loginDatas.pass]);

  return (
    <>
      {/* <HeadCont rightIcon={"no icon"} /> */}
      <form onSubmit={onLogin} className="mx-6 mt-[80px] flex flex-col gap-6">
        {/* <TitleBox option={2} title={"휴대폰번호로 로그인"} /> */}
        {/* 디자인상 3번째, 8번째 자리에 띄어쓰기를 해야하기 때문에 type='number' 대신 'text'로 진행 */}
        <InputBox
          option={"label"}
          // label={"휴대폰 번호"}
          placeholder={"휴대폰번호 입력"}
          inputM={"tel"}
          refName={idRef}
          id={"phone"}
          nameTitle={"phone"}
          onChangeAction={(e) => onChangeInput1(e)}
          onChangeKeyDown={(e) => onChangeKeyDown1(e)}
          value={loginDatas.id || ""}
          active={active1}
          onClickAction={() => onDeleteValue("id")}
          errorMsg={errorMsg.msg1}
          login={true}
        />
        <InputBox
          option={"passlabel"}
          // label={"비밀번호"}
          placeholder={"비밀번호 입력"}
          refName={passRef}
          id={"pass"}
          nameTitle={"pass"}
          length={4}
          onChangeAction={(e) => onChangeInput2(e)}
          value={loginDatas.pass || ""}
          active={active2}
          onClickAction={() => onDeleteValue("pass")}
          errorMsg={errorMsg.msg2}
        />

        {/* 디자인대로 로그인 버튼 바로 위에 fixed 위치했다가 갤럭시에서 flot되어 비밀번호 위에 올라가는 현상으로 디자인 변경 */}
        {/* <div className="space-y-2 text-center">
          <p className="text-sm font-light">로그인에 문제가 있으신가요?</p>
          <ul className="flex justify-center text-lg font-bold text-gray_160">
            <li onClick={() => navigate(`/findpass`)}>비밀번호 찾기</li>
            <span className="mx-2 my-1 w-[1px] bg-gray_60"></span>
            <li onClick={() => navigate(`/sign`)}>다른방법으로 로그인</li>
          </ul>
        </div> */}
        <Btn
          bgColor={"bg-main"}
          textColor={"text-white font-semibold text-lg"}
          borderColor={"border border-main"}
          title={"로그인"}
          onAction={onLogin}
          // active={active1 === "on" && active2 === "on" ? "on" : "ready"}
          active={"on"}
        />
      </form>
      {openDialog && (
        <Dialog
          innerClass={"py-4 pl-6 pr-5"}
          btnClass={"mt-8"}
          title={"로그인 성공!"}
          desc={`${loginInfo.nickName}님 안녕하세요!💕`}
          onRightBtnAction={() => navigate(`/home`)}
          onCoverAction={() => navigate(`/home`)}
        />
      )}
    </>
  );
};

export default SignIn;
