import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchSurveyResult } from "../../../../features/mobile/questionHealthSlice";
import { checkQuestionsComplete } from "../../../../features/mobile/otherSlice";
import { makeRecommendMeal } from "../../../../features/mobile/recommendSlice";

// import { pointFn } from "../../components/logic";
// import HeadCont from "../../components/common/HeadCont";
// import MenuBar from "../../components/common/MenuBar";
// import TitleBox from "../../components/common/TitleBox";
// import ResultCard from "../../components/result/ResultCard";
// import IdCard from "../../components/result/IdCard";
// import HealthCard from "../../components/result/HealthCard";
// import Dialog from "../../components/common/Dialog";

import Btn from "../../components/common/Btn";
import useGAEvent from "../../hooks/useGAEvent";
import AnimateCard from "../../components/common/AnimateCard";
import HomeResultCard from "../../components/result/HomeResultCard";

// type of weightInfo
const weightInfoType = 4;

// index of each info within resultLists.datas
const weightInfoIdx = 4;
const foodInfoIdx = 0;
const proteinInfoIdx = 1;
const foodBalanceInfoIdx = 2;
const cationFoodInfoIdx = 3;

const selectionArr = [
  { name: "체중관리", type: 4 },
  { name: "영양 섭취 목표", type: 1 },
  { name: "영양 밸런스", type: 3 },
  { name: "단백질 섭취 안내", type: 2 },
  { name: "주의식품", type: 5 },
  { name: "맞춤 식단", type: 0 },
];

const HealthInfoComponent = ({ healthResult, setHealthResult }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef([]);

  const { sendSingleEvent } = useGAEvent();

  const { loginInfo } = useSelector((state) => state.commonApp);
  const { resultLists } = useSelector((state) => state.questionHealth);
  const { questionsCompleteCheck } = useSelector((state) => state.others);
  const [errorMsg, setErrorMsg] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  // const resultDatas = resultLists?.datas || null;
  const [resultDatas, setResultDatas] = useState(null);
  const [makeRecommendMealReady, setmakeRecommendedMealReady] = useState(true);
  // const [openAll, setOpenAll] = useState(false);
  const [openStates, setOpenStates] = useState({ 0: false });

  // const [clickMenu, setClickMenu] = useState(false);
  const [moveMenu, setMoveMenu] = useState("");
  const [moveRecommend, setmoveRecommend] = useState("");

  // stores the selected categories type. (initial type: 4)
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const datas = [
    {
      id: 1,
      title: "하루 필요 열량",
      data: resultLists?.needKcal,
      unit: "kcal",
    },
    {
      id: 2,
      title: "하루 필요 단백질",
      data: resultLists?.needProtein,
      unit: "g",
    },
    { id: 3, title: "목표 체중", data: resultLists?.weightState },
  ];

  useEffect(() => {
    // return () => {

    if (moveMenu !== "") {
      navigate(moveMenu);
      return;
    }
    if (moveRecommend !== "") {
      // onMakeRecommend();
      navigate(moveRecommend);
      return;
    }
    //   if (location.pathname === "/result") {
    //     navigate(-1); // 직접 url로 경로를 주게 되면 그 전 히스토리가 쌓이기때문에 이동한 뒤 뒤로가기 버튼을 사용하면 다시 설문조사로 돌아가게 됨 / 그래서 -1로 사용
    //   }
    // setClickMenu(false);
    // };
  }, [location, moveMenu, moveRecommend]);

  //   const separateUnitAndText = (text) => {
  //     const updatedText = text?.replace(/([^ ])\(/g, "$1 (");
  //     return updatedText;
  //   };

  useEffect(() => {
    // window.scrollTo(0, 0);

    dispatch(checkQuestionsComplete(loginInfo.userId));
    dispatch(fetchSurveyResult(loginInfo.userId))
      .unwrap()
      .then((res) => {
        setResultDatas(res.datas);
      });

    // eslint-disable-next-line
  }, [selectedTypes]);

  //   useEffect(() => {
  //     const closeAllButtonHeight = 28;
  //     const headerHeight = 56;
  //     if (openAll) {
  //       // select all the types
  //       setTimeout(
  //         window.scrollTo({
  //           behavior: "smooth",
  //           top: ref.current[0].offsetTop - closeAllButtonHeight - headerHeight,
  //         }),
  //         300
  //       );
  //     }
  //   }, [openAll]);

  // initializing sortedData and SelectedType with Weight info
  useEffect(() => {
    // when the fetchSurveyResults succeeds
    if (sortedData.length === 0 && resultDatas) {
      // initializing it with 체중관리. (its the 4th idx)
      setSortedData([
        resultDatas[weightInfoIdx],
        resultDatas[foodInfoIdx],
        resultDatas[foodBalanceInfoIdx],
        resultDatas[proteinInfoIdx],
        resultDatas[cationFoodInfoIdx],
      ]);
      setSelectedTypes([weightInfoType]);
    }
  }, [resultDatas, sortedData]);

  // 나의 건강관리 목표 open close
  const handleToggle = (id, name) => {
    setOpenStates({ [id]: !openStates[id] });
  };

  // ! 건강 설문조사 후 실행
  useEffect(() => {
    if (healthResult) {
      handleToggle(0);
      // setHealthResult(false);
      navigate(".", { state: { healthResult: null } });
    }
    // setHealthResult(false);
  }, [healthResult]);

  const handleSelection = (data, idx) => {
    // check if it will openAll
    // if (selectedTypes.length === 5 && !selectedTypes?.includes(data.type)) {
    //   setOpenAll(true);
    // } else {
    //   setOpenAll(false);
    // }
    // toggle
    if (!(selectedTypes.length === 1 && selectedTypes?.includes(data.type))) {
      setSelectedTypes([data.type]);
      // setSelectedTypes((prev) =>
      //   prev.includes(data.type)
      //     ? prev.filter((type) => type !== data.type)
      //     : [...prev, data.type]
      // );

      // scroll
      //   if (ref.current && ref.current.length > 1) {
      //     setTimeout(
      //       () =>
      //         ref.current[idx]?.scrollIntoView({
      //           inline: "center",
      //           behavior: "smooth",
      //         }),
      //       100
      //     );
      //   }
    }
    // send GA event

    sendSingleEvent({
      event: "nutrition_goal_detail",
      label: "nutrition_detail_button_name",
      value: data.name,
    });
  };

  // the closed State of the component
  const closedComponent = ({ bgcolor, id, title, textcolor, textsize }) => (
    <div
      className={`flex flex-col items-center justify-between rounded-[20px] px-3 ${bgcolor} `}
      onClick={() => {
        handleToggle(id, title);
      }}
    >
      <span
        className={`font-medium leading-[18px] tracking-tight underline ${textsize} ${textcolor}`}
      >
        {title}
      </span>
      <img
        alt={"arrowRight"}
        src="/images/mobile/icon/arrow_right.svg"
        className="rotate-90 transform"
      />
    </div>
  );

  const onMakeRecommend = () => {
    // setmoveRecommend("/recommend");
    if (
      !questionsCompleteCheck.healthComplete ||
      !questionsCompleteCheck.dietLifeComplete
    )
      setmoveRecommend("/recommend");
    // navigate(`/recommend`);
    else {
      setmakeRecommendedMealReady(false);
      dispatch(makeRecommendMeal({ userId: loginInfo.userId })) //이 식단 불러오기 로직 - 맞춤식단 받기 버튼 눌렀을때만 부르기
        .unwrap()
        .then((res) => {
          if (res.ok) setmoveRecommend("/recommend");
          else {
            setErrorMsg(res.error);
            setOpenDialog(true);
            setmakeRecommendedMealReady(true);
          }
        })
        .catch((err) => {
          setmakeRecommendedMealReady(true);
          console.log(err);
        });
    }
  };

  return (
    <>
      <AnimateCard
        id={0}
        openStates={openStates}
        closedComponent={closedComponent({
          textcolor: "text-gray_100",
          textsize: "text-neutral-400 text-[15px]",
          id: 0,
          title: "나의 건강관리 목표",
        })}
        height={30}
        contentComponent={
          //   <HealthCard
          //     // myInfoList={resultLists}
          //     // myInfo={myInfo}
          //     loginInfo={loginInfo}
          //     handleToggle={() => handleToggle(0, "나의 건강관리 목표")}
          //   />
          <div className="inline-flex flex-col items-center gap-4">
            <div className="mx-auto flex flex-wrap justify-center gap-2.5">
              {selectionArr.map((data, idx) => (
                <div
                  className={`mb-2.5 px-[10px] py-[5px] ${
                    selectedTypes.includes(data.type)
                      ? "border border-[#434343] bg-[#434343]"
                      : "border border-gray_60 bg-white"
                  } shrink rounded-[20px]`}
                  //   style={{ boxShadow: "0px 3px 7px #EBE7C9" }}
                  key={idx}
                  onClick={() => handleSelection(data, idx)}
                >
                  <span
                    className={`text-[16px] font-semibold leading-[18px] tracking-[0.24px] ${
                      selectedTypes.includes(data.type)
                        ? "text-white"
                        : "text-gray_200"
                    }`}
                  >
                    {data.name}
                  </span>
                </div>
              ))}
            </div>
            <div>
              {sortedData?.length > 0 ? (
                <ul>
                  {sortedData
                    // .filter((data) => selectedTypes.includes(data.type))
                    ?.map((data, idx) => (
                      <li
                        key={idx}
                        ref={(e) => (ref.current[idx] = e)}
                        className={`${
                          selectedTypes.includes(data?.type) ? "" : "hidden"
                        }`}
                      >
                        <HomeResultCard data={data} />
                      </li>
                    ))}

                  <li
                    className={`space-y-4 px-6 pb-5 text-center ${
                      selectedTypes?.includes(0) ? "" : "hidden"
                    }`}
                    // ref={(e) => (ref.current[5] = e)}
                  >
                    <div className="text-subtitle3 text-black">
                      어떻게 드셔야 할지 막막하신가요?
                    </div>
                    <p className="text-body3_700 text-gray_160">
                      힐리어리가 추천하는 식단을 받아보세요.
                      <br />
                      환자분의 건강상태와
                      <br />
                      식습관에 맞춘 식단을 드립니다.
                    </p>
                    <Btn
                      id={"checkMealPlan"}
                      bgColor={"bg-main"}
                      textColor={"text-white"}
                      borderColor={"border border-main"}
                      title={"맞춤 추천 식단 확인하기"}
                      active={makeRecommendMealReady ? "on" : "ready"}
                      onAction={onMakeRecommend}
                    />
                  </li>
                </ul>
              ) : (
                <div className="h-10">
                  <div>{/* <  /> / */}</div>
                </div>
              )}
              <div
                className="flex flex-col items-center pt-[24px]"
                onClick={() => handleToggle()}
              >
                <div className="text-center text-[15px] font-medium leading-[18px] tracking-tight text-neutral-400 underline">
                  닫기
                </div>
                <img
                  alt={"arrowRight"}
                  src="/images/mobile/icon/arrow_right.svg"
                  className="mx-auto mb-4 h-6 w-6 rotate-[270deg] transform"
                />
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default HealthInfoComponent;
