import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSetLikeDay,
  onSetLikeFood,
} from "../../../../features/mobile/recommendSlice";
import { ReactComponent as Like } from "../../../../svg/like.svg";
import { ReactComponent as DisLike } from "../../../../svg/dislike.svg";

const LikeBtns = ({
  option,
  checkStatus,
  id,
  likeTitle,
  marginR,
  foodName,
}) => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const [isChecked, setIsChecked] = useState(
    checkStatus === null ? null : !checkStatus ? false : true
  );
  const onCheckToggle = (type) => {
    if (option === "day") {
      //식단
      if (type === "like") {
        //좋아요
        let params = {
          id: id,
          like: isChecked === true ? null : isChecked === null ? true : true,
        };
        dispatch(onSetLikeDay(params));
        setIsChecked(params.like);
      } else {
        //싫어요
        let params = {
          id: id,
          like: isChecked === true ? false : isChecked === null ? false : null,
        };
        dispatch(onSetLikeDay(params));
        setIsChecked(params.like);
      }
    } else if (option === "food") {
      //음식
      if (type === "like") {
        //좋아요

        let params = {
          userId: loginInfo.userId,
          foodId: id,
          like: isChecked === true ? null : isChecked === null ? true : true,
        };

        // 음식 좋아요, 좋아요 취소 GA 이벤트
        params.like === true
          ? window.dataLayer.push({
              event: "liked_food_name",
              food_id: id,
              food_name: foodName,
            })
          : window.dataLayer.push({
              event: "unliked_food_name",
              food_id: id,
              food_name: foodName,
            });

        dispatch(onSetLikeFood(params));
        setIsChecked(params.like);
      } else {
        //싫어요

        let params = {
          userId: loginInfo.userId,
          foodId: id,
          like: isChecked === true ? false : isChecked === null ? false : null,
        };

        // 음식 싫어요, 싫어요 취소 GA 이벤트
        params.like === false
          ? window.dataLayer.push({
              event: "disliked_food_name",
              food_id: id,
              food_name: foodName,
            })
          : window.dataLayer.push({
              event: "undisliked_food_name",
              food_id: id,
              food_name: foodName,
            });

        dispatch(onSetLikeFood(params));
        setIsChecked(params.like);
      }
    }
  };

  return (
    <>
      <li
        id="foodLike"
        onClick={() => onCheckToggle("like")}
        className={
          ` flex items-center rounded-[36px] p-3  ${marginR} ` +
          (isChecked === null
            ? "bg-gray_20"
            : !isChecked
            ? "bg-gray_20"
            : "bg-main")
        }
      >
        <Like
          stroke={
            isChecked === null ? "#555555" : !isChecked ? "#555555" : "#fff"
          }
        />
        {likeTitle && (
          <span
            className={
              "text-caption1_60 ml-1 " +
              (isChecked === null
                ? "text-gray_160"
                : !isChecked
                ? "text-gray_160"
                : "text-white")
            }
          >
            {likeTitle}
          </span>
        )}
      </li>
      <li
        id="foodDislike"
        onClick={() => onCheckToggle("dislike")}
        className={
          ` flex items-center rounded-[36px] p-3 ${marginR} ` +
          (isChecked === null
            ? "bg-gray_20"
            : !isChecked
            ? "bg-main"
            : "bg-gray_20")
        }
      >
        <DisLike
          stroke={
            isChecked === null ? "#555555" : !isChecked ? "#fff" : "#555555"
          }
        />
      </li>
    </>
  );
};

export default LikeBtns;
