import React from "react";
import { useSelector } from "react-redux";
import TableHeader from "../common/TableHeader";
import ProgressBar from "../graph/ProgressBar";
import CalorieProteinGraph from "../graph/CalorieProteinGraph";
import EatGraphBoxKcalProtein from "./EatGraphBoxKcalProtein";

const EatGraphBox = ({ dayNo }) => {
  const {
    averageKCal,
    averageProtein,
    needKCal,
    needProtein,
    eatKCalList,
    eatProteinList
  } = useSelector((state) => state.management);

  const calProteinCard = (tableHeaderTit,calDatas,areaNoData,optionData,barHColorData,calorieBool) =>{
    return(
      <div className="space-y-4">
        <TableHeader title={tableHeaderTit} />
        <div className="border rounded-md">
          <CalorieProteinGraph
            dayNo={dayNo}
            datas={calDatas}
            areaNo={areaNoData}
            option={optionData}
            barHColor={barHColorData}
            calorie={calorieBool}
          />
        </div>
      </div>
    )
  }

  const progressBarRepeat = (title,averageVal,needVal,optionVal,unit,subTitleVal,isProteinBool) =>{
    return(
      <div className="flex items-center gap-4">
        <h3 className="text-caption1_600 text-black w-24">
          {title}
        </h3>
        <ProgressBar
          progress={(averageVal / needVal) * 100}
          option={optionVal}
          title={averageVal}
          isProtein={isProteinBool}
          subTitle={`${subTitleVal} ${isProteinBool?needVal?.toFixed(1):Math.ceil(needVal) || "-"}${unit}`}
        />
        <h2 className="w-10 text-gray-40 text-body_2">
          {(parseInt((averageVal / needVal) * 100)) || "-"}%
        </h2>
      </div>
    )
  }
  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <EatGraphBoxKcalProtein averageKCal={averageKCal} needKCal={needKCal}
          averageProtein={averageProtein} needProtein={needProtein} />
      </div>
      <div className="space-y-4">
        <TableHeader title={"하루 필요량 대비 평균 섭취량"} />
        {progressBarRepeat("열량 (kcal)",averageKCal,needKCal,1,"kcal","하루 필요 열량",false)}
        {progressBarRepeat("단백질양 (g)",averageProtein,needProtein,2,"g","하루 필요 단백질양",true)}
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        {calProteinCard("하루 섭취 열량 kcal",eatKCalList,needKCal,1,"red_h",true)}
        {calProteinCard("하루 섭취 단백질양 g",eatProteinList,needProtein,2,"green_h",false)}
      </div>
    </>
  );
};

export default EatGraphBox;
