import React from "react";
import CustomDropdown from "./customDropdown";
const CancerType = ({
  tapSelect,
  hospitalAreaList,
  hospitalCancerList,
  nursingHospitalAreaList,
  targetCancer,
  setTargetCancer,
  setTargetArea,
}) => {
  return (
    <div>
      <section className="flex flex-col items-start gap-[14px] border-b border-gray_60 bg-white px-[16px] py-[14px]">
        <p className="text-caption1_700"> 지역 선택 </p>
        <CustomDropdown
          options={
            tapSelect === "cancerTreatmentCenter"
              ? hospitalAreaList
              : nursingHospitalAreaList
          } // 병원
          // options2={nursingHospitalAreaList} // 요양병원
          setTargetArea={setTargetArea}
        />
      </section>

      {tapSelect === "cancerTreatmentCenter" ? (
        <section className="flex flex-col items-start gap-[14px] border-b border-gray_60 bg-white px-[16px] py-[14px]">
          <p className="text-caption1_700"> 암 종류 선택 </p>
          <div className="grid w-full grid-cols-2 items-start gap-[14px] pr-0">
            {hospitalCancerList.map((cancerList, i) => {
              return (
                <button
                  key={i}
                  className={`flex w-full shrink-0 flex-wrap content-center items-center justify-center gap-[10px] rounded-[39px] border border-main p-[10px] text-gray_160 ${
                    cancerList.name === targetCancer
                      ? "bg-main text-white"
                      : null
                  }`}
                  onClick={() => {
                    if (cancerList.name === targetCancer) {
                      setTargetCancer("");
                      return;
                    }
                    setTargetCancer(cancerList.name);
                  }}
                >
                  <span className="text-caption1_700 ">
                    {cancerList.name} ({cancerList.count}개)
                  </span>
                </button>
              );
            })}
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default CancerType;
