import React, { useState } from "react";
import Btn from "../common/Btn";
import HeadCont from "../common/HeadCont";
import TitleBox from "../common/TitleBox";

const ProfileCharacterChange = ({
  setCharacterChange,
  photo,
  setPhoto,
  oldPhoto,
}) => {
  const [changeC, setChageC] = useState(null);

  const datas = [
    { id: 1, imgUrl: "/images/mobile/character/girl.svg", name: "girl" },
    { id: 2, imgUrl: "/images/mobile/character/woman.svg", name: "woman" },
    { id: 3, imgUrl: "/images/mobile/character/boy.svg", name: "boy" },
    { id: 4, imgUrl: "/images/mobile/character/man.svg", name: "man" },
    {
      id: 5,
      imgUrl: "/images/mobile/character/rabbit_pink.svg",
      name: "rabbit_pink",
    },
    {
      id: 6,
      imgUrl: "/images/mobile/character/rabbit_blue.svg",
      name: "rabbit_blue",
    },
  ];
  const onChangeImage = (data) => setChageC(data.name); //1차저장

  const onClickBtn = () => {
    setPhoto(changeC); //2차저장
    setTimeout(() => {
      setCharacterChange(false);
      setChageC(null);
    }, 1000);
  };

  return (
    <>
      <HeadCont
        rightIcon={"no icon"}
        transparent={"on"}
        title={"프로필 관리"}
        underline={true}
        onLeftAction={() => setCharacterChange(false)}
      />
      <div className="fixed left-0 top-0 z-[2] h-full w-full overflow-y-scroll">
        <div className="maxW mx-auto mb-[100px] h-full gap-6 bg-back_ground px-6 pt-[80px]">
          <TitleBox option={2} title={"나의 캐릭터를 선택해주세요"} />
          <ul className="grid grid-cols-2 gap-4 p-6 mt-6">
            {datas.map((data) => (
              <li
                key={data.id}
                onClick={() => onChangeImage(data)}
                className={
                  ((changeC !== null
                    ? changeC
                    : photo !== null
                    ? photo
                    : oldPhoto) === data.name
                    ? "border-4 border-yellow_100 bg-yellow_10 "
                    : "") +
                  "rounded-2xl py-[22px] shadow-[0_0_12px_rgba(0,0,0,0.1)] "
                }
              >
                <img
                  src={data.imgUrl}
                  alt={data.name}
                  className="m-auto h-[99px] w-[60px]"
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="fixed bottom-0 left-0 w-full px-6 pb-6 bg-back_ground">
          <Btn
            bgColor={"bg-main"}
            textColor={"text-white font-semibold"}
            borderColor={"border border-main"}
            title={"선택완료"}
            active={changeC ? "on" : "ready"}
            onAction={() => onClickBtn()}
          />
        </div>
      </div>
    </>
  );
};

export default ProfileCharacterChange;
