import React from "react";

const ProgressBar = ({ progress, option, title, subTitle, isProtein }) => {
  return (
    <div className="flex-1 pt-2">
      <div
        className={
          "inline-flex h-8 w-4/5 rounded-l-full border " +
          (option === 1 ? "bg-orange_10" : "bg-green_10")
        }
      >
        <div
          style={{ width: progress + "%" }}
          className={
            "relative rounded-l-full transition duration-1000 " +
            (option === 1 ? "bg-orange_100" : "bg-green_100")
          }
        >
          {title !== null ? (
            <span className="absolute left-4 top-1.5 w-32 text-caption1_600 text-gray_10">
              {isProtein ? title?.toFixed(1) : Math.ceil(title)}
              {isProtein ? "g" : "kcal"} 섭취
            </span>
          ) : (
            <span className="absolute left-4 top-1.5 w-32 text-caption1_600 text-gray_10">
              -{isProtein ? "g" : "kcal"} 섭취
            </span>
          )}
        </div>
      </div>
      <div className="relative inline-flex h-8 w-1/5 rounded-r-full border">
        <div
          className={
            "relative rounded-r-full transition duration-1000 " +
            (option === 1 ? "bg-orange_100" : "bg-green_100 ") +
            (progress < 100
              ? "w-[" + 0 + "%]"
              : "w-[" + (progress - 100) + "%]")
          }
        ></div>
        <span
          className={
            "absolute text-caption1_600 text-black " +
            (option === 1
              ? " -left-[145px] bottom-8 "
              : " -left-[155px] top-8 ")
          }
        >
          {subTitle}
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
