import dayjs from "dayjs";
import React, { useState } from "react";

import DateStrip from "./DateStrip";
import CalendarDialog from "./CalendarDialog";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveChosenDate } from "../../../../features/mobile/commonSlice";

const CalendarHeader = ({
  type,
  startDate,
  setStartDate,
  chosenDate,
  // setChosenDate,
  existDaysOfYear,
}) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dayjs.locale("ko");
  const backgroundColor = {
    기록완료: "bg-[#417FFF]",
    식사기록: "bg-[#417FFF]",
    체중기록: "bg-[#FFC700]",
    증상기록: "bg-[#847CE9]",
    복약알림: "bg-[#01B68B]",
    진료알림: "bg-[#51CCF3]",
    걸음수: "bg-[#FE718E]",
  };

  const handleChange = (date) => {
    setStartDate(date);
    dispatch(saveChosenDate(dayjs(date).format("YYYY-MM-DD")));
    setOpenCalendar(false);
  };

  return (
    <>
      <div
        className={`${type === "기록완료" ? "pt-[14px]" : "mt-14"} ${
          backgroundColor[type]
        }`}
      >
        <div
          className={`flex h-[60px] items-center justify-between px-6 py-3 ${backgroundColor[type]}`}
        >
          <span className="text-center text-xl font-semibold text-white">
            {type === "기록완료" ? (
              <span className="text-[32px] font-extrabold">
                {dayjs(chosenDate || startDate).format("M")}월
              </span>
            ) : (
              dayjs(chosenDate || startDate).format("YYYY. MM. DD")
            )}
          </span>
          <div className="flex gap-3">
            <img
              src="/images/mobile/home/new_calendar.svg"
              alt="달력 아이콘"
              onClick={() => setOpenCalendar(true)}
              className="h-6 w-6"
            />
            {type === "기록완료" && (
              <>
                <img
                  onClick={() => navigate(`/notice`)}
                  src={"/images/mobile/home/new_alarm.svg"}
                  alt="new알람"
                  className="h-6 w-6"
                />
                <img
                  onClick={() => navigate(`/mypage`)}
                  src={"/images/mobile/home/new_hamburger.svg"}
                  alt="new햄버거"
                  className="h-6 w-6"
                />
              </>
            )}
          </div>
        </div>

        <div className={`px-4 py-[10px] ${backgroundColor[type]}`}>
          <DateStrip
            type={type}
            startDate={startDate}
            setStartDate={setStartDate}
            chosenDate={chosenDate}
            handleChange={handleChange}
            existDates={[...existDaysOfYear]}
          />
        </div>

        <div className={`h-[28px] ${backgroundColor[type]}`}></div>
      </div>
      <CalendarDialog
        type={type}
        startDate={startDate}
        setStartDate={setStartDate}
        handleChange={handleChange}
        chosenDate={chosenDate}
        existDates={[...existDaysOfYear]}
        openCalendar={openCalendar}
        setOpenCalendar={setOpenCalendar}
      />
    </>
  );
};

export default CalendarHeader;
