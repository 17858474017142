import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFoodDetail,
  fetchRecommendMealForShare,
} from "../../../../features/mobile/recommendSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { mobileTypeFn } from "../../components/logic";
import RecommendCard from "../../components/recommend/RecommendCard";
import NutritionInner from "../../components/common/NutritionInner";
import NutritionGraph from "../../components/common/NutritionGraph";
import Btn from "../../components/common/Btn";
import FoodDetailDialog from "../../components/recommend/FoodDetailDialog";
import IngredientsDialog from "../../components/recommend/IngredientsDialog";
import useGAEvent from "../../hooks/useGAEvent";
import { pointFn } from "../../components/logic";

const RecommendForShare = () => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);

  const { type } = useParams();
  const { recommendForShareStatus, recommendListsForShare } = useSelector(
    (state) => state.recommend
  );
  const [openFoodDialog, setOpenFoodDialog] = useState({
    open: false,
    id: null,
    foodId: null,
    mealDate: null,
    mealType: null,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const shareState = true;

  const [tabNo, setTabNo] = useState(1);
  // const tabs = [{ id: 1, title: '추천식단Ⅰ' }, { id: 2, title: '추천식단II' }, { id: 3, title: '추천식단III' }]
  // const onSetTabNo = (no) => setTabNo(no)

  const { sendEvent } = useGAEvent();
  const params = useParams();

  const onMoveToStore = () => {
    sendEvent({
      event: "app_from_share",
      value: params.type,
    });
    window.scrollTo(0, 0);
    if (mobileTypeFn() === "iphone") {
      //아이폰
      window.open(`https://apps.apple.com/kr/app/id1636424503`, "_blank");
    } else {
      //안드로이드
      window.open(`market://details?id=com.herings.healiary&pli=1`, "_blank");
    }
  };

  useEffect(() => {
    // var meta = document.createElement('meta');
    // meta.setAttribute("property", "og:title")
    // meta.content = "힐리어리가 추천해 드리는 맞춤형 식단입니다.";
    // document.getElementsByTagName('head')[0].appendChild(meta);
    // var meta1 = document.createElement('meta');
    // meta1.setAttribute("property", "og:image");
    // meta1.content = "https://healiary.s3.ap-northeast-2.amazonaws.com/shareMessageImage/shareImg.png";
    // document.getElementsByTagName('head')[0].appendChild(meta1);
    // var meta2 = document.createElement('meta');
    // meta2.setAttribute("property", "og:description");
    // meta2.content = "#힐리어리 #힐리어리추천식단 #힐리어리맞춤형식단 #힐리어리영양관리 #힐리어리체중관리";
    // document.getElementsByTagName('head')[0].appendChild(meta2);
    // var meta3 = document.createElement('meta');
    // meta3.setAttribute("property", "og:url");
    // meta3.content = "https://www.healiary.com/recommend/share/" + type;
    // document.getElementsByTagName('head')[0].appendChild(meta3);

    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", `힐리어리가 추천해 드리는 맞춤형 식단입니다.`);

    //set description
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute(
        "content",
        "#힐리어리 #힐리어리추천식단 #힐리어리맞춤형식단 #힐리어리영양관리 #힐리어리체중관리"
      );

    //set images
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute(
        "content",
        "https://healiary.s3.ap-northeast-2.amazonaws.com/shareMessageImage/shareImg.png"
      );

    //set url
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute(
        "content",
        "https://www.healiary.com/recommend/share/" + type
      );
    window.scrollTo(0, 0);
    dispatch(fetchRecommendMealForShare(type));
    return () => {
      document
        .querySelector('meta[property="og:title"]')
        .setAttribute("content", `힐리어리`);

      //set description
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", "");

      //set images
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", "www.healiary.com/Icon-196.png");

      //set url
      document
        .querySelector('meta[property="og:url"]')
        .setAttribute("content", "");
    };
  }, [dispatch, type]);

  const onSetOneFood = (id, foodId, date, type) => {
    const params = { userId: loginInfo.userId, recommendMealDetailId: id };
    if (id) {
      dispatch(fetchFoodDetail(params));
      setOpenFoodDialog({
        open: true,
        id,
        foodId: foodId,
        mealDate: date,
        mealType: type,
      });
    }
  };

  const nutritionInfo = (title, data, unit) => {
    return (
      <li className="flex-1 rounded-full bg-gray_20 py-3">
        <h2 className="text-caption1_600 text-gray_160">{title}</h2>
        <h2
          className={
            "text-body2_700 " +
            (title === "총 열량" ? "text-orange_180" : "text-green_180")
          }
        >
          {pointFn(data)}
          {unit}
        </h2>
      </li>
    );
  };

  return (
    <>
      <section
        className={
          "mb-28 mt-5 " + (openFoodDialog.open || openDialog ? "no-scroll" : "")
        }
      >
        {recommendForShareStatus === "success" ? (
          <>
            <div className="border-gray_60 px-6">
              <span className="flex items-center justify-center text-h3 text-orange_80">
                추천식단 🍚{" "}
              </span>
              {/* <ul className='flex'>
                            {tabs.map(tab =>
                                <li key={tab.id} className={'pt-2 pb-3 w-1/3 text-center text-body3_700 ' + (tabNo === tab.id ? 'text-gray_180 border-b-[2px] border-black' : 'text-gray_100')}
                                    onClick={() => onSetTabNo(tab.id)}
                                >{tab.title}</li>)}
                        </ul> */}
            </div>
            <div className="pt-2">
              <Swiper
                direction={"horizontal"}
                spaceBetween={16}
                slidesPerView={1.15}
                slideToClickedSlide={true}
                centeredSlides={true}
                slidesOffsetBefore={-5}
                setWrapperSize={true}
              >
                {recommendListsForShare?.[
                  tabNo - 1
                ]?.recommendMealH.mealGroups.map((list, idx) => (
                  <SwiperSlide key={idx}>
                    <RecommendCard
                      id={`swipeMealPlan${idx + 1}`}
                      list={list}
                      onSetOneFood={onSetOneFood}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="my-6 h-[1px] w-full bg-gray_40"></div>
            <div className="px-6">
              <h1 className="mb-5 text-body2_700 text-black">하루 영양 정보</h1>
              <ul className="flex justify-between gap-4 text-center">
                {nutritionInfo(
                  "총 열량",
                  recommendListsForShare?.[tabNo - 1]?.recommendMealH.kcal,
                  "kcal"
                )}
                {nutritionInfo(
                  "총 단백질",
                  recommendListsForShare?.[tabNo - 1]?.recommendMealH.proteinG,
                  "g"
                )}
              </ul>
              {/* <NutritionInner
                title={"총 열량"}
                data={recommendListsForShare?.[tabNo - 1]?.recommendMealH.kcal}
                imgUrl={"/images/mobile/image/rice.svg"}
                unit={"kcal"}
              />
              <div className="my-4 h-[1px] w-full bg-gray_40"></div>
              <NutritionInner
                title={"총 단백질"}
                data={
                  recommendListsForShare?.[tabNo - 1]?.recommendMealH.proteinG
                }
                imgUrl={"/images/mobile/image/fish.svg"}
                unit={"g"}
              /> */}
              <NutritionGraph
                datas={recommendListsForShare?.[tabNo - 1].recommendMealH}
              />
              <p className="mt-4 text-caption2_400 text-gray_160">
                추천식단대로 드신다면 위와 같이 영양소를 골고루
                <br />
                섭취하실 수 있습니다.
              </p>
              <div className="mt-8">
                <Btn
                  bgColor={"bg-main"}
                  textColor={"text-white"}
                  borderColor={"border border-main"}
                  title={"맞춤 식단 받기"}
                  active={"on"}
                  onAction={onMoveToStore}
                />
              </div>
            </div>
          </>
        ) : null}
      </section>
      <FoodDetailDialog
        openFoodDialog={openFoodDialog}
        setOpenFoodDialog={setOpenFoodDialog}
        shareState={shareState}
      />
      <IngredientsDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        main={recommendListsForShare?.[tabNo - 1]?.ingredientMain}
        sub={recommendListsForShare?.[tabNo - 1]?.ingredientSub}
      />
    </>
  );
};

export default RecommendForShare;
