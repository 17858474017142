import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../../common/api";
import {
  removeLoginInfo,
  removeCurrentInfo,
} from "../../../../features/mobile/commonSlice";
import { onResetQuestionComplete } from "../../../../features/mobile/otherSlice";
import HeadCont from "../../components/common/HeadCont";
import Dialog from "../../components/common/Dialog";

const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const [openDialog, setOpenDialog] = useState(false);

  const datas = [
    {
      id: 1,
      subMenu: [
        {
          id: 1,
          title: "비밀번호 변경",
          onAction: () => navigate("/account/editpass"),
        },
        {
          id: 2,
          title: "프로필 관리",
          onAction: () => navigate("/account/profile"),
        },
        { id: 3, title: "로그아웃", onAction: () => setOpenDialog(true) },
        { id: 4, title: "탈퇴", onAction: () => navigate("/resign") },
      ],
    },
  ];
  console.log(loginInfo);
  const onLogout = () => {
    // 로그아웃 - 백엔드에서 토큰 삭제(자동로그인 못하게)
    api
      .get(`/user/logout/${loginInfo.userId}`)
      .then((res) => {
        if (res.data.ok) {
          const timer = setTimeout(() => navigate("/sign"), 1000);
          dispatch(removeLoginInfo());
          dispatch(removeCurrentInfo());
          dispatch(onResetQuestionComplete());
          return () => clearTimeout(timer);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <HeadCont rightIcon={"no icon"} transparent={"on"} title={"계정 설정"} />
      <section className="mb-6 mt-14">
        {datas.map((data) => (
          <div key={data.id} className="p-4 ">
            <ul>
              {data.subMenu.map((menu) => {
                if (
                  menu.title === "비밀번호 변경" &&
                  loginInfo.existPass === false
                ) {
                  return;
                } else {
                  return (
                    <li
                      onClick={menu.onAction}
                      key={menu.id}
                      className={
                        "flex justify-between rounded-md p-3 active:bg-gray_20"
                      }
                    >
                      <h2 className="text-body3_700 text-gray_180">
                        {menu.title}
                      </h2>
                      <img
                        src="/images/mobile/icon/right.svg"
                        alt="더보기 아이콘"
                      />
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        ))}
      </section>
      {openDialog && (
        <Dialog
          innerClass={"p-4"}
          title={"로그아웃"}
          desc={"정말 로그아웃 하시겠습니까?"}
          leftBtnTitle={"로그인 유지"}
          rightBtnTitle={"네"}
          onLeftBtnAction={() => setOpenDialog(false)}
          onRightBtnAction={onLogout}
          onCoverAction={() => setOpenDialog(false)}
        />
      )}
    </>
  );
};

export default Account;
