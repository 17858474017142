import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyActivity } from "../../../../features/mobile/otherSlice";
import { onSetBookmarkRecipe } from "../../../../features/mobile/recommendSlice";
import HeadCont from "../../components/common/HeadCont";
import MenuBar from "../../components/common/MenuBar";
import TitleBox from "../../components/common/TitleBox";
import MealCard from "../../components/mypage/MealCard";
import NoData from "../../components/mypage/NoData";
import Dialog from "../../components/common/Dialog";
// prettier-ignore
const BookmarkRecipe = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loginInfo } = useSelector((state) => state.commonApp)
    const { bookmarkedStatus, bookmarkedRecipeLists } = useSelector((state) => state.others)
    const [openDialog, setOpenDialog] = useState({ status: false, id: null })

    const onDelete = () => {
        let params = { userId: loginInfo.userId, foodId: openDialog.id, bookmark: false }
        dispatch(onSetBookmarkRecipe(params))
            .unwrap()
            .then(res => {
                if (res.ok) {
                    setOpenDialog({ status: false, id: null })
                    dispatch(fetchMyActivity({ userId: loginInfo.userId, type: 2 }))
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        let params = { userId: loginInfo.userId, type: 2 }
        // if (!bookmarkedRecipeLists || bookmarkedRecipeLists?.length === 0) 
        dispatch(fetchMyActivity(params))
        //return () => dispatch(onResetBookmarkedStatus())
        // eslint-disable-next-line
    }, [dispatch, loginInfo.userId])


    return (
        <>
            <HeadCont />
            <section className='px-6 mb-6 mt-14'>
                <TitleBox title={'즐겨찾는 레시피'} font={'text-subtitle1'} textColor={'text-gray_180'} />
                <ul className='mt-[40px] flex flex-col gap-4 mb-28'>
                    {(bookmarkedStatus === 'success' || bookmarkedRecipeLists?.length > 0) ?
                        bookmarkedRecipeLists && bookmarkedRecipeLists.length > 0 ?
                            bookmarkedRecipeLists.map((list, idx) =>
                                <MealCard key={idx} type={list.foodType} image={list.representativeImage} name={list.foodname} bgColor={'bg-white'}
                                    onAction={() => navigate(`/recommend/recipe/${list.foodId}`)} onDeleteAction={(e) => { e.stopPropagation(); setOpenDialog({ status: true, id: list.foodId }); }} />
                            )
                            : <NoData imgUrl={'/images/mobile/image/book.svg'} title={'즐겨찾는 레시피가'} title2={'레시피를'} />
                        : null
                    }
                </ul>
            </section>
            {openDialog.status && <Dialog innerClass={'p-4'} title={'레시피 즐겨찾기 삭제'} desc={'선택하신 레시피를 <br/> 즐겨찾기에서 삭제할까요?'} leftBtnTitle={'네, 삭제할게요'} rightBtnTitle={'아니요'}
                onLeftBtnAction={onDelete} onRightBtnAction={() => setOpenDialog({ status: false, id: null })} onCoverAction={() => setOpenDialog({ status: false, id: null })} />}
            <MenuBar />
        </>
    );
};

export default BookmarkRecipe;
