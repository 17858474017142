import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const fetchMain = createAsyncThunk("user/mainData", async (params) => {
  const res = await api.post(`/user/getMainData`, params);
  return res.data;
});

export const fetchMainPoints = createAsyncThunk(
  "healthDiary/fetchMainPoints",
  async (params) => {
    const res = await api.post(`/healthDiary/getMainPoints`, params);
    return res.data;
  }
);

const initialState = {
  steps: [],
  weights: [],
  weight: 0,
  symptoms: [],
  medicines: [],
  treatments: [],
  eat: {},

  existMainPointOfYear: [],

  todayStep: 0,
};

const mainDataSlice = createSlice({
  name: "메인화면",
  initialState,
  reducers: {
    onSaveTodayStep: (state, action) => {
      state.todayStep = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchMain.fulfilled, (state, action) => {
      state.steps = action.payload.steps;
      state.weights = action.payload.weights;
      state.weight = action.payload.weight;
      state.symptoms = action.payload.symptoms;
      state.medicines = action.payload.medicines;
      state.treatments = action.payload.treatments;
      state.eat = action.payload.eat;
    });
    builder.addCase(fetchMainPoints.fulfilled, (state, action) => {
      state.existMainPointOfYear = action.payload.list;
    });
  },
});

export const { onSaveTodayStep } = mainDataSlice.actions;
export default mainDataSlice.reducer;
