export const CommunityHeadlist = [
  false,
  "No.",
  "카테고리",
  "제목",
  "작성자",
  "작성일",
];
export const headList = [
  "기본정보",
  "신체 계측",
  "질병 정보",
  "식습관 정보",
  "유입 경로",
];

export const tableList1 = [
  //환자일 경우
  { title: "이름", subtit: "name" },
  { title: "나이", subtit: "age" },
  { title: "생년", subtit: "birth" },
  { title: "성별", subtit: "gender" },
  { title: "닉네임", subtit: "nickName" },
  { title: "휴대전화", subtit: "phoneNumber" },
  { title: "구분", subtit: "userType" },
  { title: "가입일", subtit: "createdAt" },
];

export const tableList1_Patient = [
  { title: "닉네임", subtit: "nickName" },
  { title: "나이", subtit: "age" },
  { title: "생년", subtit: "birth" },
  { title: "성별", subtit: "gender" },
];
export const tableList1_Protector = [
  //보호자일경우
  { title: "닉네임", subtit: "nickNameRegist" },
  { title: "휴대전화", subtit: "phoneNumber" },
  { title: "구분", subtit: "userType" },
  { title: "가입일", subtit: "createdAt" },

  { title: "나이", subtit: "protectorAge" }, //back에서 정해주지 않음
  { title: "생년", subtit: "protectorBirth" }, //back에서 정해주지 않음
  { title: "성별", subtit: "protectorGender" }, //back에서 정해주지 않음
  { title: "", subtit: "" },
];
export const tableList2 = [
  //신체계측
  { title: "키", subtit: "height" },
  { title: "체중", subtit: "weight" },
  { title: "목표 체중", subtit: "targetWeight" },
  { title: "BMI", subtit: "bmi" },
  { title: "권장 칼로리", subtit: "kcal" },
  { title: "권장 단백질량", subtit: "protein" },
  { title: "", subtit: "" },
  { title: "", subtit: "" },
];
export const tableList3 = [
  //질병정보
  { title: "암종", subtit: "cancerType" },
  { title: "병기", subtit: "cancerStaging" },
  { title: "수술유무", subtit: "operationYN" },
  { title: "수술일", subtit: "operationDate" },
  { title: "치료여부", subtit: "anticancerTreatmentYN" },
  { title: "치료종류", subtit: "anticancerTreatmentType" },
  { title: "수술 후 주차", subtit: "surgery_week" },
  { title: "수술 후 일자", subtit: "surgery_day" },
  { title: "수술명", subtit: "operationName" },
  { title: "진단병원", subtit: "hospitalName" },
  { title: "", subtit: "" },
  { title: "", subtit: "" },
];

export const tableList3_Grid1 = [
  { title: "복용약품", subtit: "medicineDrugs" },
];
export const tableList3_Grid2BT = [
  //질병정보gridbottom
  { title: "만성질환", subtit: "chronicDisease" },
  { title: "", subtit: "" },
];

export const tableList4 = [
  //식습관정보
  { title: "유당 불내증", subtit: "lactoseIntolerance" },
  { title: "아침 식사여부", subtit: "breakfastEat" },
  { title: "간식 횟수", subtit: "snackPreferCount" },
  { title: "식사 문제", subtit: "dietProblem" },
  { title: "식사 섭취속도", subtit: "dietRate" },
  { title: "하루 물 섭취량", subtit: "waterGlassCount" },
  { title: "최근 체중변화", subtit: "changeWeight" },
  { title: "", subtit: "" },
];
export const tableList4_Grid2 = [
  //식습관정보grid
  { title: "식사 불편", subtit: "dietInconvenient" },
  { title: "알레르기", subtit: "allergie" },
];

export const tableList4_Grid2_2 = [
  //식습관정보grid
  { title: "선호 음식", subtit: "dietPreferType" },
  { title: "건강기능식품", subtit: "healthFood" },
];
export const tableList4_Grid2_3 = [
  //식습관정보grid
  { title: "흡연", subtit: "smoke" },
  { title: "음주", subtit: "alcoholNumber" },
];
export const tableList4_Grid2_4 = [
  //식습관정보grid
  { title: "섭취 가능 유제품", subtit: "lactoseIntoleranceEdible" },
  { title: "", subtit: "" },
];
export const chips_list = [
  "식단",
  "생활",
  "운동",
  "공유",
  "암",
  "만성질환",
  "건강관리",
  "예방",
  "복약",
  "처방",
  "공지",
  "기타",
];

export const tableList5_Grid2BT = [
  { title: "유입경로", subtit: "registSurveyAnswer" },
  { title: "기타", subtit: "registSurveyAnswerETC" },
];
