import React from "react";

const GuideForFood = ({ openGuide, setOpenGuide }) => {
  const datas = [
    { id: 1, title: "밥,죽,스프" },
    { id: 2, title: "국,탕,찌개,면" },
    { id: 3, title: "나물,김치" },
    { id: 4, title: "생선" },
    { id: 5, title: "고기" },
    { id: 6, title: "과일류" },
    { id: 7, title: "우유 및 유제품" },
    { id: 8, title: "견과류" },
  ];
  return (
    <>
      <div
        onClick={() => setOpenGuide(false)}
        className={
          openGuide
            ? "fixed left-0 top-0 z-10 h-full w-full bg-black opacity-70"
            : ""
        }
      ></div>
      <div
        className={
          "maxW fixed left-0 right-0 z-[102] mx-auto h-[500px] overflow-scroll rounded-t-[32px] bg-white p-6 shadow-[0_0_12px_rgba(0,0,0,0.1)] duration-700 ease-in-out " +
          (openGuide ? "bottom-0" : "bottom-[-650px]")
        }
      >
        <div className="flex items-center justify-between overflow-hidden maxW">
          <h1 className="text-body3_700 text-gray_160">눈대중량</h1>
          <img
            onClick={() => setOpenGuide(false)}
            src="/images/mobile/icon/close.svg"
            alt="닫기 아이콘"
          />
        </div>
        <p className="mb-4 text-caption2_400 text-main">
          음식 단위에 대한 가이드입니다.
        </p>
        <ul>
          {datas.map((data) => (
            <li
              key={data.id}
              className="py-4 space-y-3 border-b border-gray_40"
            >
              <h1 className="text-caption2_400 text-gray_160">
                {data.title.replaceAll(",", "/")}
              </h1>
              <img
                src={`/images/mobile/image/guideForFood/${data.title}.svg`}
                alt="음식 가이드 이미지"
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default GuideForFood;
