import React from "react";

//1.bg있는 경우
// onClick:클릭시 이벤트
//content: 버튼 텍스트
//width: px, % (string)둘다 가능   *** width, xpadding 인자 안들어오면 width full
//xpadding: padding 좌우 (string)
// bgcolor:버튼배경색 (string)
//textcolor: 버튼 텍스트 컬러 (string)
//textsize:버튼 텍스트 사이즈 (string)
export const BGButtonCustom = ({
  onClick,
  content,
  bgcolor,
  width,
  xpadding,
  textcolor,
  textsize,
  icon,
}) => {
  const commonBg = "py-[12px] rounded-[4px] font-semibold ";
  const customBGhover =
    bgcolor === "bg-gray_40"
      ? `hover:bg-gray_20`
      : bgcolor === "bg-orange_100"
      ? `hover:bg-orange_110`
      : bgcolor === "bg-gray_70"
      ? `hover:bg-gray_50`
      : "";
  const ificonhere = "flex gap-x-3 justify-center items-center";
  return (
    <>
      {icon !== undefined ? (
        //  icon이 있는 경우
        <button
          className={`${commonBg} ${bgcolor} ${customBGhover} ${textcolor} ${textsize} ${ificonhere}`}
          style={
            xpadding === undefined && width === undefined
              ? { width: "100%" }
              : { width: width, paddingRight: xpadding, paddingLeft: xpadding }
          }
          onClick={onClick}
        >
          <img src={icon} alt="icon" />
          <p>{content}</p>
        </button>
      ) : (
        // icon이 없는 경우 width
        <button
          className={`${commonBg} ${bgcolor} ${customBGhover} ${textcolor} ${textsize} `}
          style={
            xpadding === undefined && width === undefined
              ? { width: "100%" }
              : { width: width, paddingRight: xpadding, paddingLeft: xpadding }
          }
          onClick={onClick}
        >
          <p>{content}</p>
        </button>
      )}
    </>
  );
};

//2.border 있는경우
export const BorderButtonCustom = ({
  onClick,
  content,
  width,
  xpadding,
  bgcolor,
  textcolor,
  textsize,
  bordercolor,
}) => {
  const commonBg = "py-[12px] rounded-[4px] border font-semibold";
  //hover는 적용이 안돼서 따로커스텀
  const customBGhover =
    bgcolor === "bg-gray_40"
      ? `hover:bg-gray_20`
      : bgcolor === "bg-primary_100"
      ? `hover:bg-primary_110`
      : "";
  return (
    <button
      className={`${commonBg}  ${customBGhover} ${textcolor} ${textsize} ${bordercolor}`}
      style={
        xpadding === undefined && width === undefined
          ? { width: "100%" }
          : { width: width, paddingRight: xpadding, paddingLeft: xpadding }
      }
      onClick={onClick}
    >
      <p>{content}</p>
    </button>
  );
};

//3.reset
export const ResetButton = ({ onClick, text, width }) => {
  return (
    <div
      className="h-max w-max cursor-pointer rounded-full border border-black px-[12px] py-[8px] hover:shadow active:shadow-none"
      style={{
        width: `${width}px`,
      }}
      onClick={onClick}
    >
      <img
        src="/images/admin/icon/filter.svg"
        alt="필터 아이콘"
        className="pointer-events-none mr-2 inline-block"
      />
      <span className={` pointer-events-none text-black`}>{text}</span>
    </div>
  );
};

export const CommentBtn = ({
  onClickAction,
  title,
  bgcolor,
  txtcolor,
  bordercolor,
}) => {
  return (
    <span
      onClick={onClickAction}
      className={
        "flex h-[34px] w-[63px] cursor-pointer flex-row items-center justify-center gap-[10px] rounded-[4px] px-[9px] py-[8px] text-xs font-semibold " +
        bgcolor +
        " " +
        txtcolor +
        " " +
        bordercolor
      }
    >
      {title}
    </span>
  );
};
