import React from "react";
import dayjs from "dayjs";
import { HomeBoardHeadList, ManagementHeadlist } from "../common/TableHeadList";
const MemberList = ({ onClickDetail, filteredUsers, title }) => {
  const cellStyle = " border-b border-gray_60 text-center py-4 text-black ";
  const commonhead =
    " bg-gray_60 py-4 px-2 text-black border-gray_60 border-y first:border-l last:border-r first:rounded-tl-lg last:rounded-tr-lg ";

  const repeatedHead = (headlist, headcss) => {
    return (
      <thead>
        <tr>
          {headlist?.map((head, idx) => (
            <th key={idx} className={headcss + (idx === 0 ? " w-[80px] " : "")}>
              {head}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const repeatedTd = (data, idx, title) => {
    const 회원목록리스트 = [
      {
        tdclass:
          cellStyle +
          " border-l " +
          (idx === filteredUsers?.length - 1 ? "rounded-bl-lg" : ""),
        tdcontent: data.no,
      },
      { tdclass: cellStyle, tdcontent: data.name },
      {
        tdclass: cellStyle,
        tdcontent: data.nickName,
        tdcontent2: data.nickNameProtector
          ? `(보: ${data.nickNameProtector})`
          : "",
      },
      { tdclass: cellStyle, tdcontent: data.age },
      { tdclass: cellStyle, tdcontent: data.gender },
      { tdclass: cellStyle, tdcontent: data.cancerType },
      { tdclass: cellStyle, tdcontent: data.cancerStaging },
      { tdclass: cellStyle, tdcontent: data.operationYN },
      {
        tdclass: cellStyle,
        tdcontent:
          data.operationDate == null || data.operationDate === ""
            ? "-"
            : data.operationDate === "OD997"
            ? "모름"
            : dayjs(data.operationDate).format("YYYY-MM-DD"),
      },
      { tdclass: cellStyle, tdcontent: data.anticancerTreatmentYN },
      { tdclass: cellStyle, tdcontent: data.anticancerTreatmentType },
      {
        tdclass: cellStyle + " z-1 underline ",
        tdcontent: data.isExistRecommendMeal ? "보기" : "",
      },
      // { tdclass: cellStyle, tdcontent: data.userType },
      {
        tdclass:
          cellStyle +
          " border-r " +
          (idx === filteredUsers?.length - 1 ? "rounded-br-lg" : ""),
        tdcontent: dayjs(data.createdAt).format("YYYY-MM-DD"),
      },
    ];
    const 소식리스트 = [
      {
        tdclass:
          cellStyle +
          " border-l " +
          (idx === filteredUsers?.length - 1 ? "rounded-bl-lg" : ""),
        tdcontent: data.no,
      },
      {
        tdclass: cellStyle,
        tdcontent: dayjs(data.createdAt).format("YYYY-MM-DD"),
      },
      {
        tdclass: cellStyle,
        tdcontent: data.adminName
          ? data.adminName
          : data.nickName
          ? data.nickName
          : "",
        tdcontent2: data.nickNameProtector
          ? `(보: ${data.nickNameProtector})`
          : "",
      },
      {
        tdclass:
          cellStyle +
          " border-r " +
          (idx === filteredUsers?.length - 1 ? "rounded-br-lg" : ""),
        tdcontent: data.title,
      },
    ];

    const 이외리스트 = [
      {
        tdclass:
          cellStyle +
          " border-l " +
          (idx === filteredUsers?.length - 1 ? "rounded-bl-lg" : ""),
        tdcontent: data.no,
      },
      {
        tdclass: cellStyle,
        tdcontent: dayjs(data.createdAt).format("YYYY-MM-DD"),
      },
      {
        tdclass: cellStyle,
        tdcontent: data.nickName,
        tdcontent2: data.nickNameProtector
          ? `(보: ${data.nickNameProtector})`
          : "",
      },
      {
        tdclass:
          cellStyle +
          " border-r " +
          (idx === filteredUsers?.length - 1 ? "rounded-br-lg" : ""),
        tdcontent: data.title,
      },
    ];
    const tdLists =
      title === "회원목록" || title === undefined
        ? 회원목록리스트
        : title === "소식"
        ? 소식리스트
        : 이외리스트;
    return (
      <tr
        onClick={(e) => {
          if (title === "회원목록") {
            onClickDetail(data, e);
          } else {
            //소식, 1:1이용문의 카드 클릭시
            onClickDetail(data, title);
          }
        }}
        key={idx}
        className={
          "hover:bg-primary_10 cursor-pointer  " +
          (idx % 2 === 1 ? "bg-gray_95" : "bg-gray_10")
        }
      >
        {tdLists?.map((el, index) => (
          <td className={el.tdclass} key={index}>
            <div>{el.tdcontent}</div>
            {el.tdcontent2 ? <p>{el.tdcontent2}</p> : ""}
          </td>
        ))}
      </tr>
    );
  };
  return (
    <table
      className="w-full overflow-hidden border-separate table-auto"
      cellSpacing={0}
    >
      {title === "회원목록" || !title ? (
        <>
          {repeatedHead(ManagementHeadlist, commonhead)}
          <tbody>
            {filteredUsers?.map((data, idx) => repeatedTd(data, idx, title))}
          </tbody>
        </>
      ) : title === "소식" ? (
        <>
          {repeatedHead(
            HomeBoardHeadList,
            commonhead +
              (filteredUsers === undefined || filteredUsers?.length === 0
                ? "first:rounded-bl-lg last:rounded-br-lg"
                : null)
          )}
          <tbody>
            {filteredUsers?.map((data, idx) => repeatedTd(data, idx, title))}
          </tbody>
        </>
      ) : (
        <>
          {repeatedHead(HomeBoardHeadList, commonhead)}
          <tbody>
            {filteredUsers?.map((data, idx) => repeatedTd(data, idx, title))}
          </tbody>
        </>
      )}
    </table>
  );
};

export default MemberList;
