import React, { useEffect, useRef, useState } from "react";

// 옵션 컴포넌트 (개별 드롭다운 메뉴 아이템)
const Option = ({ option, handleOptionClick }) => (
  <li
    onClick={() => handleOptionClick(option)}
    className="w-full py-3 border-b cursor-pointer border-gray_40 last:border-none hover:bg-gray-200"
  >
    <span className="block text-center">{option.name}</span>
  </li>
);

const ListHospitalDropdownBtn = ({ options, setTarget, changeTarget }) => {
  const [isOpen, setIsOpen] = useState(false); // 드롭다운 메뉴의 열림/닫힘 상태
  const [selectedOption, setSelectedOption] = useState(setTarget); // 현재 선택된 옵션
  const dropDownRef = useRef(null);

  useEffect(() => {
    setSelectedOption(setTarget);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 드롭다운메뉴 밖을 터치했을때 메뉴 닫기
    const handleOutsideClose = (e) => {
      if (isOpen && !dropDownRef.current.contains(e.target)) setIsOpen(false);
    };
    document.addEventListener("click", handleOutsideClose);

    return () => document.removeEventListener("click", handleOutsideClose);
  }, [isOpen]);

  // 드롭다운 메뉴의 열림/닫힘 상태를 토글
  const toggleDropdown = () => setIsOpen(!isOpen);

  // 옵션이 클릭 됐을 때 실행되는 함수
  const handleOptionClick = (option) => {
    setSelectedOption(option.name); // 선택된 옵션을 설정
    changeTarget(option.name);
    setIsOpen(false); // 드롭다운 메뉴를 닫음
  };

  return (
    <div className="">
      <div
        onClick={toggleDropdown}
        className="relative items-start w-full pr-0"
        ref={dropDownRef}
      >
        {/* 선택된 옵션을 표시하는 부분 */}
        <div className="flex h-11 w-[120px] shrink-0 items-center justify-between gap-1 rounded-[44px] border-[1.5px] border-main p-[10px]">
          <span className="text-caption2_700 text-main">
            {selectedOption === "" ? "전체" : selectedOption}
          </span>
          <img
            src="/images/mobile/icon/chevron_white.svg"
            alt="드롭박스 화살표 아이콘"
          />
        </div>

        {/* 드롭다운 메뉴가 열릴 경우, 옵션 목록을 표시 */}
        {isOpen && (
          <ul className="absolute left-0 right-0 flex flex-col items-center px-5 overflow-y-scroll bg-white shadow top-14 h-52 rounded-xl">
            {options.map((option, index) => (
              <Option
                key={index}
                option={option}
                handleOptionClick={handleOptionClick}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ListHospitalDropdownBtn;
