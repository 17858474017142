export const CommunityHeadlist = [
  "No.",
  "제목",
  "내용",
  "작성자",
  "작성일",
  "수정일",
  "조회",
  "좋아요",
  "댓글",
  "신고 누적",
  "공개/비공개",
];

export const CenterHeadlist = [
  "No.",
  "제목",
  "닉네임",
  "작성일",
  "답변 상태",
  "답변 작성",
];

export const InformationHeadList = [
  "check",
  "No.",
  "카테고리",
  "제목",
  "작성자",
  "작성일",
  "조회",
  "좋아요",
  "북마크",
  "공개여부",
];

export const FoodRequiredHeadList = [
  "No.",
  "음식명",
  "내용",
  "사진",
  "닉네임",
  "작성일",
  //"개발일자"
];
export const CancerGPTHEadList = [
  "No.",
  "분류",
  "닉네임",
  "휴대폰번호",
  "질문",
  "답변",
  "작성일",
];
export const NoticeHeadList = [
  "No.",
  "제목",
  "작성자",
  "작성일",
  "수정일",
  "공개/비공개",
];

export const AdminHeadlist = [
  "번호",
  "이름",
  "아이디",
  "성별",
  // "소속",
  "권한",
  "신청일",
  "승인처리",
];

export const ClientHeadlist = [
  "No.",
  "이름",
  "닉네임",
  "만 나이",
  "성별",
  "암 종",
  "병기",
  "수술여부",
  "수술일자",
  "항암치료 종류",
  "추천식단",
  // "회원 구분",
  "가입일",
];
export const ManagementHeadlist = [
  "No.",
  "이름",
  "닉네임",
  "만 나이",
  "성별",
  "암 종",
  "병기",
  "수술여부",
  "수술일자",
  "항암치료 여부",
  "항암치료 종류",
  "추천 식단",
  // "회원구분",
  "가입일",
];

export const HomeBoardHeadList = ["No.", "작성날짜 ", "작성자", "제목"];
export const HeadList_종합가이드 = [
  "No.",
  "열량 섭취량",
  "단백질 섭취량",
  "종합 가이드",
  "작성자",
  "작성일",
  "수정자",
  "최근 수정일",
];
export const HeadList_식사가이드 = [
  "No.",
  "대분류",
  "중분류",
  "소분류",
  "식사 가이드",
  "작성자",
  "작성일",
  "수정자",
  "최근 수정일",
];
export const HeadList_체중가이드 = [
  "No.",
  "치료 단계",
  "체중 변화",
  "체중 상태",
  "체중 가이드",
  "작성자",
  "작성일",
  "수정자",
  "최근 수정일",
];
export const HeadList_증상가이드 = [
  "No.",
  "증상명",
  "증상 가이드",
  "작성자",
  "작성일",
  "수정자",
  "최근 수정일",
];
export const HeadList_FAQ = [
  "No.",
  "분류",
  "제목",
  "FAQ",
  "작성자",
  "작성일",
  "수정자",
  "최근 수정일",
];

export const Hospital = ["No.", "병원명", "등록일"];

export const RequestDBExcelHeadList = [
  { label: "id", key: "id" },
  { label: "no", key: "no" },
  { label: "title", key: "title" },
  { label: "content", key: "content" },
  { label: "nickName", key: "nickName" },
  { label: "createdAt", key: "createdAt" },
  { label: "updatedAt", key: "updatedAt" },
];

export const CancerGPTExcelHeadList = [
  { label: "id", key: "id" },
  { label: "no", key: "no" },
  { label: "userId", key: "userId" },
  { label: "nickName", key: "nickName" },
  { label: "questionType", key: "questionType" },
  { label: "question", key: "question" },
  { label: "questionSummary", key: "questionSummary" },
  { label: "answer", key: "answer" },
  { label: "questionPatientInfo", key: "questionPatientInfo" },
  { label: "anticancerTreatmentType", key: "anticancerTreatmentType" },
  { label: "anticancerTreatmentYN", key: "anticancerTreatmentYN" },
  { label: "birth", key: "birth" },
  { label: "cancerStaging", key: "cancerStaging" },
  { label: "cancerType", key: "cancerType" },
  { label: "gender", key: "gender" },
  { label: "height", key: "height" },
  { label: "weight", key: "weight" },
  { label: "operationName", key: "operationName" },
  { label: "operationYN", key: "operationYN" },
  { label: "createdAt", key: "createdAt" },
];

export const funnelsHeadList = [
  { label: "날짜", key: "date" },
  { label: "위", key: "rank" },
  { label: "가입자 소계", key: "sum" },
];
