import React from 'react';

const CharacterInput = ({ onSaveEach, character, onSaveAnswer, type }) => {
    const datas = [
        { id: 1, imgUrl: '/images/mobile/character/girl.svg', name: 'girl' },
        { id: 2, imgUrl: '/images/mobile/character/woman.svg', name: 'woman' },
        { id: 3, imgUrl: '/images/mobile/character/boy.svg', name: 'boy' },
        { id: 4, imgUrl: '/images/mobile/character/man.svg', name: 'man' },
        { id: 5, imgUrl: '/images/mobile/character/rabbit_pink.svg', name: 'rabbit_pink' },
        { id: 6, imgUrl: '/images/mobile/character/rabbit_blue.svg', name: 'rabbit_blue' },
    ]

    return (
        <ul className='grid grid-cols-2 gap-4 mt-6 mx-6 pb-[100px]' >
            {datas.map(data =>
                <li onClick={onSaveEach ? () => onSaveEach('characterPhoto', data.name) : () => onSaveAnswer(type, data.name)} key={data.id} className={'rounded-2xl py-[22px] shadow-[0_0_12px_rgba(0,0,0,0.1)] '
                    + (character === data.name ? 'border-4 border-yellow_100 bg-yellow_10' : 'bg-white border-none')}>
                    <img src={data.imgUrl} alt={data.name} className='w-[60px] h-[99px] m-auto' />
                </li>)}

        </ul>
    );
};

export default CharacterInput;