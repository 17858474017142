import React from "react";
import HeadCont from "../../components/common/HeadCont";
import useCommunity from "../../hooks/useCommunity";
import { useSelector } from "react-redux";
import CommunityContainer from "../../components/mypage/CommunityContainer";

const MyPosts = () => {
  const { loginInfo } = useSelector((state) => state.commonApp);

  // community Hook variables
  // prettier-ignore
  const commParams = useCommunity("myPage", loginInfo, "전체");

  return (
    <>
      <HeadCont />
      <div className="mt-14" />
      <h1 className="text-subtitle1 mx-6 mt-2 mb-2.5">나의 게시글</h1>
      {/* Post Container from MyPage (Different from Post Container from Community) */}
      <CommunityContainer commParams={commParams} loginInfo={loginInfo} />
    </>
  );
};

export default MyPosts;
