import { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { format, addDays, startOfWeek, isSameDay } from "date-fns";
import dayjs from "dayjs";

const DateStrip = ({
  type,
  setStartDate,
  chosenDate,
  handleChange,
  existDates,
}) => {
  const [currentDate, setCurrentDate] = useState(
    chosenDate || dayjs(new Date()).format("YYYY-MM-DD")
  );

  const [translateX, setTranslateX] = useState(0); // 터치 이동 거리를 저장하는 상태
  const today = dayjs(new Date()).format("YYYY-MM-DD");

  const typeChecker = {
    기록완료: {
      bg: "bg-[#417FFF]",
      dot: "bg-[#B0CAFE]",
      text: "text-[#417FFF]",
    },
    식사기록: {
      bg: "bg-[#417FFF]",
      dot: "bg-[#B0CAFE]",
      text: "text-[#417FFF]",
    },
    체중기록: {
      bg: "bg-[#FFC700]",
      dot: "bg-[#FFF2C6]",
      text: "text-[#FFC700]",
    },
    증상기록: {
      bg: "bg-[#847CE9]",
      dot: "bg-[#C0B0FE]",
      text: "text-[#847CE9]",
    },
    복약알림: {
      bg: "bg-[#01B68B]",
      dot: "bg-[#77DCC4]",
      text: "text-[#01B68B]",
    },
    진료알림: {
      bg: "bg-[#51CCF3]",
      dot: "bg-[#C0F0FF]",
      text: "text-[#51CCF3]",
    },
    걸음수: {
      bg: "bg-[#FE718E]",
      dot: "bg-[#FFDBE4]",
      text: "text-[#FE718E]",
    },
  };

  const getWeekDates = (date) => {
    let week = [];
    let start = startOfWeek(new Date(date)); // 한 주의 시작을 월요일로 설정
    for (let i = 0; i < 7; i++) {
      week.push(format(addDays(start, i), "yyyy-MM-dd"));
    }
    return week;
  };

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      setTranslateX(eventData.deltaX); // 터치 이동 거리 업데이트
    },
    // onSwipedLeft: () => setCurrentDate(addDays(currentDate, 7)),
    // onSwipedRight: () => setCurrentDate(addDays(currentDate, -7)),
    // onSwipedLeft: () => {
    //   handleChange(addDays(currentWeek[6], 1));
    //   setAnimationClass("animate-slideLeft");
    // },
    onSwipedLeft: () => {
      handleChange(addDays(currentDate, 7));
      setTranslateX(0); // 스와이프 완료 후 위치 리셋
    },
    // onSwipedRight: () => {
    //   handleChange(addDays(currentWeek[0], -7));
    //   setAnimationClass("animate-slideRight");
    // },
    onSwipedRight: () => {
      handleChange(addDays(currentDate, -7));
      setTranslateX(0); // 스와이프 완료 후 위치 리셋
    },

    onSwiped: () => {
      setTranslateX(0); // 스와이프 완료 후 위치 리셋, 스냅 애니메이션 적용
    },
    preventDefaultTouchmoveEvent: true, // 브라우저의 touchmove 이벤트를 방지
    trackMouse: true,
    trackTouch: true,
  });

  const weekDates = getWeekDates(currentDate);

  useEffect(() => {
    setStartDate(currentDate);
  }, [currentDate]);

  useEffect(() => {
    if (chosenDate) {
      setCurrentDate(chosenDate);
    }
  }, [chosenDate]);

  return (
    <div className="w-full font-semibold leading-tight text-white">
      <div className="flex items-center justify-between w-full mx-auto mb-1 text-sm font-semibold">
        {["일", "월", "화", "수", "목", "금", "토"].map((val) => (
          <p
            className={`h-[25px] w-[45px] self-center text-center leading-[25px]`}
            key={val}
          >
            {val}
          </p>
        ))}
      </div>
      <div
        {...handlers}
        className={` flex items-center justify-between text-lg first-letter:mt-[10px] `}
        style={{
          transform: `translateX(${translateX}px)`,
          transition: "transform 0.2s ease-out",
        }}
      >
        {weekDates.map((date, index) => (
          <div
            key={index}
            className={"h-[44px] text-center text-white"}
            onClick={() => {
              handleChange(date);
            }}
          >
            <p
              className={` ${
                date === chosenDate
                  ? `bg-white ${typeChecker[type]?.text}`
                  : date === today && "bg-[#FFFFFF50]"
              } flex h-[44px] w-[44px] items-center justify-center rounded-full text-center`}
            >
              {format(date, "d")}
            </p>
            {existDates?.includes(date) && (
              <p
                className={`mx-auto -mt-[10px] h-[5px] w-[5px] rounded-full text-center ${
                  date === chosenDate
                    ? `${typeChecker[type]?.bg}`
                    : `${typeChecker[type]?.dot}`
                }`}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default DateStrip;
