// import dayjs from 'dayjs'
import React from "react";

const Announce = ({
  idx,
  createdAt,
  content,
  color,
  onClick,
  fixedcount,
  noticeBannerImagePath,
}) => {
  const colors = ["#FFD43B", "#904CFF", "#66CD79", "#6A79FF", "#FFBF6A"];

  return (
    <>
      {noticeBannerImagePath ? (
        <img src={noticeBannerImagePath} alt={content} onClick={onClick} />
      ) : (
        <div
          className={"flex h-full w-full px-8"}
          style={{ backgroundColor: colors[idx - fixedcount] }} //ho 인덱스에서 fixed 배열 안에 배너광고 수 만큼 빼준다. (힐리어리 공지글 수는 5개 한정)
          onClick={onClick}
        >
          <div className="flex flex-col my-auto">
            <span
              className={
                "block " +
                (idx % 2 === 1
                  ? " text-subtitle2 text-white"
                  : " text-subtitle2 text-black")
              }
            >
              힐리어리 공지
            </span>
            <span
              className={
                "ellipsis block break-words" +
                (idx % 2 === 1
                  ? " text-caption1_400 text-white"
                  : " text-caption1_400 text-black")
              }
            >
              {/* {(dayjs(createdAt).format("MM월DD일"))}  */}
              {content}
            </span>
          </div>
          {/* <div
                  className="ml-auto w-[66px] h-[66px] flex-shrink-0"
                  style={{
                      background:
                          "url('/images/mobile/icon/comm_alert.svg') no-repeat center",
                  }}
              /> */}
          <img
            className="my-auto ml-auto mr-[-10px] h-[66px] w-[66px]"
            src={`/images/mobile/icon/comm_alert.svg`}
            alt="writer icon"
            loading="lazy"
          />
        </div>
      )}
    </>
  );
};

export default Announce;
