import React from 'react'
// import { Tooltip } from "@material-ui/core";

const SymptomBoxTd = ({symptomList,head, toolTipClassName, exampleColors,idx}) => {

  return (
  <>
  {/* 발생빈도 */}
    <td
      className={
      "border-t border-l border-gray_80 p-4 text-sm font-semibold text-gray_10 " +
      (idx + 1 === symptomList?.length ? "border-y" : "")
      }
    >
      {/* Tooltip의 span 내부 공통모듈화 하려 했으나 Tooltip 라이브러리 구조떄문인지 props가 전달이 안되어서 일일히 코드 작성 */}
      {/* {head.frequency === -1?
        <Tooltip title={"질문 해당 안됨"} arrow>
          <img
          className="m-auto"
          src=" /images/admin/icon/해당없음.svg"
          alt="해당없음 아이콘"/>
        </Tooltip>
        : head.frequency === 1?
          <Tooltip title={"전혀 없다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[4],
                  borderColor:exampleColors[4],
                }}
            ></span>
          </Tooltip> 
        : head.frequency === 2 ?
          <Tooltip title={"드물게 있다"} arrow>            
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[3],
                  borderColor:exampleColors[3],
                }}
            ></span>
          </Tooltip>
        : head.frequency === 3?
          <Tooltip title={"가끔 있다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[2],
                  borderColor:exampleColors[2],
                }}
            ></span>
          </Tooltip>
        : head.frequency === 4?
          <Tooltip title={"자주 있다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[1],
                  borderColor:exampleColors[1],
                }}
            ></span>
          </Tooltip>
        : head.frequency === 5?
          <Tooltip title={"거의 항상 있다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[0],
                  borderColor:exampleColors[0],
                }}
            ></span>
          </Tooltip>
          :
        ""
      } */}
    </td>
    {/* 심각도 */}
    <td
      className={
      "border-t border-l border-gray_80 p-4 text-sm font-semibold text-gray_10 " +
      (idx + 1 === symptomList?.length ? "border-y " : "")
      }
    >
      {/* {head.severity === -1?
        <Tooltip title={"질문 해당 안됨"} arrow>
          <img
          className="m-auto"
          src=" /images/admin/icon/해당없음.svg"
          alt="해당없음 아이콘"/>
        </Tooltip>
      : head.severity === 1?
        <Tooltip title={"전혀 없다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[4],
                  borderColor:exampleColors[4],
                }}
            ></span>
        </Tooltip>
      : head.severity === 2?
        <Tooltip title={"약간 있다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[3],
                  borderColor:exampleColors[3],
                }}
            ></span>
        </Tooltip>
      : head.severity === 3?
      <Tooltip title={"보통이다"} arrow>
          <span
              className= {toolTipClassName}
              style={{
                backgroundColor: exampleColors[2],
                borderColor:exampleColors[2],
              }}
          ></span>
      </Tooltip>
      : head.severity === 4?
      <Tooltip title={"심하다"} arrow>
          <span
              className= {toolTipClassName}
              style={{
                backgroundColor: exampleColors[1],
                borderColor:exampleColors[1],
              }}
          ></span>
      </Tooltip>
      : head.severity === 5?
      <Tooltip title={"매우 심하다"} arrow>
          <span
              className= {toolTipClassName}
              style={{
                backgroundColor: exampleColors[0],
                borderColor:exampleColors[0],
              }}
          ></span>
      </Tooltip>
      :""} */}
    </td>
    {/* 일상생활지장 정도 */}
    <td
      className={
      "border-t border-l border-gray_80 p-4 text-sm font-semibold text-gray_10 " +
      (idx + 1 === symptomList?.length ? "border-y " : "")
      }
    >
      {/* {head.interference === -1?
        <Tooltip title={"질문 해당 안됨"} arrow>
          <img
          className="m-auto"
          src=" /images/admin/icon/해당없음.svg"
          alt="해당없음 아이콘"/>
        </Tooltip>
      : head.interference === 1?
        <Tooltip title={"전혀 없다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[4],
                  borderColor:exampleColors[4],
                }}
            ></span>
        </Tooltip>
      : head.interference === 2?
        <Tooltip title={"약간 있다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[3],
                  borderColor:exampleColors[3],
                }}
            ></span>
        </Tooltip>
      : head.interference === 3?
        <Tooltip title={"다소 있다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[2],
                  borderColor:exampleColors[2],
                }}
            ></span>
        </Tooltip>
        : head.interference === 4?
        <Tooltip title={"많다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[1],
                  borderColor:exampleColors[1],
                }}
            ></span>
        </Tooltip>
        : head.interference === 5?
        <Tooltip title={"매우 많다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[0],
                  borderColor:exampleColors[0],
                }}
            ></span>
        </Tooltip>
        :""} */}
      </td>
      {/* 발생유무 */}
      <td
        className={
        "border-t border-l border-gray_80 p-4 text-sm font-semibold text-gray_10 " +
        (idx + 1 === symptomList?.length ? "border-y " : "")
        }
      >
        {/* {head.presence === -1?
          <Tooltip title={"발생 o"} arrow>
            <img
            className="m-auto"
            src=" /images/admin/icon/해당없음.svg"
            alt="해당없음 아이콘"/>
          </Tooltip>
        
        :head.presence === 1?
          <Tooltip title={"발생 o"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[4],
                  borderColor:exampleColors[4],
                }}
            ></span>
          </Tooltip>
        : head.presence === 5?
        <Tooltip title={"발생 x"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[0],
                  borderColor:exampleColors[0],
                }}
            ></span>
        </Tooltip>
        :""} */}
        </td>
        {/* // 양 */}
        <td
        className={
        "border-t border-l border-r border-gray_80 p-4 text-sm font-semibold text-gray_10 " +
        (idx + 1 === symptomList?.length ? "border-y rounded-br-lg" : "")
        }
      >
        {/* {head.amount === -1?
          <Tooltip title={"질문 해당 안됨"} arrow>
            <img
            className="m-auto"
            src=" /images/admin/icon/해당없음.svg"
            alt="해당없음 아이콘"/>
          </Tooltip>
        : head.amount === 1?
          <Tooltip title={"전혀 없다"} arrow>
              <span
                  className= {toolTipClassName}
                  style={{
                    backgroundColor: exampleColors[4],
                    borderColor:exampleColors[4],
                  }}
              ></span>
          </Tooltip>
        : head.amount === 2?
          <Tooltip title={"약간 있다"} arrow>
              <span
                  className= {toolTipClassName}
                  style={{
                    backgroundColor: exampleColors[3],
                    borderColor:exampleColors[3],
                  }}
              ></span>
          </Tooltip>
        : head.amount === 3?
        <Tooltip title={"다소 있다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[2],
                  borderColor:exampleColors[2],
                }}
            ></span>
        </Tooltip>
        : head.amount === 4?
        <Tooltip title={"많다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[1],
                  borderColor:exampleColors[1],
                }}
            ></span>
        </Tooltip>
        : head.amount === 5?
        <Tooltip title={"매우 많다"} arrow>
            <span
                className= {toolTipClassName}
                style={{
                  backgroundColor: exampleColors[0],
                  borderColor:exampleColors[0],
                }}
            ></span>
        </Tooltip>
      :""} */}
    </td>


  </>
  )
}

export default SymptomBoxTd