import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import { adminEditPwd } from "../../../../features/admin/adminSlice";
import CommonSquareBox from "../common/CommonSquareBox";
import {
  correctInputRing,
  correctMsgColor,
  errorInputRing,
  errorMsgColor,
  normalInputRing,
  normalMsgColor,
} from "../../data/TailwindCommon";
import { checkPwd } from "../../commonfunc/Reg";
import { LabelAdminInput } from "../common/LabelAdminInput";

const EditAdminPwd = ({ findAdminId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [findInput, setFindInput] = useState({
    pass: "",
    repass: "",
  });
  const acceptPass =
    findInput.pass === findInput.repass &&
    !(findInput.pass === "" || findInput.repass === "") &&
    checkPwd(findInput.pass) &&
    checkPwd(findInput.repass);

  const EditPwdIn = async () => {
    let newparam = {
      adminId: findAdminId,
      pass: findInput.pass,
      repass: findInput.repass,
    };
    let response = await dispatch(adminEditPwd(newparam)).unwrap();
    try {
      if (response.data.ok === true) {
        toast(
          <p className="whitespace-pre-line">
            저장되었습니다. <br />
            변경된 비밀번호로 로그인 해주세요.
          </p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
        setTimeout(() => navigate("/admin/signin"), 1000);
      } else {
        toast(
          <p className="whitespace-pre-line">
            비밀번호 변경에 실패하였습니다. <br />
            잠시 후 다시 시도해 주세요.
          </p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangePass = (e) => {
    const { name, value } = e.target;
    setFindInput({ ...findInput, [name]: value });
  };

  return (
    <div className="flex items-center">
      <div className=" mb-16 flex h-[585px] w-[416px] flex-col items-center ">
        <CommonSquareBox>
          <img
            src="/images/admin/icon/힐리어리새비밀번호입력.svg"
            alt="힐리어리 새 비밀번호 입력 이미지"
            className="items-center pb-5"
          />
          <div className="order-1 flex flex-col items-end gap-11">
            <div className="flex flex-col items-end gap-[20px]">
              <LabelAdminInput
                title={"새 비밀번호"}
                name={"pass"}
                type={"password"}
                value={findInput.pass}
                placeholderVal={"새로 사용할 비밀번호를 입력해주세요"}
                inputcss={
                  findInput.pass === ""
                    ? "w-[300px]" + normalInputRing
                    : checkPwd(findInput.pass)
                    ? "w-[300px]" + correctInputRing
                    : "w-[300px]" + errorInputRing
                }
                onChangeAction={(e) => onChangePass(e)}
                onKeyPressAction={EditPwdIn}
                onetimeuse={true}
                checkCss={
                  findInput.pass === ""
                    ? normalMsgColor
                    : checkPwd(findInput.pass)
                    ? correctMsgColor
                    : errorMsgColor
                }
                checkVal={
                  checkPwd(findInput.pass)
                    ? "안전한 비밀번호 입니다"
                    : "숫자, 문자, 특수문자 포함 8~12자리로 입력해주세요"
                }
              />
              <LabelAdminInput
                title={"새 비밀번호 확인"}
                name={"repass"}
                type={"password"}
                value={findInput.repass}
                placeholderVal={"새 비밀번호를 다시 입력해주세요"}
                inputcss={
                  findInput.repass === ""
                    ? "w-[300px]" + normalInputRing
                    : checkPwd(findInput.repass) &&
                      findInput.pass === findInput.repass
                    ? "w-[300px]" + correctInputRing
                    : "w-[300px]" + errorInputRing
                }
                onChangeAction={(e) => onChangePass(e)}
                onKeyPressAction={EditPwdIn}
                onetimeuse={true}
                checkCss={
                  findInput.repass === ""
                    ? normalMsgColor
                    : checkPwd(findInput.repass) &&
                      findInput.pass === findInput.repass
                    ? correctMsgColor
                    : errorMsgColor
                }
                checkVal={
                  checkPwd(findInput.repass) &&
                  findInput.pass === findInput.repass
                    ? "비밀번호가 일치합니다."
                    : "비밀번호가 일치하지 않습니다."
                }
              />
            </div>
            <div className="order-1 flex flex-col items-start gap-2 self-stretch">
              <button
                onClick={acceptPass ? EditPwdIn : undefined}
                className={
                  "flex flex-row items-center justify-center gap-[10px] self-stretch rounded p-3 text-caption1_600 text-gray_10 " +
                  (acceptPass ? " bg-orange_100  " : " bg-orange_80 ")
                }
              >
                저장하기
              </button>
              <button
                onClick={() => {
                  navigate("/admin/signin");
                }}
                className={
                  "flex flex-row items-center justify-center gap-[10px] self-stretch rounded bg-gray_120 p-3  text-caption1_600 text-gray_10 "
                }
              >
                홈으로
              </button>
            </div>
          </div>
        </CommonSquareBox>
      </div>
    </div>
  );
};

export default EditAdminPwd;
