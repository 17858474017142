
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getCustomerCenterQuestion, onSetCustomerCenterIdName } from '../../../../../features/mobile/settingSlice'

const UsageInquiry = ({ customerCenterQ, customerCenterId }) => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const navigate = useNavigate()
  const scrollRef = useRef()

  const targetId = document.getElementById(`usage${customerCenterId}`)
  useEffect(() => { //ios에서는 scrollIntoView가 안먹는다고 알고있는데 scrollTo는 안먹히고 intoView는먹힘 (slice에 해당 targetId의 scroll)
    if (!(customerCenterId === null || customerCenterId === undefined)) {
      targetId?.scrollIntoView({ behavior: 'smooth', top: targetId.offsetTop })
    }
  }, [customerCenterId, targetId])

  useEffect(() => {
    dispatch(getCustomerCenterQuestion({ userId: loginInfo.userId }))
  }, [dispatch, loginInfo.userId])

  const InquiryList = (el) => {
    return (
      <>
        <span className=
          {'inline-block px-[6px] py-[2px] text-caption2_700 rounded' + (el.aWaiting ? " bg-green_100 text-white " : " border border-gray_120 text-gray_120 ")}
        >{el.aWaiting ? "답변완료" : "답변대기"}</span>
        <p className='pt-[10px] text-body3_600'>{el.title}</p>
        <span className='text-caption1_400 text-gray_140'>{`${dayjs(el.createdAt).format("YYYY.MM.DD")}${dayjs(el.createdAt).format("A") === "PM" ? " 오후" : " 오전"} ${dayjs(el.createdAt).format("H") > 12 ? dayjs(el.createdAt).format("H") - 12 : dayjs(el.createdAt).format("H")}시`}</span>
      </>
    )
  }

  return (
    <>
      <p className='p-6 text-center text-caption1_600 text-gray_160 bg-gray_15 border-b'>힐리어리 앱에 대해 궁금하신 점을 문의해주세요.</p>
      <ul className='bg-white' ref={scrollRef}>
        {customerCenterQ?.map((el, idx) => (
          <li className='p-6 border-b' key={idx}
            id={`usage${el?.id}`}
            onClick={() => { dispatch(onSetCustomerCenterIdName(el?.id)); navigate("/contact/usageqa") }}
          >
            {InquiryList(el)}
          </li>
        ))}
      </ul>
    </>
  )
}

export default UsageInquiry