import React from "react";
import { useSelector } from "react-redux";

const EditSelect = ({
  selects,
  idname,
  label,
  onChangeSelect,
  editForm,
  state,
}) => {
  const role = (el) => {
    let value = "";
    el === "슈퍼관리자(헤링스)"
      ? (value = "00")
      : el === "일반관리자(헤링스)"
      ? (value = "01")
      : el === "슈퍼관리자(병원)"
      ? (value = "10")
      : (value = "11");
    return value;
  };

  return (
    <div className="w-full">
      <span className="text-xs	text-[#463F38]">{label}</span>
      <div className="itens-center box-border flex h-[48px] w-full flex-row justify-between gap-[16px] rounded-[4px] border border-[#A39F9B] bg-[#FCFCFC]	px-[15px] py-[12px]">
        <select
          className=" w-full bg-[#FCFCFC] outline-none"
          id={idname}
          name={idname}
          onChange={onChangeSelect}
        >
          {selects?.map((el, idx) => (
            <option
              key={idx}
              value={
                idname === "authority"
                  ? role(el)
                  : idname === "approval"
                  ? state?.approval
                  : idname === "adminId"
                  ? state?.adminId
                  : state?.gender
              }
              className="flex h-[24px] w-[42px] items-center bg-[#FCFCFC] text-base font-normal hover:bg-[#FFF4F0]"
            >
              {el}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default EditSelect;
