import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ dataName, data, recommended }) => {
  const series =
    dataName === "체중"
      ? [
          {
            name: dataName,
            data: data || [0, 0, 0, 0, 0, 0, 0],
          },
        ]
      : [
          {
            name: dataName,
            data: data || [0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "목표",
            data: recommended,
          },
        ];

  const chartOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false, // 차트 상단의 툴바를 숨깁니다.
      },
    },
    // xaxis: {
    //   categories: [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //   ],
    // },
    stroke: {
      curve: "smooth", // 선의 형태를 부드러운 곡선으로 설정합니다.
      width: dataName === "체중" ? 4 : [4, 1], // 선 굵기
      dashArray: dataName === "체중" ? null : [, 2],
    },
    markers: {
      size: 0, // 기본 마커 사이즈를 0으로 설정하여 다른 모든 마커를 숨김
      discrete: [
        {
          seriesIndex: 0,
          dataPointIndex: 0, // 시작 인덱스
          fillColor: "#fff",
          strokeColor: "#FFC700",
          strokeWidth: 2,
          size: 4,
        },
        {
          seriesIndex: 0,
          dataPointIndex: 6, // 마지막 포인트의 인덱스
          fillColor: "#fff",
          strokeColor: "#FFC700",
          strokeWidth: 2,
          size: 4,
        },
      ],
    },

    legend: {
      show: false, // 범례 숨기기
    },
    grid: {
      show: false, // 그리드 숨기기
    },
    tooltip: {
      show: false, // 툴팁 숨기기
    },
    xaxis: {
      labels: {
        show: false, // X축 레이블 숨기기
      },
      axisBorder: {
        show: false, // X축 경계선 숨기기
      },
      axisTicks: {
        show: false, // X축 눈금 숨기기
      },
    },
    yaxis: {
      labels: {
        show: false, // Y축 레이블 숨기기
      },
    },
    colors: ["#FFC700", "#888787"],
  };

  return <ReactApexChart options={chartOptions} series={series} type="line" />;
};

export default LineChart;
