import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { onSetScrollNum } from "../../../../features/mobile/mealSlice";

const InputBox = ({ setStatus, type, scrollNum }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [value, setValue] = useState(null);

  return (
    <>
      <div className="flex items-center justify-between py-2 pl-3 pr-2 rounded-xl bg-gray_20">
        <input
          onFocus={() => {
            dispatch(onSetScrollNum(scrollNum));
            setStatus({ option: "add", type });
          }}
          ref={inputRef}
          type={"text"}
          placeholder={"드신 음식을 입력해주세요."}
          onChange={(e) => setValue(e.target.value)}
          value={value || ""}
          className={
            "w-full appearance-none bg-gray_20 text-body3_700 text-black placeholder:font-medium focus:outline-none focus:ring-0"
          }
        />
        <ul className="flex items-center gap-4">
          <li className="p-3 rounded-lg bg-gray_40 text-caption2_700 text-gray_100">
            0kcal
          </li>
        </ul>
      </div>
    </>
  );
};

export default InputBox;
