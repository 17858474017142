import React from "react";
import Parser from "html-react-parser";

const HomeResultCard = ({ data }) => {
  return (
    <div className="w-full px-2.5 text-center">
      {/* <h1 className="text-subtitle3">
        <span>
          {data?.title === "영양 섭취 목표"
            ? "🥦"
            : data?.title === "단백질 섭취 안내"
            ? "🍖"
            : data?.title === "영양밸런스"
            ? "⚖️"
            : "🍏️ "}{" "}
        </span>
        {data?.title}
      </h1> */}

      {/* <span className="mx-auto my-4 flex h-[1px] w-[48px] bg-gray_60"></span> */}
      <div className="space-y-3">
        <div>{data?.content ? Parser(data?.content) : ""}</div>
        {data?.title === "주의식품" && data?.cautionFoodList?.length > 0 && (
          <div className="rounded-md bg-gray_20 p-4">
            <div className="mx-auto w-fit text-left">
              {data?.cautionFoodList.map((food, idx) => (
                <p className="text-caption1_400 text-black" key={idx}>
                  {idx + 1}. {food}
                </p>
              ))}
            </div>
          </div>
        )}
        <div>{data?.subContent ? Parser(data?.subContent) : ""}</div>
      </div>
    </div>
  );
};

export default HomeResultCard;
