import React from "react";
import { useNavigate } from "react-router-dom";
import { pointFn } from "../../components/logic";
import TitleBox from "../common/TitleBox";
import NutritionInner from "./NutritionInner";
import { HealthInfoButton } from "./HealthInfoButton";

const NutritionCard = ({ myinfo, handleToggle }) => {
  const navigate = useNavigate();
  return (
    <div className="space-y-[28px] rounded-lg bg-orange_10 p-6 " id="result">
      <div onClick={handleToggle} className="flex flex-row justify-between">
        <TitleBox option={1} title={"영양 관리 목표"} />
        <img
          alt={"arrowRight"}
          src="/images/mobile/icon/arrow_right.svg"
          className="transform rotate-90"
        />
      </div>

      {!(myinfo.kcal === undefined || myinfo.protein === undefined) ? (
        <div className="grid grid-cols-2 gap-2">
          <NutritionInner
            imgUrl={"/images/mobile/image/rice.svg"}
            title={"하루 필요 열량"}
            num={pointFn(myinfo.kcal.toFixed(1))}
            unit={"kcal"}
          />
          <NutritionInner
            imgUrl={"/images/mobile/image/fish.svg"}
            title={"하루 필요 단백질"}
            num={pointFn(myinfo.protein.toFixed(1))}
            unit={"g"}
          />
        </div>
      ) : (
        <div className="flex justify-between">"건강설문을 진행해주세요."</div>
      )}
    </div>
  );
};

export default NutritionCard;
