import React from 'react'

const EatGraphBoxKcalProtein = ({averageKCal,needKCal,averageProtein,needProtein}) => {

  const repeatBox = (title,subtitle1,subcontent1,unit, subtitle2,subcontent2) =>{
    const arr = [{key1:subtitle1,key2:subcontent1,key3:unit},{key1:subtitle2,key2:subcontent2,key3:unit}]
    return(
      <div className="bg-gray_10 rounded-lg p-4 border border-gray_90">
        <h2 className="text-body2_600 text-black">{title}</h2>
        <div className="flex justify-between divide-x mt-4">
          {arr?.map((el,idx)=>(
            <div className="w-1/2 pr-4" key={idx}>
              <h3 className="flex justify-end text-caption1_400 text-gray_140 py-2">
                {el.key1}
              </h3>
              <div className="flex justify-end items-center">
                <h3 className="text-body3_600 text-black">
                  {el.key2}
                </h3>
                <span className="text-caption2_400 text-black pt-1">{el.key3}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    )

  }
  return (
    <>
    {repeatBox("열량","섭취량(일평균)",Math.ceil(averageKCal) || "-","kcal","하루 필요량",Math.ceil(needKCal) || "-")}
    {repeatBox("단백질량","섭취량(일평균)",averageProtein?.toFixed(1) || "-","g","하루 필요량",needProtein?.toFixed(1) || "-")}     
    </>
  )
}

export default EatGraphBoxKcalProtein