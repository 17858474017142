import React from "react";
import "dayjs/locale/ko";

const DetailTable = ({ eatDayLists, option, idx }) => {
  const titles = ["-", "메뉴", "섭취량", "열량(kcal)", "단백질(g)"];
  const datas = [
    {
      id: 1,
      endTitle: "B",
      title: "아침 식사",
      imgUrl: "/images/admin/meal/rice.svg",
    },
    {
      id: 2,
      endTitle: "BS",
      title: "아침 간식",
      imgUrl: "/images/admin/meal/apple.svg",
    },
    {
      id: 3,
      endTitle: "L",
      title: "점심 식사",
      imgUrl: "/images/admin/meal/rice.svg",
    },
    {
      id: 4,
      endTitle: "LS",
      title: "점심 간식",
      imgUrl: "/images/admin/meal/apple.svg",
    },
    {
      id: 5,
      endTitle: "D",
      title: "저녁 식사",
      imgUrl: "/images/admin/meal/rice.svg",
    },
    {
      id: 6,
      endTitle: "DS",
      title: "저녁 간식",
      imgUrl: "/images/admin/meal/apple.svg",
    },
  ];
  const usedEatDayList =
    option === "addedEat" ? eatDayLists.eatList : eatDayLists.mealList;
  const colors =
    option === "addedEat" ? ["#FFF2C6", "#FFF8E1"] : ["#FDD2C3", "#FFF4F0"];
  const rowSpanArr = datas
    ?.map((data) =>
      usedEatDayList
        ?.filter((item) => item.mealType === data.endTitle)
        ?.map((item) => item.detailList?.length)
    )
    ?.map((data) => {
      if (data?.length === 0) return new Array(1).fill(0);
      return data;
    });

  return (
    <div className="flex flex-col">
      <div
        className="py-2 text-center border border-b-0 border-gray_60"
        style={{ backgroundColor: colors[0] }}
      >
        {eatDayLists["mealDay"]}
      </div>
      <table
        className="text-caption_1 min-w-[360px] table-auto border-separate text-gray_10"
        cellSpacing={0}
      >
        <thead className="text-center text-black">
          <tr>
            {titles?.map((title, idx) => (
              <th
                key={idx}
                className="border-l border-t border-gray_60 p-1.5 font-normal last:border-r"
                style={{ backgroundColor: colors[1] }}
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-black">
          {datas?.map((data, idx) => (
            <React.Fragment key={idx}>
              <tr>
                <td
                  rowSpan={
                    rowSpanArr[idx][0] === 0 ? 2 : rowSpanArr[idx][0] + 2
                  }
                  className="w-max border-l border-t border-gray_60 p-1.5"
                  style={{ backgroundColor: colors[1] }}
                >
                  <img src={data.imgUrl} alt={data.title} />
                  <span>{data.title}</span>
                </td>
              </tr>

              {usedEatDayList
                ?.filter((item) => item.mealType === data.endTitle)
                ?.map((item) =>
                  item.detailList?.map((detail, idx) => (
                    <tr key={idx}>
                      <td className="w-max border-l border-t border-gray_60 p-1.5">
                        <span className="flex-col ellipsisTable">
                          {detail.foodName}
                        </span>
                      </td>
                      <td className="w-max border-l border-t border-gray_60 p-1.5 ">
                        <span className="flex flex-col ellipsisTable">
                          {detail.eyemesurementnumber}
                          {detail.eyemesurementunit}
                        </span>
                      </td>
                      <td className="w-max border-l border-t border-gray_60 p-1.5">
                        <span className="flex flex-col">
                          {detail.kcal ? detail.kcal?.toFixed(2) : "-"}
                        </span>
                      </td>
                      <td className="w-max border border-b-0 border-gray_60 p-1.5">
                        <span className="flex flex-col">
                          {detail.proteinG ? detail.proteinG?.toFixed(2) : "-"}
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              {rowSpanArr[idx][0] === 0 ? (
                <tr>
                  <td
                    colSpan="2"
                    className="border-l border-t border-gray_60 p-1.5"
                  ></td>
                  <td className="w-max border-l border-t border-gray_60 p-1.5"></td>
                  <td className="w-max border border-b-0 border-gray_60 p-1.5"></td>
                </tr>
              ) : (
                <tr>
                  <td
                    colSpan="2"
                    className="border-l border-t border-gray_60 p-1.5"
                  >
                    합계
                  </td>

                  <td className="w-max border-l border-t border-gray_60 p-1.5">
                    {usedEatDayList?.filter(
                      (item) => item.mealType === data.endTitle
                    )[0] !== undefined
                      ? usedEatDayList
                          ?.filter((item) => item.mealType === data.endTitle)[0]
                          .kcal?.toFixed(2)
                      : null}
                  </td>
                  <td className="w-max border border-b-0 border-gray_60 p-1.5">
                    {usedEatDayList?.filter(
                      (item) => item.mealType === data.endTitle
                    )[0] !== undefined
                      ? usedEatDayList
                          ?.filter((item) => item.mealType === data.endTitle)[0]
                          .proteinG?.toFixed(2)
                      : null}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
          <tr>
            <td
              colSpan="3"
              className="w-[60px] border border-r-0 border-gray_60 p-1.5"
            >
              total
            </td>
            <td className="w-[60px] border border-r-0 border-gray_60 p-1.5">
              {eatDayLists.kcal ? eatDayLists.kcal?.toFixed(2) : ""}{" "}
              {/** 대이타 목록이 없을 때 ""*/}
            </td>
            <td className="w-[60px] border border-gray_60 p-1.5">
              {eatDayLists.proteinG ? eatDayLists.proteinG?.toFixed(2) : ""}{" "}
              {/** 대이타 목록이 없을 때 */}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DetailTable;
