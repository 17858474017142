import dayjs from "dayjs";
import { AnimatePresence } from "framer-motion";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import EditBtn from "../EditBtn";
import ProfilePicture from "../ProfilePicture";
import PostReportDialog from "../PostReportDialog";
import { formatStringWithLinks } from "../../logic";
import useLongPress from "../../../hooks/useLongPress";

const CommentCard = ({
  postId,
  reply,
  onDeleteReply,
  onEditReply,
  onBlockUser,
  onAddReporting,
  onReReply,
  setStatus,
  nickName,
  childReply,
  isChild,
  totalLength,
  setOptionOpen,
  optionOpen,
  onClickThreeDots,
}) => {
  dayjs.locale("ko");
  const moreIcon = useMemo(
    () => ({
      background:
        "url('/images/mobile/icon/vertical_three_dot.svg') no-repeat center",
    }),
    []
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [reportStatus, setReportStatus] = useState("report");
  const [reason, setReason] = useState("");
  const { loginInfo } = useSelector((state) => state.commonApp);

  const onCloseDialog = () => {
    if (reportStatus === "complete") {
      onAddReporting(postId, reply?.id, reason); //, childReply?.length ? 1+childReply?.length : 1)
      setStatus("read");
      setReportStatus("report");
    }
    if (reportStatus === "block")
      onBlockUser(reply?.writer?.userId, "reportReply");
    setOpenDialog(false);
  };
  const onOpenDialog = () => {
    setOpenDialog(true);
    setOptionOpen({ open: false, id: null });
  };

  const onCancelReport = () => {
    onAddReporting(postId, reply?.id);
    setOptionOpen({ open: false, id: null });
  };

  const onReportReply = (reason) => {
    setReportStatus("complete");
    setReason(reason);
  };

  const { action, handlers } = useLongPress();

  return (
    <>
      {reply?.isDeleted || reply?.isBlocked ? (
        // ?   totalLength !== 1 ? <div className={'text-body2_500l text-gray_100 py-4 px-6 border-b border-gray_60 ' + (isChild && 'bg-gray_20')}>{reply?.content}</div> : <></>
        <div
          className={
            "flex border-b border-gray_60 py-4 pl-6 pr-4 text-body2_500l text-gray_100 " +
            (isChild && "bg-gray_20")
          }
        >
          {reply?.content}
          {reply?.isBlocked && !reply?.isDeleted && (
            <div className="my-auto ml-auto mr-[1px]">
              <div
                id="three_dot_menu"
                className="w-6 h-6"
                style={moreIcon}
                onClick={() => onClickThreeDots(reply.id)}
              />

              <AnimatePresence>
                {optionOpen.open && optionOpen.id === reply.id && (
                  <EditBtn
                    titleUp="차단 해제"
                    onActionUp={() =>
                      onBlockUser(reply?.writer?.userId, "reportReply")
                    }
                    location="absolute"
                    dropdown={true}
                    open={optionOpen.open}
                    onClickOutside={() => {
                      setOptionOpen({ open: false, id: null });
                    }}
                  />
                )}
              </AnimatePresence>
            </div>
          )}
        </div>
      ) : (
        <div
          className={
            "space-y-2 border-b border-gray_60 px-4 py-6 " +
            (isChild && "bg-gray_20")
          }
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <ProfilePicture writer={reply?.writer} location={"comment"} />
              <h2
                className={
                  "flex text-body3_600 " +
                  (reply?.writerAdmin
                    ? "text-black"
                    : reply?.writer?.userId === loginInfo.userId
                    ? "text-main"
                    : "text-gray_160")
                }
              >
                {nickName}{" "}
                {reply?.writerAdmin && (
                  <img
                    className="w-4 h-4 my-auto"
                    alt="adminCheck"
                    src="/images/mobile/icon/check-badge.svg"
                  />
                )}
              </h2>
            </div>
            {/* {openEditBtn && ( */}
            <div className="my-auto ml-auto mr-[1px]">
              <div
                id="three_dot_menu"
                className="w-6 h-6"
                style={moreIcon}
                onClick={() => onClickThreeDots(reply.id)}
              />
              <AnimatePresence>
                {loginInfo?.userId === reply?.writer?.userId
                  ? optionOpen.open &&
                    optionOpen.id === reply.id && (
                      <EditBtn
                        titleUp="댓글 수정"
                        onActionUp={() =>
                          onEditReply(reply?.content, reply?.id)
                        }
                        titleDown="댓글 삭제"
                        onActionDown={() => onDeleteReply(reply?.id)}
                        location="absolute"
                        dropdown={true}
                        open={optionOpen.open}
                        onClickOutside={() => {
                          setOptionOpen({ open: false, id: null });
                        }}
                      />
                    )
                  : optionOpen.open &&
                    optionOpen.id === reply.id && (
                      // <EditBtn titleUp="답댓글 작성" onActionUp={() => onReReply(reply)} titleDown={"댓글 신고"} onActionDown={() => onOpenDialog()} location="absolute" dropdown={true} open={optionOpen} onClickOutside={() => {setOptionOpen(false);}} />
                      <EditBtn
                        titleUp="답댓글 작성"
                        onActionUp={() => onReReply(reply)}
                        titleDown={
                          reply?.writer &&
                          (reply?.isReported ? "신고 해제" : "댓글 신고")
                        }
                        onActionDown={() =>
                          reply?.writer &&
                          (reply?.isReported
                            ? onCancelReport()
                            : onOpenDialog())
                        }
                        location="absolute"
                        dropdown={true}
                        open={optionOpen.open}
                        onClickOutside={() => {
                          setOptionOpen({ open: false, id: null });
                        }}
                      />
                    )}
              </AnimatePresence>
            </div>
          </div>
          <p
            className="break-all text-body2_500l text-gray_180"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {reply?.tagNickName && (
              <span className="text-body2_500l text-gray_140">
                @{reply?.tagNickName}&nbsp;
              </span>
            )}
            {formatStringWithLinks(
              reply?.content,
              false,
              null,
              action,
              handlers
            )}
          </p>
          <h2 className="text-right text-caption2_400 text-gray_140">
            {dayjs(reply?.createdAt).format("YYYY-MM-DD A h:mm")}
          </h2>
        </div>
      )}
      {openDialog && (
        <PostReportDialog
          onCloseDialog={onCloseDialog}
          onReportReply={onReportReply}
          reportStatus={reportStatus}
          post={false}
          nickName={reply?.writer?.nickName}
        />
      )}
    </>
  );
};

export default CommentCard;
