import React from "react";
import InfoSkeleton from "./InfoSkeleton";

const InfoLoading = ({ tabTitle }) => {
  return (
    <>
      <InfoSkeleton />
      <InfoSkeleton />
      <InfoSkeleton />
      <div className="h-24"></div>
    </>
  );
};

export default InfoLoading;
