import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  onResetFoodDetail,
  onResetSimilarFoodLists,
  fetchSimilarFoodSearch,
  changeRecommendFood,
  fetchRecommendMeal,
} from "../../../../features/mobile/recommendSlice";
import SimilarFoodCard from "./SimilarFoodCard";
// import Btn from "../common/Btn";
import LikeBtns from "../common/LikeBtns";
import { useEffect } from "react";
// import BookmarkBtn from '../common/BookmarkBtn';

const FoodDetailDialog = ({
  openFoodDialog,
  setOpenFoodDialog,
  shareState,
  setLoading,
  recipeHidden,
}) => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { foodDetailStatus, foodDetail, isExistRecipe, similarFoodLists } =
    useSelector((state) => state.recommend);
  const [chosenOne, setChosenOne] = useState({
    id: null,
    eyemesurementnumber: null,
    foodName: "",
  });
  // const [openTab, setOpenTab] = useState(false);
  const [showMenuChange, setShowMenuChange] = useState(false);
  const [menuChangeErrorMsg, setMenuChangeErrorMsg] = useState(false);
  const [checkText, setCheckText] = useState("");
  const [checkColor, setCheckColor] = useState("");
  const [checkHeight, setCheckHeight] = useState(" h-[500px] ");
  const [miniHeight, setMiniHeight] = useState(" h-[380px] ");
  const [checkAni, setCheckAni] = useState("animate-rightSlide");
  const cpf = [
    {
      name: "탄",
      keyName: "carbohydratesG",
      unit: "g",
      bgColor: "bg-[#73C07A]",
    },
    { name: "단", keyName: "proteinG", unit: "g", bgColor: "bg-[#F68282]" },
    { name: "지", keyName: "fatG", unit: "g", bgColor: "bg-[#FFD442]" },
  ];
  const nutri = [
    { name: "식이섬유", keyName: "totalDietaryfiberG", unit: "g" },
    { name: "당류", keyName: "totalsugarG", unit: "g" },
    { name: "포화지방", keyName: "totalSaturatedfattyacidG", unit: "g" },
    { name: "나트륨", keyName: "sodiumMG", unit: "mg" },
    { name: "콜레스테롤", keyName: "cholesterolMG", unit: "mg" },
    { name: "트랜스지방", keyName: "transfattyacidG", unit: "g" },
  ];
  const navigate = useNavigate();

  const cpfReturn = (el) => {
    return (
      <>
        <span className={"block h-2 w-2 rounded-full " + el.bgColor}></span>
        <span>
          {el.name}{" "}
          {parseInt(foodDetail[el.keyName]) !== null
            ? parseInt(foodDetail[el.keyName])
            : "-"}
          {el.unit}
        </span>
      </>
    );
  };

  const foodTypeEmoji = (foodDetail) => {
    return (
      <>
        {foodDetail && foodDetail.foodType ? (
          <span>
            {foodDetail.foodType.includes("밥")
              ? "🍚"
              : foodDetail.foodType.includes("국")
              ? "🍲"
              : foodDetail.foodType.includes("단백질")
              ? "🍖"
              : foodDetail.foodType.includes("채소")
              ? "🥗"
              : foodDetail.foodType.includes("김치")
              ? "🌶"
              : foodDetail.foodType.includes("간식")
              ? "🍎"
              : foodDetail.foodType.includes("죽")
              ? "🍚"
              : "🍚"}
          </span>
        ) : null}
      </>
    );
  };

  const onCloseDialog = () => {
    setOpenFoodDialog({
      open: false,
      id: null,
      foodId: null,
      mealDate: null,
      mealType: null,
    });
    setShowMenuChange(false);
    // setOpenTab(false);
    setMenuChangeErrorMsg(false);
    const timer = setTimeout(() => {
      if (foodDetail?.id) dispatch(onResetFoodDetail());
      if (similarFoodLists?.length > 0) dispatch(onResetSimilarFoodLists());
    }, 300);
    return () => clearTimeout(timer);
  };

  const onOpenMenuChange = () => {
    window.dataLayer.push({
      event: "change_from_food",
      food_id: foodDetail?.foodId,
      food_name: foodDetail?.foodName,
    });

    let params = { userId: loginInfo.userId, foodId: openFoodDialog.id };
    dispatch(fetchSimilarFoodSearch(params))
      .unwrap()
      .then((res) => {
        if (!res.ok) {
          setMenuChangeErrorMsg(res.error);
        }
      })
      .catch((err) => console.log(err));
    setChosenOne({ id: null, eyemesurementnumber: null });
    setShowMenuChange(true);
  };

  const onSaveChangedMenu = () => {
    window.dataLayer.push({
      event: "selected_food_name",
      food_id: chosenOne.id,
      food_name: chosenOne.foodName,
    });

    if (menuChangeErrorMsg) {
      onCloseDialog();
    } else {
      let params = {
        userId: loginInfo.userId,
        orgFoodId: openFoodDialog.id,
        changeFoodId: chosenOne.id,
        eyemesurementnumber: chosenOne.eyemesurementnumber,
      };
      setLoading(false);
      dispatch(changeRecommendFood(params))
        .unwrap()
        .then(() =>
          dispatch(fetchRecommendMeal({ userId: loginInfo.userId }))
            .unwrap()
            .then(() => setLoading(true))
        )
        .catch((err) => console.log(err));
    }
    setChosenOne({ id: null, eyemesurementnumber: null, foodName: "" });
    onCloseDialog();
  };

  const onBackMenu = () => {
    // * 뒤로가기시 오른쪽으로 들어가는 애니메이션
    setCheckAni("animate-leftSlide");
    // setShowMenuChange(true);
    // * 이벤트 시간과 비슷하게 시간 맞춤
    setTimeout(() => {
      setShowMenuChange(false);
      setCheckAni("animate-rightSlide");
    }, 400);

    // console.log("실행됨");
  };

  useEffect(() => {
    setCheckColor("");
    setCheckText("");
    if (foodDetail && foodDetail.contentunit === "g") {
      const SodiumRisk = (foodDetail.sodiumMG / foodDetail.servingsize) * 100;
      if (foodDetail.sodiumMG === null) {
        setCheckText("-");
      } else if (SodiumRisk < 120) {
        setCheckColor("text-green_120");
        setCheckText("낮음");
      } else if (SodiumRisk >= 120 && SodiumRisk <= 600) {
        setCheckColor("text-yellow_160");
        setCheckText("보통");
      } else if (SodiumRisk > 600) {
        setCheckColor("text-orange_180");
        setCheckText("높음");
      }
    } else if (
      foodDetail &&
      (foodDetail.contentunit === "mL" || foodDetail.contentunit === "ml")
    ) {
      const SodiumRisk = (foodDetail.sodiumMG / foodDetail.servingsize) * 100;
      if (foodDetail.sodiumMG === null) {
        setCheckText("-");
      } else if (SodiumRisk < 120) {
        setCheckColor("text-green_120");
        setCheckText("낮음");
      } else if (SodiumRisk >= 120 && SodiumRisk <= 300) {
        setCheckColor("text-yellow_160");
        setCheckText("보통");
      } else if (SodiumRisk > 300) {
        setCheckColor("text-orange_180");
        setCheckText("높음");
      }
    }
    if (
      checkText !== "낮음" &&
      foodDetail &&
      foodDetail.foodType !== "밥" &&
      !foodDetail.foodType.includes("간식") &&
      foodDetail.foodType !== "죽"
    ) {
      setCheckHeight(" h-[500px] ");
      setMiniHeight(" h-[380px] ");
    } else {
      setCheckHeight(" h-[440px] ");
      setMiniHeight(" h-[320px] ");
    }
  }, [foodDetail, checkText, openFoodDialog]);

  return (
    <>
      <div
        onClick={onCloseDialog}
        className={
          openFoodDialog.open
            ? "fixed left-0 top-0 z-10 h-full w-full bg-black opacity-70"
            : ""
        }
      ></div>
      {/* { */}
      {/* !showMenuChange ? ( */}
      <div
        className={
          `fixed z-20 flex-col items-start rounded-t-[32px] bg-white py-[32px]  duration-700 ${checkHeight} maxW left-0 right-0 isolate mx-auto gap-6 ` +
          (window.innerWidth <= 368
            ? "px-[15px] "
            : window.innerWidth <= 372
            ? "px-[20px] "
            : window.innerWidth <= 376
            ? "px-[22px] "
            : "px-[24px] ") +
          // (checkText !== "낮음" &&
          // foodDetail &&
          // foodDetail.foodType !== "밥" &&
          // !foodDetail.foodType.includes("간식") &&
          // foodDetail.foodType !== "죽"
          //   ? "h-[500px] "
          //   : "h-[440px] ") +
          (openFoodDialog.open
            ? "bottom-0"
            : foodDetail &&
              foodDetail.foodType !== "밥" &&
              !foodDetail.foodType.includes("간식") &&
              foodDetail.foodType !== "죽"
            ? "bottom-[-500px]"
            : "bottom-[-500px]")
          // : 'bottom-[-440px]')

          // ? "bottom-[-620px]"
          // : "bottom-[-540px]"
        }
      >
        <div
          className="bgCover rounded-t-[32px]"
          // style={{
          //   background: `url('${
          //     foodDetail?.representativeImage
          //       ? foodDetail?.representativeImage
          //       : "/images/mobile/image/no_image.svg"
          //   }') no-repeat center`,
          // }}
        >
          <img
            src="/images/mobile/icon/close.svg"
            alt="닫기"
            className="absolute right-6 top-6"
            onClick={onCloseDialog}
          />
        </div>
        {foodDetailStatus === "success" && foodDetail?.foodId ? (
          // <div className="flex flex-col items-center w-full gap-2 ">
          <div>
            <div className="flex flex-col items-start gap-2">
              <span
                id="foodName"
                className="items-center text-subtitle1 text-black"
              >
                {foodDetail.foodName}
              </span>
              <ul className="order-1 flex w-full flex-row items-start gap-2">
                {/* <li className="flex items-center">
                  {foodTypeEmoji(foodDetail)}
                  <h2 className="text-body2_600 text-gray_140">
                    {foodDetail.foodType?.length > 5
                      ? foodDetail.foodType.slice(0, 5) + ".."
                      : foodDetail.foodType || "-"}
                  </h2>
                </li> */}
                {/* <span className=""></span> */}
                <li className="text-caption1_400 text-gray_160">
                  총 {foodDetail.servingsize || "-"}
                  {foodDetail.contentunit}
                </li>
                <li className="order-1 h-[24px] self-stretch text-gray_40">
                  |
                </li>
                {/* <span className="inline-block w-[1px] h-full bg-gray_40 block mx-[8px]"></span> */}
                <li className="order-2 text-caption1_400 text-gray_160">
                  {foodDetail.eyemesurementnumber || "-"}
                  {foodDetail.eyemesurementunit}
                </li>
                <li className="order-3 h-[24px] self-stretch text-gray_40">
                  |
                </li>
                {/* <span className="inline-block w-[1px] h-full bg-gray_40 block mx-[8px]"></span> */}
                <li className="order-4 text-caption1_400 text-gray_160">
                  {foodDetail.kcal || "-"}kcal
                </li>
                <li className="order-5 h-[24px] self-stretch text-gray_40">
                  |
                </li>
                {/* <span className="inline-block w-[1px] h-full bg-gray_40 block mx-[8px]"></span> */}
                <li className="order-6 text-caption1_400 text-gray_160">
                  나트륨
                </li>
                <li className={` ${checkColor} order-7 text-caption1_600 `}>
                  {checkText}
                </li>
              </ul>
            </div>
            {shareState !== true ? (
              <div className="z-10 order-1 flex flex-row items-center justify-between gap-2 py-[24px] ">
                <div
                  typeof="button"
                  className={
                    " box-border flex flex-row items-center justify-center gap-2 rounded-[36px] border border-main bg-main py-[8px] pl-[10px] pr-[12px] " +
                    (recipeHidden && " pointer-events-none opacity-50 ")
                  }
                  onClick={onOpenMenuChange}
                >
                  <img
                    src="/images/mobile/icon/arrow_left_right.svg"
                    alt="왼쪽 오른쪽 화살표 아이콘"
                  />
                  <span className="order-1 flex items-center text-center text-caption1_700 text-white ">
                    음식 바꾸기
                  </span>
                </div>
                <div
                  typeof="button"
                  className={
                    "order-1 box-border flex flex-row items-center justify-center gap-1 rounded-[36px] border bg-white py-[8px] pl-[10px] pr-[12px]" +
                    (isExistRecipe ? " border-[#5CCFFE]" : " border-gray_100")
                  }
                  onClick={
                    isExistRecipe
                      ? () => navigate(`/recommend/recipe/${foodDetail.foodId}`)
                      : null
                  }
                >
                  <img
                    src={
                      isExistRecipe
                        ? "/images/mobile/icon/recipe_icon.svg"
                        : "/images/mobile/icon/recipe_none_icon.svg"
                    }
                    alt="레시피 아이콘"
                  />
                  <span
                    className={
                      "flex items-center text-center text-caption1_700 " +
                      (isExistRecipe ? " text-[#5CCFFE] " : " text-gray_100 ")
                    }
                  >
                    레시피
                  </span>
                </div>

                <LikeBtns
                  option={"food"}
                  checkStatus={foodDetail.like}
                  id={foodDetail.foodId}
                  foodName={foodDetail.foodName}
                  marginR=" order-2 "
                  // likeTitle={"이 음식 좋아요"}
                />
                {/* <ul className="flex flex-row items-center  justify-end gap-[11px] order-2 ]">
                  <LikeBtns
                    option={"food"}
                    checkStatus={foodDetail.like}
                    id={foodDetail.foodId}
                    marginR="  "
                    // likeTitle={"이 음식 좋아요"}
                  />
                </ul> */}
                {/* <BookmarkBtn option={'food'} checkStatus={foodDetail.bookmark} id={foodDetail.foodId} title={'즐겨찾기'} /> */}
              </div>
            ) : shareState === true && isExistRecipe ? (
              <div className="flex py-[24px]">
                <div
                  typeof="button"
                  className={
                    "order-1 box-border flex flex-row items-center justify-center gap-1 rounded-[36px] border bg-white py-[8px] pl-[10px] pr-[12px]" +
                    (isExistRecipe ? " border-orange_140" : " border-gray_100")
                  }
                  onClick={
                    isExistRecipe && shareState === true
                      ? () =>
                          navigate(
                            `/recommend/recipe/share/${foodDetail.foodId}`
                          )
                      : isExistRecipe
                      ? () => navigate(`/recommend/recipe/${foodDetail.foodId}`)
                      : null
                  }
                >
                  <img
                    src={
                      isExistRecipe
                        ? "/images/mobile/icon/recipe_icon.svg"
                        : "/images/mobile/icon/recipe_none_icon.svg"
                    }
                    alt="레시피 아이콘"
                  />
                  <span
                    className={
                      "flex items-center text-center text-caption1_700 " +
                      (isExistRecipe ? " text-orange_140 " : " text-gray_100 ")
                    }
                  >
                    레시피
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex py-[20px]"></div>
            )}

            {foodDetail.foodType !== "밥" &&
            !foodDetail.foodType.includes("간식") &&
            foodDetail.foodType !== "죽" ? (
              <div className="z-20 order-2 mb-[24px] flex flex-col items-start gap-2 self-stretch rounded-[12px] bg-gray_15 p-4">
                <span className="flex items-center text-caption1_400 text-gray_200">
                  {foodDetail.foodType === "국"
                    ? "🍲  국"
                    : foodDetail.foodType === "단백질 반찬"
                    ? "🍖 단백질 반찬"
                    : foodDetail.foodType === "채소 반찬"
                    ? "🥗 채소 반찬"
                    : foodDetail.foodType === "김치"
                    ? "🌶 김치"
                    : "🍚 일품 메뉴"}
                </span>
                {checkText !== "낮음" ? (
                  <span className="order-1 self-stretch text-caption2_400 text-gray_160">
                    {foodDetail.foodType === "국"
                      ? "국은 건더기 위주로 먹고 국물을 남기면 소금 섭취를 1/3로 줄일 수 있습니다.​"
                      : foodDetail.foodType === "단백질 반찬" ||
                        foodDetail.foodType === "채소 반찬"
                      ? "반찬을 만들 때 소금 대신 간장, 간장보다는 된장, 고추장을 사용하면 소금 사용을 줄일 수 있습니다."
                      : foodDetail.foodType === "김치"
                      ? "김치를 잘게 잘라서 조금만 먹으면 소금 섭취를 줄일 수 있습니다. ​"
                      : "조리 시 소금 사용량을 줄이고 재료 고유의 맛을 살리면 소금 섭취를 줄일 수 있습니다."}
                  </span>
                ) : null}
              </div>
            ) : (
              <div className="z-20 order-2 mb-[24px] flex flex-col items-start gap-2 self-stretch rounded-[12px] bg-gray_15 p-4">
                <span className="flex items-center text-caption1_400 text-gray_200">
                  {foodDetail.foodType === "밥"
                    ? "🍚 밥"
                    : foodDetail.foodType === "죽"
                    ? "🍚 죽"
                    : foodDetail.foodType.includes("간식")
                    ? "🍎 간식"
                    : "🍚"}
                </span>
              </div>
            )}

            <div className="z-30 order-3 flex flex-col items-start gap-2 self-stretch rounded-[12px] bg-gray_15 p-[12px]">
              <div className="flex flex-row items-center gap-4 self-stretch">
                <ul className="flex flex-row items-start gap-4">
                  {cpf.map((el, idx) => (
                    <li
                      key={idx}
                      className="flex items-center gap-1 text-caption1_400"
                    >
                      {cpfReturn(el)}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="order-1 flex flex-col items-start gap-4 self-stretch py-1 pr-2">
                <ul className="grid grid-flow-col grid-rows-3 items-start self-stretch text-caption2_400 text-gray_160">
                  {nutri.map((el, idx) => (
                    <li key={idx}>
                      <span>• {el.name}:</span>
                      <span>
                        {" "}
                        {foodDetail[el.keyName] !== null
                          ? foodDetail[el.keyName]
                          : "-"}
                        {el.unit}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {showMenuChange ? (
        <div
          className={
            ` maxW fixed left-0 right-0 z-20 mx-auto rounded-t-[32px] bg-white ${checkHeight} ${checkAni} ` +
            (openFoodDialog.open ? "bottom-0" : "bottom-[-500px]")
          }
        >
          <ul className="flex cursor-pointer items-center justify-between py-2 pl-4 pr-2 ">
            <li className="flex items-center gap-2">
              <img
                src="/images/mobile/icon/back_btn.svg"
                alt="뒤로가기 버튼"
                onClick={onBackMenu}
              />
            </li>
            <li className="order-1">
              <h2 className="text-caption1_400 text-gray_200">
                {foodDetail && foodTypeEmoji(foodDetail)}{" "}
                {foodDetail ? foodDetail.foodType : "-"}
              </h2>
            </li>
            <li className="order-2">
              <img
                onClick={onCloseDialog}
                src="/images/mobile/icon/close.svg"
                alt="닫기 아이콘"
              />
            </li>
          </ul>

          <div
            className={`pb-[24px] pt-2 ${miniHeight} order-1 overflow-y-scroll px-[24px]`}
          >
            {/* <div className="flex items-center justify-between gap-2 pb-2 border-b border-gray_90">
              <h2 className="text-black text-subtitle1">
                {foodDetail.foodName}
              </h2>
              <h5 className="text-body2_600 text-gray_140">
                {foodDetail.eyemesurementnumber || ""}{" "}
                {foodDetail.eyemesurementunit || "ea"}
              </h5>
            </div> */}

            {!menuChangeErrorMsg && (
              <p className="mt-2 text-caption1_400 text-gray_200">
                <span className="text-caption1_700 text-black">
                  '{foodDetail && foodDetail.foodName}'
                </span>{" "}
                대신
                <span className="text-caption1_600"> 저염분 </span>
                순으로 추천 드리는 음식입니다.
                <br />
                원하시는 음식을 선택해주세요.
              </p>
            )}
            {similarFoodLists && similarFoodLists?.length > 0 ? (
              <div className="mb-1 mt-4 flex flex-col gap-3">
                {similarFoodLists.map((food) => (
                  <SimilarFoodCard
                    key={food.id}
                    chosenOne={chosenOne}
                    setChosenOne={setChosenOne}
                    food={food}
                  />
                ))}
              </div>
            ) : null}
            {menuChangeErrorMsg && (
              <p className="body2_500 mt-2 text-center text-gray_160">
                {menuChangeErrorMsg}
              </p>
            )}
          </div>

          {/* <Btn
            option={"bottom"}
            bgColor={"bg-main"}
            textColor={"text-white"}
            borderColor={"border border-main"}
            title={menuChangeErrorMsg ? "확인" : "선택한 음식으로 메뉴 변경"}
            active={menuChangeErrorMsg || chosenOne.id ? "on" : "ready"}
            onAction={onSaveChangedMenu}
          /> */}

          <div className="maxW fixed bottom-0 left-0 right-0 mx-auto items-center justify-center text-center text-white ">
            <button
              className={
                " w-full gap-[2px] p-4 text-body3_700  " +
                (menuChangeErrorMsg || chosenOne.id
                  ? " border-main bg-main text-white active:border-blue-600 active:bg-blue-600 "
                  : " bg-blue-300 text-white ")
              }
              onClick={(e) => {
                if (menuChangeErrorMsg || chosenOne.id) {
                  if (menuChangeErrorMsg === "바꾸기 가능한 메뉴가 없습니다.") {
                    onBackMenu(e);
                  } else {
                    onSaveChangedMenu(e);
                  }
                }
              }}
            >
              <span className="text-body3_700 text-white">
                {menuChangeErrorMsg === "바꾸기 가능한 메뉴가 없습니다."
                  ? "확인"
                  : "음식 바꾸기"}
              </span>
            </button>
          </div>
        </div>
      ) : null}
      {/* )  */}
    </>
  );
};

export default FoodDetailDialog;
