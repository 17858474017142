import { data } from 'jquery'
import React from 'react'

const ContentsSelect = ({
  datas,
  name,
  onChangeContent,
  categories
}) => {
  const divcommoncss = "box-border flex flex-row justify-between items-center p-[12px] gap-[16px] h-[48px] bg-[#FCFCFC] border border-gray_100 rounded-[8px] cursor-pointer "
  const selectcommoncss = "w-full bg-[#FCFCFC] outline-none text-gray_100 focus:text-black  cursor-pointer"
  return (
    <div className="px-3 py-2 rounded w-1/3">
      <p className='text-caption_2 text-black'>{datas.label}</p>
      <div className={divcommoncss}>
        <select
        id={datas.id}
        value={datas.id ===1? categories.lcategory: datas.id ===2? categories.mcategory: datas.id ===3? categories.scategory:""}
        name={name}
        className={selectcommoncss}
        onChange={onChangeContent}
        >
          {datas.options?.map((el,idx)=>(
            <option 
            key={idx} >
              {el}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default ContentsSelect