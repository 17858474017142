import React from 'react';
import { useSelector } from "react-redux";
import Parser from "html-react-parser";

const AnswerCard = ({ option, type, title, answer, onAction }) => {
    const { questionLists, i, gettingAnswers } = useSelector((state) => state.questionFoodAdd)
    return (
        <>
            {option === 2 ? //2개이상 질문 페지
                <li onClick={() => onAction(type, answer)} className={'cursor-pointer p-4 border rounded-2xl text-center '
                    + (gettingAnswers[type] === answer ? 'bg-gray_180' : 'border border-gray_200')}>
                    <p className={gettingAnswers[type] === answer ? 'text-btn_700 text-white' : 'text-btn_700 text-gray_200'}>
                        {title ? Parser(title) : ''}
                    </p>
                </li>
                : option === 3 ? //한 열에 3개 문항
                    <li onClick={() => onAction(type, answer)} className={'cursor-pointer py-4 px-1 border rounded-2xl text-center '
                        + (gettingAnswers[type] === answer ? 'bg-gray_180' : 'border border-gray_200')}>
                        <p className={gettingAnswers[type] === answer ? 'text-btn_700 text-white' : 'text-btn_700 text-gray_200'}>
                            {title ? Parser(title) : ''}
                        </p>
                    </li>
                    : option === 0 ? //foodPreferType일 경우만
                        <li onClick={() => onAction(type, answer)} className={'cursor-pointer py-4 px-4 border rounded-2xl '
                            + (gettingAnswers?.[questionLists[i]?.saveUserColumn]&& gettingAnswers?.[questionLists[i]?.saveUserColumn][0] === answer[0] ? 'bg-gray_180 ' : 'border border-gray_200 ') + (type === 'userType' ? 'text-left' : 'text-center')}>
                            <p className={gettingAnswers?.[questionLists[i]?.saveUserColumn]&&gettingAnswers?.[questionLists[i]?.saveUserColumn][0] === answer[0] ? 'text-btn_700 text-white' : 'text-btn_700 text-gray_200'}>
                                {title ? Parser(title) : ''}
                            </p>
                        </li>
                        : 
                        <li onClick={() => onAction(type, answer)} className={'cursor-pointer py-4 px-4 border rounded-2xl '
                            + (gettingAnswers?.[questionLists[i]?.saveUserColumn] === answer ? 'bg-gray_180 ' : 'border border-gray_200 ') + (type === 'userType' ? 'text-left' : 'text-center')}>
                            <p className={gettingAnswers?.[questionLists[i]?.saveUserColumn] === answer ? 'text-btn_700 text-white' : 'text-btn_700 text-gray_200'}>
                                {title ? Parser(title) : ''}
                            </p>
                        </li>
            }
        </>
    );
};

export default AnswerCard;