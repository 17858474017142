import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchInfoPostingDetails } from "../../../../features/mobile/infoSlice";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import InfoDetail from "./InfoDetail";
import CommentCardHolder from "../../components/common/comments/CommentCardHolder";
const InfoDetailWithComment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  // Redux State Variables
  const { loginInfo, prevReplyList } = useSelector((state) => state.commonApp);
  const { totalReplies } = useSelector((state) => state.info);

  // Local State Variables
  const [status, setStatus] = useState("read"); // Status indicating which window is currently open
  const [postComment, setPostComment] = useState(null); // variable for comment text area
  // Bringing in the post detail
  const bringPostingDetails = () => {
    let params = { userId: loginInfo.userId, postingId: id };
    dispatch(fetchInfoPostingDetails(params));
  };

  useEffect(() => {
    if (!loginInfo?.userId) navigate(`/sign`);
    else {
      window.scrollTo(0, 0);
      setTimeout(bringPostingDetails(), 3000);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {status === "read" && <InfoDetail myPage={true} />}
      <CommentCardHolder
        commonParams={{
          postId: id,
          postComment,
          setPostComment,
          status,
          setStatus,
          totalReplies,
          prevReplyList,
          bringPostingDetails,
        }}
        loginInfo={loginInfo}
        myPage={true}
      />
    </>
  );
};

export default InfoDetailWithComment;
