import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { onSetAccountTab } from "../../../../features/admin/accountSlice";
import {
  handlePageChange,
  onSetSubSection
} from "../../../../features/admin/commonSlice";

const AccountTabs = ({ ACCOUNTTABS, filterInfo, tabName, onClickSearch }) => {
  const dispatch = useDispatch();
  return ACCOUNTTABS?.map((tab, idx) => (
    <div
      key={idx}
      onClick={() => {
        dispatch(onSetAccountTab(tab));
        dispatch(onSetSubSection(tab));
        dispatch(handlePageChange(1));

        filterInfo.searchNameOrID = "";
        onClickSearch(tab, 1);
      }}
      className={
        "text-center rounded-t-2xl py-4 text-body_2 cursor-pointer w-32 h-15 " +
        (tabName === tab
          ? "bg-white text-body3_600 text-black font-semibold"
          : "bg-gray_30 text-body3_600 text-gray_100")
      }
    >
      {tab}
    </div>
  ));
};

export default AccountTabs;
