import React, { useCallback, useEffect, useRef, useState } from "react";
import HeadCont from "../../components/common/HeadCont";
import { useDispatch, useSelector } from "react-redux";
import {
  addReply,
  changeNavTab,
  deleteReply,
  getMyReplies,
  onResetListeners,
  onSetExistNextReply,
  onSetPrevReplyList,
  onSetRangeMonth,
  onSetSearchedValue,
} from "../../../../features/mobile/commonSlice";
import ReplyCard from "../../components/mypage/ReplyCard";
import useOutsideClick from "../../hooks/useOutsideClick";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Btn from "../../components/common/Btn";
import { toast } from "react-toastify";
import toastCom from "../../../../common/toast";
import ListCommentSkeleton from "../../components/loading/ListCommentSkeleton";

const timeSpan = {
  전체: null,
  "1개월": 1,
  "3개월": 3,
  "6개월": 6,
  "1년": 12,
};

/**
 *
 * @date 6/20/2023 - 4:10:18 PM
 *
 * @returns {*}
 */
const MyComments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux states
  const {
    loginInfo,
    navTab,
    prevReplyList,
    existNextReply,
    rangeMonth,
    searchedValue,

    // listeners
    isModified,
    modifyType,
    modifiedId,
    modifiedContent,
  } = useSelector((state) => state.commonApp);

  // local states
  // const [localNavTab, setLocalNavTab] = useState(navTab);
  const [editComment, setEditComment] = useState({ id: 0, status: false });
  const [optionOpen, setOptionOpen] = useState({ open: false, id: null });
  const [openListViewBtn, setOpenListViewBtn] = useState(false);
  const [comment, setComment] = useState(null);
  const [currentScrollPosition, setCurrentPosition] = useState(0);

  // specific for displaying replies
  const [isMorePosting, setIsMorePosting] = useState(existNextReply);
  const [searchText, setSearchText] = useState(searchedValue || null);
  const [loading, setLoading] = useState(true);
  const [replyList, setReplyList] = useState(
    prevReplyList?.length > 0 ? prevReplyList : []
  );

  // references and hooks
  const textArea = useRef();

  // hook for outside click detection
  const ref = useOutsideClick(
    useCallback(() => {
      setOpenListViewBtn(false);
    }, [setOpenListViewBtn])
  );

  ///////////////////
  // etc., functions
  ///////////////////
  const matchTimeSpan = (rangeMonth) => {
    const [foundKey] =
      Object.entries(timeSpan).find(([key, value]) => value === rangeMonth) ||
      [];
    if (foundKey) return { [foundKey]: rangeMonth };
    else return { 전체: null };
  };
  const [currentTimeSpan, setCurrentTimeSpan] = useState(
    rangeMonth ? matchTimeSpan(rangeMonth) : { 전체: null }
  );

  // When an input is registered to the textBox
  const onChangeAction = (e) => {
    setComment(e.target.value);
    if (e.target.scrollHeight > 66 * 3) return;
    e.target.style.height = "68px";
    let textAreaH = e.target.scrollHeight;
    e.target.style.height = `${textAreaH}px`;
    window.scrollBy(0, 66);
  };

  const onClickPostInner = (postId) => {
    // dispatch(changeNavTab(localNavTab));
    dispatch(onSetSearchedValue(searchText));
    dispatch(onSetPrevReplyList(replyList));
    // if (localNavTab) {
    //   navigate(`/community/${postId}`);
    // } else {
    //   navigate(`/mypage/infocomment/${postId}`);
    // }
  };

  const resetLocalStates = () => {
    setSearchText(null);
    setCurrentTimeSpan({ 전체: null });
    dispatch(onSetPrevReplyList([]));
    dispatch(onSetSearchedValue(null));
    dispatch(onSetRangeMonth(null));
  };

  ///////////
  // API's //
  ///////////
  /**
   * api = /posting/getPageMyReplies (getMyReplies())
   *
   * OnBringMyReplies posts *params* and receives a result where if the result is ok
   * we update the local *replyList* and *isMorePosting*, and dispatch *onSetExistNextReply*
   *
   * The function is a Promise, and returns true on successful completetion.
   *
   * !!!
   * The prevReplyList is not dispatched because it is being dispatched during the navigation of posts
   * !!!
   * @date 6/20/2023 - 1:19:25 PM
   *
   * @param {Object} params - Parameters for fetching my replies.
   * @param {number} params.countPerPage - The number of replies to fetch per page. Default is 10.
   * @param {number} params.currentListCount - The current count of replies in the list. Default is 0.
   * @param {string|null} params.searchText - The search text to filter replies. Default is null.
   * @param {boolean} params.isInfo - Flag indicating whether to retrieve information from info page. Default is false (community).
   * @param {number|null} params.rangeMonth - The range of months for filtering replies. Default is null (전체). (1,3,6,12)
   * @returns {Promise} - A Promise representing the fetching of my replies.
   */
  const onBringMyReplies = useCallback(
    ({
      countPerPage = 10,
      currentListCount = 0,
      searchText = null,
      isInfo = false,
      rangeMonth = null,
    }) => {
      return new Promise((resolve, reject) => {
        const params = {
          userId: loginInfo?.userId,
          countPerPage: countPerPage,
          currentListCount: currentListCount,
          searchText: searchText,
          isInfo: isInfo,
          rangeMonth: rangeMonth,
        };
        dispatch(getMyReplies(params))
          .unwrap()
          .then((res) => {
            if (res.ok) {
              // local states
              setReplyList(res.replyList);
              setIsMorePosting(res.isExistNextReply);
              // redux states
              dispatch(onSetExistNextReply(res.isExistNextReply));
              setLoading(true);
              resolve(true);
            } else {
              reject(new Error(res?.error));
            }
          })
          .catch((error) =>
            reject({ ...error, location: "MyComments.js:190:1" })
          );
      });
    },
    [dispatch, loginInfo]
  );

  /**
   * api = /posting/getPageMyReplies (getMyReplies())
   *
   * onFetchMoreReplies is called to continue the fetch is *isMorePosting* is true (within <InifiniteScroll>)
   * Since the function is within MyComments Func Component, the local states (searchText, rangeMonth, replyList.length, loginInfo)
   * are used.
   *
   * Upon successful getMyReplies it concats resulting replyList to the local *replyList* and sets and dispatched
   * isExistNextReply.
   *
   * @date 6/20/2023 - 1:19:25 PM
   *
   * @param {bool|undefined} isInfo
   * @returns {Promise<bool|Error>}
   */
  const onFetchMoreReplies = (isInfo) => {
    return new Promise((resolve, reject) => {
      const params = {
        userId: loginInfo?.userId,
        countPerPage: 10,
        currentListCount: replyList.length,
        searchText: searchText,
        isInfo: isInfo,
        rangeMonth: rangeMonth,
      };

      dispatch(getMyReplies(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            // local states
            setReplyList(replyList.concat(res.replyList));
            setIsMorePosting(res.isExistNextReply);
            // redux states
            dispatch(onSetExistNextReply(res.isExistNextReply));
            setLoading(true);
            resolve(true);
          } else {
            reject(new Error(res?.error));
          }
        })
        .catch((error) =>
          reject({ ...error, location: "MyComments.js:240:1" })
        );
    });
  };

  /**
   * api = /posting/addReply (addReply())
   *
   * Edits the comment (editComment.id).
   * When the edit is successful, it updates the local *replyList* and resets the *editComment*
   * Since the scroll location is reset, we use currentScrollPostion (which was initialized before the
   * screen changed to editComment screen) and scroll to the correct position
   *
   * @date 6/20/2023 - 1:24:48 PM
   */
  const onEditComment = () => {
    let params = {
      writerUserId: loginInfo.userId,
      id: editComment.id,
      content: comment,
    };
    setComment("");

    dispatch(addReply(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          setReplyList((prev) => {
            const temp = prev.map((item) =>
              item.id === editComment.id ? { ...item, content: comment } : item
            );
            // was about to put dispatch and update prevReplyList
            // but we can update them when we leave the screen
            return temp;
          });
          setEditComment({ id: 0, status: false });
          setTimeout(() => window.scrollTo(0, currentScrollPosition), 100);
        }
      });
  };

  /**
   * api = /posting/deleteReply (deleteReply)
   *
   * Deletes comment designated to replyId. If the operation is successful,
   * take out the corresponding comment from the local *replyList*
   *
   * @date 6/20/2023 - 1:27:44 PM
   *
   * @param {number} replyId
   */
  const onDeleteComment = (replyId) => {
    let params = { id: replyId };
    dispatch(deleteReply(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          setReplyList((prev) => prev.filter((item) => item.id !== replyId));
          toast(<p>댓글 삭제완료</p>, toastCom("bottom-center", "toast_alert"));
        }
      });
  };

  useEffect(() => {
    const reload = () => {
      setLoading(false);
      onBringMyReplies({
        // isInfo: !localNavTab,
        searchText: searchText,
        rangeMonth: rangeMonth,
      })
        .then((res) => null)
        .catch((error) => console.log(error));
    };
    if (isModified) {
      let temp = [];
      try {
        switch (modifyType) {
          case "Delete": // not being used
            temp = prevReplyList.filter((item) => item.id !== modifiedId);
            setReplyList(temp);
            dispatch(onSetPrevReplyList(temp));
            dispatch(onResetListeners());
            break;
          case "Edit": // not being used
            temp = prevReplyList.map((item) =>
              item.id === modifiedId
                ? { ...item, content: modifiedContent }
                : item
            );
            console.log(temp);
            setReplyList(temp);

            dispatch(onSetPrevReplyList(temp));
            dispatch(onResetListeners());
            break;
          case "Add":
            // Add character Photo and name of the post the reply is being added on
            dispatch(onResetListeners());
            reload();
            break;
          default:
            // is from community/ info, but did not add edit or delete any replies
            dispatch(onResetListeners());
            break;
        }
      } catch (error) {
        console.log(error);
      }
    } else if (replyList?.length === 0) {
      reload();
    }

    // eslint-disable-next-line
  }, []);

  // When there is no search value, reload the page
  useEffect(() => {
    if (searchText === "") {
      if (prevReplyList?.length === 0) {
        onBringMyReplies().catch((error) => console.log(error));
        // { isInfo: !localNavTab }
      } else {
        setReplyList(prevReplyList);
      }
    }
  }, [searchText, prevReplyList, onBringMyReplies]);

  return (
    <>
      <HeadCont
        title={editComment.status && "댓글 수정"}
        onLeftAction={() => {
          if (editComment.status) {
            setEditComment({ id: 0, status: false });
          } else {
            navigate(-1);
          }
        }}
      />
      {editComment.status ? (
        <>
          <div className="mx-6 mt-16">
            <textarea
              onChange={onChangeAction}
              onClick={() =>
                setTimeout(
                  () =>
                    textArea?.current?.scrollIntoView({ behavior: "smooth" }),
                  400
                )
              }
              ref={textArea}
              id={"question"}
              name={"question"}
              value={comment || ""}
              maxLength={"600"}
              placeholder={"댓글을 작성해보세요."}
              className="no-scrollbar mb- h-[66px] w-full resize-none appearance-none rounded border border-gray_80 p-4 text-body2_500l text-black focus:border-gray_160 focus:outline-none focus:ring-0"
              style={{ whiteSpace: "pre-wrap" }}
            />
            <span className="float-right text-caption1_400 text-gray_160">
              {comment?.length || "0"}/600
            </span>
          </div>
          <div className="h-28" />
          {/* <div className="mt-4"> */}
          <Btn
            option={"bottom"}
            bgColor={"bg-main"}
            textColor={"text-white font-semibold"}
            borderColor={"border border-main"}
            title={"작성"}
            active={comment?.length > 0 ? "on" : "ready"}
            onAction={() => onEditComment()}
          />
        </>
      ) : (
        <>
          <div className="mt-14" />
          <h1 className="mx-4 mb-2.5 mt-2 text-subtitle1">나의 댓글</h1>
          <section>
            {/* Search Tab */}
            <div className="flex flex-col gap-2.5 p-4 py-2.5">
              <span className="text-caption1_700 text-gray_160">
                키워드로 검색하기
              </span>
              <div className="relative flex items-center gap-1">
                <input
                  placeholder={"키워드를 입력해주세요."}
                  className="w-full p-4 leading-none text-black border rounded appearance-none resize-none no-scrollbar border-gray_80 text-body2_500l focus:border-gray_160 focus:outline-none focus:ring-0"
                  value={searchText || ""}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
                <figure
                  className="absolute right-4 top-4 h-fit shrink-0"
                  onClick={() =>
                    onBringMyReplies({
                      // isInfo: !localNavTab,
                      searchText: searchText,
                    }).catch((error) => console.log(error))
                  }
                >
                  <img
                    alt="search"
                    src="/images/mobile/icon/search.svg"
                    className="w-6 h-6"
                  />
                </figure>
              </div>
            </div>
            {/* Main Content */}
            <div className="flex justify-end border-y border-gray_60 bg-white px-6 py-2.5">
              <div
                className="flex gap-1.5"
                onClick={() => setOpenListViewBtn(!openListViewBtn)}
              >
                <span className="text-caption2_700" id="three_dot_menu">
                  {Object.keys(currentTimeSpan)[0]}
                </span>
                <div className="w-6 h-6 shrink-0">
                  <img
                    alt="viewMore"
                    src="/images/mobile/icon/list_more.svg"
                    id="three_dot_menu"
                  />
                </div>
              </div>
            </div>
            <AnimatePresence>
              {openListViewBtn && (
                <motion.ul
                  initial={{ opacity: 0, y: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, y: 0 }}
                  transition={{ ease: "easeOut", duration: 0.2 }}
                  ref={ref}
                  className={
                    "absolute right-[24px] z-[6] mt-[-15px] flex h-min flex-col rounded-xl bg-[#FFFFFF] px-5 py-4 opacity-100 shadow-[0_0_12px_rgba(0,0,0,0.1)] "
                  }
                >
                  {Object.entries(timeSpan)?.map(([key, value]) => (
                    <li key={key} className="flex flex-col gap-2">
                      <div
                        className={
                          "focus:bg-primary_20 w-full text-center text-caption1_700 text-black focus:outline-none"
                        }
                        onClick={() => {
                          setLoading(false);
                          setCurrentTimeSpan({ [key]: value });
                          dispatch(onSetRangeMonth(value));
                          setOpenListViewBtn(false);
                          onBringMyReplies({
                            // isInfo: !localNavTab,
                            searchText: searchText,
                            rangeMonth: value,
                          }).catch((error) => console.log(error));
                        }}
                      >
                        {key}
                      </div>
                      {key !== "1년" && (
                        <div className="mb-2 border-b border-gray_40" />
                      )}
                    </li>
                  ))}

                  {/* </div> */}
                </motion.ul>
              )}
            </AnimatePresence>

            {loading ? (
              <ReplyCard
                onClickPostInner={onClickPostInner}
                // localNavTab={localNavTab}
                replyList={replyList}
                isMorePosting={isMorePosting}
                onFetchMoreReplies={onFetchMoreReplies}
                optionOpen={optionOpen}
                setOptionOpen={setOptionOpen}
                setEditComment={setEditComment}
                setComment={setComment}
                onDeleteComment={onDeleteComment}
                setCurrentPosition={setCurrentPosition}
              />
            ) : (
              <>
                <ListCommentSkeleton />
                <ListCommentSkeleton />
                <ListCommentSkeleton />
                <ListCommentSkeleton />
                <ListCommentSkeleton />
              </>
            )}
          </section>
        </>
      )}
    </>
  );
};

export default MyComments;
