import React from 'react'

const DontEditAccountInput = ({labelname, value}) => {
  return (
    <div className="flex flex-col items-start w-full">
    <label className="rounded-[4px] text-xs	text-[#463F38]">
      {labelname}
    </label>
    <label className="p-[16px] w-full bg-[#F3F3F2] border border-[#E8E7E7] rounded-[4px] h-[48px]
    flex items-center">
      {value}
    </label>
  </div>
  )
}

export default DontEditAccountInput
