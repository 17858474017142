import React, { useEffect, useState } from "react";
import HeadCont from "../components/common/HeadCont";
import CalendarHeader from "../components/common/CalendarHeader";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "../components/common/Dialog";
import {
  deleteOneTreatment,
  editOneTreatment,
  fetchExistTreatmentDataOfYear,
  fetchOneTreatment,
  fetchTreatment,
  saveTreatment,
} from "../../../features/mobile/treatmentSlice";
import Btn from "../components/common/Btn";
import { AnimatePresence, motion } from "framer-motion";
import { saveChosenDate } from "../../../features/mobile/commonSlice";
import { ToastContainer, toast } from "react-toastify";
import toastCommonProps from "../../../common/toast";

const Treatment = () => {
  dayjs.locale("ko");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState(false);

  const [status, setStatus] = useState({ status: "show", id: null }); //show: 보여주기, add: 입력하기, edit: 수정하기
  const [openDialog, setOpenDialog] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [value, setValue] = useState({
    hospital: "",
    time: "",
    alarm: 0,
    memo: "",
  });

  const alarmList = [
    { title: "당일(오전 9시)", value: 0 },
    { title: "1일전(오전 9시) ", value: 1 },
    { title: "2일전(오전 9시)", value: 2 },
    { title: "알림 안함", value: -1 },
  ];

  const { loginInfo, chosenDate } = useSelector((state) => state.commonApp);
  const { treatmentList, existDaysOfYear } = useSelector(
    (state) => state.treatment
  );

  const onSaveOrEditTreatment = async () => {
    console.log("dflakjsd");
    let userId = loginInfo.userId;
    let params = {
      name: value.hospital,
      date: chosenDate || startDate,
      hour: Number(value.time.split(":")[0]),
      minute: Number(value.time.split(":")[1]),
      alarm: value.alarm,
      memo: value.memo,
    };
    if (value.hospital.length <= 0) {
      toast(
        <p>병원 이름을 입력해주세요.</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
      return;
    }
    if (status.status === "add") {
      dispatch(saveTreatment({ ...params, userId }))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            dispatch(
              fetchExistTreatmentDataOfYear({
                userId: loginInfo.userId,
                year: Number(dayjs(startDate).format("YYYY")),
              })
            );
            setStatus({ status: "show", id: null });
          }
        })
        .catch((err) => console.log(err));
    } else if (status.status === "edit") {
      dispatch(editOneTreatment({ ...params, id: status.id }))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            setStatus({ status: "show", id: null });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onDeleteTreatment = () => {
    dispatch(deleteOneTreatment(status.id))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          dispatch(
            fetchExistTreatmentDataOfYear({
              userId: loginInfo.userId,
              year: Number(dayjs(startDate).format("YYYY")),
            })
          );
          setOpenDialog(false);
          setStatus({ status: "show", id: null });
        }
      });
  };

  useEffect(() => {
    dispatch(
      fetchTreatment({
        userId: loginInfo.userId,
        date: chosenDate || startDate,
      })
    );
  }, [chosenDate, status]);

  useEffect(() => {
    // 달력이 보이는 구간(startDate)이나 선택날짜 년도가 달라지면
    let params = {
      userId: loginInfo.userId,
      year: Number(dayjs(startDate).format("YYYY")),
    };
    dispatch(fetchExistTreatmentDataOfYear(params));
  }, [dayjs(startDate).format("YYYY")]);

  return (
    <>
      {status.status === "show" ? (
        <>
          <HeadCont
            title={"진료알림"}
            transparent={"white"}
            onLeftAction={() => navigate(-1)}
            onRightAction={() => navigate("/home")}
            rightIcon={"home"}
          />

          <CalendarHeader
            type={"진료알림"}
            startDate={startDate}
            setStartDate={setStartDate}
            chosenDate={chosenDate}
            // setChosenDate={setChosenDate}
            existDaysOfYear={[...existDaysOfYear]}
          />
          <div className="-mt-[28px] rounded-tl-[28px] bg-white p-6">
            <div className="pb-[80px] pt-[10px]">
              {treatmentList.length === 0 ? (
                <p className="text-xl font-bold text-[#05040D]">
                  진료알림을 추가해주세요!
                </p>
              ) : (
                <ul className="flex flex-col gap-[10px]">
                  {treatmentList.map(
                    ({ id, name, date, hour, minute, alarm, memo }) => {
                      return (
                        <li
                          key={id}
                          className="flex justify-between rounded-[10px] border border-[#B4EBFF] bg-[#E8F6FB] px-5 py-[10px]"
                          onClick={() => {
                            setValue({
                              hospital: name,
                              date,
                              time: `${hour}:${String(minute).padStart(
                                2,
                                "0"
                              )}`,
                              alarm: alarm || 0,
                              memo,
                            });
                            setStatus({ status: "edit", id: id });
                          }}
                        >
                          <div className="flex flex-col justify-center gap-3">
                            <div className="flex h-10 items-center">
                              <span className="line-clamp-2 w-52 text-ellipsis whitespace-normal text-[18px] font-semibold leading-[20px] text-[#0D0D0D]">
                                {name}
                              </span>
                            </div>
                            <div className="flex items-center gap-[6px] text-[#454545]">
                              <p>{date}</p>
                              <p className="h-[8px] w-[2px] bg-[#DBDFE0]"></p>
                              <p>
                                {hour > 12
                                  ? `오후 ${hour - 12}`
                                  : `오전 ${hour}`}
                                시 {minute}분
                              </p>
                            </div>
                          </div>
                          <img
                            src="/images/mobile/icon/chevron_skyblue.svg"
                            alt="수정"
                          />
                        </li>
                      );
                    }
                  )}
                </ul>
              )}
              <button
                onClick={() => {
                  setValue({
                    hospital: "",
                    memo: "",
                    alarm: 0,
                    time: dayjs(new Date()).format("HH:mm"),
                  });
                  setStatus({ status: "add", id: null });
                }}
                className="fixed bottom-4 left-6 right-6 rounded-2xl bg-[#3A404D] p-4 text-body3_700 text-white"
              >
                진료알림 추가
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <HeadCont
            title={"진료알림 설정"}
            transparent={"white"}
            underline={true}
            onLeftAction={() => setStatus({ status: "show", id: null })}
          />

          <div className="mt-14 flex flex-col gap-2 p-5 text-base font-bold text-[#444444]">
            <label className="my-[10px]">
              병원이름
              <input
                name="hospital"
                type="text"
                value={value.hospital}
                placeholder="예)힐리어리병원 내과"
                className="my-[10px] w-full rounded-md border border-[#DFDFDF] p-[10px] font-semibold text-[#545454] focus:outline-none focus:ring-0"
                onChange={(e) =>
                  setValue({ ...value, hospital: e.currentTarget.value })
                }
              />
              {}
            </label>
            <p>예약시간</p>
            <div className="my-[10px] flex gap-[10px]">
              <label className="flex w-1/2 flex-col">
                날짜
                <input
                  name="date"
                  type="date"
                  value={chosenDate || startDate}
                  pattern="\d{4}-\d{2}-\d{2}"
                  className="my-[10px] w-full appearance-none rounded-md border border-[#DFDFDF] bg-white p-[10px] font-semibold text-[#545454] focus:outline-none focus:ring-0"
                  onChange={(e) =>
                    dispatch(saveChosenDate(e.currentTarget.value))
                  }
                />
              </label>
              <label className="flex w-1/2 flex-col">
                시간
                <input
                  name="time"
                  type="time"
                  value={value.time}
                  className="my-[10px] w-full appearance-none rounded-md border border-[#DFDFDF] bg-white p-[10px] font-semibold text-[#545454] focus:outline-none focus:ring-0"
                  onChange={(e) =>
                    setValue({ ...value, time: e.currentTarget.value })
                  }
                />
              </label>
            </div>

            <p>알림</p>
            <div className="relative shrink-0">
              <button
                type="button"
                className="flex w-full items-center justify-center rounded-md border border-[#DFDFDF] bg-white p-[10px] font-semibold text-[#545454]"
                id="menu-button"
                onClick={() => {
                  setDropdown(!dropdown);
                }}
              >
                {
                  alarmList.filter((list) => list.value === value.alarm)[0]
                    ?.title
                }
              </button>
              <AnimatePresence>
                {dropdown ? (
                  <motion.div
                    initial={{ scaleY: 0 }}
                    animate={{
                      scaleY: 1,
                      transition: { ease: "easeIn", duration: 0.1 },
                    }}
                    exit={{
                      scale: 0,
                      transition: { type: "spring", bounce: 0, duration: 0.2 },
                    }}
                    style={{ originY: 0 }}
                    className="-shadow-basic absolute top-12 z-20 w-full overflow-hidden rounded-2xl"
                  >
                    <ul className="-shadow-basic flex h-fit w-full flex-col items-center overflow-hidden border bg-[#E8F6FB] font-bold">
                      {alarmList.map((list) => (
                        <li
                          key={list.title}
                          onClick={() => {
                            setValue({ ...value, alarm: list.value });
                            setDropdown(false);
                          }}
                          className="flex w-full justify-center overflow-hidden py-3 "
                        >
                          <button>{list.title}</button>
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </div>

            <label className="my-[10px]">
              메모추가
              <textarea
                name="memo"
                className="my-[10px] h-52 w-full rounded-md border border-[#DFDFDF] p-[10px] font-semibold text-[#545454] focus:outline-none focus:ring-0"
                placeholder="메모를 입력해주세요."
                value={value.memo}
                onChange={(e) =>
                  setValue({ ...value, memo: e.currentTarget.value })
                }
              />
            </label>
          </div>

          <div className="fixed bottom-4 left-6 right-6 flex gap-[10px] text-body3_700">
            {status.status === "edit" ? (
              <>
                <Btn
                  title={"삭제하기"}
                  innerClass="w-2/5 rounded-2xl"
                  textColor="text-[#3A404D]"
                  borderColor="border border-[#3A404D]"
                  bgColor="bg-white"
                  active={"on"}
                  onAction={() => setOpenDialog(true)}
                />

                <button
                  className={`w-3/5 rounded-2xl py-4 text-white ${
                    value.hospital.length > 0 && value.time !== null
                      ? "bg-[#3A404D]"
                      : "bg-[#939393] text-white"
                  }`}
                  onClick={onSaveOrEditTreatment}
                >
                  수정완료
                </button>
              </>
            ) : (
              <button
                className={`z-30 w-full rounded-2xl py-4 text-white ${
                  value.hospital.length > 0 && value.time !== null
                    ? "bg-[#3A404D]"
                    : "bg-[#939393] text-white"
                }`}
                onClick={onSaveOrEditTreatment}
              >
                추가하기
              </button>
            )}
          </div>

          {openDialog && (
            <Dialog
              innerClass={"p-4"}
              title={"알림 삭제"}
              desc={"해당 알림을 목록에서 삭제할까요?"}
              leftBtnTitle={"네, 삭제할게요"}
              rightBtnTitle={"아니요"}
              onLeftBtnAction={onDeleteTreatment}
              onRightBtnAction={() => setOpenDialog(false)}
              onCoverAction={() => setOpenDialog(false)}
            />
          )}
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default Treatment;
