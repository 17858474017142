import React, { useState } from 'react'
import { resizeFile } from '../../../../common/util/base';

const AccountImgChange = ({state,setPhoto}) => {
  const [loadedImage, setLoadedImage] = useState([])   
  const onChangeFile = async(e) => {
    let fileUrls = [];
      let cameraArr =[]
      for(let i=0; i<e.target.files?.length ; i++){
        let reader = new FileReader()
        let resize = await resizeFile(e.target.files[i])
        reader.readAsDataURL(resize)
        cameraArr.push(resize)
        setPhoto([...cameraArr])
        reader.onload = () =>{
          fileUrls[i] = reader.result
          setLoadedImage([...fileUrls])
          e.target.value = ""
        }
      }
    };
    const camerasvg = "/images/admin/icon/camera.svg";
  return (
    <>
      {state.photo === null && loadedImage?.length === 0?
        <span className="absolute top-0 left-0 w-[64px] h-[64px] bg-gray-100 rounded-full flex justify-center items-center font-bold">{state.name?.length!== 0?state.name.slice(0,1):""}</span>
      : (
        loadedImage?.length !== 0?
        <img 
        alt="loadedimage"
        className="absolute top-0 left-0 w-[64px] h-[64px] rounded-full" src={loadedImage[0]}/>
        :
        <img
        alt="loadedimage"
        className="absolute top-0 left-0 w-[64px] h-[64px] rounded-full" src={state.photo}/>
        )
      }
      <div className="absolute bottom-0 right-0 border border-[#A39F9B] cursor-pointer w-[24px] h-[24px] rounded flex justify-center items-center bg-white">
        <label
          htmlFor="input_file"
          className="w-full h-full cursor-pointer  font-bold text-base flex items-center justify-center"
        >
          <img src={camerasvg} alt="camera" />
        </label>
        <input
          id="input_file"
          className="w-full hidden"
          type="file"
          label="Upload"
          name="image"
          accept="image/*"
          multiple
          placeholder="첨부할 파일이 있는 경우 버튼을 클릭하여 등록하세요. (이미지는 최대 5개)"
          onChange={(e)=>onChangeFile(e)}
        />
      </div>
    </>
  )
}

export default AccountImgChange