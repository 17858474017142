import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";
//22.0824 게시판관리,계정관리 slice 다 섞여있어서 분리하려고 했으나 각 페이징 및 페이지에 틈틈이 적용되어있어서 일단 주석으로 설명 달아 놓음..

//건강관리 slice
export const fetchEatStatistics = createAsyncThunk(
  "management/fetchEatStatistics",
  async (params) => {
    const res = await api.post(`/admin/health/getEatStatistics`, params);
    return res.data;
  }
);

export const fetchEatRecord = createAsyncThunk(
  "management/fetchEatRecord",
  async (params) => {
    const res = await api.post(`/admin/health/getEatRecord`, params);
    return res.data;
  }
);

export const fetchRecommendMeal = createAsyncThunk(
  "management/fetchRecommendMeal",
  async (params) => {
    const res = await api.post(`/admin/health/getRecommendMeal`, params);
    return res.data;
  }
);

export const fetchETCStatistics = createAsyncThunk(
  "management/fetchETCStatics",
  async (params) => {
    const res = await api.post("/admin/health/getETCStatistics", params);
    return res.data;
  }
);
export const fetchETCDiaryRecord = createAsyncThunk(
  "management/fetchETCDiaryRecord",
  async (params) => {
    const res = await api.post("/admin/health/getETCDiaryRecord", params);
    return res.data;
  }
);

export const fetchWeight = createAsyncThunk(
  //건강관리 체중 통계 그래프
  "management/fetchWeight",
  async (params) => {
    const res = await api.post(`/admin/health/getWeight`, params);
    return res.data;
  }
);

export const fetchWeightRecord = createAsyncThunk(
  "management/fetchWeightRecord",
  async (params) => {
    const res = await api.post(`/admin/health/getWeightRecord`, params);
    return res.data;
  }
);

export const userInfoSimple = createAsyncThunk(
  "management/userInfoSimple",
  async (params) => {
    const res = await api.post("/admin/health/userInfoSimple", params);
    return res.data;
  }
);

export const fetchSymptomList = createAsyncThunk(
  "management/getSymptomList",
  async (params) => {
    const res = await api.post("admin/health/getSymptomList", params);
    return res.data;
  }
);

export const fetchSymptomInputDayList = createAsyncThunk(
  "management/getSymptomInputDayList",
  async (params) => {
    const res = await api.post("admin/health/getSymptomInputDayList", params);
    return res.data;
  }
);

export const fetchSymptomOneDay = createAsyncThunk(
  "management/getSymptomOneDay",
  async (params) => {
    const res = await api.post("/admin/health/getSymptomOneDay", params);
    return res.data;
  }
);

export const coachReportWeekList = createAsyncThunk(
  "management/reportWeekList",
  async (params) => {
    const res = await api.post("/user/coach/reportWeekList", params);
    return res.data;
  }
);

//계정관리 slice
export const searchManagementUsers = createAsyncThunk(
  "management/searchUsers",
  async (params) => {
    const res = await api.post("/admin/management/searchUser", params);
    return res.data;
  }
);
export const searchManagementAdmins = createAsyncThunk(
  "management/searchAdmin",
  async (params) => {
    const res = await api.post("/admin/search", params);
    return res.data;
  }
);

export const searchCoachReport = createAsyncThunk(
  "management/coachReport",
  async (params) => {
    const res = await api.post("/user/coach/report", params);
    return res.data;
  }
);

const initialState = {
  i: 0,
  status: null,
  startDate: null,
  endDate: null,
  averageKCal: null,
  averageProtein: null,
  needKCal: null,
  needProtein: null,
  eatKCalList: [],
  eatProteinList: [],
  dateList: [],
  eatDayLists: [],
  recommendMealList: [],
  weightInfo: [],
  weightLists: [],
  totalPage: 0,
  // ! totalSearchCount  기본값이 0이면 새로고침 후에 토스트팝업이 출력돼 기본값 1로 수정
  totalSearchCount: 1,
  searchUserList: [],
  searchCustomerCenter: [],
  searchAdminList: [],
  userSimple: [],
  data: [],
  onMember: [],
  postingList: [],
  etcStaticsWater: [],
  etcStaticsFeel: [],
  etcStaticsSmoke: [],
  etcStaticsAlcohol: [],
  diaryRecord: [],
  symptomDayList: [],
  symptomOutbreakList: [],
  symptomNotOutbreakList: [],
  symptomDaySelect: [],
  symptomList: [],
  daySelect: "",
  coachWeekList: [],
  coachWeeklyReport: [],
  selectCoachWeekStart: "",
  selectCoachWeekEnd: "",
  mangementDetailState: {},
};

const managementSlice = createSlice({
  name: "management",
  initialState,
  reducers: {
    onClickMember: (state, action) => {
      state.onMember = action.payload;
    },
    onClickDaySelect: (state, action) => {
      state.daySelect = action.payload;
    },
    onSelectCoachWeekStart: (state, action) => {
      state.selectCoachWeekStart = action.payload;
    },
    onSelectCoachWeekEnd: (state, action) => {
      state.selectCoachWeekEnd = action.payload;
    },
    onClickManagementDetailState: (state, action) => {
      state.mangementDetailState = action.payload;
    },
  },
  extraReducers: {
    [fetchEatStatistics.pending]: (state) => {
      state.status = "pending";
    },
    [fetchEatStatistics.fulfilled]: (state, action) => {
      state.status = "success";
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.averageKCal = action.payload.averageKCal;
      state.averageProtein = action.payload.averageProtein;
      state.needKCal = action.payload.needKCal;
      state.needProtein = action.payload.needProtein;
      state.eatKCalList = action.payload.eatKCalList;
      state.eatProteinList = action.payload.eatProteinList;
    },
    [fetchEatStatistics.rejected]: (state) => {
      state.status = "rejected";
    },
    [fetchEatRecord.fulfilled]: (state, action) => {
      state.status = "success";
      state.weekDatas = action.payload.weekData;
      state.eatDayLists = action.payload.eatDayList;
    },
    [fetchRecommendMeal.fulfilled]: (state, action) => {
      state.status = "success";
      state.dateList = action.payload.dateList;
      state.recommendMealList = action.payload.recommendMealList;
    },
    [fetchSymptomList.fulfilled]: (state, action) => {
      state.status = "success";
      state.symptomDayList = action.payload.symptomDayList;
      state.symptomOutbreakList = action.payload.symptomOutbreakList;
      state.symptomNotOutbreakList = action.payload.symptomNotOutbreakList;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    [fetchSymptomInputDayList.fulfilled]: (state, action) => {
      state.status = "success";
      state.symptomDaySelect = action.payload.dayList;
    },
    [fetchSymptomOneDay.fulfilled]: (state, action) => {
      state.status = "success";
      state.symptomList = action.payload.symptomList;
    },
    [fetchETCStatistics.fulfilled]: (state, action) => {
      state.status = "success";
      state.weekDatas = action.payload.weekData;
      state.etcStaticsWater = action.payload.waterList;
      state.etcStaticsFeel = action.payload.feelList;
      state.etcStaticsAlcohol = action.payload.alcoholList;
      state.etcStaticsSmoke = action.payload.smokeList;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    [fetchETCDiaryRecord.fulfilled]: (state, action) => {
      state.status = "success";
      state.totalPage = action.payload.totalPage;
      state.diaryRecord = action.payload.diaryList;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.totalSearchCount = action.payload.totalSearchCount;
    },
    [fetchWeight.pending]: (state) => {
      state.status = "pending";
    },
    [fetchWeight.fulfilled]: (state, action) => {
      state.status = "success";
      state.weightInfo = action.payload;
      state.weightLists = action.payload.weightList;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    [fetchWeight.rejected]: (state) => {
      state.status = "rejected";
    },
    [fetchWeightRecord.fulfilled]: (state, action) => {
      state.status = "success";
      state.weightLists = action.payload.weightList;
      state.totalPage = action.payload.totalPage;
      state.totalSearchCount = action.payload.totalSearchCount;
    },
    [searchManagementUsers.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.totalPage = action.payload.totalPage;
      state.totalSearchCount = action.payload.totalSearchCount;
      state.page = action.payload.page;
      state.searchUserList = action.payload.userList;
    },

    [searchManagementAdmins.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.totalPage = action.payload.totalPage;
      state.totalSearchCount = action.payload.totalSearchCount;
      state.page = action.payload.page;
      state.searchAdminList = action.payload.adminList;
    },

    [userInfoSimple.fulfilled]: (state, action) => {
      state.status = "success";
      state.userSimple = action.payload;
    },
    [coachReportWeekList.fulfilled]: (state, action) => {
      state.status = "success";
      state.coachWeekList = action.payload.weekList;
    },
    [searchCoachReport.fulfilled]: (state, action) => {
      state.status = "success";
      state.coachWeeklyReport = action.payload.weeklyReport;
    },
  },
});

export const {
  onClickMember,
  onClickDaySelect,
  onSelectCoachWeekStart,
  onSelectCoachWeekEnd,
  onClickManagementDetailState,
} = managementSlice.actions;

export default managementSlice.reducer;
