import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import { getNickname } from "../../../../features/mobile/questionHealthSlice";
import { checkNickname } from "../../../../features/mobile/otherSlice";
import {
  onAddUser,
  saveLoginInfo,
} from "../../../../features/mobile/commonSlice";
import Parser from "html-react-parser";
import HeadCont from "../../components/common/HeadCont";
import Btn from "../../components/common/Btn";
import InputBox from "../../components/common/InputBox";
import TitleBox from "../../components/common/TitleBox";
import CharacterInput from "../../components/sign/CharacterInput";
import SignUpSurvey from "../../components/survey/SignUpSurvey";

const SnsSignUp = () => {
  //sns 회원가입 전 콜백 - katalk은 url로 받아 보내기, naver: NaverSignUp
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type } = useParams();
  const path = useLocation().search;
  //https://www.healiary.com/sns/katalk?phoneNumber=010xxxxxxxx&name=xxx&snsId=xxxx
  const userId = path.substring(
    path.indexOf("?userId=") + 8,
    path.indexOf("&snsId")
  );
  const snsId = path.substring(path.indexOf("&snsId=") + 7);
  console.log(type, userId, snsId);
  const { dummyNickname } = useSelector((state) => state.questionHealth);
  const { loginInfo } = useSelector((state) => state.commonApp);
  const [value, setValue] = useState("");
  const [signupDatas, setSignupDatas] = useState({
    userId: userId,
    nickName: "",
    agree: [2, 3, 4],
    characterPhoto: "",
    accountType: type,
    firebaseToken: window.sessionStorage.getItem("token") || null,
    snsId: snsId,
    gaKey: "",
  });
  const [step, setStep] = useState(0); //0. Survey ,1. 닉네임, 2. 캐릭터 선택 3. 가입 오류시 처음으로 돌아가기 화면
  const [active, setActive] = useState("ready"); //ready, on
  const [errorMsg, setErrorMsg] = useState(null);
  const [surveyResponse, setSurveyResponse] = useState(null);

  const onSurveyInput = (value) => {
    setSurveyResponse({
      selectedNum: value.selectedNum,
      selectedAnswer: value.selectedAnswer,
      etc: value.etc,
    });
  };
  const onChangeInput = (e) => {
    setErrorMsg(null);
    setValue(e.target.value.slice(0, 8));
    setSignupDatas({ ...signupDatas, nickName: e.target.value.slice(0, 8) });
  };

  const onSetNextStep = (step) => {
    if (step === 0) {
      const gaKey =
        new Date().getTime() + Math.random().toString(36).substring(2, 11);
      setSignupDatas({
        ...signupDatas,
        surveyType: 1,
        selectNum: surveyResponse.selectedNum,
        selectAnswer: surveyResponse.selectedAnswer,
        etcAnswer: surveyResponse.etc,
        gaKey,
      });
      window.dataLayer.push({
        event: "signup_try",
        value: gaKey,
      });
      setStep(step + 1);
    } else if (step === 1) {
      if (signupDatas.nickName === "") {
        //닉네임 입력 안했을씨 더미 닉네임 저장
        setSignupDatas({ ...signupDatas, nickName: dummyNickname });
        setStep(step + 1);
      } else {
        dispatch(checkNickname(signupDatas.nickName)) //닉네임 중복 확인
          .unwrap()
          .then((res) => {
            if (res.ok) {
              setSignupDatas({
                ...signupDatas,
                nickName: signupDatas.nickName,
              });
              setStep(step + 1);
              setErrorMsg("");
              setActive("on");
            } else {
              setErrorMsg(res.error);
              setActive("ready");
            }
          })
          .catch((err) => console.log(err));
      }
    } else if (step === 2) {
      //회원가입
      // console.log(signupDatas);
      dispatch(onAddUser(signupDatas))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            dispatch(saveLoginInfo({ ...res, existPass: false }));
            navigate(`/registered`); //회원가입 완료 화면
          } else {
            //회원가입 불가시 처음으로 돌아가기 버튼
            toast(
              <p>{res.error}</p>,
              toastCommonProps("bottom-center", "toast_alert", 1000)
            );
            setStep(step + 1);
            setActive("on");
          }
        })
        .catch((err) => console.log(err));
    } else if (step === 3) {
      navigate(`/sign`);
    } else {
      setStep(step + 1);
      setActive("ready");
      setValue("");
      setErrorMsg("");
    }
  };

  const onSetPrevStep = () => setStep(step - 1);

  const onSaveEach = (type, one) => {
    setSignupDatas({ ...signupDatas, [type]: one });
    if (type === "characterPhoto") setActive("on");
  };

  useEffect(() => {
    const surveyLength = 8;
    if (step === 0) {
      if (surveyResponse && surveyResponse?.selectedNum !== surveyLength - 1)
        setActive("on");
      else if (
        surveyResponse?.selectedNum === surveyLength - 1 &&
        surveyResponse?.etc !== ""
      ) {
        setActive("on");
      } else {
        setActive("ready");
      }
      dispatch(getNickname({ maxLength: 12 }));
    } else if (step === 1) {
      setActive("on");
      if (value === "") {
        setSignupDatas({ ...signupDatas, nickName: "" });
      }
    } else if (step === 2) {
      if (!signupDatas.characterPhoto) {
        setActive("ready");
      } else setActive("on");
    }
  }, [dispatch, step, signupDatas.characterPhoto, surveyResponse, value]);

  return (
    <>
      <HeadCont
        rightIcon={"no icon"}
        onLeftAction={step !== 0 ? onSetPrevStep : () => navigate(-1)}
      />
      {step === 0 ? ( // 0 survey
        <SignUpSurvey
          surveyResponse={surveyResponse}
          onSurveyInput={onSurveyInput}
        />
      ) : step === 1 ? ( //1 닉네임
        <section className="mx-6 mt-[80px] flex flex-col gap-6">
          <TitleBox
            option={2}
            title={Parser("힐리어리에서 사용하실<br />별명을 입력해주세요")}
          />
          <InputBox
            option="common"
            placeholder={dummyNickname}
            comments={"1~8자 한글, 영어, 숫자 입력이 가능합니다."}
            onChangeInput={onChangeInput}
            setErrorMsg={setErrorMsg}
            value={value}
            setValue={setValue}
            active={active}
            errorMsg={value?.length > 0 ? errorMsg : null}
          />
        </section>
      ) : step === 2 ? ( //캐릭터
        <section className="mx-6 mt-[80px] flex flex-col gap-6">
          <TitleBox option={2} title={"나의 캐릭터를 선택해주세요"} />
          <CharacterInput
            onSaveEach={onSaveEach}
            character={signupDatas.characterPhoto}
          />
        </section>
      ) : (
        <section className="mx-6 mt-[80px] flex flex-col gap-6">
          <TitleBox option={2} title={"나의 캐릭터를 선택해주세요"} />
          <CharacterInput
            onSaveEach={onSaveEach}
            character={signupDatas.characterPhoto}
          />
        </section>
      )}
      <Btn
        option={"bottom"}
        bgColor={"bg-main"}
        textColor={"text-white"}
        borderColor={"border border-main"}
        active={active}
        onAction={() => onSetNextStep(step)}
        title={
          step === 0
            ? "다음"
            : step === 1
            ? "별명 저장"
            : step === 2
            ? "완료"
            : "처음으로 돌아가기"
        }
      />
    </>
  );
};

export default SnsSignUp;
