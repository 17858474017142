import React from "react";
import Slide from "./Slide";

const MobileSecondPart = () => {
  const contents = [
    {
      title: `나만의 식사 가이드`,
      contents: [
        `기록한 식단을 바탕으로 열량, 단백질 중심의`,
        `식사 분석과 신뢰도 높은 임상 영양사가 `,
        `환자의 상태에 따른 최적화된 식단을 추천해 드립니다.`,
      ],
    },
    {
      title: `나만의 건강지표 관리`,
      contents: [
        `하루동안 발생한 증상과 변화된 체중을 기록하여`,
        `나의 건강을 모니터링하고 변화된 상태에 따라 새로운`,
        `추천 식단을 제공해 드립니다.`,
      ],
    },
    {
      title: `나만의 스케줄 매니저`,
      contents: [
        `복용해야 하는 복약 스케줄과 진료 일정을 입력하면`,
        `알림을 통해 꼼꼼하게 관리해 드립니다.`,
      ],
    },
  ];

  return (
    <section className="flex flex-col items-center justify-center gap-12 px-[45px] py-12">
      <div className="flex flex-col gap-6 text-center">
        <h3 className="text-2xl font-extrabold">ABOUT HEALIARY</h3>
        <p className="text-center text-base font-medium">
          힐리어리는 암 환자분들의
          <br />
          막막한 치료 여정 속<span className="text-main"> ‘등불’</span>이
          되어주고자
          <br />
          탄생한 어플리케이션입니다.
        </p>
      </div>

      <div className="flex w-full flex-col gap-12">
        {contents.map((value, index) => {
          return (
            <div
              className="mx-auto flex h-full flex-col gap-3 "
              key={value.title}
            >
              <img
                src={`/images/mobile/landing/landing_main2-${index + 1}.png`}
                alt={value.title}
              />
              <span className="relative ml-2 w-fit before:absolute before:bottom-0 before:left-0 before:right-0 before:h-1/2 before:bg-[#417FFF24]">
                <span className="relative text-lg font-extrabold">
                  {value.title}
                </span>
              </span>
              <div className="ml-2 text-[13px] font-semibold leading-[20px] text-[#606365]">
                {value.contents.map((p) => (
                  <p key={p}>{p} </p>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default MobileSecondPart;
