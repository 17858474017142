import React from "react";
const ContentHeader = ({ title, commu }) => {
  return (
    <div className="flex items-center gap-1">
      {commu ? (
        <img src={`/images/admin/icon/소식.svg`} alt={`소식 아이콘`} />
      ) : (
        <img src={`/images/admin/icon/${title}.svg`} alt={`${title} 아이콘`} />
      )}
      <h2 className="text-body_1 font-semibold text-black">{title}</h2>
    </div>
  );
};

export default ContentHeader;
