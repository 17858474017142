import React, { useState } from "react";
import Btn from "../common/Btn";

const AgreementDialog = ({ openDialog, onSaveAgreement }) => {
  const [checkedItems, setCheckedItems] = useState([]);

  const datas = [
    { id: 1, title: "전체동의" },
    { id: 2, title: "[필수]이용약관동의" }, //필수
    { id: 3, title: "[필수]개인정보처리방침" }, //필수
    { id: 4, title: "[필수]개인민감정보(건강정보)처리방침" }, //필수
    { id: 5, title: "[선택]마케팅목적 개인정보 수집,이용동의" },
    { id: 6, title: "[선택]마케팅 정보 수신동의" },
  ];

  const onCheckAll = () => {
    if (checkedItems.includes(1)) {
      setCheckedItems([]);
    } else {
      const all = [];
      datas.forEach((one) => all.push(one.id));
      setCheckedItems([...all]);
    }
  };

  const onCheckOne = (one) => {
    if (checkedItems.includes(one)) {
      const arr = checkedItems.filter((el) => el !== one);
      setCheckedItems([...arr]);
    } else setCheckedItems([...checkedItems, one]);
  };

  return (
    <>
      <div
        className={
          "fixed left-0 z-20 h-[90vh] w-full flex-shrink-0 transform overflow-scroll rounded-t-[32px] bg-white px-6 py-[48px] text-center transition-all duration-300 " +
          (openDialog ? "bottom-0 translate-y-0" : "translate-y-full")
        }
      >
        <h1 className="mb-2 text-subtitle1">서비스 이용을 위한 동의 안내</h1>
        <p className="text-caption1_400 text-gray_180">
          서비스 이용에 꼭 필요한 사항입니다.
          <br />
          정책 및 약관을 클릭해 모든 내용을 확인해주세요.
        </p>
        <ul className="mb-4 mt-[40px]">
          {datas.slice(0, 1).map((data) => (
            <li
              key={data.id}
              onClick={onCheckAll}
              className="flex justify-between py-6 border-b border-gray_80"
            >
              <h2 className="text-black text text-body1_600">{data.title}</h2>
              <img
                src={
                  checkedItems.includes(1)
                    ? `/images/mobile/icon/checkbox_circle_active.svg`
                    : `/images/mobile/icon/checkbox_circle.svg`
                }
                alt="체크박스"
              />
            </li>
          ))}
          {datas.slice(1).map((data) => (
            <li
              key={data.id}
              onClick={() => onCheckOne(data.id)}
              className="flex justify-between py-6"
            >
              <h2 className="text-left text-black text-caption1_500">
                {data.title}
              </h2>
              <img
                src={
                  checkedItems.find((one) => one === data.id)
                    ? `/images/mobile/icon/checkbox_circle_active.svg`
                    : `/images/mobile/icon/checkbox_circle.svg`
                }
                alt="체크박스"
              />
            </li>
          ))}
        </ul>
      </div>
      <Btn
        option={openDialog ? "bottom" : ""}
        bgColor={"bg-[#417FFF]"}
        textColor={"text-white"}
        borderColor={"border border-[#417FFF"}
        onAction={() => onSaveAgreement(checkedItems)}
        title={"다음"}
        innerClass={openDialog ? "" : "fixed bottom-0 translate-y-full "}
        active={
          checkedItems.includes(2) &&
          checkedItems.includes(3) &&
          checkedItems.includes(4)
            ? "on"
            : "ready"
        }
      />
    </>
  );
};

export default AgreementDialog;
