import { useSelector } from "react-redux";
import { AdminHeadlist } from "../../components/common/TableHeadList";
import { ClientHeadlist } from "../../components/common/TableHeadList";
import dayjs from "dayjs";
const ManagerUserList = ({ onMemberDetail, filteredUsers }) => {
  const tabName = useSelector((state) => state.accountadmin.clickAccountTab);
  const cellStyle =
    "text-center py-4 text-caption_1 text-black  border-b border-gray_80";

  let Headlist;
  if (tabName === "회원") {
    Headlist = ClientHeadlist;
  } else {
    Headlist = AdminHeadlist;
  }

  const repeatTd = (data, idx, filteredUsers, tab) => {
    const idxCellStyle =
      "border-b border-l border-gray_80 p-4 text-black text-center " +
      (idx + 1 === filteredUsers?.length ? " rounded-bl-lg" : "");
    const lastIdxCellStyle =
      " border-r border-gray_80 p-4 text-center text-black border-b" +
      (idx + 1 === filteredUsers?.length ? " rounded-br-lg" : "");
    const operationDateWhen =
      data.operationDate === null || data.operationDate === ""
        ? "-"
        : data.operationDate === "OD997"
        ? "모름"
        : dayjs(data.operationDate).format("YYYY-MM-DD");
    const authorityWhen =
      data.authority === "00"
        ? "헤링스 슈퍼관리자"
        : data.authority === "01"
        ? "헤링스 일반관리자"
        : data.authority === "10"
        ? "병원 슈퍼관리자"
        : "병원 일반관리자";
    const userDatas = [
      { cssStyle: idxCellStyle, content: data.no },
      { cssStyle: cellStyle, content: data.name === null ? "-" : data.name },
      {
        cssStyle: cellStyle,
        content: data.nickName,
        content2: data.nickNameProtector
          ? `(보: ${data.nickNameProtector})`
          : "",
      },
      { cssStyle: cellStyle, content: data.age },
      { cssStyle: cellStyle, content: data.gender },
      { cssStyle: cellStyle, content: data.cancerType },
      { cssStyle: cellStyle, content: data.cancerStaging },
      { cssStyle: cellStyle, content: data.operationYN },
      { cssStyle: cellStyle, content: operationDateWhen },
      { cssStyle: cellStyle, content: data.anticancerTreatmentType },
      {
        cssStyle: cellStyle + " z-1 underline",
        content: data.isExistRecommendMeal ? "보기" : "",
      },
      // { cssStyle: cellStyle, content: data.userType },
      {
        cssStyle: lastIdxCellStyle,
        content: dayjs(data.createdAt).format("YYYY-MM-DD"),
      },
    ];
    const managerDatas = [
      { cssStyle: idxCellStyle, content: data.no },
      { cssStyle: cellStyle, content: data.name === null ? "-" : data.name },
      { cssStyle: cellStyle, content: data.adminId },
      { cssStyle: cellStyle, content: data.gender === "M" ? "남" : "여" },
      // { cssStyle: cellStyle, content: data !== null ? data.hospitalName : "" },
      { cssStyle: cellStyle, content: authorityWhen },
      {
        cssStyle: cellStyle,
        content: dayjs(data.createdAt).format("YYYY-MM-DD"),
      },
      {
        cssStyle: lastIdxCellStyle,
        content:
          data.approvalDate !== null &&
          dayjs(data.approvalDate).format("YYYY-MM-DD"),
      },
    ];
    const allDatas = tab === "회원" ? userDatas : managerDatas;
    return (
      <>
        {allDatas?.map((el, idx) => (
          <td className={el.cssStyle} key={idx}>
            <p>{el.content}</p>
            <p>{el.content2}</p>
          </td>
        ))}
      </>
    );
  };

  return (
    <table
      className="col-span-3 w-full table-auto border-separate 3xl:col-span-2"
      cellSpacing={0}
    >
      <thead>
        <tr className="bg-gray_90">
          {Headlist?.map((head, idx) => (
            <th
              key={idx}
              className={
                "border-y border-gray_80 bg-gray_60 p-4 font-semibold text-black first:rounded-tl-lg first:border-l last:rounded-tr-lg last:border-r " +
                (tabName === "회원"
                  ? idx + 1 === Headlist?.length
                    ? " w-[200px] "
                    : ""
                  : idx === 0
                  ? " w-[80px] "
                  : "")
              } //회원 가입일 th만 크기 조정
            >
              {head}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {filteredUsers &&
          filteredUsers?.map((data, idx) => {
            return (
              <tr
                onClick={(e) => onMemberDetail(data, e)}
                key={idx}
                className={
                  "hover:bg-primary_10 cursor-pointer  px-6 py-3 " +
                  (idx % 2 === 1 ? "bg-gray_95" : "bg-gray_10")
                }
              >
                {repeatTd(data, idx, filteredUsers, tabName)}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default ManagerUserList;
