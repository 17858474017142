
import React, { useState } from 'react'
import ContentsGuideListLayout from './ContentsGuideListLayout'
import ContentsModal from './ContentsModal'
import RadioBtn from './RadioBtn'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFullGuide, editFullGuide, getFullGuide, saveFullGuide } from '../../../../../features/admin/contentsSlice'
import { toast } from 'react-toastify'
import toastCommonProps from '../../../../../common/toast'
import RadioBtnDisabled from './RadioBtnDisabled'
import { BGButtonCustom, BorderButtonCustom } from '../../common/ButtonCustom'
import { ThreeConceptReport, TwoConceptReport } from '../ModalReportTop'

const FullGuideModalIn = ({ newModal, editModal, tabName, setNewModal, setEditModal }) => {
  const allGuideEach = useSelector((state)=>state.contents.eachGuideList)
  const {loginInfo} = useSelector((state)=>state.adminLogin)
  const fullGuideEach = useSelector((state) => state.contents.eachGuideList)
  const dispatch = useDispatch()
  const [editInner, setEditInner] = useState(false)
  const [fullGuide, setFullGuide] = useState({
    kcalIntake: allGuideEach.kcalIntake,
    proteinIntake: allGuideEach.proteinIntake,
    //files 추후 추가하게되면 넣을것=> files:null,
    content: allGuideEach.content,
    addAdmin: loginInfo?.adminId
  })

  const onChangeFULL = (e) => {
    const { name, value } = e.target;
    if(name === "content"){
      setFullGuide({...fullGuide, [name]:value})
    }else{
      const clickOption = e.target.parentNode.children[1].children[1].innerText;
      setFullGuide({ ...fullGuide, [name]: clickOption })
    }
  }

  const submitFULLGuide = async () => {
    let param = {
      kcalIntake: fullGuide.kcalIntake,
      proteinIntake: fullGuide.proteinIntake,
      content: fullGuide.content,
      addAdmin: loginInfo?.adminId
    }
    const res = await dispatch(saveFullGuide(param)).unwrap()
    if (res.data.ok) {
      toast(<p>저장 완료되었습니다</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
      setTimeout(() => setNewModal(false), 1000)
      dispatch(getFullGuide());
    } else {
      console.log(res);
    }
  }
  const onClickDelete = async (paramid) => {
    const res = await dispatch(deleteFullGuide(paramid)).unwrap()
    if (res.data.ok) {
      toast(<p>해당 종합 가이드가 삭제 되었습니다.</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
      dispatch(getFullGuide());
      setTimeout(() => { setEditModal(false); }, 1000)
    } else {
      console.log(res, "종합 가이드 삭제 실패ㅜ");
    }
  }
  const onClickEdit = async () => {
    let paramEdit = {
      //가이드 수정클릭시 fullGuide에 id값 추가
      id: fullGuide.id,
      kcalIntake: fullGuide.kcalIntake,
      proteinIntake: fullGuide.proteinIntake,
      content: fullGuide.content,
      editAdmin: loginInfo?.adminId
    }
  
    const res = await dispatch(editFullGuide(paramEdit)).unwrap()
    if (res.data.ok) {
      toast(<p>수정 완료되었습니다</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
      setTimeout(() => setEditModal(false), 1000)
      dispatch(getFullGuide());
    } else {
      console.log(res)
    }
  }
  const movePage = () => {
    setEditInner(true);
    setFullGuide({
      id: fullGuideEach.id,
      kcalIntake: fullGuideEach.kcalIntake,
      proteinIntake: fullGuideEach.proteinIntake,
      content: fullGuideEach.content,
      addAdmin: fullGuideEach.editAdmin !== null ? fullGuideEach.editAdmin.adminId : fullGuideEach.addAdmin.adminId
    })
  }

  return (
    <>
      {/* 새가이드등록 */}
      {newModal &&
        <ContentsModal
          tabName={tabName} 
          setEditModal={setEditModal}
          setNewModal={setNewModal}
          subTab={"새 가이드 등록"}>
          <TwoConceptReport user={loginInfo}/>
          <ContentsGuideListLayout tabName={tabName} title={"열량 섭취량"}>
            <RadioBtn htmlfor="short" commonname="kcalIntake" korean="부족" onChangeFunc={onChangeFULL} thekey={fullGuide.kcalIntake}/>
            <RadioBtn htmlfor="normal" commonname="kcalIntake" korean="양호" onChangeFunc={onChangeFULL} thekey={fullGuide.kcalIntake}/>
            <RadioBtn htmlfor="extra" commonname="kcalIntake" korean="과다" onChangeFunc={onChangeFULL} thekey={fullGuide.kcalIntake}/>
          </ContentsGuideListLayout>
          <ContentsGuideListLayout tabName={tabName} title={"단백질 섭취량"}>
            <RadioBtn htmlfor="shorta" commonname="proteinIntake" korean="부족" onChangeFunc={onChangeFULL} thekey={fullGuide.proteinIntake}/>
            <RadioBtn htmlfor="normala" commonname="proteinIntake" korean="양호" onChangeFunc={onChangeFULL} thekey={fullGuide.proteinIntake}/>
            <RadioBtn htmlfor="extraa" commonname="proteinIntake" korean="과다" onChangeFunc={onChangeFULL} thekey={fullGuide.proteinIntake}/>
          </ContentsGuideListLayout>
          <ContentsGuideListLayout tabName={tabName} title={"내용"}>
            <textarea placeholder='내용을 입력하세요(최대 500자)'
              value={fullGuide.content}
              name="content"
              className='w-full h-[300px] border border-gray_100 rounded px-4 py-2 outline-none bg-transparent'
              onChange={onChangeFULL}
            />
          </ContentsGuideListLayout>
          <div className='w-full flex justify-end gap-x-4 pt-4'>
              <BorderButtonCustom
              onClick={()=>{setNewModal(false)}}
              content={"작성취소"}
              bgcolor={""}
              textcolor={"text-primary_100"}
              bordercolor={"border-primary_100"}
              textsize={"text-body_2"}
              xpadding={"12px"}
            />
            <BGButtonCustom
              onClick={submitFULLGuide}
              content={"저장"}
              bgcolor={"bg-primary_100"}
              textcolor={"text-gray_10"}
              textsize={"text-body_2"}
              xpadding={"12px"}
            />
          </div>
        </ContentsModal>
      }
      {/* 수정가이드등록 */}
      {
        editModal && (
        //editInner 가 true면 가이드 수정 component
        editInner ?
          <ContentsModal
            tabName={tabName}
            setEditModal={setEditModal}
            setNewModal={setNewModal}
            subTab={"가이드 수정"}>
            <TwoConceptReport user={loginInfo}/>
            <ContentsGuideListLayout tabName={tabName} title={"열량 섭취량"}>
              <RadioBtn htmlfor="short" commonname="kcalIntake" korean="부족" onChangeFunc={onChangeFULL} thekey={fullGuide.kcalIntake}/>
              <RadioBtn htmlfor="normal" commonname="kcalIntake" korean="양호" onChangeFunc={onChangeFULL} thekey={fullGuide.kcalIntake}/>
              <RadioBtn htmlfor="extra" commonname="kcalIntake" korean="과다" onChangeFunc={onChangeFULL} thekey={fullGuide.kcalIntake}/>
            </ContentsGuideListLayout>
            <ContentsGuideListLayout tabName={tabName} title={"단백질 섭취량"}>
              <RadioBtn htmlfor="shorta" commonname="proteinIntake" korean="부족" onChangeFunc={onChangeFULL} thekey={fullGuide.proteinIntake}/>
              <RadioBtn htmlfor="normala" commonname="proteinIntake" korean="양호" onChangeFunc={onChangeFULL} thekey={fullGuide.proteinIntake}/>
              <RadioBtn htmlfor="extraa" commonname="proteinIntake" korean="과다" onChangeFunc={onChangeFULL} thekey={fullGuide.proteinIntake}/>
            </ContentsGuideListLayout>
            <ContentsGuideListLayout tabName={tabName} title={"내용"}>
              <textarea placeholder='내용을 입력하세요(최대 500자)'
                value={fullGuide.content}
                name="content"
                className='w-full h-[300px] border border-[#A39F9B] rounded px-4 py-2 outline-none bg-transparent'
                onChange={onChangeFULL}
              />
            </ContentsGuideListLayout>
            <div className='w-full flex justify-end gap-x-4 pt-4'>
              <BorderButtonCustom
                onClick={() => setEditInner(false)}
                content={"수정 취소"}
                bgcolor={""}
                textcolor={"text-primary_100"}
                bordercolor={"border-primary_100"}
                textsize={"text-body_2"}
                xpadding={"12px"}
              />
              <BGButtonCustom
                onClick={onClickEdit}
                content={"수정 완료 및 저장"}
                bgcolor={"bg-primary_100"}
                textcolor={"text-gray_10"}
                textsize={"text-body_2"}
                xpadding={"12px"}
              />
            </div>
          </ContentsModal>
          :
          //editInner 가 false이면 가이드 수정전 component
          <ContentsModal
            tabName={tabName}
            setEditModal={setEditModal}
            setNewModal={setNewModal}
            >
            <ThreeConceptReport whatGuideEach={fullGuideEach}/>
            <div>
              <ContentsGuideListLayout tabName={tabName} title={"열량 섭취량"}>
                <RadioBtnDisabled engtit={"kcalIntake"} label={"부족"} />
                <RadioBtnDisabled engtit={"kcalIntake"} label={"양호"} />
                <RadioBtnDisabled engtit={"kcalIntake"} label={"과다"} />
              </ContentsGuideListLayout>
              <ContentsGuideListLayout tabName={tabName} title={"단백질 섭취량"}>
                <RadioBtnDisabled engtit={"proteinIntake"} label={"부족"} />
                <RadioBtnDisabled engtit={"proteinIntake"} label={"양호"} />
                <RadioBtnDisabled engtit={"proteinIntake"} label={"과다"} />
              </ContentsGuideListLayout>
              <ContentsGuideListLayout tabName={tabName} title={"내용"}>
                <div
                  className=' bg-[#F3F3F2] w-full h-[300px] border border-gray_90 rounded px-4 py-2'
                >
                  {fullGuideEach.content}
                </div>
              </ContentsGuideListLayout>
            </div>
            <div className='w-full flex justify-end gap-x-4 pt-4'>
              <BorderButtonCustom
                onClick={() => onClickDelete(fullGuideEach.id)}
                content={"가이드 삭제"}
                bgcolor={""}
                textcolor={"text-primary_100"}
                bordercolor={"border-primary_100"}
                textsize={"text-body_2"}
                width={100}
              />
              <BorderButtonCustom
                onClick={movePage}
                content={"가이드 수정"}
                bgcolor={""}
                textcolor={"text-primary_100"}
                bordercolor={"border-primary_100"}
                textsize={"text-body_2"}
                xpadding={"12px"}
              />
              <BGButtonCustom
                onClick={() => setEditModal(false)}
                content={"확인"}
                bgcolor={"bg-primary_100"}
                textcolor={"text-gray_10"}
                textsize={"text-body_2"}
                xpadding={"12px"}
              />
            </div>
          </ContentsModal>
        )
      }
    </>

  )
}

export default FullGuideModalIn