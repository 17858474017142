import React from "react";
import { useNavigate } from "react-router-dom";
import Btn from "../common/Btn";

const NoData = ({ imgUrl, title, title2 }) => {
  const navigate = useNavigate();

  return (
    <div className="-mt-[132px] flex h-screen flex-col items-center justify-center">
      <div className="text-center">
        <img src={imgUrl} alt="비어있는 이미지" className="mx-auto mb-[42px]" />
        <p className="mb-[48px] text-body3_600 text-gray_160">
          {title} 아직 없네요!
          <br />
          추천식단에서 {title2} 확인해보세요👏
        </p>
        <div className="-mx-4">
          <Btn
            bgColor={"bg-main"}
            textColor={"text-white font-semibold"}
            borderColor={"border border-main"}
            title={"추천식단 바로가기"}
            active={"on"}
            onAction={() => {
              window.scrollTo(0, 0);
              navigate(`/recommend`);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NoData;
