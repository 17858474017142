import dayjs from 'dayjs';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Hospital } from '../../../components/common/TableHeadList'

const HospitalList = ({filteredUsers}) => {
  const { page } = useSelector((state) => state.common);
  const {pagePerList} = useSelector((state)=>state.pagination)
  const navigate = useNavigate();
  return (
    <table
    className="w-full table-auto 3xl:col-span-2 col-span-3 border-separate "
    cellSpacing={0}
  >
  <thead>
    <tr className="bg-gray_90">
      {Hospital?.map((head, idx) => (
        <th
          key={idx}
          className=
          {"border-y border-gray_80 p-4 font-semibold text-black bg-gray_60 first:border-l last:border-r first:rounded-tl-lg last:rounded-tr-lg "  +(idx===0? " w-[80px] ":" w-1/2")}>
          {head}
        </th>
      ))}
    </tr>
  </thead>
  <tbody>
    {filteredUsers !==undefined &&filteredUsers?.map((data, idx) => {
        return (
          <tr
            key={idx}
            className={
              "px-6 py-3  cursor-pointer hover:bg-primary_10 " +
              (idx % 2 === 1 ? "bg-gray_95" : "bg-gray_10")
            }
           onClick={()=>navigate("/admin/hospitalid",{
            state:data
           })}
          >
            <td
                className={
                "border-b border-l border-gray_80 p-4 text-black text-center " +
                (idx + 1 === filteredUsers?.length ? " rounded-bl-lg" : "")
                }
                >
                {(page-1)*pagePerList + idx+1}
            </td>
            <td className="text-center py-4 text-caption_1 text-black w-24 border-b border-gray_80">
              {data.name === null ? "" : data.name}
            </td>
            <td className={
                " border-r border-gray_80 p-4 text-center text-black border-b" +
                (idx + 1 === filteredUsers?.length ? " rounded-br-lg" : "")
                }
              >
              {dayjs(data.createdAt).format("YYYY-MM-DD")}
            </td>
          </tr>
        );
      })
    }
  </tbody>
</table>
  )
}

export default HospitalList