import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  customerCenterDelteQ,
  getCustomerCenterQuestionOne,
} from "../../../../../features/mobile/settingSlice";
import HeadCont from "../../../components/common/HeadCont";
import toastCommonProps from "../../../../../common/toast";
import Dialog from "../../../components/common/Dialog";

const UsageQA = () => {
  const hambugIcon = useMemo(
    () => ({
      background: "url('/images/mobile/icon/hamburger.svg') no-repeat center",
    }),
    []
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const [modal, setModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [question, setQuestion] = useState(false);
  const { customerCenterId } = useSelector((state) => state.setting);
  const [customerCenterQList, setCustomerCenterQList] = useState([]);

  const onClickQuestionTrue = () => {
    setQuestion(!question);
  };

  const onClickDelete = async (thisId) => {
    const res = await dispatch(customerCenterDelteQ({ id: thisId })).unwrap();
    try {
      if (res.data.ok) {
        navigate(-1);
        toast(
          <p>삭제되었습니다.</p>,
          toastCommonProps("bottom-center", "toast_alert", 1000)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onClickEdit = () => {
    navigate("/contact/usageId");
  };
  const allModalFalse = () => {
    setModal(false);
    setConfirm(false);
  };

  const showMyQandA = (list) => {
    return (
      <div className="px-6 py-8 break-words whitespace-pre-line">
        <p>{list?.content}</p>
        <ul className="flex flex-col gap-y-4">
          {list?.files?.map((el, idx) => (
            <li key={idx}>
              <img src={el} alt="customerCenterImage" />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const showMyQandAHead = (list, param) => {
    return (
      <div className="flex flex-col gap-y-[18px] px-6 pt-6">
        <p className="text-body2_700">{list?.title}</p>
        <div className="flex justify-between pb-8 border-b">
          <span className="text-caption1_400 text-gray_140">
            {dayjs(list?.createdAt).format("YYYY-MM-DD HH:mm")}
          </span>
          {param === "questionHead" && (
            <span>
              {list?.aWaiting ? (
                <span className="rounded bg-green_100 p-1 text-caption2_700 text-[#fefefe]">
                  답변완료
                </span>
              ) : (
                <span className="p-1 border rounded border-gray_120 text-caption2_700 text-gray_120">
                  답변대기
                </span>
              )}
            </span>
          )}
        </div>
      </div>
    );
  };

  const printCustomerCenterQOne = useCallback(async () => {
    const param = {
      userId: loginInfo.userId,
      id: customerCenterId,
    };
    const res = await dispatch(getCustomerCenterQuestionOne(param)).unwrap();
    try {
      if (res.data.ok) {
        setCustomerCenterQList(res.data.question);
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, customerCenterId, loginInfo.userId]);

  useEffect(() => {
    printCustomerCenterQOne();
  }, [printCustomerCenterQOne]);

  return (
    <>
      <div className="h-screen overflow-y-auto bg-white">
        <div className="h-full pb-24 mx-auto maxW pt-14">
          <HeadCont
            rightIcon={hambugIcon}
            transparent={"white"}
            underline={true}
            title={"문의 내용"}
            onRightAction={() => setModal(!modal)}
          />
          {showMyQandAHead(customerCenterQList, "questionHead")}
          {customerCenterQList?.answer !== null ? (
            <>
              {question && showMyQandA(customerCenterQList)}
              <div
                className="flex items-center justify-center gap-x-[19.75px] border-b py-6 text-btn_700 text-gray_160"
                onClick={onClickQuestionTrue}
              >
                <span>내 질문 보기</span>
                <img
                  src="/images/mobile/icon/arrow_down2.svg"
                  className={
                    "h-6 w-6 transition duration-150 ease-linear " +
                    (question ? "rotate-180" : "rotate-0")
                  }
                  alt="하단 arrow 아이콘"
                />
              </div>
              {showMyQandAHead(customerCenterQList?.answer)}
              {showMyQandA(customerCenterQList?.answer)}
            </>
          ) : (
            showMyQandA(customerCenterQList)
          )}
        </div>
      </div>

      {modal && (
        <>
          <div
            className="fixed top-0 left-0 z-20 w-full h-screen bg-black bg-opacity-70"
            onClick={allModalFalse}
          ></div>
          <ul className="fixed z-30 right-6 top-6 ">
            <li
              className="flex gap-x-3 rounded-t-[8px] border-b border-gray_120 bg-white p-4 "
              onClick={() => onClickEdit(customerCenterQList?.id)}
            >
              <span className="font-semibold">수정하기</span>
              <img src="/images/mobile/icon/pencil.svg" alt="pencil" />
            </li>
            <li
              className="flex gap-x-3 rounded-b-[8px] bg-white p-4 "
              onClick={() => setConfirm(true)}
            >
              <span className="font-semibold">삭제하기</span>
              <img src="/images/mobile/icon/trashcan.svg" alt="trashcan" />
            </li>
          </ul>
          {confirm && (
            // <div className="transform-50 fixed left-1/2 top-1/2 z-40 w-[300px] rounded-md bg-white px-6 py-4 shadow-[0_0_12px_rgba(0,0,0,0.2)]">
            //   <h1 className="text-black text-subtitle2">
            //     정말 삭제하시겠습니까?
            //   </h1>
            //   <div className="flex justify-end pt-8">
            //     <button
            //       onClick={() => onClickDelete(customerCenterQList?.id)}
            //       className="text-btn_700 text-correct"
            //     >
            //       확인
            //     </button>
            //   </div>
            // </div>
            <Dialog
              innerClass={"p-4"}
              title={"삭제하기"}
              desc={"선택하신 문의내용을 삭제할까요?"}
              leftBtnTitle={"네, 삭제할게요"}
              rightBtnTitle={"아니요"}
              onLeftBtnAction={() => onClickDelete(customerCenterQList?.id)}
              onRightBtnAction={() => setConfirm(false)}
              onCoverAction={() => setConfirm(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default UsageQA;
