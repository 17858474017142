import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  searchForRecentFood,
  searchForFood,
} from "../../../../features/mobile/mealSlice";
import SearchDialog from "./SearchDialog";
import { ReactComponent as Search } from "../../../../svg/search.svg";

const FoodInputBox = ({ onSaveAddorEdit }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const boxRef = useRef(null);
  const keywordRef = useRef(null);
  const [value, setValue] = useState(null);
  const [boxStatus, setBoxStatus] = useState("search"); //search 최근 입력한 음식, 검색한 음식, addQty 음식량 입력 후 완료
  const { loginInfo } = useSelector((state) => state.commonApp);
  const [noFoodOpen, setNoFoodOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchedFoodLists, setSearchedFoodLists] = useState([]); //비동기문제로 내부에서 데이터 담아줌
  console.log(searchedFoodLists, "searchedFoodLists");

  const onSearchForFood = () => {
    let params = { searchText: value, userId: loginInfo.userId };
    dispatch(searchForFood(params))
      .unwrap()
      .then((res) => {
        if (res.data.ok) {
          if (res.data.searchText === keywordRef.current) {
            // 이 코드 중요. 이 전에 부른 키워드의 데이터 값이 늦게 들어올 경우, 제외 시킴
            if (res.data?.foodList?.length > 0) {
              setSearchedFoodLists(res.data.foodList);
              setNoFoodOpen(false);
            } else setNoFoodOpen(true);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const onChangeKeyEnter = (e) => {
    const key = e.code;
    const keyCode = e.keyCode;
    if (key === "Enter" || keyCode === 13) {
      onSearchForFood();
    }
  };

  const onFocusAction = () => {
    setOpenDialog(true);
    if (boxStatus === "addQty") setBoxStatus("search");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    inputRef.current.focus();
    setOpenDialog(true); //다이얼로그 바로 오픈
    dispatch(searchForRecentFood({ userId: loginInfo.userId }));
  }, [dispatch, loginInfo.userId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const debounce = setTimeout(() => {
      if (value?.length > 0 && value !== setValue) {
        setOpenDialog(true);
        onSearchForFood();
      }
    }, 200);
    keywordRef.current = value;
    return () => clearTimeout(debounce);
    // eslint-disable-next-line
  }, [dispatch, value]);

  return (
    <>
      <div className="flex items-center rounded-xl border border-gray_60 bg-white pb-[18px] pl-3 pt-4">
        <input
          id="foodSearch"
          name="foodSearch"
          ref={inputRef}
          type={"text"}
          onFocus={onFocusAction}
          placeholder={"드신 음식을 입력해주세요."}
          onChange={(e) => {
            boxRef?.current?.scrollIntoView({
              inline: "center",
              behavior: "instant",
            });
            setValue(e.target.value);
          }}
          value={value || ""}
          onKeyDown={onChangeKeyEnter}
          className={
            "h-[64] w-full appearance-none text-body3_500 focus:outline-none focus:ring-0 " +
            (value && boxStatus === "addQty" ? "text-gray_160" : "text-black")
          }
        />
        <label
          onClick={value ? onSearchForFood : null}
          htmlFor="foodSearch"
          className="pr-4"
        >
          <Search className="w-6 h-6" fill={value ? "#0D0D0D" : "#dedede"} />
        </label>
      </div>
      <div className="pt-5 text-center text-gray_160">
        <p className="text-caption2_700">
          찾으시는 음식이 없다면,
          <br />
          가장 비슷한 음식을 선택해주세요.
          <br />
          <span className="text-caption2_400 ">
            더 나은 서비스를 위해 계속 업데이트 중입니다.
          </span>
        </p>
      </div>
      <SearchDialog
        boxRef={boxRef}
        boxStatus={boxStatus}
        setBoxStatus={setBoxStatus}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        keyword={value}
        setValue={setValue}
        onSaveAddorEdit={onSaveAddorEdit}
        noFoodOpen={noFoodOpen}
        setNoFoodOpen={setNoFoodOpen}
        searchedFoodLists={searchedFoodLists}
      />
    </>
  );
};

export default FoodInputBox;
