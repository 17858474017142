import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { onSetSection } from "../../../../features/admin/commonSlice";
import { getAllAdmin } from "../../../../features/admin/adminSlice";
import CustomCenterModal from "./CustomCenterModal";
import { onSetAccountOneInfo } from "../../../../features/admin/accountSlice";

const AccountDialog = ({ user, onSetAccountDialog }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const goMyAccount = () => {
    if (path === "/admin/account/myaccount") {
      onSetAccountDialog();
    } else {
      dispatch(onSetSection("계정 관리"));
      navigate("/admin/account/myaccount");
      dispatch(onSetAccountOneInfo(user));
    }
  };
  const goSignin = () => {
    window.localStorage.removeItem("admin");
    navigate("/admin/signin");
  };

  useEffect(() => {
    dispatch(getAllAdmin());
  }, [dispatch]);

  return (
    <CustomCenterModal
      customlayout={"absolute top-16 right-6 w-[320px] rounded-md bg-gray_95 "}
    >
      <div className="z-10 flex-col gap-4 rounded-md bg-gray_10 p-4 shadow-[0_2px_2px_rgba(0,0,0,0.12)] ">
        <div className="flex justify-between">
          <span>현재 로그인 계정</span>
          <img
            src="/images/admin/icon/close.svg"
            alt="닫기 아이콘"
            className="absolute top-0 right-0 p-1"
            onClick={onSetAccountDialog}
          />
        </div>
        <div
          className="flex cursor-pointer gap-2 pt-[16px]"
          onClick={goMyAccount}
        >
          <span className="flex h-[40px] w-[40px] items-center justify-center rounded-full font-bold">
            {user.photo === null ? (
              <span className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-gray-100 font-bold">
                {user.name.slice(0, 1)}
              </span>
            ) : (
              <img
                className="h-[40px] w-[40px] rounded-full"
                src={user.photo}
                alt="userphoto"
              />
            )}
          </span>
          <div className="flex flex-col">
            <h3 className="font-semibold text-black text-caption_1">
              {user.name}
            </h3>
            <h4 className="text-caption2_400 text-gray_100">
              헤링스 수퍼관리자
            </h4>
          </div>
        </div>
      </div>
      <ul className="flex flex-col gap-4 p-4 rounded-md ">
        <li
          className="flex justify-between cursor-pointer"
          onClick={goMyAccount}
        >
          <div className="flex justify-between gap-2">
            <img
              src="/images/admin/icon/my_info.svg"
              alt="내 정보관리 아이콘"
            />
            <h3 className="font-semibold text-black text-body_2">
              내 정보 관리
            </h3>
          </div>
          <img
            src="/images/admin/icon/arrow_next.svg"
            alt="아래 화살표 아이콘"
          />
        </li>
        <li className="flex justify-between cursor-pointer" onClick={goSignin}>
          <div className="flex justify-between gap-2">
            <img src="/images/admin/icon/logout.svg" alt="로그아웃 아이콘" />
            <h3 className="font-semibold text-black text-body_2">로그아웃</h3>
          </div>
        </li>
      </ul>
    </CustomCenterModal>
  );
};

export default AccountDialog;
