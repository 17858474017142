import React, { useEffect, useState } from "react";
import HeadCont from "../../components/common/HeadCont";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchInfoPostingDetails } from "../../../../features/mobile/infoSlice";
import CommentCardHolder from "../../components/common/comments/CommentCardHolder";


const InfoComments = () => {
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    // Redux State Variables
    const { loginInfo } = useSelector((state) => state.commonApp)
    const { postingDetails, totalReplies } = useSelector((state) => state.info)
    
    // Local State Variables
    const [status, setStatus] = useState('read') // Status indicating which window is currently open
    const [postComment, setPostComment] = useState(null) // variable for comment text area

    
    // static variables
    // const shareIcon = useMemo(() => ({ background: "url('/images/mobile/icon/share.svg') no-repeat center",}),[])
    const postId = useParams()
    
    // Bringing in the post detail
    const bringPostingDetails = () =>{
        let params = { userId: loginInfo.userId, postingId: postId.id }
        dispatch(fetchInfoPostingDetails(params))
    }

    useEffect(() => {
        if (!loginInfo?.userId) navigate(`/sign`);
        else {
            window.scrollTo(0, 0)
            setTimeout(bringPostingDetails(), 3000)
        }
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <HeadCont
                title={
                     '댓글(' + (postingDetails ? postingDetails.posting?.replyCount : 0 ) + ')'
                }
                rightIcon={null}
                transparent={'white'}
                underline={true}
                onRightAction={null}
                onLeftAction={() => {
                    if(status === 'read')
                        navigate(-1)
                    else {
                        setPostComment('')
                        setStatus('read')
                    }
                }}
            />
            <section className="mt-14 ">
                <CommentCardHolder 
                    commonParams = {{
                        postId: postId.id, 
                        postComment, 
                        setPostComment, 
                        status, 
                        setStatus,
                        totalReplies, 
                        bringPostingDetails
                    }}
                    loginInfo={loginInfo}
                />
            </section>      
        </>
    );
}

export default InfoComments;