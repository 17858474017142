import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../src/App.css";
import "../src/Admin.css";
import Layout from "../src/common/Layout";

//app

import Home from "./screens/mobile/pages/Home";
import NotFound from "./screens/mobile/pages/NotFound";
import MobileSignUp from "./screens/mobile/pages/sign/MobileSignUp";
import SnsSignUp from "./screens/mobile/pages/sign/SnsSignUp";
import SignUpFail from "./screens/mobile/pages/sign/SignUpFail";
import HospitalInfo from "./screens/mobile/pages/hospitalinfo/infoHospital";
import HospitalList from "./screens/mobile/pages/hospitalinfo/listHospital";
import Navbar from "./screens/mobile/pages/Navbar";
import Setting from "./screens/mobile/pages/Setting";
import HealthInfo from "./screens/mobile/pages/healthInfo/HealthInfo";
import QuestionHealth from "./screens/mobile/pages/question/QuestionHealth";
import QuestionFood from "./screens/mobile/pages/question/QuestionFood";
import Result from "./screens/mobile/pages/question/Result";
import Alram from "./screens/mobile/pages/setting/Alarm";
import Account from "./screens/mobile/pages/setting/Account";
import Register from "./screens/mobile/pages/sign/Register";
import Recommend from "./screens/mobile/pages/recommend/Recommend";
import Guide from "./screens/mobile/pages/recommend/Guide";
import Recipe from "./screens/mobile/pages/recommend/Recipe";
import RecommendForShare from "./screens/mobile/pages/recommend/RecommendForShare";
import RecipeForShare from "./screens/mobile/pages/recommend/RecipeForShare";
import MyPage from "./screens/mobile/pages/mypage/MyPage";
import MyPosts from "./screens/mobile/pages/mypage/MyPosts";
import MyComments from "./screens/mobile/pages/mypage/MyComments";
import Like from "./screens/mobile/pages/mypage/Like";
import Dislike from "./screens/mobile/pages/mypage/Dislike";
import BookmarkMeal from "./screens/mobile/pages/mypage/BookmarkMeal";
import BookmarkRecipe from "./screens/mobile/pages/mypage/BookmarkRecipe";
import BookmarkedMealDetail from "./screens/mobile/pages/mypage/BookmarkedMealDetail";
import Record from "./screens/mobile/pages/meal/Record";
import Analysis from "./screens/mobile/pages/meal/Analysis";
import Report from "./screens/mobile/pages/meal/Report";
import RequestToAddFood from "./screens/mobile/pages/meal/RequestToAddFood";
import HospitalResister from "./screens/mobile/pages/setting/HospitalResister";
import Policy from "./screens/mobile/pages/setting/Policy";
import Source from "./screens/mobile/pages/setting/Source";
import Version from "./screens/mobile/pages/setting/Version";
import Profile from "./screens/mobile/pages/setting/account/Profile";
import Push from "./screens/mobile/pages/setting/Push";
import EditPass from "./screens/mobile/pages/setting/account/EditPass";
import Contact from "./screens/mobile/pages/setting/Contact";
import Resign from "./screens/mobile/pages/setting/account/Resign";
import Information from "./screens/mobile/pages/information/Information";
import InfoDetail from "./screens/mobile/pages/information/InfoDetail";
import InfoDetailForShare from "./screens/mobile/pages/information/InfoDetailForShare";
import InfoComments from "./screens/mobile/pages/information/InfoComments";
import CancerGpt from "./screens/mobile/pages/cancerGpt/CancerGpt";
import SymptomRecord from "./screens/mobile/pages/symptomRecord/SymptomRecord";
import DrugAlarm from "./screens/mobile/pages/DrugAlarm";
import Weight from "./screens/mobile/pages/Weight";
import Treatment from "./screens/mobile/pages/Treatment";
import Step from "./screens/mobile/pages/Step";
import Login from "./screens/mobile/pages/sign/Login";
import SignUp from "./screens/mobile/components/sign/SignUp";
import Bookmark from "./screens/mobile/pages/mypage/Bookmark";
import InfoDetailWithComment from "./screens/mobile/pages/information/InfoDetailWitComment";
import FindRecipe from "./screens/mobile/pages/recommend/FindRecipe";
import Event from "./screens/mobile/pages/Event";
import MyBlockUsers from "./screens/mobile/pages/mypage/MyBlockUsers";
import Dialog from "./screens/mobile/components/common/Dialog";

//admin
import Admin from "./screens/admin/pages/Admin";
import AdminSignin from "./screens/admin/pages/AdminSignIn";
import AdminSignUp from "./screens/admin/pages/AdminSignUp";
import AdminSighUp2 from "./screens/admin/pages/AdminSighUp2";
import AdminFindId from "./screens/admin/pages/FindId";
import AdminFindAdminPwd from "./screens/admin/pages/FindAdminPwd";
import AdminManagement from "./screens/admin/pages/Management";
import AdminContents from "./screens/admin/pages/Contents";
import AdminBoard from "./screens/admin/pages/Board/Board";
import AdminAccount from "./screens/admin/pages/Account";
import AdminManagementDetail from "./screens/admin/pages/ManagementDetail";
import AdminEditAdmin from "./screens/admin/pages/Account/EditAdmin";
import AdminMemberList from "./screens/admin/pages/Account/MemberList";
import AdminBoardId from "./screens/admin/pages/Board/BoardId";
import AdminCenterDetail from "./screens/admin/pages/Board/CenterDetail";
import AdminInformationDetail from "./screens/admin/pages/Board/InformationDetail";
import AdminHospital from "./screens/admin/pages/Hospital";
import AdminHospitalAdd from "./screens/admin/pages/Hospital/HospitalAdd";
import AdminHospitalId from "./screens/admin/pages/Hospital/HospitalId";
import AdminHospitalEdit from "./screens/admin/pages/Hospital/HospitalEdit";
import AdminAccountPrev from "./screens/admin/pages/Account/AccountPrev";
import AdminAccountApprov from "./screens/admin/pages/Account/AccountApprov";
import AdminMyAccount from "./screens/admin/pages/Account/MyAccount";
import UsageId from "./screens/mobile/pages/setting/contact/UsageId";
import Notice from "./screens/mobile/pages/setting/Notice";
import QuestionDisease from "./screens/mobile/pages/question/QuestionDisease";
import UsageQA from "./screens/mobile/pages/setting/contact/UsageQA";
import FindPass from "./screens/mobile/pages/sign/FindPass";
import QuestionFoodAdd from "./screens/mobile/pages/question/QuestionFoodAdd";
import Funnels from "./screens/admin/pages/Funnels";

//landingpage
import Landing from "./screens/landingpage/Landing";
import MoveAppStore from "./screens/landingpage/MoveAppStore";
import MoveAppStoreForInsta from "./screens/landingpage/MoveAppStoreForInsta";
import DrugDiary from "./screens/landingpage/DrugDiary";

//태그 매니저
import TagManager from "react-gtm-module";

import TextEditor from "./screens/admin/pages/TextEditor";
import InfoSearch from "./screens/mobile/pages/information/InfoSearch";
import TestPage from "./screens/admin/pages/Board/TestPage";
import ExternalLink from "./screens/mobile/pages/community/ExternalLink";

function App() {
  // const { loginInfo } = useSelector((state) => state.commonApp);
  // let firebaseToken = window.sessionStorage.getItem("token");
  const [updateAlarm, setUpdateAlarm] = useState(false);

  const tagManagerArgs = {
    //태그 매니저
    gtmId: "GTM-N3XGGSS",
    // dataLayer: {
    //   event: "app",
    //   //user_id: firebaseToken || loginInfo.snsId || null,
    //   method: "login_method",
    //   page: "app",
    // },
    // dataLayerName: "dataLayer",
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    TagManager.initialize({
      gtmId: "GTM-MHZ2ZXDV",
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const v3UpdateCheck = async () => {
      if (window?.flutter_inappwebview) {
        let appVersion = await window.flutter_inappwebview?.callHandler(
          "getIosVersion"
        );
        if (Number(appVersion.split(".")[2]) < 19) {
          setUpdateAlarm(true);
        }
      }
    };

    v3UpdateCheck();
  }, []);

  return (
    <BrowserRouter>
      {updateAlarm && (
        <Dialog
          innerClass={"p-4"}
          title={"앱 업데이트"}
          desc={"최신버전으로 업데이트가 필요합니다."}
          rightBtnTitle={"업데이트 하기"}
          onRightBtnAction={MoveAppStore}
        />
      )}
      <ToastContainer />
      <Layout>
        <Routes>
          {/* app */}
          {/* 회원가입 로그인 */}
          <Route path="/sign" element={<SignUp />} />
          <Route path="/signup" element={<SignUp />} />

          <Route path="/mobile" element={<MobileSignUp />} />
          <Route path="/sns/:type" element={<SnsSignUp />} />
          <Route path="/fail/:type" element={<SignUpFail />} />
          {/* 자동로그인 또는 sns로그인 성공시 */}
          <Route path="/login" element={<Login />} />

          <Route path="/registered" element={<Register />} />
          <Route path="/findpass" element={<FindPass />} />
          {/* 홈, 메뉴등 기본 */}
          <Route path="/home" element={<Home />} />
          <Route path="/nav" element={<Navbar />} />
          {/* 설문 */}
          <Route path="/question/health" element={<QuestionHealth />} />
          <Route path="/question/food" element={<QuestionFood />} />
          <Route path="/question/disease" element={<QuestionDisease />} />
          <Route path="/question/foodadd" element={<QuestionFoodAdd />} />
          <Route path="/result" element={<Result />} />
          {/* 계정 */}
          <Route path="/setting" element={<Setting />} />
          <Route path="/account" element={<Account />} />
          <Route path="/account/profile" element={<Profile />} />
          <Route path="/hospital/register" element={<HospitalResister />} />
          <Route path="/account/editpass" element={<EditPass />} />
          <Route path="/alarm" element={<Alram />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/source" element={<Source />} />
          <Route path="/version" element={<Version />} />
          <Route path="/push" element={<Push />} />
          <Route path="/resign" element={<Resign />} />
          {/* 병원 정보 찾기 */}
          <Route path="hospitalinfo" element={<HospitalInfo />} />
          <Route path="hospitalinfo/list" element={<HospitalList />} />
          {/* 내 건강 정보 */}
          <Route path="/healthinfo" element={<HealthInfo />} />
          {/* 추천식단 */}
          <Route path="/recommend" element={<Recommend />} />
          <Route
            path="/recommend/share/:type"
            element={<RecommendForShare />}
          />
          <Route path="/recommend/guide" element={<Guide />} />
          <Route path="/recommend/recipe/:type" element={<Recipe />} />
          <Route
            path="/recommend/recipe/share/:type"
            element={<RecipeForShare />}
          />
          <Route path="/recommend/findrecipe" element={<FindRecipe />} />
          {/* 정보 */}
          <Route path="/info" element={<Information />} />
          <Route path="/info/:id" element={<InfoDetail />} />
          <Route path="/info/share/:id" element={<InfoDetailForShare />} />
          <Route path="/info/comment/:id" element={<InfoComments />} />
          <Route path="/info/search" element={<InfoSearch />} />
          {/* Cancer GPT */}
          <Route path="/cancergpt" element={<CancerGpt />} />
          {/* 마이페이지 */}
          <Route path="/mypage" element={<MyPage />} />
          <Route path="/mypage/posts" element={<MyPosts />} />
          <Route path="/mypage/bookmark" element={<Bookmark />} />
          <Route path="/mypage/comments" element={<MyComments />} />
          <Route path="/mypage/blockUsers" element={<MyBlockUsers />} />
          <Route
            path="/mypage/infocomment/:id"
            element={<InfoDetailWithComment />}
          />
          <Route path="/bookmark/recipe" element={<BookmarkRecipe />} />
          <Route path="/like" element={<Like />} />
          <Route path="/dislike" element={<Dislike />} />
          <Route
            path="/bookmark/meal/:type"
            element={<BookmarkedMealDetail />}
          />
          {/*식사기록*/}
          <Route path="/meal/record" element={<Record />} />
          <Route path="/meal/analysis/:type" element={<Analysis />} />
          <Route path="/meal/report" element={<Report />} />
          <Route path="/meal/request" element={<RequestToAddFood />} />
          {/* 증상기록 */}
          <Route path="/symptom/record" element={<SymptomRecord />} />
          {/* 체중기록 */}
          <Route path="/weight/record" element={<Weight />} />
          {/* 진료알림 */}
          <Route path="/treatment" element={<Treatment />} />
          {/* 복약알림 */}
          <Route path="/drugalarm" element={<DrugAlarm />} />
          {/* 걸음수 상세 */}
          <Route path="/step" element={<Step />} />

          {/* 기타 */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/usageid" element={<UsageId />} />
          <Route path="/contact/usageqa" element={<UsageQA />} />
          <Route path="/notice" element={<Notice />} />

          {/* admin */}
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/management" element={<AdminManagement />} />
          <Route
            path="/admin/management/detail/:type"
            element={<AdminManagementDetail />}
          />
          <Route path="/admin/contents" element={<AdminContents />} />
          <Route path="/admin/board" element={<AdminBoard />} />
          <Route path="/admin/boardid" element={<AdminBoardId />} />
          <Route
            path="/admin/customercenter/detail"
            element={<AdminCenterDetail />}
          />
          <Route
            path="/admin/information/detail"
            element={<AdminInformationDetail />}
          />
          <Route path="/admin/account" element={<AdminAccount />} />
          <Route
            path="/admin/account/prevapprov"
            element={<AdminAccountPrev />}
          />
          <Route
            path="/admin/account/approv"
            element={<AdminAccountApprov />}
          />
          <Route path="/admin/account/editadmin" element={<AdminEditAdmin />} />
          <Route path="/admin/account/myaccount" element={<AdminMyAccount />} />
          <Route
            path="/admin/account/memberlist"
            element={<AdminMemberList />}
          />
          <Route path="/admin/signin" element={<AdminSignin />} />
          <Route path="/admin/signup" element={<AdminSignUp />} />
          <Route path="/admin/signup2" element={<AdminSighUp2 />} />
          <Route path="/admin/findid" element={<AdminFindId />} />
          <Route path="/admin/findadminpwd" element={<AdminFindAdminPwd />} />
          <Route path="/admin/hospital" element={<AdminHospital />} />
          <Route path="/admin/hospital/add" element={<AdminHospitalAdd />} />
          <Route path="/admin/hospitalid" element={<AdminHospitalId />} />
          <Route path="/admin/hospital/edit" element={<AdminHospitalEdit />} />
          <Route path="/admin/funnels" element={<Funnels />} />
          {/* for test */}
          <Route path="/admin/text" element={<TextEditor />} />
          <Route path="/admin/test" element={<TestPage />} />
          {/* 랜딩페이지 */}
          <Route path="/landing/:type" element={<Landing />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/appdownload" element={<MoveAppStore />} />
          <Route path="/appdownloadisad" element={<MoveAppStoreForInsta />} />
          {/* 복약일기 큐알 구글플레이/앱스토어 이동 페이지 */}
          <Route path="/drugdiary" element={<DrugDiary />} />
          {/* 이벤트 페이지 */}
          {new Date() >= new Date("2023-09-11 00:00:00") &&
          new Date() < new Date("2023-10-16 00:00:00") ? (
            <Route path="event" element={<Event />} />
          ) : null}
          {/* 공통 */}
          <Route path="/" element={<Landing />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* </Suspense> */}
      </Layout>
    </BrowserRouter>
  );
}

export default App;
