import React, { useState, useEffect, useRef } from "react";

/**
 * Component for truncating text to desired lines
 *
 * @param {Object} params
 * @param {string} params.text Main content
 * @param {number} params.maxLines The max lines in which the text must fit (default = 2)
 * @param {string} params.style The string style (default = "text-body3_700 break-all")
 *
 *
 * Dispatched:
 * - onSaveCommunityPage
 */
export default function TruncateText({
  text,
  maxLines = 2,
  style = " text-body3_700 break-all",
  onClick,
}) {
  const containerRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [truncatedText, setTruncatedText] = useState(text);

  useEffect(() => {
    const container = containerRef.current;
    const containerHeight = container.offsetHeight;
    const lineHeight = parseInt(getComputedStyle(container).lineHeight);
    if (containerHeight > lineHeight * maxLines) {
      setIsTruncated(true);
      truncateTextToFit(container, lineHeight * maxLines);
    }
    // eslint-disable-next-line
  }, [text]);

  const truncateTextToFit = (container, maxHeight) => {
    const words = text.split(" ");
    let truncatedContent = "";
    for (let i = 0; i < words.length; i++) {
      const tempContent = truncatedContent + words[i] + " ";
      container.textContent = tempContent + "...";
      if (container.offsetHeight > maxHeight) {
        setTruncatedText(truncatedContent.trim() + "...");
        return;
      }

      truncatedContent = tempContent;
    }

    setTruncatedText(text);
  };
  return (
    <div className={style} ref={containerRef} onClick={onClick}>
      {isTruncated ? truncatedText : text}
    </div>
  );
}
