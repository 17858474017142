import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  removeLoginInfo,
  removeCurrentInfo,
} from "../../../../../features/mobile/commonSlice";
import { onResetQuestionComplete } from "../../../../../features/mobile/otherSlice";
import api from "../../../../../common/api";
// import Dialog from "../../../components/common/Dialog";
import HeadCont from "../../../components/common/HeadCont";
import Btn from "../../../components/common/Btn";
import OutSurvey from "../../../components/survey/OutSurvey";

const Resign = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const [openDialog, setOpenDialog] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [surveyResponse, setSurveyResponse] = useState(null); // 탈퇴 설문조사

  const unCheckedBox = useMemo(
    () => ({
      background:
        "url('/images/mobile/icon/checkbox_circle.svg') no-repeat center",
    }),
    []
  );
  const checkedBox = useMemo(
    () => ({
      background:
        "url('/images/mobile/icon/checkbox_circle_active.svg') no-repeat center",
    }),
    []
  );

  const onSurveyInput = (value) => {
    setSurveyResponse({
      selectedNum: value.selectedNum,
      selectedAnswer: value.selectedAnswer,
      etc: value.etc,
    });

    // 회원탈퇴 설문 조사 테스트
  };

  const onCheck = ({ target }) => setIsChecked(target.checked);

  const onResign = () => {
    let params = {
      userId: loginInfo.userId,
      withdrawalReason: surveyResponse.selectedAnswer,
      withdrawalEtcReason: surveyResponse.etc,
    };
    //회원탈퇴
    api
      .post(`user/quit`, params)
      .then((res) => {
        if (res.data.ok === true) {
          navigate("/sign");
          dispatch(removeLoginInfo());
          dispatch(removeCurrentInfo());
          dispatch(onResetQuestionComplete());
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <HeadCont title="탈퇴 신청" />
      <section className="px-4 pt-8 pb-20 mb-20 mt-14">
        {/* {openDialog && (
          <OutSurvey
            surveyResponse={surveyResponse}
            onSurveyInput={onSurveyInput}
            onClickEvent={onResign}
          />
        )} */}
        {openDialog ? (
          <OutSurvey
            openDialog={openDialog}
            surveyResponse={surveyResponse}
            onSurveyInput={onSurveyInput}
            title={"힐리어리를 떠나시기 전에 왜 탈퇴하시는지 알려주세요"}
            subTitle={
              "사용자의 소중한 의견이 저희 서비스 개선에 큰 도움이 됩니다.(다중선택 가능)"
            }
            // desc={"정말 탈퇴 하시겠습니까?"}
            leftBtnTitle={"취소"}
            rightBtnTitle={"제출하고 탈퇴하기"}
            onLeftBtnAction={() => setOpenDialog(false)}
            onRightBtnAction={onResign}
            onCoverAction={() => setOpenDialog(false)}
          />
        ) : (
          <>
            <ul className="keepAll text-caption2_400 text-gray_120">
              <li>
                <div className="mb-2 text-caption2_700 text-gray_160">
                  내 정보 및 서비스 이용 기록 삭제 안내
                </div>
                <div>
                  내 정보 및 서비스 이용기록이 모두 삭제되며, 삭제된 데이터는
                  복구 되지 않습니다.
                </div>
              </li>
              <li className="pt-8">
                <div className="mb-2 text-caption2_700 text-gray_160">
                  게시글 삭제 불가 안내
                </div>
                <ul>
                  <li>
                    - 삭제를 원하시는 게시글이 있다면 반드시 삭제하시기
                    바랍니다.
                  </li>
                  <li>
                    - 탈퇴 후에는 회원정보가 삭제되어 본인 여부를 확인할 수 있는
                    방법이 없어 게시글을 임의로 삭제해 드릴 수 없습니다.
                  </li>
                </ul>
              </li>
              <li className="pt-8">
                <div className="mb-2 text-caption2_700 text-gray_160">
                  기기 이용기록 보관 안내
                </div>
                <div>
                  휴대폰 번호, 이메일, 이름 등 개인 식별이 가능한 정보를 제외한
                  나머지 기기 사용 데이터(질병, 식습관, 식사기록, 체중, 성별,
                  생년월일, 증상)는 건강관리 및 의료용 서비스 연구를 위해
                  암호화되어 보관됩니다.
                </div>
              </li>
              <li className="flex flex-col pt-8">
                <span>• 탈퇴 후에는 아이디의 데이터는 복구할 수 없습니다.</span>
                <span>• 게시글은 탈퇴 후 삭제할 수 없습니다.</span>
              </li>
            </ul>
            <div className="fixed bottom-[80px] left-0 flex w-full items-center justify-center gap-x-[8px] bg-gray_95 p-4">
              <input
                id="resignAgree"
                type="checkbox"
                onChange={(e) => onCheck(e)}
                checked={isChecked}
                style={isChecked ? checkedBox : unCheckedBox}
                className={
                  "mr-3 h-5 w-5 appearance-none rounded-full focus:border-gray_60 " +
                  (isChecked ? "text-gray_100" : "border border-gray_60")
                }
              />
              <label
                htmlFor="resignAgree"
                className="text-caption2_400 text-gray_160"
              >
                네, 위 내용을 모두 확인했습니다.
              </label>
            </div>
            <Btn
              option={"bottom"}
              bgColor={"bg-main"}
              textColor={"text-white fon-semibold"}
              borderColor={"border border-main"}
              title={"탈퇴 신청"}
              active={isChecked ? "on" : "ready"}
              onAction={() => setOpenDialog(true)}
            />
          </>
        )}
        {/* {openDialog && (
          <Dialog
            innerClass={"p-4"}
            title={"탈퇴"}
            desc={"정말 탈퇴 하시겠습니까?"}
            leftBtnTitle={"아니요"}
            rightBtnTitle={"네"}
            onLeftBtnAction={() => setOpenDialog(false)}
            onRightBtnAction={onResign}
            onCoverAction={() => setOpenDialog(false)}
          />
        )} */}
      </section>
    </>
  );
};

export default Resign;
