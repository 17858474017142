import React, { useState, useEffect } from "react";
import { pointFn } from "../logic";
import EditBtn from "../../components/common/EditBtn";
import PlusMinusBtn from "./PlusMinusBtn";
import Btn from "../../components/common/Btn";
import GuideForFood from "./GuideForFood";

const DetailDialog = ({
  openDetail,
  onCloseDetail,
  data,
  onSaveAddorEdit,
  onDelete,
}) => {
  const cpf = [
    {
      name: "탄",
      keyName: "carbohydratesG",
      unit: "g",
      bgColor: "bg-[#FFB800]",
    },
    { name: "단", keyName: "proteinG", unit: "g", bgColor: "bg-[#82C6F6]" },
    { name: "지", keyName: "fatG", unit: "g", bgColor: "bg-[#9E87FB]" },
  ];
  const nutritons = [
    { name: "식이섬유", keyName: "totalDietaryfiberG", unit: "g" },
    { name: "당류", keyName: "totalsugarG", unit: "g" },
    { name: "포화지방", keyName: "totalSaturatedfattyacidG", unit: "g" },
    { name: "나트륨", keyName: "sodiumMG", unit: "mg" },
    { name: "콜레스테롤", keyName: "cholesterolMG", unit: "mg" },
    { name: "트랜스지방", keyName: "transfattyacidG", unit: "g" },
  ];

  const [quantity, setQuantity] = useState(data.eyemesurementnumber);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnDisabled2, setBtnDisabled2] = useState(false);
  const [openGuide, setOpenGuide] = useState(false);
  const [i, setI] = useState(0); //배수시 2, 3, 4 ...

  const cpfFn = (el) => {
    return (
      <>
        <span className={"block h-2 w-2 rounded-full " + el.bgColor}></span>
        <span>
          {el.name} {data[el.keyName] !== null ? data[el.keyName] : "-"}
          {el.unit}
        </span>
      </>
    );
  };

  const onDeleteFn = () => {
    onDelete(data.foodId);
    onCloseDetail();
    // const timeout = setTimeout(() => setOpenEditBtn(false), 600);
    // return () => clearTimeout(timeout);
  };

  const onIncreaseQty = (qty) => {
    setBtnDisabled(false);
    if (qty === 1 || qty === 0.25) {
      if (quantity + Number(qty) > 500) {
        setBtnDisabled2(true);
      } else {
        setBtnDisabled2(false);
        setQuantity(quantity + Number(qty));
      }
    } else {
      if (i <= 100) {
        if (data.eyemesurementnumber * (2 + i) > 500) {
          setBtnDisabled2(true);
        } else {
          setBtnDisabled2(false);
          setQuantity(data.eyemesurementnumber * (2 + i));
          setI(i + 1);
        }
      } else if (i === 0) {
        setBtnDisabled2(false);
        setQuantity(data.eyemesurementnumber);
        setI(i + 1);
      } else {
        setBtnDisabled2(true);
      }
    }
  };

  const onDecreaseQty = (qty) => {
    setBtnDisabled2(false);
    if (qty === 1 || qty === 0.25) {
      if (quantity - Number(qty) <= 0 || quantity - Number(qty) < 0.1) {
        setBtnDisabled(true);
      } else {
        setBtnDisabled(false);
        setQuantity(quantity - Number(qty));
      }
    } else {
      if (quantity / Number(qty) <= 0 || quantity / Number(qty) < 0.1) {
        setBtnDisabled(true);
      } else {
        if (i > 0) {
          setBtnDisabled(false);
          setQuantity(data.eyemesurementnumber * i);
          setI(i - 1);
        } else {
          setBtnDisabled(false);
          setQuantity(quantity / Number(qty));
        }
      }
    }
  };

  useEffect(() => {
    if (data.eyemesurementnumber) setQuantity(data.eyemesurementnumber);
  }, [data.eyemesurementnumber]);

  //eyemesurementnumber_org 음식의 원본 1회 제공 눈대중량(0.8대접), eyemesurementnumber 사용자가 먹은양, servingsize 1회제공량(200g), servingsize_org 음식 원본 1회 제공량,
  //결과값 최대 500, 최소 0.1
  //정수일때
  //3 이상 일때 +- 1, 3 미만 일때 +-0.25
  //소수점 일때
  //x % 0.25===0 일때 +-0.25
  //x % 0.25!==0 일때 * / 배수 2,3,4,5...
  //0.1 이하 부터 비활성화

  return (
    <>
      <div
        onClick={onCloseDetail}
        className={
          openDetail
            ? "fixed left-0 top-0 z-10 h-full w-full bg-black opacity-70"
            : "hidden"
        }
      ></div>

      <div
        className={
          "maxW fixed left-0 right-0 z-20 mx-auto h-[470px] rounded-t-[32px] bg-white duration-700 ease-in-out " +
          (openDetail ? "bottom-0" : "bottom-[-470px]")
        }
      >
        <div className="flex flex-col items-center rounded-t-[32px] bg-back_ground p-6 pt-7">
          <div className="flex items-center justify-between w-full mb-6">
            <span className="text-subtitle2">{data.foodName}</span>

            <img
              onClick={() => {
                onCloseDetail();
                setQuantity(data.eyemesurementnumber);
              }}
              src="/images/mobile/icon/close.svg"
              alt="닫기"
            />
          </div>

          <div className="space-y-2">
            <div className="flex justify-center gap-2">
              <PlusMinusBtn
                num={quantity}
                unit={data.eyemesurementunit}
                chosenFood={data}
                onIncreaseQty={onIncreaseQty}
                onDecreaseQty={onDecreaseQty}
                btnDisabled={btnDisabled}
                btnDisabled2={btnDisabled2}
              />
              <img
                onClick={() => setOpenGuide(true)}
                src="/images/mobile/icon/info_circle.svg"
                alt="정보 아이콘"
              />
            </div>
            <ul className="flex justify-center gap-2 text-caption2_700">
              <li className="text-gray_160">
                총{" "}
                {quantity
                  ? pointFn(
                      (
                        (quantity * data.servingsize_org) /
                        data.eyemesurementnumber_org
                      ).toFixed(0)
                    )
                  : pointFn(data.servingsize)}
                g
              </li>
              <span className="h-[28px] w-[1px] bg-gray_40"></span>
              <li className="text-orange_180">
                열량{" "}
                {quantity
                  ? pointFn(
                      (
                        (quantity * data.kcal_org) /
                        data.eyemesurementnumber_org
                      ).toFixed(0)
                    )
                  : pointFn(data.kcal)}
                kcal
              </li>
              <span className="h-[28px] w-[1px] bg-gray_40"></span>
              <li className="text-green_160">
                단백질{" "}
                {quantity
                  ? pointFn(
                      (
                        (quantity * data.proteinG_org) /
                        data.eyemesurementnumber_org
                      ).toFixed(0)
                    )
                  : pointFn(data.proteinG)}
                g
              </li>
            </ul>
          </div>
        </div>

        <div className="w-full px-6 pt-5 text-gray_160">
          <div className="flex justify-between">
            <h1 className="text-caption1_700">영양 정보</h1>
            <div className="flex justify-between pb-5">
              <ul className="flex gap-x-4">
                {cpf.map((el, idx) => (
                  <li className="flex items-center gap-x-1" key={idx}>
                    {cpfFn(el)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <ul
            className={
              "grid grid-flow-col grid-rows-3 bg-gray_15 px-2 py-3 text-caption2_400"
            }
          >
            {nutritons.map((el, idx) => (
              <li key={idx} className="text-caption2_400 text-gray_160">
                <span>· {el.name}:</span>
                <span>
                  {" "}
                  {data?.[el.keyName] !== null ? data?.[el.keyName] : "-"}
                  {el.unit}
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex gap-[10px] px-6 py-[10px]">
          <Btn
            innerClass={"w-2/5"}
            bgColor={"bg-white"}
            textColor={"text-main font-semibold"}
            borderColor={"border border-main"}
            title={"삭제하기"}
            active={"on"}
            onAction={onDeleteFn}
          />
          <Btn
            innerClass={"w-3/5"}
            bgColor={"bg-main"}
            textColor={"text-white font-semibold"}
            borderColor={"border border-main"}
            title={"적용하기"}
            active={"on"}
            onAction={() => {
              onSaveAddorEdit({
                eyemesurementnumber: quantity,
                foodId: data.foodId,
              });
              onCloseDetail();
            }}
          />
        </div>
      </div>

      <GuideForFood openGuide={openGuide} setOpenGuide={setOpenGuide} />
    </>
  );
};

export default DetailDialog;
