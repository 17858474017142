import React from "react";

const NotEatCard = () => {
  return (
    <div className="flex items-center justify-between rounded-xl bg-[#E2ECFF] py-2 pl-3 pr-2">
      <h1 className="text-black text-body3_700">안먹음</h1>
      <ul className="flex items-center gap-4">
        <li className="text-caption1_600 text-gray_160">-</li>
        <li className="p-3 text-white rounded-lg bg-main text-caption2_700">
          0 kcal
        </li>
      </ul>
    </div>
  );
};

export default NotEatCard;
