import React from "react";
import { useSelector } from "react-redux";
import TableHeader from "../common/TableHeader";
import WeightGraph from "../graph/WeightGraph";
import WeightAreaGraph from "../graph/WeightAreaGraph";
// import WeightGraph22 from "../graph/WeightGraph22";

const WeightGraphBox = ({ dayNo }) => {
  const { weightInfo, weightLists } = useSelector((state) => state.management);
  const headList = ["체중 정보", "체중", "BMI"];

  const tBody1 = [
    {
      trCss: "px-6 py-3 hover:bg-primary_10",
      trtxts: [
        "초기",
        weightInfo?.firstWeight?.toFixed(1),
        weightInfo?.firstBmi?.toFixed(1),
      ],
    },
    {
      trCss: "px-6 py-3 bg-gray_95 hover:bg-primary_10",
      trtxts: [
        "최종(현재)",
        weightInfo?.currentWeight?.toFixed(1),
        weightInfo?.currentBmi?.toFixed(1),
      ],
    },
    {
      trCss: "px-6 py-3 hover:bg-primary_10",
      trtxts: [
        "평균",
        weightInfo?.avgWeight === null
          ? "-"
          : weightInfo?.avgWeight?.toFixed(1),
        weightInfo?.avgBmi === null ? "-" : weightInfo?.avgBmi?.toFixed(1),
      ],
    },
  ];

  const tBody2 = [
    {
      trCss: "px-6 py-3 hover:bg-primary_10 ",
      trtxts: ["체중 변화", weightInfo.changeWeight || "-"],
    },
    {
      trCss: "px-6 py-3 hover:bg-primary_10",
      trtxts: ["변화율", weightInfo?.changeWeightRate || "-"],
    },
  ];
  return (
    <>
      <div className="grid grid-cols-2 gap-4 mt-4 ">
        <div className="h-full space-y-4">
          <TableHeader title={"체중 정보"} />
          <table cellSpacing={0} className="w-full border-separate table-auto">
            <thead>
              <tr className="bg-gray_90">
                {headList?.map((head, idx) => (
                  <th
                    key={idx}
                    className="w-1/3 py-4 text-black border border-l border-y border-gray_60 last:border-r text-caption_1 bg-gray_60 first:rounded-tl-lg last:rounded-tr-lg"
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tBody1?.map((el, idx) => (
                <tr className={el.trCss} key={idx}>
                  {el.trtxts?.map((tdel, indx) => (
                    <td
                      key={indx}
                      className={
                        "border border-b border-gray_60 text-center py-4 text-caption_1 text-black" +
                        (el.trtxts?.length === indx + 1
                          ? " border-x " +
                            (idx + 1 === tBody1?.length
                              ? " rounded-br-lg "
                              : "")
                          : indx === 0 && idx + 1 === tBody1?.length
                          ? " border-l rounded-bl-lg "
                          : " border-l ")
                      }
                    >
                      {tdel}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <table cellSpacing={0} className="w-full border-separate table-auto">
            <tbody>
              {tBody2?.map((el, idx) => (
                <tr className={el.trCss} key={idx}>
                  {el.trtxts?.map((tdel, indx) => (
                    <td
                      key={indx}
                      className={
                        "border border-gray_60 text-center py-4 text-caption_1 text-black" +
                        (indx === 0
                          ? " bg-tertiary_10 w-1/3 border-r-0 " +
                            (idx === 0
                              ? " rounded-tl-md "
                              : " rounded-bl-md border-t-0 ")
                          : indx + 1 === el.trtxts?.length &&
                            idx + 1 === tBody2?.length
                          ? " rounded-br-md border-t-0 "
                          : " rounded-tr-md ")
                      }
                    >
                      {tdel}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="space-y-4 ">
          <TableHeader title={"체중(kg)"} />
          {dayNo === "최근 90일" ? (
            <WeightAreaGraph
              graphOption={dayNo}
              measureUnit={"kg"}
              weightLists={weightLists}
            />
          ) : (
            <WeightGraph
              graphOption={dayNo}
              measureUnit={"kg"}
              weightLists={weightLists}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WeightGraphBox;
