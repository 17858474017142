import React from 'react';
import { pointFn } from '../../components/logic';

const CalorieProteinCard = ({ actual, recommended, option, result }) => {

    return (
        <div>
            <div className='rounded-xl bg-white border border-gray_60 pt-4 text-center'>
                <h1 className='text-body2_600 text-black pb-2'>{option === 'calorie' ? '열량' : '단백질'} {parseInt(actual / recommended * 100) + '%'}</h1>
                <div className='h-[217px] flex items-end border-b px-3 pt-4'>
                    <div className={'relative w-1/2 h-[160px] rounded-t-lg ' + (option === 'calorie' ? 'bg-orange_40' : 'bg-green_40')}>
                        <h3 className='absolute top-1 left-0 right-0 text-center text-caption1_700 text-black'>
                            {option === 'calorie' ? pointFn(recommended).length < 4 ? pointFn(recommended) + 'kcal' : pointFn(recommended) + '\n'
                                : pointFn(recommended).length < 4 ? pointFn(recommended) + 'g' : pointFn(recommended) + '\n'}
                            <span>{pointFn(recommended).length >= 4 ? option === 'calorie' ? 'kcal' : 'g' : null}</span>
                        </h3>
                    </div>
                    <div className={'relative w-1/2 rounded-t-lg max-h-[200px] ' + (option === 'calorie' ? 'bg-orange_80' : 'bg-green_80')} style={{ height: (actual * 160 / recommended) || '' }}>
                        <h3 className={'absolute left-0 right-0 text-center text-caption1_700 text-black ' + ((actual === 0 || actual === null) ? 'bottom-2' : (actual * 160 / recommended) <= 24 ? 'bottom-4' : 'top-1')}>
                            {option === 'calorie' ? pointFn(actual).length < 4 ? pointFn(actual) + 'kcal' : pointFn(actual) + '\n'
                                : pointFn(actual).length < 4 ? pointFn(actual) + 'g' : pointFn(actual) + '\n'}
                            <span>{pointFn(actual).length >= 4 ? option === 'calorie' ? 'kcal' : 'g' : null}</span>
                        </h3>
                    </div>
                </div>
                <div className='flex justify-between my-4 text-caption1_700 text-black'>
                    <h2 className='w-1/2'>권장</h2>
                    <h2 className='w-1/2'>섭취</h2>
                </div>
            </div>
            <div className='mt-4 text-center'>
                <span className={'px-4 py-1 rounded-2xl ' + (result === '섭취 부족' ? 'text-black border border-black bg-white' : result === '과다 섭취' ? 'text-white bg-black' : 'bg-correct text-white')}>
                    {result === '양호' ? '양호👏' : result}
                </span>
            </div>
        </div >
    );
};

export default CalorieProteinCard;