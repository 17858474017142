import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getFAQ,
  getFoodGuide,
  getFullGuide,
  getWeightGuide,
  onClickGuideList,
  onSetContTab,
  searchFoodGuide,
  searchSymptomGuide,
  searchWeightGuide,
} from "../../../features/admin/contentsSlice";
/*components*/
import Sidebar from "../components/common/Sidebar";
import Navbar from "../components/common/Navbar";
import {
  foodOptions,
  weightOptions,
} from "../components/contents/functions/service";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import FullGuideModalIn from "../components/contents/innerData/FullGuideModalIn";
import ContentsTable from "../components/contents/ContentsTable";
import ContentsSelect from "../components/contents/ContentsSelect";
import FoodGuideModalIn from "../components/contents/innerData/FoodGuideModalIn";
import WeightGuideModalIn from "../components/contents/innerData/WeightGuideModalIn";
import SymptomGuideModalIn from "../components/contents/innerData/SymptomGuideModalIn";
import { BGButtonCustom, ResetButton } from "../components/common/ButtonCustom";
import CommonOption from "../components/common/CommonOption";

const Contents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const tabName = useSelector((state) => state.contents.clickTab);
  const fullGuideList = useSelector((state) => state.contents.fullGuide);
  const foodGuideList = useSelector((state) => state.contents.foodGuide);
  const weightGuideList = useSelector((state) => state.contents.weightGuide);
  const symptomGuideList = useSelector((state) => state.contents.symptomGuide);
  const faqList = useSelector((state) => state.contents.faq);
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectName, setSelectName] = useState("");
  const [categories, setCategories] = useState({
    lcategory: "",
    mcategory: "",
    scategory: "",
  });
  const tabs = [
    "종합 가이드",
    "식사 가이드",
    "체중 가이드",
    "증상 가이드",
    "FAQ",
  ];

  const showEditModal = (data) => {
    setEditModal(!editModal);
    dispatch(onClickGuideList(data));
  };

  useEffect(() => {
    if (loginInfo?.adminId) {
      async function fetchDataList() {
        if (tabName === "종합 가이드") {
          dispatch(getFullGuide());
        } else if (tabName === "식사 가이드") {
          dispatch(getFoodGuide());
        } else if (tabName === "체중 가이드") {
          dispatch(getWeightGuide());
        } else if (tabName === "증상 가이드") {
          let param = { type: "", searchTxt: "" };
          dispatch(searchSymptomGuide(param));
        } else if (tabName === "FAQ") {
          dispatch(getFAQ());
        }
      }
      fetchDataList();
      dispatch(onSetContTab(tabName !== "" ? tabName : "종합 가이드"));
    } else {
      navigate("/admin/signin");
    }
  }, [tabName]);

  const onChangeContent = (e) => {
    const { name, value, id } = e.target;
    //id가 1이면 lcategory, id가 2이면 mcategory, id가 3이면 lcategory
    const param1 = {
      lcategory: value === "-" ? null : value,
      mcategory: categories.mcategory === "-" ? null : categories.mcategory,
      scategory: categories.scategory === "-" ? null : categories.scategory,
    };
    const param2 = {
      lcategory: categories.lcategory === "-" ? null : categories.lcategory,
      mcategory: value === "-" ? null : value,
      scategory: categories.scategory === "-" ? null : categories.scategory,
    };
    const param3 = {
      lcategory: categories.lcategory === "-" ? null : categories.lcategory,
      mcategory: categories.mcategory === "-" ? null : categories.mcategory,
      scategory: value === "-" ? null : value,
    };
    if (name === "food") {
      if (Number(id) === 1) {
        setCategories({ ...categories, lcategory: value });
        dispatch(searchFoodGuide(param1));
      } else if (Number(id) === 2) {
        setCategories({ ...categories, mcategory: value });
        dispatch(searchFoodGuide(param2));
      } else {
        setCategories({ ...categories, scategory: value });
        dispatch(searchFoodGuide(param3));
      }
    } else if (name === "weight") {
      //id가 1이면 lcategory, id가 2이면 mcategory, id가 3이면 lcategory
      if (Number(id) === 1) {
        setCategories({ ...categories, lcategory: value });
        dispatch(searchWeightGuide(param1));
      } else if (Number(id) === 2) {
        setCategories({ ...categories, mcategory: value });
        dispatch(searchWeightGuide(param2));
      } else {
        setCategories({ ...categories, scategory: value });
        dispatch(searchWeightGuide(param3));
      }
    } else {
      console.log("this else");
    }
  };

  const onClickDefault = () => {
    setCategories({
      lcategory: "-",
      mcategory: "-",
      scategory: "-",
    });
    const param = {
      lcategory: null,
      mcategory: null,
      scategory: null,
    };
    if (tabName === "식사 가이드") {
      dispatch(searchFoodGuide(param));
    } else {
      dispatch(searchWeightGuide(param));
    }
  };

  const onChangeSelectName = (e) => {
    setSelectName(e.target.value);
  };

  //검색내용
  const onChangeSearch = (e) => {
    //type = 1(증상명),2(내용),3(증상명+내용)
    let param = {
      type:
        selectName === "증상명"
          ? 1
          : selectName === "내용"
          ? 2
          : selectName === "증상명+내용"
          ? 3
          : "",
      searchTxt: e.target.value,
    };
    dispatch(searchSymptomGuide(param));
  };
  return (
    <>
      <AnimatePresence initial={false}>
        {newModal ? (
          tabName === "종합 가이드" ? (
            <FullGuideModalIn
              newModal={newModal}
              tabName={tabName}
              setEditModal={setEditModal}
              setNewModal={setNewModal}
            />
          ) : tabName === "식사 가이드" ? (
            <FoodGuideModalIn
              newModal={newModal}
              tabName={tabName}
              setEditModal={setEditModal}
              setNewModal={setNewModal}
            />
          ) : tabName === "체중 가이드" ? (
            <WeightGuideModalIn
              newModal={newModal}
              tabName={tabName}
              setEditModal={setEditModal}
              setNewModal={setNewModal}
            />
          ) : tabName === "증상 가이드" ? (
            <SymptomGuideModalIn
              newModal={newModal}
              tabName={tabName}
              setEditModal={setEditModal}
              setNewModal={setNewModal}
            />
          ) : (
            ""
          )
        ) : editModal ? (
          tabName === "종합 가이드" ? (
            <FullGuideModalIn
              editModal={editModal}
              tabName={tabName}
              setEditModal={setEditModal}
              setNewModal={setNewModal}
            />
          ) : tabName === "식사 가이드" ? (
            <FoodGuideModalIn
              editModal={editModal}
              tabName={tabName}
              setEditModal={setEditModal}
              setNewModal={setNewModal}
            />
          ) : tabName === "체중 가이드" ? (
            <WeightGuideModalIn
              editModal={editModal}
              tabName={tabName}
              setEditModal={setEditModal}
              setNewModal={setNewModal}
            />
          ) : tabName === "증상 가이드" ? (
            <SymptomGuideModalIn
              editModal={editModal}
              tabName={tabName}
              setEditModal={setEditModal}
              setNewModal={setNewModal}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </AnimatePresence>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        {loginInfo?.authority === "00" || loginInfo?.authority === "01" ? (
          <div className="flex flex-col gap-4 p-6">
            <div>
              <div className="flex justify-between">
                <div className="flex">
                  {tabs?.map((tab, idx) => (
                    <div
                      key={idx}
                      onClick={() => {
                        dispatch(onSetContTab(tab));
                      }}
                      className={
                        "h-15 w-32 cursor-pointer rounded-t-2xl py-4 text-center text-body3_600 " +
                        (tabName === tab
                          ? "bg-white  text-black "
                          : "bg-gray_30  text-gray_140 ")
                      }
                    >
                      {tab}
                    </div>
                  ))}
                </div>
                <div>
                  <div className="flex gap-[8px]">
                    <BGButtonCustom
                      onClick={() => {
                        window.open("http://3.37.41.219:3100/", "_blank");
                      }}
                      content={"식품 DB"}
                      bgcolor={"bg-orange_100"}
                      textcolor={"text-gray_10"}
                      textsize={"text-caption1_600"}
                      xpadding={"12px"}
                    />
                    {tabName !== "FAQ" && (
                      <BGButtonCustom
                        onClick={() => {
                          setNewModal(true);
                          dispatch(onClickGuideList([]));
                        }}
                        content={"새 가이드 등록"}
                        bgcolor={"bg-orange_100"}
                        textcolor={"text-gray_10"}
                        textsize={"text-caption1_600"}
                        xpadding={"12px"}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="rounded-b-xl rounded-tr-xl bg-gray_10 p-4 ">
                <div
                  className={
                    tabName === "식사 가이드" || tabName === "체중 가이드"
                      ? "flex w-full justify-between  gap-x-2 border-b"
                      : "flex w-full justify-between  gap-x-2"
                  }
                >
                  <div
                    className={
                      tabName === "증상 가이드"
                        ? "flex w-full justify-end gap-x-2 pb-[16px]"
                        : "flex w-full items-end justify-between gap-x-1 pb-2"
                    }
                  >
                    {tabName === "식사 가이드" ? (
                      foodOptions?.map((el, idx) => (
                        <ContentsSelect
                          name="food"
                          key={idx}
                          datas={el}
                          categories={categories}
                          onChangeContent={onChangeContent}
                        />
                      ))
                    ) : tabName === "체중 가이드" ? (
                      weightOptions?.map((el, idx) => (
                        <ContentsSelect
                          name="weight"
                          key={idx}
                          datas={el}
                          categories={categories}
                          onChangeContent={onChangeContent}
                        />
                      ))
                    ) : tabName === "증상 가이드" ? (
                      <>
                        <CommonOption
                          lists={["증상명+내용", "증상명", "내용"]}
                          selectid={"symptom"}
                          onChangeFunction={onChangeSelectName}
                          mywidth={200}
                        />
                        <div className="relative flex h-[48px] items-center gap-2 rounded-[4px] border border-gray_100 px-[20px] py-[12px]">
                          <input
                            className="text-body_2 mr-6 h-full w-full bg-transparent text-black focus:outline-none "
                            placeholder="검색내용을 입력하세요"
                            onChange={onChangeSearch}
                          />
                          <img
                            src={"/images/admin/icon/search.svg"}
                            className="w-[20px]"
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {tabName === "식사 가이드" || tabName === "체중 가이드" ? (
                      <div className="pb-2">
                        <ResetButton
                          onClick={onClickDefault}
                          text={"선택 값 초기화"}
                          custom={"text-body_2"}
                          width={145}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {tabName === "식사 가이드" || tabName === "체중 가이드" ? (
                  <div className="pb-4"></div>
                ) : (
                  ""
                )}
                <ContentsTable
                  tabName={tabName}
                  showEditModal={showEditModal}
                  DataList={
                    tabName === "종합 가이드"
                      ? fullGuideList
                      : tabName === "식사 가이드"
                      ? foodGuideList
                      : tabName === "체중 가이드"
                      ? weightGuideList
                      : tabName === "증상 가이드"
                      ? symptomGuideList
                      : tabName === "FAQ"
                      ? faqList
                      : null
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Contents;
