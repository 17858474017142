import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchFoodRecipe } from "../../../../features/mobile/recommendSlice";
import { mobileTypeFn } from "../../components/logic";
import TitleBox from "../../components/common/TitleBox";
import Btn from "../../components/common/Btn";
import HeadCont from "../../components/common/HeadCont";
// import BookmarkBtn from '../../components/common/BookmarkBtn';
// import ShareBtn from '../../components/common/ShareBtn';

const RecipeForShare = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { type } = useParams();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { recipeLists } = useSelector((state) => state.recommend);
  const [errorMsg, SetErrorMsg] = useState(null);

  const ingredientCard = (title, data) => {
    return (
      <li className="flex justify-between">
        <h2 className="text-caption1_600 text-gray_180">{title}</h2>
        <h3 className="text-body2_500 text-black">{data}</h3>
      </li>
    );
  };

  const onMoveToStore = () => {
    window.scrollTo(0, 0);
    if (mobileTypeFn() === "iphone") {
      //아이폰
      window.open(`https://apps.apple.com/kr/app/id1636424503`, "_blank");
    } else {
      //안드로이드
      window.open(`market://details?id=com.herings.healiary&pli=1`, "_blank");
    }
  };

  useEffect(() => {
    if (type) {
      let params = { userId: loginInfo.userId, foodId: type };
      dispatch(fetchFoodRecipe(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            SetErrorMsg(null);
          } else SetErrorMsg(res.error);
        })
        .catch((err) => console.log(err));
    }
  }, [type, dispatch, loginInfo.userId]);

  return (
    <>
      <HeadCont
        title={"레시피"}
        onRightAction={() => navigate(-1)}
        rightIcon={"close"}
        transparent="white"
      />
      <section className="mb-6">
        {errorMsg ? (
          <div className="flex h-screen items-center justify-center">
            {errorMsg}
          </div>
        ) : recipeLists ? (
          <>
            <span
              className="bgCover block h-[320px] w-full"
              style={{
                background: `url(${
                  recipeLists.representativeImage
                    ? recipeLists.representativeImage
                    : "/images/mobile/image/no_image.svg"
                }) no-repeat center`,
              }}
            ></span>
            <div className="space-y-4">
              <h1 className="border-b border-gray_40 py-4 text-center text-subtitle1 text-black">
                {recipeLists.foodname}
              </h1>
              <div className="mx-[28px] my-4 space-y-3">
                <TitleBox
                  title={"재료"}
                  font={"text-body1_700"}
                  textColor={"text-black"}
                />
                {ingredientCard("주재료", recipeLists.ingredientMain)}
                {ingredientCard("부재료", recipeLists.ingredientSub)}
                <div className="rounded-xl bg-back_ground px-4 py-3">
                  <div className="flex justify-between">
                    <h2 className="text-caption1_600 text-gray_180">
                      전체 재료
                    </h2>
                    <span className="rounded-full bg-orange_10 px-3 py-1 text-caption1_600 text-orange_160">
                      {recipeLists.persons}인분기준
                    </span>
                  </div>
                  <p className="mt-3 text-body2_500l text-gray_120">
                    {recipeLists.ingredientAll}
                  </p>
                </div>
              </div>
              <div className="mx-[28px] my-4 space-y-3">
                <TitleBox
                  title={"레시피"}
                  font={"text-body1_700"}
                  textColor={"text-black"}
                />
                <ul className="space-y-3">
                  {recipeLists.recipe &&
                    recipeLists.recipe.map((list, idx) => (
                      <li className="flex gap-2" key={idx}>
                        <span className="h-[32px] w-[32px] rounded-full bg-gray_20 p-1 text-center text-caption1_600 text-gray_180">
                          {idx + 1}
                        </span>
                        <p className="text_black flex-1 text-body2_500">
                          {list}
                        </p>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="mx-[28px] mt-8">
              <Btn
                bgColor={"bg-main"}
                textColor={"text-white"}
                borderColor={"border border-main"}
                title={"맞춤 식단 받기"}
                active={"on"}
                onAction={onMoveToStore}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export default RecipeForShare;
