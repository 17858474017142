import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
//mobile
import commonSliceAppReducer from "./mobile/commonSlice";
import questionHealthReducer from "./mobile/questionHealthSlice";
import questionFoodReducer from "./mobile/questionFoodSlice";
import questionDiseaseReducer from "./mobile/questionDiseaseSlice";
import questionFoodAddReducer from "./mobile/questionFoodAddSlice";
import otherReducer from "./mobile/otherSlice";
import recommendReducer from "./mobile/recommendSlice";
import mealReducer from "./mobile/mealSlice";
import diaryReducer from "./mobile/diarySlice";
import accountReducer from "./mobile/accountSlice";
import settingReducer from "./mobile/settingSlice";
import infoReducer from "./mobile/infoSlice";
import commReducer from "./mobile/commSlice";
import cancerGptReducer from "./mobile/cancerGptSlice";
import drugReducer from "./mobile/drugSlice";
import treatmentReducer from "./mobile/treatmentSlice";
import mainDataReducer from "./mobile/mainSlice";

//admin
import adminloginSlice from "./admin/adminloginSlice";
import toggleSlice from "./admin/toggleSlice";
import managementSlice from "./admin/managementSlice";
import contentsSlice from "./admin/contentsSlice";
import mainSlice from "./admin/mainSlice";
import adminSlice from "./admin/adminSlice";
import communitySlice from "./admin/communitySlice";
import accountSlice from "./admin/accountSlice";
import hospitalSlice from "./admin/hospitalSlice";
import commonSlice from "./admin/commonSlice";
import paginationSlice from "./admin/paginationSlice";
import stepReducer from "./mobile/stepSlice";

const reducers = combineReducers({
  //app
  commonApp: commonSliceAppReducer,
  questionHealth: questionHealthReducer,
  questionFood: questionFoodReducer,
  questionDisease: questionDiseaseReducer,
  questionFoodAdd: questionFoodAddReducer,
  others: otherReducer,
  recommend: recommendReducer,
  meal: mealReducer,
  diary: diaryReducer,
  account: accountReducer,
  setting: settingReducer,
  info: infoReducer,
  commMobile: commReducer,
  cancerGpt: cancerGptReducer,
  drug: drugReducer,
  treatment: treatmentReducer,
  step: stepReducer,
  mainData: mainDataReducer,

  //admin
  adminLogin: adminloginSlice,
  toggleAdmin: toggleSlice,
  common: commonSlice,
  management: managementSlice,
  contents: contentsSlice,
  main: mainSlice,
  admin: adminSlice,
  commu: communitySlice,
  accountadmin: accountSlice,
  hospital: hospitalSlice,
  pagination: paginationSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["commonApp"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
