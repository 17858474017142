import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getHospitalInfo,
  searchHospitalInfo,
} from "../../../../features/mobile/infoSlice";
// import { CancerTypes } from "../../../admin/components/management/SearchTargetOption";
import BannerSlide from "../../components/BannerSlide";
import HeadCont from "../../components/common/HeadCont";
import CancerType from "./cancerType";
import HelpModal from "./helpModal";
import MenuBar from "../../components/common/MenuBar";
import { getBanners } from "../../../../features/mobile/commSlice";
// import SearchHospital from "./searchHospital";

const InfoHospital = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { hospitalInfoList } = useSelector((state) => state.info);

  const [tapSelect, setTapSelect] = useState("cancerTreatmentCenter");
  const [modalOpen, setModalOpen] = useState(false);
  const [hospitalAreaList, setHospitalAreaList] = useState([]); // 병원 지역별 갯수
  const [hospitalCancerList, setHospitalCancerList] = useState([]); // 병원 암종별 갯수
  const [nursingHospitalAreaList, setNursingHospitalAreaList] = useState([]); // 요양병원 지역별 갯수
  const [bannerList, setBannerList] = useState([]); // 배너광고 배열

  const [targetCancer, setTargetCancer] = useState("");
  const [targetArea, setTargetArea] = useState("");
  const [inputHospital, setInputHospital] = useState("");

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  // 페이지 렌더링시 실행
  useEffect(() => {
    getBanner();
    getlInfoHospital();
    // return () => {
    //   second
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 암종, 지역, 병원이름입력 값 초기화
  useEffect(() => {
    setTargetCancer("");
    setTargetArea("");
    setInputHospital("");
  }, [tapSelect]);

  // 배너 광고 가져오기
  const getBanner = async () => {
    let params = {
      menu: 2,
    };
    try {
      const res = await dispatch(getBanners(params)).unwrap();
      if (res.ok) {
        setBannerList(res.bannerList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getlInfoHospital = async () => {
    // 병원정보조회 메인 데이터
    await dispatch(getHospitalInfo())
      .unwrap()
      .then((res) => {
        if (res.ok) {
          setHospitalAreaList(res.hospitalAreaList);
          setHospitalCancerList(res.hospitalCancerList);
          setNursingHospitalAreaList(res.nursingHospitalAreaList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchInfoHospital = async () => {
    // 병원 정보 검색
    let params = {
      name: inputHospital,
      area: targetArea,
      cancer: targetCancer,
      type: tapSelect === "cancerTreatmentCenter" ? 1 : 2,
    };
    await dispatch(searchHospitalInfo(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          navigate("/hospitalinfo/list", {
            state: {
              targetCancer: targetCancer,
              targetArea: targetArea,
              tapSelect: tapSelect,
              hospitalCancerList: hospitalCancerList,
              hospitalAreaList: hospitalAreaList,
              nursingHospitalAreaList: nursingHospitalAreaList,
              // hospitalInfoList: hospitalInfoList,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="maxW fixed z-30 h-screen w-full overflow-scroll bg-white">
      <div className="mb-24">
        <section>
          <HeadCont
            title={"병원 정보 찾기"}
            transparent={"white"}
            topline={true}
            onLeftAction={() => navigate(-1)}
          />
        </section>

        <section className="mt-[57px]">
          <BannerSlide bannerList={bannerList} />
          <div className="flex w-full items-start gap-10 border-b border-gray_60 bg-white px-6">
            <div
              className={
                ` box-content flex h-[56px] w-1/2 items-center justify-center gap-1 ` +
                (tapSelect === "cancerTreatmentCenter" &&
                  ` border-b-4 border-main `)
              }
              onClick={() => setTapSelect("cancerTreatmentCenter")}
            >
              <span
                className={
                  ` flex items-center justify-center gap-1 text-caption1_700 ` +
                  (tapSelect !== "cancerTreatmentCenter" && ` text-gray_100 `)
                }
              >
                수술
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="4"
                  height="4"
                  viewBox="0 0 4 4"
                  fill="none"
                >
                  <circle
                    cx="2"
                    cy="2"
                    r="2"
                    fill={
                      tapSelect === "cancerTreatmentCenter" ? "black" : "silver"
                    }
                  />
                </svg>
                항암치료병원
              </span>
            </div>

            <div
              className={
                ` box-content flex h-[56px] w-1/2 items-center justify-center gap-1 ` +
                (tapSelect === "nursingHome" && ` border-b-4 border-main `)
              }
              onClick={() => setTapSelect("nursingHome")}
            >
              <span
                className={
                  ` text-caption1_700 ` +
                  (tapSelect !== "nursingHome" && ` text-gray_100 `)
                }
              >
                요양병원
              </span>
            </div>
          </div>
          {/* tap 파트 여기까지 */}

          <div
            className="flex w-auto items-center gap-5 border-b bg-white p-4"
            onClick={() => openModal()}
          >
            <div className="flex flex-col items-start">
              <p className="text-center text-caption1_700">
                찾으시는 병원이 있으신가요?
              </p>
              <div className="flex items-end gap-1">
                <span className="text-caption2_700 text-gray_120">
                  건강보험심사평가원에서 실시한
                  <br />
                  적정성 평가 결과 종합평가 1등급 의료기관입니다.
                </span>
                <img
                  src="/images/mobile/icon/help.svg"
                  alt="병원 찾기 도움말 아이콘"
                />
              </div>
            </div>
          </div>
          <CancerType
            tapSelect={tapSelect}
            hospitalAreaList={hospitalAreaList}
            hospitalCancerList={hospitalCancerList}
            nursingHospitalAreaList={nursingHospitalAreaList}
            targetCancer={targetCancer}
            setTargetCancer={setTargetCancer}
            setTargetArea={setTargetArea}
          />
          <div className="flex flex-col items-center justify-center gap-2 bg-white p-4">
            <button
              className="flex w-full items-center justify-center rounded-[16px] border bg-main p-[15px]"
              onClick={() => searchInfoHospital()}
            >
              <p className="text-body3_700 text-white">찾아보기</p>
            </button>
          </div>
        </section>
      </div>
      <HelpModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        tapSelect={tapSelect}
      />
      <MenuBar />
    </div>
  );
};
export default InfoHospital;
