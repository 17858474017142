import React from 'react'

const LabelSelectLayout = ({title,children,mywidth}) => {
  return (
    <div className="flex flex-col items-start "
    style={
      mywidth === undefined ?
      {width: "100%"}:
      {width:`${mywidth}px`}
    }
    >
    <label className="flex items-center font-normal text-caption_2 text-gray_100">
      {title}
    </label>
      {children}
  </div>
  )
}

export default LabelSelectLayout