import { Editor } from '@toast-ui/react-editor'
import "@toast-ui/editor/dist/toastui-editor.css";
import { strings } from './String';
import React, { useState } from "react"
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/react-editor';

const TextEditor = () => {
  const editorRef = React.createRef();
  const [imgurl, setImgUrl] = useState([])
  const handleClickContent = () => {
    const editorIns = editorRef?.current?.getInstance().getHTML()
  }

  return (
    <>
      <div className='flex flex-col pr-6'>
        <Editor
          height="400px"
          initialEditType="wysiwig"
          initialValue="hello"
          useCommandShortcut={true}
          viewer={false}
          hooks={{
            addImageBlobHook: async (blob, callback) => {
              console.log(blob);
              callback("https://data.si.re.kr/sites/default/files/styles/large/public/2021-06/06P02903Aa42000_0.jpg?itok=nyWmLZN7", '카레유');
            }
          }}
          ref={editorRef} />

        <button className="bg-red-100 h-[48px]" onClick={handleClickContent}>클릭하면콘솔에서 컨텐트볼수있다.</button>
      </div>
      <div>
        html을 string으로

        <Viewer initialValue={strings} />
      </div>
    </>
  )
}

export default TextEditor