import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Btn from "../../components/common/Btn";
import { common } from "../../../../common/api";
import { mobileTypeFn } from "../logic";
import SignIn from "../../pages/sign/SignIn";
import FindPass from "../../pages/sign/FindPass";

const SignUp = () => {
  const navigate = useNavigate();
  //eslint-disable-next-line
  // let [showSns, setShowSns] = useState(true);
  const [isApple, setIsApple] = useState(false);
  const params = new URL(document.location).searchParams;
  const firebaseToken = params.get("firebaseToken");

  // useEffect(() => {
  //   const iphoneLoad = async () => {
  //     const phoneType = mobileTypeFn();
  //     if (phoneType === "iphone") {
  //       //아이폰 특정 버전일때 sns 로그인 버튼제거, 아이폰앱 새로 올릴때 사용, 앱올라간후에는 제거
  //       let appVersion = await window.flutter_inappwebview.callHandler(
  //         "getIosVersion"
  //       );
  //       if (appVersion === "2.0.17") {
  //         setShowSns(false);
  //       }
  //     }
  //   };
  //   iphoneLoad();
  // }, []);

  useEffect(() => {
    if (firebaseToken !== null && firebaseToken !== "") {
      //token 있을때
      window.sessionStorage.setItem("token", firebaseToken);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const iphoneLoad = async () => {
      const phoneType = mobileTypeFn();
      if (phoneType === "iphone") {
        setIsApple(true);
      }
    };
    iphoneLoad();
  }, []);

  const onLoginKakao = () => {
    window.flutter_inappwebview?.callHandler("check_kakao");
  };

  const onLoginNaver = () => {
    window.flutter_inappwebview?.callHandler("check_naver");
  };

  const onLoginApple = () => {
    window.flutter_inappwebview?.callHandler("apple_login");
  };

  return (
    <div className="flex flex-col h-screen place-content-center">
      <img
        src="/images/mobile/home/signup_txt.svg"
        className="mx-auto"
        alt="힐리어리"
      />
      <SignIn />
      <div className="flex justify-between mx-6 my-5">
        <p
          className="text-base font-semibold leading-7 tracking-tight text-neutral-600"
          onClick={() => navigate("/findpass")}
        >
          비밀번호 찾기
        </p>
        <div className="flex flex-row gap-1">
          <p
            className="text-base font-semibold leading-7 tracking-tight text-neutral-600"
            onClick={() => navigate(`/mobile`)}
          >
            휴대폰번호로 가입하기
          </p>
          <img src="./images/mobile/icon/login/arrow_right.svg" alt="화살표" />
        </div>
      </div>
      <div className="flex items-center gap-2 mx-6 mt-10 mb-3">
        <p className="h-[0.5px] w-full bg-gray_160"></p>
        <p className="font-bold shrink-0 text-gray_160">SNS계정으로 로그인</p>
        <p className="h-[0.5px] w-full bg-gray_160"></p>
      </div>

      <div className="mx-6 flex flex-col gap-[10px]">
        <Btn
          bgColor={"bg-[#FFD43B]"}
          textColor={"text-black font-semibold"}
          borderColor={"border border-[#FFD43B]"}
          title={"카카오톡으로 시작하기"}
          onAction={onLoginKakao}
          active={"on"}
        />
        {!isApple && (
          <Btn
            bgColor={"bg-[#36B44D]"}
            textColor={"text-white font-semibold"}
            borderColor={"border border-[#36B44D]"}
            title={"네이버로 시작하기"}
            onAction={onLoginNaver}
            active={"on"}
          />
        )}
        {isApple && (
          <Btn
            bgColor={"bg-[#202020]"}
            textColor={"text-white font-semibold"}
            borderColor={"border border-[#202020]"}
            title={"애플아이디로 시작하기"}
            onAction={onLoginApple}
            active={"on"}
          />
        )}
      </div>
    </div>
  );
};

export default SignUp;
