import dayjs from "dayjs";

import { useDispatch, useSelector } from "react-redux";
import {
  deletePostings,
  onClickInInfoId,
} from "../../../../features/admin/communitySlice";
import Comment from "../../components/board/Comment";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../components/common/ButtonCustom";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { CancerTypes } from "../../components/management/SearchTargetOption";

const NotEditPosting = ({ setOpenEdit, repeatRes, handlePageFn }) => {
  const dispatch = useDispatch();
  const { isInInfoId, postingIdList } = useSelector((state) => state.commu);

  const onClickEdit = () => {
    dispatch(onClickInInfoId(isInInfoId));
    setOpenEdit(true); //dispatch가 아닌 useState사용한 이유 : default화 시키려면 dispatch default 값을 추가로 넣어줘야해서
  };
  const onClickDelete = async () => {
    let param = { id: isInInfoId.id };
    const res = await dispatch(deletePostings(param)).unwrap();
    repeatRes(res, "delete");
  };

  return (
    <div className="relative flex w-full flex-col items-center justify-center rounded-[8px] border border-[#DFDFDF] bg-gray_15">
      <a
        href="#infoDetailBottom"
        className="border-primary_100 text-primary_100 absolute right-4 top-4 rounded border p-3 font-semibold"
      >
        하단으로
      </a>
      <div className="maxW mx-auto flex flex-col bg-[#FEFEFE] ">
        <div className="border-b border-[#DFDFDF] p-[24px]">
          <div className="flex flex-col items-center justify-center">
            <span className="text-caption2_400 text-orange_140">
              {postingIdList?.category}
            </span>
            <p className="break-all text-subtitle1 text-gray_160">
              {postingIdList?.title}
            </p>
          </div>
          <div className="flex items-center gap-x-1">
            <img
              src={postingIdList?.writerAdmin?.photo}
              className="h-10 w-10 rounded-full"
              alt=""
            />
            <ul>
              <li className="text-body2_700 text-orange_140">
                {postingIdList?.writerAdmin?.name}
              </li>
              <li className="text-caption2_400 text-gray_140">{`${dayjs(
                postingIdList?.createdAt
              ).format("YYYY-MM-DD")}
              ${dayjs(postingIdList?.writerAdmin?.createdAt).format("A")}
              ${
                dayjs(postingIdList?.writerAdmin?.createdAt).format("H") -
                (dayjs(postingIdList?.writerAdmin?.createdAt).format("A") ===
                "오후"
                  ? 12
                  : 0)
              }:${dayjs(postingIdList?.writerAdmin?.createdAt).format(
                "MM"
              )}`}</li>
            </ul>
          </div>
        </div>
        <div className="bg-back_ground p-[24px]">
          <div id="viewer"></div>
          <div className="flex flex-wrap gap-3">
            {postingIdList?.infoCancerTypes?.map((el, idx) => {
              if (el !== "" && el !== "null") {
                return (
                  <span
                    key={idx}
                    className="relative block h-[36px] w-max cursor-pointer rounded-[20px] bg-gray_20 px-4 pt-2 text-center text-caption1_400 text-sm "
                  >
                    {CancerTypes[el]}
                  </span>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <Comment />
      </div>
      <div className="absolute bottom-6 right-6 flex gap-x-4">
        <a
          href="#infoDetailTop"
          className="border-primary_100 text-primary_100 rounded border p-3 font-semibold"
        >
          상단으로
        </a>
        <BorderButtonCustom
          onClick={onClickEdit}
          content={"수정"}
          bgcolor={""}
          textcolor={"text-orange_100"}
          bordercolor={"border-orange_100"}
          textsize={"text-caption1_600"}
          xpadding={"12px"}
        />
        <BGButtonCustom
          onClick={onClickDelete}
          content={"삭제"}
          bgcolor={"bg-orange_100"}
          textcolor={"text-white"}
          textsize={"text-caption1_600"}
          xpadding={"12px"}
        />
      </div>
    </div>
  );
};

export default NotEditPosting;
