import React, { useState, useEffect } from "react";
import ContentsGuideListLayout from "./ContentsGuideListLayout";
import ContentsModal from "./ContentsModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSymptomGuide,
  editSymptomGuide,
  getFullGuide,
  getSymptomGuide,
  saveSymptomGuide
} from "../../../../../features/admin/contentsSlice";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../../common/toast";
import { symptomInputs } from "../functions/service";
import ModalNotSelect from "./ModalNotSelect";
import PhotoComp from "../../common/PhotoComp";
import { ThreeConceptReport, TwoConceptReport } from "../ModalReportTop";
import { BGButtonCustom, BorderButtonCustom } from "../../common/ButtonCustom";
import { resizeFile } from "../../../../../common/util/base";

const SymptomGuideModalIn = ({
  newModal,
  editModal,
  tabName,
  setNewModal,
  setEditModal
}) => {
  const { loginInfo } = useSelector((state) => state.adminLogin);
  //가이드수정시 최초로 불러오는 조건식
  const symptomGuideEach = useSelector((state) => state.contents.eachGuideList);

  const dispatch = useDispatch();
  const [loadedImage, setLoadedImage] = useState([]);
  const [editInner, setEditInner] = useState(false);
  const [symptomGuide, setSymptomGuide] = useState({
    symptom: "",
    explanation: "",
    copingSummary: "",
    files: null,
    content: "",
    addAdmin: loginInfo?.adminId
  });

  useEffect(() => {
    setLoadedImage(symptomGuideEach.files);
  }, []);
  const onChangeSymptom = (e) => {
    const { name, value } = e.target;
    setSymptomGuide({ ...symptomGuide, [name]: value });
  };
  const submitSYMPOMGuide = async () => {
    let param = symptomGuide;
    const res = await dispatch(saveSymptomGuide(param)).unwrap();
    if (res.data.ok) {
      toast(
        <p>저장 완료되었습니다</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
      setTimeout(() => setNewModal(false), 1000);
      dispatch(getSymptomGuide());
    } else {
      console.log(res);
    }
  };
  const onClickDelete = async (paramid) => {
    const res = await dispatch(deleteSymptomGuide(paramid)).unwrap();
    if (res.data.ok) {
      toast(
        <p>해당 증상가이드가 삭제 되었습니다.</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
      dispatch(getSymptomGuide());
      setTimeout(() => {
        setEditModal(false);
      }, 1000);
    } else {
      console.log(res, "증상가이드 삭제 실패ㅜ");
    }
  };
  const onClickEdit = async () => {
    let paramEdit = {
      //가이드 수정클릭시 symptomGuide에 id값 추가
      id: symptomGuide.id,
      symptom: symptomGuide.symptom,
      explanation: symptomGuide.explanation,
      copingSummary: symptomGuide.copingSummary,
      content: symptomGuide.content,
      //symptomGuide.files 수정 초기 화면에서 기존 url 가져오느라 string 타입으로 가져오므로,
      //edit시에는 files의 type이 object(loaded 되는 이미지)가 아니면 null 로 전송
      files: typeof symptomGuide.files === "string" ? null : symptomGuide.files,
      editAdmin: loginInfo?.adminId
    };
    const res = await dispatch(editSymptomGuide(paramEdit)).unwrap();
    if (res.data.ok) {
      toast(
        <p>수정 완료되었습니다</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
      setTimeout(() => setEditModal(false), 1000);
      dispatch(getSymptomGuide());
    } else {
      console.log(res);
    }
  };

  const onChangeFile = async (e) => {
    if (e.target.files?.length > 1) alert("하나의 이미지만 등록 가능합니다.");
    else {
      let fileUrls = [];
      let cameraArr = [];
      for (let i = 0; i < e.target.files?.length; i++) {
        let reader = new FileReader();
        let resize = await resizeFile(e.target.files[i]);
        reader.readAsDataURL(resize);
        cameraArr.push(resize);
        setSymptomGuide({
          ...symptomGuide,
          files: symptomGuide.files.concat([...cameraArr])
        });
        reader.onload = async () => {
          fileUrls[i] = reader.result;
          loadedImage?.length > 0
            ? setLoadedImage(loadedImage.concat([...fileUrls]))
            : setLoadedImage([...fileUrls]);
        };
      }
      e.target.value = "";
    }
  };
  const deleteImage = (image, no) => {
    setLoadedImage([...loadedImage?.filter((el) => el !== image)]);
    symptomGuide.files.splice(no, 1);
    setSymptomGuide({ ...symptomGuide, files: symptomGuide.files });
  };
  const movePage = () => {
    setEditInner(true);
    setSymptomGuide({
      id: symptomGuideEach.id,
      symptom: symptomGuideEach.symptom,
      explanation: symptomGuideEach.explanation,
      copingSummary: symptomGuideEach.copingSummary,
      content: symptomGuideEach.content,
      files: symptomGuideEach.files
    });
  };
  return (
    <>
      {/* 새가이드등록 */}
      {newModal && (
        <ContentsModal
          tabName={tabName}
          setEditModal={setEditModal}
          setNewModal={setNewModal}
          subTab={"새 가이드 등록"}
        >
          <div className="pb-[32px]">
            <TwoConceptReport user={loginInfo} />
          </div>
          <div className="pb-[32px]">
            <ContentsGuideListLayout title="증상 이미지">
              <PhotoComp
                smallsize={true}
                onChangeFile={onChangeFile}
                deleteImage={deleteImage}
                loadedImage={loadedImage}
              />
              <span className="text-sm">
                png로 업로드해주세요. 하나의 이미지만 등록 가능합니다.
              </span>
            </ContentsGuideListLayout>
          </div>
          {symptomInputs?.length > 0 &&
            symptomInputs?.map((el, idx) => (
              <ContentsGuideListLayout title={el.label} key={idx}>
                <input
                  name={el.name}
                  className="border border-gray_60 w-full rounded px-[20px] py-[12px] outline-none"
                  onChange={onChangeSymptom}
                />
              </ContentsGuideListLayout>
            ))}
          <ContentsGuideListLayout title={"내용"}>
            <textarea
              placeholder="내용을 입력하세요(최대 500자)"
              value={symptomGuide.content}
              name="content"
              className="w-full h-[300px] border border-[#A39F9B] rounded px-4 py-2 outline-none"
              onChange={onChangeSymptom}
            />
          </ContentsGuideListLayout>
          <div className="w-full flex justify-end gap-x-4 pt-4">
            <BorderButtonCustom
              onClick={() => {
                setNewModal(false);
              }}
              content={"작성취소"}
              bgcolor={""}
              textcolor={"text-primary_100"}
              bordercolor={"border-primary_100"}
              textsize={"text-body_2"}
              xpadding={"12px"}
            />
            <BGButtonCustom
              onClick={submitSYMPOMGuide}
              content={"저장"}
              bgcolor={"bg-primary_100"}
              textcolor={"text-gray_100"}
              textsize={"text-body_2"}
              xpadding={"12px"}
            />
          </div>
        </ContentsModal>
      )}
      {/* 수정가이드등록 */}
      {editModal &&
        //editInner 가 true면 가이드 수정 component
        (editInner ? (
          <ContentsModal
            tabName={tabName}
            setEditModal={setEditModal}
            setNewModal={setNewModal}
            subTab={"가이드 수정"}
          >
            <div className="pb-[32px]">
              <TwoConceptReport user={loginInfo} />
            </div>
            <ContentsGuideListLayout title={"증상 이미지"}>
              <PhotoComp
                smallsize={true}
                onChangeFile={onChangeFile}
                deleteImage={deleteImage}
                loadedImage={loadedImage}
              />
              <span className="text-sm">
                png로 업로드해주세요. 하나의 이미지만 등록 가능합니다.
              </span>
            </ContentsGuideListLayout>
            {symptomInputs?.length > 0 &&
              symptomInputs?.map((el, idx) => (
                <ContentsGuideListLayout title={el.label} key={idx}>
                  <input
                    name={el.name}
                    value={
                      el.name === "symptom"
                        ? symptomGuide.symptom
                        : el.name === "explanation"
                        ? symptomGuide.explanation
                        : el.name === "copingSummary"
                        ? symptomGuide.copingSummary
                        : ""
                    }
                    className="border border-gray_60 w-full rounded px-[20px] py-[12px] outline-none"
                    onChange={onChangeSymptom}
                  />
                </ContentsGuideListLayout>
              ))}
            <ContentsGuideListLayout title={"내용"}>
              <textarea
                placeholder="내용을 입력하세요(최대 500자)"
                value={symptomGuide.content}
                name="content"
                className="w-full h-[300px] border border-gray_60 rounded px-4 py-2 outline-none"
                onChange={onChangeSymptom}
              />
            </ContentsGuideListLayout>
            <div className="w-full flex justify-end gap-x-4 pt-4">
              <BorderButtonCustom
                onClick={() => setEditInner(false)}
                content={"수정 취소"}
                bgcolor={""}
                textcolor={"text-primary_100"}
                bordercolor={"border-primary_100"}
                textsize={"text-body_2"}
                xpadding={"12px"}
              />
              <BGButtonCustom
                onClick={onClickEdit}
                content={"수정 완료 및 저장"}
                bgcolor={"bg-primary_100"}
                textcolor={"text-gray_100"}
                textsize={"text-body_2"}
                xpadding={"12px"}
              />
            </div>
          </ContentsModal>
        ) : (
          //editInner 가 false이면 가이드 수정전 component
          <ContentsModal
            tabName={tabName}
            setEditModal={setEditModal}
            setNewModal={setNewModal}
          >
            <div className="pb-[32px]">
              <ThreeConceptReport whatGuideEach={symptomGuideEach} />
            </div>

            {!(
              symptomGuideEach.files === undefined ||
              symptomGuideEach.files === null ||
              symptomGuideEach.files?.length === 0
            ) && (
              <div className="pb-[32px]">
                <ContentsGuideListLayout title={"증상 이미지"}>
                  <img
                    src={symptomGuideEach.files[0]}
                    alt="symptomimage"
                    className="w-[48px] h-[48px] rounded"
                  />
                </ContentsGuideListLayout>
              </div>
            )}
            <ContentsGuideListLayout title={"증상 명"}>
              <ModalNotSelect element={symptomGuideEach.symptom} />
            </ContentsGuideListLayout>
            <ContentsGuideListLayout title={"용어 설명"}>
              <ModalNotSelect element={symptomGuideEach.explanation} />
            </ContentsGuideListLayout>
            <ContentsGuideListLayout title={"대처방안 요약"}>
              <ModalNotSelect element={symptomGuideEach.copingSummary} />
            </ContentsGuideListLayout>
            <ContentsGuideListLayout title={"내용"}>
              <div className=" bg-[#F3F3F2] w-full h-[300px] border border-gray_90 rounded px-4 py-2">
                {symptomGuideEach.content}
              </div>
            </ContentsGuideListLayout>
            <div className="w-full flex justify-end gap-x-4 pt-4">
              <BorderButtonCustom
                onClick={() => onClickDelete(symptomGuideEach.id)}
                content={"가이드 삭제"}
                bgcolor={""}
                textcolor={"text-primary_100"}
                bordercolor={"border-primary_100"}
                textsize={"text-body_2"}
                width={100}
              />
              <BorderButtonCustom
                onClick={movePage}
                content={"가이드 수정"}
                bgcolor={""}
                textcolor={"text-primary_100"}
                bordercolor={"border-primary_100"}
                textsize={"text-body_2"}
                xpadding={"12px"}
              />
              <BGButtonCustom
                onClick={() => setEditModal(false)}
                content={"확인"}
                bgcolor={"bg-primary_100"}
                textcolor={"text-gray_10"}
                textsize={"text-body_2"}
                xpadding={"12px"}
              />
            </div>
          </ContentsModal>
        ))}
    </>
  );
};

export default SymptomGuideModalIn;
