//정규식함수
export const checkName = (thisvalue) => {
  const nameRule = /^[가-힣a-zA-Z]+$/
  if (nameRule.test(thisvalue)) {
    return true
  } else {
    return false
  }
}

export const checkPhone = (thisvalue) => {
  let reg_mobile = /^\d{3}-\d{3,4}-\d{4}$/; // 휴대폰 번호
  let reg_tel = /^\d{2,3}-\d{3,4}-\d{4}$/; // 일반 전화 번호
  if (reg_mobile.test(thisvalue) || reg_tel.test(thisvalue)) {
    return true
  } else {
    return false;
  }
};

export const checkBirth = (thisvalue) => {
  const birthRule = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
  if (birthRule.test(thisvalue)) {
    return true;
  } else {
    return false;
  }
};

export const checkEmail = (thisvalue) => {
  const emailRule =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
  if (emailRule.test(thisvalue)) {
    return true;
  } else {
    return false;
  }
};
export const checkPwd = (thisvalue) => {
  const specialRule = /[`~!@#$%^&*|\\'";:/?]/;
  const num = /[0-9]/;
  const char = /[a-zA-Z]/;
  if (
    specialRule.test(thisvalue) &&
    num.test(thisvalue) &&
    char.test(thisvalue) &&
    thisvalue.length >= 8 &&
    thisvalue.length <= 12
  ) {
    return true;
  }
  return false;
};

export const checkUserId = (thisvalue) => {
  const userIdRule = /^[a-z]+[a-z0-9]{5,7}$/g;
  if (userIdRule.test(thisvalue)) {
    return true;
  } else {
    return false;
  }
}

export const checkHeightAndWeight = (thisvalue) => {
  let regHeight = /^\d*.?\d{0,1}$/;//소숫점 1자리 실수
  if (regHeight.test(thisvalue)) {
    return true
  } else {
    return false;
  }
};

