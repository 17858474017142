import React from "react";
import { useSelector } from "react-redux";
import TableHeader from "../common/TableHeader";
import WeeklySymptomTable from "./WeeklySymptomTable";

const WeeklySymptomBox = ({dayNo}) => { //증상탭 빠져서 리팩토링 진행하지 x
  const getSymptomNotOutbreakLists = useSelector((state)=>state.management.symptomNotOutbreakList)
  const getSymtomOutbreakLists = useSelector((state)=>state.management.symptomOutbreakList)
  
  return (
    <div className="space-y-4">
      <section className="grid grid-cols-3 gap-4">
        <div className="col-span-2 p-4 border border-gray_80 rounded-lg space-y-4">
          <TableHeader title={"발생한 증상"} />
          <ul className="grid grid-cols-2">
            {getSymtomOutbreakLists?.map((data) => (
              <li
                key={data.id}
                className={
                  "py-2 " +
                  (data.id % 2 === 1 ? "border-r" : "border-none pl-4")
                }
              >
                <h2 className="text-caption_1 text-semibold text-gray_100">
                  #{data.symptom}
                </h2>
                <p className="text-caption_1 text-gray_100">:
                {data.answerList?.map((el,idx)=><span key={idx}>
                  {data.answerList?.length !==idx+1? el+",":el}</span>)}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="p-4 border border-gray_80 rounded-lg space-y-4">
          <TableHeader title={"전혀 없던 증상"} />
          <div className="word-break ">
            {getSymptomNotOutbreakLists?.map((data) => (
              <span key={data.id} className="text-caption_1 text-semibold text-gray_100 inline-block pr-2">
                  #{data}
              </span>
            ))}
          </div>
        </div>
      </section>
      <section>
        <WeeklySymptomTable dayNo={dayNo}/>
      </section>
    </div>
  );
};

export default WeeklySymptomBox;
