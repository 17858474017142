import React, { useEffect, useState } from "react";
import TitleBox from "../common/TitleBox";
import Parser from "html-react-parser";
import InputBox from "../common/InputBox";

// const questions = [
//   "카페 커뮤니티 (암카페 활동 등)",
//   "SNS를 통해 (인스타그램, 유튜브, 페이스북)",
//   "뉴스 기사를 통해",
//   "의료진의 추천",
//   "온라인 광고 (네이버, 유튜브 배너 광고)",
//   "지인의 추천",
//   "직접 검색 (암환자 식단 등)",
//   "그 외 기타",
// ];

const answers = [
  { num: 0, text: "카페 커뮤니티 (암카페 활동 등)" },
  { num: 1, text: "SNS를 통해 (인스타그램, 유튜브, 페이스북)" },
  { num: 2, text: "뉴스 기사를 통해" },
  { num: 3, text: "의료진의 추천" },
  { num: 4, text: "온라인 광고 (네이버, 유튜브 배너 광고)" },
  { num: 8, text: "SRT 매거진" },
  { num: 5, text: "지인의 추천" },
  { num: 6, text: "직접 검색 (암환자 식단 등)" },
  { num: 7, text: "그 외 기타" },
];

const SignUpSurvey = ({ surveyResponse, onSurveyInput }) => {
  console.log(surveyResponse);
  const [value, setValue] = useState("");
  const handleClick = (answer) => {
    if (answer.num !== 7) setValue("");
    onSurveyInput({
      selectedNum: answer.num,
      selectedAnswer: answer.text,
      etc: value,
    });
    if (answer.num === 7)
      setTimeout(
        () =>
          window.scrollTo({
            left: 0,
            top: document.body.scrollHeight / 2,
            behavior: "smooth",
          }),
        100
      );
  };
  useEffect(() => {
    if (surveyResponse) onSurveyInput({ ...surveyResponse, etc: value });
    // eslint-disable-next-line
  }, [value]);
  return (
    <section className="mx-6 mb-24 mt-[80px] flex flex-col gap-6">
      <div className="flex flex-col gap-1.5">
        <TitleBox
          option={2}
          title={Parser("힐리어리를 어떻게 알게 되셨나요?")}
        />
        <span className="text-caption1_400">한가지만 선택해 주세요</span>
      </div>
      <ul className="flex flex-col gap-2.5">
        {answers.map((value, idx) => (
          <li
            key={idx}
            onClick={() => handleClick(value)}
            className={`cursor-pointer rounded-2xl border border-gray_200 px-4 ${
              surveyResponse?.selectedNum === value.num ? "py-4" : "py-2"
            } w-full text-center ${
              surveyResponse?.selectedNum === value.num && "bg-gray_180"
            }`}
          >
            <h2
              className={`whitespace-pre-line text-left text-body3_600 ${
                surveyResponse?.selectedNum === value.num
                  ? "text-white"
                  : "text-gray_200"
              }`}
            >
              {value.text}
            </h2>
          </li>
        ))}
        {surveyResponse?.selectedNum === 7 && (
          <>
            <InputBox
              option="common"
              placeholder={"기타 항목을 작성해주세요"}
              onChangeInput={(e) => setValue(e.target.value)}
              value={value}
              setValue={setValue}
              active={"off"}
            />
            <div className="h-64" />
          </>
        )}
      </ul>
    </section>
  );
};

export default SignUpSurvey;
