
const ToggleBtn = ({isOn, onToggle,title}) => {
  return (
    <div className="flex gap-x-[8px] items-center text-caption1_600 text-gray_140 ">
    <p>{title}</p>
      <div className="toggle" onClick={onToggle}>
        <div className={`toggle-container ${isOn ? "toggle--checked" : ""}`} />
        <div className={`toggle-circle ${isOn ? "toggle--checked" : ""}`} />
      </div>
    </div>
  );
};

export default ToggleBtn;
