import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRepliesOfOnePosting } from '../../../../features/admin/communitySlice'
//import  {Viewer}  from '@toast-ui/react-editor';
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";
import '@toast-ui/editor/dist/toastui-editor-viewer.css';

const TestPage = () => {
  const { isInInfoId } = useSelector((state) => state.commu)
  const [newContent, setNewContent] = useState('')
  const dispatch = useDispatch()

  // const viewer = new Viewer({
  //   el: document.querySelector('#viewer'),
  //     height: '600px',
  //     initialValue: newContent
  // });

  useEffect(() => {
    const viewer = new Viewer({
      el: document.querySelector("#viewer")
    });
    const fetchInfo = async () => {
      if (isInInfoId) {
        let param = { postingId: isInInfoId.id, isInfo: true }
        const res = await dispatch(getAllRepliesOfOnePosting(param)).unwrap();
        if (res.ok) {
          setNewContent(res.posting)
          viewer.setMarkdown(res.posting.content)
        }
      }
    }
    fetchInfo();
  }, [getAllRepliesOfOnePosting])
  return (
    <div>
      {newContent?.title}
      {newContent?.content}
      <div id="viewer"></div>
      {/* <Viewer 
      ref={viewerRef}
      initialValue={newContent?.content}/> */}
      {/* <div>{Parser(newContent?.content)}</div> */}
    </div>
  )
}

export default TestPage