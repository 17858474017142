import React, { useEffect, useRef, useState } from "react";
// import TitleBox from "../common/TitleBox";
// import Parser from "html-react-parser";
import InputBox from "../common/InputBox";
import { motion } from "framer-motion";

const questions = [
  "개인정보 노출 우려",
  "접속오류 등 시스템 불편",
  "서비스를 거의 이용하지 않음",
  "식사기록 불편",
  "다이어리 기록 불편",
  "추천식단 불만족",
  "기대했던 앱이 아님",
  "암 환자 아니라서 도움이 되지 않음",
  "그 외 기타",
];

const OutSurvey = ({
  openDialog,
  surveyResponse,
  onSurveyInput,
  onClickEvent,
  className,
  onCoverAction,
  title,
  desc,
  innerClass,
  btnClass,
  leftBtnTitle,
  rightBtnTitle,
  onLeftBtnAction,
  onRightBtnAction,
  subTitle,
}) => {
  const [value, setValue] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const modalRef = useRef();

  //  일정 시간이 지난 후 onSurveyInput 함수를 호출합니다.
  // onSurveyInput 함수는 부모 컴포넌트의 상태 변화나 필요한 동작을 처리
  useEffect(() => {
    const handler = setTimeout(() => {
      onSurveyInput({ selectedAnswer: selectedValues, etc: value });
    }, 300);
    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues, value]);
  console.log(surveyResponse, "surveyResponse");
  // 회원 탈퇴 설문조사 버튼 상태
  useEffect(() => {
    if (selectedValues.includes(questions.length - 1)) {
      // 그 외 기타 선택시 value 입력값 있어야 탈퇴 버튼 활성화
      if (value !== "") {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (surveyResponse?.selectedAnswer.length >= 1) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [surveyResponse]);
  //옵션을 클릭할 때 호출
  const handleClick = (surveyValue, idx) => {
    // 이미 선택된 값에 클릭된 옵션의 인덱스(idx)가 포함되어 있다면 (즉, 이미 선택된 옵션을 다시 클릭한 경우)
    if (selectedValues.includes(idx)) {
      // 선택된 값들(selectedValues)에서 클릭된 옵션의 인덱스(idx)를 제거합니다.
      setSelectedValues((prevValues) =>
        prevValues.filter((value) => value !== idx)
      );
      // 기타 버튼 취소시 내용 입력값 초기화
      setValue("");
    } else {
      // 클릭된 옵션이 선택되지 않은 옵션인 경우
      // 선택된 값들(selectedValues)에 클릭된 옵션의 인덱스(idx)를 추가합니다.
      setSelectedValues((prevValues) => [...prevValues, idx]);
    }
  };

  // Input에 값 입력시 input 박스 닫혀서 주석처리함
  //   useEffect(() => {
  //     if (surveyResponse)
  //       handleClick(questions[questions.length - 1], questions.length - 1);
  //     // eslint-disable-next-line
  //   }, [value]);
  //   max-h-[100%] h-full

  // 기타 내용 입력창 클릭 시 모달 하단으로 이동
  const scrollToBottom = () => {
    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.scrollTop = modalElement.scrollHeight;
    }
  };
  // 모달 화면 크기 변경시 이벤트 실행
  useEffect(() => {
    const handleResize = () => {
      scrollToBottom();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // 더 이상 필요하지 않은 이벤트 리스너를 제거하여 메모리 누수를 방지
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        onClick={onCoverAction}
        className="fixed inset-0 z-30 flex h-screen  w-full max-w-[100%] flex-shrink-0 justify-center bg-black bg-opacity-80 p-4"
      >
        <motion.div
          ref={modalRef}
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeOut", duration: 1 }}
          onClick={(e) => e.stopPropagation()}
          className={
            " h-auto w-auto overflow-scroll rounded-lg bg-white px-4 py-4 shadow-[0_0_12px_rgba(64,64,64,0.2)] " +
            (innerClass ? innerClass : "")
          }
        >
          <div className="flex flex-col gap-2">
            <h1 className="text-subtitle2 text-black">{title}</h1>
            {subTitle && (
              <h2 className="text-caption1_400 text-gray_160">{subTitle}</h2>
            )}
          </div>

          <section>
            <ul className="flex flex-col gap-[12px] py-7">
              {questions.map((value, idx) => (
                <li
                  key={idx}
                  onClick={() => handleClick(value, idx)}
                  className={`cursor-pointer rounded-2xl border border-gray_200 px-4 ${
                    selectedValues.includes(idx) ? "py-3" : "py-2"
                  } w-full text-center ${
                    selectedValues.includes(idx) && "bg-gray_180"
                  }`}
                >
                  <h2
                    className={`whitespace-pre-line text-left text-body3_600 ${
                      selectedValues.includes(idx)
                        ? "text-white"
                        : "text-gray_200"
                    }`}
                  >
                    {value}
                  </h2>
                </li>
              ))}
              {selectedValues.includes(questions.length - 1) && (
                <>
                  <InputBox
                    option="common"
                    onClickAction={scrollToBottom}
                    placeholder={"기타 항목을 작성해주세요"}
                    onChangeInput={(e) => setValue(e.target.value)}
                    value={value}
                    setValue={setValue}
                    active={"off"}
                  />
                </>
              )}
            </ul>
          </section>
          {!onLeftBtnAction ? (
            <div className="text-right">
              <button
                onClick={onRightBtnAction}
                className={
                  "text-btn_700 text-correct " + (btnClass ? btnClass : "")
                }
              >
                {rightBtnTitle || "확인"}
              </button>
            </div>
          ) : (
            <div className="mt-5 flex gap-2">
              <button
                onClick={onLeftBtnAction}
                className="w-1/2 min-w-fit rounded-2xl bg-main p-4 text-body3_700 text-white"
              >
                {leftBtnTitle || "확인"}
              </button>
              <button
                onClick={onRightBtnAction}
                className={`w-1/2 min-w-fit rounded-2xl border border-main p-4 text-body3_700 text-main ${
                  isDisabled
                    ? "cursor-not-allowed text-gray_100 opacity-50"
                    : ""
                }`}
                disabled={isDisabled}
              >
                {rightBtnTitle || "확인"}
              </button>
            </div>
          )}
        </motion.div>
      </div>
    </>
  );
};

export default OutSurvey;
