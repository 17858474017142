import React from 'react';

const Loading = () => {
    return (
        < div className={'fixed flex flex-col gap-6 justify-center items-center h-screen w-full bg-white z-[9]'}>
            <div className="loader ease-linear rounded-full border-[2px] border-t-[2px] border-gray-200 h-8 w-8 " />
            <img className="h-24" src='/images/mobile/character/coach.gif' alt='coach'/>
        </div >
    );
};

export default Loading;