import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkQuestionsComplete,
  fetchMyinfo,
} from "../../../../features/mobile/otherSlice";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import HeadCont from "../../components/common/HeadCont";
import TitleBox from "../../components/common/TitleBox";
import NutritionCard from "../../components/healthInfo/NutritionCard";
import CautionFood from "../../components/healthInfo/CautionFoodCard";
import BodyInfoCard from "../../components/healthInfo/BodyInfoCard";
import FoodLifeCard from "../../components/healthInfo/FoodLifeCard";
import MenuBar from "../../components/common/MenuBar";
import BodyMassCard from "../../components/healthInfo/BodyMassCard";
import AnimateCard from "../../components/common/AnimateCard";
import useGAEvent from "../../hooks/useGAEvent";
const objFalse = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
};
const objTrue = {
  0: true,
  1: true,
  2: true,
  3: true,
  4: true,
};
const HealthInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openAll, setOpenAll] = useState(false);
  const [openStates, setOpenStates] = useState(objFalse);
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { questionsCompleteCheck } = useSelector((state) => state.others);
  const myinfo = useSelector((state) => state.others.userInfo?.user);
  const { sendSingleEvent } = useGAEvent();
  const handleToggle = (id, name) => {
    sendSingleEvent({
      event: "healthinfo_detail",
      label: "healthinfo_button_name",
      value: name,
    });
    // console.log(id, name);
    setOpenStates((prev) => ({ ...prev, [id]: !openStates[id] }));
  };

  useEffect(() => {
    dispatch(checkQuestionsComplete(loginInfo.userId))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          if (!res.healthComplete) {
            navigate(`/question/health`, { replace: true });
          } else {
            dispatch(fetchMyinfo(loginInfo.userId));
          }
        }
      })
      .catch((err) => console.log(err));
  }, [dispatch, loginInfo.userId, navigate]);

  useEffect(() => {
    if (Object.values(openStates).every((value) => value === true)) {
      setOpenAll(true);
    } else setOpenAll(false);
  }, [openStates, openAll]);

  const closedComponent = ({ color, id, title }) => (
    <div
      className={`flex items-center justify-between rounded-lg p-6 ${color} `}
      onClick={() => {
        handleToggle(id, title);
      }}
    >
      <div className="flex items-center gap-1 ">
        <TitleBox option={1} title={title} />
        {title === "신체 및 질병정보" || title === "식습관 정보" ? (
          <div className="px-3 py-1 text-base font-bold bg-white rounded-lg text-gray_140">
            수정하기
          </div>
        ) : null}
      </div>
      <img alt={"arrowRight"} src="/images/mobile/icon/arrow_right.svg" />
    </div>
  );
  return (
    <>
      <HeadCont rightIcon={"no icon"} transparent={"white"} />
      {questionsCompleteCheck &&
      questionsCompleteCheck.healthComplete &&
      myinfo ? (
        <section className="mx-6 mb-6 mt-14">
          <TitleBox option={5} title={`${myinfo.nickName}님의<br />건강정보`} />
          {/* <div className="flex items-center justify-end mb-11"> */}
          <div className="mb-2.5 flex items-center justify-end">
            <span className="text-caption2_400 text-gray_120">
              업데이트: {dayjs().format("YYYY-MM-DD (HH:mm)")}
            </span>
          </div>
          <div
            onClick={() => {
              sendSingleEvent({
                event: "healthinfo_detail",
                label: "healthinfo_button_name",
                value: "모두열기",
              });
              if (Object.values(openStates).every((value) => value === true)) {
                setOpenStates(objFalse);
                setOpenAll(false);
              } else {
                setOpenStates(objTrue);
                setOpenAll(true);
              }
            }}
            className="mb-1.5 flex items-center justify-end gap-1.5"
          >
            <span
              className={`text-caption2_700 ${
                openAll ? "text-gray_120" : "text-gray_170"
              } `}
            >
              {openAll ? "모두닫기" : "모두열기"}
            </span>
            {openAll ? (
              <img
                alt="openAll"
                src="/images/mobile/icon/checkbox_thick_gray.svg"
              />
            ) : (
              <img
                alt="openAll"
                src="/images/mobile/icon/checkbox_thick_dark.svg"
              />
            )}
          </div>

          <div className="space-y-2.5 break-words">
            <AnimateCard
              id={0}
              openStates={openStates}
              closedComponent={closedComponent({
                color: "bg-orange_10",
                id: 0,
                title: "체중관리 가이드",
              })}
              contentComponent={
                <BodyMassCard
                  myinfo={myinfo}
                  loginInfo={loginInfo}
                  handleToggle={() => handleToggle(0, "체중관리 가이드")}
                />
              }
            />
            <AnimateCard
              id={1}
              openStates={openStates}
              closedComponent={closedComponent({
                color: "bg-orange_10",
                id: 1,
                title: "영양 관리 목표",
              })}
              contentComponent={
                <NutritionCard
                  myinfo={myinfo}
                  handleToggle={() => handleToggle(1, "영양 관리 목표")}
                />
              }
            />
            {/* <GuideCard myinfo={myinfo} /> */}
            <AnimateCard
              id={2}
              openStates={openStates}
              closedComponent={closedComponent({
                color: "bg-orange_10",
                id: 2,
                title: "주의식품",
              })}
              contentComponent={
                <CautionFood
                  myinfo={myinfo}
                  handleToggle={() => handleToggle(2, "주의식품")}
                />
              }
            />
            {/* <IntakeCard /> */}
            <AnimateCard
              id={3}
              openStates={openStates}
              closedComponent={closedComponent({
                color: "bg-green_10",
                id: 3,
                title: "신체 및 질병정보",
              })}
              contentComponent={
                <BodyInfoCard
                  myinfo={myinfo}
                  handleToggle={() => handleToggle(3, "신체 및 질병정보")}
                />
              }
            />
            {/*기존 입력폼 대신 링크로 처리(신체정보, 질병정보일부 합침) */}
            <AnimateCard
              id={4}
              openStates={openStates}
              closedComponent={closedComponent({
                color: "bg-yellow_10 ",
                id: 4,
                title: "식습관 정보",
              })}
              contentComponent={
                <FoodLifeCard
                  userDietLife={myinfo?.userDietLife}
                  handleToggle={() => handleToggle(4, "식습관 정보")}
                />
              }
            />
          </div>
        </section>
      ) : (
        ""
      )}
      <span className="h-[115px]"></span>
      <MenuBar />
    </>
  );
};

export default HealthInfo;
