import React from 'react';

const NotFound = () => {

    return <main className='flex items-center justify-center h-screen'>
        <div className='flex flex-col items-start space-y-3 sm:flex-row sm:space-y-0 sm:items-center sm:space-x-3'>
            <p className='font-semibold text-red-600 text-9xl'>404</p>
            <div className='space-y-2'>
                <h1 className='flex items-center space-x-1'>
                    <svg className='w-6 h-6 text-yellow-point' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                    </svg>
                    <span className='text-xl font-medium text-gray-600 sm:text-2xl'>Page not found!</span>
                </h1>
                <p className='text-base font-normal text-gray-600 border-2 rounded-full text-center border-yellow-500'><a href='/sign'>처음으로 돌아가기</a></p>
            </div>
        </div>
    </main>
};

export default NotFound;