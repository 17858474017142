import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onSetSection } from "../../../../features/admin/commonSlice";
import { toggleNavigation } from "../../../../features/admin/toggleSlice";
import { navTitle, navTitle10 } from "../../data/NavTxt";
import NavTitle from "./NavTitle";

const Sidebar = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //navigation 접을때 토글 boolean
  const navBooleen = useSelector((state) => state.toggleAdmin.navActive);
  const toggleHandler = () => {
    dispatch(toggleNavigation(navBooleen));
  };
  const onSetHome = () => {
    dispatch(onSetSection("홈"));
    navigate("/admin");
  };
  const heringsAuth = user.authority === "00" || user.authority === "01";

  return (
    <aside
      className={
        "relative h-full flex-shrink-0 md-block  bg-white border-r" +
        (navBooleen ? " md:w-[100px] " : " md:w-[220px] ")
      }
    >
      <h1
        onClick={onSetHome}
        className="bg-yellow-point relative cursor-pointer h-[60px]"
      >
        <img
          className="absolute top-0 bottom-0 left-0 right-0 m-auto md:h-5 sm:h-4"
          src="/images/admin/icon/힐리어리.svg"
          alt="힐리어리 로고"
        />
      </h1>
      {heringsAuth ? (
        <div className="mx-4 bg-[#efefef] rounded-lg p-4 flex justify-center items-center">
          <span>{user.authority === "00" ? "헤링스 슈퍼" : "헤링스 일반"}</span>
        </div>
      ) : user.hospitalCode !== null ? (
        <div className="mx-4 bg-[#efefef] rounded-lg p-4 flex justify-center items-center flex-col">
          <span>병원코드 </span>
          <span>{user.hospitalCode}</span>
        </div>
      ) : (
        ""
      )}
      {/* side bar nav */}
      <NavTitle NAVTITLE={heringsAuth ? navTitle : navTitle10} user={user} />

      <footer
        className="absolute bottom-0 right-0 p-3 text-xs "
        onClick={toggleHandler}
      >
        <img
          src=" /images/admin/icon/sidebar_arrow.svg"
          alt="폭 줄임 아이콘"
          className="cursor-pointer"
        />
      </footer>
    </aside>
  );
};

export default Sidebar;
