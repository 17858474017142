import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const saveDrugSchedule = createAsyncThunk(
  "drug/saveDrugSchedule", // 복약 스케줄 등록
  async (params) => {
    const res = await api.post(`/schedule/postSchedule`, params);
    return res.data;
  }
);

export const fetchDrugSchedule = createAsyncThunk(
  "drug/fetchDrugSchedule", //복약 스케줄 가져오기
  async (params) => {
    const res = await api.post(`/schedule/getSchedule`, params);
    if (res.data.ok) {
      return res.data;
    }
  }
);

export const fetchDScheduleInfo = createAsyncThunk(
  "drug/fetchDScheduleInfo", //복약 스케줄 정보 가져오기
  async (params) => {
    const res = await api.get(`/schedule/getScheduleInfo/${params}`);
    if (res.data.ok) {
      return res.data;
    }
  }
);

export const modifyDrugSchedule = createAsyncThunk(
  "drug/modifyDrugSchedule", //복약 스케줄 수정
  async (params) => {
    const res = await api.post(`/schedule/modifySchedule`, params);
    if (res.data.ok) {
      return res.data;
    }
  }
);

export const deleteSchedule = createAsyncThunk(
  "drug/deleteSchedule", //스케줄 제거
  async (params) => {
    const res = await api.get(`/schedule/deleteSchedule/${params}`);
    if (res.data.ok) {
      return res.data;
    }
  }
);

export const scheduleCheck = createAsyncThunk(
  "drug/scheduleCheck", //복약 스케줄 예정, 완료 여부
  async (params) => {
    const res = await api.post(`/schedule/checkSchedule`, params);
    if (res.data.ok) {
      return res.data;
    }
  }
);

export const fetchScheduleCalendar = createAsyncThunk(
  "drug/fetchSchedulePoints", //복약 스케줄 캘린더 일정 가져오기
  async (params) => {
    const res = await api.post(`/schedule/getSchedulePoints`, params);
    if (res.data.ok) {
      return res.data;
    }
  }
);

const initialState = {
  existDrugOfYear: [],
  fetchDrugStatus: null,
  dayDrugLists: [],
};

const drugSlice = createSlice({
  name: "복약알림",
  initialState,
  // reducers: {
  // },

  extraReducers: (builder) => {
    builder.addCase(fetchDrugSchedule.pending, (state) => {
      state.fetchDrugStatus = "pending";
    });
    builder.addCase(fetchDrugSchedule.rejected, (state) => {
      state.fetchDrugStatus = "rejected";
    });
    builder.addCase(fetchDrugSchedule.fulfilled, (state, action) => {
      state.fetchDrugStatus = "success";
      state.dayDrugLists = action.payload.list;
    });
    builder.addCase(fetchScheduleCalendar.fulfilled, (state, action) => {
      state.existDrugOfYear = [...action.payload.list];
    });
  },
});
export default drugSlice.reducer;
