import React, { useCallback, useEffect, useState } from "react";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../../components/common/ButtonCustom";
import CommonOption from "../../../components/common/CommonOption";
import PaginationLayout from "../../../components/common/pagination/PaginationLayout";
import SearchBox from "../../../components/common/SearchBox";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePostings,
  onClickInInfoId,
  searchInformation,
} from "../../../../../features/admin/communitySlice";
import {
  handlePageChange,
  onSetSubSection,
} from "../../../../../features/admin/commonSlice";
import { setPageArr } from "../../../../../features/admin/paginationSlice";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../../common/toast";
import InfoMemberList from "../InfoMemberList";
import ContentLayout from "./ContentLayout";
import {
  SearchTargetOption,
  암종,
} from "../../../components/management/SearchTargetOption";

const Information = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const [searchinput, setSearchInput] = useState("");
  const select_list1 = [
    "카테고리 전체",
    "암과 생활",
    "암종별 정보",
    "항암치료 관리",
    "국가 지원제도",
  ];
  const select_list2 = ["작성자", "제목", "내용"];
  const select_list3 = ["전체암"].concat(암종);
  const { pagePerList } = useSelector((state) => state.pagination);
  const { postingList, totalPage } = useSelector((state) => state.commu);
  const allCheckBoxes = document.querySelectorAll('input[type="checkbox"]');
  const [delactive, setDelActive] = useState(false);

  const onChangeInput = (e) => {
    setSearchInput(e.target.value);
  };

  const onClickSearch = useCallback(
    async (pageNum, searchinput) => {
      dispatch(handlePageChange(pageNum));
      const infoSelect1 = document.getElementById("infoSelect1");
      const infoSelect2 = document.getElementById("infoSelect2");
      const infoSelect3 = document.getElementById("infoSelect3");

      let infoSelect1Val =
        infoSelect1?.options[infoSelect1.selectedIndex].value;
      let infoSelect2Val =
        infoSelect2?.options[infoSelect2.selectedIndex].value;
      let infoSelect3Val =
        infoSelect3?.options[infoSelect3.selectedIndex].value;

      let param = {
        page: pageNum,
        countPerPage: pagePerList,
        writerUserId: infoSelect2Val === "작성자" ? searchinput : "",
        title: infoSelect2Val === "제목" ? searchinput : "",
        content: infoSelect2Val === "내용" ? searchinput : "",
        category: infoSelect1Val === "카테고리 전체" ? "" : infoSelect1Val,
        cancerType:
          infoSelect3Val === "전체암" ? "" : SearchTargetOption[infoSelect3Val],
      };

      const res = await dispatch(searchInformation(param)).unwrap();
      if (res.ok) {
        const defaultarr = Array(res.totalPage)
          .fill()
          ?.map((v, i) => {
            return i + 1;
          });
        if (pagePerList >= pageNum) {
          //1~10사이일경우 pagePerList 10
          dispatch(setPageArr(defaultarr.slice(0, pagePerList)));
        } else {
          //11~
          dispatch(
            setPageArr(
              defaultarr.slice(
                (Math.ceil(pageNum / pagePerList) - 1) * pagePerList,
                Math.ceil(pageNum / pagePerList) * pagePerList
              )
            )
          );
        }
      }
    },
    [dispatch, pagePerList]
  );

  const handlePageFn = (clickPage) => {
    dispatch(handlePageChange(clickPage));
    onClickSearch(clickPage, searchinput);
  };
  const toastRepeat = (ment) => {
    toast(<p>{ment}</p>, toastCommonProps("top-right", "toast_alert", 1000));
  };
  const repeatDelRes = async (arr) => {
    const res = await dispatch(deletePostings({ ids: arr })).unwrap();
    try {
      if (res.data.ok) {
        toastRepeat("글이 삭제되었습니다.");
        onClickSearch(1, "");
        for (let i = 0; allCheckBoxes.length > i; i++) {
          allCheckBoxes[i].checked = false;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickDelete = async () => {
    const allCHeckboxChecked =
      document.getElementsByClassName("allcheckbox")[0].checked;
    const eachCheckBox = document.querySelectorAll(".eachCheckbox");
    if (allCHeckboxChecked) {
      //전체글 checkbox check true
      const postingListids = postingList?.map((el) => el.id);
      repeatDelRes(postingListids);
    } else {
      //선택글 checkbox check true
      let selectArr = [];
      for (let i = 0; i < eachCheckBox?.length; i++) {
        if (eachCheckBox[i].checked) {
          selectArr.push(postingList[i].id);
        }
      }
      repeatDelRes(selectArr);
    }
  };

  useEffect(() => {
    if (loginInfo?.adminId) {
      onClickSearch(1, "");
      dispatch(onSetSubSection("정보"));
    } else {
      navigate("/admin/signin");
    }
  }, [dispatch, loginInfo.adminId]);

  return (
    <ContentLayout>
      <div className="flex w-full items-center justify-between pb-6">
        <BGButtonCustom
          onClick={() => {
            navigate("/admin/information/detail");
            dispatch(onClickInInfoId(null));
          }}
          content={"정보글 작성하기"}
          bgcolor={"bg-orange_100"}
          textcolor={"text-gray_10"}
          textsize={"text-caption1_600"}
          xpadding={"12px"}
        />
        <div className="flex h-[48px] flex-row items-start items-center gap-[24px] ">
          <div className="flex gap-x-[8px]">
            <CommonOption
              lists={select_list3}
              ver={1}
              selectid={"infoSelect3"}
              mywidth={160}
            />
            <CommonOption
              lists={select_list1}
              ver={1}
              selectid={"infoSelect1"}
              mywidth={160}
            />
            <CommonOption
              lists={select_list2}
              ver={1}
              selectid={"infoSelect2"}
              mywidth={114}
            />
            <SearchBox
              name={"content"}
              value={searchinput || ""}
              onChange={onChangeInput}
              onClick={() => onClickSearch(1, searchinput)}
              placeholder={"검색내용을 입력하세요."}
              width={296}
            />
          </div>
        </div>
      </div>
      <div className="w-full gap-[16px]">
        <PaginationLayout
          totalPage={totalPage}
          pagePerList={pagePerList}
          onClick={(clickpage) => handlePageFn(clickpage)}
        >
          <InfoMemberList setDelActive={setDelActive} />
        </PaginationLayout>
        <div className="pt-4">
          <BorderButtonCustom
            onClick={onClickDelete}
            content={"선택글 삭제"}
            bgcolor={delactive ? " bg-orange_100 " : " bg-orange_60 "}
            textcolor={delactive ? " text-orange_100 " : " text-orange_60 "}
            bordercolor={
              delactive ? " border-orange_100 " : " border-orange_60 "
            }
            textsize={"text-caption1_600"}
            xpadding={"12px"}
          />
        </div>
      </div>
    </ContentLayout>
  );
};

export default Information;
