import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import {
  fetchRecommendMeal,
  onClickManagementDetailState,
  onClickMember,
  searchManagementUsers,
} from "../../../features/admin/managementSlice";
import { handlePageChange } from "../../../features/admin/commonSlice";
import { setPageArr } from "../../../features/admin/paginationSlice";
/*component*/
import Sidebar from "../components/common/Sidebar";
import Navbar from "../components/common/Navbar";
import RecommendMealDialog from "../components/common/RecommendMealDialog";
import {
  SearchTargetOption,
  나이,
  수술여부,
  암종,
  항암치료여부,
  항암치료종류,
} from "../components/management/SearchTargetOption";
import FilterTab from "../components/management/FilterTab";
import SearchBox from "../components/common/SearchBox";
import { ResetButton } from "../components/common/ButtonCustom";
import MemberList from "../components/graph/MemberList";
import PaginationLayout from "../components/common/pagination/PaginationLayout";

const Management = () => {
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const { page } = useSelector((state) => state.common);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pagePerList } = useSelector((state) => state.pagination);

  //---날짜별 식단보기
  const [selected, setSelected] = useState({
    mealD: null,
    gro: null,
  });
  const [openDialog, setOpenDialog] = useState(false);
  //---filter check
  const filteredText = [
    "가입일 순",
    "나이 많은 순",
    "나이 적은 순",
    // "일반회원",
    // "병원회원",
  ];
  const [filterNamed, setFilteredNamed] = useState("");
  const [filterInfo, setFilterInfo] = useState({
    page: page,
    countPerPage: pagePerList,
    searchNameOrID: "",
    filterCancerType: [],
    filterOperationYN: [],
    filterAntiCancerYN: [],
    filterAntiCancerType: [],
    filterAge: [],
    sortRegistDay: "",
    sortAge: "",
    filterUserType: "",
    loginedAdminID: loginInfo?.adminId,
  });
  const onePageUsers = useSelector((state) => state.management.searchUserList);
  const { totalPage } = useSelector((state) => state.management);

  //---추천식단보기
  const defaultPageShow = useCallback(async () => {
    let params = {
      page: 1,
      countPerPage: pagePerList,
      searchNameOrID: filterInfo.nameUserId,
      filterCancerType: [],
      filterOperationYN: [],
      filterAntiCancerYN: [],
      filterAntiCancerType: [],
      filterAge: [],
      sortRegistDay: "",
      sortAge: "",
      filterUserType: "",
      loginedAdminID: loginInfo?.adminId,
    };
    const res = await dispatch(searchManagementUsers(params)).unwrap();
    if (res.ok) {
      const defaultarr = Array(res.totalPage)
        .fill()
        ?.map((v, i) => {
          return i + 1;
        });
      dispatch(setPageArr(defaultarr.slice(0, pagePerList)));
    }
  }, [dispatch, loginInfo.adminId, searchManagementUsers, setPageArr]);

  useEffect(() => {
    if (loginInfo?.adminId) {
      defaultPageShow();
      dispatch(handlePageChange(1));
    } else {
      navigate("/admin/signin");
    }
  }, [dispatch, loginInfo.adminId]);

  const onClickSearch = useCallback(
    async (pageNum) => {
      dispatch(handlePageChange(pageNum));
      let params = {
        page: pageNum,
        countPerPage: pagePerList,
        searchNameOrID: filterInfo.searchNameOrID,
        filterCancerType: filterInfo.filterCancerType,
        filterOperationYN: filterInfo.filterOperationYN,
        filterAntiCancerYN: filterInfo.filterAntiCancerYN,
        filterAntiCancerType: filterInfo.filterAntiCancerType,
        filterAge: filterInfo.filterAge,
        sortRegistDay: filterInfo.sortRegistDay,
        sortAge: filterInfo.sortAge,
        filterUserType: filterInfo.filterUserType,
        loginedAdminID: loginInfo?.adminId,
      };

      const res = await dispatch(searchManagementUsers(params)).unwrap();
      if (res.ok) {
        const defaultarr = Array(res.totalPage)
          .fill()
          ?.map((v, i) => {
            return i + 1;
          });
        dispatch(
          setPageArr(
            defaultarr.slice(
              (Math.ceil(pageNum / pagePerList) - 1) * pagePerList,
              Math.ceil(pageNum / pagePerList) * pagePerList
            )
          )
        );
      }
    },
    [filterInfo, dispatch, pagePerList, setPageArr]
  );

  const filterKeyAndPush = (
    name,
    checkName,
    isInFilter,
    filterKey,
    searchTarget
  ) => {
    if (name === checkName) {
      if (isInFilter?.length >= 1) {
        filterInfo[filterKey] = filterInfo[filterKey]?.filter(
          (el) => el !== searchTarget
        );
      } else {
        filterInfo[filterKey].push(searchTarget);
      }
    }
  };
  const onClickCheckboxFilter = (e, name) => {
    let searchTarget =
      SearchTargetOption[e.target.parentNode.children[1].innerText];
    let isInCancer = filterInfo.filterCancerType?.filter(
      (el) => el === searchTarget
    );
    let isInOperationYN = filterInfo.filterOperationYN?.filter(
      (el) => el === searchTarget
    );
    let isInAnticancerYN = filterInfo.filterAntiCancerYN?.filter(
      (el) => el === searchTarget
    );
    let isInAnticancerType = filterInfo.filterAntiCancerType?.filter(
      (el) => el === searchTarget
    );
    let isInAgeRange = filterInfo.filterAge?.filter(
      (el) => el === searchTarget
    );

    filterKeyAndPush(
      name,
      "암종",
      isInCancer,
      "filterCancerType",
      searchTarget
    );
    filterKeyAndPush(
      name,
      "수술 여부",
      isInOperationYN,
      "filterOperationYN",
      searchTarget
    );
    filterKeyAndPush(
      name,
      "항암치료 여부",
      isInAnticancerYN,
      "filterAntiCancerYN",
      searchTarget
    );
    filterKeyAndPush(
      name,
      "항암치료 종류",
      isInAnticancerType,
      "filterAntiCancerType",
      searchTarget
    );
    filterKeyAndPush(name, "나이", isInAgeRange, "filterAge", searchTarget);
    onClickSearch(1);
  };

  const filterFn = (type, name) => {
    return (
      <div className="flex flex-wrap items-center justify-start">
        <h2 className="float-right text-caption2_700 text-gray_140">{name}</h2>
        {type?.map((item, idx) => {
          return (
            <div
              key={idx}
              className={`managementCheck mx-2 my-1 flex items-center justify-center text-sm`}
            >
              <input
                type="checkbox"
                id={item}
                className="checkbox"
                onClick={(e) => {
                  onClickCheckboxFilter(e, name);
                }}
              />
              <label
                htmlFor={item}
                className={`relative block h-[36px] w-max cursor-pointer rounded-[20px] bg-gray_20 px-4 pt-2 text-center text-caption1_400 text-sm hover:shadow`}
              >
                {item}
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onChangeNameUserId = (e) => {
    setFilterInfo({ ...filterInfo, searchNameOrID: e.target.value });
  };

  const onMemberDetail = (member, e) => {
    //1.클릭한 tr의 e.target.innerText가 "보기"인 경우만 모달보이기
    if (e.target.innerText === "보기") {
      dispatch(onClickMember(member)); //멤버 userId update
      dispatch(fetchRecommendMeal({ userId: member.userId }));
      // dispatch(fetchEatRecord(params));
      setOpenDialog(true);
    } else {
      //2.management/detail페이지로 넘어가기
      navigate(`/admin/management/detail/${member.name}`);
      dispatch(onClickManagementDetailState(member));
    }
  };

  const onClickFilterDefault = useCallback(async () => {
    defaultPageShow();
    ManagementExceptSearchDefault();
  }, [loginInfo.adminId, dispatch]);

  const ManagementExceptSearchDefault = useCallback(() => {
    setFilterInfo({
      page: 1,
      countPerPage: pagePerList,
      searchNameOrID: "",
      filterCancerType: [],
      filterOperationYN: [],
      filterAntiCancerYN: [],
      filterAntiCancerType: [],
      filterAge: [],
      sortRegistDay: "",
      sortAge: "",
      filterUserType: "",
      loginedAdminID: loginInfo?.adminId,
    });
    //checkbox 초기화
    const managecheckall = document.querySelectorAll(
      ".managementCheck .checkbox"
    );
    for (let i = 0; i < managecheckall?.length; i++) {
      managecheckall[i].checked = false;
    }
    onClickFilterdText("");
  }, []);
  const onClickFilterdText = (param) => {
    setFilteredNamed(param);
    if (param === "가입일 순") {
      filterInfo.filterUserType = "";
      filterInfo.sortAge = "";
      filterInfo.sortRegistDay === "ASC"
        ? (filterInfo.sortRegistDay = "")
        : (filterInfo.sortRegistDay = "ASC");
    } else if (param === "나이 많은 순") {
      filterInfo.filterUserType = "";
      filterInfo.sortRegistDay = "";
      filterInfo.sortAge === "DESC"
        ? (filterInfo.sortAge = "")
        : (filterInfo.sortAge = "DESC");
    } else if (param === "나이 적은 순") {
      filterInfo.filterUserType = "";
      filterInfo.sortRegistDay = "";
      filterInfo.sortAge === "ASC"
        ? (filterInfo.sortAge = "")
        : (filterInfo.sortAge = "ASC");
    } else if (param === "일반회원") {
      filterInfo.sortRegistDay = "";
      filterInfo.sortAge = "";
      filterInfo.filterUserType = "0";
    } else if (param === "병원회원") {
      filterInfo.sortRegistDay = "";
      filterInfo.sortAge = "";
      filterInfo.filterUserType = "1";
    } else {
      filterInfo.sortRegistDay = "";
      filterInfo.sortAge = "";
      filterInfo.filterUserType = "2";
    }
    onClickSearch(1);
  };

  const handlePageFn = (clickPage) => {
    dispatch(handlePageChange(clickPage));
    onClickSearch(clickPage);
  };
  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex flex-col gap-4 p-6">
          <div className="px-6 py-6 shadow-md rounded-3xl bg-gray_10">
            <PaginationLayout
              totalPage={totalPage}
              pagePerList={pagePerList}
              onClick={(clickpage) => handlePageFn(clickpage)}
            >
              {/* 필터(옵션) 선택 */}
              <div className="flex items-end justify-between ">
                <div>
                  <div className="flex flex-wrap pb-2 gap-x-10">
                    {filterFn(암종, "암종")}
                    {filterFn(수술여부, "수술 여부")}
                  </div>
                  <div className="flex flex-wrap pb-2 gap-x-10">
                    {filterFn(항암치료여부, "항암치료 여부")}
                    {filterFn(항암치료종류, "항암치료 종류")}
                  </div>
                  <div className="flex justify-between pb-2">
                    {filterFn(나이, "나이")}
                  </div>
                </div>
                <div className="hidden mt-auto 2xl:block">
                  <ResetButton
                    onClick={onClickFilterDefault}
                    text={"필터 초기화"}
                    custom={"text-caption2_400"}
                  />
                </div>
              </div>
              <div className="flex w-full justify-between gap-x-[16px] py-6 ">
                <ul className="pr-15 caption_1 mr-0 flex w-full items-center divide-x rounded-lg border border-gray_100 p-2.5 text-black 2xl:mr-5 2xl:w-3/4">
                  <FilterTab
                    text={filteredText[0]}
                    filterNamed={filterNamed}
                    onClick={() => onClickFilterdText(filteredText[0])}
                  />
                  <FilterTab
                    text={filteredText[1]}
                    filterNamed={filterNamed}
                    onClick={() => onClickFilterdText(filteredText[1])}
                  />
                  <FilterTab
                    text={filteredText[2]}
                    filterNamed={filterNamed}
                    onClick={() => onClickFilterdText(filteredText[2])}
                  />
                  {loginInfo?.authority === "00" ||
                  loginInfo?.authority === "01" ? (
                    <>
                      <FilterTab
                        text={filteredText[3]}
                        filterNamed={filterNamed}
                        onClick={() => onClickFilterdText(filteredText[3])}
                      />
                      <FilterTab
                        text={filteredText[4]}
                        filterNamed={filterNamed}
                        onClick={() => onClickFilterdText(filteredText[4])}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </ul>
                <SearchBox
                  value={filterInfo.searchNameOrID}
                  onChange={onChangeNameUserId}
                  onClick={() => onClickSearch(1)}
                  placeholder={"이름 또는 닉네임을 검색하세요."}
                  width={296}
                />
              </div>
              <MemberList
                onClickDetail={onMemberDetail}
                filteredUsers={onePageUsers}
                title={"회원목록"}
              />
            </PaginationLayout>
          </div>
        </div>
      </div>
      <AnimatePresence initial={false} mode="wait">
        {openDialog && (
          <RecommendMealDialog
            onCloseDialog={onCloseDialog}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Management;
