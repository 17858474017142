import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import "./slide.css";

// Swiper styles를 임포트합니다.
import "swiper/css/pagination";
import "swiper/css/navigation";

// Swiper에 Pagination과 Navigation 모듈을 사용하도록 설정합니다.
SwiperCore.use([Pagination, Navigation]);

const Slide = ({ selectedRoom, isMobile }) => {
  useEffect(() => {}, [selectedRoom]);

  const indexArr = selectedRoom !== "식사기록" ? [1, 2, 3, 4] : [1, 2, 3, 4, 5];

  return (
    <Swiper
      modules={[Pagination]}
      centeredSlides={true} // 슬라이드 가운데 정렬
      loop={false} // 무한루프 (인덱스 이상함)
      // loopedSlides={4} // 슬라이드 수를 지정해서 루프 사용
      slidesPerView={1} // 한 페이지에 슬라이드 출력 개수
      navigation={true}
      className="landing-swiper flex w-full flex-col pb-20"
      pagination={{
        clickable: true,
        bulletClass: "landing-swiper-bullet",
        bulletActiveClass: "landing-swiper-bullet-active",
        horizontalClass: "landing-swiper-pagination",
      }}
    >
      <div>
        {indexArr.map((roomNum) => (
          <SwiperSlide key={roomNum}>
            <img
              src={`/images/mobile/landing/all-in-one/${selectedRoom}-${roomNum}.png`}
              alt={roomNum}
              className={`mx-auto ${
                isMobile ? "w-52" : "md:w-[260px] lg:w-[349px] xl:w-[380px]"
              }`}
            />
          </SwiperSlide>
        ))}
      </div>
    </Swiper>
  );
};

export default Slide;
