import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TableHeader from "./common/TableHeader";
// import Paging from "./common/Paging";
import dayjs from "dayjs";
import { handlePageChange } from "../../../features/admin/commonSlice";
import PaginationLayout from "../components/common/pagination/PaginationLayout";
import "dayjs/locale/ko";

const OthersDetailBox = ({
  onClickChange,
  dayNo,
  tabName,
}) => {
  const dispatch = useDispatch();
  // const { totalPage } = useSelector((state) => state.management);
  const { totalPage } = useSelector((state) => state.management);
  const { diaryRecord } = useSelector((state) => state.management);
  const {pagePerList7} = useSelector((state)=>state.pagination)

  const headList = ["NO.", "작성일", "내용", "메모"];
  const moodList = [
    "아주 슬퍼요",
    "별로예요",
    "나쁘지 않아요",
    "좋아요",
    "아주 행복해요",
  ];

  const handlePageFn = (clickPage) => {
    dispatch(handlePageChange(clickPage));
    onClickChange(clickPage, dayNo, tabName);
  };

  const diaryReportList = (posts, idx, list) => {
    const tdCss =
      " border-b border-gray_60 text-center py-4 text-caption_1 text-black ";
    const symptomList = [
      `물: ${list.waterGlassCount || "0"}잔`,
      `기분: ${moodList[list.feel - 1] || "기입안함"}`, // * hoho 인덱스 +1씩 밀려서 -1 해줌
      `술: ${list.alchohol || "0"}잔`,
      `담배: ${list.smoke || "0"}개비`,
    ];

    return (
      <>
        <td
          className={
            tdCss +
            " border-l " +
            (posts?.length === 1 + idx ? "rounded-bl-lg" : "")
          }
        >
          {list.no}
          {/* {pagaNumerator(idx, page)} */}
        </td>
        <td className={tdCss}>{dayjs(list.inputDay).format("YYYY-MM-DD")}</td>
        <td className={tdCss + " flex flex-col"}>
          {symptomList?.map((el, indexx) => (
            <span key={indexx} className="text-left">
              {el}
            </span>
          ))}
        </td>
        <td
          className={
            tdCss + (posts?.length === 1 + idx ? " rounded-br-lg" : "-")
          }
        >
          {list.memo || "-"}
        </td>
      </>
    );
  };

  const repeatTr = (lists) => {
    return (
      <>
        {lists?.map((list, idx) => (
          <tr
            key={idx}
            className={
              "px-6 py-3 " +
              (idx % 2 === 1
                ? "bg-gray_95 hover:bg-primary_10"
                : "bg-gray_10 hover:bg-primary_10")
            }
          >
            {diaryReportList(diaryRecord, idx, list)}
          </tr>
        ))}
      </>
    );
  };

  const repeatTable = () => {
    return (
      <table
        className="w-full border border-separate rounded-lg table-auto"
        cellSpacing="0"
      >
        <thead>
          <tr className="bg-gray_60">
            {headList?.map((head) => (
              <th
                key={head}
                className={
                  "p-4 font-semibold text-black border-t first:border-l last:border-r border-gray_60 first:w-0 text-caption_1 first:rounded-tl-lg last:rounded-tr-lg " +
                  (diaryRecord?.length === 0
                    ? "first:rounded-bl-lg last:rounded-br-lg"
                    : "")
                }
              >
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{repeatTr(diaryRecord)}</tbody>
      </table>
    );
  };

  return (
    <div className="mt-4">
      <div className="space-y-4">
        <TableHeader title={"전혀 없던 증상"} />
        {totalPage !== 0 ? (
          <PaginationLayout
            totalPage={totalPage}
            pagePerList={pagePerList7}
            onClick={(clickpage) => handlePageFn(clickpage)}
          >
            {repeatTable()}
          </PaginationLayout>
        ) : (
          <div>다이어리를 아직 작성하지 않았습니다.</div>
        )}
      </div>
    </div>
  );
};

export default OthersDetailBox;
