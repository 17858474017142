import React from "react";
import TitleBox from "../common/TitleBox";
import { useNavigate } from "react-router-dom";
import { HealthInfoButton } from "./HealthInfoButton";

const FoodLifeCard = ({ userDietLife, handleToggle }) => {
  const navigate = useNavigate();
  const datas = [
    { id: 1, title: "음식 섭취 불편", data: "dietInconvenient" },
    { id: 2, title: "식품 알레르기", data: "allergie" },
    { id: 3, title: "유당불내증", data: "lactoseIntolerance" },
    { id: 4, title: "섭취가능 유제품", data: "lactoseIntoleranceEdible" },
    { id: 5, title: "아침식사 여부", data: "breakfastEat" },
    { id: 6, title: "간식 횟수", data: "snackPreferCount" },
    { id: 7, title: "식사 준비 담당", data: "dietPrepare" },
  ];

  const dataMore = [
    { id: 1, title: "식사문제", name: "dietProblem" },
    { id: 2, title: "식사 섭취 속도", name: "dietRate" },
    { id: 3, title: "선호 음식", name: "dietPreferType" },
    {
      id: 4,
      title: "하루 물 섭취량",
      name: "waterGlassCount",
      per1ml: "200ml X ",
    },
    { id: 5, title: "최근 체중 변화", name: "changeWeight", unit: "kg" },
    { id: 6, title: "건강 기능 식품", name: "healthFood" },
    { id: 7, title: "흡연", name: "smoke" },
    { id: 8, title: "음주", name: "alcoholNumber" },
  ];

  //const sliceAllergie = userDietLife['allergie']?.slice(0, 2)
  return (
    <div className="space-y-[28px] rounded-lg bg-yellow_10 p-6" id="food">
      <div onClick={handleToggle} className="flex flex-row justify-between">
        <div className="flex items-center gap-1 ">
          <TitleBox option={1} title={"식습관 정보"} />
          <div className="rounded-lg bg-white px-3 py-1 text-base font-bold text-gray_140">
            수정하기
          </div>
        </div>
        <img
          alt={"arrowRight"}
          src="/images/mobile/icon/arrow_right.svg"
          className="rotate-90 transform"
        />
      </div>
      <div className="space-y-3 border-b border-dashed border-yellow_160 pb-6">
        {datas.map((el) => (
          <ul key={el.id} className="flex justify-between">
            <li className="w-[125px] text-body3_700 text-gray_160">
              {el.title}
            </li>
            <li className="text-right text-body3_700 text-yellow_180">
              {/* {userDietLife[el.data] ?
                                (el.data === 'dietInconvenient' ? ifLength6SLice(userDietLife[el.data]) :
                                    el.data === "allergie" ? userDietLife['allergie'].length === 0 ? '없음' : sliceAllergie.map((li, idx) => <span key={idx}>{li}{idx + 1 === sliceAllergie?.length ? (sliceAllergie?.length === 2 ? "..." : "") : ", "}</span>) :
                                        el.data === "lactoseIntoleranceEdible" ?
                                            userDietLife[el.data]?.map((li, idx) => <div key={idx}>{li}{idx + 1 === userDietLife[el.data]?.length ? "" : ","}</div>) :
                                            userDietLife[el.data]
                                ) : "-"} */}
              {userDietLife[el.data]
                ? el.data === "dietInconvenient"
                  ? userDietLife["dietInconvenient"].slice(0, 3)
                  : el.data === "allergie"
                  ? userDietLife["allergie"].length === 0
                    ? "없음"
                    : userDietLife["allergie"].map((li, idx) => (
                        <span key={idx}>{li} </span>
                      ))
                  : el.data === "lactoseIntoleranceEdible"
                  ? userDietLife[el.data]?.map((li, idx) => (
                      <div key={idx}>
                        {li}
                        {idx + 1 === userDietLife[el.data]?.length ? "" : ","}
                      </div>
                    ))
                  : userDietLife[el.data]
                : "-"}
            </li>
          </ul>
        ))}
        <HealthInfoButton
          onClick={() =>
            navigate("/question/food", { state: { from: "healthInfo" } })
          }
          content={"수정하기"}
          textColor={"text-yellow_160"}
          boxShadow={"0px 0px 5px #DDD3A3"}
        />
      </div>
      <div className="space-y-3">
        <TitleBox option={1} title={"추가 식습관정보"} />
        {dataMore.map((data) => (
          <ul key={data.id} className="flex justify-between text-right">
            <li className="text-body3_700 text-gray_160">{data.title}</li>
            <li className="text-body3_700 text-orange_120">
              {["waterGlassCount"] && userDietLife ? data.per1ml : ""}
              {userDietLife[data.name]
                ? data.name === "dietPreferType"
                  ? userDietLife["dietPreferType"]?.map(
                      (el, idx) =>
                        el +
                        (idx + 1 === userDietLife["dietPreferType"]?.length
                          ? ""
                          : ",")
                    )
                  : data.name === "dietProblem"
                  ? userDietLife["dietProblem"]?.map(
                      (el, idx) =>
                        el +
                        (idx + 1 === userDietLife["dietProblem"]?.length
                          ? ""
                          : ",")
                    )
                  : userDietLife[data.name]
                : "-"}
              {userDietLife[data.name] ? data.unit : ""}
            </li>
          </ul>
        ))}
      </div>
      <HealthInfoButton
        onClick={() =>
          navigate("/question/foodadd", { state: { from: "healthInfo" } })
        }
        content={"수정하기"}
        textColor={"text-yellow_160"}
        boxShadow={"0px 0px 5px #DDD3A3"}
      />
    </div>
  );
};

export default FoodLifeCard;
