import React from 'react';
import Parser from "html-react-parser";

const GuideInner = ({ data }) => {

    return (
        <li className='space-y-2'>
            <h2 className='text-subtitle3 text-black'>{data.title ? Parser(data.title) : ''}</h2>
            {data.type === 1 ?
                data.subGuide.map(one =>
                    <div key={one.type} className='space-y-1'>
                        <h2 className='text-body2_700 text-gray_160'>{one.title ? Parser(one.title) : ''}</h2>
                        <h3 className='text-body2_500l text-gray_160'>{one.content ? Parser(one.content) : ''}</h3>
                    </div>
                )
                : <h2 className='text-body2_500l text-gray_160'>{data.content ? Parser(data.content) : ''}</h2>}

        </li>
    );
};

export default GuideInner;