import React from 'react'

const SignupCheckBox = ({label, onChangeAuthority}) => {
  return (
    <div className="flex box-border items-center py-4 px-5 rounded-lg border-[1px]  border-gray_50 flex-grow signupCheckbox gap-x-1 cursor-pointer"
    onClick={(e)=>onChangeAuthority(e)}
    >
      <input
        name="authority"
        type="checkbox"
        value={label}
        className="authority cursor-pointer"
      />
      <p className="flex items-center order-1 font-semibold text-caption_1 text-gray_50">
        {label}
      </p>

  </div>
  )
}

export default SignupCheckBox