import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAlarmLists,
  setAlarmRead,
  setAlarmReadAll,
  newAlarmCheck,
} from "../../../../features/mobile/otherSlice";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { mobileTypeFn } from "../../components/logic";
import HeadCont from "../../components/common/HeadCont";
import MenuBar from "../../components/common/MenuBar";
import { ReactComponent as More } from "../../../../svg/arrow_right_com.svg";

const Alarm = () => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { alarmLists } = useSelector((state) => state.others);
  const navigate = useNavigate();
  dayjs.locale("ko");

  const onMoveToPage = (type, id, postId, created) => {
    //type 1: 식사기록 type 2: 다이어리 type 3: 나의 건강정보 type4: 추천식단 type5: 식사기록 type6: 앱후기, type7: 나의 건강 정보, type8: 공지사항, 카페 특정id 새글알림(나중에 커뮤니티 개발 후)
    console.log(type, id, postId, created);
    if (type === 1 || type === 5) {
      const timer1 = setTimeout(
        () =>
          navigate(
            `/meal/record`
            // , { state: created }
          ),
        500
      );
      dispatch(setAlarmRead(id));
      return () => clearTimeout(timer1);
    } else if (type === 2) {
      const timer2 = setTimeout(() => navigate(`/diary`), 500);
      dispatch(setAlarmRead(id));
      return () => clearTimeout(timer2);
    } else if (type === 3 || type === 7) {
      const timer3 = setTimeout(() => navigate(`/healthinfo`), 500);
      dispatch(setAlarmRead(id));
      return () => clearTimeout(timer3);
    } else if (type === 4) {
      const timer4 = setTimeout(() => navigate(`/recommend`), 500);
      dispatch(setAlarmRead(id));
      return () => clearTimeout(timer4);
    } else if (type === 6) {
      dispatch(setAlarmRead(id));
      if (mobileTypeFn() === "iphone") {
        //아이폰
        window.open(`https://apps.apple.com/kr/app/id1636424503`, "_blank");
      } else {
        //안드로이드
        window.open(`market://details?id=com.herings.healiary&pli=1`, "_blank");
      }
    } else if (type === 8) {
      const timer5 = setTimeout(() => navigate(`/notice`), 500);
      dispatch(setAlarmRead(id));
      return () => clearTimeout(timer5);
    }
  };

  const onSetAlarmReadAll = () => {
    dispatch(setAlarmReadAll(loginInfo?.userId))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          dispatch(fetchAlarmLists(loginInfo?.userId));
          dispatch(newAlarmCheck(loginInfo?.userId));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    dispatch(fetchAlarmLists(loginInfo.userId));
  }, [dispatch, loginInfo.userId]);

  return (
    <>
      <HeadCont rightIcon={"no icon"} transparent={"white"} />
      <section className="mt-14 mb-24">
        {alarmLists && alarmLists.length > 0 ? (
          <div className="p-4">
            <div className="flex justify-between ml-2 mb-6">
              <h1 className="text-subtitle1 text-gray_180">알림</h1>
              <span
                onClick={onSetAlarmReadAll}
                className="text-caption1_700 text-gray_200 border border-gray_200 rounded-2xl px-4 py-2 active:bg-black active:text-white"
              >
                새 알림 전체 읽음 표시
              </span>
            </div>
            <ul className="flex flex-col gap-2">
              {alarmLists.map((alarm) => (
                <li
                  key={alarm.id}
                  onClick={() =>
                    onMoveToPage(
                      alarm.type,
                      alarm.id,
                      alarm.additionalData,
                      alarm.createdAt
                    )
                  }
                  className="flex w-full justify-between gap-3 items-center py-2 rounded-xl active:bg-orange_10"
                >
                  <img
                    src={
                      alarm.isRead
                        ? "/images/mobile/icon/alarm.svg"
                        : "/images/mobile/icon/alarm_unread.svg"
                    }
                    alt="알람 아이콘"
                  />
                  <div className="space-y-2">
                    <h2 className="text-body2_700 text-black">{alarm.title}</h2>
                    <p className="text-body3_500 text-black">{alarm.content}</p>
                    <p className="text-caption_2_400 text-gray_160 text-right">
                      {dayjs(alarm.createdAt).format(`MM${"월"} DD${"일"}`)}
                    </p>
                  </div>
                  <More fill="#C1C1C1" className="w-6 h-6" />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="-mt-14 h-screen flex justify-center items-center text-body2_700 text-gray_160">
            확인할 알림이 없습니다.
          </div>
        )}
      </section>
      <MenuBar />
    </>
  );
};

export default Alarm;
