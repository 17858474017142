import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

const EditBtn = ({
  onActionUp,
  onActionDown,
  titleUp,
  titleDown,
  location,
  dropdown,
  open,
  setOpen,
  onClickOutside,
  zValue,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        e.target.id !== "three_dot_menu"
      ) {
        onClickOutside();
        onClickOutside && onClickOutside(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, y: 0 }}
        transition={{ ease: "easeOut", duration: 0.2 }}
        ref={ref}
        className={
          zValue +
          " bg-[#FFFFFF] flex flex-col rounded-xl px-5 py-4 " +
          (location === "absolute" ? "absolute " : "fixed ") +
          (dropdown
            ? " right-[24px] mt-[-15px] h-min opacity-100 shadow-[0_0_12px_rgba(0,0,0,0.1)] "
            : "z-20 right-[8px] w-[117px] top-2 border border-gray_40 ")
        }
      >
        {/* <div
          
        > */}
        <div
          onClick={onActionUp}
          className={
            "w-full text-caption1_700 text-black focus:bg-primary_20 focus:outline-none text-center " +
            (dropdown ? "" : "min-w-[60px]")
          }
        >
          {titleUp}
        </div>

        {titleDown && (
          <>
            <div className="w-full h-[1px] my-2 bg-gray_40"></div>
            <div
              onClick={onActionDown}
              className="w-full text-caption1_700 text-black focus:bg-primary_20 focus:outline-none text-center"
            >
              {titleDown}
            </div>
          </>
        )}
        {/* </div> */}
      </motion.div>
    </>
  );
};

export default EditBtn;
