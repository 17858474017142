import React from "react";
import { appDownload, information, sns } from "./SixthPart";

const MobileSixthPart = () => {
  return (
    <>
      <section
        className="flex flex-col gap-3 px-6 py-12"
        style={{
          backgroundImage: "url('/images/mobile/landing/landing_main6-3.png')",
          backgroundSize: "cover",
        }}
      >
        <p className="text-xl text-gray_160">
          <strong className="text-[24px] font-extrabold text-main">
            힐리어리
          </strong>
          와 함께
          <br />
          건강관리의
          <strong className="text-[24px] font-extrabold"> 첫 스텝</strong>을
          <br />
          함께 해보세요.
        </p>
        <button
          onClick={appDownload}
          className="flex w-40 items-center justify-around gap-3 rounded-full bg-main px-4 py-2 text-xs font-bold tracking-wide text-white"
        >
          <img
            src="/images/mobile/landing/download.svg"
            alt="다운로드"
            className="w-4 pb-[1px]"
          />
          <span>힐리어리 시작하기</span>
        </button>
      </section>
      <section
        className="relative flex flex-col items-center justify-center py-72 text-white"
        style={{
          backgroundImage: "url('/images/mobile/landing/landing_main6-2.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <p className="z-10 text-4xl font-bold">Always with you</p>
        <p className="z-10 mt-5 text-xl font-light">
          HERINGS will be always with you
        </p>
        <p className="z-10 text-xl font-light">through your journey</p>
        <button
          onClick={() => window.open("https://www.heringsglobal.com/")}
          className="z-10 mt-5 flex items-center rounded-full border-2 border-white px-7 py-1 text-base font-bold text-white"
        >
          헤링스 홈페이지
        </button>
        <div className="absolute inset-0 h-full w-full bg-black bg-opacity-20"></div>
      </section>

      <footer className="flex flex-col justify-center gap-4 bg-[#222] px-[35px] py-8 text-xs font-light text-white ">
        <div className="flex flex-col gap-6">
          <div className="flex gap-3">
            <a
              href="https://www.heringsglobal.com/privacypolicy/healiary"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              {"Privacy Policy  &  Terms And Conditions"}
            </a>
          </div>
          <div className="flex flex-wrap gap-3 text-gray_100">
            {information.map((v) => (
              <div key={v.title}>
                <span className="mr-1 text-orange_160">{v.title}</span>
                {v.content}
              </div>
            ))}
          </div>

          <div className="flex flex-wrap gap-3">
            {sns.map((value) => (
              <a
                key={value.title}
                className="w-9"
                target="_blank"
                rel="noopener noreferrer"
                href={value.link}
              >
                <img
                  src={`/images/mobile/landing/footer/${value.title}.svg`}
                  alt={value.title}
                  className=""
                />
              </a>
            ))}
          </div>
        </div>

        <div className="text-gray_100">
          Copyright © {new Date().getFullYear()} HERINGS. All rights reserved.
        </div>
      </footer>
    </>
  );
};

export default MobileSixthPart;
