import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveAnswer,
  fetchAnswerList,
  increment,
  incrementByAmount,
  resetI,
} from "../../../../features/mobile/questionFoodSlice";
import { makeRecommendMeal } from "../../../../features/mobile/recommendSlice";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import TitleBox from "../common/TitleBox";
import AnswerCard from "./AnswerCard";
import BtnForQuestion from "../common/BtnForQuestion";
import AnswerCard2 from "./AnswerCard2";
import InputSelectBox from "../common/InputSelectBox";
import { changeStateArray } from "../../../../features/mobile/questionHealthSlice";
import Dialog from "../../components/common/Dialog";

const QuestionForm = ({ onLeftAction, setOpenMessage }) => {
  const dispatch = useDispatch();
  // const bottomRef = useRef(null);
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { questionListsStatus, questionLists, i, gettingAnswers } = useSelector(
    (state) => state.questionFood
  );
  const [checkedItems, setCheckedItems] = useState([]);
  const [otherDietPrepare, setOtherDietPrepare] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const onCheckOne = (one) => {
    if (one === "LE999") {
      setCheckedItems([one]);
    } else {
      if (checkedItems.includes(one)) {
        const arr = checkedItems.filter((el) => el !== one);
        setCheckedItems([...arr]);
      } else {
        if (checkedItems.includes("LE999")) {
          const arr = checkedItems.filter((el) => el !== "LE999");
          setCheckedItems([...arr, one]);
        } else setCheckedItems([...checkedItems, one]);
      }
    }
  };
  const onChangeOtherDietPrepare = (e) => {
    setOtherDietPrepare(e.target.value);
  };

  const saveAnswerUseCallback = useCallback((type, params) => {
    dispatch(saveAnswer(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          dispatch(fetchAnswerList(loginInfo.userId));
          if (type === "dietPrepare" && otherDietPrepare === null) {
            setOtherDietPrepare(null);
          }
        } else
          toast(
            <p>{res.error}</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
      })
      .catch((err) => console.log(err));
  }, []);
  const onSaveAnswer = useCallback((type, answer) => {
    let params = { userId: loginInfo.userId, [type]: answer };
    saveAnswerUseCallback(type, params);
    // eslint-disable-next-line
  }, []);

  const onSaveAnswerAndNext = useCallback((type, answer) => {
    let params = { userId: loginInfo.userId, [type]: answer };
    dispatch(saveAnswer(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          dispatch(fetchAnswerList(loginInfo.userId));
          dispatch(increment());
          if (checkedItems.length > 0) setCheckedItems([]);
        } else
          toast(
            <p>{res.error}</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
      })
      .catch((err) => console.log(err));
  }, []);

  const onSaveAnswer2 = useCallback((params) => {
    dispatch(saveAnswer(params))
      .unwrap()
      .then((res) => {
        if (res.ok) dispatch(fetchAnswerList(loginInfo.userId));
        else
          toast(
            <p>{res.error}</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
      })
      .catch((err) => console.log(err));
  }, []);

  const onMoveToNext = () => {
    //다음 버튼
    setCheckedItems([]);
    if (
      questionLists[i]?.saveUserColumn === "allergieYN" &&
      gettingAnswers.allergieYN === "AY999"
    ) {
      //알레르기 없음시 건너띄기
      dispatch(incrementByAmount(2));
    } else if (questionLists[i]?.saveUserColumn === "allergie") {
      onSaveAnswerAndNext("allergie", checkedItems);
    } else if (
      questionLists[i]?.saveUserColumn === "lactoseIntolerance" &&
      gettingAnswers.lactoseIntolerance === "LI999"
    ) {
      //유당불내증 없음시 건너띄기
      dispatch(incrementByAmount(2));
    } else if (
      questionLists[i]?.saveUserColumn === "lactoseIntoleranceEdible"
    ) {
      onSaveAnswerAndNext("lactoseIntoleranceEdible", checkedItems);
    } else if (questionLists[i]?.saveUserColumn === "dietPrepare") {
      if (otherDietPrepare) {
        //직접 입력시
        onSaveAnswerAndNext("dietPrepare", otherDietPrepare);
      } else if (gettingAnswers.dietPrepare) {
        //기존 입력값 있을때
        dispatch(increment());
      }
    } else {
      dispatch(increment());
    }
  };

  useEffect(() => {
    if (questionLists[i]?.saveUserColumn === "dietPrepare") {
      //식사준비 직접입력시
      if (gettingAnswers.dietPrepare) {
        let params = { userId: loginInfo.userId, ["dietPrepare"]: null };
        onSaveAnswer2(params);
      }
    }
  }, [otherDietPrepare]);

  useEffect(() => {
    if (
      questionLists[i]?.saveUserColumn === "allergie" ||
      questionLists[i]?.saveUserColumn === "lactoseIntoleranceEdible"
    ) {
      if (gettingAnswers[questionLists[i]?.saveUserColumn] !== null) {
        if (gettingAnswers[questionLists[i]?.saveUserColumn].length > 0) {
          setCheckedItems([
            ...gettingAnswers[questionLists[i]?.saveUserColumn],
          ]);
        }
      }
    } else if (
      questionListsStatus === "success" &&
      i >= questionLists?.length
    ) {
      //설문 종료
      dispatch(makeRecommendMeal({ userId: loginInfo.userId })) //설문 완료시 추천식단 새로고침(추천 식단 새로 받기 버튼 안누르는 경우 대비)
        .unwrap()
        .then((res) => {
          if (res.ok) {
            setOpenMessage(true);
            dispatch(resetI());
            dispatch(changeStateArray(0));
          } else {
            setErrorMsg(res.error);
            setOpenDialog(true);
          }
        })
        .catch((err) => console.log(err));
      //dispatch(fetchSurveyResult(loginInfo.userId)) //결과 페이지 정보 불러오기(<Message />위해)
    }
  }, [
    i,
    questionLists[i]?.saveUserColumn,
    questionListsStatus,
    questionLists?.length,
  ]);

  return (
    <>
      {questionListsStatus === "success" && questionLists?.[i] ? (
        // {false ? (
        <>
          <TitleBox
            option={3}
            title={questionLists[i]?.question}
            subTitle={questionLists[i]?.subTitle1}
            subTitle2={questionLists[i]?.subTitle2}
          />
          {questionLists[i]?.saveUserColumn === "allergie" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              <ul className="flex flex-wrap gap-2">
                {questionLists[i]?.answerList.map((list, idx) => (
                  <AnswerCard2
                    key={idx}
                    list={list}
                    onCheckOne={onCheckOne}
                    checkedItems={checkedItems}
                  />
                ))}
              </ul>
            </div>
          ) : questionLists[i]?.saveUserColumn ===
            "lactoseIntoleranceEdible" ? (
            <div className="mb-[100px] mt-8 space-y-4">
              {questionLists[i].answerList.map((list, idx) => (
                <ul key={idx}>
                  <AnswerCard2
                    key={idx}
                    list={list}
                    onCheckOne={onCheckOne}
                    checkedItems={checkedItems}
                  />
                </ul>
              ))}
            </div>
          ) : (
            <div className="mb-[100px] mt-8 space-y-4">
              {questionLists[i]?.answerList.map((answer, idx) => (
                <ul key={idx}>
                  <AnswerCard
                    title={answer.name}
                    onAction={onSaveAnswer}
                    answer={answer.dCode}
                    type={questionLists[i]?.saveUserColumn}
                  />
                </ul>
              ))}
              {questionLists[i]?.saveUserColumn === "dietPrepare" && (
                <>
                  <InputSelectBox
                    label={"직접입력"}
                    nameTitle={"dietPrepare"}
                    placeholder={
                      gettingAnswers.dietPrepare
                        ? !gettingAnswers.dietPrepare?.includes("PP")
                          ? gettingAnswers.dietPrepare
                          : "기타"
                        : "기타"
                    }
                    onChangeAction={onChangeOtherDietPrepare}
                    value={otherDietPrepare}
                    setValue={setOtherDietPrepare}
                    active={otherDietPrepare ? "on" : "ready"}
                    onSaveAnswer={onSaveAnswer}
                  />
                  {/* <div ref={bottomRef} className={'h-[500px]'}></div> */}
                </>
              )}
            </div>
          )}
          <BtnForQuestion
            i={i}
            active={
              questionLists[i]?.saveUserColumn === "allergie"
                ? checkedItems?.length > 0 || gettingAnswers.allergie
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn ===
                  "lactoseIntoleranceEdible"
                ? checkedItems?.length > 0 ||
                  gettingAnswers.lactoseIntoleranceEdible
                  ? "on"
                  : "ready"
                : questionLists[i]?.saveUserColumn === "dietPrepare"
                ? otherDietPrepare || gettingAnswers.dietPrepare
                  ? "on"
                  : "ready"
                : gettingAnswers?.[questionLists[i]?.saveUserColumn]
                ? "on"
                : "ready"
            }
            onAction1={onLeftAction}
            onAction2={onMoveToNext}
          />
        </>
      ) : (
        <div className="flex flex-col items-center justify-center w-full gap-6  mt-44">
          <div className="loader h-8 w-8 rounded-full border-[2px] border-t-[2px] border-gray-200 ease-linear " />
          <img
            className="h-24"
            src="/images/mobile/character/coach.gif"
            alt="coach"
          />
        </div>
      )}
      {openDialog && (
        <Dialog
          innerClass={"py-4 pl-6 pr-5"}
          btnClass={"mt-8"}
          title={"알림"}
          desc={errorMsg}
          onRightBtnAction={() => {
            setOpenDialog(false);
            setOpenMessage(true);
          }}
          onCoverAction={() => {
            setOpenDialog(false);
            setOpenMessage(true);
          }}
        />
      )}
    </>
  );
};

export default QuestionForm;
