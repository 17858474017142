import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { onClickGetRequestDB } from '../../../../features/admin/communitySlice';
import { FoodRequiredHeadList } from '../../components/common/TableHeadList'

const DbRequestList = ({showEachNoticeModal}) => {
  const {foodRegister} = useSelector((state)=>state.commu);
  const dispatch = useDispatch()
  return (
    <table className="w-full table-auto 3xl:col-span-2 col-span-3 border-separate"
    cellSpacing={0}>
      <thead>
        <tr className="bg-gray_60">
          {FoodRequiredHeadList?.map((head, idx) => (
          <th key={idx}
              className={"border-y border-gray_80 p-4 font-semibold text-black first:border-l last:border-r first:rounded-tl-lg last:rounded-tr-lg"+(idx===0?" w-[80px] ":"")}>
              {head}
          </th>
          ))}
        </tr>
      </thead> 
      <tbody>
        {foodRegister?.map((data, idx) => (
          <tr key={idx}
            className={"px-6 py-3 hover:bg-primary_10 " +(idx % 2 === 1 ? "bg-gray_95" : "bg-gray_10")}
            onClick={()=>{
              showEachNoticeModal(data,"음식DB요청")
              dispatch(onClickGetRequestDB(data))
            }}>
            <td className={ "border-b border-l border-gray_80 p-4 text-black text-center " + (idx + 1 === foodRegister?.length ? " rounded-bl-lg" : "")}>
            {data.no}
              </td>
             <td
                className={
                  data.title
                    ? "text-center py-4  text-black px-4 border-gray_80 border-b"
                    : "text-center py-4  text-gray-400 px-4 italic"
                }
              >
                {data.title?.length>50 ?  data.title.slice(0,50)+"..."
                : data.title?.length !== 0 ? data.title
                : "제목 없음"}
              </td>
              <td className="text-center py-4  text-black px-4 border-gray_80 border-b">
                {data.content?.length>40? data.content?.slice(0,40)+"...":data.content}
              </td>
              <td className="text-center py-4  text-black px-4 border-gray_80 border-b">
                {data.files?.length>0?"O":"-"}
              </td>
              <td className="text-center py-4  text-black px-4 border-gray_80 border-b">
              {data.nickName && data.nickNameProtector === data.nickName? 
                <p>{data.nickName}</p>:
                <>
                <p>{data.nickName}</p>
                  {data.nickNameProtector?<p>(보:{data.nickNameProtector})</p>:""}
                </>
              }
              </td>
              <td className={" border-r border-gray_80 p-4 text-black border-b border-gray_80 text-center" +
                (idx + 1 === foodRegister?.length ? " rounded-br-lg" : "")}>
                {data.createdAt.slice(0, 10)}
              </td>
            </tr>
          ))
        }
      </tbody> 
    </table>
  )
}

export default DbRequestList