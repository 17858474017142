import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSetBookmarkFood,
  onSetBookmarkMeal,
  onSetBookmarkRecipe,
} from "../../../../features/mobile/recommendSlice";
// import { onSetBookmarkInfo, onSavePrevPostLists, fetchInfoPostings, onSetPrevBookmark } from '../../../../features/mobile/infoSlice';
import {
  onSetBookmarkInfo,
  onSetPrevBookmark,
  onSetPrevPostList,
} from "../../../../features/mobile/infoSlice";

const BookmarkBtn = ({ option, checkStatus, foodId, title, id }) => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const [isChecked, setIsChecked] = useState(checkStatus);
  // const { searchedValue, prevBookmark, prevTabTitle } = useSelector((state) => state.info)
  const { prevBookmark, prevTabTitle, prevPostList } = useSelector(
    (state) => state.info
  );

  const onCheckToggle = () => {
    if (option === "day") {
      //식단
      let params = { id: foodId, bookmark: !isChecked };
      dispatch(onSetBookmarkMeal(params));
      setIsChecked(!isChecked);
    } else if (option === "food") {
      //음식
      let params = {
        userId: loginInfo.userId,
        foodId: foodId,
        bookmark: !isChecked,
      };
      dispatch(onSetBookmarkFood(params));
      setIsChecked(!isChecked);
    } else if (option === "recipe") {
      //레시피
      let params = {
        userId: loginInfo.userId,
        foodId: foodId,
        bookmark: !isChecked,
      };
      dispatch(onSetBookmarkRecipe(params));
      setIsChecked(!isChecked);
    } else if (option === "info") {
      //정보
      let params = {
        userId: loginInfo.userId,
        infoPostingId: id,
        bookmark: !isChecked,
      };
      dispatch(onSetBookmarkInfo(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            if (prevTabTitle === "북마크") {
              dispatch(
                onSetPrevPostList(
                  prevPostList?.filter((value) => value.id !== id)
                )
              );
              dispatch(
                onSetPrevBookmark(
                  prevBookmark?.filter((value) => value.id !== id)
                )
              );
            } else {
              dispatch(
                onSetPrevBookmark(
                  prevBookmark?.map((value) =>
                    value.id === id ? { ...value, bookmark: !isChecked } : value
                  )
                )
              );
              // let params = { userId: loginInfo.userId, searchText: searchedValue ? searchedValue : undefined, category: prevTabTitle ? prevTabTitle : undefined }
              // dispatch(fetchInfoPostings(params)).unwrap()
              //     .then(res => {
              //         if (res.ok) {
              //             dispatch(onSavePrevPostLists(res.postingList))
              //         }
              //     })
            }
          }
        })
        .catch((err) => console.log(err));
      setIsChecked(!isChecked);
    }
  };

  useEffect(() => {
    setIsChecked(checkStatus);
  }, [checkStatus]);

  return (
    // prettier-ignore
    <>
            {option === 'info' ?
                <div className='w-12 h-12 ml-auto'>
                    <img onClick={onCheckToggle}  className='m-auto ' src={isChecked ? '/images/mobile/icon/bookmark2_marked.svg' : '/images/mobile/icon/bookmark2_unmarked.svg'} alt='북마크 아이콘' />
                </div>
                : <li id={id} onClick={onCheckToggle} className={'flex items-center p-3 rounded-[36px] ' + (isChecked === null ? 'bg-gray_20' : !isChecked ? 'bg-gray_20' : 'bg-main')}>
                    <span className={'text-caption1_60 ' + (isChecked === null ? 'text-gray_160' : !isChecked ? 'text-gray_160' : 'text-white')}>{title}</span>
                </li >
            }
        </>
  );
};

export default BookmarkBtn;
