import React, { useEffect, useRef, useState } from "react";

// 옵션 컴포넌트 (개별 드롭다운 메뉴 아이템)
const Option = ({ option, handleOptionClick }) => (
  <li
    onClick={() => handleOptionClick(option)}
    className="w-full py-3 border-b cursor-pointer border-gray_40 last:border-none hover:bg-gray-200"
  >
    <span className="block text-center">
      {option.name} ({option.count}개)
    </span>
  </li>
);

const CustomDropdown = ({ options, setTargetArea }) => {
  const [isOpen, setIsOpen] = useState(false); // 드롭다운 메뉴의 열림/닫힘 상태
  const [selectedOption, setSelectedOption] = useState(options[0]); // 현재 선택된 옵션
  const dropDownRef = useRef(null);

  useEffect(() => {
    setSelectedOption(options[0]);
    setTargetArea(options[0]?.name);

    // return () => {
    //   second
    // }
  }, [options]);

  useEffect(() => {
    // 드롭다운메뉴 밖을 터치했을때 메뉴 닫기
    const handleOutsideClose = (e) => {
      if (isOpen && !dropDownRef.current.contains(e.target)) setIsOpen(false);
    };
    document.addEventListener("click", handleOutsideClose);

    return () => document.removeEventListener("click", handleOutsideClose);
  }, [isOpen]);

  // 드롭다운 메뉴의 열림/닫힘 상태를 토글
  const toggleDropdown = () => setIsOpen(!isOpen);

  // 옵션이 클릭 됐을 때 실행되는 함수
  const handleOptionClick = (option) => {
    setSelectedOption(option); // 선택된 옵션을 설정
    setTargetArea(option.name);
    setIsOpen(false); // 드롭다운 메뉴를 닫음
  };

  return (
    <div className="flex w-full gap-[14px]">
      <div
        onClick={toggleDropdown}
        className="relative items-start w-1/2 pr-0"
        ref={dropDownRef}
      >
        {/* 선택된 옵션을 표시하는 부분 */}
        <div className="px-auto px-auto relative flex w-full shrink-0 items-center justify-center gap-[14px] rounded-[39px] border border-main bg-main py-[10px]">
          <span className="block mr-2 text-center text-white text-caption1_700">
            {selectedOption?.name} ({selectedOption?.count}개)
          </span>
          <img
            src="/images/mobile/icon/chevron.svg"
            alt=""
            className={`absolute right-5 ${isOpen ? "rotate-180" : null}`}
          />
        </div>

        {/* 드롭다운 메뉴가 열릴 경우, 옵션 목록을 표시 */}
        {isOpen && (
          <ul className="absolute left-0 right-0 flex flex-col items-center h-56 px-5 overflow-y-scroll bg-white shadow top-14 rounded-xl">
            {options.map((option, index) => (
              <Option
                key={index}
                option={option}
                handleOptionClick={handleOptionClick}
              />
            ))}
          </ul>
        )}
      </div>
      <div className="w-1/2"></div>
    </div>
  );
};

export default CustomDropdown;
