import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkQuestionsComplete } from "../../../../features/mobile/otherSlice";
import {
  makeRecommendMeal,
  fetchRecommendMeal,
  fetchFoodDetail,
  onResetRecipePage,
  getBanner,
} from "../../../../features/mobile/recommendSlice";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { pointFn } from "../../components/logic";
import Parser from "html-react-parser";
import HeadCont from "../../components/common/HeadCont";
import MenuBar from "../../components/common/MenuBar";
import RecommendCard from "../../components/recommend/RecommendCard";
import FoodDetailDialog from "../../components/recommend/FoodDetailDialog";
import NutritionGraph from "../../components/common/NutritionGraph";
import DialogCover from "../../components/common/DialogCover";
// import ShareBtn from "../../components/common/ShareBtn";
import IngredientsDialog from "../../components/recommend/IngredientsDialog";
import Dialog from "../../components/common/Dialog";
import { ReactComponent as More } from "../../../../svg/arrow_right_com.svg";
import Loading from "../../components/loading/Loading";
import useGAEvent from "../../hooks/useGAEvent";
import BannerSlide from "../../components/BannerSlide";
// import { getNoticeTop } from "../../../../features/mobile/commSlice";
// import BannerSlide from "../../components/BannerSlide";
// import LikeBtns from '../../components/common/LikeBtns';
// import BookmarkBtn from '../../components/common/BookmarkBtn';
const Recommend = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { recommendMealListsStatus, recommendMealLists, mealComposition } =
    useSelector((state) => state.recommend);
  //const refreshIcon = useMemo(() => ({ background: "url('/images/mobile/icon/refresh.svg') no-repeat center" }), [])
  const [tabNo, setTabNo] = useState(1);
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false); //추천식단 알레르기 문제로 안나올때
  const [openFoodDialog, setOpenFoodDialog] = useState({
    open: false,
    id: null,
    foodId: null,
    mealDate: null,
    mealType: null,
  });
  const [openSummaryDialog, setOpenSummaryDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [banner, setBanner] = useState([]);

  const { sendEvent } = useGAEvent();

  const filtered = recommendMealLists?.[tabNo - 1]?.recommendMealH;
  // const tabs = [
  //   { id: 1, title: "추천식단Ⅰ" },
  //   { id: 2, title: "추천식단II" },
  //   { id: 3, title: "추천식단III" },
  // ];
  const donwBtns = [
    {
      id: 0,
      title: "레시피 찾기",
      bgColor: "bg-yellow_1000",
      linkTo: "/recommend/findrecipe",
      idName: "recipeSearch",
      arrowColor: "#FEFEFE",
    },
    {
      id: 1,
      title: "식사 가이드",
      bgColor: "bg-red_1000",
      linkTo: "/recommend/guide",
      idName: "mealGuide",
      arrowColor: "#FEFEFE",
    },
    {
      id: 2,
      title: "식습관 설문 다시하기",
      bgColor: "bg-green_1000",
      linkTo: "/question/food",
      idName: "reanswerFoodSurvey",
      arrowColor: "#FEFEFE",
    },
  ];
  const arrs = ["breakFast", "lunch", "dinner"];

  // const refreshRecommend = () => { //새로고침 기능 현재 사용 안하지만 추후에 가능
  //     let params = { userId: loginInfo.userId }
  //     dispatch(makeRecommendMeal(params))
  //         .unwrap()
  //         .then(res => { if (res.ok) dispatch(fetchRecommendMeal(params)) })
  // }

  const inner = (data, idx) => {
    return (
      <li key={idx} className="flex gap-2">
        <img src={`/images/mobile/icon/${data}.svg`} alt="식단별 아이콘" />
        <p className="text-black text-body3_700">{mealComposition[data]}</p>
      </li>
    );
  };

  const onSetOneFood = (id, foodId, date, type) => {
    const params = { userId: loginInfo.userId, recommendMealDetailId: id };
    if (id) {
      dispatch(fetchFoodDetail(params));
      setOpenFoodDialog({
        open: true,
        id,
        foodId: foodId,
        mealDate: date,
        mealType: type,
      });
    }
  };

  const nutritionInfo = (title, data, unit) => {
    return (
      <li className="flex-1 py-3 rounded-full bg-gray_20">
        <h2 className="text-caption1_600 text-gray_160">{title}</h2>
        <h2
          className={
            "text-body2_700 " +
            (title === "총 열량" ? "text-orange_180" : "text-green_180")
          }
        >
          {pointFn(data)}
          {unit}
        </h2>
      </li>
    );
  };

  // const ingredientCard = (one) => { //아래 전체재료(주재료, 부재료 컨텐츠)
  //     return (
  //         <li key={one} className='space-y-1'>
  //             <div className='overflow-hidden rounded-xl bg-gray_160'>
  //                 <img src='/images/mobile/image/준비중.svg' alt='재료 이미지' className='w-full' />
  //             </div>
  //             <h3 className='text-caption1_700 text-gray_140'>{one}</h3>
  //         </li>)
  // }

  // const onSetTabNo = (no) => {
  //   setTabNo(no);
  //   // dispatch(fetchRecommendMeal({ userId: loginInfo.userId }));
  // };

  useEffect(() => {
    dispatch(checkQuestionsComplete(loginInfo.userId))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          if (!res.healthComplete) {
            navigate(`/question/health`, { replace: true });
          } else if (!res.dietLifeComplete) {
            navigate(`/question/food`, { replace: true });
          } else {
            window.scrollTo(0, 0);
            dispatch(fetchRecommendMeal({ userId: loginInfo.userId })) //저장된 식단 있건 없건 무조건 부르기 - 식단구성 안내(주의 식품) 변경건 때문에
              .unwrap()
              .then((res) => {
                if (res.ok) {
                  setLoading(true);
                } else {
                  //처음부터 생성된 식단이 없을 경우, 식단 만든 후, 식단 불러오기
                  dispatch(makeRecommendMeal({ userId: loginInfo.userId }))
                    .unwrap()
                    .then((res) => {
                      if (res.ok)
                        dispatch(
                          fetchRecommendMeal({ userId: loginInfo.userId })
                        )
                          .unwrap()
                          .then((res) => {
                            if (res.ok) setLoading(true);
                          });
                      else {
                        setErrorMsg(res.error);
                        setOpenDialog2(true);
                      }
                    })
                    .catch((err) => console.log(err));
                }
              });
          }
        }
      })
      .catch((err) => console.log(err));
  }, [dispatch, loginInfo.userId, navigate]);

  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;
  const todayDay = today.getDate();

  useEffect(() => {
    const tabs = recommendMealLists?.map((list, index) => {
      const date = new Date(list.recommendMealH.mealDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const dateForGa = `${year}${("0" + month).slice(-2)}${("0" + day).slice(
        -2
      )}`;

      if (month === todayMonth && day === todayDay) setTabNo(index + 1);

      return { id: index + 1, title: `${month}월 ${day}일`, date: dateForGa };
    });
    setTabs(tabs);

    // eslint-disable-next-line
  }, [recommendMealLists]);

  useEffect(() => {
    sendEvent({
      event: "recommended_meal_view",
      value: `${todayYear}${("0" + todayMonth).slice(-2)}${(
        "0" + todayDay
      ).slice(-2)}`,
    });

    dispatch(getBanner())
      .unwrap()
      .then((res) => {
        if (res.ok) {
          setBanner(res.bannerList);
        }
      });
  }, []);

  return (
    // prettier-ignore
    <>
      {/* <HeadCont option={'recommend'} rightIcon2={refreshIcon} rightTitle={'식단구성 안내'} transparent={'white'} rightTitleAction={() => setOpenSummaryDialog(true)} rightIcon2Action={refreshRecommend} /> */}
      <HeadCont
        option={"recommend"}
        rightTitle={"식단구성 안내"}
        transparent={"white"}
        rightTitleAction={() => setOpenSummaryDialog(true)}
        onLeftAction={() => navigate("/home")} // ho 설문조사로 돌아가는거 방지
        id={"mealPlanForm"}
        filtered={filtered}
        foodId={filtered?.id}
        loading={loading}
      />
      <section
        className={
          "mt-14 mb-28 " +
          (openSummaryDialog || openFoodDialog.open || openDialog
            ? "no-scroll"
            : "")
        }
      >
        {loading && recommendMealListsStatus === 'success' && tabs.length !== 0 ? 
        (
          <>
            <div className="px-6 border-b border-gray_60">
              <ul className="flex">
                {tabs.map((date) => (
                  <li
                    id={`mealPlan${date}`}
                    key={date.id}
                    className={
                      "pt-2 pb-3 w-1/3 text-center text-body3_700 " +
                      (tabNo === date.id
                        ? "text-gray_180 border-b-[2px] border-black"
                        : "text-gray_100")
                    }
                    onClick={() => {
                      setTabNo(date.id)
                      sendEvent({
                        event: "recommended_meal_view",
                        value: `${date.date}`
                      })
                    }}
                  >
                    {date.title}
                  </li>
                ))}
              </ul>
            </div>
            <div className="pt-4">
              <Swiper
                direction={"horizontal"}
                slidesPerView={1.24}
                spaceBetween={16}
                slideToClickedSlide={true}
                centeredSlides={true}
                slidesOffsetBefore={-5}
                setWrapperSize={true}
                onSlideChange={(e) => 
                sendEvent({
                  event: "slider_swipe",
                  value: e.activeIndex
                })
                }
              >
                {filtered &&
                  filtered.mealGroups.map((list, idx) => (
                    <SwiperSlide key={idx} >
                      <RecommendCard
                        id={`swipeMealPlan${idx + 1}`}
                        list={list}
                        onSetOneFood={onSetOneFood}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
              {
              // filtered && (
              //   <ul className="flex justify-end gap-2 px-6 pt-8">
              //     {/* <LikeBtns option={'day'} checkStatus={filtered?.like} id={filtered.id} />
              //                   <BookmarkBtn option={'day'} checkStatus={filtered?.bookmark} id={filtered.id} title={'즐겨찾기 '} /> */}
              //     <ShareBtn option={"day"} foodId={filtered?.id} />
              //   </ul>
              // )
              }
              
            </div>
            <div className="my-6">
              <BannerSlide bannerList={banner} currentPage={"recommend"} />
            </div>
            {/* <div className="w-full h-[1px] bg-gray_40 my-6"></div> */}
            {filtered && (
              <div className="px-6">
                <h1 className="mb-5 text-black text-body2_700">
                  추천식단 하루 영양 정보
                </h1>
                <ul className="flex justify-between gap-4 text-center">
                  {nutritionInfo("총 열량", filtered.kcal, "kcal")}
                  {nutritionInfo("총 단백질", filtered.proteinG, "g")}
                </ul>
                <NutritionGraph datas={filtered} />
                {/* <p className="mt-4 text-caption2_400 text-gray_160">
                  추천식단대로 드신다면 위와 같이 영양소를 골고루
                  <br />
                  섭취 하실 수 있습니다.
                </p> */}
              </div>
            )}
            {/* 기획팀에서 임시 히든처리 요청: 추후 쇼핑몰과 연결이 가능할  때에 활용할 예정 */}
            {/* <div className='w-full h-[1px] bg-gray_40 my-6'></div>
                        {filtered && <div className='px-6 space-y-5'>
                            <div className='flex justify-between w-full'>
                                <h1 className='text-black text-body2_700'>전체 재료</h1>
                                <div id={'checkIngredient'} onClick={() => setOpenDialog(true)}
                                    className={'w-[99px] flex items-center gap-1 ' + (recommendMealLists[tabNo - 1]?.ingredientMain?.length === 0 && recommendMealLists[tabNo - 1]?.ingredientSub?.length === 0 ? 'hidden' : '')}>
                                    <span className='text-caption1_600 text-gray_160'>한 눈에 보기</span>
                                    <img src='/images/mobile/icon/arrow_right.svg' alt='더보기 아이콘' className='w-4 h-4' />
                                </div>
                            </div>
                            <div>
                                <h2 className='text-caption1_700 text-gray_180'>주재료</h2>
                                <ul className={'flex gap-4 mt-2'}>
                                    {recommendMealLists?.[tabNo - 1]?.ingredientMain?.length > 0 ? recommendMealLists[tabNo - 1]?.ingredientMain.slice(0, 3).map(one => (ingredientCard(one))) : '없음'}
                                </ul>
                            </div>
                            <div>
                                <h2 className='text-caption1_700 text-gray_180'>부재료</h2>
                                <ul className={'flex gap-4 mt-2'}>
                                    {recommendMealLists?.[tabNo - 1]?.ingredientSub?.length > 0 ? recommendMealLists[tabNo - 1]?.ingredientSub.slice(0, 3).map(one => (ingredientCard(one))) : '없음'}
                                </ul>
                            </div>
                        </div>} */}
            <div className="w-full h-[1px] bg-gray_40 my-6"></div>
            <div className="m-6">
              <ul className="space-y-3">
                {donwBtns.map((btn) => (
                  <li
                    id={btn.idName}
                    onClick={() => {
                      if (btn.id === 0 ) {
                        dispatch(onResetRecipePage())
                      }
                      navigate(`${btn.linkTo}`)}}
                    key={btn.id}
                    className={
                      "flex justify-between items-center p-6 rounded-2xl " +
                      btn.bgColor
                    }
                  >
                    <h3 className={`text-[${btn.arrowColor}] text-body1_700`}>{btn.title}</h3>
                    <More fill={btn.arrowColor} className="w-6 h-6" />
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <Loading/>
        )}
      </section>
      {openSummaryDialog && (
        <DialogCover
          onAction={() => setOpenSummaryDialog(false)}
          innerClass={
            "z-100 bg-white rounded-md w-[317px] shadow-[0_0_12px_rgba(0,0,0,0.1)] px-5 py-4 overflow-scroll max-h-[75vh]"
          }
        >
          <h1 className="text-black text-subtitle2">식단구성 안내</h1>
          <h2 className="text-caption2_400 text-gray_160">{`${mealComposition.nickName}님을 위한 추천식단 구성`}</h2>
          {arrs && (
            <>
              <div className="px-4 py-3 mt-4 rounded-xl bg-gray_95">
                <ul className="space-y-4">
                  {arrs.map((arr, idx) => inner(arr, idx))}
                </ul>
              </div>
              <div className="mt-4 ">
                {mealComposition.cautionFood
                  ? Parser(mealComposition.cautionFood)
                  : ""}
              </div>
              {mealComposition.cautionFoodList?.length > 0 && (
                <div className="p-4 mt-4 text-left rounded-md bg-back_ground">
                  {mealComposition.cautionFoodList.map((food, idx) => (
                    <p key={idx} className="text-black text-caption1_700">
                      {idx + 1}. {food}
                    </p>
                  ))}
                </div>
              )}
              <div className="text-right">
                <button
                  onClick={() => setOpenSummaryDialog(false)}
                  className="mt-8 text-btn_700 text-correct"
                >
                  확인
                </button>
              </div>
            </>
          )}
        </DialogCover>
      )}
      
      <FoodDetailDialog
        openFoodDialog={openFoodDialog}
        setOpenFoodDialog={setOpenFoodDialog}
        setLoading={setLoading}
      />
      <IngredientsDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        main={recommendMealLists?.[tabNo - 1]?.ingredientMain}
        sub={recommendMealLists?.[tabNo - 1]?.ingredientSub}
      />
      {openDialog2 && (
        <Dialog
          innerClass={"py-4 pl-6 pr-5"}
          btnClass={"mt-8"}
          title={"알림"}
          desc={errorMsg}
          onRightBtnAction={() => setOpenDialog2(false)}
          onCoverAction={() => setOpenDialog2(false)}
        />
      )}
      <MenuBar />
    </>
  );
};

export default Recommend;
