export const questionTypeOption = {
  diet: "식이",
  exercise: "운동",
  surgery: "수술",
  "searching doctor": "의사 검색",
  "searching caregiver": "간병인 검색",
  "searching nursing home": "요양원 검색",
  "nutrition supplement": "기능성 식품",
  rehabilitation: "재활",
  "side effects": "부작용",
  symptoms: "증상",
  drug: "약제",
  emotion: "감정",
  "cancer statistics": "암관련 통계",
  "disease related": "암",
  greeting: "인사",
  "not related": "기타",
};
