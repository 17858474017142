import React, { useState } from "react";
import { pointFn } from "../logic";
import DetailDialog from "./DetailDialog";

const FoodCard = ({ data, type, setStatus, onSaveAddorEdit, onDelete }) => {
  const [openDetail, setOpenDetail] = useState(false);

  const onOpenDetail = () => {
    setOpenDetail(true);
    setStatus({ option: "show", type: type });
    const body = document.querySelector("body");
    body.classList.add("no-scroll");
  };

  const onCloseDetail = () => {
    setOpenDetail(false);
    const body = document.querySelector("body");
    body.classList.remove("no-scroll");
  };

  return (
    <>
      <div
        onClick={onOpenDetail}
        className="flex items-center justify-between rounded-xl bg-[#E2ECFF] py-2 pl-3 pr-2"
      >
        <h1 className="text-black text-body2_700">
          {data.foodName?.length >= 9
            ? data.foodName.slice(0, 8) + ".."
            : data.foodName}
        </h1>
        <ul className="flex items-center gap-2">
          <li className="text-caption1_600 text-gray_160">
            {data.eyemesurementnumber} {data.eyemesurementunit}
          </li>
          <li className="p-3 text-right text-white rounded-lg bg-main text-caption2_700">
            {pointFn(data.kcal)} kcal
          </li>
        </ul>
      </div>
      <DetailDialog
        openDetail={openDetail}
        onCloseDetail={onCloseDetail}
        data={data}
        onSaveAddorEdit={onSaveAddorEdit}
        onDelete={onDelete}
      />
    </>
  );
};

export default FoodCard;
