import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  bannerClickCount,
  bannerShowCount,
} from "../../../features/mobile/commSlice";
import { mobileTypeFn } from "./logic";
import Announce from "./community/Announce";

const BannerSlide = ({ bannerList, currentPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const mobileType = mobileTypeFn(); // Get the mobile type outside the function
  //ho
  const handleSlideClick = async (swiper, url, value) => {
    // ho 웹, 모바일 환경 체크
    if (mobileType !== "pc") {
      // Mobile android
      if (mobileType === "android" && value.isAppDown === true) {
        window.flutter_inappwebview.callHandler(
          "linkUrl",
          value.androidDownUrl
        );
      }
      // Mobile ios
      else if (mobileType === "iphone" && value.isAppDown === true) {
        window.flutter_inappwebview.callHandler("linkUrl", value.iosDownUrl);
      }
      // 앱 다운로드 이동 광고가 아닐 경우
      else {
        window.flutter_inappwebview.callHandler("linkUrl", url);
      }
    } else {
      // Web
      window.open(url, "_blank");
    }

    if (swiper.target.alt === "isBanner") {
      const arg = {
        bannerId: swiper.target.id,
        userId: loginInfo?.userId,
      };
      await dispatch(bannerClickCount(arg))
        .unwrap()
        // .then((res) => {
        //   if (res.ok) {
        //     console.log(res, "클릭카운트 증가");
        //   } else {
        //     console.log(res, "클릭카운트 실패");
        //   }
        // })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // ho
  const handleSlideChange = async (swiper) => {
    let adjustedIndex = swiper.activeIndex;

    if (bannerList[adjustedIndex]?.isBanner === true) {
      const arg = {
        bannerId: bannerList[adjustedIndex].id,
        userId: loginInfo?.userId,
      };
      await dispatch(bannerShowCount(arg))
        .unwrap()
        // .then((res) => {
        //   if (res.ok) {
        //     console.log(res, "쇼카운트 res");
        //   } else {
        //     console.log("쇼카운트 실패 ");
        //   }
        // })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Swiper
      direction={"horizontal"}
      // slidesPerView={3} 가로로 한번에 출력할 수
      centeredSlides={true} // 슬라이드 가운데 정렬
      loop={false} // 무한루프 (인덱스 이상함)
      loopedSlides={bannerList.length} // 슬라이드 수를 지정해서 루프 사용
      slidesPerView={1} // 한 페이지에 슬라이드 출력 개수
      initialSlide={0} // 초기 슬라이드 지정 값 0번째
      modules={[Autoplay]}
      autoplay={{ delay: 4500, disableOnInteraction: false }}
      setWrapperSize={true} // 사이즈 최대 크기로 통일
      onSwiper={(swiper) => handleSlideChange(swiper)} //ho 첫 슬라이드부터 바로 이벤트 실행
      onSlideChange={(swiper) => handleSlideChange(swiper)}
    >
      {bannerList && bannerList.length > 0 ? ( // 배너 목록이 있고 배너 목록의 길이가 0보다 크면 (즉, 배너가 있는 경우) 회색 배너를 표시합니다.
        bannerList.map((value, idx) => (
          // React.memo를 사용하여 불필요한 렌더링을 방지합니다.
          // eslint-disable-next-line react/jsx-pascal-case
          <React.memo key={value.id}>
            <SwiperSlide key={idx} style={{ height: "auto" }}>
              {value.isBanner === true ? (
                <div className="flex w-full h-full">
                  <img
                    className="w-full h-full"
                    id={value.id}
                    src={value.bannerImagePath}
                    loading="lazy"
                    alt="isBanner"
                    onClick={(swiper) =>
                      handleSlideClick(
                        swiper,
                        value.linkUrl,
                        value
                        // "market://details?id=com.herings.healiary&pli=1"
                      )
                    }
                  />
                </div>
              ) : (
                <Announce
                  idx={idx}
                  content={value?.title}
                  color={"#6B11FF"}
                  fixedcount={
                    bannerList.filter((arr) => arr.isBanner === true).length // 배너 광고 수 카운팅
                  }
                  banner={value.isBanner}
                  onClick={() =>
                    currentPage === "community"
                      ? navigate("/community/" + value.id)
                      : null
                  }
                  noticeBannerImagePath={value.noticeBannerImagePath}
                />
              )}
            </SwiperSlide>
          </React.memo>
        ))
      ) : (
        <div className="w-full h-24 bg-white" />
      )}
    </Swiper>
  );
};

export default BannerSlide;
