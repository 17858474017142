import React from "react";

export const HealthInfoButton = ({
  onClick,
  content,
  textColor,
  boxShadow,
}) => {
  return (
    <div className="flex justify-center ">
      <button
        onClick={onClick}
        className={`text-caption1_700 ${textColor} text-center py-3 px-4 rounded-2xl bg-white `}
        style={{ boxShadow: `${boxShadow}` }}
      >
        {content}
      </button>
    </div>
  );
};
