import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const saveAnswerGpt = createAsyncThunk(
  "cancerGpt/saveAnswerGpt",
  async (params) => {
    const res = await api.post(`/customerCenter/questionChatGPT`, params);
    return res.data;
  }
);

const initialState = {
  answer: "",
  questionStatus: null,
};

const cancerGptSlice = createSlice({
  name: "Gpt 상담",
  initialState,
  reducers: {
    // increment: (state) => {
    //   state.i += 1;
    // },
    // decrement: (state) => {
    //   if (state.i <= 0) {
    //   } else {
    //   }
    // },
  },

  extraReducers: (builder) => {
    builder.addCase(saveAnswerGpt.pending, (state) => {
      state.questionStatus = "pending";
    });

    builder.addCase(saveAnswerGpt.fulfilled, (state, action) => {
      state.questionStatus = "success";
      state.answer = action.payload.answer;
    });

    builder.addCase(saveAnswerGpt.rejected, (state) => {
      state.questionStatus = "rejected";
    });
  },

  // {
  //   // 비동기
  //   [saveAnswerGpt.pending]: (state) => {
  //     state.questionStatus = "pending";
  //   },

  //   [saveAnswerGpt.fulfilled]: (state, action) => {
  //     state.questionStatus = "success";
  //     state.answer = action.payload.answer;
  //   },

  //   [saveAnswerGpt.rejected]: (state) => {
  //     state.questionStatus = "rejected";
  //   },
  // },
});

// export const { } =
//   cancerGptSlice.actions;

export default cancerGptSlice.reducer;
