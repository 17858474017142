import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const IntroCard = ({ statusMsg, mode }) => {
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const path = useLocation().pathname;
  const candidates = ["rabbit_blue", "rabbit_pink"];
  const chosen = Math.floor(Math.random() * candidates.length);
  return (
    <div
      className={
        "flex bg-white p-6 gap-6 " +
        (path === "/mypage" ? "" : "border-b border-gray_40")
      }
    >
      <div
        onClick={() => navigate(`/account/profile`)}
        className="w-[60px] h-[60px] bg-orange_40 relative overflow-hidden rounded-[24px] shrink-0"
      >
        <span
          className="absolute top-[7px] left-[5px] w-[39px] h-[67px] bgCover"
          style={{
            background:
              "url(/images/mobile/character/" +
              `${
                loginInfo?.characterPhoto
                  ? loginInfo?.characterPhoto
                  : candidates[chosen]
              }` +
              ".svg) no-repeat center",
          }}
        ></span>
      </div>
      <div className="flex-1 space-y-2">
        <h1
          onClick={() => navigate(`/account/profile`)}
          className="text-subtitle3 text-black"
        >
          {loginInfo.nickName}
        </h1>
        {statusMsg && mode === "random" ? (
          <div className="text-caption1_600 text-gray_160 overflow-hidden whitespace-nowrap">
            <div className="marquee">{statusMsg}</div>
          </div>
        ) : (
          <h3 className="text-caption1_600 text-gray_160">{statusMsg}</h3>
        )}
      </div>
    </div>
  );
};

export default IntroCard;
