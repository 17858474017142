import React from 'react'

const ModalNotSelect = ({element}) => {
  return (
    <div
    className="w-full bg-[#F3F3F2] border border-gray_90 px-[20px] py-[12px] min-h-[48px] rounded"
    >
      <span>
        {element}
      </span>
    </div>
  )
}

export default ModalNotSelect