import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import TruncateText from "../common/TruncateText";
import { AnimatePresence } from "framer-motion";
import EditBtn from "../common/EditBtn";

const ListCard = ({
  main,
  PostContents,
  onClickPost,
  onDeletePost,
  onEditPost,
  reportDialog,
  setOptionOpen,
  optionOpen,
  cancelReport,
  myPage,
}) => {
  // All the Post--Content related variables
  // prettier-ignore
  const {    
    postId, header, content, thumbnail,
    likeCount, isLiked, replyCount,isWriteReply, isReported,
    writer, createdAt, nickName,
    event, notice, news,
  } = PostContents;
  dayjs.locale("ko");
  const { loginInfo } = useSelector((state) => state.commonApp);
  const moreIcon = useMemo(
    () => ({
      background:
        "url('/images/mobile/icon/vertical_three_dot.svg') no-repeat center",
    }),
    []
  );

  const onClickThreeDots = (id) => {
    if (id === optionOpen.id) setOptionOpen({ open: false, id: id });
    else setOptionOpen({ open: true, id: id });
  };
  const onClickPostInner = useCallback(() => {
    onClickPost(postId);
  }, [postId, onClickPost]);
  const onOpenDialog = () => {
    if (isReported) {
      cancelReport(postId);
      setOptionOpen({ open: false, id: null });
    } else {
      reportDialog.setOpenDialog(true);
      reportDialog.setCurrentUser({ writer: writer, postId: postId });
      setOptionOpen({ open: false, id: null });
    }
  };
  const onBlock = () => {
    reportDialog.setReportStatus("blocking");
    reportDialog.setOpenDialog(true);
    reportDialog.setCurrentUser({ writer: writer, postId: postId });
    setOptionOpen({ open: false, id: null });
  };

  // constant variables
  const kNotice = (
    <span className="block text-caption2_700 text-[#2C71FF] ">공지</span>
  );
  const kEvent = (
    <span className="block text-caption2_700 text-[#5C8EF4] ">이벤트</span>
  );
  const kNews = <span className="text-caption2_700 text-[#5C8EF4] ">소식</span>;

  return (
    <>
      <section>
        <div
          className={`flex flex-col gap-1.5 px-4 py-3 ${
            !writer ? "bg-[#E8EFFF]" : "bg-white"
          } border-b border-gray_60`}
        >
          <div className="flex items-center">
            <figure
              className="mr-1 h-[22px] w-[22px] overflow-hidden rounded-lg bg-[#5C8EF4] p-0.5"
              onClick={onClickPostInner}
            >
              <img
                alt={"character_" + nickName}
                src={`/images/mobile/character/${
                  writer ? writer.characterPhoto : "doctor_to_right"
                }.svg`}
                className="h-7 w-[18px] "
              />
            </figure>
            <div onClick={onClickPostInner} className="flex items-center ">
              <span
                className={
                  "flex shrink-0 gap-[2px] text-sm font-semibold " +
                  (writer?.userId === loginInfo.userId && myPage === undefined
                    ? "text-[#5C8EF4]"
                    : "text-black")
                }
              >
                {/* {writer ? writer.nickName : '힐리어리코치'} */}
                {nickName}
                {!writer && (
                  <img
                    className="my-auto h-4 w-4"
                    alt="adminCheck"
                    src="/images/mobile/icon/check-badge.svg"
                  />
                )}
              </span>
              {myPage === "like" && (
                <TruncateText
                  text={"님의 게시물을 공감합니다."}
                  maxLines={1}
                  // eslint-disable-next-line react/style-prop-object
                  style={"text-caption2_400 text-gray_140 overflow-wrap"}
                />
              )}
            </div>

            {writer ? (
              <div className="z-[3] my-auto ml-auto mr-[2px]">
                <div
                  id="three_dot_menu"
                  className="h-6 w-6"
                  style={moreIcon}
                  onClick={() => onClickThreeDots(postId)}
                />
                <AnimatePresence>
                  {loginInfo?.userId === writer?.userId
                    ? optionOpen.open &&
                      optionOpen.id === postId && (
                        <EditBtn
                          titleUp="게시글 수정"
                          onActionUp={() => {
                            onEditPost();
                            setOptionOpen({ open: false, id: null });
                          }}
                          titleDown="게시글 삭제"
                          onActionDown={() => {
                            onDeletePost(postId);
                            setOptionOpen({ open: false, id: null });
                          }}
                          location="absolute"
                          dropdown={true}
                          open={optionOpen.open}
                          onClickOutside={() => {
                            setOptionOpen({ open: false, id: null });
                          }}
                        />
                      )
                    : optionOpen.open &&
                      optionOpen.id === postId && (
                        <EditBtn
                          titleUp={isReported ? "신고 취소" : "게시글 신고"}
                          onActionUp={() => {
                            onOpenDialog();
                            setOptionOpen({ open: false, id: null });
                          }}
                          titleDown="작성자 차단"
                          onActionDown={() => {
                            onBlock();
                            setOptionOpen({ open: false, id: null });
                          }}
                          location="absolute"
                          dropdown={true}
                          open={optionOpen.open}
                          onClickOutside={() => {
                            setOptionOpen({ open: false, id: null });
                          }}
                        />
                      )}
                </AnimatePresence>
              </div>
            ) : (
              <div className="ml-auto mr-4 flex h-min gap-2">
                {notice && kNotice}
                {event && kEvent}
                {news && kNews}
                {/* {!notice && !event && !news && kNotice} */}
              </div>
            )}
          </div>
          <div
            className="flex items-start justify-between gap-3"
            onClick={onClickPostInner}
          >
            <TruncateText text={header ? header : content} maxLines={2} />
            {thumbnail && thumbnail?.length > 0 ? (
              <figure
                id={"#" + postId}
                className="mr-3 h-[65px] w-[65px] shrink-0 rounded border border-gray_40 bg-gray_15"
                style={{
                  backgroundImage: `url('${thumbnail[0]}')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                {thumbnail?.length > 1 && (
                  <div className="ml-auto mt-[43px] grid h-5 w-5 place-items-center rounded-[3px] bg-black bg-opacity-50 ">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line x1="0" y1="5" x2="10" y2="5" stroke="#FEFEFE" />
                      <line x1="5" y1="0" x2="5" y2="10" stroke="#FEFEFE" />
                    </svg>
                  </div>
                )}
                {/* <img src={thumbnail[0]} alt={'postImg'+postId} className={"shrink-0 min-w-full min-h-full"}/> */}
              </figure>
            ) : (
              <span className="w-3"></span>
            )}
          </div>
          <div className="mr-3 flex justify-between" onClick={onClickPostInner}>
            <span className="mt-1 block w-fit text-xs text-gray_140">
              {dayjs(createdAt).format(`YYYY${"-"}MM${"-"}DD A h:mm`)}
            </span>
            {/* Like and Comment Button */}
            <ul className="flex gap-1">
              <li
                id="comment"
                onClick={main ? onClickPostInner : null}
                className="flex items-center"
              >
                <img
                  className="m-auto h-6 w-6"
                  src={`/images/mobile/icon/comment_orange${
                    isWriteReply ? "_filled" : ""
                  }.svg`}
                  alt="Comment 아이콘"
                />
                <span className="w-4 text-center text-caption2_700 text-yellow_160">
                  {replyCount}
                </span>
              </li>
              <li
                id="like"
                onClick={main ? onClickPostInner : null}
                className="flex items-center"
              >
                <img
                  className="m-auto h-6 w-6"
                  src={`/images/mobile/icon/heart${
                    isLiked ? "_filled" : ""
                  }.svg`}
                  alt="Heart 아이콘"
                />
                <span className="w-4 text-center text-caption2_700 text-orange_160">
                  {likeCount}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default ListCard;
