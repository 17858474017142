import React, { useEffect, useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import DontEditAccountInput from "../../components/account/DontEditAccountInput";
import DaumPostcodeEmbed from "react-daum-postcode";
import { editHospital } from "../../../../features/admin/hospitalSlice";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../components/common/ButtonCustom";
import CustomCenterModal from "../../components/common/CustomCenterModal";
const HospitalEdit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { loginInfo } = useSelector((state) => state.adminLogin);
  useEffect(() => {
    if (loginInfo?.adminId) {
      const editstate = {
        code: state.code, //병원 코드
        name: state.name, //병원 이름
        address: state.address, //주소
        addressDetail: state.addressDetail, //상세 주소
        loginedAdminID: loginInfo?.adminId,
      };
      setHospitalInput(editstate);
    } else {
      navigate("/admin/signin");
    }
  }, []);
  //daum postcode - 주소찾기기능
  const [postCode, setPostCode] = useState(false);
  const [hospitalInput, setHospitalInput] = useState({
    code: "", //병원 코드
    name: "", //병원 이름
    address: "", //주소
    addressDetail: "", //상세 주소
    loginedAdminID: loginInfo?.adminId,
  });

  //onChange함수
  const onChangeAction = (e) => {
    const { name, value } = e.target;
    setHospitalInput({ ...hospitalInput, [name]: value });
  };

  const submitData = async () => {
    if (!(hospitalInput.address === "" || hospitalInput.name === "")) {
      const res = await dispatch(editHospital(hospitalInput)).unwrap();
      if (res.data.ok) {
        toast(
          <p>병원 수정 완료.</p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
        setTimeout(
          () => navigate("/admin/hospitalid", { state: hospitalInput }),
          2000
        );
      } else {
        console.log(res);
      }
    } else {
      toast(
        <p>빈칸을 채우거나 형식에 맞게 작성하세요.</p>,
        toastCommonProps("top-right", "toast_alert", 1000)
      );
    }
  };

  const handleComplete = (data) => {
    setPostCode(false);
    setHospitalInput({ ...hospitalInput, address: data.address });
  };
  const showPostCodeModal = () => {
    setPostCode(!postCode);
  };
  return (
    <>
      {postCode && (
        <CustomCenterModal
          onClickFc={showPostCodeModal}
          customlayout={"w-[400px] rounded-xl bg-white"}
        >
          <div className="relative pb-[20px] pt-[50px]">
            <span
              className="absolute right-3 top-3 block flex h-[28px] w-[28px] items-center justify-center rounded-full bg-[#F3F3F2] "
              onClick={showPostCodeModal}
            >
              <img
                src="/images/admin/icon/close.svg"
                className="cursor-pointer"
              />
            </span>
            <DaumPostcodeEmbed onComplete={handleComplete} />
          </div>
        </CustomCenterModal>
      )}
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex w-auto min-w-min flex-col items-start justify-start gap-[16px] p-[24px]">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="flex h-[112px] w-[882px] items-center justify-between gap-[48px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              <div className="flex h-[64px] items-center justify-between gap-[24px] bg-gray_10">
                <div className="relative flex h-[64px] w-[72px]">
                  <span className="bg-gray-10 flex h-[64px] w-[64px] items-center justify-center rounded-full font-bold">
                    {state.name}
                  </span>
                </div>
                <div className="flex flex-col  justify-center ">
                  <h3 className=" text-xl font-bold text-gray_10">
                    {state.name === null ? "null" : state.name}
                  </h3>
                </div>
              </div>
              <p className="text-sm text-[#05040D]">
                since {dayjs(state.createdAt).format("YYYY-MM-DD")}
              </p>
            </div>

            <div className="mt-[16px] flex w-[882px] flex-col items-center gap-[24px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              {state !== undefined && (
                <div className="flex w-[340px] flex-col gap-[8px]">
                  <DontEditAccountInput
                    labelname="병원 코드"
                    value={state.code}
                  />
                  <div>
                    <label className="text-xs font-normal text-[#463F38]">
                      병원 주소
                    </label>
                    <div className="flex gap-x-[10px]">
                      <input
                        className="w-full gap-[10px] rounded-[4px] border border-[#A39F9B] bg-[#FCFCFC] p-[12px] placeholder:text-sm focus:outline-none "
                        name="adress"
                        placeholder="주소를 입력해 주세요"
                        value={hospitalInput.address}
                        disabled
                      />
                      <span
                        className="flex w-[100px] cursor-pointer items-center  justify-center gap-[10px] rounded-[4px] bg-orange_100 p-[12px] text-xs	font-semibold	text-[#FCFCFC]"
                        onClick={showPostCodeModal}
                      >
                        주소 찾기
                      </span>
                    </div>
                    <input
                      className="mt-[10px] w-full gap-[10px] rounded-[4px] border border-[#A39F9B] bg-[#FCFCFC] p-[12px] placeholder:text-sm focus:outline-none "
                      name="addressDetail"
                      placeholder="상세주소를 입력해 주세요"
                      defaultValue={hospitalInput.addressDetail}
                      onChange={(e) => onChangeAction(e)}
                    />
                  </div>
                </div>
              )}
              <div className="flex w-[340px] gap-[10px]">
                <BorderButtonCustom
                  onClick={() =>
                    navigate("/admin/hospitalid", { state: state })
                  }
                  content={"취소"}
                  bgcolor={""}
                  textcolor={"text-orange_100"}
                  bordercolor={"border-orange_100"}
                  textsize={"text-caption1_600"}
                  width={"30%"}
                />
                <BGButtonCustom
                  onClick={submitData}
                  content={"확인"}
                  bgcolor={"bg-orange_100"}
                  textcolor={"text-gray_10"}
                  textsize={"text-caption1_600"}
                  width={"70%"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HospitalEdit;
