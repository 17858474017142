
import CenterMemberList from "../CenterMemberList";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  handlePageChange,
  onSetSubSection,
} from "../../../../../features/admin/commonSlice";
import { useCallback } from "react";
import { onClickCustomerCenterState, searchCustomerCenter, onSetToggleCommunity} from "../../../../../features/admin/communitySlice";
// import Paging from "../../../components/common/Paging";
import ToggleBtn from "../../../components/ToggleBtn";
import CommonOption from "../../../components/common/CommonOption";
import SearchBox from "../../../components/common/SearchBox";
import PaginationLayout from "../../../components/common/pagination/PaginationLayout";
import { setPageArr } from "../../../../../features/admin/paginationSlice";
import ContentLayout from "./ContentLayout";

const CustomerCenter = () => {
  const navigate = useNavigate();
  const select_list = ["작성자", "제목", "내용"];
  const dispatch = useDispatch();
  const {toggleCommunity,totalPage,questionList} = useSelector((state) => state.commu);
  const [searchinput, setSearchInput] = useState("")
  const {loginInfo} = useSelector((state)=>state.adminLogin)
  const {pagePerList} = useSelector((state)=>state.pagination)

  const onChangeInput = (e) =>{
    setSearchInput(e.target.value)
  }

  const onMemberDetail = (e) => {
   navigate("/admin/customercenter/detail");
   dispatch(onClickCustomerCenterState(e.id))
  };

  const onClickSearch = useCallback(async(pageNum,toggleComm)=>{
    dispatch(handlePageChange(pageNum));
    const centerSelectid = document.getElementById('centerSelect')
    let centerSelectValue = centerSelectid.options[centerSelectid.selectedIndex].value
    let params = {
      page: pageNum,
      countPerPage: pagePerList,
      userId: centerSelectValue==="작성자"?searchinput:"",
      title: centerSelectValue==="제목"?searchinput:"",
      content: centerSelectValue==="내용"?searchinput:"",
      isAwaiting: toggleComm
    };
    const res = await dispatch(searchCustomerCenter(params)).unwrap()
    if(res.ok){
      const defaultarr = Array(res.totalPage).fill()
      ?.map((v, i) => {
        return i + 1;
      });
      if(pagePerList>=pageNum){ //1~10사이일경우 pagePerList 10
        dispatch(setPageArr(defaultarr.slice(0, pagePerList)))
      }else{//11~
        dispatch(setPageArr(defaultarr.slice((Math.ceil(pageNum/pagePerList)-1)*pagePerList , Math.ceil(pageNum/pagePerList)*pagePerList)))
      }
    }
  }, [ dispatch, pagePerList,searchinput]);

  const onToggle = () => {
    const centerSelectid = document.getElementById('centerSelect')
    centerSelectid.value = "작성자"
    setSearchInput("")
    dispatch(onSetToggleCommunity(!toggleCommunity))
    onClickSearch(1,!toggleCommunity)
  };


  const handlePageFn = (clickPage,toggleCommunity) =>{
    dispatch(handlePageChange(clickPage));
    onClickSearch(clickPage,toggleCommunity)
  }

  useEffect(() => {
    if(loginInfo?.adminId){
      onClickSearch(1,false)
      dispatch(onSetSubSection("1:1 이용문의"));
    }else{
      navigate("/admin/signin")
    }
  }, [dispatch,loginInfo.adminId]);
  
  return (
    <ContentLayout>
      <div className="flex flex-row justify-end items-center gap-[24px] w-full h-[48px] mb-6">
        <ToggleBtn isOn={toggleCommunity} onToggle={onToggle} title={"답변 대기만 보기"}/>
        <div className="flex flex-row items-start gap-[8px] w-[369px] h-[48px] ">
          <CommonOption lists={select_list} ver={1} selectid={"centerSelect"} mywidth={114}/>
          <SearchBox
          value={searchinput || ""}
          onChange={onChangeInput}
          onClick={()=>onClickSearch(1,toggleCommunity)}
          placeholder={"검색내용을 입력하세요."}
          width={296}/>
        </div>
      </div>
      <div className="gap-[16px] w-full">
        <PaginationLayout totalPage={totalPage} onClick={(clickpage)=>handlePageFn(clickpage,toggleCommunity)}>
          <CenterMemberList
            onMemberDetail={onMemberDetail}
            filteredUsers={questionList}
            postPerPage={pagePerList}
            toggle={toggleCommunity}
          />
        </PaginationLayout>
      </div>
    </ContentLayout>



  );
};


export default CustomerCenter