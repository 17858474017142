import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Parser from "html-react-parser";
import { pointFn } from "../../components/logic";
import PlusMinusBtn from "./PlusMinusBtn";
import Btn from "../../components/common/Btn";
import GuideForFood from "./GuideForFood";

const SearchDialog = ({
  boxRef,
  boxStatus,
  keyword,
  setValue,
  setBoxStatus,
  openDialog,
  setOpenDialog,
  onSaveAddorEdit,
  noFoodOpen,
  setNoFoodOpen,
  searchedFoodLists,
}) => {
  const navigate = useNavigate();
  const { searchedFoodStatus, recentFoodLists } = useSelector(
    (state) => state.meal
  );
  const [chosenFood, setChosenFood] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [openGuide, setOpenGuide] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnDisabled2, setBtnDisabled2] = useState(false);
  const [i, setI] = useState(0); //배수시 2, 3, 4 ...

  const foodFn = (food, idx, ref = null) => {
    return idx === 0 ? (
      <li
        ref={ref}
        onClick={() => {
          setValue(food.foodnameOrg);
          setBoxStatus("addQty");
          setChosenFood(food);
          setQuantity(food.eyemesurementnumber);
        }}
        key={idx}
        className="inline-block p-3 mb-3 mr-3 text-black rounded-xl bg-gray_20 text-body2_600"
      >
        {food.foodname ? Parser(food.foodname) : null}
      </li>
    ) : (
      <li
        onClick={() => {
          setValue(food.foodnameOrg);
          setBoxStatus("addQty");
          setChosenFood(food);
          setQuantity(food.eyemesurementnumber);
        }}
        key={idx}
        className="inline-block p-3 mb-3 mr-3 text-black rounded-xl bg-gray_20 text-body2_600"
      >
        {food.foodname ? Parser(food.foodname) : null}
      </li>
    );
  };

  const onIncreaseQty = (qty) => {
    setBtnDisabled(false);
    if (qty === 1 || qty === 0.25) {
      if (quantity + Number(qty) > 500) {
        setBtnDisabled2(true);
      } else {
        setBtnDisabled2(false);
        setQuantity(quantity + Number(qty));
      }
    } else {
      if (i <= 100) {
        if (chosenFood.eyemesurementnumber * (2 + i) > 500) {
          setBtnDisabled2(true);
        } else {
          setBtnDisabled2(false);
          setQuantity(chosenFood.eyemesurementnumber * (2 + i));
          setI(i + 1);
        }
      } else if (i === 0) {
        setBtnDisabled2(false);
        setQuantity(chosenFood.eyemesurementnumber);
        setI(i + 1);
      } else {
        setBtnDisabled2(true);
      }
    }
  };

  const onDecreaseQty = (qty) => {
    setBtnDisabled2(false);
    if (qty === 1 || qty === 0.25) {
      if (quantity - Number(qty) <= 0 || quantity - Number(qty) < 0.1) {
        setBtnDisabled(true);
      } else {
        setBtnDisabled(false);
        setQuantity(quantity - Number(qty));
      }
    } else {
      if (quantity / Number(qty) <= 0 || quantity / Number(qty) < 0.1) {
        setBtnDisabled(true);
      } else {
        if (i > 0) {
          setBtnDisabled(false);
          setQuantity(chosenFood.eyemesurementnumber * i);
          setI(i - 1);
        } else {
          setBtnDisabled(false);
          setQuantity(quantity / Number(qty));
        }
      }
    }
  };

  const getTotalLength = (arr) => {
    const margin = 1.5;
    const padding = 3;
    const combinedString = arr.reduce(
      (accumulator, current) =>
        accumulator +
        current.length +
        (current.length - 1) +
        margin * 2 +
        padding * 2,
      0
    );
    return combinedString;
  };

  const calculateSplitPoint = (arr) => {
    const margin = 1.5;
    const padding = 3;
    if (arr.length <= 2) return 1;
    try {
      const strings = arr.map((e) => e.foodnameOrg);
      const midPoint = getTotalLength(strings) / 2;
      let optimalMidPoint = 0;
      for (let [index, item] of strings.entries()) {
        optimalMidPoint +=
          item.length + (item.length - 1) + margin * 2 + padding * 2;
        if (optimalMidPoint >= midPoint) {
          return index + 1;
        }
      }
    } catch (e) {
      return 15;
    }
  };

  //eyemesurementnumber_org 음식의 원본 1회 제공 눈대중량(0.8대접), eyemesurementnumber 사용자가 먹은양, servingsize 1회제공량(200g), servingsize_org 음식 원본 1회 제공량,
  //결과값 최대 500, 최소 0.1
  //정수일때
  //3 이상 일때 +- 1, 3 미만 일때 +-0.25
  //소수점 일때
  //x % 0.25===0 일때 +-0.25
  //x % 0.25!==0 일때 * / 배수 2,3,4,5...
  //0.1 이하 부터 비활성화
  // console.log('chosenFood', chosenFood, 'quantity', quantity)
  // console.log('boxstatus', boxStatus, 'searchedFoodLists', searchedFoodLists)
  //alert(phoneType)
  return (
    <>
      {boxStatus === "search" ? (
        noFoodOpen ? (
          <div
            className={
              "maxW fixed left-0 right-0 z-10 mx-auto rounded-t-[32px] bg-white shadow-[0_0_12px_rgba(0,0,0,0.1)] duration-300 ease-in-out " +
              (openDialog ? "bottom-0 h-[240px]" : "bottom-[-240px] h-[240px]")
            }
          >
            <div className="flex items-center justify-between maxW">
              <h1 className="my-[22px] ml-6 text-body3_700 text-gray_160">
                {keyword ? "검색 결과" : "최근 입력한 음식"}
              </h1>
              <img
                onClick={() => {
                  setOpenDialog(false);
                  setValue(null);
                  setNoFoodOpen(false);
                }}
                src="/images/mobile/icon/close.svg"
                alt="닫기 아이콘"
                className="mr-4"
              />
            </div>
            <div className="mx-6 space-y-[19px]">
              <p className="text-center text-body3_700 text-gray_160">
                '<span className="text-main">{keyword}</span>'에 대한
                <br />
                검색 결과가 없습니다
              </p>
              <Btn
                id={"noFoodReport"}
                textColor={"text-main"}
                borderColor={"border border-main"}
                title={"없는 음식 알려주기"}
                active={"on"}
                onAction={() => navigate(`/meal/request`)}
              />
            </div>
          </div>
        ) : (
          // took out overflow-hidden from the div below
          <div
            className={
              "maxW fixed left-0 right-0 z-10 mx-auto rounded-t-[32px] bg-white shadow-[0_0_12px_rgba(0,0,0,0.1)] duration-300 ease-in-out " +
              (openDialog ? "bottom-0 h-[200px]" : "bottom-[-200px] h-[200px]")
            }
          >
            <div className="flex items-center justify-between maxW">
              <h1 className="my-[22px] ml-6 text-body3_700 text-gray_160">
                {keyword ? "검색 결과" : "최근 입력한 음식"}
              </h1>
              <img
                onClick={() => setOpenDialog(false)}
                src="/images/mobile/icon/close.svg"
                alt="닫기 아이콘"
                className="mr-4"
              />
            </div>
            <div className="overflow-scroll">
              <div
                className={
                  "pl-[19px] " +
                  (keyword &&
                  searchedFoodStatus === "success" &&
                  searchedFoodLists?.length < 1
                    ? ""
                    : "w-max overflow-scroll ")
                }
              >
                <ul className="w-full">
                  {keyword && searchedFoodStatus === "success"
                    ? searchedFoodLists &&
                      searchedFoodLists
                        .slice(0, calculateSplitPoint(searchedFoodLists))
                        .map((food, idx) => foodFn(food, idx, boxRef))
                    : recentFoodLists &&
                      recentFoodLists
                        .slice(0, calculateSplitPoint(recentFoodLists))
                        .map((food, idx) => foodFn(food, idx, boxRef))}
                </ul>
                <ul className="w-full">
                  {keyword && searchedFoodStatus === "success"
                    ? searchedFoodLists &&
                      searchedFoodLists
                        .slice(calculateSplitPoint(searchedFoodLists))
                        .map((food, idx) => foodFn(food, idx))
                    : recentFoodLists &&
                      recentFoodLists
                        .slice(calculateSplitPoint(recentFoodLists))
                        .map((food, idx) => foodFn(food, idx))}
                </ul>

                {/* <ul ref={boxRef}>
															{(keyword && searchedFoodStatus === 'success') ?
																	searchedFoodLists && searchedFoodLists.map((food, idx) => foodFn(food, idx))
																	: recentFoodLists && recentFoodLists.map((food, idx) => foodFn(food, idx))}
													</ul> */}
              </div>
            </div>
          </div>
        )
      ) : boxStatus === "addQty" && chosenFood ? (
        <>
          <div
            className={
              "maxW fixed left-0 right-0 z-10 mx-auto h-[300px] rounded-t-[32px] bg-white p-6 shadow-[0_0_12px_rgba(0,0,0,0.1)] duration-700 ease-in-out " +
              (openDialog ? "bottom-0" : "bottom-[-400px]")
            }
          >
            <div className="flex items-center justify-between overflow-hidden maxW">
              <h1 className="text-black text-subtitle2">{keyword}</h1>
              <img
                onClick={() => {
                  setOpenDialog(false);
                  setValue(null);
                  setBoxStatus("search");
                }}
                src="/images/mobile/icon/close.svg"
                alt="닫기 아이콘"
              />
            </div>
            <div className="mt-3 space-y-2">
              <div className="flex justify-center gap-2">
                <PlusMinusBtn
                  num={quantity}
                  unit={chosenFood.eyemesurementunit}
                  chosenFood={chosenFood}
                  onIncreaseQty={onIncreaseQty}
                  onDecreaseQty={onDecreaseQty}
                  btnDisabled={btnDisabled}
                  btnDisabled2={btnDisabled2}
                />
                <img
                  onClick={() => setOpenGuide(true)}
                  src="/images/mobile/icon/info_circle.svg"
                  alt="정보 아이콘"
                />
              </div>
              <ul className="flex justify-center gap-2 text-caption2_700">
                <li className="text-gray_160">
                  총{" "}
                  {quantity
                    ? pointFn(
                        (
                          (quantity * chosenFood.servingsize_org) /
                          chosenFood.eyemesurementnumber_org
                        ).toFixed(0)
                      )
                    : pointFn(chosenFood.servingsize)}
                  {chosenFood.contentunit.toLowerCase()}
                </li>
                <span className="h-[28px] w-[1px] bg-gray_40"></span>
                <li className="text-orange_180">
                  열량{" "}
                  {quantity
                    ? pointFn(
                        (
                          (quantity * chosenFood.kcal_org) /
                          chosenFood.eyemesurementnumber_org
                        ).toFixed(0)
                      )
                    : pointFn(chosenFood.kcal)}
                  kcal
                </li>
                <span className="h-[28px] w-[1px] bg-gray_40"></span>
                <li className="text-green_160">
                  단백질{" "}
                  {quantity
                    ? pointFn(
                        (
                          (quantity * chosenFood.proteinG_org) /
                          chosenFood.eyemesurementnumber_org
                        ).toFixed(0)
                      )
                    : pointFn(chosenFood.proteinG)}
                  g
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <Btn
                bgColor={"bg-main"}
                textColor={"text-white font-semibold"}
                borderColor={"border border-main"}
                title={"기록 완료"}
                active={"on"}
                onAction={() => {
                  onSaveAddorEdit({
                    eyemesurementnumber: quantity,
                    foodId: chosenFood.id,
                  });
                  setOpenDialog(false);
                }}
              />
            </div>
          </div>
          {<GuideForFood openGuide={openGuide} setOpenGuide={setOpenGuide} />}
        </>
      ) : null}
    </>
  );
};

export default SearchDialog;
