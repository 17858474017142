import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { addAdmin } from "../../../features/admin/adminSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import toastCommonProps from "../../../common/toast";
import CommonSquareBox from "../components/common/CommonSquareBox";
import CommonOption from "../components/common/CommonOption";
import {
  checkBirth,
  checkEmail,
  checkName,
  checkPhone,
  checkPwd,
  checkUserId,
} from "../commonfunc/Reg";
import {
  commonInputcss,
  correctInputRing,
  correctMsgColor,
  errorInputRing,
  errorMsgColor,
  labeltext,
  listheight,
  normalInputRing,
  normalMsgColor,
} from "../data/TailwindCommon";
import LabelSelectLayout from "../components/common/LabelSelectLayout";
import { BGButtonCustom } from "../components/common/ButtonCustom";
import AllSigned from "../components/signup/AllSigned";
import { searchHospitalDrop } from "../../../features/admin/hospitalSlice";
import { dupIdCheck } from "../../../features/admin/accountSlice";
import { AnimatePresence } from "framer-motion";
import CustomCenterModal from "../components/common/CustomCenterModal";
import { LabelAdminInput } from "../components/common/LabelAdminInput";

const AdminSighUp2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authority = useLocation().state;
  //중복되지 않은 아이디
  const [nodupId, setNoDupId] = useState(false);
  //가입신청완료모달
  const [confirmModal, setConfirmModal] = useState(false);
  //select list
  const genderList = ["선택", "남자", "여자"];
  const divisionList = ["선택", "의사", "간호사", "영양사"];
  //병원코드리스트, 병원이름리스트
  const [hospitalList, setHospitalList] = useState([]);
  const [hospitalNames, setHospitalNames] = useState([]);
  //전체 동의하기 toggle
  const [allTog, setAllTog] = useState(false);
  const [newInput, setNewInput] = useState({
    adminId: "",
    name: "",
    pass: "",
    repass: "",
    phoneNumber: "",
    gender: "",
    email: "",
    birth: "",
    hospitalCode: "",
    authority: "",
    division: "",
    serviceAgree: true,
  });
  //input 빈칸check
  const checkInputStringNone =
    newInput.adminId === "" ||
    newInput.name === "" ||
    newInput.pass === "" ||
    newInput.phoneNumber === "" ||
    newInput.gender === "" ||
    newInput.email === "" ||
    newInput.birth === "" ||
    newInput.repass === "" ||
    newInput.pass === "" ||
    newInput.hospitalCode === "" ||
    authority === "" ||
    newInput.division === "" ||
    !allTog ||
    !nodupId;
  const ifCheckInputStringNoneIsHerings =
    newInput.adminId === "" ||
    newInput.name === "" ||
    newInput.pass === "" ||
    newInput.phoneNumber === "" ||
    newInput.gender === "" ||
    newInput.email === "" ||
    newInput.birth === "" ||
    newInput.repass === "" ||
    newInput.pass === "" ||
    authority === "" ||
    !allTog ||
    !nodupId;

  useEffect(() => {
    //hospitalList 받기
    const fetchHosRes = async () => {
      let hosRes = await dispatch(searchHospitalDrop()).unwrap();
      if (hosRes.data.ok) {
        try {
          const newList = [];
          const newNames = [];
          for (let i = 0; i < hosRes.data.hospitalList?.length; i++) {
            newList.push({
              name: hosRes.data.hospitalList[i].name,
              code: hosRes.data.hospitalList[i].code,
            });
            newNames.push(hosRes.data.hospitalList[i].name);
          }
          const pushDefault = ["선택"].concat(newNames);
          setHospitalList(newList);
          setHospitalNames(pushDefault);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(hosRes.data.ok, ">>>hosRes");
      }
    };
    fetchHosRes();
  }, []);

  const onChangeAction = (e) => {
    const { name, value } = e.target;
    setNewInput({ ...newInput, [name]: value });
  };

  const SubmitData = async () => {
    //1. 빈칸 x
    if (!checkInputStringNone || !ifCheckInputStringNoneIsHerings) {
      //2. 입력값 형식 check
      if (
        !(
          !checkName(newInput.name) ||
          !checkPwd(newInput.pass) ||
          !checkPhone(newInput.phoneNumber) ||
          !checkEmail(newInput.email) ||
          !checkBirth(newInput.birth) ||
          !(checkPwd(newInput.repass) && newInput.pass === newInput.repass)
        )
      ) {
        const filterHospitalCode = hospitalList?.filter(
          (el) => el.name === newInput.hospitalCode
        );
        const filterAuthority =
          authority === "병원 수퍼관리자"
            ? "10"
            : authority === "병원 일반관리자"
            ? "11"
            : "01";
        const filterDivision =
          newInput.division === "의사"
            ? "1"
            : newInput.division === "간호사"
            ? "2"
            : "3";
        const filterGender = newInput.gender === "남자" ? "M" : "F";
        const param =
          authority === "힐리어리 일반관리자"
            ? {
                adminId: newInput.adminId,
                name: newInput.name,
                pass: newInput.pass,
                phoneNumber: newInput.phoneNumber,
                gender: filterGender,
                email: newInput.email,
                birth: newInput.birth,
                authority: filterAuthority,
                serviceAgree: true,
              }
            : {
                adminId: newInput.adminId,
                name: newInput.name,
                pass: newInput.pass,
                phoneNumber: newInput.phoneNumber,
                gender: filterGender,
                email: newInput.email,
                birth: newInput.birth,
                hospitalCode: filterHospitalCode[0].code,
                authority: filterAuthority,
                division: filterDivision,
                serviceAgree: true,
              };
        const res = await dispatch(addAdmin(param)).unwrap();
        try {
          if (res.data.ok) {
            setConfirmModal(true);
          } else {
            toast(
              <p className="whitespace-pre-line">{res.data.error}</p>,
              toastCommonProps("top-right", "toast_alert", 1000)
            );
          }
        } catch (error) {
          console.log(res);
        }
      } else {
        toast(
          <p className="whitespace-pre-line">입력 형식을 확인해주세요.</p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
      }
    }
  };
  //모달
  const goSignin = () => {
    navigate("/admin/signin");
  };

  const goPrev = () => navigate(-1);

  //중복확인
  const onClickOvelap = async () => {
    const res = await dispatch(dupIdCheck(newInput.adminId)).unwrap();
    try {
      if (res.data.ok) {
        toast(
          <p>사용 가능한 아이디입니다.</p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
        setNoDupId(true);
      } else {
        toast(
          <p>중복된 아이디입니다.</p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
        setNoDupId(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AnimatePresence initial={false} mode="wait">
        {confirmModal ? (
          <CustomCenterModal
            onClickFc={goSignin}
            customlayout={
              "p-[32px] rounded-md bg-gray_10 overflow-auto p-2 text-caption_1"
            }
          >
            <span className="text-xl font-bold">
              가입 신청이 완료되었습니다.
            </span>
            <div className="flex flex-col gap-y-2 pb-6 pt-4">
              <p>- 가입 승인이 완료되면 이용이 가능합니다.</p>
              <p>- 가입이 승인되면 이메일로 안내해 드립니다.</p>
              <p>- 일반적으로 영업일 기준 1-2일이 소요될 수 있습니다.</p>
              <p>
                - 문의 사항은{" "}
                <a
                  href="mailto:help@herings.com"
                  className="text-[#5786FF] underline"
                >
                  help@herings.com
                </a>
                으로 문의해 주세요.
              </p>
              <p>감사합니다.</p>
            </div>
            <BGButtonCustom
              onClick={goSignin}
              content={"확인"}
              bgcolor={"bg-gray_70"}
              textcolor={"text-white"}
              textsize={"text-caption1_600"}
            />
          </CustomCenterModal>
        ) : (
          ""
        )}
      </AnimatePresence>
      <div className="flex h-full w-full flex-col items-center justify-center gap-y-[24px]">
        <CommonSquareBox>
          <div className="flex w-[800px] flex-col items-center justify-center gap-y-[48px]">
            <div className="flex flex-col items-center gap-4 ">
              <img
                src="/images/admin/icon/관리자 회원가입 신청.svg"
                alt="관리자 회원가입 신청 이미지"
                className="items-center"
              />
              <p className="text-body_1 font-normal tracking-[0.02em]  text-gray_100 ">
                Step 2. 소속 기관 및 개인 정보를 입력해주세요.
              </p>
            </div>
            {authority !== "힐리어리 일반관리자" && (
              <div className="flex flex-col items-start gap-y-6">
                <LabelSelectLayout title={"기관명"} mywidth={436}>
                  <CommonOption
                    ver={2}
                    lists={hospitalNames}
                    selectid={"hospitalCode"}
                    onChangeFunction={onChangeAction}
                  />
                </LabelSelectLayout>
                <LabelSelectLayout title={"구분"} mywidth={436}>
                  <CommonOption
                    ver={2}
                    lists={divisionList}
                    selectid={"division"}
                    onChangeFunction={onChangeAction}
                  />
                </LabelSelectLayout>
              </div>
            )}
          </div>
        </CommonSquareBox>
        <CommonSquareBox>
          <div
            className={
              authority === "힐리어리 일반관리자"
                ? "flex h-[500px] w-[800px]  flex-col items-center overflow-y-auto"
                : "flex h-[300px] w-[800px]  flex-col items-center overflow-y-auto"
            }
          >
            <div className="flex flex-col gap-y-6">
              <div className={listheight}>
                <label className={labeltext}>아이디</label>
                <div className="relative flex gap-x-4">
                  <input
                    name="adminId"
                    placeholder="아이디"
                    onChange={onChangeAction}
                    className={
                      newInput.adminId === "" || checkUserId(newInput.adminId)
                        ? "w-full" + normalInputRing
                        : "w-full" + errorInputRing
                    }
                  />
                  <span
                    className={`${commonInputcss} cursor-pointer bg-orange_100`}
                    onClick={onClickOvelap}
                  >
                    중복확인
                  </span>
                </div>
                <p
                  className={
                    !(newInput.adminId === "" || checkUserId(newInput.adminId))
                      ? errorMsgColor
                      : normalMsgColor
                  }
                >
                  {!(newInput.adminId === "" || checkUserId(newInput.adminId))
                    ? "아이디 형식을 확인해주세요."
                    : "6-12자리 영문, 숫자만 사용가능합니다."}
                </p>
              </div>
              <LabelAdminInput
                title={"비밀번호"}
                name={"pass"}
                type={"password"}
                value={newInput.pass}
                placeholderVal={"비밀번호를 입력해주세요"}
                inputcss={
                  newInput.pass === ""
                    ? "w-full" + normalInputRing
                    : checkPwd(newInput.pass)
                    ? "w-full" + correctInputRing
                    : "w-full" + errorInputRing
                }
                onChangeAction={(e) => onChangeAction(e)}
                onKeyPressAction={SubmitData}
                onetimeuse={true}
                checkCss={
                  newInput.pass === ""
                    ? normalMsgColor
                    : checkPwd(newInput.pass)
                    ? correctMsgColor
                    : errorMsgColor
                }
                checkVal={
                  checkPwd(newInput.pass)
                    ? "안전한 비밀번호 입니다"
                    : "숫자, 문자, 특수문자 포함 8~12자리로 입력해주세요"
                }
              />
              <LabelAdminInput
                title={"비밀번호 확인"}
                name={"repass"}
                type={"password"}
                value={newInput.repass}
                placeholderVal={"새 비밀번호를 다시 입력해주세요"}
                inputcss={
                  newInput.repass === ""
                    ? "w-full" + normalInputRing
                    : checkPwd(newInput.repass) &&
                      newInput.pass === newInput.repass
                    ? "w-full" + correctInputRing
                    : "w-full" + errorInputRing
                }
                onChangeAction={(e) => onChangeAction(e)}
                onKeyPressAction={SubmitData}
                checkCss={
                  newInput.repass === ""
                    ? normalMsgColor
                    : checkPwd(newInput.repass) &&
                      newInput.pass === newInput.repass
                    ? correctMsgColor
                    : errorMsgColor
                }
                checkVal={
                  newInput.repass === ""
                    ? ""
                    : checkPwd(newInput.repass) &&
                      newInput.pass === newInput.repass
                    ? "비밀번호가 일치합니다."
                    : "비밀번호가 일치하지 않습니다."
                }
              />
              <LabelAdminInput
                title={"이름"}
                name={"name"}
                type={"text"}
                value={newInput.repass}
                placeholderVal={"실명"}
                inputcss={
                  newInput.name === "" || checkName(newInput.name)
                    ? "w-full" + normalInputRing
                    : "w-full" + errorInputRing
                }
                onChangeAction={(e) => onChangeAction(e)}
                onKeyPressAction={SubmitData}
                checkCss={
                  !(newInput.name === "" || checkName(newInput.name))
                    ? errorMsgColor
                    : ""
                }
                checkVal={
                  !(newInput.name === "" || checkName(newInput.name)) &&
                  "이름 형식을 체크해주세요."
                }
              />
              <LabelSelectLayout title={"성별"}>
                <CommonOption
                  ver={2}
                  lists={genderList}
                  selectid={"gender"}
                  onChangeFunction={onChangeAction}
                />
              </LabelSelectLayout>
              <LabelAdminInput
                title={"이메일 주소"}
                name={"email"}
                type={"text"}
                value={newInput.email}
                placeholderVal={"이메일 주소를 입력해주세요"}
                inputcss={
                  newInput.email === "" || checkEmail(newInput.email)
                    ? " w-full " + normalInputRing
                    : " w-full " + errorInputRing
                }
                onChangeAction={(e) => onChangeAction(e)}
                onKeyPressAction={SubmitData}
                checkCss={
                  !(newInput.email === "" || checkEmail(newInput.email))
                    ? errorMsgColor
                    : normalMsgColor
                }
                checkVal={
                  !(newInput.email === "" || checkEmail(newInput.email)) &&
                  "이메일 형식에 맞게 작성해주세요."
                }
              />
              <LabelAdminInput
                title={"생일"}
                name={"birth"}
                type={"text"}
                value={newInput.birth}
                placeholderVal={"ex)1991-01-01"}
                inputcss={
                  newInput.birth === "" || checkBirth(newInput.birth)
                    ? "w-full" + normalInputRing
                    : "w-full" + errorInputRing
                }
                onChangeAction={(e) => onChangeAction(e)}
                onKeyPressAction={SubmitData}
                checkCss={
                  !(newInput.birth === "" || checkBirth(newInput.birth))
                    ? errorMsgColor
                    : normalMsgColor
                }
                checkVal={
                  !(newInput.birth === "" || checkBirth(newInput.birth))
                    ? "생년월일 형식에 맞게 작성하세요."
                    : ""
                }
              />
              <LabelAdminInput
                title={"휴대폰 번호"}
                name={"phoneNumber"}
                type={"text"}
                value={newInput.phoneNumber}
                placeholderVal={"ex)010-0000-0000"}
                inputcss={
                  newInput.phoneNumber === "" ||
                  checkPhone(newInput.phoneNumber)
                    ? "w-full" + normalInputRing
                    : "w-full" + errorInputRing
                }
                onChangeAction={(e) => onChangeAction(e)}
                onKeyPressAction={SubmitData}
                checkCss={
                  !(
                    newInput.phoneNumber === "" ||
                    checkPhone(newInput.phoneNumber)
                  )
                    ? errorMsgColor
                    : normalMsgColor
                }
                checkVal={
                  !(
                    newInput.phoneNumber === "" ||
                    checkPhone(newInput.phoneNumber)
                  )
                    ? "전화번호 형식에 맞게 작성하세요."
                    : ""
                }
              />
              <AllSigned setAllTog={setAllTog} allTog={allTog} />
              <div className=" flex w-[440px] max-w-[440px] gap-x-[10px] ">
                <BGButtonCustom
                  onClick={goPrev}
                  content={"이전"}
                  bgcolor={"bg-gray_40"}
                  textcolor={"text-gray_100"}
                  textsize={"text-caption1_600"}
                  width={"25%"}
                />
                {authority === "힐리어리 일반관리자" ? (
                  <BGButtonCustom
                    onClick={
                      ifCheckInputStringNoneIsHerings ? undefined : SubmitData
                    }
                    content={"가입 신청하기"}
                    bgcolor={
                      ifCheckInputStringNoneIsHerings
                        ? "bg-orange_80"
                        : "bg-orange_100"
                    }
                    textcolor={"text-gray_10"}
                    textsize={"text-caption1_600"}
                    width={"75%"}
                  />
                ) : (
                  <BGButtonCustom
                    onClick={checkInputStringNone ? undefined : SubmitData}
                    content={"가입 신청하기"}
                    bgcolor={
                      checkInputStringNone ? "bg-orange_80" : "bg-orange_100"
                    }
                    textcolor={"text-gray_10"}
                    textsize={"text-caption1_600"}
                    width={"75%"}
                  />
                )}
                {/* 헤링스일반관리자 가입시 구분넣을까? */}
              </div>
            </div>
          </div>
        </CommonSquareBox>
      </div>
    </>
  );
};

export default AdminSighUp2;
