import React, { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import TruncateText from "../common/TruncateText";
import dayjs from "dayjs";

import { AnimatePresence } from "framer-motion";
import EditBtn from "../common/EditBtn";
import ListCommentSkeleton from "../loading/ListCommentSkeleton";

const ReplyCard = ({
  onClickComment,
  localNavTab,
  replyList,
  isMorePosting,
  onFetchMoreReplies,
  onClickPostInner,
  optionOpen,
  setOptionOpen,
  setEditComment,
  setComment,
  onDeleteComment,
  setCurrentPosition,
}) => {
  dayjs.locale("ko");
  const onClickThreeDots = (id) => {
    if (id === optionOpen.id) setOptionOpen({ open: false, id: id });
    else setOptionOpen({ open: true, id: id });
  };
  const moreIcon = useMemo(
    () => ({
      background:
        "url('/images/mobile/icon/vertical_three_dot.svg') no-repeat center",
    }),
    []
  );
  return (
    <InfiniteScroll
      dataLength={replyList.length}
      next={() =>
        onFetchMoreReplies(!localNavTab).catch((error) => console.log(error))
      }
      hasMore={isMorePosting}
      loader={<ListCommentSkeleton />}
    >
      <ul className="bg-white">
        {replyList.map((list, idx) => (
          <li key={list?.id + Math.random()}>
            <div
              className={`flex flex-col gap-1.5 border-b border-gray_60 px-6 py-3`}
            >
              <div className="flex">
                <figure
                  className="mr-1 h-[22px] w-[22px] shrink-0 overflow-hidden rounded-lg bg-orange_40 p-0.5"
                  onClick={() => onClickPostInner(list?.belongToPostingId)}
                >
                  <img
                    alt={"character_" + list?.postingNickName}
                    src={`/images/mobile/character/${
                      list?.isAdmin ? "doctor_to_right" : list?.characterPhoto
                    }.svg`}
                    className="h-7 w-[18px] "
                  />
                </figure>
                <div className="flex gap-1">
                  <span
                    onClick={() => onClickPostInner(list?.belongToPostingId)}
                    className={
                      "flex shrink-0 justify-center text-caption1_600 text-black"
                    }
                  >
                    {list?.postingNickName}
                    {list?.isAdmin && (
                      <img
                        className="w-4 h-4 my-auto"
                        alt="adminCheck"
                        src="/images/mobile/icon/check-badge.svg"
                      />
                    )}
                  </span>
                  <span
                    onClick={() => onClickPostInner(list?.belongToPostingId)}
                    className="overflow-hidden break-all ellipsis2 text-caption2_400 text-gray_140"
                  >
                    {list?.isReReply
                      ? "님의 글에 댓글을 달았습니다."
                      : "님의 게시물에 댓글을 달았습니다."}
                  </span>
                </div>

                <div className="my-auto ml-auto">
                  <div
                    id="three_dot_menu"
                    className="w-6 h-6"
                    style={moreIcon}
                    onClick={() => {
                      onClickThreeDots(list?.id);
                    }}
                  />
                  <AnimatePresence>
                    {optionOpen.open && optionOpen.id === list?.id && (
                      <EditBtn
                        titleUp="댓글 수정"
                        onActionUp={() => {
                          setCurrentPosition(
                            document.documentElement.scrollTop ||
                              document.body.scrollTop
                          );
                          setEditComment({ id: list?.id, status: true });
                          setComment(list?.content);
                          setOptionOpen({ open: false, id: null });
                        }}
                        titleDown="댓글 삭제"
                        onActionDown={() => {
                          onDeleteComment(list?.id);
                          setOptionOpen({ open: false, id: null });
                        }}
                        location="absolute"
                        dropdown={true}
                        open={optionOpen.open}
                        onClickOutside={() => {
                          setOptionOpen({ open: false, id: null });
                        }}
                      />
                    )}
                  </AnimatePresence>
                </div>
              </div>

              <TruncateText
                text={list?.content}
                maxLines={2}
                // eslint-disable-next-line react/style-prop-object
                style={"text-body3_700 text-gray_180 pr-3"}
                onClick={() => onClickPostInner(list?.belongToPostingId)}
              />

              <span
                className="block text-caption2_400 text-gray_140"
                onClick={() => onClickPostInner(list?.belongToPostingId)}
              >
                {dayjs(list?.createdAt).format(`YYYY${"-"}MM${"-"}DD A h:mm`)}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </InfiniteScroll>
  );
};

export default ReplyCard;
