import React, { useEffect, useState } from "react";
// import { randomMsgFn } from "../logic";
import { useSelector, useDispatch } from "react-redux";
import { saveAnswerGpt } from "../../../../features/mobile/cancerGptSlice";

const GPTAnswer = ({ question, cancerQ }) => {
  const { loginInfo } = useSelector((state) => state.commonApp);
  const dispatch = useDispatch();

  const [msg, setMsg] = useState("답변을 작성중입니다...");
  const [state, setState] = useState(false);
  //   const loadingSpan =
  //     "inline-block w-[6px] h-[6px] bg-gray_160 rounded-[50%] animate-[gptloadingg_1s_linear_infinite] bg-black ";

  useEffect(() => {
    // todo GPT 응답은 이쪽 (question)이 들어올때마다
    setMsg("답변을 작성중입니다");
    setState(false);
    onSaveAnswer();
    // eslint-disable-next-line
  }, [question]);

  const onSaveAnswer = () => {
    dispatch(
      saveAnswerGpt({
        userId: loginInfo.userId,
        question: question,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.ok) {
          //   console.log(res, "res");
          //   console.log(res.answer, "res");
          setState(true);
          setMsg(res.answer);
          // console.log(res.answer, "answer");
        } else {
          //   console.log(res, "실패 res");
          setState(true);
          setMsg("일시적인 오류입니다. 다시 질문해 주시기 바랍니다.");
          //   toast(
          //     <p>{res.error}</p>,
          //     toastCommonProps("bottom-center", "toast_alert", 1000)
          //   );
        }
      })
      .catch((err) => {
        console.log(err);
        setState(true);
        setMsg("일시적인 오류입니다. 다시 질문해 주시기 바랍니다.");
      });
  };
  return (
    <>
      <div className="mx-6 mb-[124px] mt-4">
        <div className="mr-[-1px] h-min grow rounded-2xl bg-[#E8ECFF] p-4">
          <div className="flex text-left break-all whitespace-pre-wrap text-body2_500l text-gray_180 ">
            <span>{msg}</span>
            {/* tailwind로 변환 */}
            {/* 답변오기까지의 시간동안 말줄임표에 애니메이션을 적용해 보여줌 */}
            {/* className={"flex  " + (state === false ? "gptloading" : "")} */}
            {/* <div className="flex  ml-[10px] justify-center items-center  last:delay-[5000ms]">
              <span className={`${loadingSpan}  delay-75`}></span>
              <span className={`${loadingSpan}  mx-[10px] delay-100`}></span>
              <span className={`${loadingSpan} delay-1000`}></span>
            </div> */}
            <div className={"flex  " + (state === false ? "gptloading" : "")}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GPTAnswer;
