import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const fetchStep = createAsyncThunk("step/fetchStep", async (params) => {
  const res = await api.post(`/healthDiary/searchStep`, params);
  return res.data;
});

export const fetchStepPoints = createAsyncThunk(
  "step/fetchStepPoints",
  async (params) => {
    const res = await api.post(`/healthDiary/getStepPoints`, params);
    return res.data;
  }
);

const initialState = {
  stepList: [],
  group: [],
  steps: [],
  existStepOfYear: [],
  stepStatus: null,
};

const stepSlice = createSlice({
  name: "걸음수",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchStep.pending, (state) => {
      state.stepStatus = "pending";
    });
    builder.addCase(fetchStep.fulfilled, (state, action) => {
      state.stepStatus = "success";
      console.log(action.payload);
      state.stepList = action.payload?.stepList;
      state.group = action.payload?.group;
      state.steps = action.payload?.steps;
    });
    builder.addCase(fetchStepPoints.fulfilled, (state, action) => {
      state.existStepOfYear = action.payload.list;
    });
  },
});

export const { onSetStepList } = stepSlice.actions;

export default stepSlice.reducer;
