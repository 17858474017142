import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import { saveLoginInfo } from "../../../../features/mobile/commonSlice";
import {
  registerHospitalMember,
  searchHospital,
  unregistHospitalMember,
} from "../../../../features/mobile/settingSlice";
import Btn from "../../components/common/Btn";
import HeadCont from "../../components/common/HeadCont";
import InputSelectBox2 from "../../components/common/InputSelectBox2";

const HospitalResister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bottomRef = useRef(null);
  const { loginInfo } = useSelector((state) => state.commonApp);
  const closeIcon = useMemo(
    () => ({
      background: "url('/images/mobile/icon/close.svg') no-repeat center",
    }),
    []
  );
  const [hospitalArr, setHospitalArr] = useState(null);
  const [hospital, setHospital] = useState(null);
  const [code, setCode] = useState(null);

  const message =
    loginInfo.hospitalCode !== null
      ? ["일반회원으로 전환하시려면", "병원회원 탈퇴를 진행해주세요."]
      : [
          "병원회원으로 전환하시려면",
          "병원에서 안내 받으신",
          "병원 코드를 입력해주세요.",
        ];
  const HosTxt = [
    "병원회원 탈퇴시,",
    "일반회원으로 자동 전환됩니다.",
    "일반 회원으로 전환 시 병원의 의료진으로 부터 관리를 밭을수 없게 됩니다. 그 외 모든 서비스는 정상적으로 이용 가능합니다.",
    "병원회원 탈퇴를 원하시면 아래의 병원 회원 탈퇴 버튼을 선택해주세요.",
  ];

  const submitHospital = async () => {
    let params = {
      userId: loginInfo.userId,
      hospitalName: hospital,
      hospitalCode: code,
    };
    const res = await dispatch(registerHospitalMember(params)).unwrap();
    try {
      if (res.data.ok) {
        toast(
          <p>병원 회원으로 가입되었습니다.</p>,
          toastCommonProps("bottom-center", "toast_alert")
        ); //일반회원 병원회원으로 변경
        dispatch(saveLoginInfo({ ...loginInfo, hospitalCode: code }));
        navigate(-1);
      } else {
        toast(
          <p>{res.data.error}</p>,
          toastCommonProps("bottom-center", "toast_alert")
        );
        setHospital(null);
        setCode(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const unRegistSubmitHospital = async () => {
    let param = { userId: loginInfo.userId };
    const res = await dispatch(unregistHospitalMember(param)).unwrap();
    try {
      if (res.data.ok) {
        toast(
          <p>탈퇴하였습니다.</p>,
          toastCommonProps("bottom-center", "toast_alert")
        ); //병원회원 일반회원으로 변경
        dispatch(saveLoginInfo({ ...loginInfo, hospitalCode: null }));
      } else {
        toast(
          <p>{res.data.error}</p>,
          toastCommonProps("bottom-center", "toast_alert")
        );
      }
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const scrollAction = () => {
    const timer = setTimeout(
      () =>
        bottomRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        }),
      500
    ); //늦게반응
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (!loginInfo.hospitalCode) {
      const fetchHospital = async () => {
        const res = await dispatch(searchHospital()).unwrap();
        try {
          if (res.data) {
            setHospitalArr(res.data.hospitalList);
          } else {
            // console.log(res)
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchHospital();
    }
  }, [dispatch, loginInfo.hospitalCode]);

  return (
    <>
      <HeadCont
        rightIcon={closeIcon}
        leftIcon={"no icon"}
        title={"회원 전환"}
        onRightAction={() => navigate(-1)}
      />
      <section className="px-6 pt-14">
        <div className="text-subtitle1 text-gray_180">
          <p>{loginInfo.nickName}님은</p>
          <p>
            <span className="text-orange_160">
              {loginInfo.hospitalCode !== null ? "병원회원" : "일반회원"}
            </span>
            입니다.
          </p>
        </div>
        <div className="pt-4 text-body2_500l text-gray_160">
          {message.map((el, idx) => (
            <p key={idx}>{el}</p>
          ))}
        </div>
        <div className="flex flex-col gap-y-4 pt-[44px]">
          {loginInfo.hospitalCode !== null ? (
            <ul className="rounded-lg bg-white p-5 text-body3_500 text-gray_160">
              {HosTxt.map((el, idx) => (
                <li className={idx === 1 ? "pb-6" : ""} key={idx}>
                  {el}
                </li>
              ))}
            </ul>
          ) : (
            <>
              <InputSelectBox2
                optionLists={hospitalArr}
                labelN={"병원명"}
                htmlForN={"hospitalN"}
                nameTitle={"hospital"}
                placeH={"병원명을 입력해주세요"}
                setValue={setHospital}
                value={hospital}
                onClickAction={scrollAction}
              />
              <div>
                <InputSelectBox2
                  labelN={"병원 코드"}
                  htmlForN={"hospitalC"}
                  nameTitle={"code"}
                  placeH={"병원코드"}
                  setValue={setCode}
                  value={code}
                />
                <span className="text-caption1_400 text-gray_160">
                  병원 코드 6자리를 입력해주세요
                </span>
              </div>
            </>
          )}
        </div>
        <div className="fixed bottom-0 left-0 w-full bg-back_ground pb-6">
          <div className="maxW mx-auto flex flex-col gap-y-[8px] px-6">
            {loginInfo.hospitalCode !== null ? (
              <Btn
                bgColor={"bg-main"}
                textColor={"text-white"}
                borderColor={"border border-main"}
                title={"병원회원 탈퇴하기"}
                active={"on"}
                onAction={unRegistSubmitHospital}
              />
            ) : (
              <Btn
                bgColor={"bg-main"}
                textColor={"text-white"}
                borderColor={"border border-main"}
                title={"저장하기"}
                active={hospital && code ? "on" : "ready"}
                onAction={submitHospital}
              />
            )}
            <Btn
              textColor={"text-main"}
              borderColor={"border border-main"}
              title={"취소하기"}
              active={"on"}
              onAction={() => navigate(-1)}
            />
          </div>
        </div>
        <div ref={bottomRef} className="h-[160px]"></div>
      </section>
    </>
  );
};

export default HospitalResister;
