// hooks/useCommunity.js
// dont use this for general use !!!!
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import toastCommonProps from "../../../common/toast";
// Redux reducers
import { addReporting, blockUser } from "../../../features/mobile/commonSlice";
import {
  deletePosting,
  fetchCommPostingDetails,
  fetchCommPostings,
  onResetCommunityPage,
  onResetAll,
  onSaveCommunityPage,
  onSetExistNextPosting,
  onSetIsLike,
  onSetPrevLikedList,
  onSetPrevPostList,
  onSetRangeMonth,
  onSetSearchedValue,
  onSetTotalPosts,
  onSetWriterUserId,
} from "../../../features/mobile/commSlice";

/**
 * Community hook containing functions and \
 * variables shared among the Community Components
 *
 * @date 6/21/2023 - 2:21:59 PM
 *
 * @export
 * @param {string} page "main", "post", "search" for each of the Community Pages
 * @param {number} loginInfo
 * @param {string} tabTitle
 * @return {void}
 */
export default function useCommunity(page, loginInfo, tabTitle) {
  // hook declaration
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // redux commMobile states
  const {
    prevPostList,
    prevLikedList,
    existNextPosting,
    searchedValue,
    writerUserId,
    isLike,
    rangeMonth,
  } = useSelector((state) => state.commMobile);

  // State declaration
  // prettier-ignore
  const [postList, setPostList] = useState( prevPostList?.length > 0 ? prevPostList : [] );
  const [isMorePosting, setIsMorePosting] = useState(existNextPosting);
  const [loading, setLoading] = useState(true);
  // report display status
  const [openDialog, setOpenDialog] = useState(false);
  const [reportStatus, setReportStatus] = useState("report");
  const [currentUser, setCurrentUser] = useState(null);

  /**
   *
   * onBringPosting: /posting/getPagePostings \
   * When in the Community.js page, the current posts \
   * are not saved in the Redux (as they are saved on exit)
   *
   * Dispatched:
   * - onSetExistNextPosting
   * - onSetPrevLikedList
   *
   * @date 6/21/2023 - 2:22:41 PM
   *
   * @param {{ lastId: number; qty: number; keyword: string; textValue: string; setValue: {}; setLocalTotalPosts: {}; type: number; writerUserId: string; isLike: boolean; rangeMonth: number; }}
   * @param {number} params.lastId Used for 인기 tab. Describes the last fetched id
   * @param {number} params.qty Variable describing how many posts to fetch
   * @param {string} params.keyword Used during tab change
   * @param {string} params.textValue State of a search keyword (or search word)
   * @param {Function} params.setValue State mutator for a search keyword (or search word)
   * @param {Function} params.setLocalTotalPosts State mutator for total post count
   * @param {number | undefined} params.type subCategory
   * @param {string | undefined} params.writerUserId Used to get all the posts (or liked posts) of writerUserId
   * @param {boolean | undefined} params.isLike Used with writerUserId (for liked posts)
   * @param {number | undefined} params.rangeMonth Used with writerUserId (for setting the range)
   * @returns {void}
   */
  const onBringPostings = ({
    lastId = 0,
    qty = 10,
    keyword,
    textValue,
    setValue,
    setLocalTotalPosts,
    type,
    isKeyword,
    // My Page (myPosts, myLikedPosts, myComments)
    writerUserId,
    isLike,
    rangeMonth,
  }) => {
    return new Promise((resolve, reject) => {
      let params = isKeyword
        ? {
            userId: loginInfo.userId,
            // lastPostingId: lastId,
            countPerPage: qty,
            keyword: textValue,
          }
        : {
            userId: loginInfo.userId,
            countPerPage: qty,
            searchText: textValue,
          };

      if (type !== undefined) {
        params.subCategory = type;
      }
      if (type !== undefined && type === 0) {
        params.currentListCount = 0;
        params.isPopular = true;
        // delete params.lastPostingId;
      }
      /////////////////////////////
      // adding MyPage variables //
      /////////////////////////////
      if (writerUserId) {
        params.writerUserId = writerUserId;
        dispatch(onSetWriterUserId(writerUserId));
      }
      if (isLike) {
        params.isLike = isLike;
        dispatch(onSetIsLike(isLike));
      }
      if (rangeMonth) {
        params.rangeMonth = rangeMonth;
        dispatch(onSetRangeMonth(rangeMonth));
      }
      dispatch(fetchCommPostings(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            if (page === "search") {
              dispatch(onSetTotalPosts(res.totalSearhCount));
              dispatch(onSetSearchedValue(textValue));
              setValue("");
              if (res?.popularPostingList) setPostList([]);
              else {
                setPostList([...res.postingList]);
                setLocalTotalPosts(res.totalSearhCount);
              }
            } else {
              setPostList([...res.postingList]);
              if (page === "myPage") {
                dispatch(onSetSearchedValue(textValue));
                // setValue && setValue("");
              }
            }
            setIsMorePosting(res.isExistNextPosting);

            // setLikedList(res.postingList.map(value => ({id: value.id, isLiked: value.isLiked ? true : false, likeCount: value.likeCount, replyCount: value.replyCount})))
            dispatch(onSetExistNextPosting(res.isExistNextPosting));
            dispatch(
              onSetPrevLikedList(
                res.postingList.map((value) => ({
                  id: value.id,
                  isLiked: value.isLiked ? true : false,
                  likeCount: value.likeCount,
                  replyCount: value.replyCount,
                  report: value.isReported ? true : false,
                  isWriteReply: value.isWriteReply ? true : false,
                }))
              )
            );
            setLoading(true);
            resolve(true);
          } else {
            reject(new Error(res?.error));
          }
        })
        .catch((error) =>
          reject({ ...error, location: "useCommunity.js:172:1" })
        );
    });
  };

  /**
   * onFetchMorePosts: /posting/getPagePostings\
   * When in the Community.js page, the current posts \
   * are not saved in the Redux (as they are saved on exit)
   *
   * Dispatched:
   * - onSetExistNextPosting
   * - onSetPrevLikedList
   *
   * @date 6/21/2023 - 2:30:08 PM
   */
  const onFetchMorePosts = () => {
    let params = {
      userId: loginInfo.userId,
      // lastPostingId: postList[postList.length - 1]?.id,
      currentListCount: postList.length,
      countPerPage: 10,
      searchText: searchedValue,
      writerUserId: writerUserId,
      isLike: isLike,
      rangeMonth: rangeMonth,
    };

    switch (tabTitle) {
      case "인기":
        params.subCategory = 0;
        params.isPopular = true;
        break;
      case "소식":
        params.subCategory = 1;
        break;
      case "소통":
        params.subCategory = 2;
        break;
      default:
        break;
    }

    if (isMorePosting) {
      dispatch(fetchCommPostings(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            setIsMorePosting(res.isExistNextPosting);
            setPostList(postList.concat(res.postingList));
            // setLikedList(likedList.concat(res.postingList.map(value => ({id: value.id, isLiked: value.isLiked ? true : false, likeCount: value.likeCount, replyCount: value.replyCount}))))
            dispatch(onSetExistNextPosting(res.isExistNextPosting));
            dispatch(
              onSetPrevLikedList(
                prevLikedList.concat(
                  res.postingList.map((value) => ({
                    id: value.id,
                    isLiked: value.isLiked ? true : false,
                    likeCount: value.likeCount,
                    replyCount: value.replyCount,
                    report: value.isReported ? true : false,
                    isWriteReply: value.isWriteReply ? true : false,
                  }))
                )
              )
            );
          }
        })
        .catch((error) =>
          console.log({ ...error, location: "useCommunity.js:241:1" })
        );
    }
  };

  /**
   *
   * bringPostingDetails: /posting/getAllRepliesOfOnePosting
   *
   * Dispatched:
   * - onSetPrevLikedList
   * @date 6/21/2023 - 2:31:09 PM
   *
   * @param {number} postId
   */
  const bringPostingDetails = (postId) => {
    let params = { userId: loginInfo.userId, postingId: postId };
    dispatch(fetchCommPostingDetails(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          dispatch(
            onSetPrevLikedList(
              prevLikedList.map((value) =>
                value.id === parseInt(postId)
                  ? { ...value, replyCount: res.posting.replyCount }
                  : value
              )
            )
          );
        }
      });
  };

  /**
   * Navigate to CommunityPost.js
   *
   * Dispatched:
   * - onSaveCommunityPage
   *
   * @date 6/21/2023 - 2:31:59 PM
   *
   * @param {number} lastId Post ID
   * @type {callBack}
   */
  const onClickPost = useCallback(
    (id) => {
      if (page === "main")
        dispatch(
          onSaveCommunityPage({
            postList: postList,
            tabTitle: tabTitle,
            likedList: prevLikedList,
          })
        );
      else dispatch(onSetPrevPostList(postList));
      navigate(`/community/` + id);
    },
    [postList, tabTitle, prevLikedList, dispatch, navigate, page]
  );

  /**
   * Navigate to CommunityPublish.js
   * Dispatched:
   * - onResetCommunityPage (Write a Post)
   * - onSaveCommunityPage (Edit a Post)
   *
   * @date 6/21/2023 - 2:33:54 PM
   *
   * @param {string} option [New Post: "write"] : [Edit Post: "edit" | undefined]
   * @param {number} id Post ID (provide if option==edit)
   */
  const onClickWritePost = (option, id) => {
    if (option === "write") {
      dispatch(onResetCommunityPage());
      navigate("/community/post/0");
    } else {
      if (page === "main")
        dispatch(onSaveCommunityPage(postList, tabTitle, prevLikedList));
      else dispatch(onSetPrevPostList(postList));
      navigate("/community/post/" + id);
    }
  };

  /**
   * onDeletePost: /posting/delete
   *
   * Dispatched:
   * - onSetPrevLikedList
   * - onSetPrevPostList
   * @date 6/21/2023 - 2:34:36 PM
   *
   * @param {number} postId
   */
  const onDeletePost = (postId) => {
    let params = { writerUserId: loginInfo.userId, ids: [postId] };
    dispatch(deletePosting(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          dispatch(
            onSetPrevLikedList(
              prevLikedList.filter(
                (likedList) => likedList.id !== parseInt(postId)
              )
            )
          );
          toast(
            <p>게시글 삭제완료</p>,
            toastCommonProps("bottom-center", "toast_alert")
          );
          if (page === "main" || page === "search" || page === "myPage")
            setPostList((current) =>
              current.filter((postList) => postList.id !== postId)
            );
          if (page === "post") {
            dispatch(
              onSetPrevPostList(
                prevPostList.filter(
                  (viewPosts) => viewPosts.id !== parseInt(postId)
                )
              )
            );
            setTimeout(() => navigate(-1), 300);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  /**
   * onAddReporting: /posting/addReporting
   * @param {[number]} postId
   * @param {[string | undefined]} reason leave it undefined for canceling a report
   */

  /**
   * onAddReporting: /posting/addReporting
   * @date 6/21/2023 - 2:35:08 PM
   *
   * @param {[number]} postId
   * @param {*} replyId deprecated parameter
   * @param {[string]} reason leave it undefined for canceling a report
   */
  const onAddReporting = (postId, replyId, reason) => {
    let params = {
      reportUserId: loginInfo.userId,
      reportingReason: reason,
      reportedPostingId: postId,
    };
    dispatch(addReporting(params))
      .unwrap()
      .then((res) => {
        dispatch(
          onSetPrevLikedList(
            prevLikedList.map((value) =>
              parseInt(postId) === value.id
                ? {
                    ...value,
                    report: res.reportingStatus === "added" ? true : false,
                  }
                : value
            )
          )
        );
        if (page === "post") {
          console.log("check");
          bringPostingDetails(postId);
        }
      });
  };

  /**
   * onBlockUser: /posting/blockUser
   *
   * InnerStatus:
   * - "reportPost": Within a CommunityPost and is a User from a Post
   * - "reportReply": Within a CommunityPost and is a User from a Reply
   * - undefined: Within a Community
   *
   * @date 6/21/2023 - 2:36:28 PM
   *
   * @param {string} blockId Blocked User's ID
   * @param {string} innerStatus
   * @param {number} postId
   */
  const onBlockUser = (blockId, innerStatus, postId) => {
    let params = { userId: loginInfo.userId, blockUsersId: blockId };
    dispatch(blockUser(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          if (innerStatus === "reportPost") {
            dispatch(onResetAll());
            navigate(-1);
          }
          if (innerStatus === "reportReply") {
            dispatch(onResetAll());
            bringPostingDetails(postId);
          } else {
            console.log(postList);
            setPostList((current) =>
              current.filter((postList) => postList?.writer?.userId !== blockId)
            );
            // onBringPostings({
            //   type:
            //     tabTitle === "인기"
            //       ? 0
            //       : tabTitle === "소식"
            //       ? 1
            //       : tabTitle === "소통" && 2,
            // });
          }
        }
      });
  };

  /**
   * onClocse Dialog, responsible for registering reports and
   * userBlocks.
   * Need Re-working... (lots of useless logic)
   *
   * @date 6/21/2023 - 2:37:08 PM
   */
  const onCloseDialog = () => {
    if (reportStatus === "complete") setReportStatus("report");
    if (reportStatus === "block") {
      if (page === "post")
        onBlockUser(currentUser?.writer?.userId, "reportPost");
      else onBlockUser(currentUser?.writer?.userId);
    }
    setReportStatus("report");
    setOpenDialog(false);
  };

  /**
   *
   * @date 6/21/2023 - 2:37:32 PM
   *
   * @param {string} reason
   */
  const onReportReply = (reason) => {
    onAddReporting(currentUser?.postId, null, reason);
    setReportStatus("complete");
  };

  /**
   * Reset local postList, isMorPosting and loading states
   * Reset redux states (onResetAll:commSlice.js)
   * @date 6/21/2023 - 2:37:55 PM
   */
  const onResetCommunity = () => {
    setPostList([]);
    setIsMorePosting(true);
    setLoading(false);
    dispatch(onResetAll());
  };

  return {
    // variables
    postList,
    loading,
    isMorePosting,
    openDialog,
    reportStatus,
    currentUser,
    searchedValue,
    rangeMonth,
    // functions
    onBringPostings,
    onFetchMorePosts,
    bringPostingDetails,
    onClickPost,
    onClickWritePost,
    onDeletePost,
    onAddReporting,
    onBlockUser,
    onCloseDialog,
    onReportReply,
    // state function
    setLoading,
    setOpenDialog,
    setCurrentUser,
    setReportStatus,
    onResetCommunity,

    // etc.,
    dispatch,
    navigate,
  };
}
