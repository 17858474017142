import React, { useState, } from 'react'
import ContentsGuideListLayout from './ContentsGuideListLayout'
import ContentsModal from './ContentsModal'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFoodGuide, editFoodGuide, getFoodGuide, saveFoodGuide } from '../../../../../features/admin/contentsSlice'
import { toast } from 'react-toastify'
import toastCommonProps from '../../../../../common/toast'
import ModalSelect from './ModalSelect'
import { foodOptions } from '../functions/service'
import ModalNotSelect from './ModalNotSelect'
import { ThreeConceptReport, TwoConceptReport } from '../ModalReportTop'
import { BGButtonCustom, BorderButtonCustom } from '../../common/ButtonCustom'

const FoodGuideModalIn = ({newModal,editModal, tabName,setNewModal, setEditModal}) => {
  const {loginInfo} = useSelector((state)=>state.adminLogin)
  //가이드수정시 최초로 불러오는 조건식
  const foodGuideEach = useSelector((state)=>state.contents.eachGuideList)

  const dispatch = useDispatch()   
  const [editInner, setEditInner] = useState(false)
  const [foodGuide, setFoodGuide] = useState({
    lcategory:foodGuideEach.lcategory,
    mcategory:foodGuideEach.mcategory,
    scategory:foodGuideEach.scategory,
    //files 추후 추가하게되면 넣을것=> files:null,
    content:foodGuideEach.content,
    addAdmin:loginInfo?.adminId
  })

  const onChangeFood = (e) =>{
    const {name,id,value} = e.target;
    setFoodGuide({...foodGuide,[name]:value})

  }
  const submitFOODGuide = async() =>{
    let param = foodGuide
   const res = await dispatch(saveFoodGuide(param)).unwrap()
    if (res.data.ok) {
      toast(<p>저장 완료되었습니다</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
      setTimeout(()=>setNewModal(false),1000)
      dispatch(getFoodGuide());
    } else {
      console.log(res);
    }
  }
const onClickDelete = async(paramid)=>{
  const res = await dispatch(deleteFoodGuide(paramid)).unwrap()
  if (res.data.ok) {
    toast(<p>해당 푸드 가이드가 삭제 되었습니다.</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
    setTimeout(()=>{setEditModal(false);},1000)
    dispatch(getFoodGuide());
  } else {
    console.log(res, "푸드 가이드 삭제 실패ㅜ");
  }
}
const onClickEdit = async() =>{
  let paramEdit = {
    //가이드 수정클릭시 foodGuide에 id값 추가
    id: foodGuide.id,
    lcategory:foodGuide.lcategory,
    mcategory:foodGuide.mcategory,
    scategory:foodGuide.scategory,
    content:foodGuide.content,
    editAdmin:loginInfo?.adminId
  }
  const res = await dispatch(editFoodGuide(paramEdit)).unwrap()
  if(res.data.ok){
    toast(<p>수정 완료되었습니다</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
    setTimeout(()=>setEditModal(false),1000)
    dispatch(getFoodGuide());
  }else{
    console.log(res)
  }
}

const changePage = ()=>{
  setEditInner(true); 
  setFoodGuide({
  id: foodGuideEach.id,
  lcategory:foodGuideEach.lcategory,
  mcategory:foodGuideEach.mcategory,
  scategory:foodGuideEach.scategory,
  content:foodGuideEach.content,
  editAdmin:foodGuideEach.editAdmin!==null ? foodGuideEach.editAdmin.adminId: foodGuideEach.addAdmin.adminId
}

)}
  return (
    <>
    {/* 새가이드등록 */}
    {newModal &&
    <ContentsModal
    tabName={tabName}
    setEditModal={setEditModal}
    setNewModal={setNewModal}
    subTab={"새 가이드 등록"}>
      <TwoConceptReport user={loginInfo}/>
      <div className='pt-[32px]'>
        {foodOptions?.length>0 && foodOptions?.map((el,idx)=>(
          <ContentsGuideListLayout title={el.label} key={idx}>
            <ModalSelect 
            datas={el} name={el.type}
            onChangeFunc={onChangeFood}
            />
          </ContentsGuideListLayout>
        ))}
        <ContentsGuideListLayout title={"내용"}>
          <textarea placeholder='내용을 입력하세요(최대 500자)'
          value={foodGuide.content}
          name="content"
          className='w-full h-[300px] border border-[#A39F9B] rounded px-4 py-2 outline-none bg-transparent'
          onChange={onChangeFood}
          />
        </ContentsGuideListLayout>
      </div>
      <div className='w-full flex justify-end gap-x-4 pt-4'>
        <BorderButtonCustom
          onClick={() => {setNewModal(false)}}
          content={"작성 취소"}
          bgcolor={""}
          textcolor={"text-primary_100"}
          bordercolor={"border-primary_100"}
          textsize={"text-body_2"}
          xpadding={"12px"}
        />
        <BGButtonCustom
          onClick={submitFOODGuide}
          content={"저장"}
          bgcolor={"bg-primary_100"}
          textcolor={"text-gray_100"}
          textsize={"text-body_2"}
          xpadding={"12px"}
        />
      </div>
    </ContentsModal>
    }
    {/* 수정가이드등록 */}
    {
      editModal && 
        //editInner 가 true면 가이드 수정 component
      (editInner ?
      <ContentsModal
      tabName={tabName}
      setEditModal={setEditModal}
      setNewModal={setNewModal}
      subTab={"가이드수정"}
      >
        <TwoConceptReport user={loginInfo}/>
        <div className='pt-[32px]'>
          {foodOptions?.length>0 && foodOptions?.map((el,idx)=>(
            <ContentsGuideListLayout title={el.label} key={idx}>
              <ModalSelect   
              datas={el} name={el.type}
              onChangeFunc={onChangeFood}
              categories = {foodGuide}
              />
            </ContentsGuideListLayout>
          ))}
        <ContentsGuideListLayout title={"내용"}>
          <textarea placeholder='내용을 입력하세요(최대 500자)'
          value={foodGuide.content}
          name="content"
          className='w-full h-[300px] border border-[#A39F9B] rounded px-4 py-2 outline-none bg-transparent'
          onChange={onChangeFood}
          />
        </ContentsGuideListLayout>
        </div>
        <div className='w-full flex justify-end gap-x-4 pt-4'>
          <BorderButtonCustom
            onClick={() => setEditInner(false)}
            content={"수정 취소"}
            bgcolor={""}
            textcolor={"text-primary_100"}
            bordercolor={"border-primary_100"}
            textsize={"text-body_2"}
            xpadding={"12px"}
          />
          <BGButtonCustom
            onClick={onClickEdit}
            content={"수정 완료 및 저장"}
            bgcolor={"bg-primary_100"}
            textcolor={"text-gray_100"}
            textsize={"text-body_2"}
            xpadding={"12px"}
          />
        </div>
      </ContentsModal>
        :
      //editInner 가 false이면 가이드 수정전 component
      <ContentsModal
      tabName={tabName}
      setEditModal={setEditModal}
      setNewModal={setNewModal}
      >
        <ThreeConceptReport whatGuideEach={foodGuideEach}/>
        <div className='pt-[32px]'>
          <ContentsGuideListLayout title={"암 종류"}>
            <ModalNotSelect element={foodGuideEach.lcategory}/>
          </ContentsGuideListLayout>
          <ContentsGuideListLayout title={"치료 단계"}>
            <ModalNotSelect element={foodGuideEach.mcategory}/>
          </ContentsGuideListLayout>
          <ContentsGuideListLayout title={"주차 및 시기"}>
            <ModalNotSelect element={foodGuideEach.scategory}/>
          </ContentsGuideListLayout>
          <ContentsGuideListLayout title={"내용"}>
            <div
            className=' bg-[#F3F3F2] w-full h-[300px] border border-gray_90 rounded px-4 py-2'
            >
              {foodGuideEach.content}
            </div>
          </ContentsGuideListLayout>
        </div>
        <div className='w-full flex justify-end gap-x-4 pt-4'>
          <BorderButtonCustom
            onClick={()=>onClickDelete(foodGuideEach.id)}
            content={"가이드 삭제"}
            bgcolor={""}
            textcolor={"text-primary_100"}
            bordercolor={"border-primary_100"}
            textsize={"text-body_2"}
            width={120}
          />
          <BorderButtonCustom
            onClick={changePage}
            content={"가이드 수정"}
            bgcolor={""}
            textcolor={"text-primary_100"}
            bordercolor={"border-primary_100"}
            textsize={"text-body_2"}
            xpadding={"12px"}
          />
          <BGButtonCustom
            onClick={()=>setEditModal(false)}
            content={"확인"}
            bgcolor={"bg-primary_100"}
            textcolor={"text-gray_100"}
            textsize={"text-body_2"}
            xpadding={"12px"}
          />
        </div>
      </ContentsModal>
      )

    }
    </>

  )
}

export default FoodGuideModalIn