import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const adminLogin = createAsyncThunk("admin/login", async (params) => {
  const res = await api.post("/admin/login", params);
  return res.data
});
export const getAdminIdInfo = createAsyncThunk(
  "account/getAdminIdInfo",
  async (params) => {
    const res = await api.get(`/admin/getAdmin/${params.adminId}`);
    return res.data;
  }
);
const initialState = {
  state: null,
  adminNotauto: [],
  loginInfo:{}
};
const adminloginSlice = createSlice({
  name: "어드민로그인",
  initialState,
  reducers: {
    autoLoginSave: (state, action) => {//자동로그인
      state.loginInfo = action.payload;
    },
    removeLoginInfo: (state)=>{
      state.loginInfo={}
    }
  },
  extraReducers: {
    [adminLogin.fulfilled]: (state, action) => {
      state.status = "success";
      state.adminNotauto = action.payload.admin;
      state.loginInfo = action.payload.admin
    },
    [getAdminIdInfo.fulfilled]:(state,action) =>{state.loginInfo = action.payload}
  },
});

export const { autoLoginSave,removeLoginInfo } = adminloginSlice.actions;

export default adminloginSlice.reducer;

