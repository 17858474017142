import React, { useState } from "react";
import HeadCont from "../../components/common/HeadCont";
import PersonalInfo from "../../components/account/PersonalInfo";
import TermsOfService from "../../components/account/TermsOfService";
import TabCommon from "../../components/common/TabCommon";

const Policy = () => {
  const [tab, setTab] = useState("개인정보 보호 정책");
  const tabs = ["개인정보 보호 정책", "이용약관"];
  // const onCloseMenu = () => {

  //   let menu = document.querySelector("#menu")
  //   menu.scrollTo(0, 0)
  // }

  return (
    <>
      <HeadCont title={"약관 및 정책"} />
      <section className="mt-14">
        <TabCommon tabs={tabs} setTab={setTab} tab={tab} />
        <div className="p-4">
          {tab === "개인정보 보호 정책" ? <PersonalInfo /> : <TermsOfService />}
        </div>
      </section>
    </>
  );
};

export default Policy;
