import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onSetCustomerCenterIdName } from "../../../features/mobile/settingSlice";
import { searchWeeklyMeal } from "../../../features/mobile/mealSlice";
import { checkQuestionsComplete } from "../../../features/mobile/otherSlice";
import dayjs from "dayjs";
import IntroCard from "../components/common/IntroCard";
import QuickNav from "../components/common/QuickNav";
import MenuBar from "../components/common/MenuBar";
import { onResetRecipePage } from "../../../features/mobile/recommendSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { weeklyMealLists } = useSelector((state) => state.meal);
  const { questionsCompleteCheck } = useSelector((state) => state.others);

  const datas = [
    {
      id: 1,
      menu: "건강관리",
      subMenu: [
        { id: 1, title: "건강 정보", sublink: "healthinfo" },
        { id: 2, title: "추천 식단", sublink: "recommend" },
        {
          id: 3,
          title: "식사 분석",
          sublink: `meal/analysis/${dayjs().format("YYYY-MM-DD")}`,
        },
        { id: 4, title: "레시피 찾기", sublink: "recommend/findrecipe" },
      ],
    },
    // { id: 2, menu: '생활', subMenu: [{ id: 1, title: '커뮤니티', sublink: 'community' }] },
    // { id: 2, menu: '생활', subMenu: [{ id: 1, title: '커뮤니티', sublink: 'community' }, { id: 2, title: '주간 리포트', sublink: 'report' }] },
    // { id: 2, menu: '고객센터', subMenu: [{ id: 2, title: '공지 사항', sublink: 'notice' }, { id: 3, title: '이용문의 및 FAQ', sublink: 'contact' }, { id: 4, title: '설정', sublink: 'setting' }] },
    // { id: 2, menu: '생활', subMenu: [{id: 1, title: '마이페이지', sublink: 'mypage' }, {id: 2, title: '커뮤니티', sublink: 'community' }] },
    {
      id: 2,
      menu: "생활",
      subMenu: [
        { id: 1, title: "정보", sublink: "info" },
        { id: 2, title: "커뮤니티", sublink: "community" },
        { id: 3, title: "마이페이지", sublink: "mypage" },
        { id: 4, title: "병원 정보 찾기", sublink: "hospitalinfo" },
      ],
    },
    {
      id: 3,
      menu: "고객센터",
      subMenu: [
        { id: 1, title: "공지 사항", sublink: "notice" },
        { id: 2, title: "이용문의 및 FAQ", sublink: "contact" },
        { id: 3, title: "설정", sublink: "setting" },
      ],
    },
  ];

  const filtered = weeklyMealLists?.filter(
    (list) => list.mealDay === dayjs().format("YYYY-MM-DD")
  )[0]?.eatList;

  const onClickGoToLink = (menu) => {
    navigate(`/${menu.sublink}`);
    //이용문의id값 null처리
    if (menu.sublink === "contact") {
      dispatch(onSetCustomerCenterIdName(null));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // if (weeklyMealLists?.length === 0) {
    let params = {
      userId: loginInfo.userId,
      mealDate: dayjs().format("YYYY-MM-DD"),
    };
    dispatch(searchWeeklyMeal(params));
    // }
    if (Object.keys(questionsCompleteCheck)?.length === 0) {
      //설문여부 체크
      dispatch(checkQuestionsComplete(loginInfo.userId));
    }
    // eslint-disable-next-line
  }, [dispatch, loginInfo.userId]);
  return (
    <nav
      id="menu"
      className="maxW fixed z-30 h-screen w-full overflow-scroll bg-white"
    >
      <IntroCard />
      <div className="mb-40">
        <QuickNav />

        {new Date() >= new Date("2023-09-11 00:00:00") &&
        new Date() < new Date("2023-10-16 00:00:00") ? (
          <div className="p-4">
            <button
              onClick={() => navigate(`/event`)}
              className="flex w-full justify-center gap-2 rounded-lg bg-main p-3 text-lg font-bold text-white"
            >
              <p className="-scale-x-100"> 🎉</p>
              힐리어리 이벤트 참여하기
              <p>🎉</p>
            </button>
          </div>
        ) : null}

        {datas.map((data) => (
          <div key={data.id} className="border-b border-gray_40 p-4">
            <h1 className="text-caption1_500 text-gray_160">{data.menu}</h1>
            <ul>
              {data.subMenu.map((menu) => (
                <li
                  key={menu.id}
                  className={
                    "flex justify-between rounded-md p-3 active:bg-gray_20"
                  }
                  onClick={() => {
                    if (menu.title === "식사 분석") {
                      if (
                        filtered?.length > 0 &&
                        questionsCompleteCheck.healthComplete &&
                        questionsCompleteCheck.dietLifeComplete
                      ) {
                        onClickGoToLink(menu);
                      }
                    } else if (menu.title === "레시피 찾기") {
                      dispatch(onResetRecipePage());
                      onClickGoToLink(menu);
                    } else {
                      onClickGoToLink(menu);
                    }
                  }}
                >
                  <h2
                    className={
                      "text-body3_700 " +
                      (menu.title === "식사 분석" &&
                      (filtered?.length === 0 ||
                        !(
                          questionsCompleteCheck.healthComplete &&
                          questionsCompleteCheck.dietLifeComplete
                        ))
                        ? "text-gray_100"
                        : "text-gray_180")
                    }
                  >
                    {menu.title}
                  </h2>
                  <img
                    src="/images/mobile/icon/right.svg"
                    alt="더보기 아이콘"
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <MenuBar />
    </nav>
  );
};

export default Navbar;
