import React from 'react'

const CommonSquareBox = ({children}) => {
  return (
    <div className="flex flex-col items-center justify-center bg-gray_10 rounded-[24px] shadow-md p-[48px] "
    >
      {children}
    </div>
  )
}

export default CommonSquareBox