import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const IdCard = ({ resultLists }) => {
  const { loginInfo } = useSelector((state) => state.commonApp);
  const candidates = ["rabbit_blue", "rabbit_pink"];
  const chosen = Math.floor(Math.random() * candidates.length);

  return (
    <div className="bg-gray_20 rounded-[20px]">
      {resultLists?.protectorInfo ? (
        <>
          <div className="border-b border-dashed border-gray_80 p-4">
            <h1 className="text-left text-caption2_700">건강관리 등록증</h1>
            <div className="flex justify-between mt-4">
              <div className="w-[96px] h-[96px] bg-white relative overflow-hidden rounded-[28px]">
                <span
                  className="absolute top-[12px] left-[15px] w-[66px] h-[109px]"
                  style={{
                    background: `url(/images/mobile/character/${
                      resultLists.protectorInfo?.characterPhoto
                        ? resultLists.protectorInfo?.characterPhoto
                        : candidates[chosen]
                    }.svg) no-repeat center`,
                  }}
                ></span>
              </div>
              <ul className="space-y-1 text-right">
                <li className="text-caption2_700 text-correct">보호자</li>
                <li className="text-body1_700 text_black">
                  {resultLists.protectorInfo?.nickName}
                </li>
                <li className="text-caption2_400 text-gray_160 hidden">
                  회원코드: ABC123
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-4 p-4">
            <div className="flex justify-between mb-6">
              <ul className="space-y-1 text-left">
                <li className="text-caption2_700 text-correct">환자</li>
                <li className="text-body1_700 text_black">
                  {resultLists.patientInfo?.nickName}
                </li>
                <li className="text-caption2_400 text_gray_160">
                  {resultLists.patientInfo.age}세 /{" "}
                  {resultLists.patientInfo.gender}
                </li>
              </ul>
              <div className="w-[96px] h-[96px] bg-white relative overflow-hidden rounded-[28px]">
                <span
                  className="absolute top-[12px] left-[15px] w-[66px] h-[109px]"
                  style={{
                    background: `url(/images/mobile/character/${resultLists.patientInfo?.characterPhoto}.svg) no-repeat center`,
                  }}
                ></span>
              </div>
            </div>
            <span className="text-caption2_700 text-gray_160">
              발급일{" "}
              {dayjs(resultLists.patientInfo.registDay).format("YYYY.MM.DD")}
            </span>
          </div>
        </>
      ) : (
        <div className="p-4 text-center">
          <h1 className="text-left text-caption2_700">건강관리 등록증</h1>
          <div className="flex justify-between mt-4 mb-6">
            <div className="w-[96px] h-[96px] bg-white relative overflow-hidden rounded-[28px]">
              {resultLists?.patientInfo ? (
                <span
                  className="absolute top-[12px] left-[15px] w-[66px] h-[109px]"
                  style={{
                    background: `url(/images/mobile/character/${
                      resultLists.patientInfo?.characterPhoto
                        ? resultLists.patientInfo?.characterPhoto
                        : candidates[chosen]
                    }.svg) no-repeat center`,
                  }}
                ></span>
              ) : loginInfo.userId ? (
                <span
                  className="absolute top-[12px] left-[15px] w-[66px] h-[109px]"
                  style={{
                    background: `url(/images/mobile/character/${
                      loginInfo?.characterPhoto
                        ? loginInfo?.characterPhoto
                        : candidates[chosen]
                    }.svg) no-repeat center`,
                  }}
                ></span>
              ) : (
                ""
              )}
            </div>
            <ul className="space-y-1 text-right mb-6">
              <li className="text-caption2_700 text-correct">
                {resultLists ? "환자" : "보호자 or 환자"}
              </li>
              <li className="text-body1_700 text_black">
                {resultLists && resultLists?.patientInfo
                  ? resultLists?.patientInfo.nickName.slice(0, 9)
                  : loginInfo.userId
                  ? loginInfo.nickName.slice(0, 9)
                  : ""}
              </li>
              {resultLists?.patientInfo.age &&
                resultLists?.patientInfo.gender && (
                  <li className="text-caption2_400 text_gray_160">
                    {resultLists.patientInfo.age || ""}세 /{" "}
                    {resultLists.patientInfo.gender || ""}
                  </li>
                )}
            </ul>
          </div>
          <span className="text-caption2_700 text-gray_160">
            발급일{" "}
            {resultLists?.patientInfo
              ? dayjs(resultLists.patientInfo.registDay).format("YYYY.MM.DD")
              : loginInfo.userId
              ? dayjs(loginInfo.createdAt).format("YYYY.MM.DD")
              : ""}
          </span>
        </div>
      )}
    </div>
  );
};

export default IdCard;
