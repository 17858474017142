import React from "react";
import { partners } from "./FifthPart";

const MobileFifthPart = () => {
  return (
    <section className="flex flex-col items-center bg-[#F8FBFF] px-[24px] py-12">
      <h3 className="mb-[24px] text-2xl font-extrabold">PARTNERS</h3>
      <p className="px-10 text-center text-base font-semibold text-[#3E3E3E]">
        힐리어리와 의료기관의 협업을 통해 암환자 식단관리를 위한 최적화된
        서비스를 제공하고 있습니다.
      </p>
      <div className="px-auto mt-[48px] grid w-full grid-cols-3 gap-1">
        {partners.map((partner) => (
          <div
            className="border-1 flex h-20 border border-gray_20 bg-white px-[10%] text-center"
            key={partner}
          >
            <img
              src={`/images/mobile/landing/partners/${partner}.png`}
              alt={partner}
              className="w-full h-auto m-auto"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default MobileFifthPart;
