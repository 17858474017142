import React from "react";
// import Parser from "html-react-parser";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { formatStringWithLinks } from "../logic";
import useLongPress from "../../hooks/useLongPress";
import ReactQuill from "react-quill";
import { formats } from "../../../admin/components/board/TextEditorToolbar";
import "react-quill/dist/quill.snow.css";

const PostDetailsCard = ({ data }) => {
  const { category, createdAt, title, writerAdmin, content, id } = data;
  const { action, handlers } = useLongPress();

  dayjs.locale("ko");
  console.log(category);
  // useEffect(() => {
  //     const images = document.getElementsByTagName('img')
  //     images.className('m-auto')
  //     // document.find('img').addClass('m-auto')
  // }, [])
  return (
    <>
      <div className="flex flex-col py-6 bg-white border-b">
        <div className="pb-6">
          <div className="pb-1 text-center">
            <span className="text-caption2_400 text-main">{category}</span>
          </div>
          <div className="px-6 text-center">
            <span className="break-all text-subtitle2 text-gray_160">
              {title}
            </span>
          </div>
        </div>
        <div className="flex px-6">
          <div className="w-12 h-12 mr-1 rounded-full bg-gray_30">
            <img
              className="object-cover w-12 h-12"
              src={writerAdmin?.photo}
              alt=""
            />
          </div>
          <div>
            <h2 className="text-body2_700 text-main">{writerAdmin?.name}</h2>
            <span className="text-caption2_400 text-gray_140">
              {dayjs(createdAt).format("YYYY-MM-DD A h:mm")}
            </span>
          </div>
        </div>
      </div>
      {/* <div className='m-6 break-words whitespace-pre-line text-body1_500l'>{Parser(content)}</div> */}
      {category === null ? (
        <div className="pb-20 m-6 break-words whitespace-pre-line text-body1_500l">
          <span
            className={"break-words text-body2_500l text-gray_180 "}
            style={{ whiteSpace: "pre-wrap" }}
            // DOM Purify를 사용하여 XSS 공격 방지
            // dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(linktify(content, main, postId))}}
          >
            {formatStringWithLinks(content, false, id, action, handlers)?.map(
              (content) => content
            )}
          </span>
        </div>
      ) : (
        <div className="pb-20 m-6">
          {/* <div id="viewer"></div> */}
          <ReactQuill
            className="w-full h-full ql-readonly"
            theme="snow"
            modules={{
              toolbar: false,
            }}
            formats={formats}
            // placeholder={"작성해주세요"}
            value={content}
            // onChange={(content, delta, source, editor) => {
            //   setEditorPost({ ...editorPost, content: editor.getHTML() });
            // }}
            // hooks={{
            //   addImageBlobHook: async (blob, callback) => {
            //     const res = await dispatch(
            //       addINfoCOntentImage({ contentFile: blob })
            //     ).unwrap();
            //     if (res.data.ok) {
            //       callback(res.data.path, "informationImg");
            //     }
            //   },
            // }}
            // ref={editorRef}
            readOnly={true}
          />
        </div>
      )}
    </>
  );
};

export default PostDetailsCard;
