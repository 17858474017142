import React, { useEffect, useRef, useState } from "react";
import TitleBox from "../common/TitleBox";
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const BodyMassCard = ({ myinfo, loginInfo, handleToggle }) => {
  const characterPhoto = loginInfo?.characterPhoto;
  const colorSet = ["#0E69F0", "#36B44D", "#FD7E14", "#CD410A"];
  const [colorIndex, setColorIndex] = useState(0);
  const [position, setPosition] = useState(0);
  const chartRef = useRef(null);
  const [animationEnd, setAnimationEnd] = useState(false);
  const [data, setData] = useState([
    // W = Weight
    {
      name: "data",
      underW: 5,
      normalW: 5,
      beforeObese: 5,
      obese: 5,
    },
  ]);

  const renderCustomizedLabel = (props, content) => {
    const { x, y, width } = props;

    // console.log(props);
    const tipStyle = {
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "15.51px",
      letterSpacing: "0.015em",
    };

    let numberWidth;
    if (content.toString().length === 4) {
      // numberWidth = x + width - 3;
      numberWidth = x + width - 12.5;
    } else {
      numberWidth = x + width - 8;
    }
    return (
      <g>
        <text
          x={numberWidth}
          y={y - 13}
          style={tipStyle}
          className="fill-gray_140"
        >
          {content}
        </text>
        <line
          x1={x + width}
          y1={y - 10}
          x2={x + width}
          y2={y}
          stroke="black"
          strokeWidth="0.3"
          strokeDasharray="2,1"
        />
      </g>
    );
  };
  const renderCustomizedName = (props, content) => {
    const { x, y, width } = props;
    const tipStyle = {
      fontWeight: 600,
      fontSize: "13px",
      lineHeight: "15.51px",
      letterSpacing: "0.015em",
    };
    const offSet =
      content.length === 2
        ? 11.5
        : content.length === 3
        ? 17
        : content.length === 4
        ? 23
        : 28.5;
    let stringPosition = x + width / 2 - offSet;

    return (
      <g>
        <text x={stringPosition} y={y + 28 + 5} style={tipStyle}>
          {content}
        </text>
      </g>
    );
  };
  const BarWithoutGap = () => {
    return (props) => {
      const { fill, x, y, width, height } = props;
      return (
        <g>
          <rect
            x={x}
            y={y}
            width={width + 1}
            height={height}
            stroke="none"
            fill={fill}
          />
        </g>
      );
    };
  };
  useEffect(() => {
    const bmi = myinfo?.bmi;
    // const bmi = 30;
    if (chartRef !== null && chartRef) {
      // let width = chartRef.current?.props?.viewBox?.width;
      let width = chartRef.current.clientWidth;
      if (bmi <= 0) setPosition(0);
      else if (bmi >= 30) {
        setPosition(width);
        setColorIndex(3);
      } else if (bmi > 0 && bmi < 18.5) {
        // console.log("Interval A >>> ", (fakeBMI * 5) / 18);
        setPosition((((bmi * 5) / 18.5) * width) / 20);
        setColorIndex(0);
      } else if (bmi >= 18.5 && bmi < 23) {
        // console.log("Interval B >>> ", fakeBMI - 18 + 5);
        setPosition(((((bmi - 18.5) * 5) / 4.5 + 5) * width) / 20);
        setColorIndex(1);
      } else if (bmi >= 23 && bmi < 25) {
        // console.log("Interval C >>> ", ((fakeBMI - 23 + 10) * 5) / 2);
        setPosition(((((bmi - 23) * 5) / 2 + 10) * width) / 20);
        setColorIndex(2);
      } else if (bmi >= 25 && bmi < 30) {
        // console.log("Interval D >>> ", fakeBMI - 25 + 15);
        setPosition(((bmi - 25 + 15) * width) / 20);
        setColorIndex(3);
      }
    }
  }, [chartRef, myinfo?.bmi]);
  // console.log(myinfo);
  useEffect(() => {
    setData(data);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="space-y-[28px] rounded-lg bg-orange_10 p-6" id="result">
      <div onClick={handleToggle} className="flex flex-row justify-between">
        <TitleBox option={1} title={"체중관리 가이드"} />
        <img
          alt={"arrowRight"}
          src="/images/mobile/icon/arrow_right.svg"
          className="transform rotate-90"
        />
      </div>

      <div className="flex flex-col gap-3 rounded-2xl bg-white px-3.5 py-5">
        <div className="flex flex-col justify-between px-1 py-1.5 ">
          <span className="ml-2 text-caption1_600">체중</span>
          <div className="flex justify-center gap-2">
            <span
              className="text-subtitle3"
              style={{ color: colorSet[colorIndex] }}
            >
              {myinfo?.weight}
            </span>
            <span className="mt-auto text-caption2_700"> kg</span>
          </div>
          <div className="mx-8 mt-1 border-t border-dashed border-gray_140" />
        </div>
        <div className="flex flex-col justify-between px-1 py-1.5">
          <span className="ml-2 text-caption2_700">BMI</span>
          {!(myinfo.kcal === undefined || myinfo.protein === undefined) ? (
            <div className="h-[80px] w-full" ref={chartRef}>
              <ResponsiveContainer>
                <BarChart
                  data={data}
                  layout="vertical"
                  height={80}
                  barSize={10}
                  margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
                >
                  <XAxis type="number" domain={[0, 20]} hide />
                  <YAxis type="category" hide />
                  <Tooltip
                    isAnimationActive={true}
                    wrapperStyle={{
                      visibility: "visible",
                      outline: "none",
                    }}
                    cursor={false}
                    active={false}
                    position={{ x: position - 10, y: 15 }}
                    content={(props) => {
                      return (
                        <img
                          alt="characterPhoto"
                          src={`/images/mobile/character/${characterPhoto}.svg`}
                          width="20px"
                        />
                      );
                    }}
                  />
                  <Bar
                    dataKey="underW"
                    stackId={"a"}
                    fill={colorSet[0]}
                    radius={[10, 0, 0, 10]}
                  >
                    <LabelList
                      content={(props) => renderCustomizedLabel(props, "18.5")}
                    />
                    <LabelList
                      content={(props) => renderCustomizedName(props, "저체중")}
                    />
                  </Bar>
                  <Bar
                    dataKey="normalW"
                    stackId={"a"}
                    fill={colorSet[1]}
                    shape={BarWithoutGap()}
                  >
                    <LabelList
                      content={(props) => renderCustomizedLabel(props, "23")}
                    />
                    <LabelList
                      content={(props) =>
                        renderCustomizedName(props, "정상체중")
                      }
                    />
                  </Bar>
                  <Bar
                    dataKey="beforeObese"
                    stackId={"a"}
                    fill={colorSet[2]}
                    shape={BarWithoutGap()}
                  >
                    <LabelList
                      content={(props) => renderCustomizedLabel(props, "25")}
                    />
                    <LabelList
                      content={(props) =>
                        renderCustomizedName(props, "비만전단계")
                      }
                    />
                  </Bar>
                  <Bar
                    dataKey="obese"
                    stackId={"a"}
                    fill={colorSet[3]}
                    radius={[0, 10, 10, 0]}
                    // isAnimationActive={false}
                    onAnimationEnd={() => {
                      setAnimationEnd(!animationEnd);
                    }}
                  >
                    <LabelList
                      content={(props) => renderCustomizedName(props, "비만")}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            "건강설문을 진행해주세요."
          )}
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col justify-center text-center text-body3_700 ">
          <div>
            현재 체중은{" "}
            <span style={{ color: colorSet[colorIndex] }}>
              {myinfo.weight}kg
            </span>
            로
          </div>
          <div>{myinfo.weightName} 상태입니다.</div>
        </div>
        <div className="flex flex-col justify-center text-center text-body3_700 ">
          <div>
            {myinfo.treatmentName ? myinfo.treatmentName : ""}
            {myinfo.targetWeightName}
            {myinfo.targetWeight ? "" : "을"}
          </div>
          <div>
            <span
              className={`${
                myinfo.targetWeightName === "조정 체중"
                  ? "text-orange_120"
                  : "text-green_120"
              } `}
            >
              {myinfo.targetWeight1
                ? `(${myinfo.targetWeight}~${myinfo.targetWeight1}kg)`
                : myinfo.targetWeight
                ? "(" + myinfo.targetWeight + "kg)"
                : ""}
            </span>
            <span>{myinfo.targetWeight ? "을 " : ""}</span>
            유지해 주세요.
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyMassCard;
