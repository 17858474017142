import React from "react";
import IdCard from "./IdCard";
import dayjs from "dayjs";
import "dayjs/locale/ko";
const HealthCard = ({ myInfoList, myInfo, handleToggle }) => {
  dayjs.locale("ko");
  const datas = [
    {
      id: 1,
      title: "나이 / 성별",
      data: myInfo?.userTypeCode
        ? myInfo.userTypeCode === "UT101"
          ? `${myInfoList?.patientInfo?.age}세 / ${myInfoList?.patientInfo?.gender}`
          : `${myInfoList?.age}세 / ${myInfoList?.gender}`
        : "- / -",
    },
    {
      id: 2,
      title: "키 / 체중",
      data: myInfo?.userTypeCode
        ? `${myInfoList?.height}cm / ${myInfoList?.weight}kg`
        : "- / -",
    },
    {
      id: 3,
      title: "암 종 및 병기",
      data: `${myInfoList?.cancerType || "-"} ${
        myInfoList?.cancerStaging || ""
      }`,
    },
    { id: 4, title: "수술여부", data: myInfoList?.operationYN || "-" },
    { id: 5, title: "수술시기", data: myInfoList?.operationAfter || "-" },
    {
      id: 6,
      title: "치료 종류",
      data: myInfoList?.anticancerTreatmentType || "-",
    },
    {
      id: 7,
      title: "치료 및 단계",
      data: myInfoList?.anticancerTreatmentYN || "-",
    },
  ];
  return (
    <div className=" rounded-[20px] bg-white">
      <ul className="flex flex-col gap-4 p-6">
        <li id="title">
          <span className="text-body3_700 text-black">건강관리 등록증</span>
        </li>
        {datas.map((data) => (
          <li key={data.id} className="flex justify-between gap-2">
            <span className="shrink-0 text-body3_700 text-gray_160">
              {data.title}
            </span>
            <span className="text-right text-body3_700 text-green_100">
              {data.data}
              {data.unit}
            </span>
          </li>
        ))}
      </ul>
      {myInfo?.userTypeCode === "UT101" ? (
        <div className="flex flex-col border-t border-dashed border-gray_80 ">
          <IdCard myInfo={myInfo} patientCard={true} />
          <span className="mb-6 text-center text-caption2_700 text-gray_160">
            발급일{" "}
            {dayjs(myInfoList?.patientInfo?.registDay).format("YYYY-MM-DD")}
          </span>
          {/* <div onClick={() => handleToggle()}>
            <img
              alt={"arrowRight"}
              src="/images/mobile/icon/arrow_right.svg"
              className="mx-auto mb-4 h-6 w-6 rotate-[270deg] transform"
            />
          </div> */}
        </div>
      ) : // <div className="flex flex-col" onClick={() => handleToggle()}>
      //   <img
      //     alt={"arrowRight"}
      //     src="/images/mobile/icon/arrow_right.svg"
      //     className="mx-auto mb-4 h-6 w-6 rotate-[270deg] transform"
      //   />
      // </div>
      null}
    </div>
  );
};

export default HealthCard;
