import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ dataName, actualData, step }) => {
  let data = [];

  for (let i = 0; i < 7; i++) {
    // 오늘인 경우 6개만 들어와서 따로 오늘 step 받아와서 넣어줌
    if (actualData[i] >= 0) {
      data.push(actualData[i]);
    } else {
      data.push(step || 0);
    }
  }

  const series = [
    {
      name: dataName, // 실제 데이터를 나타내는 시리즈
      data: data,
      color: "#FE718E", // 메인 색상
    },
  ];

  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false, // 차트 상단의 툴바를 숨깁니다.
      },
      // todo 전체 차트를 X축 방향으로 10px 이동 ios에서 이동 안함 확인
    },

    plotOptions: {
      bar: {
        borderRadius: 6, // 막대의 모서리 둥글기
        columnWidth: "50%", // 막대의 폭
        barHeight: "100%",
        rangeBarOverlap: true,

        colors: {
          ranges: [
            {
              from: 0,
              to: "100%",
              color: "#FFF2F5",
            },
          ],
          backgroundBarRadius: 1,
          backgroundBarColors: ["#FFF2F5"],
          backgroundBarRadius: 6,
        },
      },
    },
    dataLabels: {
      enabled: false, // 데이터 레이블(값) 표시 비활성화
    },
    legend: {
      show: false, // 범례 숨기기
    },
    grid: {
      show: false, // 그리드 숨기기
    },
    tooltip: {
      enabled: false, // 툴팁 활성화
    },
    xaxis: {
      labels: {
        show: false, // X축 레이블 숨기기
      },
      axisBorder: {
        show: false, // X축 경계선 숨기기
      },
      axisTicks: {
        show: false, // X축 눈금 숨기기
      },
    },
    yaxis: {
      labels: {
        show: false, // Y축 레이블 숨기기
      },
    },
  };

  return (
    <ReactApexChart
      options={chartOptions}
      series={series}
      type="bar"
      height="100"
      width="160"
    />
  );
};

export default BarChart;
