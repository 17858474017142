import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
/*component*/
import DetailTable from "../management/DetailTable";
import {
  fetchRecommendMeal
} from "../../../../features/admin/managementSlice";
import CustomCenterModal from "./CustomCenterModal";
import dayjs from "dayjs";

const RecommendMealDialog = ({ onCloseDialog, selected, setSelected }) => {
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const { recommendMealList,dateList, onMember } = useSelector((state) => state.management);
  const closesvg = "/images/admin/icon/close.svg";
  const onSetDate = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const array = value.split(",");
    let params = { userId: onMember.userId, searchDate: array[0], group: array[1] };
    dispatch(fetchRecommendMeal(params));
    setSelected({ mealD: array[0], gro: array[1] });
  };

  return (
    <CustomCenterModal
    onClickFc={onCloseDialog}
    customlayout={"w-3/4 h-4/5 rounded-md bg-gray_10  space-y-4 p-2 overflow-hidden"}>
      <div className="flex justify-between">
        <h2 className="text-body2_600 text-black">
          {selected?.mealD?selected?.mealD:dayjs(dateList[0]?.mealDate).format("YYYY-MM-DD")}
          {" "}일자 추천식단{" "}
          {selected?.gro?selected.gro:dateList[0]?.group}
        </h2>
        <img
          src={closesvg}
          alt="close"
          className="block cursor-pointer"
          onClick={onCloseDialog}
        />
      </div>
      {pathname === "/admin" || pathname === "/admin/management" || pathname === "/admin/account"? (
        <select
          onChange={onSetDate}
          value={`${selected.mealD},${selected.gro}`}
          className="flex justify-between p-2 rounded-md border border-gray_20 focus:outline-gray_100"
        >
          {dateList &&
            dateList?.map((data, idx) => (
              <option key={idx} 
              value={`${dayjs(data.mealDate).format("YYYY-MM-DD")},${data.group}`}
              >
                 {dayjs(data.mealDate).format("YYYY-MM-DD")}-{data.group}
              </option>
            ))}
        </select>
      ) : (
        ""
      )}
      <div className=" overflow-auto w-full h-4/5 flex gap-4">
        {recommendMealList?.map((eatDayLists, idx) => (
          <DetailTable
            key={idx}
            idx={idx}
            eatDayLists={eatDayLists}
            option={"recommandMeals"}
          />
        ))}
      </div>     
    </CustomCenterModal>
  );
};

export default RecommendMealDialog;
