import Paging from "../../../components/common/Paging";
import { useDispatch, useSelector } from "react-redux";
import ManagerUserList from "../ManagerUserList";
import FilterTab from "../../../components/management/FilterTab";
import CommonOption from "../../../components/common/CommonOption";
import { useState } from "react";
import SearchBox from "../../../components/common/SearchBox";
import PaginationLayout from "../../../components/common/pagination/PaginationLayout";
import { handlePageChange } from "../../../../../features/admin/commonSlice";

const Users = ({
  user,
  pagePerList,
  onChangeNameUserId,
  onMemberDetail,
  filterInfo,
  onClickSearch,
  tabName,
  hospitalList,
  onChangeInput,
}) => {
  const dispatch = useDispatch();
  const { totalPage } = useSelector((state) => state.management);
  const hospitalName = hospitalList?.map((el) => el.name);
  const onePageUsers = useSelector((state) => state.management.searchUserList);
  const [filterNamed, setFilteredNamed] = useState("");
  //---filter check
  // const filteredText = ["일반회원", "병원회원"];

  const onClickFilterdText = (param) => {
    setFilteredNamed(param);
    if (param === "일반회원") {
      filterInfo.filterUserType = "0";
    } else {
      filterInfo.filterUserType = "1";
    }
    filterInfo.filterHospitalCode = "";
    const whereisSelect = document.getElementById("filterHospitalCode");
    whereisSelect.value = "전체";
    onClickSearch("회원", 1);
    dispatch(handlePageChange(1));
  };
  const heringsAuthority = user.authority === "00" || user.authority === "01";

  const handlePageFn = (clickPage) => {
    dispatch(handlePageChange(clickPage));
    onClickSearch("회원", clickPage);
  };

  return (
    <div className="mb-6 flex w-full flex-col items-start rounded-b-[24px] rounded-r-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
      <div className="flex w-full flex-col items-start gap-[16px]">
        <div className="flex w-full flex-none flex-row items-center justify-between gap-[44px]">
          <div
            className={
              "flex h-[48px] w-full flex-row gap-[8px] " +
              (heringsAuthority
                ? " items-start justify-between "
                : " justify-end ")
            }
          >
            {/* {heringsAuthority ? (
              <div className="flex gap-[8px]">
                <div className="flex items-center divide-x rounded-lg caption_1 text-gray_100 ">
                  <FilterTab
                    text={filteredText[0]}
                    filterNamed={filterNamed}
                    onClick={() => onClickFilterdText(filteredText[0])}
                  />
                  <FilterTab
                    text={filteredText[1]}
                    filterNamed={filterNamed}
                    onClick={() => onClickFilterdText(filteredText[1])}
                  />
                </div>
                <CommonOption
                  lists={hospitalName}
                  ver={1}
                  selectid={"filterHospitalCode"}
                  onChangeFunction={onChangeNameUserId}
                  mywidth={114}
                />
              </div>
            ) : (
              ""
            )} */}
            <SearchBox
              value={filterInfo.searchNameOrID}
              onChange={(e) => onChangeInput(e)}
              onClick={() => {
                onClickSearch(tabName, 1);
                dispatch(handlePageChange(1));
              }}
              placeholder={"이름 또는 닉네임을 검색하세요."}
              width={296}
            />
          </div>
        </div>
        <div className="w-full gap-[16px]">
          <PaginationLayout
            totalPage={totalPage}
            pagePerList={pagePerList}
            onClick={(clickpage) => handlePageFn(clickpage)}
          >
            <ManagerUserList
              onMemberDetail={onMemberDetail}
              postPerPage={pagePerList}
              filteredUsers={onePageUsers}
            />
          </PaginationLayout>
        </div>
      </div>
    </div>
  );
};

export default Users;
