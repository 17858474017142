import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../../common/toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  setProfile,
} from "../../../../../features/mobile/settingSlice";
import Btn from "../../../components/common/Btn";
import HeadCont from "../../../components/common/HeadCont";
import InputSelectBox2 from "../../../components/common/InputSelectBox2";
import ToggleBtn from "../../../components/common/Toggle";
import ProfileCharacterChange from "../../../components/setting/ProfileCharacterChange";
import { useNavigate } from "react-router-dom";
import { addLoginInfo } from "../../../../../features/mobile/commonSlice";
import AnimateCard from "../../../components/common/AnimateCard";
import HealthCard from "../../../components/mypage/HealthCard";

const Profile = () => {
  const { loginInfo } = useSelector((state) => state.commonApp);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bottomRef = useRef(null);
  const [characterChange, setCharacterChange] = useState(false);
  // const [toggle, setToggle] = useState({
  //   cancerOpen: false,
  //   userTypeOpen: false,
  // });
  const myInfo = useSelector((state) => state.others.userInfo?.user);
  const handleToggle = (id, name) => {
    setOpenStates({ [id]: !openStates[id] });
  };
  const closedComponent = ({ color, id, title }) => (
    <div
      className={`flex flex-col items-center justify-between rounded-[20px] px-3 pt-3 ${color} `}
      onClick={() => {
        handleToggle(id, title);
      }}
    >
      <span className="text-body3_700 text-black">{title}</span>
      <img
        alt={"arrowRight"}
        src="/images/mobile/icon/arrow_right.svg"
        className="rotate-90 transform"
      />
    </div>
  );

  const [openStates, setOpenStates] = useState({ 0: false });
  const { resultLists } = useSelector((state) => state.questionHealth);

  const Rename = (param) => {
    return param === "암종" ? "cancerOpen" : "userTypeOpen";
  };
  const [nickName, setNickName] = useState(null);
  const [nickNameCount, setnickNameCount] = useState(0);

  const [introduce, setIntroduce] = useState(null);
  const [introduceCount, setintroduceCount] = useState(0);

  const [stickInfo, setStickInfo] = useState({
    cancerType: null,
    userType: null,
  });
  const [photo, setPhoto] = useState(null);
  const [oldNickName, setOldNickName] = useState(null);
  const [oldIntroduce, setOldIntroduce] = useState(null);
  const [oldPhoto, setOldPhoto] = useState(null);
  const [oldToggle, setOldToggle] = useState({
    cancerOpen: false,
    userTypeOpen: false,
  });
  const [checkNickname, setCheckNickname] = useState(false);
  const candidates = ["rabbit_blue", "rabbit_pink"];
  const chosen = Math.floor(Math.random() * candidates.length);

  const getFirstMyProfile = useCallback(async () => {
    const res = await dispatch(getProfile(loginInfo.userId)).unwrap();
    try {
      if (res.data.ok) {
        setStickInfo({
          cancerType: res.data.cancerType,
          userType: res.data.userType,
        });
        setIntroduce(res.data.introduce);
        setNickName(res.data.nickName);

        setOldNickName(res.data.nickName);
        setOldIntroduce(res.data.introduce);
        setOldToggle({
          cancerOpen: res.data.cancerOpen,
          userTypeOpen: res.data.userTypeOpen,
        });
        if (res.data.characterPhoto === null) {
          setOldPhoto(candidates[chosen]);
        } else {
          setOldPhoto(res.data.characterPhoto);
        }
      } else {
        toast(
          <p>{res.error}.</p>,
          toastCommonProps("bottom-center", "toast_alert", 1000)
        );
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [setIntroduce, setNickName, dispatch, loginInfo.userId]);

  const dispatchProfile = useCallback(
    (res, param) => {
      try {
        if (res.data.ok) {
          toast(
            <p>수정완료되었습니다.</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
          dispatch(addLoginInfo(param));
          navigate(-1);
        } else {
          toast(
            <p>{res.data.error}.</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
          setNickName(oldNickName);
        }
      } catch (error) {
        console.log(error);
      }
      // eslint-disable-next-line
    },
    [
      toastCommonProps,
      toast,
      dispatch,
      addLoginInfo,
      nickName,
      photo,
      navigate,
      setNickName,
    ]
  );
  const editProfile = async () => {
    const param = {
      userId: loginInfo.userId,
      nickName: nickName,
      introduce: introduce,
      characterPhoto: photo,
    };
    const param2 = {
      userId: loginInfo.userId,
      nickName: nickName,
      introduce: introduce,
    };
    if (photo) {
      const res = await dispatch(setProfile(param)).unwrap();
      dispatchProfile(res, { nickName: nickName, characterPhoto: photo });
    } else {
      const res = await dispatch(setProfile(param2)).unwrap();
      dispatchProfile(res, { nickName: nickName });
    }
  };
  useEffect(() => {
    getFirstMyProfile();
  }, [getFirstMyProfile]);

  useEffect(() => {
    const nickNameFilter = /[^a-zA-Z0-9\uAC00-\uD7A3]/g; // 닉네임 특수문자, 이모지 제외 정규식
    setnickNameCount(nickName?.length);

    if (nickNameFilter.test(nickName)) {
      setCheckNickname(true);
    } else {
      setCheckNickname(false);
    }
  }, [nickName]);

  useEffect(() => {
    setintroduceCount(introduce?.length);
  }, [introduce]);

  return (
    <>
      <HeadCont
        rightIcon={"no icon"}
        transparent={"off"}
        title={"프로필 관리"}
        underline={true}
      />
      <section className="relative mb-24 mt-14">
        <div className="flex justify-center pb-[16px] pt-[24px]">
          <div
            className="relative h-[72px] w-[80px] "
            onClick={() => setCharacterChange(true)}
          >
            <div className="relative h-full w-[72px] overflow-hidden rounded-[28px] bg-orange_40">
              <span
                className="absolute left-[-6px] top-0 h-[120px] w-[80px]"
                style={{
                  background: `url(/images/mobile/character/${
                    photo === null ? oldPhoto : photo
                  }.svg) no-repeat center`,
                }}
              ></span>
            </div>
            <span className="z-2 absolute bottom-0 right-0 rounded-full bg-gray_40">
              <img src="/images/mobile/icon/pencil.svg" alt="pencil" />
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 px-6">
          <div>
            <InputSelectBox2
              labelN={"닉네임"}
              placeH={"닉네임을 입력하세요"}
              htmlForN={"profileN"}
              nameTitle={"nickName"}
              setValue={setNickName}
              value={nickName}
              inputCount={nickNameCount}
            />
            <span
              className={
                " text-caption1_400 " +
                (checkNickname ? " text-error " : " text-gray_160 ")
              }
            >
              1~8자 한글,영어,숫자 입력이 가능합니다.
              {/* ({nickNameCount + "/ 8자"} ) */}
            </span>
          </div>
        </div>

        <div className="border-b px-6 py-4">
          <span className="text-caption1_600 text-gray_160">암종</span>
          <div className="flex items-center justify-between">
            <label className="text-body1_700 ">{stickInfo.cancerType}</label>
          </div>
        </div>
        <div className="border-b px-6 py-4">
          <span className="text-caption1_600 text-gray_160">사용자 구분</span>
          <div className="flex items-center justify-between">
            <label className="text-body1_700 ">{stickInfo.userType}</label>
          </div>
        </div>

        <div className="mt-4 px-6">
          {/* <AnimateCard
            id={0}
            openStates={openStates}
            closedComponent={closedComponent({
              color: "bg-white",
              id: 0,
              title: "나의 건강관리 등록증",
            })}
            height={64}
            contentComponent={
              <HealthCard
                myInfoList={resultLists}
                myInfo={myInfo}
                loginInfo={loginInfo}
                handleToggle={() => handleToggle(0, "나의 건강관리 등록증")}
              />
            }
          /> */}
          <HealthCard
            myInfoList={resultLists}
            myInfo={myInfo}
            loginInfo={loginInfo}
            handleToggle={() => handleToggle(0, "나의 건강관리 등록증")}
          />
        </div>

        <div className="fixed bottom-0 left-0 w-full border-t bg-back_ground py-6 ">
          <div className="maxW mx-auto flex flex-col gap-y-[8px] px-6">
            <Btn
              bgColor={"bg-main"}
              textColor={"text-white font-semibold"}
              borderColor={"border border-main"}
              title={"수정 완료"}
              active={
                !(nickName === "" || checkNickname === true) &&
                !(
                  (nickName === null || oldNickName === nickName) &&
                  oldIntroduce === introduce &&
                  (photo === null || oldPhoto === photo)
                )
                  ? "on"
                  : "ready"
              }
              onAction={editProfile}
            />
          </div>
        </div>
        <div ref={bottomRef} className="h-[40px]"></div>
      </section>
      {characterChange && (
        <ProfileCharacterChange
          setCharacterChange={setCharacterChange}
          photo={photo}
          setPhoto={setPhoto}
          oldPhoto={oldPhoto}
        />
      )}
    </>
  );
};

export default Profile;
