import React from "react";
import { motion } from "framer-motion";
import Parser from "html-react-parser";

const Dialog = ({
  onCoverAction,
  title,
  desc,
  innerClass,
  btnClass,
  leftBtnTitle,
  rightBtnTitle,
  onLeftBtnAction,
  onRightBtnAction,
  subTitle,
}) => {
  return (
    <div
      onClick={onCoverAction}
      className="fixed left-0 top-0 z-30 flex h-screen w-full items-center justify-center bg-black bg-opacity-70"
    >
      <motion.div
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeOut", duration: 1 }}
        onClick={(e) => e.stopPropagation()}
        className={
          "w-[317px] rounded-2xl bg-white shadow-[0_0_12px_rgba(64,64,64,0.2)] " +
          (innerClass ? innerClass : "")
        }
      >
        <h1 className="text-subtitle2 text-black">{title}</h1>
        {subTitle && (
          <h2 className="text-caption2_400 text-gray_160">{subTitle}</h2>
        )}
        {desc && (
          <div
            className={"text-body2_500l text-black " + (title ? "mt-4" : "")}
          >
            {desc ? Parser(desc) : ""}
          </div>
        )}
        {!onLeftBtnAction ? (
          <div className="text-right">
            <button
              onClick={onRightBtnAction}
              className={
                "text-btn_700 text-correct " + (btnClass ? btnClass : "")
              }
            >
              {rightBtnTitle || "확인"}
            </button>
          </div>
        ) : (
          <div className="mt-5 flex gap-2">
            <button
              onClick={onLeftBtnAction}
              className="w-1/2 min-w-fit rounded-2xl bg-main p-4 text-body3_700 text-white"
            >
              {leftBtnTitle || "확인"}
            </button>
            <button
              onClick={onRightBtnAction}
              className="w-1/2 min-w-fit rounded-2xl border border-main p-4 text-body3_700 text-main"
            >
              {rightBtnTitle || "확인"}
            </button>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default Dialog;
