import React from "react";
import ApexChart from "react-apexcharts";
import dayjs from "dayjs";
import { pointFn } from "../../components/logic";

const Graph = ({ graphOption, graphDatas, option }) => {
  const datas = graphDatas?.map((el) => {
    if (option === "calorie") {
      //칼로리
      return {
        day: el.day,
        data: el.kcal ? el.kcal : 0,
        line: el.needKcal ? el.needKcal : 0,
      };
    } else if (option === "protein") {
      //단백질
      return {
        day: el.day,
        data: el.proteinG ? el.proteinG : 0,
        line: el.needProteinG ? el.needProteinG : 0,
      };
    } else {
      return {
        day: el.day,
        data: el.weight ? el.weight : 0,
      };
    }
  })

  return (
    <div id="chart">
      {graphOption === 3 ? ( //area 그래프
        <ApexChart
          className="pt-[30px]"
          series={[{ data: datas?.map((el) => el.data) }]}
          options={{
            colors: [
              option === "calorie"
                ? "#FD7E14"
                : option === "protein"
                  ? "#339A45"
                  : "#FFE066",
            ],
            chart: {
              type: "area",
              height: 300,
              zoom: {
                autoScaleYaxis: false,
              },
              toolbar: {
                show: false,
              },
            },
            stroke: {
              curve: "straight",
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              labels: {
                rotate: 0,
              },
              categories: graphDatas.map((el, idx) => {
                if (
                  idx % (graphDatas.length / 3).toFixed(0) === 0 ||
                  idx === graphDatas.length - 1
                ) {
                  return dayjs(el.day).format("MM.DD");
                } else return "";
              }),
              position: "bottom",
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                  '<div class="arrow_box">' +
                  "<span class='text-caption1_700 text-gray_120'>" +
                  dayjs(graphDatas[dataPointIndex].day).format("M월 D일") +
                  " " +
                  "</span>" +
                  "<span class='text-caption1_700 text-black'>" +
                  series[seriesIndex][dataPointIndex] +
                  (option === "calorie" ? `kcal` : `g`) +
                  "</span>" +
                  "</div>"
                );
              },
            },
          }}
          type="area"
          height={300}
        />
      ) : option === "calorie" || option === "protein" ? ( //칼로리, 단백질 라인그래프
        <ApexChart
          className="pt-[30px] "
          id="lineGraph"
          series={[
            {
              name: "실제 섭취량",
              type: "line",
              data: datas?.map((el) => el.data),
            },
            {
              name: "목표 섭취량",
              type: "line",
              data: datas?.map((el) => el.line),
            },
          ]}
          options={{
            colors:
              option === "calorie"
                ? ["#FD7E14", "#0E69F0"]
                : ["#339A45", "#0E69F0"],
            chart: {
              type: "line",
              selection: {
                enabled: true,
              },
              toolbar: {
                show: false,
              },
            },
            stroke: {
              width: [2, 2],
              curve: "straight",
            },
            dataLabels: {
              enabled: graphOption === 1 ? true : false,
              formatter: function (val) {
                return pointFn(val);
              },
              offsetY: -6,
              style: {
                fontSize: "12px",
                colors: ["#8B8B8B"],
              },
              background: {
                enabled: false,
              },
              enabledOnSeries: [0],
            },
            legend: {
              show: true,
              showForSingleSeries: true,
              customLegendItems: ["실제 섭취량", "필요 섭취량"],
              position: "bottom",
              horizontalAlign: "right",
              fontSize: "16px",
              markers: {
                width: 16,
                height: 16,
                radius: 0,
                fillColors:
                  option === "calorie"
                    ? ["#FD7E14", "#0E69F0"]
                    : ["#339A45", "#0E69F0"],
              },
            },
            xaxis: {
              categories:
                graphOption === 2
                  ? datas?.map((el, idx) => {
                    if (idx % 7 === 0) {
                      return dayjs(el.day).format("MM.DD");
                    } else return "";
                  })
                  : datas?.map((el) => dayjs(el.day).format("MM.DD")),
              labels: {
                rotate: 0,
              },
              position: "bottom",
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              crosshairs: {
                // 막대위 dashed line
                width: 1,
                stroke: {
                  color: "#8B8B8B",
                  width: 0.5,
                  height: 210,
                },
              },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              tickAmount: 5,
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: graphOption === 1 ? false : true,
                formatter: function (val) {
                  return pointFn(val);
                },
              },
            },
            tooltip: {
              enabled: true,
              shared: true,
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                  '<div class="arrow_box">' +
                  "<span class='text-caption1_700 text-gray_120'>" +
                  dayjs(datas[dataPointIndex].day).format("MM.DD") +
                  " " +
                  "</span>" +
                  "<span class='text-caption1_700 text-black'>" +
                  series[seriesIndex][dataPointIndex] +
                  (option === "calorie" ? `kcal` : `g`) +
                  "</span>" +
                  "</div>"
                );
              },
            },
          }}
          type="line"
          height={270}
        />
      ) : (
        <ApexChart //체중 라인그래프
          className="pt-[30px]"
          series={[{ data: datas?.map((el) => el.data) }]}
          options={{
            colors: ["#FFE066"],
            chart: {
              selection: {
                enabled: true,
              },
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                dataLabels: {
                  position: "top", // top, center, bottom
                },
              },
            },
            stroke: {
              width: [2, 2],
              curve: "straight",
            },
            dataLabels: {
              enabled: graphOption === 1 ? true : false,
              formatter: function (val) {
                return pointFn(val);
              },
              offsetY: -6,
              style: {
                fontSize: "12px",
                colors: ["#8B8B8B"],
              },
              background: {
                enabled: false,
              },
              enabledOnSeries: [0],
            },
            xaxis: {
              categories:
                graphOption === 2
                  ? datas?.map((el, idx) => {
                    if (idx % 7 === 0) {
                      return dayjs(el.day).format("MM.DD");
                    } else return "";
                  })
                  : datas?.map((el) => dayjs(el.day).format("MM.DD")),
              labels: {
                rotate: 0,
              },
              position: "bottom",
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              crosshairs: {
                //bar background
                // 막대위 dashed line
                width: 1,
                stroke: {
                  color: "#A39F9B",
                  width: 0.5,
                },
              },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              tickAmount: 5,
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: graphOption === 1 ? false : true,
                formatter: function (val) {
                  return pointFn(val);
                },
              },
            },
            tooltip: {
              enabled: true,
              shared: true,
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                  '<div class="arrow_box">' +
                  "<span class='text-caption1_700 text-gray_120'>" +
                  dayjs(datas[dataPointIndex].day).format("MM.DD") +
                  " " +
                  "</span>" +
                  "<span class='text-caption1_700 text-black'>" +
                  series[seriesIndex][dataPointIndex] +
                  `kg` +
                  "</span>" +
                  "</div>"
                );
              },
            },
          }}
          type="line"
          height={270}
        />
      )}
    </div>
  );
};

export default Graph;
