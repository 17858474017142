import dayjs from "dayjs";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  deleteAnswer,
  editCenterAnswer,
  getAnswer,
  onSetCommTab,
  saveCenterAnswer,
  searchCustomerCenter,
} from "../../../../features/admin/communitySlice";
import Navbar from "../../components/common/Navbar";
import Sidebar from "../../components/common/Sidebar";
import PhotoComp from "../../components/common/PhotoComp";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import {
  onSetSection,
  onSetSubSection,
} from "../../../../features/admin/commonSlice";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../components/common/ButtonCustom";
import CustomerCenterDetailForm from "../../components/board/CustomerCenterDetailForm";
import ConfirmModal from "../../components/common/ConfirmModal";
import PhotoCompRemain from "../../components/common/PhotoCompRemain";
import ImageClick from "../../components/ImageClick";
import { resizeFile } from "../../../../common/util/base";

const CenterDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadedImage, setLoadedImage] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [prevbtn, setPrevbtn] = useState();
  const [nextbtn, setNextbtn] = useState();
  const { pagePerList } = useSelector((state) => state.pagination);
  const QID = useSelector((state) => state.commu.customerCenterDetailState);
  const { loginInfo } = useSelector((state) => state.adminLogin);

  const [question, setQuestion] = useState({
    id: QID,
    aWaiting: null,
    userId: "",
    title: "",
    content: "",
    files: [],
    updatedAt: "",
    createdAt: "",
  });
  const [answer, setAnswer] = useState({
    id: 0,
    createdAt: "",
    updatedAt: "",
    adminId: "",
    title: "",
    content: "",
    files: [],
    remainingFiles: [],
    questionId: 0,
  });
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);

  const showConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const onTheInput = (e) => {
    const { name, value } = e.target;
    setAnswer({ ...answer, [name]: value });
  };
  const prevCenter = () => {
    if (prevbtn !== undefined) {
      defaultAnswer();
      getAnswerId(prevbtn);
    } else {
      toast(
        <p>이전글이 없습니다.</p>,
        toastCommonProps("top-right", "toast_alert", 1000)
      );
    }
  };

  const nextCenter = () => {
    if (nextbtn !== undefined) {
      defaultAnswer();
      getAnswerId(nextbtn);
    } else {
      toast(
        <p>다음글이 없습니다.</p>,
        toastCommonProps("top-right", "toast_alert", 1000)
      );
    }
  };

  const deleteImage = (image, no) => {
    setLoadedImage([...loadedImage?.filter((el) => el !== image)]);
    answer.files.splice(no, 1);
    setAnswer({ ...answer, files: answer.files });
  };

  const defaultAnswer = useCallback(() => {
    setAnswer({
      adminId: loginInfo?.adminId,
      title: "",
      content: "",
      files: null,
      questionId: QID,
    });
    setLoadedImage([]);
  }, [setAnswer, setLoadedImage]);

  const saveOk = useCallback(
    (toastTxt) => {
      defaultAnswer();
      toast(
        <p>{toastTxt}</p>,
        toastCommonProps("top-right", "toast_alert", 1000)
      );
      let params2 = {
        page: 1,
        countPerPage: pagePerList,
        userId: "",
        title: "",
        content: "",
      };
      dispatch(searchCustomerCenter(params2));
      setTimeout(() => navigate(-1), 500);
    },
    [searchCustomerCenter]
  );

  const saveCustomerCenter = async () => {
    if (!alreadyAnswered) {
      //최초 save
      let paramfirst = {
        adminId: loginInfo?.adminId,
        title: answer?.title,
        content: answer?.content,
        files: answer?.files,
        questionId: QID,
      };
      const res = await dispatch(saveCenterAnswer(paramfirst)).unwrap();
      try {
        if (res.ok) {
          saveOk("답변 저장완료.");
        } else {
          console.log(res, ">>new save");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      //answer.id 있을 경우 edit
      let param = {
        id: answer?.id,
        adminId: loginInfo?.adminId,
        title: answer?.title,
        content: answer?.content,
        files: answer?.files,
        questionId: QID,
        remainingFiles: answer?.remainingFiles,
      };
      const res = await dispatch(editCenterAnswer(param)).unwrap();
      try {
        if (res.ok) {
          saveOk("답변 수정완료.");
        } else {
          console.log(res, ">>edit save");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getAnswerId = useCallback(async (propid) => {
    const res = await dispatch(getAnswer(propid)).unwrap();
    try {
      if (res.data.ok) {
        setQuestion(res.data.question);
        if (res.data.answer) {
          //이미 답변 있을경우
          const answerData = res.data.answer;
          setAlreadyAnswered(true);

          setAnswer({
            id: answerData.id,
            createdAt: answerData.createdAt,
            updatedAt: answerData.updatedAt,
            adminId: answerData.adminId,
            title: answerData.title,
            content: answerData.content,
            files: [],
            remainingFiles: answerData.files,
            questionId: answerData.questionId,
          });
        }
        setPrevbtn(res.data.preCustomerCenterQID);
        setNextbtn(res.data.nextCustomerCenterQID);
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const deleteCustomerCAnswer = async () => {
    const res = await dispatch(deleteAnswer(answer?.id)).unwrap();
    if (res.data.ok) {
      setAlreadyAnswered(false);
      defaultAnswer();
      toast(
        <p>답변이 삭제되었습니다.</p>,
        toastCommonProps("top-right", "toast_alert", 1000)
      );
    }
  };
  const onChangeFile = async (e) => {
    let fileUrls = [];
    let cameraArr = [];
    if (
      e.target.files?.length +
        answer?.remainingFiles?.length +
        loadedImage?.length >
      5
    )
      alert("파일을 최대 5개 선택해주세요.");
    else {
      for (let i = 0; i < e.target.files?.length; i++) {
        let reader = new FileReader();
        let resize = await resizeFile(e.target.files[i]);
        reader.readAsDataURL(resize);
        cameraArr.push(resize);

        if (answer?.files) {
          //답변파일 있는경우
          setAnswer({
            ...answer,
            files: answer.files.concat([...cameraArr]),
          });
        } else {
          setAnswer({
            ...answer,
            files: [...cameraArr],
          });
        }
        reader.onload = async () => {
          fileUrls[i] = reader.result;
          loadedImage?.length > 0
            ? setLoadedImage(loadedImage.concat([...fileUrls]))
            : setLoadedImage([...fileUrls]);
        };
      }
      e.target.value = "";
    }
  };

  useEffect(() => {
    if (loginInfo?.adminId) {
      getAnswerId(QID);
    } else {
      navigate("/admin/signin");
    }
  }, []);

  const goCustomerCenter = () => {
    navigate("/admin/board");
    dispatch(onSetCommTab("1:1 이용문의"));
    dispatch(onSetSubSection("1:1 이용문의"));
    dispatch(onSetSection("게시판 관리"));
  };
  const noBlankTitleContent = !(answer?.title === "" || answer?.content === "");
  // console.log(question, "q");
  // console.log(answer, "answer");
  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex flex-col items-start justify-start gap-[16px] p-[24px]">
          <div className="flex h-full w-full flex-col items-start justify-start p-0">
            <div className="w-a uto order-1 flex h-full flex-none grow-0 flex-col items-start justify-start self-stretch">
              <div className="flex w-full flex-col items-start gap-[8px] rounded-3xl bg-[#FCFCFC] p-[16px]  px-[14px] shadow-md">
                <div className="flex w-full items-center justify-between px-[12px]">
                  <div className="body_1 font-semibold text-black">
                    1:1 이용문의
                  </div>
                  <div className="flex gap-[12px]">
                    <BGButtonCustom
                      onClick={prevCenter}
                      content={"이전 글"}
                      bgcolor={"bg-gray_70"}
                      textcolor={"text-gray_10"}
                      textsize={"text-caption1_600"}
                      icon={"/images/admin/icon/centerUp.svg"}
                      xpadding={"12px"}
                    />
                    <BGButtonCustom
                      onClick={nextCenter}
                      content={"다음 글"}
                      bgcolor={"bg-gray_70"}
                      textcolor={"text-gray_10"}
                      textsize={"text-caption1_600"}
                      icon={"/images/admin/icon/centerDown.svg"}
                      xpadding={"12px"}
                    />
                    <BGButtonCustom
                      onClick={goCustomerCenter}
                      content={"목록"}
                      bgcolor={"bg-gray_70"}
                      textcolor={"text-gray_10"}
                      textsize={"text-caption1_600"}
                      xpadding={"12px"}
                    />
                  </div>
                </div>
                <CustomerCenterDetailForm title={"1:1 문의 내용"}>
                  <div className="flex w-full items-start">
                    <div className="flex flex-col gap-[8px] ">
                      <p className="text-xl font-bold text-[#05040D]">
                        {question.title}
                      </p>
                      <div className="flex items-center gap-x-[12px]">
                        <p className="text-lg font-semibold text-[#05040D]">
                          {question.nickName ? question.nickName : ""}
                          {question.nickNameProtector
                            ? `(보:${question.nickNameProtector})`
                            : ""}
                        </p>
                        <p className="text-[#463F38]">
                          {dayjs(question.createdAt).format("YYYY-MM-DD")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-x-[16px]">
                    {question.files !== null &&
                      question.files?.map((el, idx) => (
                        <div
                          key={idx}
                          className="flex h-[240px] w-[240px] cursor-pointer justify-center bg-[#F3F3F2]"
                        >
                          <img
                            src={el}
                            alt="community"
                            className="max-h-[240px] max-w-[240px] "
                          />
                        </div>
                      ))}
                  </div>
                  {question.files !== null && (
                    <ImageClick postings={question} />
                  )}
                  <span className="flex w-full items-center gap-[4px] rounded-[8px] pt-4 text-[#05040D]">
                    {question.content}
                  </span>
                </CustomerCenterDetailForm>
                {answer && ( //새로운 답변 , 답변 수정
                  <CustomerCenterDetailForm title={"답변 작성"}>
                    <div className="flex flex-col gap-y-[24px] p-[24px]">
                      <div className="flex w-full items-center gap-[8px]">
                        <p className="flex w-[50px] text-[#463F38]">제목</p>
                        <input
                          name="title"
                          value={answer ? answer?.title : ""}
                          onChange={onTheInput}
                          placeholder="제목을 입력하세요. (최대 20자)"
                          className="w-full rounded-[4px] border border-[#A39F9B] bg-transparent px-[20px] py-[12px] outline-none"
                        />
                      </div>
                      <div className="flex w-full items-center gap-[8px]">
                        <p className="flex h-[24px] w-[50px] items-center text-[#463F38]">
                          내용
                        </p>
                        <textarea
                          name="content"
                          value={answer ? answer?.content : ""}
                          onChange={onTheInput}
                          placeholder="내용을 입력하세요."
                          className=" h-[324px] w-full rounded-[4px] border border-[#A39F9B] bg-transparent px-[20px] py-[12px] outline-none"
                        />
                      </div>
                      <div className="flex items-start gap-[8px] rounded-[4px]">
                        <p className="flex w-[50px] items-center text-[#463F38]">
                          이미지
                        </p>
                        <div className="flex gap-x-2">
                          <PhotoComp
                            onChangeFile={onChangeFile}
                            deleteImage={deleteImage}
                            loadedImage={loadedImage}
                          />
                          <PhotoCompRemain
                            editData={answer}
                            setEditData={setAnswer}
                            loadedImage={loadedImage}
                            setLoadedImage={setLoadedImage}
                          />
                        </div>
                      </div>
                      <div className="flex w-full items-center justify-end gap-[8px]">
                        <BorderButtonCustom
                          onClick={showConfirmModal}
                          content={"취소"}
                          bgcolor={""}
                          textcolor={"text-orange_100"}
                          bordercolor={"border-orange_100"}
                          textsize={"text-caption1_600"}
                          xpadding={"12px"}
                        />
                        {answer.content !== "" && answer.id !== 0 ? (
                          <BorderButtonCustom
                            onClick={deleteCustomerCAnswer}
                            content={"답변 삭제"}
                            bgcolor={""}
                            textcolor={"text-orange_100"}
                            bordercolor={"border-orange_100"}
                            textsize={"text-caption1_600"}
                            xpadding={"12px"}
                          />
                        ) : null}
                        <BGButtonCustom
                          onClick={
                            noBlankTitleContent ? saveCustomerCenter : undefined
                          }
                          content={answer.id !== 0 ? "수정" : "저장"}
                          bgcolor={
                            noBlankTitleContent
                              ? "bg-orange_100"
                              : "bg-orange_60"
                          }
                          textcolor={"text-gray_10"}
                          textsize={"text-caption1_600"}
                          xpadding={"12px"}
                        />
                      </div>
                    </div>
                  </CustomerCenterDetailForm>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {confirmModal && (
        <ConfirmModal
          showConfirmModal={showConfirmModal}
          defaultAnswer={defaultAnswer}
          flag="customerdetail"
        />
      )}
    </>
  );
};

export default CenterDetail;
