
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Paging from "../common/Paging";

import {
  HeadList_FAQ,
  HeadList_식사가이드,
  HeadList_종합가이드,
  HeadList_증상가이드,
  HeadList_체중가이드,
} from "../common/TableHeadList";
import ContentsTableHeader from "./ContentsTableHeader";
import FullGuideInnerTable from "./innerData/FullGuideInnerTable";
import PagingInnerTable from "./innerData/PagingInnerTable";

const ContentsTable = ({ showEditModal, tabName, DataList }) => {
  const { page } = useSelector((state) => state.common);
  const [pageUserCount] = useState(10); //페이지당 포스트 개수
  let EachPage =DataList !==undefined ? (DataList.slice((page - 1) * pageUserCount, page * pageUserCount)):[]

  const cellStyle =
    "text-center py-4 text-caption_1 text-black border-gray_60 border-l border-b ";
  return (
    <>
      <div className="w-full justify-center items-center">
        {EachPage?.length !==0 || tabName ==="종합가이드"?
          <table
          className="w-2/3 table-auto overflow-hidden mb-[14px] m-auto border-separate"
          cellSpacing={0}
        >
          <ContentsTableHeader
            HeadList={
              tabName === "종합 가이드"
                ? HeadList_종합가이드
                : tabName === "식사 가이드"
                ? HeadList_식사가이드
                : tabName === "체중 가이드"
                ? HeadList_체중가이드
                : tabName === "증상 가이드"
                ? HeadList_증상가이드
                : tabName === "FAQ"
                ? HeadList_FAQ
                : ""
            }
            isPage={DataList?.length === 0}
          />
          <tbody className="px-3">
          {tabName ==="종합 가이드" ?
            DataList?.map((data,idx)=>(
              <FullGuideInnerTable 
              key={idx}
              data={data} idx={idx} 
              showEditModal={showEditModal}
              cellStyle={cellStyle}
              DataList={DataList}
              pageUserCount={pageUserCount}
              page={page}
              />
            ))
            :
            tabName ==="식사 가이드"?
            EachPage?.map((data,idx)=>(
              <PagingInnerTable
              key={idx}
              data={data} idx={idx} 
              showEditModal={showEditModal}
              cellStyle={cellStyle}
              EachPage={EachPage}
              pageUserCount={pageUserCount}
              page={page}
              />
            ))
            :
            tabName ==="체중 가이드"?
            EachPage?.map((data,idx)=>(
              <PagingInnerTable
              key={idx}
              data={data} idx={idx} 
              showEditModal={showEditModal}
              cellStyle={cellStyle}
              EachPage={EachPage}
              pageUserCount={pageUserCount}
              page={page}
              />
            )):
            tabName ==="증상 가이드"?
            EachPage?.map((data,idx)=>(
              <PagingInnerTable
              symptom={true}
              key={idx}
              data={data} idx={idx} 
              showEditModal={showEditModal}
              cellStyle={cellStyle}
              EachPage={EachPage}
              pageUserCount={pageUserCount}
              page={page}
              />
            )):
            //faq
            EachPage?.map((data,idx)=>(
              <PagingInnerTable
              faq={true}
              key={idx}
              data={data} idx={idx} 
              showEditModal={showEditModal}
              cellStyle={cellStyle}
              EachPage={EachPage}
              pageUserCount={pageUserCount}
              page={page}
              />
            ))
            }
          </tbody>
        </table>
        :
        <div className="flex w-full h-[200px] flex justify-center items-center font-semibold">
          내용을 준비중입니다.
        </div>
      }
      </div>
      {EachPage?.length ===0 || tabName === "종합 가이드" ? "" : (
        <Paging
          totalSearchCount={DataList!==undefined?DataList?.length:0}
          pageUserCount={pageUserCount}
          pageRangeDisplayed={5}
        />
      )}
    </>
  );
};

export default ContentsTable;
