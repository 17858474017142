import { useLocation } from "react-router-dom";

import AccountTabs from "../account/AccountTabs";
import CommuTabs from "../board/CommuTabs";
const CommonTabs = ({ user, filterInfo, tabName, onClickSearch }) => {
  let path = useLocation().pathname;
  const COMMUTABS = [
    "소식",
    "1:1 이용문의",
    "정보",
    "음식DB요청",
    "CancerGPT",
    "공지사항",
  ];
  const ACCOUNTTABS = ["회원", "관리자"];
  //hospital
  const ACCOUNTTABS01 = ["회원"];

  return (
    <div className="flex">
      {path.includes("board") ? (
        <CommuTabs COMMUTABS={COMMUTABS} />
      ) : (
        <>
          {user.authority === "00" || user.authority === "10" ? (
            <AccountTabs
              ACCOUNTTABS={ACCOUNTTABS}
              filterInfo={filterInfo}
              user={user}
              tabName={tabName}
              onClickSearch={onClickSearch}
            />
          ) : (
            <AccountTabs
              ACCOUNTTABS={ACCOUNTTABS01}
              filterInfo={filterInfo}
              user={user}
              tabName={tabName}
              onClickSearch={onClickSearch}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CommonTabs;
