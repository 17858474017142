import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../common/api";

//게시판 관리 slice
export const searchCustomerCenter = createAsyncThunk(
  "community/searchCustomerCenter",
  async (params) => {
    const res = await api.post("/admin/board/customerCenter/search", params);
    return res.data;
  }
);

export const getAllCenter = createAsyncThunk(
  "community/getAllhUser",
  async () => {
    const res = await api.get("/admin/board/customerCenter");
    if (res.data.ok) {
      return res.data;
    }
  }
);

export const getAnswer = createAsyncThunk("admin/getAnswer", async (params) => {
  //1:1 이용문의 답변가져오기
  const res = await api.get(`/customerCenter/questionIdAnswer/${params}`);
  return res;
});

export const deleteAnswer = createAsyncThunk(
  "admin/deleteAnswer",
  async (params) => {
    //1:1 이용문의 답변삭제
    const res = await api.get(`/customerCenter/deleteAnswer/${params}`);
    return res;
  }
);

export const searchManagementCommunity = createAsyncThunk(
  "community/searchCommu",
  async (params) => {
    const res = await api.post("/admin/board/community/search", params);
    return res.data;
  }
);

export const getAllReportedPosting = createAsyncThunk(
  "community/getAllReportedPosting",
  async (params) => {
    const res = await api.post("/posting/getAllReportedPostings", params);
    return res.data;
  }
);

export const getCenterAwaiting = createAsyncThunk(
  "community/awaiting",
  async (params) => {
    const res = await api.post("/admin/board/customerCenter/awaiting", params);
    return res.data;
  }
);

export const getAllRepliesOfOnePosting = createAsyncThunk(
  "community/getAllRepliesOfOnePosting",
  async (params) => {
    const res = await api.post("/posting/getAllRepliesOfOnePosting", params);
    return res.data;
  }
);

export const editPostingCommunity = createAsyncThunk(
  "posting/editPostingCommunity",
  async (param) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("id", param.id);
    formdata.append("title", param.title);
    formdata.append("fixedOnTop", param.fixOnTop);
    formdata.append("content", param.content);
    formdata.append("writerAdminAdminId", param.writerAdminAdminId);
    formdata.append("isHide", param.isHide);
    formdata.append("notice", param.notice);
    formdata.append("event", param.event);
    formdata.append("news", param.news);

    formdata.append("sendingAlarm", param.sendingAlarm);
    if (param.remainingFiles !== null && param.remainingFiles !== undefined) {
      //이전 저장파일
      param.remainingFiles.forEach((file) =>
        formdata.append("remainingFiles", file)
      );
    }
    if (param.files)
      param.files.forEach((file) => formdata.append("files", file));
    const res = await api.post("/posting/editPosting", formdata, config);
    return res;
  }
);

export const toggleHide = createAsyncThunk("community/hide", async (params) => {
  const res = await api.post("/posting/setHide", params);
  return res;
});
export const addReply = createAsyncThunk(
  "community/postingaddReply",
  async (params) => {
    const res = await api.post("/posting/addReply", params);
    return res.data;
  }
);
export const deleteReply = createAsyncThunk(
  "community/deleteReply",
  async (params) => {
    const res = await api.post(`/posting/deleteReply`, params);
    return res.data;
  }
);

export const deletePostings = createAsyncThunk(
  "community/deletePosting",
  async (params) => {
    const res = await api.post(`/posting/delete`, params);
    return res;
  }
);

export const addPostingAdmin = createAsyncThunk(
  "community/addPosting",
  async (param) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("writerAdminAdminId", param.writerAdminAdminId);
    formdata.append("notice", param.notice);
    formdata.append("event", param.event);
    formdata.append("news", param.news);

    formdata.append("sendingAlarm", param.sendingAlarm);
    formdata.append("fixOnTop", param.fixOnTop);
    formdata.append("isHide", param.isHide);
    formdata.append("title", param.title);
    formdata.append("content", param.content);
    param.files.forEach((file) => formdata.append("files", file));
    const res = await api.post(`/posting/addPosting`, formdata, config);
    return res;
  }
);

export const saveCenterAnswer = createAsyncThunk(
  "customerCenter/saveCenterAnswer", //1대1 이용문의 질문하기 등록
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("adminId", params.adminId);
    formdata.append("title", params.title);
    formdata.append("content", params.content);
    formdata.append("questionId", params.questionId);
    if (params.files !== null && params.files !== undefined) {
      params.files.forEach((file) => formdata.append("files", file));
    }
    const res = await api.post(`/customerCenter/addAnswer`, formdata, config);
    return res.data;
  }
);

export const editCenterAnswer = createAsyncThunk(
  "customerCenter/editCenterAnswer", //1:1 이용문의 질문하기 수정
  async (params) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("id", params.id);
    formdata.append("adminId", params.adminId);
    formdata.append("title", params.title);
    formdata.append("content", params.content);
    formdata.append("questionId", params.questionId);

    if (params.files !== null && params.files !== undefined) {
      params.files.forEach((file) => formdata.append("files", file)); // 새로 추가된 파일
    }
    if (params.remainingFiles !== null && params.remainingFiles !== undefined) {
      params.remainingFiles.forEach((file) =>
        formdata.append("remainingFiles", file)
      ); //이전 저장파일
    }
    const res = await api.post(`/customerCenter/editAnswer`, formdata, config);
    return res.data;
  }
);

export const getFoodRegistrationRequest = createAsyncThunk(
  "customerCenter/getFoodRegistrationRequest", //음식DB요청
  async (param) => {
    const res = await api.post("/food/getFoodRegistrationRequest", param);
    return res.data;
  }
);

export const getFoodRegistrationRequestShowAll = createAsyncThunk(
  "customerCenter/getFoodRegistrationRequestShowAll", //음식DB 전체조회
  async () => {
    const auth = 1; //authorization아무거나
    const res = await api.get("/food/getFoodRegistrationRequest", auth);
    return res.data;
  }
);

export const addINfoCOntentImage = createAsyncThunk(
  "posting/addInfoContentImage", //text editor 이미지 s3저장
  async (param) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("contentFile", param.contentFile);
    const res = await api.post(
      "/posting/addInfoContentImage",
      formdata,
      config
    );
    return res;
  }
);

export const addInfoPosting = createAsyncThunk(
  "posting/addInfoPosting",
  async (param) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("category", param.category);
    formdata.append("title", param.title);
    formdata.append("thumbnail", param.thumbnail[0]);
    formdata.append("content", param.content);
    formdata.append("writerAdminAdminId", param.writerAdminAdminId);
    formdata.append("isHide", param.isHide);
    for (const cancerType of param.infoCancerTypes) {
      formdata.append("infoCancerTypes[]", cancerType);
    }
    if (param.sendingAlarm) {
      formdata.append("sendingAlarm", param.sendingAlarm);
    }
    const res = await api.post("/posting/addInfoPosting", formdata, config);
    return res;
  }
);

//여기수정
export const editInfoPosting = createAsyncThunk(
  "posting/editInfoPosting",
  async (param) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("id", param.id);
    formdata.append("category", param.category);
    formdata.append("writerAdminAdminId", param.writerAdminAdminId);
    formdata.append("isHide", param.isHide);
    formdata.append("title", param.title);
    formdata.append("content", param.content);
    if (param.infoCancerTypes?.length > 0) {
      for (const cancerType of param.infoCancerTypes) {
        formdata.append("infoCancerTypes[]", cancerType);
      }
    } else {
      formdata.append("infoCancerTypes[]", []);
    }

    if (param.sendingAlarm) {
      formdata.append("sendingAlarm", param.sendingAlarm);
    }
    if (typeof param.thumbnail !== "string") {
      //파일 수정할 경우 string으로 들어오므로, 새로 수정할 경우만 formData에 태워보내기
      formdata.append("thumbnail", param.thumbnail[0]);
    }

    const res = await api.post("/posting/editInfoPosting", formdata, config);
    return res;
  }
);

export const searchInformation = createAsyncThunk(
  "communit/searchInfo",
  async (param) => {
    const res = await api.post(`/admin/board/community/searchInfo`, param);
    return res.data;
  }
);

export const searchCancerGPT = createAsyncThunk(
  "communit/searchCancerGPT",
  async (param) => {
    const res = await api.post(
      `/admin/board/customerCenter/searchChatGPT`,
      param
    );
    return res.data;
  }
);
export const getALLCancerGPT = createAsyncThunk(
  "communit/getALLCancerGPT",
  async (param) => {
    const res = await api.post(
      `/admin/board/customerCenter/searchChatGPT`,
      param
    );
    return res.data;
  }
);
export const deleteAllTestGPT = createAsyncThunk(
  "communit/deleteAllTestGPT",
  async () => {
    const res = await api.get(
      "/admin/board/customerCenter/deleteHeringsUserChatGPT"
    );
    return res.data;
  }
);
export const getNotice = createAsyncThunk(
  "communit/getNotice",
  async (param) => {
    const res = await api.post(`/posting/getNoticeAdmin`, param);
    return res.data;
  }
);
export const addNotice = createAsyncThunk(
  "communit/addNotice",
  async (param) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("writerAdminAdminId", param.writerAdminAdminId);
    formdata.append("sendingAlarm", param.sendingAlarm);
    formdata.append("isHide", param.isHide);
    formdata.append("title", param.title);
    formdata.append("content", param.content);
    param.files.forEach((file) => formdata.append("files", file));
    const res = await api.post(`/posting/addNotice`, formdata, config);
    return res;
  }
);
export const editNotice = createAsyncThunk(
  "communit/editNotice",
  async (param) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formdata = new FormData();
    formdata.append("id", param.id);
    formdata.append("writerAdminAdminId", param.writerAdminAdminId);
    formdata.append("sendingAlarm", param.sendingAlarm);
    formdata.append("isHide", param.isHide);
    formdata.append("title", param.title);
    formdata.append("content", param.content);
    if (param.remainingFiles !== null && param.remainingFiles !== undefined) {
      //이전 저장파일
      param.remainingFiles.forEach((file) =>
        formdata.append("remainingFiles", file)
      );
    }
    if (param.files)
      param.files.forEach((file) => formdata.append("files", file));
    const res = await api.post("/posting/editNotice", formdata, config);
    return res;
  }
);

const initialState = {
  i: 0,
  status: null,
  totalSearchCountcommu: 0,
  totalSearchCountcenter: 0,
  searchUserList: [],
  data: [],
  questionList: [],
  searchAdminList: [],
  postingList: [],
  awaitingList: [],
  clickCommuTab: "",
  toggleCustomer: false,
  toggleCommunity: false,
  postingIdList: [],
  postingIdReplies: [],
  page: 1,
  replySliceArr: [],
  postFromMain: [],
  foodRegister: [],
  customerCenterDetailState: 0,
  totalPage: 0,
  totalSearchCount: 0,
  foodRegisterAllList: [],
  isInInfoId: null,
  requestDBEach: [],
  allCancerGPT: [],
  cancerGPTEeach: [],
  editCommunityPosting: [],
  commentOpen: false,
  allNotices: [],
  newWrite: false,
};
const communitySlice = createSlice({
  name: "소식",
  initialState,
  reducers: {
    onSetCommTab: (state, action) => {
      state.clickCommuTab = action.payload;
    },
    onSetToggleCustomerCenter: (state, action) => {
      state.toggleCustomer = action.payload;
    },
    onSetToggleCommunity: (state, action) => {
      state.toggleCommunity = action.payload;
    },
    handlePageChangeCommu: (state, action) => {
      state.page = action.payload;
    },
    handleReplySliceArr: (state, action) => {
      state.replySliceArr = action.payload;
    },
    onClickCustomerCenterState: (state, action) => {
      state.customerCenterDetailState = action.payload;
    },
    onClickInInfoId: (state, action) => {
      state.isInInfoId = action.payload;
    },
    onClickGetRequestDB: (state, action) => {
      state.requestDBEach = action.payload;
    },
    onClickGetCancerGpt: (state, action) => {
      state.cancerGPTEeach = action.payload;
    },
    onClickGetEditCommunityPosting: (state, action) => {
      state.editCommunityPosting = action.payload;
    },
    onClickCommentOpen: (state, action) => {
      state.commentOpen = action.payload;
    },
    onClickNewWrite: (state, action) => {
      //새글쓰기 조건
      state.newWrite = action.payload;
    },
  },
  extraReducers: {
    [searchCustomerCenter.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.totalSearchCountcenter = action.payload.totalSearchCount;
      state.questionList = action.payload.QList;
      state.totalPage = action.payload.totalPage;
    },
    [getCenterAwaiting.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.totalSearchCountcenter = action.payload.totalSearchCount;
      state.questionList = action.payload.questionList;
    },
    [searchManagementCommunity.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.totalSearchCountcommu = action.payload.totalSearchCount;
      state.totalPage = action.payload.totalPage;
      state.postingList = action.payload.postingList;
    },
    [getAllReportedPosting.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.totalSearchCountcommu = action.payload.totalSearhCount;
      state.postingList = action.payload.postingList;
    },
    [getAllRepliesOfOnePosting.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.postingIdList = action.payload.posting;
      state.postingIdReplies = action.payload.totalReplies;
    },
    [editPostingCommunity.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
    },
    [addReply.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
    },
    [getFoodRegistrationRequest.fulfilled]: (state, action) => {
      state.status = "success";
      state.foodRegister = action.payload.requestList;
      state.totalPage = action.payload.totalPage;
      state.totalSearchCount = action.payload.totalSearchCount;
    },
    [getFoodRegistrationRequestShowAll.fulfilled]: (state, action) => {
      state.status = "success";
      state.foodRegisterAllList = action.payload.requestList;
    },
    [searchInformation.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.totalPage = action.payload.totalPage;
      state.postingList = action.payload.postingList;
    },
    [searchCancerGPT.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
      // state.totalSearchCountcenter = action.payload.totalSearchCount;
      state.questionList = action.payload.questionList;
      state.totalPage = action.payload.totalPage;
    },
    [getALLCancerGPT.fulfilled]: (state, action) => {
      state.status = "success";
      state.data = action.payload;
      state.allCancerGPT = action.payload.questionList;
    },
    [getNotice.fulfilled]: (state, action) => {
      state.status = "success";
      state.totalPage = action.payload.totalPage;
      state.data = action.payload;

      state.allNotices = action.payload.postingList;
    },
  },
});

export const {
  onSetCommTab,
  onSetToggleCustomerCenter,
  onSetToggleCommunity,
  handlePageChangeCommu,
  handleReplySliceArr,
  onClickCustomerCenterState,
  onClickInInfoId,
  onClickGetRequestDB,
  onClickGetCancerGpt,
  onClickGetEditCommunityPosting,
  onClickCommentOpen,
  onClickNewWrite,
} = communitySlice.actions;

export default communitySlice.reducer;
