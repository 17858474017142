import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import TitleBox from "../common/TitleBox";
import { HealthInfoButton } from "./HealthInfoButton";

const BodyInfoCard = ({ myinfo, handleToggle }) => {
  const navigate = useNavigate();
  const datas = [
    { id: 1, title: "키", data: "height", unit: "cm" },
    { id: 2, title: "체중", data: "weight", unit: "kg" },
    { id: 3, title: "나이", data: "age", unit: "세" },
    { id: 4, title: "성별", data: "gender", unit: "" },
  ];
  const datas2 = [
    { id: 1, title: "진단 명", data: "cancerType" },
    { id: 2, title: "병기", data: "cancerStaging" },
    { id: 3, title: "치료 및 단계", data: "anticancerTreatmentYN" },
    { id: 4, title: "수술 명", data: "operationName" },
    { id: 5, title: "수술 일", data: "operationDate" },
    { id: 6, title: "치료 종류", data: "anticancerTreatmentType" },
  ];
  const data3 = [
    { id: 1, title: "치료병원", name: "hospitalName" },
    { id: 2, title: "만성질환", name: "chronicDisease" },
    { id: 3, title: "복용약", name: "medicineDrugs" },
  ];
  const ifLength6SLice = (stringdata) => {
    return stringdata?.length > 12
      ? stringdata?.slice(0, 12) + "..."
      : stringdata;
  };

  return (
    <div className="space-y-[28px] rounded-lg bg-green_10 p-6" id="health">
      <div onClick={handleToggle} className="flex flex-row justify-between">
        <div className="flex items-center gap-1 ">
          <TitleBox option={1} title={"신체 및 질병정보"} />
          <div className="px-3 py-1 text-base font-bold bg-white rounded-lg text-gray_140">
            수정하기
          </div>
        </div>
        <img
          alt={"arrowRight"}
          src="/images/mobile/icon/arrow_right.svg"
          className="transform rotate-90"
        />
      </div>

      <div className="">
        <div className="pb-3 space-y-3 dashedBottom">
          {datas.map((data) => (
            <ul key={data.id} className="flex justify-between ">
              <li className="text-body3_700 text-gray_160">{data.title}</li>
              <li className="text-body3_700 text-green_100">
                {data.data === "gender"
                  ? myinfo[data.data] === "M"
                    ? "남성"
                    : "여성"
                  : myinfo[data.data]}
                {data.unit}
              </li>
            </ul>
          ))}
        </div>
        <div className="pt-3 pb-6 space-y-3 dashedBottom">
          {datas2.map((data) => (
            <ul key={data.id} className="flex justify-between">
              <li className="text-body3_700 text-gray_160">{data.title}</li>
              <li className="text-body3_700 text-green_100">
                {data.data === "birth"
                  ? dayjs().format("YYYY") - myinfo[data.data]
                  : data.data === "gender"
                  ? myinfo[data.data] === "M"
                    ? "남성"
                    : "여성"
                  : data.data === "operationDate"
                  ? myinfo[data.data] === "OD997"
                    ? "모름"
                    : myinfo[data.data] !== undefined &&
                      dayjs(myinfo[data.data]).format("YYYY.MM.DD")
                  : myinfo[data.data]}
                {data.unit}
              </li>
            </ul>
          ))}
          <HealthInfoButton
            onClick={() => {
              navigate("/question/health", { state: { from: "healthInfo" } });
              // window.history.replaceState({ key: "value" }, "", "/healthinfo"); // /question/health 를 healthInfo로 변환해줌 결과페이지에서 뒤로가기시 설문조사 페이지로 다시 안가게끔함, 하지만 healthInfo가 두번 연속으로 있는 상태라 문제
            }}
            content={"수정하기"}
            textColor={"text-green_120"}
            boxShadow={"0px 0px 5px #ABC8AF"}
          />
        </div>
        <div className="space-y-[28px] rounded-lg pt-6" id="disease">
          <TitleBox option={1} title={"추가 질병정보"} />
          <div className="space-y-3">
            {data3.map((data) => (
              <ul key={data.id} className="flex justify-between w-full">
                <li className="text-body3_700 text-gray_160">{data.title}</li>
                <li className="text-right text-body3_700 text-[#3651B4]">
                  {myinfo[data.name]
                    ? data.name === "chronicDisease"
                      ? myinfo["chronicDisease"]?.map((el, idx) => (
                          <div key={idx}>{el + " "}</div>
                        ))
                      : data.name === "medicineDrugs"
                      ? myinfo["medicineDrugs"]?.map((el, idx) => (
                          <div key={idx}>
                            {ifLength6SLice(el) +
                              (idx + 1 === myinfo["medicineDrugs"]?.length
                                ? ""
                                : ",")}
                          </div>
                        ))
                      : ifLength6SLice(myinfo[data.name])
                    : "-"}
                </li>
              </ul>
            ))}
          </div>
          <HealthInfoButton
            onClick={() =>
              navigate("/question/disease", { state: { from: "healthInfo" } })
            }
            content={"수정하기"}
            textColor={"text-green_120"}
            boxShadow={"0px 0px 5px #ABC8AF"}
          />
        </div>
      </div>
    </div>
  );
};

export default BodyInfoCard;
