import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  checkMinVersion,
  mobileTypeFn,
} from "../../../mobile/components/logic";
import useGAEvent from "../../hooks/useGAEvent";

const ShareBtn = ({ option, foodId }) => {
  //option: day 추천식단, recipe 레시피
  const path = useLocation().pathname;
  const { loginInfo } = useSelector((state) => state.commonApp);
  const shareMealUrl = `https://www.healiary.com/recommend/share/${foodId}/`;
  //   const shareMealUrl = `http://192.168.0.81:3000/recommend/share/${foodId}/`;
  const shareRecipeUrl = `https://www.healiary.com/recommend/recipe/share/${foodId}/`;
  //   const shareRecipeUrl = `http://192.168.0.81:3000/recommend/recipe/share/${foodId}/`;

  const { sendEvent } = useGAEvent();

  const onOpenShare = () => {
    sendEvent({
      event: "meal_share",
    });
    if (mobileTypeFn() === "iphone") {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview
          .callHandler("getIosVersion")
          .then(function (result) {
            checkVersion(result);
          });
      } else {
        goWebShare();
      }
    } else if (mobileTypeFn() === "android") {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview
          .callHandler("getAndroidVersion")
          .then(function (result) {
            checkVersion(result);
          });
      } else {
        goWebShare();
      }
    } else {
      goWebShare();
    }
  };
  const goWebShare = () => {
    if (navigator.share) {
      //일반웹인경우 공유하기 가능할시
      navigator.share({
        title:
          option === "day"
            ? `힐리어리가 추천해 드리는 ${
                loginInfo.nickName || "사용자"
              }님의 이번 주 맞춤형 식단입니다.`
            : "힐리어리가 추천해 드리는 레시피 입니다.",
        text: "#힐리어리 #힐리어리추천식단 #힐리어리맞춤형식단 #힐리어리영양관리 #힐리어리체중관리",
        url: option === "day" ? shareMealUrl : shareRecipeUrl,
      });
    }
  };

  const checkVersion = (curVersion) => {
    const needUpdate = checkMinVersion(curVersion, "2.0.12");

    if (needUpdate) {
      goUpdate();
    } else {
      let title =
        option === "day"
          ? `힐리어리가 추천해 드리는 ${
              loginInfo.nickName || "사용자"
            }님의 이번 주 맞춤형 식단입니다.`
          : "힐리어리가 추천해 드리는 레시피 입니다.";
      let tag =
        "#힐리어리 #힐리어리추천식단 #힐리어리맞춤형식단 #힐리어리영양관리 #힐리어리체중관리";
      let url = option === "day" ? shareMealUrl : shareRecipeUrl;
      window.flutter_inappwebview.callHandler("share", title, tag, url);
    }
  };
  const goUpdate = () => {
    let isOk = window.confirm(
      "공유하기 기능을 사용하시려면 버전 업데이트가 필요합니다. 확인을 누르시면 설치 페이지로 이동합니다."
    );
    if (isOk) {
      if (mobileTypeFn() === "iphone") {
        window.location.replace(
          "https://apps.apple.com/kr/app/id1636424503",
          "_blank"
        );
      } else {
        window.location.replace(
          `market://details?id=com.herings.healiary&pli=1`,
          "_blank"
        );
      }
    }
  };

  useEffect(() => {
    // console.log(foodId);
    // 카카오톡 sdk 추가
    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  // bg-gray_20

  return (
    <>
      <li
        id={path === "/recommend" ? "shareMealPlan" : "shareRecipe"}
        onClick={onOpenShare}
        className={`flex items-center rounded-[36px] bg-main p-3 
        active:bg-opacity-50 ${option === "day" ? "py-[7px]" : null}
        `}
      >
        <span className="font-semibold text-white text-caption1_60">
          {option === "day" ? "추천식단" : "레시피"} 공유
        </span>
      </li>
    </>
  );
};

export default ShareBtn;
