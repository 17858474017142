import React, { useState } from "react";
import Slide from "./Slide";

const ThirdPart = ({ screenSize }) => {
  const contents = [
    {
      mobileImgName: `식사기록`,
      imgName: `식사기록`,
      title: `식사기록 • 분석`,
      contents: [
        `하루 동안 섭취한 식단을 기록하여`,
        `식사 분석과 추천식단을 받아보세요!`,
      ],
    },
    {
      mobileImgName: `증상체중기록`,
      imgName: `증상체중기록`,
      title: `증상•체중기록`,
      contents: [
        `증상과 체중을 기록하여 매일 나의`,
        `건강상태를 모니터링 해보세요!`,
      ],
    },
    {
      mobileImgName: `복약진료알림`,
      imgName: `복약진료알림`,
      title: `복약•진료알림`,
      contents: [
        `복약과 진료 일정을 입력하고`,
        `알림을 통해 스케줄 관리를 해보세요!`,
      ],
    },
    {
      mobileImgName: `걸음수`,
      imgName: `걸음수mw`,
      title: `걸음수 측정`,
      contents: [`하루동안 측정된`, `나의 걸음수를 체크해보세요!`],
    },
  ];
  return (
    <section
      className="flex flex-col items-center justify-center gap-9 md:p-11 lg:py-20 xl:px-28"
      style={{
        backgroundImage: "url('/images/mobile/landing/landing_wave_bg.png')",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <h3 className="font-bold sm:text-2xl xl:text-5xl">
        힐리어리의 All-In-One 케어
      </h3>
      <h4 className="text-center font-medium  leading-[45px] tracking-wide text-gray_170 sm:text-xl xl:text-3xl">
        식단 기록부터 복약 알림까지 한데 모아 암 환자를 위한 All-In-One 헬스케어
        어플리케이션입니다. <br /> 모든 암 환자와 보호자를 위한 희망과 지지의
        공간, 힐리어리와 시작해 보세요.
      </h4>
      {/* ${(index + 1) % 2 !== 0 ? "xl:justify-end" : "xl:justify-start"} */}
      <div className="flex w-full flex-col sm:my-20 xl:mb-[357px] xl:mt-[182px] 2xl:px-[12.5rem]">
        {contents.map((value, index) => {
          return (
            <div
              className={`mx-auto flex h-full w-full justify-between sm:gap-5 sm:pb-[50px] xl:w-full xl:flex-row xl:items-center xl:justify-between xl:gap-12 xl:pb-[180px]`}
              key={value.title}
            >
              <div
                className={`md:w-[300px] lg:w-[400px] xl:w-[450px] ${
                  (index + 1) % 2 !== 0 ? "md:order-last" : null
                }`}
              >
                <Slide
                  selectedRoom={value.mobileImgName}
                  isMobile={false}
                  index={index + 1}
                />
              </div>
              <div
                className={`mt-5 flex flex-col sm:items-center sm:gap-3 xl:gap-5 3xl:max-w-[700px] ${
                  (index + 1) % 2 !== 0 ? "xl:items-start" : "xl:items-start"
                }`}
              >
                <p
                  className={`font-bold leading-loose text-black sm:text-center sm:text-2xl  sm:tracking-tight xl:tracking-wide 2xl:text-[56px] ${
                    (index + 1) % 2 !== 0 ? "xl:text-right" : null
                  }`}
                  style={{
                    textDecoration: "underline",
                    textDecorationColor: "rgba(65, 127, 255, 0.2)",
                    textDecorationThickness: "18px",
                    textUnderlineOffset: "-10px",
                  }}
                >
                  {value.title}
                </p>
                <div
                  className={`sm:text-center sm:text-sm 2xl:text-xl 3xl:text-2xl ${
                    (index + 1) % 2 !== 0 ? "xl:text-left" : "xl:text-left"
                  }`}
                >
                  {value.contents.map((p) => (
                    <p key={p}>
                      <span className="text-xl font-bold leading-loose tracking-tight text-zinc-600">
                        {p}
                      </span>
                      <br />
                    </p>
                  ))}
                </div>

                <img
                  src={`/images/mobile/landing/all-in-one/${value.imgName}.svg`}
                  alt={value.title}
                  className={`relative xl:w-2/3${
                    index % 2 !== 0 ? "xl:order-last" : null
                  }`}
                />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ThirdPart;
