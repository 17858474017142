import React, { useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveLoginInfo } from "../../../../features/mobile/commonSlice";
import api from "../../../../common/api";

const Login = () => {
  const dispatch = useDispatch();
  const path = useLocation().search;
  const navigate = useNavigate();

  let userId = path.substring(
    path.indexOf("?userId=") + 8,
    path.indexOf("&nickName")
  );
  let nickName = decodeURI(
    path.substring(path.indexOf("nickName=") + 9, path.indexOf("&photo"))
  );
  let photo = decodeURI(
    path.substring(path.indexOf("photo=") + 6, path.indexOf("&existPass"))
  );
  let existPass = decodeURI(path.substring(path.indexOf("existPass=") + 10));

  console.log(userId, nickName, photo, existPass);
  useEffect(() => {
    let firebaseToken = window.sessionStorage.getItem("token");
    let params = { userId, firebaseToken: firebaseToken };
    api.post(`/user/addFireBaseToken`, params);
    //토큰 보내기-로그아웃 후 자동로그인 안되기 때문, 로그인때마다 보내기
    dispatch(
      saveLoginInfo({
        userId: userId,
        nickName: nickName,
        characterPhoto: photo,
        existPass: existPass === "true" ? true : false,
      })
    );
    navigate(`/home`);
    console.log(userId, nickName, photo);
  }, [dispatch, navigate, nickName]);
  //   phoneNo, photo
  return (
    <div className="flex items-center justify-center h-screen">
      <img src="/images/mobile/splash.svg" alt="로딩중" />
    </div>
  );
};

export default Login;
