import ApexChart from "react-apexcharts";
import dayjs from "dayjs";
import customToolTip from "./customToolTipGraph";
const OthersGraph = ({
  barHColor,
  dataLists,
  graphOption,
  measureUnit,
  color = "#FFE9A0",
}) => {
  const xData = dataLists
    ? dataLists?.map((el) => (el.data ? el.data : null))
    : [];
  const yDate = dataLists
    ? dataLists?.map((el) => dayjs(el.day).format("MM.DD"))
    : [];
  const areaTotal = Math.max(...xData) < 1 ? 1 : Math.max(...xData) + Math.max(...xData)/3;
  // 데이타가 없을때 nullData 로 false-chart를 render 하기
  const nullData = xData
    ? xData?.map((el) =>
        el === null
          ? Math.max(...xData) / 90
            ? Math.max(...xData) / 90
            : 0.01
          : 0
      )
    : [];

  return (
    <div className="admin-graphs">
      <div id={barHColor} className="border border-gray_60 rounded-md">
        <ApexChart
          series={[{ data: nullData }, { data: xData }]}
          options={{
            fill: {
              colors: [color],
              opacity: 1,
            },
            chart: {
              selection: {
                enabled: false,
              },
              toolbar: {
                show: false,
                autoSelected: undefined,
              },
              stacked: true,
            },
            plotOptions: {
              bar: {
                borderRadius: graphOption === "최근 7일" ? [7, 7] : [5, 5],
                columnWidth: graphOption === "최근 7일" ? "15%" : "50%",
                dataLabels: {
                  position: "top",
                  hideOverflowingLabels: false,
                },
              },
            },
            dataLabels: {
              enabled: true,
              enabledOnSeries: [1],
              formatter: function (val) {
                if (graphOption === "최근 7일")
                  return val !== null
                    ? parseInt(val?.toFixed(0)).toLocaleString("ko-KR")
                    : "0";
                else return "";
              },
              offsetY: -20,
              style: {
                fontSize: "12px",
                colors: ["#746f6a"],
                fontWeight: "400",
              },
            },
            xaxis: {
              categories: yDate,
              tickPlacement: "on",
              position: "bottom",
              labels: {
                rotate: 0,
                hideOverlappingLabels: true,
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tickAmount: 7,
              crosshairs: {
                //bar background
                show: true,
                position: "back",
                width: 1,
                stroke: {
                  color: "#A39F9B",
                  opacity: 1,
                  width: 1,
                  dashArray: 5,
                },
              },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              min:0,
              max: areaTotal,
              tickAmount: 5,
              labels: {
                show: graphOption === "최근 30일",
                formatter: function (val) {
                  if (val <= 1) return Math.round(val * 5);
                  return Math.round(val).toLocaleString("ko-KR");
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            states: {
              hover: {
                filter: {
                  type: "none",
                },
              },
              active: {
                filter: {
                  type: "none",
                },
              },
            },
            legend: {
              show: false,
            },
            tooltip: {
              enabled: true,
              enabledOnSeries: [1],
              custom: ({ series, seriesIndex, dataPointIndex }) =>
                customToolTip(series, seriesIndex, dataPointIndex, measureUnit, yDate),
            },
            grid: {
              padding: {
                right: 0,
                left: graphOption === "최근 7일" ? 0 : 10,
              },
            },
          }}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default OthersGraph;
