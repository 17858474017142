import React from "react";
import { ReactComponent as Minus } from "../../../../svg/minus.svg";
import { ReactComponent as Plus } from "../../../../svg/plus.svg";

const PlusMinusBtn = ({
  chosenFood,
  num,
  unit,
  onIncreaseQty,
  onDecreaseQty,
  btnDisabled,
  btnDisabled2,
}) => {
  return (
    <div className="ml-9 flex h-[69px] w-[250px] items-center justify-center gap-4 rounded-full bg-back_ground p-2">
      <Minus
        onClick={() =>
          Number.isInteger(chosenFood.eyemesurementnumber_org)
            ? Number(chosenFood.eyemesurementnumber_org) >= 3
              ? onDecreaseQty(1)
              : onDecreaseQty(0.25)
            : Number(chosenFood.eyemesurementnumber_org) % 0.25 === 0
            ? onDecreaseQty(0.25)
            : onDecreaseQty(2)
        }
        fill={btnDisabled ? "#828282" : "#E2ECFF"}
      />
      <div className="flex items-center justify-center gap-1 w-28">
        <h3 className="text-black text-body1_700">
          {Number.isInteger(num) ? num : num.toFixed(2) || 0}
        </h3>
        <span className="text-caption1_600 text-gray_160"> {unit}</span>
      </div>
      <Plus
        onClick={() =>
          Number.isInteger(chosenFood.eyemesurementnumber_org)
            ? Number(chosenFood.eyemesurementnumber_org) >= 3
              ? onIncreaseQty(1)
              : onIncreaseQty(0.25)
            : Number(chosenFood.eyemesurementnumber_org) % 0.25 === 0
            ? onIncreaseQty(0.25)
            : onIncreaseQty(2)
        }
        fill={btnDisabled2 ? "#828282" : "#E2ECFF"}
      />
    </div>
  );
};

export default PlusMinusBtn;
