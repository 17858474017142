import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
// import toastCommonProps from "../../../../common/toast";
// import {
//   fetchAnswerList,
//   saveAnswer,
// } from "../../../../features/mobile/questionHealthSlice";

const SelectBox = ({
  option,
  value,
  datas,
  type,
  name,
  placeholder,
  onSaveAnswer,
  onSaveAnswers,
  stateDate,
  setSelectCount,
  selectCount,
  setSaveAnswerDatas,
  saveAnswereDatas,
}) => {
  const { gettingAnswers } = useSelector((state) => state.questionHealth);
  const { loginInfo } = useSelector((state) => state.commonApp);
  const inputRef = useRef(null);
  const [openBox, setOpenBox] = useState(false);
  const [birth, setBirth] = useState(
    saveAnswereDatas ? saveAnswereDatas.birth : gettingAnswers.birth
  );
  const [water, setWater] = useState(
    saveAnswereDatas
      ? saveAnswereDatas.waterGlassCount
      : gettingAnswers.waterGlassCount
  );
  // console.log(saveAnswereDatas, "SelectBox saveAnswereDatas");

  const dispatch = useDispatch();
  const onSetOption = (param) => {
    if (type === "birth") {
      setBirth(param); //year
    } else {
      setWater(param); //water
    }
    setOpenBox(false);
    onSaveAnswer(type, param);
  };
  const onSetOption2 = (type, data) => {
    setOpenBox(false);
    onSaveAnswers(type, data);
    setSelectCount(selectCount + 1); //selectbox 한번 클릭해도 operationDate값 null처리
    if (selectCount === 0) {
      //   dispatch(saveAnswer({ userId: loginInfo.userId, operationDate: null }))
      //     .unwrap()
      //     .then((res) => {
      //       if (res.ok) dispatch(fetchAnswerList(loginInfo.userId));
      //       else
      //         toast(
      //           <p>{res.error}</p>,
      //           toastCommonProps("bottom-center", "toast_alert", 1000)
      //         );
      //     })
      //     .catch((err) => console.log(err));
      setSaveAnswerDatas({
        ...saveAnswereDatas,
        userId: loginInfo.userId,
        operationDate: null,
      });
    }
  };

  useEffect(() => {
    let focused = true;
    if (focused) inputRef.current?.focus();
    return () => (focused = false);
  }, [inputRef]);

  return (
    <div>
      {option === 1 ? ( //birth
        <>
          <div
            onClick={() => setOpenBox(!openBox)}
            className={
              "flex items-center justify-between rounded-md border border-gray_80 bg-gray_10 p-4"
            }
          >
            <h2
              className={
                birth
                  ? "text-body2_500l text-black"
                  : "text-body2_500l text-gray_100"
              }
            >
              {birth || 1970}년
            </h2>
            <span className={openBox ? "rotate-180" : "rotate-0"}>
              <img
                src="/images/mobile/icon/arrow_down.svg"
                alt="아래 화살표 아이콘"
              />
            </span>
          </div>
          <ul
            className={
              "max-h-[196px] flex-col overflow-y-scroll border border-gray_80 bg-gray_10 " +
              (openBox
                ? "h-full rounded-md border opacity-100"
                : "h-[0] border-none")
            }
          >
            {datas && datas.length > 0
              ? datas.map((data) => (
                  <li
                    onClick={() => onSetOption(data)}
                    key={data}
                    className="flex px-[12px] py-[11px] text-body2_500l text-black active:bg-orange_10"
                  >
                    {data}년
                  </li>
                ))
              : ""}
          </ul>
        </>
      ) : option === 100 ? ( //waterglass
        <>
          <div
            onClick={() => setOpenBox(!openBox)}
            className={
              "flex items-center justify-between rounded-md border border-gray_80 bg-gray_10 p-4"
            }
          >
            <h2
              className={
                water || placeholder
                  ? "text-body2_500l text-black"
                  : "text-body2_500l text-gray_100"
              }
            >
              {water || placeholder}컵 X 200ml
            </h2>
            <span className={openBox ? "rotate-180" : "rotate-0"}>
              <img
                src="/images/mobile/icon/arrow_down.svg"
                alt="아래 화살표 아이콘"
              />
            </span>
          </div>
          <ul
            className={
              "max-h-[196px] flex-col overflow-y-scroll border border-gray_80 bg-gray_10 " +
              (openBox
                ? "h-full rounded-md border opacity-100"
                : "h-[0] border-none")
            }
          >
            {datas && datas.length > 0
              ? datas.map((data) => (
                  <li
                    onClick={() => onSetOption(data)}
                    key={data}
                    className="flex px-[12px] py-[11px] text-body2_500l text-black active:bg-orange_10"
                  >
                    {data}컵 X 200ml
                  </li>
                ))
              : ""}
          </ul>
        </>
      ) : (
        <>
          <div className="flex items-start gap-2">
            <div className="w-full">
              <div
                onClick={() => setOpenBox(!openBox)}
                className="flex items-center justify-between p-4 border rounded-md border-gray_80 bg-gray_10"
              >
                <h2
                  className={
                    value
                      ? "text-body2_500l text-black"
                      : "text-body2_500l text-gray_100"
                  }
                >
                  {value || placeholder}
                </h2>
                <span className={openBox ? "rotate-180" : "rotate-0"}>
                  <img
                    src="/images/mobile/icon/arrow_down.svg"
                    alt="아래 화살표 아이콘"
                  />
                </span>
              </div>
              <ul
                className={
                  "max-h-[196px] flex-col overflow-y-scroll border border-gray_80 bg-gray_10 " +
                  (openBox
                    ? "h-full rounded-md border opacity-100"
                    : "h-[0] border-none")
                }
              >
                {datas && datas.length > 0
                  ? datas.map((data) => (
                      <li
                        onClick={() => onSetOption2(type, data)}
                        key={data}
                        className="flex px-[12px] py-[11px] text-body2_500l text-black active:bg-orange_10"
                      >
                        {data}
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
            <span className="mt-4 text-body1_500l text-gray_200">{name}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectBox;
