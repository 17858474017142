import React from "react";

const ListCommentSkeleton = () => {
  return (
    <>
      <div className="flex animate-pulse flex-col gap-4 border-b border-t border-gray_20 bg-white px-4 py-5">
        <div className="flex flex-col gap-1.5">
          <ul className="flex flex-row gap-1">
            <li className="mr-1 h-5 w-14 rounded-xl bg-gray_20" />
            <li className="h-5 w-56 rounded-xl bg-gray_20" />
            <li className="ml-auto mr-1 h-5 w-4 rounded-xl bg-gray_20" />
          </ul>
          <div className="flex flex-col gap-2">
            <div className="h-5 w-[90%] rounded-xl bg-gray_20" />
            <div className="h-5 w-[55%] rounded-xl bg-gray_20" />
          </div>
          <div className="flex justify-between">
            <div className="h-5 w-[45%] rounded-xl bg-gray_20" />
          </div>
        </div>
      </div>
    </>
  );
};
export default ListCommentSkeleton;
