import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";
import { toast } from "react-toastify";
import toastCommonProps from "../../common/toast";

export const fetchQuestion = createAsyncThunk(
  "questionHealth/fetchQuestion",
  async () => {
    const res = await api.get(`/base/getHealthQuestion`);
    return res.data;
  }
);

export const saveAnswer = createAsyncThunk(
  "questionHealth/saveAnswer",
  async (params) => {
    // const res = await api.post(`/user/inputUserHealth`, params);
    const res = await api.post(`/user/inputUserHealth`, params);
    return res.data;
  }
);

export const fetchAnswerList = createAsyncThunk(
  "questionHealth/fetchAnswerList",
  async (params) => {
    const res = await api.get(`/user/searchForUserId/${params}`);
    if (res.data.ok) {
      return res.data.user.userHealth;
    }
  }
);

export const getNickname = createAsyncThunk(
  "questionHealth/getNickname", //닉네임 자동 생성
  async (params) => {
    const res = await api.post(`/user/getNickName`, params);
    return res.data.nickName;
  }
);

export const fetchSurveyResult = createAsyncThunk(
  "questionHealth/fetchSurveyResult",
  async (params) => {
    const res = await api.get(`/user/getUserHealthQuestionResult/${params}`);
    return res.data;
  }
);

const initialState = {
  questionLists: [],
  i: 0,
  prevI: [0],
  gettingAnswers: {},
  questionListsStatus: null,
  dummyNickname: null,
  resultLists: null,
  otherCancerLists: [], // deprecated
  mainCancerList: [],
  allCancerList: [],
};

const questionHealthSlice = createSlice({
  name: "건강 설문",
  initialState,
  reducers: {
    increment: (state) => {
      state.i += 1;
      state.prevI = [...state.prevI, state.i];
    },
    decrement: (state) => {
      if (state.i <= 0) {
        toast(
          <p>1번 문항입니다</p>,
          toastCommonProps("bottom-center", "toast_alert")
        );
      } else {
        state.i -= 1;
        state.prevI = [...state.prevI, state.i];
      }
    },
    incrementByAmount: (state, action) => {
      state.i += action.payload;
      state.prevI = [...state.prevI, state.i];
    },
    goBackByAmount: (state) => {
      const filter = state.prevI.filter(
        (el, idx) => idx < state.prevI.length - 1
      ); //마지막 번호 삭제
      state.prevI = [...filter];
      state.i = state.prevI[state.prevI.length - 1];
    },
    resetI: (state) => {
      state.i = 0;
    },
    changeStateArray: (state, action) => {
      state.prevI = [action.payload];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchQuestion.pending, (state) => {
      state.questionListsStatus = "pending";
    });
    builder.addCase(fetchQuestion.fulfilled, (state, action) => {
      state.questionListsStatus = "success";
      state.questionLists = action.payload.questionList;
      state.otherCancerLists = action.payload.otherCancerQuestion?.answerList;
      state.mainCancerList = action.payload.mainCancerList;
      state.allCancerList = action.payload.allCancerList;
    });
    builder.addCase(fetchQuestion.rejected, (state) => {
      state.questionListsStatus = "rejected";
    });
    builder.addCase(fetchAnswerList.fulfilled, (state, action) => {
      state.gettingAnswers = action.payload;
    });
    builder.addCase(getNickname.fulfilled, (state, action) => {
      state.dummyNickname = action.payload;
    });
    builder.addCase(fetchSurveyResult.fulfilled, (state, action) => {
      state.resultLists = action.payload;
    });
  },

  // {
  //   [fetchQuestion.pending]: (state) => {
  //     state.questionListsStatus = "pending";
  //   },
  //   [fetchQuestion.fulfilled]: (state, action) => {
  //     state.questionListsStatus = "success";
  //     state.questionLists = action.payload.questionList;
  //     state.otherCancerLists = action.payload.otherCancerQuestion?.answerList;
  //     state.mainCancerList = action.payload.mainCancerList;
  //     state.allCancerList = action.payload.allCancerList;
  //   },
  //   [fetchQuestion.rejected]: (state) => {
  //     state.questionListsStatus = "rejected";
  //   },
  //   [fetchAnswerList.fulfilled]: (state, action) => {
  //     state.gettingAnswers = action.payload;
  //   },
  //   [getNickname.fulfilled]: (state, action) => {
  //     state.dummyNickname = action.payload;
  //   },
  //   [fetchSurveyResult.fulfilled]: (state, action) => {
  //     state.resultLists = action.payload;
  //   },
  // },
});

export const {
  increment,
  decrement,
  incrementByAmount,
  goBackByAmount,
  resetI,
  changeStateArray,
} = questionHealthSlice.actions;

export default questionHealthSlice.reducer;
