import React, { useCallback, useEffect, useState } from "react";
import HeadCont from "../../components/common/HeadCont";
import InputSelectBox from "../../components/common/InputSelectBox";
import MenuBar from "../../components/common/MenuBar";
import { ReactComponent as More } from "../../../../svg/arrow_right_com.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  onSetPageCount,
  onSetRecipeFoodList,
  onSetSearchedValue,
  searchFoodRecipe,
} from "../../../../features/mobile/recommendSlice";
import Pagination from "react-js-pagination";
import { searchAndHighlight } from "../../components/logic";

// prettier-ignore
const foodCategory = [
  {
    id: 1,
    idName: "riceRecipe",
    title: "밥/죽/떡",
    linkTo: "/recommend/findrecipe",
  },
  {
    id: 2,
    idName: "noodleRecipe",
    title: "면요리",
    linkTo: "/recommend/findrecipe",
  },
  {
    id: 3,
    idName: "soupRecipe",
    title: "국/찌개",
    linkTo: "/recommend/findrecipe",
  },
  {
    id: 4,
    idName: "mainDish",
    title: "메인반찬",
    linkTo: "/recommend/findrecipe",
  },
  {
    id: 5,
    idName: "sideDish",
    title: "밑반찬",
    linkTo: "/recommend/findrecipe",
  },
  {
    id: 6,
    idName: "sandwich",
    title: "샌드위치",
    linkTo: "/recommend/findrecipe",
  },
];

const FindRecipe = () => {
  /**
   * Used for finding the max possible recipe panels (default is 4)
   *
   * @date 6/23/2023 - 1:05:13 PM
   *
   * @returns {number}
   */
  const maxCount = () => {
    let screenHeight = window.innerHeight;
    let headContHeight = 146; // HeadCont (56) and SearchTextField(88) + (1px border x 2)
    let menuBarHeight = 97; // MenuBar (96) + 1px border
    let contentHeight = 72; // ContentHeight (60) + gap inbetween (12)
    let offset = 96; // content height + 24
    let maxCount =
      (screenHeight - (headContHeight + menuBarHeight + offset)) /
      contentHeight;
    if (Math.floor(maxCount) > 5) {
      return Math.floor(maxCount);
    } else return 5;
  };
  // hook Functions
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { recipeFoodList, recipePageCount, recipeSearchedValue } = useSelector(
    (state) => state.recommend
  );

  // Local States
  const [loading, setLoading] = useState(true);
  const [emptyMessage, setEmptyMessage] = useState("");
  const [tmpValue, setTmpValue] = useState("");
  const [foodName, setFoodName] = useState(recipeSearchedValue); // recipeSearchedValue
  const [foodSearch, setFoodSearch] = useState(false);
  const [foodList, setFoodList] = useState(
    recipeFoodList?.length > 0 ? recipeFoodList : []
  ); // recipeFoodList
  const [totalPage, setTotalPage] = useState(
    recipePageCount ? recipePageCount.totalPage : null
  ); // recipePageCount
  const [currentPage, setCurrentPage] = useState(
    recipePageCount ? recipePageCount.currentPage : null
  ); // recipePageCount

  const [maxCountPerPage] = useState(maxCount());

  // 페이지네이션 라이브러리 덜어내고 새로 구현, maxPage는 최대 페이지수
  // pagination은 최대 다섯개씩 보여지는 페이지목록의 갯수로 0부터 카운팅
  const [pagination, setPagination] = useState(
    currentPage === null ? 0 : Math.ceil(currentPage / 5) - 1
  );
  let maxPage = totalPage === null ? 1 : Math.ceil(totalPage / maxCountPerPage);
  let maxPagination = Math.ceil(maxPage / 5) - 1;

  // static functions
  // const onChangeFoodName = (e) => {
  //   setFoodName(e.target.value);
  // };
  /**
   * api: `/food/searchFoodRecipe` (searchFoodRecipe)
   *
   * Sets the local emptyMessage, foodList, and totalPage.
   * Is a callBack function with Promise when successful.
   *
   * - If there are no recipe found, set emptyMessage to "찾으시는 레시피가 없음니다.". Else ""
   * - If there exists searchedValue (aka. foodName) highlight the searched values.
   *
   * @date 6/23/2023 - 1:15:37 PM
   *
   * @type {*}
   */
  const onBringFoodList = useCallback(
    (page = 1, name) => {
      return new Promise((resolve, reject) => {
        const params = {
          userId: loginInfo?.userId,
          type: state?.id === 0 ? null : state?.id,
          searchText: name || foodName,
          page: page,
          countPerPage: maxCountPerPage,
        };
        console.log(name || foodName);
        dispatch(searchFoodRecipe(params))
          .unwrap()
          .then((res) => {
            if (res.ok) {
              if (res.recipes?.length > 0) {
                setEmptyMessage("");
              } else {
                setEmptyMessage("찾으시는 레시피가 없습니다.");
              }
              let tempList = res.recipes.map((item) => ({
                viewName: item.foodName,
                similarName: "",
                foodId: item.foodId,
              }));
              if (name || foodName) {
                setFoodList(
                  searchAndHighlight(tempList, name || foodName, true)
                );
              } else {
                setFoodList(
                  tempList.map((item) => ({
                    details: item,
                    result: [item.viewName],
                  }))
                );
              }
              setTotalPage(parseInt(res.totalSearchCount));
              resolve(true);
            } else {
              reject(new Error(res?.error));
            }
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },

    [loginInfo, state, foodName, maxCountPerPage, dispatch]
  );
  console.log(foodName, tmpValue);
  /**
   * Search foodName recipe by dispatching foodName and
   * navigating to recommend/findrecipe (state.id === 0)
   *
   * If searched within the search page simply dispatch foodName and (onBringFoodList)
   *
   * If searched within one of the food categories dispatch foodName and
   * navigate to recommend/findrecipe (state.id === 0)
   *
   * If there are no foodName set when search button is activated
   * do nothing.
   *
   * @date 6/23/2023 - 1:10:48 PM
   */
  const onSearchFoodName = (name) => {
    setFoodName(name);
    if (name !== "" && name !== null) {
      setCurrentPage(1);
      setPagination(0);
      if (state?.id || state?.id === 0) {
        dispatch(onSetSearchedValue(name));
        onBringFoodList(1, name);
      } else {
        dispatch(onSetSearchedValue(name));
        navigate(`/recommend/findrecipe`, {
          state: {
            id: 0,
            idName: "Search",
            title: "검색",
            bgColor: "bg-yellow_10",
            linkTo: "/recommend/findrecipe",
            loading: true,
          },
        });
      }
    }
  };

  const availableSpace = useCallback(() => {
    let screenHeight = window.innerHeight;
    let headContHeight = 146; // HeadCont (56) and SearchTextField(88) + (1px border x 2)
    let menuBarHeight = 97; // MenuBar (96) + 1px border
    return (
      screenHeight - (headContHeight + menuBarHeight + (foodSearch ? 23 : 67))
    );
  }, [foodSearch]);

  // useEffect(() => {
  //   console.log(foodName, state);
  //   if (foodName === "" || foodName === null) {
  //     // When foodName is not empty
  //     if (state) {
  //       // When it is not the main page (one with the categories)
  //       if (state.id === 0) {
  //         // 검섹창
  //         navigate(-1);
  //       } else {
  //         // Main Page (with the categories)

  //         setFoodSearch(false);
  //         onBringFoodList(1).catch((error) => console.log(error));
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [foodName]);

  useEffect(() => {
    if (state) {
      setLoading(true);
      onBringFoodList(currentPage)
        .then((res) => res && setLoading(false))
        .catch((error) => console.log(error));
    }
    //eslint-disable-next-line
  }, [state]);

  const foodContainer = (details, result, key) => {
    console.log(result, "result");
    return (
      <li
        key={key}
        className="flex justify-between rounded-[10px] bg-[#EBF1FF]  px-6 py-4"
        onClick={() => {
          dispatch(onSetRecipeFoodList(foodList));
          if (foodName && foodName !== "") {
            dispatch(onSetSearchedValue(foodName));
          }
          dispatch(
            onSetPageCount({
              currentPage: currentPage,
              totalPage: totalPage,
            })
          );
          navigate("/recommend/recipe/" + details.foodId);
        }}
      >
        <span className="text-black text-body3_700">
          {result.map((item) => item)}
        </span>
        <More fill="#0D0D0D" className="w-6 h-6" />
      </li>
    );
  };

  // 페이지 이동(화살표) 함수
  const movePage = (button) => {
    if (button === "prev" && pagination > 0) {
      setLoading(true);
      setPagination(pagination - 1);
      setCurrentPage(pagination * 5);
      onBringFoodList(pagination * 5)
        .then((res) => {
          res && setLoading(false);
        })
        .catch((error) => console.log(error));
    }
    if (button === "next" && pagination < maxPagination) {
      setLoading(true);
      setPagination(pagination + 1);
      setCurrentPage((pagination + 1) * 5 + 1);
      onBringFoodList((pagination + 1) * 5 + 1)
        .then((res) => {
          res && setLoading(false);
        })
        .catch((error) => console.log(error));
    }
    if (button === "first" && currentPage !== 1) {
      setLoading(true);
      setPagination(0);
      setCurrentPage(1);
      onBringFoodList(1)
        .then((res) => res && setLoading(false))
        .catch((error) => console.log(error));
    }
    if (button === "last" && currentPage !== maxPage) {
      setLoading(true);
      setPagination(maxPagination);
      setCurrentPage(maxPage);
      onBringFoodList(maxPage)
        .then((res) => res && setLoading(false))
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <HeadCont
        title={"레시피 찾기"}
        transparent={"white"}
        underline
        onLeftAction={() => {
          if ((foodName && foodName !== "") || tmpValue !== "") {
            setTmpValue("");
            setFoodName(null);
            dispatch(onSetSearchedValue(null));
          }

          if (state?.id !== 0 || foodName === null) {
            navigate(-1);
          }
        }}
      />
      <section className="mb-24 mt-14 min-h-fit">
        <div className="px-6 py-3 border-b border-gray_60">
          <InputSelectBox
            search={true}
            placeholder={
              foodName === null || foodName === ""
                ? "음식명을 입력해 주세요"
                : foodName
            }
            // onChangeAction={onChangeFoodName}
            value={tmpValue}
            setValue={setTmpValue}
            active={foodName ? "on" : "ready"}
            removeSpace={true}
            onSearch={onSearchFoodName}
          />
        </div>

        {state ? (
          // Recipe List (pagination)
          <div className={`mx-6 my-3 h-full`}>
            {state?.id !== 0 && (
              <h1 className="mb-3 pl-[6px] text-body1_700 text-black">
                {state.title}
              </h1>
            )}

            <div
              className="flex flex-col justify-between h-full"
              style={{ height: availableSpace() }}
            >
              {emptyMessage.length === 0 ? (
                loading ? (
                  <ul className="flex flex-col h-full gap-3">
                    {[...Array(maxCountPerPage).keys()].map((_, idx) => (
                      <li
                        key={idx}
                        className="h-[60px] w-full animate-pulse rounded-[10px] bg-gray_20"
                      />
                    ))}
                  </ul>
                ) : (
                  <ul className="flex flex-col gap-3">
                    {foodList?.map((item, index) =>
                      foodContainer(item.details, item.result, index)
                    )}
                  </ul>
                )
              ) : (
                <div className="m-auto">
                  <span className="text-body2_700 text-gray_160">
                    {emptyMessage}
                  </span>
                </div>
              )}
              {emptyMessage.length === 0 && (
                // 페이지네이션
                <div className="flex items-center gap-5 py-4 mx-auto">
                  {maxPagination !== 0 ? (
                    <>
                      <div className="flex" onClick={() => movePage("first")}>
                        <More fill={"#6F6F6F"} className="w-6 h-6 rotate-180" />
                        <More
                          fill={"#6F6F6F"}
                          className="w-6 h-6 -ml-5 -mr-2 rotate-180"
                        />
                      </div>
                      <More
                        fill={"#6F6F6F"}
                        className="w-6 h-6 rotate-180"
                        onClick={() => movePage("prev")}
                      />
                    </>
                  ) : null}
                  {[1, 2, 3, 4, 5].map((v) => {
                    const pageOfList = pagination * 5 + v;
                    if (pageOfList <= maxPage)
                      return (
                        <p
                          key={pageOfList}
                          className={
                            pageOfList === currentPage
                              ? "text-body3_700 text-main"
                              : "text-body3_700 text-gray_140 "
                          }
                          onClick={() => {
                            if (pageOfList !== currentPage) {
                              setLoading(true);
                              setCurrentPage(pageOfList);
                              onBringFoodList(pageOfList)
                                .then((res) => res && setLoading(false))
                                .catch((error) => console.log(error));
                            }
                          }}
                        >
                          {pageOfList}
                        </p>
                      );
                  })}
                  {maxPagination !== 0 ? (
                    <>
                      <More
                        fill={"#6F6F6F"}
                        className="w-6 h-6"
                        onClick={() => movePage("next")}
                      />
                      <div className="flex" onClick={() => movePage("last")}>
                        <More
                          fill={"#6F6F6F"}
                          className="w-6 h-6 -ml-2 -mr-5"
                        />
                        <More fill={"#6F6F6F"} className="w-6 h-6" />
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        ) : (
          // Intro Page
          <ul className="m-6 space-y-3">
            {foodCategory.map((btn) => (
              <li
                id={btn.idName}
                onClick={() => {
                  setCurrentPage(1);
                  setPagination(0);
                  if (foodName && foodName !== "") {
                    dispatch(onSetSearchedValue(null));
                  }
                  setFoodName(null);
                  navigate(`${btn.linkTo}`, {
                    state: { ...btn, loading: true },
                  });
                }}
                key={btn.id}
                className={
                  "flex items-center justify-between rounded-2xl bg-[#EBF1FF] p-6 "
                }
              >
                <h3 className={`text-[${btn.arrowColor}] text-body1_700`}>
                  {btn.title}
                </h3>
                <More fill={btn.arrowColor} className="w-6 h-6" />
              </li>
            ))}
          </ul>
        )}

        {/* Specific Food Group (pagination) */}

        {/* Search Page */}
      </section>
      <MenuBar />
    </>
  );
};

export default FindRecipe;
