import { useDispatch, useSelector } from "react-redux"
import { fetchAnswerList, saveAnswer } from "../../../../features/mobile/questionFoodAddSlice"
import { toast } from 'react-toastify';
import toastCommonProps from '../../../../common/toast';

const AnswerCardArr = ({checkedItems,checkedNoItems,setCheckedItems, answerLists, checkGettingAnswer}) => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp)
  const onClickDietPreferType = (list)=>{
      if(checkGettingAnswer?.filter((el)=>el ==='PT995')?.length>0){
        let params = { userId: loginInfo.userId, dietPreferType: [list.dCode] }
        dispatch(saveAnswer(params)).unwrap()
        .then(res =>{
          if(res.ok){
           dispatch(fetchAnswerList(loginInfo.userId))

         }
         else toast(<p>{res.error}</p>, toastCommonProps('bottom-center', 'toast_alert', 1000))
       })
       .catch((err)=>console.log(err))
      }else{
        const mapcheckedItmesNames = checkedItems?.map((el)=>el.dCode)
        let params = {userId:loginInfo.userId, dietPreferType:[...mapcheckedItmesNames,list.dCode]}
        dispatch(saveAnswer(params)).unwrap()
        .then(res =>{
          if(res.ok){
           dispatch(fetchAnswerList(loginInfo.userId))
         }
         else toast(<p>{res.error}</p>, toastCommonProps('bottom-center', 'toast_alert', 1000))
       })
       .catch((err)=>console.log(err))
      }
  }
  const onDeletePreferType = (list) =>{
    const filterChecked = checkedItems?.filter((el)=>el !==list)
    setCheckedItems(filterChecked)
    checkedNoItems.unshift(list)
  }
  return (
    <>
      <ul className='flex flex-col gap-y-2'>
        {checkedItems === null || checkedItems?.length===0?
        <li></li>:
        (checkedItems?.map((list,idx)=>(
          <li key={idx}
          className="cursor-pointer medicineList rounded-2xl text-btn_700 text-gray_200 inline-block border border-black p-4 flex justify-between items-center"
          >
            <span className='block w-4/5 break-words'>{idx+1+". " + (list.name)}</span>
            <img 
            onClick={()=>onDeletePreferType(list)} 
            src='/images/mobile/icon/close.svg' alt="닫기 아이콘"  />
          </li>
          )))
        }
      </ul>
      <ul className='flex gap-2 flex-wrap'>
        {checkedItems === null||checkedItems?.length === 0?
          answerLists?.map((list, idx) => 
          <li key={idx} onClick={()=>{onClickDietPreferType(list)}}
          className="cursor-pointer medicineList rounded-2xl text-btn_700 text-gray_200 inline-block border border-black break-words p-4"
          >{list.name}</li>
          )
          : checkedNoItems?.map((list, idx) =>
          <li key={idx} onClick={()=>{onClickDietPreferType(list)}}
          className="cursor-pointer medicineList rounded-2xl text-btn_700 text-gray_200 inline-block border border-black break-words p-4"
          >{list.name}</li>
          )
        }
      </ul >
    </>
  )
}

export default AnswerCardArr