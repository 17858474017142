import React from "react";

export const contents = [
  // {
  //   title: `“내 손 안의 영양코치”`,
  //   contents: [
  //     `의사, 간호사, 임상영양사 등 전문가의 다양한 연구를 통해`,
  //     `암환자에게 딱 맞춘 ‘추천식단'을 제공해줍니다.`,
  //   ],
  // },
  // {
  //   title: `“다양한 식단과 맛의 즐거움을 한 번에"`,
  //   contents: [
  //     `400여종 이상의 레시피와 4,200여종 이상의 음식 데이터 기반의`,
  //     `추천 식단을 통해 식단의 다양성은 물론 맛의 즐거움까지`,
  //     `두 마리의 토끼를 한 번에 잡았습니다.`,
  //   ],
  // },
  // {
  //   title: `“24시간 365일 전문가를 내 곁에"`,
  //   contents: [
  //     `의사에게 물어보기 민망했던 질문들, 궁금한 것들을 마음껏 물어보세요.`,
  //     `작은 질문에도 전문가가 직접 섬세하고 전문적으로 답해드릴게요.`,
  //     `또한 챗 GPT를 통해 24시간 언제든 궁금한 점을 물어보실 수 있습니다.`,
  //   ],
  // },
  {
    title: `나만의 식사 가이드`,
    contents: [
      `기록한 식단을 바탕으로 열량, 단백질 중심의`,
      `식사 분석과 신뢰도 높은 임상 영양사가 환자의 상태에 따른`,
      `최적화된 식단을 추천해 드립니다.`,
    ],
  },
  {
    title: `나만의 건강지표 관리`,
    contents: [
      `하루동안 발생한 증상과 변화된 체중을 기록하여`,
      `나의 건강을 모니터링하고 변화된 상태에 따라 새로운`,
      `추천 식단을 제공해 드립니다.`,
    ],
  },
  {
    title: `나만의 스케줄 매니저`,
    contents: [
      `복용해야 하는 복약 스케줄과 진료 일정을 입력하면`,
      `알림을 통해 꼼꼼하게 관리해 드립니다.`,
    ],
  },
];

const SecondPart = () => {
  return (
    <section className="z-10 -mt-[86px] flex flex-col items-center bg-white">
      <h3 className="mt-44 font-extrabold text-black sm:text-[32px] xl:text-[65px]">
        ABOUT HEALIARY
      </h3>
      <p className="text-center font-medium sm:mt-[28px] sm:text-xl xl:mt-[88px] xl:text-[2.5rem] xl:leading-[68px]">
        힐리어리는 암 환자분들의 막막한 치료 여정 속
        <strong className="text-main"> ‘등불’ </strong>이 되어주고자
        <br />
        탄생한 어플리케이션입니다.
      </p>
      {/* <p className="text-center font-semibold text-gray_160 sm:mt-[28px] sm:text-base xl:mt-[106px] xl:text-3xl xl:leading-[53px]">
        힐리어리는 어렵고 막막하게 느껴졌던 암환자 식단의 패러다임을 변화시키고
        있습니다.
        <br />
        모든 암환자 분들의 건강관리의 첫 스텝부터 마지막까지 함께
        동행하겠습니다.
      </p> */}
      {/* <div className="flex w-full flex-col gap-20 sm:my-20 sm:px-[5rem] lg:px-[9rem] xl:mb-[357px] xl:mt-[182px] 2xl:px-[12.5rem]">
        {contents.map((value, index) => {
          return (
            <div
              className={`mx-auto flex h-full sm:w-1/2 sm:flex-col sm:items-center sm:gap-5 xl:w-full xl:flex-row xl:items-center ${
                index % 2 !== 0 ? "xl:justify-end" : "xl:justify-start"
              } xl:gap-12`}
              key={value.title}
            >
              <img
                src={`/images/mobile/landing/landing_main2-${index + 1}.png`}
                alt={value.title}
                className={`xl:w-1/2 ${
                  index % 2 !== 0 ? "xl:order-last" : null
                }`}
              />
              <div
                className={`flex flex-col sm:items-center sm:gap-3 xl:gap-5 3xl:max-w-[700px] ${
                  index % 2 !== 0 ? "xl:items-end" : "xl:items-start"
                }`}
              >
                <p
                  className={`text-2xl font-extrabold text-orange_120  sm:text-center 2xl:text-3xl 3xl:text-4xl ${
                    index % 2 !== 0 ? "xl:text-right" : null
                  }`}
                >
                  {value.title}
                </p>
                <div
                  className={`text-base font-bold text-gray_170 sm:text-center xl:leading-[33px] 2xl:text-xl 3xl:text-2xl ${
                    index % 2 !== 0 ? "xl:text-right" : "xl:text-left"
                  }`}
                >
                  {value.contents.map((p) => (
                    <p key={p}>
                      <span className="leading-[33px]">{p}</span>
                      <br />
                    </p>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div> */}
      <div className="flex w-full flex-col sm:my-20 sm:px-[5rem] lg:flex-row lg:gap-14 lg:px-[9rem] xl:mb-[202px] 2xl:px-[12.5rem]">
        {contents.map((value, index) => {
          return (
            <div
              className={`mx-auto flex h-full sm:w-1/2 sm:flex-col sm:gap-5 md:items-start md:pb-10 lg:w-full lg:flex-col lg:items-start lg:gap-[30px]`}
              key={value.title}
            >
              <img
                src={`/images/mobile/landing/landing_main2-${index + 1}.png`}
                alt={value.title}
                className={`md:w-[100%] xl:w-3/4`}
              />
              <div
                className={`flex flex-col sm:items-center sm:gap-5 md:items-start lg:gap-5 3xl:max-w-[700px] `}
              >
                <p
                  className={`text-2xl font-extrabold	text-black sm:text-center lg:text-2xl 2xl:text-3xl 3xl:text-4xl`}
                  style={{
                    textDecoration: "underline",
                    textDecorationColor: "rgba(65, 127, 255, 0.2)",
                    textDecorationThickness: "18px",
                    textUnderlineOffset: "-10px",
                  }}
                >
                  {value.title}
                </p>

                <div
                  className={`text-[#606365] sm:text-center md:text-left md:text-sm lg:text-body3_600 lg:leading-[33px] `}
                >
                  {value.contents.map((p) => (
                    <p key={p}>
                      <span className="leading-[33px]">{p}</span>
                      <br />
                    </p>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default SecondPart;
