import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  onResetAll,
  onResetPrevTabTitle,
} from "../../../../features/mobile/infoSlice";
import { onResetAll as commOnResetPosts } from "../../../../features/mobile/commSlice";
import FloatingMenu from "./FloatingMenu";

const MenuInner = ({ setMoveMenu }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const [openFloating, setOpenFloating] = useState(false);

  const datas = [
    {
      id: 1,
      idName: "home",
      title: "홈",
      linkTo: "/home",
      onAction: () => navigate(`/home`),
    },
    {
      id: 2,
      idName: "cancergpt",
      title: "챗봇상담",
      linkTo: "/cancergpt",
      onAction: () => {
        navigate(`/cancergpt`);
      },
    },
    {
      id: 3,
      idName: "diary",
      title: "기록",
      linkTo: null,
      onAction: () => setOpenFloating(!openFloating),
    },
    {
      id: 4,
      idName: "information",
      title: "정보",
      linkTo: "/info",
      onAction: () => {
        dispatch(onResetPrevTabTitle());
        dispatch(onResetAll());
        navigate("/info");
      },
    },
    {
      id: 5,
      idName: "hospitalinfo",
      title: "병원정보",
      linkTo: "/hospitalinfo",
      onAction: () => navigate(`/hospitalinfo`),
    },
  ];

  const handleClick = (event, link) => {
    if (location.pathname === "/result") {
      setMoveMenu(link);
      // navigate(link);
    }
  };

  return (
    <>
      {datas.map((data) => (
        <li
          id={data.idName}
          onClick={
            location.pathname === "/result"
              ? (e) => handleClick(e, data.linkTo)
              : data.onAction
          }
          key={data.id}
          className={
            "flex h-14 w-14 flex-col items-center justify-center gap-[10px]"
          }
        >
          {data.title === "기록" ? (
            <div className="w-6 h-6"></div>
          ) : (
            <img
              className={`h-6 w-6`}
              src={`/images/mobile/icon/menu/${
                data.linkTo === path
                  ? `${data.idName}_active`
                  : `${data.idName}`
              }.svg`}
              alt={data.title}
            />
          )}
          <p
            className={`text-center text-[13px] font-semibold ${
              path === data.linkTo ? "text-main" : "text-[#888888]"
            }`}
          >
            {data.title}
          </p>
        </li>
      ))}
      {
        <div
          onClick={() => setOpenFloating(!openFloating)}
          className="absolute -top-3 left-1/2 -translate-x-1/2 rounded-full border-2 border-main bg-white p-[8px] drop-shadow-[0_0_6px_#417FFF]"
        >
          <img
            className="h-[29px] w-[29px]"
            src="/images/mobile/icon/menu/diary.svg"
            alt="기록하기"
          />
        </div>
      }
      {<FloatingMenu openFloating={openFloating} />}
    </>
  );
};

export default MenuInner;
