import dayjs from "dayjs";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onClickInInfoId } from "../../../../features/admin/communitySlice";
import { InformationHeadList } from "../../components/common/TableHeadList";

const InfoMemberList = ({ setDelActive }) => {
  const allRef = useRef();
  const { postingList } = useSelector((state) => state.commu);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tdcsscommon = " text-center py-4  text-black border-b border-gray_80 ";
  const GoToInformationDetail = async (data, e) => {
    if (!(e.target.type === "checkbox")) {
      navigate("/admin/information/detail");
      dispatch(onClickInInfoId(data));
    }
  };

  const onClickCheckEach = () => {
    const eachCheckBox = document.querySelectorAll(".eachCheckbox");
    let truecount = 0;
    for (let i = 0; i < eachCheckBox?.length; i++) {
      if (eachCheckBox[i].checked) {
        truecount++;
      }
    }
    setDelActive(true);

    if (truecount !== postingList.length) {
      allRef.current.checked = false;
      if (truecount === 0) {
        setDelActive(false);
      }
    }
  };
  const onClickCheckAll = () => {
    //모든글 삭제
    const eachCheckBox = document.querySelectorAll(".eachCheckbox");
    if (allRef.current.checked) {
      for (let i = 0; i < eachCheckBox?.length; i++) {
        eachCheckBox[i].checked = true;
      }
      setDelActive(true);
    } else {
      for (let i = 0; i < eachCheckBox?.length; i++) {
        eachCheckBox[i].checked = false;
      }
      setDelActive(false);
    }
  };

  const repeatTd = (data, index) => {
    const checkboxcss =
      "border-b border-l border-gray_80 p-4 text-center text-black " +
      (index + 1 === postingList?.length ? " rounded-bl-lg" : "");
    const isHidecss =
      tdcsscommon +
      " border-r " +
      (index + 1 === postingList?.length ? " rounded-br-lg" : "");
    const tds = [
      {
        tdcss: checkboxcss,
        tdcont: (
          <input
            type="checkbox"
            className="eachCheckbox"
            onClick={onClickCheckEach}
          />
        ),
      },
      { tdcss: tdcsscommon, tdcont: data?.no },
      { tdcss: tdcsscommon, tdcont: data?.category },
      { tdcss: tdcsscommon, tdcont: data?.title },
      { tdcss: tdcsscommon, tdcont: data?.adminName },
      {
        tdcss: tdcsscommon,
        tdcont: dayjs(data?.createdAt).format("YYYY-MM-DD"),
      },
      { tdcss: tdcsscommon, tdcont: data?.readCount },
      { tdcss: tdcsscommon, tdcont: data?.likeCount },

      { tdcss: tdcsscommon, tdcont: data?.bookmarkCount },
      { tdcss: isHidecss, tdcont: data?.isHide ? "비공개" : "공개" },
    ];
    return (
      <>
        {tds?.map((el, idx) => (
          <td key={idx} className={el.tdcss}>
            {el.tdcont}
          </td>
        ))}
      </>
    );
  };

  return (
    <table
      className="w-full col-span-3 border-separate table-auto 3xl:col-span-2"
      cellSpacing={0}
    >
      <thead>
        <tr className="bg-gray_60">
          {InformationHeadList?.map((head, idx) => (
            <th
              key={idx}
              className={
                " border-y border-gray_80 p-4 font-semibold text-black first:rounded-tl-lg first:border-l last:rounded-tr-lg last:border-r " +
                (idx === 0 || idx === 1
                  ? " w-[40px] "
                  : idx === 5
                  ? " w-[120px] "
                  : "")
              }
            >
              {head === "check" ? (
                <input
                  type="checkbox"
                  className="allcheckbox"
                  ref={allRef}
                  onClick={onClickCheckAll}
                />
              ) : (
                head
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {postingList &&
          postingList?.map((data, index) => (
            <tr
              key={index}
              className={
                "cursor-pointer px-6  py-3 hover:bg-primary_10 " +
                (index % 2 === 1 ? "bg-gray_95" : "bg-gray_10")
              }
              onClick={(e) => {
                GoToInformationDetail(data, e);
              }}
            >
              {repeatTd(data, index)}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default InfoMemberList;
