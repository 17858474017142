import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  // handlePageChange,
  // fetchAllUsers,
  onSetSection,
  onSetSubSection,
} from "../../../features/admin/commonSlice";

/*component*/
import Sidebar from "../components/common/Sidebar";
import Navbar from "../components/common/Navbar";
import MainContent from "../components/main/MainContent";
import { fetchMainInfo } from "../../../features/admin/mainSlice";
import MainMemberCard from "../components/main/MainMemberCard";
import MainRank from "../components/main/MainRank";
import { onSetAccountTab } from "../../../features/admin/accountSlice";

const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mainInfo = useSelector((state) => state.main);
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const [surveyRank, setSurveyRank] = useState([]);

  useEffect(() => {
    if (loginInfo?.adminId) {
      dispatch(fetchMainInfo({ adminId: loginInfo?.adminId }));
    } else navigate("/admin/signin");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, loginInfo.adminId]);

  useEffect(() => {
    setSurveyRank(mainInfo?.registSurveyRank);
  }, [mainInfo]);

  const goAccount = (par) => {
    dispatch(onSetSection("계정 관리"));
    dispatch(onSetAccountTab(par));
    dispatch(onSetSubSection(par));
    navigate("/admin/account");
  };

  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <>
          <div className="grid grid-cols-5 gap-4 p-6 pb-0">
            <MainMemberCard
              mainText={"회원"}
              subText1={"전체 회원 수"}
              subText2={"신규 회원 수"}
              totalUserCount={mainInfo?.totalUserCount}
              newUserCount={mainInfo?.newUserCount}
              onClick={() => goAccount("회원")}
            />
            {/* 헤링스슈펴 병원슈퍼 */}
            {loginInfo?.authority === "00" || loginInfo?.authority === "10" ? (
              <MainMemberCard
                mainText={"관리자"}
                subText1={"전체 관리자 수"}
                subText2={"승인 대기자"}
                totalUserCount={mainInfo?.totalAdminCount}
                newUserCount={mainInfo?.approvalWaitCount}
                onClick={() => goAccount("관리자")}
              />
            ) : (
              ""
            )}
            <MainRank surveyRank={surveyRank} />
          </div>

          {/* 공통 */}
          <MainContent user={loginInfo} />
        </>
      </div>
    </>
  );
};

export default Admin;
