import dayjs from "dayjs";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handlePageChange } from "../../../../features/admin/commonSlice";
import { setPageArr } from "../../../../features/admin/paginationSlice";
import { getNotice } from "../../../../features/mobile/settingSlice";
import PaginationLayout from "../../../admin/components/common/pagination/PaginationLayout";
import HeadCont from "../../components/common/HeadCont";

const Notice = () => {
  const dispatch = useDispatch();
  const { noticeList, totalPage } = useSelector((state) => state.setting);
  const { pagePerList5 } = useSelector((state) => state.pagination);
  const [pageOn, setPageOn] = useState(false);
  const [eachNotice, setEachNotice] = useState([]);
  const screenH = window.innerHeight;
  const pagePerList = screenH <= 750 ? pagePerList5 : 6;

  const notiList = (el) => {
    return (
      <>
        <div>
          <p className="text-body3_600">{el.title}</p>
          <span className="text-caption1_400 text-gray_140">
            {el.type === "default"
              ? el.createdAt
              : dayjs(el.createdAt).format("YYYY.MM.DD")}
          </span>
        </div>
        <img src="/images/mobile/icon/arrow_right.svg" alt="더보기 아이콘" />
      </>
    );
  };
  const onPagination = useCallback(
    async (pageNum) => {
      dispatch(handlePageChange(pageNum));
      let params = {
        page: pageNum,
        countPerPage: pagePerList,
      };
      const res = await dispatch(getNotice(params)).unwrap();
      if (res.ok) {
        const defaultarr = Array(res.totalPage)
          .fill()
          ?.map((v, i) => {
            return i + 1;
          });
        if (pagePerList >= pageNum) {
          //1~10사이일경우 pagePerList 10
          dispatch(setPageArr(defaultarr.slice(0, pagePerList)));
        } else {
          //11~
          dispatch(
            setPageArr(
              defaultarr.slice(
                (Math.ceil(pageNum / pagePerList) - 1) * pagePerList,
                Math.ceil(pageNum / pagePerList) * pagePerList
              )
            )
          );
        }
      }
    },
    [dispatch, pagePerList, setPageArr]
  );

  const handlePageFn = (clickPage) => {
    dispatch(handlePageChange(clickPage));
    onPagination(clickPage);
  };

  useEffect(() => {
    onPagination(1);
    dispatch(handlePageChange(1));
  }, [dispatch]);
  return (
    <>
      {pageOn && eachNotice !== null ? (
        // 공지사항 리스트 중 하나의 콘텐츠를 출력하는 화면
        <div className="absolute top-0 bottom-0 left-0 right-0 z-20 overflow-scroll bg-white ">
          <HeadCont
            rightIcon={"no icon"}
            transparent={"white"}
            title={"공지 사항"}
            underline={true}
            onLeftAction={() => setPageOn(false)}
          />
          <div className="mb-6 bg-white mt-14">
            <div className="p-6">
              <p className="text-body3_600">{eachNotice.title}</p>
              <p className="pb-6 text-right border-gray_80 text-caption1_600 text-gray_140">
                {eachNotice.type === "default"
                  ? eachNotice.createdAt
                  : dayjs(eachNotice.createdAt).format("YYYY.MM.DD")}
              </p>
              <div className="py-6 break-words whitespace-pre-line border-t ">
                {eachNotice.files?.map((el, idx) => (
                  <img key={idx} src={el} alt="공지 이미지" />
                ))}
                <p className="py-6 text-body2_500 text-gray_160">
                  {eachNotice.content}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // 공지사항 리스트 화면
        <>
          <HeadCont
            rightIcon={"no icon"}
            transparent={"bg-back_ground"}
            title={"공지 사항"}
            underline={true}
          />
          <section className="mb-6 mt-14">
            <PaginationLayout
              totalPage={totalPage}
              pagePerList={pagePerList}
              onClick={(clickpage) => handlePageFn(clickpage)}
            >
              <ul>
                {noticeList?.map((el, idx) => (
                  <li
                    key={idx}
                    className="flex justify-between p-6 bg-white border-b cursor-pointer"
                    onClick={() => {
                      setPageOn(true);
                      setEachNotice(el);
                    }}
                  >
                    {notiList(el)}
                  </li>
                ))}
              </ul>
            </PaginationLayout>
          </section>
        </>
      )}
    </>
  );
};

export default Notice;
