import React, { useState } from 'react'
import ContentsGuideListLayout from './ContentsGuideListLayout'
import ContentsModal from './ContentsModal'
import RadioBtn from './RadioBtn'
import { useDispatch, useSelector } from 'react-redux'
import {  deleteWeightGuide,  editWeightGuide, getWeightGuide, saveWeightGuide } from '../../../../../features/admin/contentsSlice'
import { toast } from 'react-toastify'
import toastCommonProps from '../../../../../common/toast'
import RadioBtnDisabled from './RadioBtnDisabled'
import ModalSelect from './ModalSelect'
import {  weightModalOptions } from '../functions/service'
import ModalNotSelect from './ModalNotSelect'
import { BGButtonCustom, BorderButtonCustom } from '../../common/ButtonCustom'
import { ThreeConceptReport, TwoConceptReport } from '../ModalReportTop'

const WeightGuideModalIn = ({newModal,editModal, tabName,setNewModal, setEditModal}) => {
  const {loginInfo} = useSelector((state)=>state.adminLogin)
  const weightGuideEach = useSelector((state)=>state.contents.eachGuideList)
  const dispatch = useDispatch()   
  const [editInner, setEditInner] = useState(false)
  const [weightGuide, setWeightGuide] = useState({
    lcategory:weightGuideEach.lcategory,
    mcategory:weightGuideEach.mcategory,
    scategory:weightGuideEach.scategory,
    //files 추후 추가하게되면 넣을것=> files:null,
    content:weightGuideEach.content,
    addAdmin:loginInfo?.adminId
  })

  const onChangeWeight = (e) =>{
    const {name,value} = e.target;
    if(name ==="lcategory"){
      const clickOption =e.target.parentNode.children[1].children[1].innerText
      setWeightGuide({...weightGuide,[name]: clickOption})

    }else{
      setWeightGuide({...weightGuide,[name]:value})
    }
  }
  const submitWEIGHTGuide = async() =>{
    let param = weightGuide
   const res = await dispatch(saveWeightGuide(param)).unwrap()
    if (res.data.ok) {
      toast(<p>저장 완료되었습니다</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
      setTimeout(()=>setNewModal(false),1000)
      dispatch(getWeightGuide());
    } else {
      console.log(res);
    }
  }
const onClickDelete = async(paramid)=>{
  const res = await dispatch(deleteWeightGuide(paramid)).unwrap()
  if (res.data.ok) {
    toast(<p>해당 체중 가이드가 삭제 되었습니다.</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
    dispatch(getWeightGuide());
    setTimeout(()=>{setEditModal(false);},1000)
  } else {
    console.log(res, "체중 가이드 삭제 실패ㅜ");
  }
}
const onClickEdit = async() =>{
  let paramEdit = {
    //가이드 수정클릭시 weightGuide에 id값 추가
    id: weightGuide.id,
    lcategory:weightGuide.lcategory,
    mcategory:weightGuide.mcategory,
    scategory:weightGuide.scategory,
    content:weightGuide.content,
    editAdmin:loginInfo?.adminId
  }
  const res = await dispatch(editWeightGuide(paramEdit)).unwrap()
  if(res.data.ok){
    toast(<p>수정 완료되었습니다</p>, toastCommonProps('bottom-center', 'toast_alert',1000))
    setTimeout(()=>setEditModal(false),1000)
    dispatch(getWeightGuide());
  }else{
    console.log(res)
  }
}

const movePage = ()=>{
  setEditInner(true); 
  setWeightGuide({
  id: weightGuideEach.id,
  lcategory:weightGuideEach.lcategory,
  mcategory:weightGuideEach.mcategory,
  scategory:weightGuideEach.scategory,
  content:weightGuideEach.content,
  editAdmin:weightGuideEach.editAdmin!==null ? weightGuideEach.editAdmin.adminId: weightGuideEach.addAdmin.adminId
})}
  return (
    <>
    {/* 새가이드등록 */}
    {newModal &&
      <ContentsModal
      tabName={tabName}
      setEditModal={setEditModal}
      setNewModal={setNewModal}
      subTab = {"새 가이드 등록"}
      >
        <div className='pb-[32px]'>
           <TwoConceptReport user={loginInfo}/>
        </div>
        <div className='pb-[32px]'>
          <ContentsGuideListLayout title={"치료 단계"}>
            <RadioBtn htmlfor ="treating" commonname="lcategory"
            korean="치료 중" onChangeFunc={onChangeWeight}  />
            <RadioBtn htmlfor ="managing" commonname="lcategory"
            korean="관리 중" onChangeFunc={onChangeWeight}  />
          </ContentsGuideListLayout>
        </div>
        {weightModalOptions?.length>0 &&  weightModalOptions?.map((el,idx)=>(
          <ContentsGuideListLayout title={el.label} key={idx}>
            <ModalSelect 
            datas={el} 
            name={el.type}
            onChangeFunc={onChangeWeight}
            />
          </ContentsGuideListLayout>
        ))}
        <ContentsGuideListLayout title={"내용"}>
          <textarea placeholder='내용을 입력하세요(최대 500자)'
          value={weightGuide.content}
          name="content"
          className='w-full h-[300px] border border-gray_60 rounded px-4 py-2 outline-none bg-transparent'
          onChange={onChangeWeight}
          />
        </ContentsGuideListLayout>
        <div className='w-full flex justify-end gap-x-4 pt-4'>
          <BorderButtonCustom
            onClick={()=>{setNewModal(false)}}
            content={"작성 취소"}
            bgcolor={""}
            textcolor={"text-primary_100"}
            bordercolor={"border-primary_100"}
            textsize={"text-body_2"}
            xpadding={"12px"}
          />
          <BGButtonCustom
            onClick={submitWEIGHTGuide}
            content={"저장"}
            bgcolor={"bg-primary_100"}
            textcolor={"text-gray_100"}
            textsize={"text-body_2"}
            xpadding={"12px"}
          />
        </div>
      </ContentsModal>
    }
    {/* 수정가이드등록 */}
    { editModal && (
      //editInner 가 true면 가이드 수정 component
      editInner ?
      <ContentsModal
      tabName={tabName}
      setEditModal={setEditModal}
      setNewModal={setNewModal}
      subTab={"가이드 수정"}
      >
        <div className='pb-[32px]'>
          <TwoConceptReport user={loginInfo}/>
        </div>
        <div className='pb-[32px]'>
          <ContentsGuideListLayout title={"치료 단계"}>
            <RadioBtn htmlfor ="treating" commonname="lcategory"
            korean="치료 중" onChangeFunc={onChangeWeight}  thekey={weightGuide.lcategory} />
            <RadioBtn htmlfor ="managing" commonname="lcategory"
            korean="관리 중" onChangeFunc={onChangeWeight}  thekey={weightGuide.lcategory} />
          </ContentsGuideListLayout>
        </div>
        {weightModalOptions?.length>0 &&  weightModalOptions?.map((el,idx)=>(
          <ContentsGuideListLayout title={el.label} key={idx}>
            <ModalSelect 
            datas={el} 
            name={el.type}
            onChangeFunc={onChangeWeight}
            categories = {weightGuide}
            />
          </ContentsGuideListLayout>
        ))}
        <ContentsGuideListLayout title={"내용"}>
          <textarea placeholder='내용을 입력하세요(최대 500자)'
          value={weightGuide.content}
          name="content"
          className='w-full h-[300px] border border-gray_60 rounded px-4 py-2 outline-none'
          onChange={onChangeWeight}
          />
        </ContentsGuideListLayout>
        <div className='w-full flex justify-end gap-x-4 pt-4'>
          <BorderButtonCustom
            onClick={()=>setEditInner(false)}
            content={"수정 취소"}
            bgcolor={""}
            textcolor={"text-primary_100"}
            bordercolor={"border-primary_100"}
            textsize={"text-body_2"}
            xpadding={"12px"}
          />
          <BGButtonCustom
            onClick={onClickEdit}
            content={"수정 완료 및 저장"}
            bgcolor={"bg-primary_100"}
            textcolor={"text-gray_100"}
            textsize={"text-body_2"}
            xpadding={"12px"}
          />
        </div>
      </ContentsModal>
      :
      //editInner 가 false이면 가이드 수정전 component
      <ContentsModal
      tabName={tabName}
      setEditModal={setEditModal}
      setNewModal={setNewModal}
      >
        <div className='pb-[32px]'>
          <ThreeConceptReport whatGuideEach={weightGuideEach}/>
        </div>          
        <div className='pb-[32px]'>
          <ContentsGuideListLayout title={"치료 단계"}>
            <RadioBtnDisabled engtit={"lcategory"} label="치료 중"/>
            <RadioBtnDisabled engtit={"lcategory"} label="관리 중"/>
          </ContentsGuideListLayout>
        </div>
        <ContentsGuideListLayout title={"체중 변화"}>
          <ModalNotSelect element={weightGuideEach.mcategory}/>
        </ContentsGuideListLayout>
        <ContentsGuideListLayout title={"체중 단계"}>
          <ModalNotSelect element={weightGuideEach.scategory}/>
        </ContentsGuideListLayout>
        <ContentsGuideListLayout title={"내용"}>
          <div
          className=' bg-[#F3F3F2] w-full h-[300px] border border-gray_90 rounded px-4 py-2'
          >
            {weightGuideEach.content}
          </div>
        </ContentsGuideListLayout>
        <div className='w-full flex justify-end gap-x-4 pt-4'>
          <BorderButtonCustom
            onClick={()=>onClickDelete(weightGuideEach.id)}
            content={"가이드 삭제"}
            bgcolor={""}
            textcolor={"text-primary_100"}
            bordercolor={"border-primary_100"}
            textsize={"text-body_2"}
            width={100}
          />
          <BorderButtonCustom
            onClick={movePage}
            content={"가이드 수정"}
            bgcolor={""}
            textcolor={"text-primary_100"}
            bordercolor={"border-primary_100"}
            textsize={"text-body_2"}
            xpadding={"12px"}
          />
          <BGButtonCustom
            onClick={()=>setEditModal(false)}
            content={"확인"}
            bgcolor={"bg-primary_100"}
            textcolor={"text-gray_100"}
            textsize={"text-body_2"}
            xpadding={"12px"}
          />
        </div>
      </ContentsModal>
      )
    }
    </>

  )
}

export default WeightGuideModalIn