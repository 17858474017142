import React, { useEffect, useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { editAdmin, editPhoto } from "../../../../features/admin/adminSlice";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import DontEditAccountInput from "../../components/account/DontEditAccountInput";
import { onSetCommTab } from "../../../../features/admin/communitySlice";
import EditSelect from "../../components/account/EditSelect";
import AccountImgChange from "../../components/account/AccountImgChange";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../components/common/ButtonCustom";
import {
  correctInputRing,
  correctMsgColor,
  errorInputRing,
  errorMsgColor,
  normalInputRing,
  normalMsgColor,
} from "../../data/TailwindCommon";
import {
  checkEmail,
  checkName,
  checkPhone,
  checkPwd,
} from "../../commonfunc/Reg";
import { LabelAdminInput } from "../../components/common/LabelAdminInput";

const MyAccount = () => {
  const navigate = useNavigate();
  const { accountInfo } = useSelector((state) => state.accountadmin);
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const stateAuthorityFilter =
    accountInfo?.authority === "00"
      ? "슈퍼관리자(헤링스)"
      : accountInfo?.authority === "01"
      ? "일반관리자(헤링스)"
      : accountInfo?.authority === "10"
      ? "슈퍼관리자(병원)"
      : "일반관리자(병원)";
  const stateGenderFilter = accountInfo?.gender === "M" ? "남자" : "여자";
  const [photo, setPhoto] = useState([]);
  //select list
  const genderList = ["남자", "여자"];
  const [editForm, setEditForm] = useState({
    adminId: "",
    name: "",
    authority: "",
    gender: "",
    email: "",
    phoneNumber: "",
    pass: "",
    repass: "",
  });
  const [changeAction, setChangeAction] = useState(false);

  const correctRule =
    checkPwd(editForm.repass) && editForm.pass === editForm.repass;
  const checkPass = !correctRule;
  const checkAll = !(
    !checkName(editForm.name) ||
    !checkPwd(editForm.pass) ||
    !checkPhone(editForm.phoneNumber) ||
    !checkEmail(editForm.email)
  );
  const btnActive =
    !(
      editForm.name === "" ||
      editForm.email === "" ||
      editForm.phoneNumber === ""
    ) &&
    checkName(editForm.name) &&
    checkEmail(editForm.email) &&
    checkPhone(editForm.phoneNumber) &&
    (correctRule || (editForm.pass === "" && editForm.repass === ""));

  useEffect(() => {
    if (accountInfo?.adminId) {
      setEditForm({
        adminId: accountInfo?.adminId,
        name: accountInfo?.name,
        authority: stateAuthorityFilter,
        gender: stateGenderFilter,
        email: accountInfo?.email,
        phoneNumber: accountInfo?.phoneNumber,
        pass: "",
        repass: "",
      });
    } else {
      navigate("/admin/signin");
    }
  }, []);
  const onChangeAction = (e) => {
    const { name, value } = e.target;
    setEditForm({ ...editForm, [name]: value });
    setChangeAction(true);
  };

  const submitData = () => {
    const genderFilterChange = editForm.gender === "남자" ? "M" : "W";
    const authorityFilterChange =
      editForm.authority === "슈퍼관리자(헤링스)"
        ? "00"
        : editForm.authority === "일반관리자(헤링스)"
        ? "01"
        : editForm.authority === "슈퍼관리자(병원)"
        ? "10"
        : "11";

    if (checkPass && editForm.pass === "" && editForm.repass === "") {
      //비밀번호 안바꾸는 경우
      let param = {
        adminId: editForm.adminId,
        name: editForm.name,
        authority: authorityFilterChange,
        gender: genderFilterChange,
        email: editForm.email,
        phoneNumber: editForm.phoneNumber,
      };
      getResult(param, "notchangepass");
    } else {
      //비밀번호 바꾸는 경우
      if (checkAll && !(editForm.pass === "" || editForm.repass === "")) {
        let param = {
          adminId: editForm.adminId,
          name: editForm.name,
          authority: authorityFilterChange,
          gender: genderFilterChange,
          email: editForm.email,
          phoneNumber: editForm.phoneNumber,
          pass: editForm.pass,
        };
        getResult(param);
      } else {
        toast(
          <p>형식에 맞게 작성해주세요.</p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
      }
    }
  };

  const getResult = async (param, hint) => {
    let result = await dispatch(editAdmin(param)).unwrap();
    try {
      if (result.data.ok) {
        //변경된 사진이 있을때 작동
        if (photo?.length !== 0) {
          let photoParam = {
            adminId: editForm.adminId,
            file: photo,
          };
          let secondResult = await dispatch(editPhoto(photoParam)).unwrap();
        }
        if (hint === "notchangepass") {
          toast(
            <p>관리자 수정 완료.</p>,
            toastCommonProps("top-right", "toast_alert", 1000)
          );
          setTimeout(() => navigate("/admin/account", {}), 2000);
          dispatch(onSetCommTab("관리자"));
        } else {
          toast(
            <p>
              저장되었습니다. <br />
              다시 로그인해주세요.
            </p>,
            toastCommonProps("top-right", "toast_alert", 1000)
          );
          window.localStorage.removeItem("admin");
          setTimeout(() => navigate("/admin/signin", {}), 1000);
        }
      } else {
        console.log(result, ">");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onClickGoAccount = () => {
    navigate("/admin/account");
    dispatch(onSetCommTab("관리자"));
  };

  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex w-auto min-w-min flex-col items-start justify-start gap-[16px] p-[24px]">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="flex h-[112px] w-[882px] items-center justify-between gap-[48px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              <div className="flex h-[64px] items-center  justify-between gap-[24px]">
                <div className="relative flex h-[64px] w-[72px]">
                  <AccountImgChange state={loginInfo} setPhoto={setPhoto} />
                </div>
                <div className="flex flex-col justify-center ">
                  <h3 className=" text-xl font-bold">
                    {accountInfo?.name ? accountInfo?.name : ""}
                  </h3>
                  <h4 className=" text-sm text-gray_100	">
                    {stateAuthorityFilter}
                  </h4>
                </div>
              </div>
              <p className="text-sm text-[#05040D]">
                since {dayjs(accountInfo?.createdAt).format("YYYY-MM-DD")}
              </p>
            </div>
            <div className="mt-[16px] flex w-[882px] flex-col items-center gap-[24px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              {/* 표 */}
              <div className="flex w-[340px] flex-col items-center justify-start gap-[8px]">
                <DontEditAccountInput
                  labelname="아이디"
                  value={accountInfo?.adminId}
                />
                <LabelAdminInput
                  title={"이름"}
                  name={"name"}
                  type={"text"}
                  inputVal={editForm.name}
                  placeholderVal={"이름을 입력해주세요"}
                  inputcss={
                    editForm.name === "" || checkName(editForm.name)
                      ? "w-full" + normalInputRing
                      : "w-full" + errorInputRing
                  }
                  onChangeAction={(e) => onChangeAction(e)}
                  onKeyPressAction={submitData}
                  checkCss={
                    !(editForm.name === "" || checkName(editForm.name))
                      ? errorMsgColor
                      : ""
                  }
                  checkVal={
                    !(editForm.name === "" || checkName(editForm.name)) &&
                    "이름 형식을 체크해주세요."
                  }
                />
                <LabelAdminInput
                  title={"비밀번호"}
                  name={"pass"}
                  type={"password"}
                  value={editForm.pass}
                  placeholderVal={"비밀번호를 입력해주세요"}
                  inputcss={
                    editForm.pass === ""
                      ? "w-full" + normalInputRing
                      : checkPwd(editForm.pass)
                      ? "w-full" + correctInputRing
                      : "w-full" + errorInputRing
                  }
                  onChangeAction={(e) => onChangeAction(e)}
                  onKeyPressAction={submitData}
                  onetimeuse={true}
                  checkCss={
                    editForm.pass === ""
                      ? normalMsgColor
                      : checkPwd(editForm.pass)
                      ? correctMsgColor
                      : errorMsgColor
                  }
                  checkVal={
                    checkPwd(editForm.pass)
                      ? "안전한 비밀번호 입니다"
                      : "숫자, 문자, 특수문자 포함 8~12자리로 입력해주세요"
                  }
                />
                <LabelAdminInput
                  title={"비밀번호 확인"}
                  name={"repass"}
                  type={"password"}
                  value={editForm.repass}
                  placeholderVal={"새 비밀번호를 다시 입력해주세요"}
                  inputcss={
                    editForm.repass === ""
                      ? "w-full" + normalInputRing
                      : correctRule
                      ? "w-full" + correctInputRing
                      : "w-full" + errorInputRing
                  }
                  onChangeAction={(e) => onChangeAction(e)}
                  onKeyPressAction={submitData}
                  onetimeuse={true}
                  checkCss={
                    editForm.repass === ""
                      ? normalMsgColor
                      : correctRule
                      ? correctMsgColor
                      : errorMsgColor
                  }
                  checkVal={
                    editForm.repass === ""
                      ? ""
                      : correctRule
                      ? "비밀번호가 일치합니다."
                      : "비밀번호가 일치하지 않습니다."
                  }
                />
                <EditSelect
                  selects={genderList}
                  idname={"gender"}
                  label={"성별"}
                  onChangeSelect={onChangeAction}
                  editForm={editForm}
                />
                {!(
                  accountInfo?.authority === "00" ||
                  accountInfo?.authority === "01"
                ) && (
                  <DontEditAccountInput
                    labelname="소속기관(병원코드)"
                    value={
                      accountInfo?.hospitalCode !== null &&
                      accountInfo?.hospital !== null
                        ? `${accountInfo?.hospital?.name}(${accountInfo?.hospitalCode})`
                        : ""
                    }
                  />
                )}
                {
                  // admin 병원 일반관리자 구분
                  //   1 : 의사
                  //   2 : 간호사
                  //   3 : 영양사
                  accountInfo?.authority === "11" && (
                    <DontEditAccountInput
                      labelname="구분"
                      value={
                        accountInfo?.division !== null
                          ? accountInfo?.division === "1"
                            ? "의사"
                            : accountInfo?.division === "2"
                            ? "간호사"
                            : accountInfo?.division === "3"
                            ? "영양사"
                            : ""
                          : ""
                      }
                    />
                  )
                }
                <LabelAdminInput
                  title={"이메일 주소"}
                  name={"email"}
                  type={"text"}
                  inputVal={editForm.email}
                  placeholderVal={"이메일 주소를 입력해주세요"}
                  inputcss={
                    editForm.email === "" || checkEmail(editForm.email)
                      ? "w-full" + normalInputRing
                      : "w-full" + errorInputRing
                  }
                  onChangeAction={(e) => onChangeAction(e)}
                  onKeyPressAction={submitData}
                  checkCss={
                    !(editForm.email === "" || checkEmail(editForm.email))
                      ? errorMsgColor
                      : normalMsgColor
                  }
                  checkVal={
                    !(editForm.email === "" || checkEmail(editForm.email)) &&
                    "이메일 형식에 맞게 작성해주세요."
                  }
                />

                <LabelAdminInput
                  title={"휴대폰"}
                  name={"phoneNumber"}
                  type={"text"}
                  inputVal={editForm.phoneNumber}
                  placeholderVal={"ex)010-0000-0000"}
                  inputcss={
                    editForm.phoneNumber === "" ||
                    checkPhone(editForm.phoneNumber)
                      ? "w-full" + normalInputRing
                      : "w-full" + errorInputRing
                  }
                  onChangeAction={(e) => onChangeAction(e)}
                  onKeyPressAction={submitData}
                  checkCss={
                    !(
                      editForm.phoneNumber === "" ||
                      checkPhone(editForm.phoneNumber)
                    )
                      ? errorMsgColor
                      : normalMsgColor
                  }
                  checkVal={
                    !(
                      editForm.phoneNumber === "" ||
                      checkPhone(editForm.phoneNumber)
                    ) && "전화번호 형식에 맞게 작성해주세요."
                  }
                />
                <DontEditAccountInput
                  labelname="권한"
                  value={stateAuthorityFilter}
                />
                <DontEditAccountInput
                  labelname="승인"
                  value={
                    accountInfo?.approvalDate !== null
                      ? dayjs(accountInfo?.approvalDate).format("YYYY.MM.DD")
                      : ""
                  }
                />
              </div>
              <div className="flex w-[340px] gap-[10px]">
                <BorderButtonCustom
                  onClick={onClickGoAccount}
                  content={"목록"}
                  bgcolor={""}
                  textcolor={"text-orange_100"}
                  bordercolor={"border-orange_100"}
                  textsize={"text-caption1_600"}
                  width={"30%"}
                />
                <BGButtonCustom
                  onClick={btnActive && changeAction ? submitData : undefined}
                  content={"저장하기"}
                  bgcolor={
                    btnActive && changeAction ? "bg-orange_100" : "bg-orange_80"
                  }
                  textcolor={"text-gray_10"}
                  textsize={"text-caption1_600"}
                  width={"70%"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccount;
