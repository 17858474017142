import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecommendMeal } from "../../../../features/mobile/recommendSlice";
import TitleBox from "../../components/common/TitleBox";
import HeadCont from "../../components/common/HeadCont";
import GuideInner from "./GuideInner";

const Guide = () => {
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { guideLists } = useSelector((state) => state.recommend);
  const [status, setStatus] = useState({
    option: "main",
    title: null,
    type: null,
  }); //main:가이드 메인 페이지, sub:가이드 상세 페이지

  useEffect(() => {
    window.scrollTo(0, 0);
    if (guideLists?.length <= 0)
      dispatch(fetchRecommendMeal({ userId: loginInfo.userId }));
    // eslint-disable-next-line
  }, [dispatch, loginInfo.userId]);
  console.log(guideLists, "guideLists");

  return (
    <>
      {status.option === "main" ? (
        <>
          <HeadCont />
          <section className="mx-6 mb-6 mt-14">
            <TitleBox option={5} title={"식사가이드"} />
            <div className="mt-1 flex justify-between gap-[45px]">
              <p className="text-caption1_400 text-gray_160">
                {loginInfo.patientNickName
                  ? loginInfo.patientNickName
                  : loginInfo.nickName}
                님을 위해 올바른
                <br />
                식습관과 영양 관리 방법을
                <br />
                안내해드립니다.
              </p>
              <img
                src="/images/mobile/character/doctor_to_left.svg"
                alt="의사 이미지"
              />
            </div>
            <ul className="grid grid-cols-2 gap-4 mt-10">
              {guideLists.map((list) => (
                <li
                  key={list.type}
                  onClick={() =>
                    setStatus({
                      option: "sub",
                      title: list.title,
                      type: list.type,
                    })
                  }
                  className="space-y-3 text-center"
                >
                  <span
                    className="bgCover block h-[140px] w-[155px] rounded-2xl"
                    style={{
                      background: `url('/images/mobile/image/guide/guide${list.type}.svg') no-repeat center`,
                    }}
                  ></span>
                  <h2 className="text-black text-body3_500">{list.title}</h2>
                </li>
              ))}
            </ul>
          </section>
        </>
      ) : (
        <>
          <HeadCont
            title={status.title}
            rightIcon={"close"}
            onLeftAction={() =>
              setStatus({ option: "main", title: null, type: null })
            }
            onRightAction={() =>
              setStatus({ option: "main", title: null, type: null })
            }
          />
          <section className="mb-6 mt-14">
            <span
              className="bgCover block h-[106px] w-full"
              style={{
                background: `url('/images/mobile/image/guide/guide${status.type}.svg') no-repeat center`,
              }}
            ></span>
            <ul className="m-6 mt-10">
              <GuideInner data={guideLists[status.type - 1]} />
            </ul>
          </section>
        </>
      )}
    </>
  );
};

export default Guide;
