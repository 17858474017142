const NewToggle = ({ isOn, onToggle, title, justifyB, width, height }) => {
  return (
    <div
      className="relative h-[33px] w-[64px] cursor-pointer"
      onClick={onToggle}
    >
      <div
        className={`absolute rounded-[30px] transition-all duration-300 ease-in-out
                      ${isOn ? "bg-emerald-500" : "bg-gray-300"} 
                      h-full w-full`}
      >
        {/* 토글 배경 */}
      </div>
      <div
        className={`absolute top-[4px] transition-all duration-300 ease-in-out 
                      ${isOn ? "left-[36px]" : "left-[4px]"}
                      h-[24px] w-[24px] rounded-full bg-white`}
      >
        {/* 토글 핸들 */}
      </div>
    </div>
  );
};

export default NewToggle;
