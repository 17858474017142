import React from 'react'
import dayjs from "dayjs";

export const ToolTipLayer = ({children,title})=>{
  return(
    <div className='customTooltipLayout relative'>
      {children}
      <div className='customToolTip absolute top-[25px] left-1/2 transform-x-50 bg-gray_120 text-white text-xs font-bold p-[2px] rounded'>{title}</div>
    </div>
  )
}
const FullGuideInnerTable = ({data,idx,showEditModal,
  cellStyle,DataList,pageUserCount,page}) => {
  const toolTipClassName ="inline-block w-4 h-4 rounded-full border border-gray_80 "
  const exampleColors = ["#FA5E2C", "#61BE82", "#FF914B"];


  return (
    <tr
    onClick={() => {
      showEditModal(data);
    }}
    key={idx}
    className={
      "px-6 py-3 cursor-pointer hover:bg-primary_10  " +
      (idx % 2 === 1 ? "bg-gray_95" : "bg-gray_10")
    }
  >
      <td
        className={
          cellStyle +
          (idx === DataList?.length - 1 ? "rounded-bl-lg" : "")
        }
      >
        {idx + 1 + pageUserCount * (page - 1)}
      </td>
      <td className={cellStyle}>
        {data.kcalIntake ==="과다" ?
          <ToolTipLayer title={data.kcalIntake}>
            <span
              className= {toolTipClassName}
              style={{
                backgroundColor: exampleColors[0],
                borderColor:"transparent"
              }}
            ></span>
          </ToolTipLayer>
          :data.kcalIntake ==="양호" ?
          <ToolTipLayer title={data.kcalIntake} arrow>
            <span
              className= {toolTipClassName}
              style={{
                backgroundColor: exampleColors[1],
                borderColor:"transparent"
              }}
            ></span>
          </ToolTipLayer>
          :
          <ToolTipLayer title={data.kcalIntake} arrow>
            <span
              className= {toolTipClassName}
              style={{
                backgroundColor: exampleColors[2],
                borderColor:"transparent"
              }}
            ></span>
          </ToolTipLayer>
        }
      </td>
      <td className={cellStyle}>
        {data.proteinIntake ==="과다"?
          <ToolTipLayer title={data.proteinIntake} arrow>

            <span
              className= {toolTipClassName}
              style={{
                backgroundColor: exampleColors[0],
                borderColor:"transparent"
              }}
            ></span>


          </ToolTipLayer>
          :data.proteinIntake ==="양호"?
          <ToolTipLayer title={data.proteinIntake} arrow>
          <span
            className= {toolTipClassName}
            style={{
              backgroundColor: exampleColors[1],
              borderColor:"transparent"
            }}
          ></span>
          </ToolTipLayer>
          :          
          <ToolTipLayer title={data.proteinIntake} arrow>
            <span
              className= {toolTipClassName}
              style={{
                backgroundColor: exampleColors[2],
                borderColor:"transparent"
              }}
            ></span>
          </ToolTipLayer>
      }
      </td>
      <td className={cellStyle + " underline "}>보기</td>
      <td className={cellStyle}>
        {data.addAdmin === null ? "-" : data.addAdmin?.name}
      </td>
      <td className={cellStyle}>
        {dayjs(data.createdAt).format("YYYY-MM-DD")}
      </td>
      <td className={cellStyle}>
        {data.editAdmin === null ? "-" : data.editAdmin?.name}
      </td>
      <td
        className={
          cellStyle +
          " border-r " +
          (idx === DataList?.length - 1 ? "rounded-br-lg" : "")
        }
      >
        {dayjs(data.updatedAt).format("YYYY-MM-DD")}
      </td>
    </tr>
  )
}

export default FullGuideInnerTable