import React from "react";
import { motion } from "framer-motion";

const DialogCover = ({ children, onAction, innerClass, duration = 1 }) => {
  return (
    <div
      onClick={onAction}
      className="z-[30] h-screen w-screen fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-70"
    >
      <motion.div
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeOut", duration: duration }}
        onClick={(e) => e.stopPropagation()}
        className={innerClass}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default DialogCover;
