import ApexChart from "react-apexcharts";
import dayjs from "dayjs";
import customToolTip from "./customToolTipGraph";

const OthersAreaGraph = ({
  barHColor,
  dataLists,
  measureUnit,
  color = "#FFE9A0",
}) => {
  const xData = dataLists
    ? dataLists?.map((el) => (el.data ? el.data : null))
    : [];
  const yDate = dataLists
    ? dataLists?.map((el) => dayjs(el.day).format("MM.DD"))
    : [];
  const areaTotal = Math.max(...xData) < 1 ? 1 : Math.max(...xData)+Math.max(...xData)/3
  
  return (
    <div className="admin-graphs">
      <div id={barHColor} className="border border-gray_60 rounded-md">
        <ApexChart
          series={[{ data: xData }]}
          options={{
            colors: [color],
            fill: {
              type: "solid",
            },
            stroke: {
              curve: "straight",
              width: 0,
            },
            chart: {
              selection: {
                enabled: false,
              },
              toolbar: {
                show: false,
                autoSelected: undefined,
              },
              stacked: undefined,
            },

            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: yDate,
              tickPlacement: "on",
              labels: {
                rotate: 0,
                hideOverlappingLabels: true,
              },
              position: "bottom",
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tickAmount: 7,
              crosshairs: {
                //bar background
                show: true,
                position: "back",
                width: 1,
                stroke: {
                  color: "#A39F9B",
                  opacity: 1,
                  width: 1,
                  dashArray: 5,
                },
              },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              min: 0,
              max: areaTotal,
              tickAmount: 4,
              labels: {
                show: true,
                formatter: function (val) {
                  if (val <= 1) return Math.round(val * 5);
                  return Math.round(val).toLocaleString("ko-KR");
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            tooltip: {
              enabled: true,
              enabledOnSeries: undefined,
              custom: ({ series, seriesIndex, dataPointIndex }) =>
                customToolTip(series, seriesIndex, dataPointIndex, measureUnit, yDate),
            },
            grid: {
              padding: {
                right: 5,
                left: 15,
              },
            },
          }}
          type="area"
          height={350}
        />
      </div>
    </div>
  );
};

export default OthersAreaGraph;
