import React from "react";
import { useDispatch } from "react-redux";
import { toggleFooter } from "../../../../features/admin/toggleSlice";

export const Footer = () => {
  const dispatch = useDispatch();

  return (
    <footer className="flex items-center justify-between fixed left-0 bottom-0 w-full bg-gray_10 h-[120px] px-6">
      <div className="flex gap-x-8 items-center">
        <img src="/images/admin/icon/힐리어리.svg" alt="힐리어리 이미지" />
        <div className="flex flex-col items-start order-1 gap-1">
          <p className="text-caption1_400 text-gray_170">
            서울시 강남구 언주로 560, 2층, 14층 헤링스
          </p>
          <p className="text-caption1_400 text-gray_170">
            Copyright © 2022 HERINGS. All rights reserved.
          </p>
        </div>
      </div>
      <div className="flex flex-row items-start gap-[9px] order-1 left-full mt-2 cursor-pointer">
        <p
          className="text-gray_170 text-body2_400"
          onClick={() => dispatch(toggleFooter("서비스 이용약관"))}
        >
          이용약관
        </p>
        <p className="text-gray_170 text-body2_400">|</p>
        <p
          className="text-gray_170 text-body2_400"
          onClick={() => dispatch(toggleFooter("개인정보 처리방침"))}
        >
          개인정보 처리방침
        </p>
      </div>
    </footer>
  );
};
