// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onClickCommentOpen,
  searchManagementCommunity,
} from "../../../../features/admin/communitySlice";
import { toggleBoardModal } from "../../../../features/admin/toggleSlice";
// import Comment from "../../components/board/Comment";
import CommunityDetailReply from "../../components/board/CommunityDetailReply";
import { CommentBtn } from "../../components/common/ButtonCustom";
import CustomCenterModal from "../../components/common/CustomCenterModal";
import NewsModalContent from "./ModalContent/NewsModalContent";

const BoardReadModal = ({
  postingIdisHideToggle,
  showEachNoticeModal,
  showWriteModal,
}) => {
  const tabName = useSelector((state) => state.commu.clickCommuTab);
  const { commentOpen, postingIdList, postingIdReplies } = useSelector(
    (state) => state.commu
  );
  const { page } = useSelector((state) => state.common);
  const { pagePerList } = useSelector((state) => state.pagination);
  const dispatch = useDispatch();
  const closesvg = "/images/admin/icon/close.svg";
  const toggleModalFalse = () => {
    dispatch(toggleBoardModal(false));
    //여기다가 search
    let params = {
      page: page,
      countPerPage: pagePerList,
      writerUserId: "",
      title: "",
      content: "",
    };
    dispatch(searchManagementCommunity(params));
    dispatch(onClickCommentOpen(false)); //comment default
  };

  return (
    <>
      <CustomCenterModal
        onClickFc={toggleModalFalse}
        customlayout={
          "absolute top-1/2 left-1/2 transform-50 bg-white rounded-xl w-[800px] z-30 overflow-hidden"
        }
      >
        <div
          className={
            "h-[600px] min-h-[400px] gap-[16px] overflow-y-auto rounded " +
            (commentOpen ? " bg-gray-100 " : "")
          }
        >
          <div className="absolute left-0 top-0 z-30 flex w-full items-center justify-between rounded-t-[8px] border-b  border-gray_80 bg-white p-[8px]">
            <span className="text-subtitle_1 block flex items-center px-[24px] font-bold text-black">
              {tabName + " 상세"}
            </span>
            <img
              src={closesvg}
              alt="close"
              className="cursor-pointer"
              onClick={toggleModalFalse}
            />
          </div>
          <div
            className={
              "mt-16 w-full rounded-b-[8px] " +
              (commentOpen ? " bg-gray-100 px-10  " : " bg-white")
            }
          >
            {commentOpen ? (
              <div>
                <div className="flex justify-end pt-6">
                  <CommentBtn
                    onClickAction={() => dispatch(onClickCommentOpen(false))}
                    title="뒤로가기"
                    bgcolor="bg-white"
                    txtcolor="text-orange_100"
                    bordercolor="border border-orange_100"
                  />
                </div>
                <div className="my-4 w-full rounded-sm bg-white ">
                  {postingIdReplies?.length > 0 ? (
                    postingIdReplies?.map((el, idx) => (
                      <CommunityDetailReply
                        key={idx}
                        el={el}
                        postingId={postingIdList.id}
                      />
                    ))
                  ) : (
                    <div className="bg-gray-100 py-4">
                      게시글에 댓글이 없습니다.
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <NewsModalContent
                postingIdisHideToggle={postingIdisHideToggle}
                showEachNoticeModal={showEachNoticeModal}
                showWriteModal={showWriteModal}
              />
            )}
          </div>
        </div>
      </CustomCenterModal>
    </>
  );
};

export default BoardReadModal;
