import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ClipboardJS from "clipboard";
import toastCommonProps from "../../../common/toast";

export default function useLongPress() {
  const [action, setAction] = useState();
  const [active, setActive] = useState(false);
  const timerRef = useRef();
  const isLongPress = useRef();
  const navigate = useNavigate();

  const copyToClipboard = (content) => {
    try {
      navigator.clipboard
        .writeText(content)
        .then(() => {
          toast(
            <p>{"URL 복사 완료"}</p>,
            toastCommonProps("bottom-center", "toast_alert")
          );
        })
        .catch((error) => {
          // for android phones with no navigator.clipboard.writeText permission
          const success = ClipboardJS.copy(content);
          if (success) {
            toast(
              <p>{"URL 복사 완료"}</p>,
              toastCommonProps("bottom-center", "toast_alert")
            );
          } else {
            console.log(error);
          }
          // const fakeButton = document.createElement("button");
          // fakeButton.addEventListener("click", () => {
          //   if (
          //     copy(content, {
          //       debug: false,
          //       message: "",
          //     })
          //   ) {
          //     toast(
          //       <p>{"URL 복사 완료"}</p>,
          //       toastCommonProps("bottom-center", "toast_alert")
          //     );
          //     fakeButton.remove();
          //   } else {
          //     console.error("Failed to copy text to clipboard:", content);
          //   }
          //   fakeButton.remove();
          // });
          // fakeButton.click();
        });
    } catch (error) {
      console.log(
        "copy function not supported. Either HTTP is not secure or the browser is not compatible"
      );
    }
  };

  function startPressTimer(e) {
    isLongPress.current = false;
    timerRef.current = setTimeout(() => {
      isLongPress.current = true;
      setAction("longpress");
      copyToClipboard(e.target.innerHTML);
    }, 500);
  }

  function handleOnClick(e, uniqueString) {
    console.log("handleClick");
    if (isLongPress.current) {
      console.log("is long press - not continuing");
      return;
    }
    navigate(uniqueString);
    // alert("click");

    setAction("click");
  }

  function handleOnMouseDown(e) {
    console.log("handleOnMouseDown");
    setActive(true);
    startPressTimer(e);
  }

  function handleOnMouseUp(e) {
    console.log("handleOnMouseUp");
    setActive(false);
    clearTimeout(timerRef.current);
  }

  function handleOnTouchStart(e) {
    console.log("handleOnTouchStart");
    setActive(true);
    startPressTimer(e);
  }

  function handleOnTouchEnd(e) {
    // if (action === "longpress") {
    //   return;
    // }
    console.log(e);
    setActive(false);
    clearTimeout(timerRef.current);
  }

  function getClassName(e) {
    let className = "text-correct underline  break-all ";
    if (active) {
      className += ` text-blue-600/50`;
    }
    return className;
  }

  return {
    action,
    handlers: {
      onClick: handleOnClick,
      onMouseDown: handleOnMouseDown,
      onMouseUp: handleOnMouseUp,
      onTouchStart: handleOnTouchStart,
      onTouchEnd: handleOnTouchEnd,
      className: getClassName,
    },
  };
}
