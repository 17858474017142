import React from 'react';
import Parser from "html-react-parser";

const TermsOfService = () => {
    const datas = [
        {
            id: 1, title: '제1조(목적)', contents: `본 약관은 주식회사 헤링스(이하 “회사”라 합니다)가 운영하는 '힐리어리' 서비스(이하 “서비스”라 합니다)를 회원이 이용함에 있어 회사와 회원간의 권리, 의무 및 책임사항 및 회원의 게시물에 대한 내용 등을 규정함을 목적으로 합니다.`
        }, {
            id: 2, title: '제2조(약관의 효력 및 변경)', contents: `① 본 약관은 서비스를 이용하고자 본 약관에 동의한 모든 회원에 대하여 그 효력을 발생합니다.<br/>
            ② 회사는 약관의 규제에 관한 법률 전자상거래 등에서의 소비자보호에 관한 법률, 정보통신망이용촉진 및 정보보호 등에 관한 법률, 소비자기본법 등 관련법령을 위반하지 않는 범위 내에서 필요 시 약관을 변경할 수 있으며, 약관을 변경할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하게 변경되거나 중요한 내용을 변경하는 경우에는 30일 이전부터 적용일자 전일까지 상기 방식으로 공지하며 공지 외 SMS, MMS 발송, 로그인시 동의팝업 알림, 이메일 발송 등 전자적 수단을 통해 별도로 통지합니다.<br/>
            ③ 회사가 제2항에 따라 변경된 약관을 공지 또는 통지하면서 회원에게 약관 변경 적용일까지 거부의사를 표시하지 아니할 경우 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하면, 회원이 변경 약관에 동의한 것으로 간주됩니다.<br/>
            ④ 변경 약관에 동의하지 않는 회원은 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.<br/>
            ⑤ 본 약관에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련법령을 적용합니다.`
        }, {
            id: 3, title: '제3조(용어의 정의)', contents: `① 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.<br/>
            1.  서비스: 스마트폰에서 네트워크를 이용하여 접속하는 애플리케이션으로 웨어러블 기기(스마트 밴드), IOT 기기 또는 의료기기로부터 측정된 값을 수신 받아 실시간 운동정보 및 건강관리에 필요한 정보를 제공하는 일련의 서비스를 말합니다.<br/>            
            2. 회원: 본 서비스를 이용하기 위하여 본 약관에 동의하고, 회사와 서비스 이용계약을 체결한 자를 의미합니다.<br/>
            3. 애플리케이션: 스마트폰에서 회원이 본 서비스를 이용할 수 있도록 구성된 프로그램을 말합니다.<br/>
            4. 개인정보: 개인정보보호법 제2조 제1호에 규정된 “개인정보”를 말합니다.<br/>
            5. 결제: “회사”가 제공하는 “유료서비스”를 이용하기 위하여 각종 지불수단을 통하여 일정 금액을 “회사” 또는 “회사”가 정한 제3자에 지불하는 것을 의미합니다.<br/>
            6. 구독: “회사”가 제공하는 “유료서비스”의 결제 방식으로 정해진 기간 동안 사용하는 서비스를 의미하며 “정기 구독”의 경우 정해진 기간이 지나면 자동으로 기간이 갱신되어 결제되는 서비스를 의미합니다.<br/>
            ② 본 조 제1항에서 정의된 용어 이외의 용어에 대해서는 관련 법령 및 서비스별 안내 등에서 정의하는 바에 따릅니다.`
        }, {
            id: 4, title: '제4조(서비스 이용계약의 성립)', contents: `① 서비스의 이용 계약은 회원이 되고자 하는 자의 가입 신청 및 “구독”에 대하여 회사가 승낙함으로써 성립합니다. 다만, 회사는 다음 각 호의 경우에 한하여 이용신청을 거절하거나 유보할 수 있습니다.<br/>
            1. “구독” 결제가 완료되지 않은 경우<br/>
            2. “결제” 정보에 이상이 생겨 정상적으로 결제가 이루어지지 않은 경우<br/>
            3. 설비에 여유가 없거나 기술상 지장이 있는 경우<br/>
            4. 회원 자격을 상실한 자로서 회사가 정한 일정 가입보류 기간이 경과되지 아니한 자<br/>            
            5. 기타 회사가 별도로 정한 이용신청 기준에 부합되지 아니한 자<br/>
            6. 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우<br/>
            7. 타인 명의로 신청하거나 제출 서류의 내용이 사실과 다른 허위인 경우<br/>
            8. 회사에 서비스 이용을 신청한 회원이 서비스 제공과 관련되는 요금 등을 납부하지 않고 있는 경우<br/>
            9. 미성년자, 법정대리인의 동의 없는 피한정후견인 등이 서비스 이용을 신청할 경우<br/>
            10. 기타 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우<br/>
            ② 회원이 본 약관에 동의함은 본 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의하는 것으로 봅니다.<br/>
            ③ 서비스 제공지역은 회사의 서비스 허가지역, 즉 대한민국 영토에 한정됩니다. 서비스 제공지역 이외의 서비스 제공은 원칙적으로 금지됩니다.`
        }, {
            id: 5, title: '제5조(애플리케이션의 설치)', contents: `회원은 서비스를 이용하기 위해서 회원의 스마트폰에 애플리케이션을 설치하여야 합니다.`
        }, {
            id: 6, title: '제6조(이용계약의 종료)', contents: `① 회원의 해지<br/>
            1. 회원이 서비스 이용계약을 해지하고자 할 경우에는 서비스 내에 회원 탈퇴 메뉴를 통해 회사에 해지신청을 하여야 합니다. 애플리케이션을 삭제하는 것이 회원 해지를 의미하는 것은 아니므로 서비스 이용계약 해지를 위해서는 반드시 애플리케이션 내에 탈퇴 메뉴를 이용하여야 합니다.<br/>
            2. 회원 탈퇴가 구독의 해지를 의미하는 것은 아니므로 구독 해지를 위해서는 반드시 결제한 해당 스토어에 구독 취소를 하셔야 합니다.<br/>
            3. 회원 탈퇴 시 서비스 내에서 사용 가능한 포인트와 상담쿠폰도 삭제되며, 탈퇴로 인해 발생한 불이익에 대한 책임은 회원 본인이 져야합니다.<br/> 
            4. 회원 탈퇴로 인해 발생한 불이익에 대한 책임은 회원 본인이 져야 하며, 이용계약이 종료되면 회사는 회원에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.<br/>
            5. 회원의 의사로 이용계약을 해지한 후, 추후 재이용을 희망할 경우에는 재이용 의사가 회사에 통지되고, 이에 대한 회사의 승낙이 있는 경우에만 서비스 재이용이 가능합니다.<br/> 
            ② 회사의 해지<br/>
            1. 회사는 다음 각 호에 해당하는 사유가 발생한 경우 이용계약을 해지할 수 있습니다.<br/>
            가. 다른 회원의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국 법령 또는 공서양속에 위배되는 행위를 한 경우<br/>
            나. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우<br/>
            다. 제4조 제1항의 승낙유보 또는 거절사유가 있음이 확인된 경우<br/>
            라. 회원이 임의로 서비스 관련 지식재산권을 무단 복제하거나 배포하는 등 회원의 지식재산권 침해가 발생한 경우<br/>
            마. 기타 법령, 이용약관의 규정을 위반하거나 회사의 업무를 방해하는 경우<br/>
            바. 회사가 제공하는 서비스를 정상적인 용도 이외 또는 부당한 방법으로 서비스를 이용하는 행위<br/>
            2. 회사가 이용계약을 해지하는 경우 회사는 회원에게 SMS, 전화, 애플리케이션의 공지메뉴나 PUSH메시지 또는 이메일 등을 통하여 해지사유와 함께 해지의사를 통지합니다. 서비스 이용계약은 회사의 해지의사가 회원에게 도달한 시점에 종료됩니다.<br/>
            3. 이용계약이 종료될 때에는 회사는 회원에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.<br/>
            4. 본 항에서 정한 바에 따라 이용계약이 종료된 경우에는, 회원의 재이용신청에 대하여 회사는 이에 대한 승낙을 거절할 수 있습니다.`
        }, {
            id: 7, title: '제7조(서비스의 이용 및 회원의 의무)', contents: `① 회사는 승낙 거절 사유 관련한 특이사항이 없는 한, 회원 가입 시점부터 즉시 서비스를 제공합니다.<br/> 
            ② 서비스 이용시간은 회사의 업무상 또는 기술상 불가능한 경우를 제외하고는 연중무휴 1일 24시간(00:00-24:00)으로 함을 원칙으로 합니다.<br/>
            ③ 회사는 서비스 설비의 정기점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우일정 기간 동안 서비스 제공을 일시 중지하거나 서비스 제공 시간을 제한할 수 있으며, 이 경우 회사는 회원에 대해 그 사유를 사전에 통지합니다. 단, 회사는 사전 고지가 불가능한 긴급한 사유가 있는 경우 사후에 통지할 수 있습니다.<br/>
            ④ 회사는 Android, iOS 전용 애플리케이션을 제공하며, 회원은 회사에서 허용하는 범위 내의 단말기에서 전용 애플리케이션을 다운받고 설치하여 서비스를 이용할 수 있습니다.<br/>
            ⑤ 회원은 연락처, 개인정보 등이 변경된 경우에는 회사에 이를 신속히 알려야 하며 이를 소홀히 하여 발생한 불이익은 회원의 책임으로 합니다.<br/>
            ⑥ 소위 '탈옥'/'루팅' 폰에는 서비스 이용이 제한됩니다.<br/>
            ⑦ 지원 가능한 스마트폰 OS는 iOS 10.0 이상 안드로이드 5.0.0 이상 입니다.<br/>
            ⑧ “회원”이 휴대폰, 무선 모뎀이 내장/장착된 PC 등에서 무선네트워크를 통하여 “회사”의 사이트에 접속하거나 무선네트워크가 연결된 상태의 기기 내에 탑재된 “회사”의 애플리케이션을 통하여 “회사”의 제반 서비스 및 이용권을 이용하는 경우 및 해외에서 “회사”의 제반 서비스 및 이용권을 이용하게 되는 경우에는 “회원”이 가입한 해당 통신사간에 체결된 통신 요금제에 의한 별도의 데이터 통화료가 발생합니다. 이 경우 발생하는 데이터 통화료는 “회원”과 해당 통신사간에 체결된 통신요금제에 따라 과금/청구/수납되므로, 데이터 통화료 분에 대해서는 “회사”는 어떠한 책임도 지지 않습니다.`
        }, {
            id: 8, title: '제8조(서비스 제한과 중단)', contents: `① 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지, 중단할 수 있으며, 그 내용을 사전에 회원에게 고지하거나, 사전 고지가 어려운 사항일 경우 사후에 고지할 수 있습니다.<br/>
            1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우<br/>
            2. 서비스 업그레이드 및 사이트 유지보수 등을 위해 필요한 경우<br/>
            3. 사업환경의 변화 또는 기술발전 등으로 인하여 서비스를 계속 제공하는 것이 현저히 곤란한 경우<br/>            
            4. 회원이 회사의 영업활동을 방해하는 경우<br/>
            5. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우<br/>
            6. 행정기관의 행정처분(방송통신위원회의 폐지나 휴지, 승인을 포함)이나, 법원의 판결, 결정 등으로 인하여 서비스를 계속 제공할 수 없는 경우<br/>
            7. 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우<br/>
            ② 회사는 제1항의 제한 또는 중지, 중단에 대하여 해당 사유, 내용 및 중지 일자 등을 제한 또는 중지, 중단 되기 전 15일 이전까지 서비스 초기화면에 게시하거나 회원에게 통지합니다. 다만, 본 조 제1항 제3호의 경우 제한 또는 중지, 중단 되기 전 60일 이전까지 서비스 초기화면에 게시하거나 회원에게 통지합니다.<br/>
            ③ 회사는 무료로 제공하는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요에 따라 유료화, 중단, 변경할 수 있으며, 이에 대하여 관련법령에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.<br/>
            ④ “회사”는 서비스의 일부 또는 전부를 “회사”의 사업 계획 및 서비스 운영정책에 따라 수정·변경 및 중단할 수 있으며, 이에 대하여 관련 법령에 특별한 규정이 없는 한 “회원”에게 별도의 보상을 하지 않습니다.<br/>
            ⑤ 회사는 본 조에 따른 서비스의 제한 또는 중지로 인하여 회원 및 제3자가 입은 손해에 대해 배상하지 않는 것을 원칙으로 합니다. 단, 회사에 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.`
        }, {
            id: 9, title: '제9조(정보 및 광고의 제공)', contents: `① 회사는 회원의 서비스 이용과 관련하여 필요한 정보를 서비스 초기화면에 게시하거나 SMS 등을 통해 제공할 수 있습니다. 단, 영리목적의 광고성 정보의 전송에 관한 것으로서 관련 법령에 따라 회원의 동의가 필요한 경우 관련 법령에 따른 절차를 거칩니다.<br/>
            ② 정보 수신에 동의한 회원이 정보제공을 원치 않는다는 의사를 밝히는 경우 회사는 정보제공대상에서 해당 회원을 제외하며, 정보제공대상에서 제외됨으로써 정보를 제공받지 못해 회원에게 불이익이 발생할 경우에는 회사는 이에 대한 책임을 지지 않습니다.<br/>
            ③ 회사는 정보와 함께 배너 및 동영상 형태의 광고를 게재할 수 있고, 이때 회원의 특성에 맞는 맞춤 광고를 제공하기 위해 "개인정보처리방침" 및 “개인정보 수집·이용 동의서”에 위배되지 않는 범위에서 회원의 동의를 받아 개인정보를 활용할 수 있습니다.<br/>
            ④ 회원이 광고를 통한 제3자의 서비스를 이용하는 것과 관련하여 발생하는 모든 문제에 대해서 회사는 어떠한 책임도 지지 않습니다.`
        }, {
            id: 10, title: '제10조(유료서비스 이용 및 계약) ', contents: `① 본 약관에 동의한 “회원”이 “유료서비스”에 대한 이용신청을 하고 “회사”가 이를 승낙함으로써 “회사”가 운영하는 “유료서비스”에 대한 이용계약은 성립합니다.<br/>
            ② 해당 “유료서비스”에 대한 세부 이용조건은 상품 안내 및 구매 페이지에서 안내합니다.<br/>
            ③ “회사”는 “회원”의 “유료서비스” 이용신청이 다음에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다.<br/>
            1. 타인의 명의를 이용한 경우<br/>
            2. 허위의 정보를 기재하거나, “회사”가 제시하는 필수 내용을 기재하지 않은 경우<br/>
            3. 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우<br/>
            ④ 서비스 이용시의 데이터량에 따른 요금은 “회사”가 별도로 청구하지 않으며, “회원”이 가입한 이동통신사가 청구합니다.`
        }, {
            id: 11, title: '제11조(정기구독서비스)', contents: `① “정기구독서비스”는 “회원”이 이용기간 동안 “서비스”에서 제공하는 모든 기능을 이용할 수 있는 “유료서비스”입니다. 단, 별도의 디바이스(스마트밴드, 블루투스 디바이스 등)가 필요한 기능의 경우는 제외합니다.<br/>
            ② “회원”이 “정기구독서비스”의 이용료를 결제하지 않거나, “정기구독서비스”를 취소하는 등의 이유로 “정기구독서비스”의 이용이 해지되는 경우 “서비스”를 이용할 수 없습니다.`
        }, {
            id: 12, title: '제12조(“유료서비스” 이용에 관한 해제ㆍ해지) ', contents: `① Apple App Store에서 판매하는 서비스에 대한 계약해제 및 환불 처리는 App Store 환불정책에 준합니다.<br/>
            ② Google Play에서 판매하는 서비스에 대한 계약해제 및 환불 처리는 Google Play 환불정책에 준합니다.<br/>
            ③ 회원 탈퇴가 구독의 해지를 의미하는 것은 아니므로 구독 해지를 위해서는 반드시 결제한 해당 스토어에 구독 취소를 하셔야 합니다.<br/>
            ④ “회원” 또는 “회사”가 유료 서비스에 대한 이용계약을 해지할 경우, “회원”의 개인정보는 관련법령 및 개인정보처리방침에서 정하는 바에 따라 일정 기간 저장된 후 파기됩니다.`
        }, {
            id: 13, title: '제13조(청약철회 및 해지)', contents: `① ”회원”이 서비스 이용계약을 해지하고자 할 경우에는 환불 기간 내에 대금의 “결제“와 동일한방법으로 이를 환불 요청하여야 하며, 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지하여야 합니다. 다만, 수납확인이 필요한 결제수단의 경우에는 수납확인일로부터 3영업일이내에 이를 환불하도록 합니다.<br/>
            ② Apple App Store에서 판매하는 서비스에 대한 계약해제 및 환불 처리는 App Store 환불정책에 준합니다.<br/>
            ③ Google Play에서 판매하는 서비스에 대한 계약해제 및 환불 처리는 Google Play 환불정책에 준합니다.<br/>
            ④ 취소 또는 환불 신청을 받은 “회사”는 각 서비스의 각 인터넷서비스에 명기된 청약철회 규정에 따라 취소 또는 환불 신청에 응할 수 있습니다. 단, 다음 각 호에 해당하는 경우 사용자의 환불신청을 승낙하지 아니 할 수 있습니다.<br/>
            1. 사용자의 실수로 이용기간 내에 서비스를 이용하지 못한 경우<br/>
            2. 어떠한 통보 없이 일방적인 어플 삭제 후 서비스를 이용하지 아니한 경우<br/>
            3. 기타 정당한 환불사유로 판명되지 않은 경우<br/>
            ⑤ 회원이 유료서비스를 프로모션 등을 통해 무료/무상으로 취득하는 등 회원이 직접 비용을 지불하지 아니한 서비스에 대하여는 회사는 환불 의무를 부담하지 않습니다.<br/>
            ⑥ 회원의 탈퇴 요청 시, 유료서비스 기간이 남은 경우 이를 환불 받거나 유료서비스 기간이 끝난 이후 탈퇴를 요청해야 합니다. 회사가 유료 서비스 기간을 안내하였음에도 불구하고 자진 탈퇴 요청 시 유료 서비스에 대한 비용은 일체의 책임을 부담하지 아니합니다. `
        }, {
            id: 14, title: '제14조(정기구독결제 및 해지) ', contents: `① 회원이 자동결제를 신청하시면 별도의 해지 신청을 하지 않을 경우, 유료서비스의 실제 이용 여부와 상관없이 매월 지정된 결제 일자 및 수단으로 자동청구 및 결제됩니다.<br/>
            ② 회원이 자동결제 상품 서비스 이용을 해지하고자 하는 경우 해지예약을 신청할 수 있고, 해지예약 신청 시 이용중인 서비스의 만료일에 적용, 해지되며 추후 결제 등은 이루어지지 않습니다.<br/>
            ③ 타인의 결제 정보를 동의 없이 사용할 경우 민/형사상 책임을 지게 되며 관계법령에 의해 처벌될 수 있습니다.<br/>
            ④ 유료서비스의 이용요금을 체납하는 경우 연체가 발생한 날 자동으로 상품 해지가 될 수 있으므로, 월 자동결제를 통한 혜택을 유지하고자 하는 회원은 이용요금의 체납 또는 결제수단의 연체가 발생하지 않도록 사전에 조치하여야 합니다.<br/>
            ⑤ 결제 정보 변경, 신용카드 및 휴대전화의 분실, 기타의 사유로 정기결제가 이루어지지 않을 경우 서비스 이용이 제한될 수 있으므로 사전에 조치하여야 합니다.<br/>
            ⑥ 회원의 이용요금 미납 등 귀책사유로 인한 정기결제중지 및 이에 따른 서비스 이용정지로 인한 손해에 대해 “회사”는 책임을 지지 않습니다.<br/>
            ⑦ Apple App Store에서 판매하는 서비스에 대한 자동 결제 및 해지는 직접 앱스토어에 신청해주셔야 하며 계약해제 및 환불 처리는 App Store 환불정책에 준합니다.<br/>
            ⑧ Google Play에서 판매하는 서비스에 대한 자동 결제 및 해지는 직접 구글 플레이에 신청주셔야 하지만 자사에 요청 시 해지 및 해제를 대신 해 드릴수 있으며 계약해제 및 환불 처리는 Google Play 환불정책에 준합니다.`
        }, {
            id: 15, title: '제15조(회원 관리) ', contents: `① 회사는 약관의 본 취지와 관련 법령 및 상거래의 일반원칙을 위반한 회원에 대하여 다음과 같은 조치를 선택적 또는 중첩적으로 행사할 수 있습니다.<br/>
            1. 회사가 부가적으로 제공한 혜택의 일부 또는 전부의 회수<br/>
            2. 특정서비스 이용제한<br/>
            3. 이용계약의 해지<br/>
            4. 손해배상의 청구<br/>
            ② 회사가 전항 각 호에 정한 조치를 할 경우 회사는 사전에 회원에게 유선 또는 SMS로 통보하며, 회원의 연락이 두절되거나 긴급을 요하는 것과 같이 부득이한 경우 선 조치 후 사후 통보할 수 있습니다.<br/>
            ③ 회원은 본 조에 의한 회사의 조치에 대하여 항변의 사유가 있는 경우 이에 대하여 항변을 할 수 있습니다.`
        }, {
            id: 16, title: '제16조(면책사항)', contents: `회사는 다음 각 호에 해당하는 사유로 인하여 회원 또는 제3자에게 발생한 손해에 대하여는 그 책임을 지지 아니합니다.<br/>
            1. 천재지변 또는 이에 준하는 불가항력적 사유로 인해 서비스를 제공할 수 없는 경우<br/>
            2. 회원이 자신의 아이디 또는 비밀번호 등의 관리를 소홀히 하는 등 타인의 부정사용을 방치한 경우<br/>
            3. 회원이 제3자의 아이디, 비밀번호 등 개인정보를 도용하여 제3자에게 손해를 발생시킨 경우<br/>
            4. 회사의 지배관리 영역이 아닌 공중통신선로 등 기술적인 장애로 서비스 이용이 불가능한 경우<br/>
            5. 기타 회사의 귀책사유가 없는 장애 등으로 인한 경우 `
        }, {
            id: 17, title: '제17조(손해배상 등) ', contents: `① 회원은 서비스에 게시된 자료 또는 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 및 기타 방법에 의해 영리목적으로 이용하거나 제3자에게 이용하게 할 수 없으며, 회원이 이를 위반하여 발생한 손해에 대하여 회사는 회원에게 손해배상을 청구할 수 있습니다.<br/>
            ② 회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하는 경우 회사에 발생하는 모든 손해를 배상하여야 합니다.<br/>
            ③ 회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여, 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책 시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.<br/>
            ④ 회사는 다음 각 호 중 하나의 사유를 입증하는 경우에는 손해배상 책임이 면제될 수 있습니다.<br/>
            1. 외부에서 발생한 침해사고로 인하여 회사에 심각한 장애가 발생한 경우<br/>
            2. 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태 등 불가항력으로 인한 경우<br/>
            3. 회원의 고의나 과실로 인해 스마트 기기가 파손되어 서비스가 불가한 경우<br/>
            4. 서비스 불가 지역으로의 전출 등 회원의 사유로 서비스 제공이 불가한 경우<br/>
            5. 제16조 각호의 사유 중 어느 하나에 해당하는 경우<br/>
            6. 기타 회사의 귀책사유 없는 경우<br/>
            ⑤ 회사는 회원이 서비스를 이용하여 기대하는 이익의 상실에 관하여 보상하지 아니하며, 서비스를 통해 얻은 정보 또는 자료 등으로 인해 발생한 손해(금전적/정신적 피해 포함), 회원의 비정상적인 사용 및 회원의 건강상태, 체질적 특성 등을 고려하지 아니한 무리한 사용 등 회원의 부주의로 인한 손해에 대해서 책임을 지지 않습니다.`
        }, {
            id: 18, title: '제18조(사이트의 연결)', contents: `① 회사는 서비스 운영과 관련하여 애플리케이션 내에서 회사 및 회사가 아닌 제3자가 운영하는 타 사이트에 하이퍼링크(하이퍼링크의 대상에는 문자, 그림 및 동영상 등이 포함됨)방식 등을 통한 연결을 제공할 수 있습니다.<br/>
            ② 회사는 하이퍼링크 등으로 연결된 사이트가 독자적으로 제공하는 재화 또는 용역에 대하여 회원과 제3자 간에 행하는 거래에 대해서 책임을 지지 않습니다.`
        }, {
            id: 19, title: '제19조(기타)', contents: `① 회사는 서비스에 대한 회원의 이의제기나 불만이 발생하였을 경우에 회원이 제기하는 정당한 의견이나 불만에 대해 적절하고 신속한 조치를 취합니다. 다만, 신속한 처리가 곤란한 경우에 회사는 회원에게 그 사유와 처리일정을 통보합니다.<br/>
            ② 회사는 개인정보 보호 및 처리에 관해서는 <a className='font-bold' href='http://heringsglobal.com'>(http://heringsglobal.com)</a>에 기재된 개인정보처리방침에 따릅니다.<br/>    
            ③ 회원은 애플리케이션에 이용된 컨텐츠와 관련하여 회사 또는 제3자의 지식재산권을 침해하여서는 아니 되며 회사의 사전 서면 승낙 없이 애플리케이션에 이용된 컨텐츠와 관련한 사항을 복제, 전송, 출판, 배포하거나 기타 방법에 의하여 영리목적으로 이용하여서는 아니됩니다.<br/>            
            ④ 회원이 애플리케이션에 이용된 컨텐츠와 관련하여 타인의 특허권, 저작권, 상표권 등 지식재산권을 침해하였음을 이유로 회사가 타인으로부터 손해배상청구, 형사고소 등을 받은 경우 회원은 회사를 면책하여야 하며 회사가 면책되지 못한 경우 회원은 그로 인해 회사에 발생한 손해를 배상하여야 합니다.<br/>
            ⑤ 회사가 서비스를 통하여 회원에게 식단 등을 제공하는 경우, 회원은 유사식품에서 알레르기 유발 시 개별적인 제한이 필요하며, 회원은 알레르기 식품으로 만든 2차 식품도 주의해야 합니다(유제품, 계란 등). 또한 가공식품 데이터는 최신 정보가 아니거나 오류가 발생할 수 있으므로, 회원은 구매나 섭취 전 제품의 영양성분을 한 번 더 확인하여야 합니다. 이와 관련하여 회원에게 발생하는 일체의 생명, 신체, 재산상의 손해에 대하여, 회사는 일절 책임을 부담하지 않고, 회원은 회사를 면책하여야 합니다. `
        }, {
            id: 20, title: '제20조(관할법원)', contents: `① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다.<br/>
            ② 본 조 제1항의 협의에 의해서도 분쟁이 해결되지 않아 소송이 제기되는 경우에는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.`
        },]

    return (
        <div className='text-gray2 keepAll'>
            <h1 className='text-body3_700 text-gray_160 mb-4'>힐리어리 이용약관</h1>
            <ul>
                <h2 className='text-caption2_700 text-gray_160 mb-2'>제1장 총칙</h2>
                {datas.slice(0, 3).map(data =>
                    <li key={data.id} className='mb-2'>
                        <h2 className='text-caption2_700 text-gray_160'>{data.title}</h2>
                        <p className='text-caption2_400 text-gray_120'>{Parser(data.contents)}</p>
                    </li>)}
            </ul>
            <ul>
                <h2 className='text-caption2_700 text-gray_160 mb-2'>제2장 서비스 이용계약 및 정보보호</h2>
                {datas.slice(3, 6).map(data =>
                    <li key={data.id} className='mb-2'>
                        <h2 className='text-caption2_700 text-gray_160'>{data.title}</h2>
                        <p className='text-caption2_400 text-gray_120'>{Parser(data.contents)}</p>
                    </li>)}
            </ul>
            <ul>
                <h2 className='text-caption2_700 text-gray_160 mb-2'>제3장 서비스의 이용</h2>
                {datas.slice(6, 14).map(data =>
                    <li key={data.id} className='mb-2'>
                        <h2 className='text-caption2_700 text-gray_160'>{data.title}</h2>
                        <p className='text-caption2_400 text-gray_120'>{Parser(data.contents)}</p>
                    </li>)}
            </ul>
            <ul>
                <h2 className='text-caption2_700 text-gray_160 mb-2'>제4장 회원관리 및 보호</h2>
                {datas.slice(14, 15).map(data =>
                    <li key={data.id} className='mb-2'>
                        <h2 className='text-caption2_700 text-gray_160'>{data.title}</h2>
                        <p className='text-caption2_400 text-gray_120'>{Parser(data.contents)}</p>
                    </li>)}
            </ul>
            <ul>
                <h2 className='text-caption2_700 text-gray_160 mb-2'>제5장 기타</h2>
                {datas.slice(15, 20).map(data =>
                    <li key={data.id} className='mb-2'>
                        <h2 className='text-caption2_700 text-gray_160'>{data.title}</h2>
                        <p className='text-caption_2 text-gray_120'>{Parser(data.contents)}</p>
                    </li>)}
            </ul>
        </div>
    );
};

export default TermsOfService;