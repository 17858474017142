import React from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import { useSelector } from "react-redux";

const headerCommunity = "/images/admin/icon/header_community.svg";

const BoardId = () => {
  const {loginInfo} = useSelector((state)=>state.adminLogin)
  return (
    <>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex flex-col justify-start items-start p-[24px] gap-[16px] w-full min-w-min h-full">
          {/* 타이틀 */}
          <div className="flex flex-row justify-center items-center p-0 gap-[4px] h-[28px]">
            <img
              src={headerCommunity}
              alt="community"
              className="w-[28px] h-[28px]"
            />
            <p className=" h-[26px] not-italic text-xl font-bold tracking-normal text-[#463F38] ">
              Here is Board-Id Room
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardId;
