import React from "react";
import { useNavigate } from "react-router-dom";
import Btn from "../../components/common/Btn";
import HeadCont from "../../components/common/HeadCont";
import IdCard from "../../components/result/IdCard";

const Register = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeadCont rightIcon={"no icon"} />
      <section className="mb-6 mt-14 space-y-6 p-6">
        <h2 className="text-subtitle3 text-gray_180">회원가입을 축하합니다!</h2>
        <p className="text-body2_700l text-gray_160">
          건강관리 등록증이 발급되었어요!
          <br />
          나의 건강관리 설문을 통해 등록증 작성을
          <br />
          마무리 할 수 있습니다.
        </p>
        <IdCard />
        <Btn
          id={"signUpDone"}
          bgColor={"bg-main"}
          textColor={"text-white"}
          borderColor={"border border-main"}
          title={"완료"}
          active={"on"}
          onAction={() => navigate(`/home`)}
        />
      </section>
    </>
  );
};

export default Register;
