import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import TableHeader from "../common/TableHeader";
import PaginationLayout from "../common/pagination/PaginationLayout";
import { handlePageChange } from "../../../../features/admin/commonSlice";

const WeightDetailBox = ({
  onClickChange,
  dayNo,
  tabName,
}) => {
  const dispatch = useDispatch();
  const { weightLists, totalPage } = useSelector((state) => state.management);
  const headList = ["No.", "작성일", "체중 기록"];
  const {pagePerList7} = useSelector((state)=>state.pagination)
  const handlePageFn = (clickPage) => {
    dispatch(handlePageChange(clickPage));
    onClickChange(clickPage, dayNo, tabName);
  };
  const weightRecordGrid = (posts, idx, list) => {
    const tdCss =
      "border-b border-gray_60 text-center py-4 text-caption_1 text-black ";
    return (
      <>
        <td
          className={
            tdCss +
            " border-l " +
            (posts?.length === 1 + idx ? "rounded-bl-lg" : "")
          }
        >
          {list.no}
        </td>
        <td className={tdCss}>
          {list.day || "-"} ({dayjs(list.day).format("dd")})
        </td>
        <td
          className={
            tdCss +
            " border-r " +
            (posts?.length === 1 + idx ? "rounded-br-lg" : "")
          }
        >
          {list.data || "-"}
        </td>
      </>
    );
  };

  const repeatTr = (lists) => {
    return (
      <>
        {lists?.map((list, idx) => (
          <tr
            key={idx}
            className={
              "px-6 py-3 hover:bg-primary_10 " +
              (idx % 2 === 1 ? " bg-gray_95 " : " bg-gray_10 ")
            }
          >
            {weightRecordGrid(weightLists, idx, list)}
          </tr>
        ))}
      </>
    );
  };

  const repeatTable = () => {
    return (
      <table className="w-full border-separate table-auto" cellSpacing="0">
        <thead>
          <tr className="bg-gray_90">
            {headList?.map((head) => (
              <th
                key={head}
                className={
                  "first:border-l last:border-r border-y border-gray_60 py-4 text-caption_1 bg-gray_60 font-semibold text-black first:rounded-tl-lg last:rounded-tr-lg " +
                  (weightLists?.length === 0
                    ? "first:rounded-bl-lg last:rounded-br-lg"
                    : "")+
                  (head === "No."?" w-[80px] px-4 ":"")
                }
              >
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{repeatTr(weightLists)}</tbody>
      </table>
    );
  };

  return (
    <div className="grid grid-cols-2 gap-4 mt-4">
      <div className="space-y-4">
        <TableHeader title={"체중 기록"} />
        {totalPage !== 0 ? (
          <PaginationLayout
            totalPage={totalPage}
            pagePerList={pagePerList7}
            onClick={(clickpage) => handlePageFn(clickpage)}
          >
            {repeatTable()}
          </PaginationLayout>
        ) : (
          <div>체중 기록이 없습니다.</div>
        )}
      </div>
    </div>
  );
};

export default WeightDetailBox;
