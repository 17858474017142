import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { onSetSection } from "../../../../features/admin/commonSlice";
import { useDispatch } from "react-redux";

const MainRank = ({ surveyRank }) => {
  const [toggleEvt, setToggleEvt] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    setToggleEvt(!toggleEvt); // 현재 상태의 반대값으로 설정
  };

  const allRankCount = () => {
    if (surveyRank) {
      let arrCount = surveyRank.map((arr) => Number(arr.count));
      let sumCount = arrCount.reduce((a, b) => a + b, 0); // 차례로 더해나감
      return sumCount;
    }
  };
  return (
    <div className="grid overflow-hidden text-center text-black border shadow-md rounded-3xl bg-gray_10">
      <table className="table-auto" onClick={handleClick}>
        <thead className=" bg-gray_60 text-body1_600">
          <tr>
            <th className="px-4 py-2 border-b border-r">순위</th>
            <th className="px-4 py-2 border-b">
              <div className="grid grid-cols-2">
                <div>
                  <span>유입 경로</span>
                  <p className="text-xs text-gray_140" onClick={handleClick}>
                    순위 전체보기 클릭
                  </p>
                </div>
                <div className="m-auto">
                  <span className="text-center text-caption1_700">
                    총 : {allRankCount()} 회
                  </span>
                  <div
                    className="flex items-center justify-center mx-auto cursor-pointer"
                    onClick={() => {
                      dispatch(onSetSection("유입경로"));
                      navigate("/admin/funnels");
                    }}
                  >
                    <span className="ml-2 text-xs text-black text-body2_400">
                      더보기
                    </span>
                    <img
                      src="/images/admin/icon/arrow_next.svg"
                      alt="더보기 아이콘"
                      className=""
                    />
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {surveyRank?.map(
            (data, index) =>
              (toggleEvt || index <= 2) && (
                <tr key={index} className="text-caption1_500">
                  <td className="px-4 py-2 border-b border-r">{data.rank}</td>
                  <td className="px-4 py-2 border-b">
                    {data.answer} ({data.count}회)
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};
export default MainRank;
