import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const fetchWeeklyDiary = createAsyncThunk(
  "diary/searchWeeklyDiary",
  async (params) => {
    const res = await api.post(`/healthDiary/searchOneWeek`, params);
    return res.data;
  }
);

export const fetchExistDiaryDatasOfYear = createAsyncThunk(
  "diary/fetchExistDiaryDatasOfYear", //다이어리 기록있는 1년치
  async (params) => {
    const res = await api.post(`/user/getExistDiaryDataDayOfYear`, params);
    if (res.data.ok) {
      return res.data.dayList;
    }
  }
);

export const fetchExistWeightDataOfYear = createAsyncThunk(
  "diary/fetchExistWeightDataOfYear",
  async (params) => {
    const res = await api.post(`/healthDiary/getWeightPoints`, params);
    if (res.data.ok) {
      console.log(res);
      return res.data.list;
    }
  }
);

export const fetchExistSymptomDataOfYear = createAsyncThunk(
  "diary/fetchExistSymptomDataOfYear",
  async (params) => {
    const res = await api.post(`/healthDiary/getSymptomPoints`, params);
    if (res.data.ok) {
      return res.data.list;
    }
  }
);

export const saveDiary = createAsyncThunk("diary/saveDiary", async (params) => {
  const res = await api.post(`/healthDiary/addOrEdit`, params);
  return res.data;
});

export const fetchSymptomLists = createAsyncThunk(
  "diary/fetchSymptomLists", //증상 가져오기
  async () => {
    const res = await api.get(`/healthDiary/getSymptomList`);
    return res.data.symptomList;
  }
);

const initialState = {
  weeklyDiaryStatus: null,
  weeklyDiaryLists: [],
  existDaysOfYear: [],
  existWeightOfYear: [],
  existSymptomOfYear: [],
  symptomLists: [],
};

const diarySlice = createSlice({
  name: "다이어리",
  initialState,
  // reducers: {
  // },

  extraReducers: (builder) => {
    builder.addCase(fetchWeeklyDiary.pending, (state) => {
      state.recommendMealListsStatus = "pending";
    });
    builder.addCase(fetchWeeklyDiary.fulfilled, (state, action) => {
      state.weeklyDiaryStatus = "success";
      state.weeklyDiaryLists = action.payload.healthDiaryList;
    });
    builder.addCase(fetchWeeklyDiary.rejected, (state) => {
      state.recommendMealListsStatus = "rejected";
    });
    builder.addCase(fetchSymptomLists.fulfilled, (state, action) => {
      state.symptomLists = action.payload;
    });
    builder.addCase(fetchExistDiaryDatasOfYear.fulfilled, (state, action) => {
      state.existDaysOfYear = [...action.payload];
    });
    builder.addCase(fetchExistWeightDataOfYear.fulfilled, (state, action) => {
      state.existWeightOfYear = action.payload;
    });
    builder.addCase(fetchExistSymptomDataOfYear.fulfilled, (state, action) => {
      state.existSymptomOfYear = action.payload;
    });
  },

  // {
  //     [fetchWeeklyDiary.pending]: (state) => { state.recommendMealListsStatus = "pending" },
  //     [fetchWeeklyDiary.fulfilled]: (state, action) => {
  //         state.weeklyDiaryStatus = "success";
  //         state.weeklyDiaryLists = action.payload.healthDiaryList;
  //     },
  //     [fetchWeeklyDiary.rejected]: (state) => { state.recommendMealListsStatus = "rejected" },
  //     [fetchSymptomLists.fulfilled]: (state, action) => {
  //         state.symptomLists = action.payload;
  //     },
  //     [fetchExistDiaryDatasOfYear.fulfilled]: (state, action) => { state.existDaysOfYear = [...state.existDaysOfYear, ...action.payload] },
  // },
});

//export const {  } = diarySlice.actions

export default diarySlice.reducer;
