import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../common/api";

export const fetchAdminUserInfo = createAsyncThunk(
  "common/fetchUserInfo",
  async (params) => {
    const res = await api.get(`/user/searchForUserId/${params}`);
    if (res.data.ok) {
      return res.data.user;
    }
  }
);

const initialState = {
  section: "홈",
  subSection: "소식",
  i: 0,
  status: null,
  isLoggedIn: false,
  page: 1,
  userInfo: [],
  allUsers: [],
};

const commonSlice = createSlice({
  name: "섹션",
  initialState,
  reducers: {
    onSetSection: (state, action) => {
      state.section = action.payload;
    },
    onSetSubSection: (state, action) => {
      state.subSection = action.payload;
    },
    onLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    handlePageChange: (state, action) => {
      state.page = action.payload;
    },
  },

  extraReducers: {
    [fetchAdminUserInfo.fulfilled]: (state, action) => {
      state.status = "success";
      state.userInfo = action.payload;
    },
    // [fetchAllUsers.fulfilled]: (state, action) => {
    //   state.status = "success";
    //   state.allUsers = action.payload;
    // },
  },
});

export const { onSetSection, onSetSubSection, onLoggedIn, handlePageChange } =
  commonSlice.actions;

export default commonSlice.reducer;
