import React from "react";

const GPTMessage = ({ loginInfo, onClick, page, nav }) => {
  return (
    <>
      <div
        className={
          `flex ` +
          (page
            ? "mt-14 items-center justify-center gap-1 p-5"
            : ` w-full flex-row items-start justify-center`)
        }
        onClick={onClick}
      >
        <div className="flex w-full gap-3">
          <img
            src="/images/mobile/character/doctor_to_right.svg"
            alt="CancerGPT 챗봇"
            className="w-14"
          />

          <div className="flex flex-row items-start flex-grow">
            <div className="gap-1 py-4">
              <img
                src={"/images/mobile/icon/chat_wing_left.svg"}
                alt="Chat Left Wing"
              />
            </div>
            <div className="flex flex-row items-center justify-center flex-grow gap-1 px-4 py-2 break-all rounded-2xl bg-main">
              {loginInfo ? (
                <div className="text-center ">
                  <div className="items-center justify-between text-white text-body3_600">
                    {loginInfo.nickName}님,
                    <br />
                    무엇이든 물어보세요!
                  </div>
                </div>
              ) : nav === "cancergpt" ? (
                <div className="mx-auto my-2 text-center ">
                  <div className="text-white text-caption1_600 ">
                    암과 관련되어
                    <br /> 궁금하신 점을 물어보세요!
                  </div>
                </div>
              ) : (
                <div className="mx-auto my-4 text-center ">
                  <div className="text-white text-body3_600 ">
                    회원가입 하시고
                  </div>
                  <div className="text-white text-body3_600 ">
                    식사 분석 받아 보세요!
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GPTMessage;
