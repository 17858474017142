import React, { useState } from "react";

const SearchHospital = ({ setInputHospital }) => {
  return (
    <div className="flex items-center justify-center gap-2 border-b border-t border-gray_60 bg-white p-4">
      <div className=" flex h-[53px] w-full shrink-0 items-center justify-between rounded border bg-white p-[10px] text-caption2_700">
        <input
          // value={inputHospital}
          id="hospitalSearch"
          type="text"
          placeholder="병원 이름을 검색해 주세요."
          onChange={(e) => setInputHospital(e.target.value)}
        />
        <img
          src="/images/admin/icon/search.svg"
          alt="검색이미지"
          // className="-gray_100"
        />
      </div>
    </div>
  );
};

export default SearchHospital;
