import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navActive: false,
  modalActive:false,
  commuHide:"",
  footerM:""
};

const toggleSlice = createSlice({
  name: "토글",
  initialState: initialState,
  reducers: {
    toggleNavigation: (state) => {
      state.navActive = !state.navActive;
    },
    toggleBoardModal:(state,action)=>{
      state.modalActive = action.payload
    },
    toggleCommuHide:(state,action)=>{
      state.commuHide = action.payload
    },
    toggleFooter:(state,action)=>{
      state.footerM = action.payload
    }
  }
});
export const { toggleNavigation,toggleBoardModal,toggleCommuHide, toggleFooter } = toggleSlice.actions;

export default toggleSlice.reducer;
