import React from "react";

const MealCard = ({
  type,
  option,
  riceLits,
  proteinLists,
  image,
  name,
  bgColor,
  day,
  onAction,
  onDeleteAction,
  list,
}) => {
  return (
    <>
      {option === "meal" ? (
        <li
          onClick={onAction}
          className={"space-y-4 rounded-xl p-5 " + bgColor}
        >
          <div className="flex justify-between">
            <h2 className="text-caption2_700 text-gray_160">{day}</h2>
            <img
              onClick={onDeleteAction}
              src="/images/mobile/icon/delete.svg"
              alt="삭제 아이콘"
            />
          </div>
          <ul className="space-y-1">
            <li className="flex items-center gap-3">
              <span className="flex-shrink-0">🍚</span>
              <ul>
                {riceLits &&
                  riceLits.map((list, idx) => (
                    <li
                      key={idx}
                      className="inline-block text-caption1_700 text-gray_180"
                    >
                      {idx === riceLits.length - 1 ? list : list + ","}&nbsp;
                    </li>
                  ))}
              </ul>
            </li>
            <li className="flex items-center gap-3">
              <span className="flex-shrink-0">🍖</span>
              <ul>
                {proteinLists &&
                  proteinLists.map((list, idx) => (
                    <li
                      key={idx}
                      className="inline-block text-caption1_700 text-gray_180"
                    >
                      {idx === proteinLists.length - 1 ? list : list + ","}
                      &nbsp;
                    </li>
                  ))}
              </ul>
            </li>
          </ul>
        </li>
      ) : (
        <li
          onClick={onAction}
          className={"space-y-4 rounded-xl p-5 " + bgColor}
        >
          <div className="flex justify-between">
            <h2 className="text-caption2_700 text-gray_160">
              {type.includes("밥")
                ? "🍚 "
                : type.includes("국")
                ? "🍲"
                : type.includes("단백질")
                ? "🍖"
                : type.includes("채소")
                ? "🥗"
                : type.includes("김치")
                ? "🌶️"
                : "🍎"}{" "}
              {type}류
            </h2>
            <img
              onClick={onDeleteAction}
              src="/images/mobile/icon/delete.svg"
              alt="삭제 아이콘"
            />
          </div>
          <div className="flex gap-4">
            <div
              className="bgSize h-[64px] w-[72px] flex-shrink-0 rounded-lg"
              style={{
                background:
                  "url(" +
                  `${image ? image : "/images/mobile/image/준비중.svg"}` +
                  ") no-repeat center",
              }}
            ></div>
            <h3 className="text-black ellipsis text-body2_700l">{name}</h3>
          </div>
        </li>
      )}
    </>
  );
};

export default MealCard;
