import React, { useEffect, useRef, useState } from "react";
import CommentCard from "./CommentCard";
import { useDispatch } from "react-redux";
import {
  addReply,
  addReReply,
  deleteReply,
  blockUser,
  addReporting,
  onSetPrevReplyList,
} from "../../../../../features/mobile/commonSlice";
import Btn from "../Btn";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../../common/toast";
import { useNavigate } from "react-router-dom";
import HeadCont from "../HeadCont";
import { focusAndOpenKeyboard } from "../../logic";
import { onResetCommunityPage } from "../../../../../features/mobile/commSlice";

const CommentCardHolder = ({
  loginInfo,
  commonParams,
  onResetAll,
  community,
  myPage,
}) => {
  // Common Params
  const {
    postId,
    postComment,
    setPostComment,
    status,
    setStatus,
    totalReplies,
    prevReplyList,
    bringPostingDetails,
  } = commonParams;
  // Local State Variables
  const [replyId, setReplyId] = useState("");
  const [reReply, setReReply] = useState(null);
  const [optionOpen, setOptionOpen] = useState({ open: false, id: null });
  // other variables
  const textArea = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickThreeDots = (id) => {
    setOptionOpen({ open: true, id: id });
  };

  // When an input is registered to the textBox
  const onChangeAction = (e) => {
    setPostComment(e.target.value);
    if (e.target.scrollHeight > 66 * 3) return;
    e.target.style.height = "66px";
    let textAreaH = e.target.scrollHeight;
    e.target.style.height = `${textAreaH}px`;
    window.scrollBy(0, 66);
  };

  // Open the 'addReReply' window and initialize the reReply state with reply content
  const onReReply = (reply) => {
    setOptionOpen({ open: false, id: null });
    setStatus("addReReply");
    setReReply(reply);
  };

  const onAddComment = () => {
    let params = {
      writerUserId: loginInfo.userId,
      belongToPostingId: postId,
      content: postComment,
    }; //댓글 등록
    let params2 = {
      writerUserId: loginInfo.userId,
      id: replyId,
      content: postComment,
    }; //댓글 수정
    let params3 = {
      writerUserId: loginInfo.userId,
      belongToPostingId: postId,
      belongToReplyId: reReply?.id,
      content: postComment,
    }; //댑댓글 등록
    if (status === "add" || status === "edit") {
      dispatch(
        addReply(status === "add" ? params : status === "edit" ? params2 : null)
      )
        .unwrap()
        .then((res) => {
          setPostComment(null);
          if (res.ok) {
            if (status === "add") {
              // When adding, update the replyCount in the prevLikedList from the main page
              bringPostingDetails(postId);
              setStatus("read");
              // setTimeout(() => setStatus('read'), 300);
            }
            // If coming from myPage, update the prevReplyList
            if (status === "edit") {
              if (prevReplyList) {
                dispatch(
                  onSetPrevReplyList(
                    prevReplyList?.map((item) =>
                      item.id === replyId
                        ? { ...item, content: postComment }
                        : item
                    )
                  )
                );
              }

              bringPostingDetails(postId);
              setStatus("read");
              // setTimeout(() => setStatus('read'), 300);
            }
            dispatch(onResetCommunityPage());
            toast(
              <p>댓글이 작성되었습니다!</p>,
              toastCommonProps("bottom-center", "toast_alert")
            );
          }
        })
        .catch((err) => console.log(err));
    } else if (status === "addReReply") {
      dispatch(addReReply(params3))
        .unwrap()
        .then((res) => {
          setPostComment(null);
          if (res.ok) {
            // When adding, update the replyCount in the prevLikedList from the main page
            bringPostingDetails(postId);
            setReReply(null);
            setStatus("read");
            dispatch(onResetCommunityPage());
            toast(
              <p>댓글이 작성되었습니다!</p>,
              toastCommonProps("bottom-center", "toast_alert")
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onEditReply = (content, reply) => {
    setStatus("edit");
    setReplyId(reply);
    setPostComment(content);
  };
  const onAddReporting = (postId, replyId, reason) => {
    let params = {
      reportUserId: loginInfo.userId,
      reportingReason: reason,
      reportedPostingId: postId,
    }; // for post
    let params2 = {
      reportUserId: loginInfo.userId,
      reportingReason: reason,
      reportedReplyId: replyId,
    }; // for reply
    if (replyId === null) {
      //
      dispatch(addReporting(params))
        .unwrap()
        .then((res) => {
          if (res.ok) bringPostingDetails(postId);
        });
    } else {
      // dispatch(onSetPrevLikedList(prevLikedList.map(value => (value.id === parseInt(postId) ? ({...value, replyCount: value.replyCount-subtractAmount}) : value))))
      dispatch(addReporting(params2))
        .unwrap()
        .then((res) => {
          if (res.ok) bringPostingDetails(postId);
        });
    }
  };
  const onBlockUser = (blockId, innerStatus) => {
    let params = { userId: loginInfo.userId, blockUsersId: blockId };
    dispatch(blockUser(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          if (innerStatus === "reportPost") {
            dispatch(onResetAll());
            navigate(-1);
          }
          if (innerStatus === "reportReply") {
            bringPostingDetails(postId);
            if (community) dispatch(onResetAll());
          }
        }
      });
  };

  const onDeleteReply = (replyId) => {
    let params = { id: replyId };
    dispatch(deleteReply(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          // If coming from myPage, update the prevReplyList
          if (prevReplyList)
            dispatch(
              onSetPrevReplyList(
                prevReplyList?.filter((item) => item.id !== replyId)
              )
            );
          bringPostingDetails(postId);
          dispatch(onResetCommunityPage());
          toast(
            <p>댓글 삭제완료</p>,
            toastCommonProps("bottom-center", "toast_alert")
          );
        }
      });
  };

  useEffect(() => {
    // let focused = true;
    if (textArea) {
      // console.log(textArea);
      focusAndOpenKeyboard(textArea.current);

      setTimeout(
        // () => textArea?.current?.scrollIntoView({ behavior: "smooth" }),
        () => {
          if (postComment) {
            textArea.current.value = "";
            textArea.current.value = postComment;
          }
        },
        50
      );
    }
    // return () => (focused = false);
  }, [textArea, status]);

  return (
    <>
      {status === "read" ? (
        <>
          <ul>
            {totalReplies?.map((reply, idx) => (
              <li key={idx}>
                {reply?.parentReply && (
                  <CommentCard
                    postId={postId}
                    reply={reply?.parentReply}
                    onDeleteReply={onDeleteReply}
                    onEditReply={onEditReply}
                    onBlockUser={onBlockUser}
                    onAddReporting={onAddReporting}
                    onReReply={onReReply}
                    setStatus={setStatus}
                    nickName={reply?.parentReply?.nickName}
                    childReply={reply?.rereplies}
                    totalLength={totalReplies?.length}
                    setOptionOpen={setOptionOpen}
                    optionOpen={optionOpen}
                    onClickThreeDots={onClickThreeDots}
                  />
                )}
                {reply?.rereplies?.length > 0
                  ? reply?.rereplies.map((rereply) => (
                      <div key={rereply.id}>
                        <CommentCard
                          postId={postId}
                          reply={rereply}
                          onDeleteReply={onDeleteReply}
                          onEditReply={onEditReply}
                          onBlockUser={onBlockUser}
                          onAddReporting={onAddReporting}
                          onReReply={onReReply}
                          setStatus={setStatus}
                          nickName={rereply?.nickName}
                          isChild={true}
                          setOptionOpen={setOptionOpen}
                          optionOpen={optionOpen}
                          onClickThreeDots={onClickThreeDots}
                        />
                      </div>
                    ))
                  : null}
              </li>
            ))}
          </ul>
          <div className="h-28" />

          {!community && (
            <div className="fixed bottom-0 w-full border-t border-gray_40 bg-[#FEFEFE] px-6 py-5">
              <input
                onClick={() => {
                  setStatus("add");
                }}
                id={"question"}
                name={"question"}
                placeholder={"댓글을 작성해주세요."}
                value=""
                readOnly={true}
                className="no-scrollbar h-[64px] w-full resize-none appearance-none rounded border border-gray_80 p-4 text-body2_500l text-black focus:border-gray_160 focus:outline-none focus:ring-0"
              />
            </div>
          )}
        </>
      ) : (
        <>
          {myPage && (
            <HeadCont
              transparent={"white"}
              onLeftAction={() => setStatus("read")}
            />
          )}
          {status === "addReReply" && (
            <CommentCard
              postId={postId}
              reply={reReply}
              onDeleteReply={onDeleteReply}
              onEditReply={onEditReply}
              onBlockUser={onBlockUser}
              onAddReporting={onAddReporting}
              onReReply={onReReply}
              setStatus={setStatus}
              nickName={reReply?.nickName}
              setOptionOpen={setOptionOpen}
              optionOpen={optionOpen}
              onClickThreeDots={onClickThreeDots}
            />
          )}
          <div className={`${myPage ? "mt-14" : "mt-4"} mx-6`}>
            <textarea
              onChange={(e) => onChangeAction(e)}
              // onClick={() =>
              //   setTimeout(
              //     () =>
              //       textArea?.current?.scrollIntoView({ behavior: "smooth" }),
              //     400
              //   )
              // }
              ref={textArea}
              id={"question"}
              name={"question"}
              value={postComment || ""}
              maxLength={"600"}
              placeholder={"댓글을 작성해보세요."}
              className="no-scrollbar block h-[66px] w-full resize-none appearance-none rounded border border-gray_80 p-4 text-body2_500l text-black focus:border-gray_160 focus:outline-none focus:ring-0"
              style={{ whiteSpace: "pre-wrap" }}
              autoFocus={"autofocus"}
            />
            <span className="float-right text-caption1_400 text-gray_160">
              {postComment?.length || "0"}/600
            </span>
          </div>
          <div className="h-28" />
          {/* <div className="mt-4"> */}
          <Btn
            option={"bottom"}
            bgColor={"bg-main"}
            textColor={"text-white font-semibold"}
            borderColor={"border border-main"}
            title={"작성"}
            active={postComment?.length > 0 ? "on" : "ready"}
            onAction={() => onAddComment()}
          />
          {/* </div> */}
        </>
      )}
    </>
  );
};

export default CommentCardHolder;
