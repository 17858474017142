import React from "react";
import { PieChart, Pie, ResponsiveContainer, Tooltip } from "recharts";

const KcalGraph = ({ isLoading, datas }) => {
  const { bkcal, bskcal, lkcal, lskcal, dkcal, dskcal } = datas;
  const percentDatas = [
    {
      name: "아침",
      value: bkcal + bskcal,
      fill: "#FE718E",
    },
    {
      name: "점심",
      value: lkcal + lskcal,
      fill: "#5CCFFE",
    },
    {
      name: "저녁",
      value: dkcal + dskcal,
      fill: "#8744E1",
    },
    {
      name: "",
      value: bkcal + bskcal + lkcal + lskcal + dkcal + dskcal === 0 ? 100 : 0,
      fill: "#DFDFDF",
    },
  ];

  return (
    <div className="flex w-full items-center gap-[10px]">
      <div className="flex h-[116px] w-[116px] ">
        {isLoading ? (
          <ResponsiveContainer width={116} height={116}>
            <PieChart>
              <Pie />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width={116} height={116}>
            <PieChart>
              <Pie
                data={percentDatas}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={25}
                outerRadius={55}
                style={{
                  outline: "none",
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
      <div className="mr-3 flex w-full gap-7 p-[10px] text-center">
        <div className="flex w-full flex-col gap-2">
          <div className="flex items-center gap-3">
            <span className="h-[11px] w-[11px] rounded-full bg-[#FE718E]"></span>
            <div className="flex w-full text-sm font-semibold tracking-tight text-neutral-500">
              <p className="w-1/2 text-start">
                아침
                <span className="font-bold"> {Math.floor(bkcal)}</span>
              </p>
              <p className="w-1/2 text-start">
                간식 <span className="font-bold"> {Math.floor(bskcal)}</span>
              </p>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <span className="h-[11px] w-[11px] rounded-full bg-[#5CCFFE]"></span>
            <div className="flex w-full text-sm font-semibold tracking-tight text-neutral-500">
              <p className="w-1/2 text-start">
                점심 <span className="font-bold"> {Math.floor(lkcal)}</span>
              </p>
              <p className="w-1/2 text-start">
                간식 <span className="font-bold"> {Math.floor(lskcal)}</span>
              </p>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <span className="h-[11px] w-[11px] rounded-full bg-[#8744E1]"></span>
            <div className="flex w-full text-sm font-semibold tracking-tight text-neutral-500">
              <p className="w-1/2 text-start">
                저녁 <span className="font-bold"> {Math.floor(dkcal)}</span>
              </p>
              <p className="w-1/2 text-start">
                간식 <span className="font-bold"> {Math.floor(dskcal)}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KcalGraph;
