import {  labeltext, listheight } from '../../data/TailwindCommon';

export const LabelAdminInput = ({title, name, type, inputVal, onChangeAction, inputcss, onKeyPressAction,placeholderVal,onetimeuse, checkCss, checkVal}) => {
  return (
    <div className={listheight}>
    <label className={labeltext}>{title}</label>
    <div className={'relative'}>
      <input
        id={name}
        type={type}
        name={name}
        value={inputVal}
        onChange={onChangeAction}
        placeholder={placeholderVal}
        className={inputcss}
        onKeyPress={(e)=>{
          if (e.key === "Enter") onKeyPressAction();
        }}
        autoComplete={onetimeuse?"one-time-code":"none"}
      />
      <p className={checkCss}>{checkVal}</p>
    </div>
  </div>
  )
}
