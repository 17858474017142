import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../common/toast";
import {
  checkRegisteredId,
  sendMobileNo,
  checkMobileAutoNo,
  checkNickname,
} from "../../../../features/mobile/otherSlice";
import { getNickname } from "../../../../features/mobile/questionHealthSlice";
import {
  onAddUser,
  saveLoginInfo,
} from "../../../../features/mobile/commonSlice";
import Parser from "html-react-parser";
import { regMobile } from "../../components/logic";
import HeadCont from "../../components/common/HeadCont";
import Btn from "../../components/common/Btn";
import InputBox from "../../components/common/InputBox";
import TitleBox from "../../components/common/TitleBox";
import CertNumInput from "./CertNumInput";
import PassInput from "../../components/common/PassInput";
import CharacterInput from "../../components/sign/CharacterInput";
import SignUpSurvey from "../../components/survey/SignUpSurvey";

const MobileSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dummyNickname } = useSelector((state) => state.questionHealth);
  const [value, setValue] = useState("");
  const [signupDatas, setSignupDatas] = useState({
    userId: "",
    pass: "",
    nickName: "",
    agree: [],
    characterPhoto: "",
    firebaseToken: window.sessionStorage.getItem("token") || null,
    gaKey: "",
  });
  const [step, setStep] = useState(1); // 1: 휴대폰 번호 입력, 2: 인증번호 입력, 3 새비밀번호 입력, 4 Survey, 5. 닉네임, 6 캐릭터 선택 7. 휴대폰 가입 오류시 처음으로 돌아가기 화면
  const [active, setActive] = useState("ready"); //ready, on
  const [errorMsg, setErrorMsg] = useState(null);
  const [responseSeq, setResponseSeq] = useState({});
  const [surveyResponse, setSurveyResponse] = useState(null);
  const onSurveyInput = (value) => {
    setSurveyResponse({
      selectedNum: value.selectedNum,
      selectedAnswer: value.selectedAnswer,
      etc: value.etc,
    });
  };

  const onChangeInput = (e) => {
    console.log("onchange");
    let len = e.target.value.length;
    if (step === 1 && len > (value && value?.length)) {
      //휴대폰 번호 입력
      setValue(e.target.value.replaceAll("-", ""));
      setSignupDatas({
        ...signupDatas,
        userId: e.target.value.replaceAll("-", ""),
      });
      if (len === 3 || len === 8) {
        setValue(e.target.value + " ");
        setSignupDatas({ ...signupDatas, userId: e.target.value + " " });
      }
    } else if (step === 2) {
      //인증번호 입력
      setValue(e.target.value);
      if (len === 5) setActive("on");
      else setActive("ready");
    } else if (step === 5) {
      //닉네임 입력
      setErrorMsg(null);
      setValue(e.target.value.slice(0, 8));

      setSignupDatas({ ...signupDatas, nickName: e.target.value.slice(0, 8) });
    } else setValue(e.target.value);
  };

  const onChangeKeyDown = (e) => {
    let len = e.target.value.length;
    if (e.keyCode === 8 && (len === 9 || len === 4)) {
      setSignupDatas({
        ...signupDatas,
        userId: e.target.value.slice(0, e.target.value.length - 1),
      });
      setValue(e.target.value.slice(0, e.target.value.length - 1));
    }
  };
  console.log(errorMsg);
  const onSetNextStep = (step) => {
    if (step === 1) {
      setSignupDatas({
        ...signupDatas,
        userId: signupDatas.userId.replaceAll(" ", "").replaceAll("-", ""),
      });
      let params = { mobileNo: signupDatas.userId.replaceAll(" ", "") };
      dispatch(checkRegisteredId(signupDatas.userId.replaceAll(" ", "")))
        .unwrap()
        .then((res) => {
          console.log("111", res);
          if (res.ok) {
            dispatch(sendMobileNo(params))
              .unwrap()
              .then((res) => {
                if (res.ok) {
                  setResponseSeq(res.responseSEQ);
                  setStep(step + 1);
                  setActive("ready");
                  setValue("");
                  setErrorMsg(null);
                } else {
                  setErrorMsg(res.error);
                  setValue("");
                }
              })
              .catch((err) => console.log(err));
          } else {
            console.log("122");
            setErrorMsg(res.error);
            setValue("");
            setActive("ready");
          }
        })
        .catch((err) => console.log(err));
    } else if (step === 2) {
      //핸드폰 가입시 문자 인증번호 확인
      let params = { authNo: value, responseSEQ: responseSeq };
      dispatch(checkMobileAutoNo(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            setResponseSeq(res.responseSEQ);
            setStep(step + 1);
            setActive("ready");
            setValue("");
            setErrorMsg(null);
          } else {
            setErrorMsg(res.error); //인증번호 에러 메시지
            setActive("ready");
            setValue("");
          }
        })
        .catch((err) => console.log(err));
    } else if (step === 3) {
      //비밀번호
      setStep(step + 1);
      dispatch(getNickname({ maxLength: 12 }));
    } else if (step === 4) {
      const gaKey =
        new Date().getTime() + Math.random().toString(36).substring(2, 11);
      setSignupDatas({
        ...signupDatas,
        surveyType: 1,
        selectNum: surveyResponse.selectedNum,
        selectAnswer: surveyResponse.selectedAnswer,
        etcAnswer: surveyResponse.etc,
        gaKey,
      });
      window.dataLayer.push({
        event: "signup_try",
        value: gaKey,
      });
      setStep(step + 1);
    } else if (step === 5) {
      //닉네임 입력 안했을씨 더미 닉네임 저장
      if (signupDatas.nickName === "") {
        setSignupDatas({ ...signupDatas, nickName: dummyNickname });
        setStep(step + 1);
      } else {
        dispatch(checkNickname(signupDatas.nickName)) //닉네임 중복 확인
          .unwrap()
          .then((res) => {
            console.log(res);
            if (res.ok) {
              setSignupDatas({
                ...signupDatas,
                nickName: signupDatas.nickName,
              });
              setStep(step + 1);
              setErrorMsg(null);
              setActive("on");
            } else {
              setErrorMsg(res.error);
              setActive("ready");
            }
          })
          .catch((err) => console.log(err));
      }
    } else if (step === 6) {
      //캐릭터 선택 후 회원가입
      dispatch(onAddUser(signupDatas))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            dispatch(saveLoginInfo({ ...res, existPass: true }));
            navigate(`/registered`); //회원가입 완료 화면
          } else {
            //회원가입 불가시 처음으로 돌아가기 버튼 -> 닉네임 에러면 닉네임 단계로 이동하고 아니면 설문단계로 이동하도록 수정
            toast(
              <p>{res.error}</p>,
              toastCommonProps("bottom-center", "toast_alert", 1000)
            );
            if (res.error.includes("닉네임")) {
              setStep(5);
              setActive("ready");
            } else {
              setStep(4);
              setActive("ready");
            }
          }
        })
        .catch((err) => console.log(err));
    } else if (step === 7) {
      navigate(`/sign`);
    } else {
      setStep(step + 1);
      setActive("ready");
      setValue("");
      setErrorMsg(null);
    }
  };
  const onSetPrevStep = () => {
    // if (step - 1 === 4) setStep(step - 2);
    setStep(step - 1);
  };
  // useEffect(() => {
  //   if (step === 4) setStep(step + 1);
  // }, [step]);

  const onSaveEach = (type, one) =>
    setSignupDatas({ ...signupDatas, [type]: one });

  const onSaveAgreement = (items) => {
    setSignupDatas({ ...signupDatas, agree: [...items] });
    onSetNextStep(step);
  };

  useEffect(() => {
    const surveyLength = 8;
    if (step === 1) {
      if (value?.length === 0) return;
      if (value?.length === 13 && regMobile(value.replaceAll(" ", ""))) {
        setErrorMsg(null);
        setActive("on");
      } else {
        setErrorMsg("잘못된 형식의 휴대폰번호 입니다");
        setActive("ready");
      }
    } else if (step === 2 && value) {
      setErrorMsg("");
    } else if (step === 4) {
      if (surveyResponse && surveyResponse?.selectedNum !== surveyLength - 1)
        setActive("on");
      else if (
        surveyResponse?.selectedNum === surveyLength - 1 &&
        surveyResponse?.etc !== ""
      ) {
        setActive("on");
      } else {
        setActive("ready");
      }
    } else if (step === 5) {
      const nickNameFilter = /[^a-zA-Z0-9\uAC00-\uD7A3]/g; // 닉네임 특수문자, 이모지 제외 정규식
      if (nickNameFilter.test(value)) {
        setActive("ready");
      } else {
        setActive("on");
      }
      // setActive("on");
      // 닉네임 중복에러 후 value 삭제하면 signupDatas에 저장된 에러닉네임 지워주기
      if (value === "") {
        setSignupDatas({ ...signupDatas, nickName: "" });
      }
    } else if (step === 6) {
      if (!signupDatas.characterPhoto) {
        setActive("ready");
      } else setActive("on");
    }
  }, [value, step, signupDatas.characterPhoto, surveyResponse]);

  return (
    <>
      <HeadCont
        rightIcon={"no icon"}
        onLeftAction={step !== 1 ? onSetPrevStep : () => navigate(-1)}
      />
      {step === 1 ? ( //1: 휴대폰 번호 입력
        <section className="mx-6 mt-[80px] flex flex-col gap-6">
          <TitleBox option={2} title={"휴대폰번호를 입력해주세요"} />
          <InputBox
            option="common"
            placeholder={"010 1111 2222"}
            comments={"‘-’를 제외하고 입력해주세요"}
            onChangeInput={onChangeInput}
            onChangeKeyDown={onChangeKeyDown}
            value={value}
            inputM={"tel"}
            setValue={setValue}
            active={active}
            errorMsg={errorMsg}
          />
        </section>
      ) : step === 2 ? ( //2: 인증번호 입력
        <section className="mx-6 mt-[80px] flex flex-col gap-6">
          <TitleBox option={2} title={"인증번호를 입력해주세요"} />
          <CertNumInput
            value={value}
            setValue={setValue}
            onChangeInput={onChangeInput}
            onSetPrevStep={onSetPrevStep}
            active={active}
            errorMsg={errorMsg}
          />
        </section>
      ) : step === 3 ? ( //3 새비밀번호 입력
        <section className="mx-6 mt-[80px] flex flex-col gap-6">
          <TitleBox option={2} title={"사용하실 비밀번호를 입력해주세요"} />
          <PassInput
            onSaveEach={onSaveEach}
            onSaveAgreement={onSaveAgreement}
          />
        </section>
      ) : step === 4 ? (
        <SignUpSurvey
          surveyResponse={surveyResponse}
          onSurveyInput={onSurveyInput}
        />
      ) : step === 5 ? ( //5 닉네임
        <section className="mx-6 mt-[80px] flex flex-col gap-6">
          <TitleBox
            option={2}
            title={Parser("힐리어리에서 사용하실<br />별명을 입력해주세요")}
          />
          <InputBox
            option="common"
            nameTitle={"nickName"}
            placeholder={dummyNickname}
            comments={"1~8자 한글, 영어, 숫자 입력이 가능합니다."}
            onChangeInput={onChangeInput}
            setErrorMsg={setErrorMsg}
            value={value}
            setValue={setValue}
            active={active}
            errorMsg={value?.length > 0 ? errorMsg : null}
          />
        </section>
      ) : step === 6 ? ( //캐릭터
        <section className="mx-6 mt-[80px] flex flex-col gap-6">
          <TitleBox option={2} title={"나의 캐릭터를 선택해주세요"} />
          <CharacterInput
            onSaveEach={onSaveEach}
            character={signupDatas.characterPhoto}
          />
        </section>
      ) : (
        <section className="mx-6 mt-[80px] flex flex-col gap-6">
          <TitleBox option={2} title={"나의 캐릭터를 선택해주세요"} />
          <CharacterInput
            onSaveEach={onSaveEach}
            character={signupDatas.characterPhoto}
          />
        </section>
      )}
      {(step === 1 ||
        step === 2 ||
        step === 4 ||
        step === 5 ||
        step === 6 ||
        step === 7) && (
        <Btn
          active={active}
          onAction={() => onSetNextStep(step)}
          option={"bottom"}
          bgColor={"bg-main"}
          textColor={"text-white"}
          borderColor={"border border-main"}
          title={
            step === 1
              ? "인증번호 문자 받기"
              : step === 2
              ? "인증번호 확인"
              : step === 4
              ? "다음"
              : step === 5
              ? "별명 저장"
              : step === 7
              ? "처음으로 돌아가기"
              : "완료"
          }
        />
      )}
    </>
  );
};

export default MobileSignUp;
