import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { editPass, chkPass } from "../../../../../features/mobile/accountSlice";
import { regPass } from "../../../components/logic";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../../common/toast";
import HeadCont from "../../../components/common/HeadCont";
import InputBox from "../../../components/common/InputBox";
import Btn from "../../../components/common/Btn";

const EditPass = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bottomRef = useRef(null);
  const { loginInfo } = useSelector((state) => state.commonApp);
  const [status, setStatus] = useState("current"); //current: 현재 비번 확인, new: 새 비번 확인
  const [currentPass, setCurrentPass] = useState(null);
  const [pwd, setPwd] = useState({ pass: "", again: "" });
  const [active, setActive] = useState("ready");
  const [active1, setActive1] = useState("ready");
  const [active2, setActive2] = useState("ready");
  const [errorMsg, setErrorMsg] = useState(
    "영문, 숫자 조합 6자리 이상으로 입력해주세요"
  );
  const [errorMsg2, setErrorMsg2] = useState({
    msg1: "영문, 숫자 조합 6자리 이상으로 입력해주세요",
    msg2: "",
  });
  const passRef = useRef(null);
  const againRef = useRef(null);

  const onChangeInput = (e) => {
    setCurrentPass(e.target.value);
    if (!regPass(e.target.value)) {
      setErrorMsg("영문, 숫자 조합 6자리 이상으로 입력해주세요");
      setActive("ready");
    } else {
      setErrorMsg("");
      setActive("on");
    }
  };

  const scrollAction = () => {
    const timer = setTimeout(
      () =>
        bottomRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        }),
      500
    ); //늦게반응
    return () => clearTimeout(timer);
  };

  const onChangeInput1 = (e) => {
    setPwd({ ...pwd, pass: e.target.value });
    if (!regPass(e.target.value)) {
      setErrorMsg2({
        msg1: "영문, 숫자 조합 6자리 이상으로 입력해주세요",
        msg2: "",
      });
      setActive1("ready");
      setActive2("ready");
    } else {
      scrollAction();
      setErrorMsg2({ msg1: "", msg2: "" });
      setActive1("on");
    }
  };

  const onChangeInput2 = (e) => {
    setPwd({ ...pwd, again: e.target.value });
    if (pwd.pass !== e.target.value) {
      setErrorMsg2({
        ...errorMsg2,
        msg2: "비밀번호 확인을 위해 다시 입력해주세요",
      });
      setActive2("ready");
    } else {
      if (active1 === "on") {
        setErrorMsg2({ ...errorMsg2, msg2: "비밀번호가 일치합니다" });
        setActive2("on");
      } else setActive2("ready");
    }
  };

  const onDeleteValue = (type) => {
    if (type === "pass") {
      setPwd({ pass: "", again: "" });
      setErrorMsg2({ ...errorMsg2, msg1: "" });
      passRef.current?.focus();
      setActive1("ready");
      setActive2("ready");
    } else {
      setPwd({ ...pwd, again: "" });
      setErrorMsg2({ ...errorMsg2, msg2: "" });
      againRef.current?.focus();
    }
  };
  // * 비밀번호 변경 저장
  const onSavePass = (e) => {
    e.preventDefault();
    let params = {
      userId: loginInfo.userId,
      orgPass: currentPass,
      newPass: pwd.pass,
    };
    dispatch(editPass(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          toast(
            <p>비밀번호 변경 되었습니다</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
          setCurrentPass("");
          setPwd({ pass: "", again: "" });
          const timer = setTimeout(() => navigate(-1), 500);
          return () => clearTimeout(timer);
        } else {
          setErrorMsg(res.error);
          setErrorMsg2({
            msg1: "영문, 숫자 조합 6자리 이상으로 입력해주세요",
            msg2: "",
          });
          setStatus("current");
          setCurrentPass("");
          setPwd({ pass: "", again: "" });
          setActive("ready");
          setActive1("ready");
        }
      })
      .catch((err) => console.log(err));
  };

  // * 현재 비밀번호 체크
  const onCheckPass = () => {
    let params = {
      userId: loginInfo.userId,
      orgPass: currentPass,
    };
    dispatch(chkPass(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          setStatus("new");

          setCurrentPass("");
          setPwd({ pass: "", again: "" });
        } else {
          setErrorMsg(res.error);
          setErrorMsg2({
            msg1: "영문, 숫자 조합 6자리 이상으로 입력해주세요",
            msg2: "",
          });
          setStatus("current");
          setCurrentPass("");
          setActive("ready");
          setActive1("ready");
          //   setPwd({ pass: "", again: "" });
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let focused = true;
    if (focused && status === "new") {
      passRef.current?.focus();
    }
    return () => (focused = false);
  }, [status]);

  return (
    <>
      <HeadCont title={"비밀번호 변경"} underline={true} />
      <section className="px-6 py-8 mb-6 mt-14">
        <p className="text-subtitle3 text-gray_180">
          {status === "current"
            ? "현재 사용중이신 비밀번호를 입력해주세요"
            : "새로 사용하실 비밀번호를 입력해주세요"}
        </p>

        {status === "current" ? (
          // * 현재 사용 비밀번호 확인
          <>
            <div className="mt-8 space-y-4">
              <InputBox
                option="common"
                type="password"
                placeholder={"현재 비밀번호"}
                onChangeInput={onChangeInput}
                errorMsg={errorMsg}
                value={currentPass}
                setValue={setCurrentPass}
                active={active}
              />
              <Btn
                option={"bottom"}
                bgColor={"bg-main"}
                textColor={"text-white font-semibold"}
                borderColor={"border border-main"}
                title={"비밀번호 확인"}
                active={errorMsg === "" ? "on" : "ready"}
                onAction={onCheckPass}
              />
            </div>
          </>
        ) : (
          <div className="mt-8 space-y-4">
            <form
              onSubmit={
                active1 === "on" && active2 === "on"
                  ? onSavePass
                  : (e) => e.preventDefault()
              }
              className="pb-24 space-y-6"
            >
              <InputBox
                option="pass"
                refName={passRef}
                id={"pass"}
                nameTitle={"pass"}
                placeholder={"비밀번호"}
                onChangeAction={(e) => onChangeInput1(e)}
                value={pwd.pass || ""}
                active={active1}
                onClickAction={() => onDeleteValue("pass")}
                errorMsg={errorMsg2.msg1}
              />
              <InputBox
                option="pass"
                refName={againRef}
                id={"again"}
                nameTitle={"again"}
                placeholder={"비밀번호 확인"}
                onChangeAction={(e) => onChangeInput2(e)}
                value={pwd.again || ""}
                active={active2}
                onClickAction={() => onDeleteValue("again")}
                errorMsg={errorMsg2.msg2}
              />
              <Btn
                option={"bottom"}
                bgColor={"bg-main"}
                textColor={"text-white font-semibold"}
                borderColor={"border border-main"}
                title={"비밀번호 저장"}
                active={active1 === "on" && active2 === "on" ? "on" : "ready"}
              />
            </form>
            <div ref={bottomRef} className="h-[40px]"></div>
          </div>
        )}
      </section>
    </>
  );
};

export default EditPass;
