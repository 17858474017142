import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeadCont from "../../../components/common/HeadCont";
import InputSelectBox2 from "../../../components/common/InputSelectBox2";
import PhotoCard from "../../../components/common/PhotoCard";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../../common/toast";
import {
  customerCenteraddQ,
  customerCenterEditQ,
  getCustomerCenterQuestionOne,
  onSetCustomerCenterIdName,
} from "../../../../../features/mobile/settingSlice";
import Btn from "../../../components/common/Btn";
import { useNavigate } from "react-router-dom";
import { resizeFile } from "../../../../../common/util/base";
import Dialog from "../../../components/common/Dialog";

const UsageId = () => {
  const navigate = useNavigate();
  const closeIcon = useMemo(
    () => ({
      background: "url('/images/mobile/icon/close.svg') no-repeat center",
    }),
    []
  );
  const { loginInfo } = useSelector((state) => state.commonApp);
  const dispatch = useDispatch();
  const [customerCenterQList, setCustomerCenterQList] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const [remainFiles, setRemainFiles] = useState([]);
  const oldTitle = customerCenterQList?.title;
  const oldContent = customerCenterQList?.content;
  const oldRemainFiles = customerCenterQList?.files;
  const [loadedImage, setLoadedImage] = useState([]);
  const { customerCenterId } = useSelector((state) => state.setting);
  const [onetime, setOnetime] = useState(0);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    txt: null,
    pathNum: -1,
  });
  const printCustomerCenterQOne = useCallback(async () => {
    const param = {
      userId: loginInfo.userId,
      id: customerCenterId,
    };
    const res = await dispatch(getCustomerCenterQuestionOne(param)).unwrap();
    try {
      if (res.data.ok) {
        setCustomerCenterQList(res.data.question);
        setTitle(res.data.question.title);
        setContent(res.data.question.content);
        setRemainFiles(res.data.question.files);
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, loginInfo.userId, customerCenterId]);

  const repeatFunc = useCallback(
    (res, txt, isSave, pathNum) => {
      try {
        if (res.data.ok) {
          if (isSave === "저장") {
            dispatch(onSetCustomerCenterIdName(res.data.id));
          }
          setOpenDialog({ open: true, txt: txt, pathNum: pathNum });
        } else {
          toast(
            <p>{res.error}</p>,
            toastCommonProps("bottom-center", "toast_alert", 1000)
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line
    [navigate, dispatch]
  );

  const onClickComplete = async () => {
    const QId = customerCenterQList?.id;
    const param = {
      userId: loginInfo.userId,
      title: title,
      content: content,
      files: files,
    };

    const param1 = {
      id: QId,
      userId: loginInfo.userId,
      title: title,
      content: content,
      files: files,
      remainingFiles: remainFiles,
    };
    setOnetime(1);
    if (QId === undefined) {
      const res = await dispatch(customerCenteraddQ(param)).unwrap();
      repeatFunc(res, "문의내용 작성 완료되었습니다.", "저장", -1);
    } else {
      const res = await dispatch(customerCenterEditQ(param1)).unwrap();
      repeatFunc(res, "문의내용 작성 수정되었습니다.", "수정", -2);
    }
  };

  const onAddThumbnail = async (e) => {
    let fileUrls = [];
    let cameraArr = [];
    for (let i = 0; i < e.target.files.length; i++) {
      let reader = new FileReader();
      let resize = await resizeFile(e.target.files[i]);
      reader.readAsDataURL(resize);
      const includesLastModified = files.filter(
        (el) =>
          el.lastModified === resize.lastModified && el.name === resize.name
      );
      if (includesLastModified.length === 0) {
        //같은 파일 제외하고 배열 저장
        if (remainFiles === undefined || remainFiles === null) {
          //수정 x 처음 저장시
          cameraArr.push(resize);
          setFiles(files.concat(cameraArr));
          reader.onload = () => {
            fileUrls[i] = reader.result;
            setLoadedImage(loadedImage.concat(fileUrls));
            e.target.value = "";
          };
        } else {
          if (remainFiles?.length !== 3) {
            //remainFile이 전부 3장이 아닐경우 slice해줌
            cameraArr.push(resize);
            setFiles(files.concat(cameraArr).slice(0, 3 - remainFiles?.length));
            reader.onload = () => {
              fileUrls[i] = reader.result;
              setLoadedImage(
                loadedImage.concat(fileUrls).slice(0, 3 - remainFiles?.length)
              );
              e.target.value = "";
            };
          }
        }
      }
    }
  };

  const onDeleteValue = (el, idx) => {
    const filterLoaded = loadedImage.filter((img) => img !== el);
    setLoadedImage(filterLoaded);
    const filterFiles = files.filter((img) => img !== files[idx]);
    setFiles(filterFiles);
  };
  const onDeleteRemain = (el) => {
    const filterRemains = remainFiles.filter((img) => img !== el);
    setRemainFiles(filterRemains);
  };
  const closeDialog = () => {
    setOpenDialog({ open: false, txt: null, pathNum: -1 });
    // openDialog within the toast is not updated yet
    toast(
      <p>{openDialog.txt}</p>,
      toastCommonProps("bottom-center", "toast_alert", 1000)
    );
    navigate(openDialog.pathNum);
  };
  useEffect(() => {
    printCustomerCenterQOne();
  }, [printCustomerCenterQOne]);

  const dialogDesc = `문의 글이 등록되었습니다. <br/> 
  질문에 대한 답변은 휴일을 제외하고 1~2일 내에 완료되며, 상황에 따라 조금 늦어질 수도 있습니다.
  감사합니다.`;
  const ifOn =
    typeof customerCenterId !== "number" //새로입력시
      ? title !== "" && content !== ""
        ? true
        : false
      : title !== oldTitle ||
        content !== oldContent ||
        remainFiles !== oldRemainFiles ||
        loadedImage?.length > 0
      ? true
      : false;

  return (
    // prettier-ignore
    <div className='h-screen overflow-y-auto bg-white'>
      <div className='h-full pb-24 mx-auto maxW pt-14'>
        <HeadCont rightIcon={closeIcon}
          transparent={"white"}
          underline={true}
          leftIcon={"no icon"} title={"문의 내용 작성"}
          onRightAction={() => navigate(-1)}
        />
        <div className='flex flex-col p-6 gap-y-6'>
          <InputSelectBox2 labelN={"이용문의 제목"} placeH={"제목을 입력해주세요"}
            htmlForN={"usageInquiryT"} nameTitle={"usageInquiryT"}
            setValue={setTitle} value={title}
          />
          <div>
            <InputSelectBox2 labelN={"문의 내용"} placeH={"앱 사용 중 궁금하신 사항을 입력해주세요"}
              htmlForN={"usageInquiryCont"} nameTitle={"usageInquiryCont"}
              setValue={setContent} value={content}
              textA={true} textAHeight={"h-[168px]"}
            />
            <span className='text-caption1_400 text-gray_160'>{(`${content !== undefined ? content.length : 0}/2,000자`)}</span>
          </div>
          <div className='flex flex-col pb-10'>
            <span className='text-gray_160 text-caption1_500'>사진 첨부(최대3장)</span>
            {remainFiles?.length + loadedImage.length !== 3 &&
              <PhotoCard onAddThumbnail={onAddThumbnail} />
            }
            <ul className={'flex flex-col gap-y-4 p-4 rounded ' + (loadedImage?.length > 0 || remainFiles?.length > 0 ? " border border-gray_80 mb-4" : "")}>
              {remainFiles?.map((el, idx) => (
                <li key={idx} className="relative w-full">
                  <img
                    onClick={() => onDeleteRemain(el)}
                    src={'/images/mobile/icon/close_white.svg'} alt="닫기 아이콘" className="absolute right-3 top-3" />
                  <img src={el} className="w-full h-auto" alt="remain" />
                </li>
              ))}
              {loadedImage.map((el, idx) => (
                <li key={idx} className="relative w-full">
                  <img
                    onClick={() => onDeleteValue(el, idx)}
                    src={'/images/mobile/icon/close_white.svg'} alt="닫기 아이콘" className="absolute right-3 top-3" />
                  <img src={el} className="w-full h-auto" alt="loaded" />
                </li>
              ))}
            </ul>
            <div className={loadedImage?.length === 0 && remainFiles?.length === 0 ? "fixed bottom-0 left-0 w-full px-6 py-4 bg-white" : "py-4"}>
              <Btn bgColor={'bg-main'} textColor={'text-white font-semibold'} borderColor={'border border-main'} title={'작성 완료'} active={ifOn && onetime === 0 ? 'on' : 'ready'} onAction={onClickComplete} />
            </div>
          </div>
        </div>
      </div >
      {openDialog.open && <Dialog onCoverAction={closeDialog} onRightBtnAction={closeDialog} innerClass={'py-4 px-6'} desc={dialogDesc} /> }
    </div >
  );
};

export default UsageId;
