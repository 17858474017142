import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toggleFooter } from '../../../../features/admin/toggleSlice'

const AllSigned = ({setAllTog,allTog}) => {
  const dispatch = useDispatch()
  const checkon = "/images/admin/icon/checkbox_on.svg"
  const checkoff = "/images/admin/icon/checkbox_off.svg"
  const arrownext = "/images/admin/icon/arrow_next.svg"
  const arrownextoff = "/images/admin/icon/arrow_next_off.svg"
  const [serviceTog,setServiceTog] = useState(false)
  const [personalTog, setPersonalTog] = useState(false)
  const ETargetInCludes = useCallback((thisTarget)=>{
    if(thisTarget.innerText.includes("서비스 이용약관") && serviceTog){
      setServiceTog(false)
      setAllTog(false)
    }else if(thisTarget.innerText.includes("서비스 이용약관") && !serviceTog){
      setServiceTog(true)
      //둘다 클릭시 
      if(personalTog){
        setAllTog(true)
      }
    }
    if(thisTarget.innerText.includes("개인정보 처리방침") && personalTog){      
      setPersonalTog(false)
      setAllTog(false)
    }else if (thisTarget.innerText.includes("개인정보 처리방침") && !personalTog){
      setPersonalTog(true)
      //둘다 클릭시 
      if(serviceTog){
        setAllTog(true)
      }
    }
  })

  const checkComp = (e) =>{
    const eTarget = e.target
    if(eTarget.tagName ==="IMG"){
      if(eTarget.alt.includes("checkoff")|| eTarget.alt.includes("checkon")){
        const inEtarget = eTarget.parentNode.childNodes[1]
        ETargetInCludes(inEtarget)
      }else{
        const inEtarget = eTarget.parentNode.firstChild.childNodes[1]
        ETargetInCludes(inEtarget)
      }
    }else if(eTarget.tagName ==="SPAN"){
      const inEtarget = eTarget
      ETargetInCludes(inEtarget)
    }else{
      const inEtarget = eTarget.firstChild.childNodes[1]
      ETargetInCludes(inEtarget)
    }
  }

  const checkAll = () =>{
    if(!allTog){
      setServiceTog(true)
      setPersonalTog(true)
      setAllTog(true)
    }else{
      setServiceTog(false)
      setPersonalTog(false)
      setAllTog(false)
    }

  }
  return (
    <div className ="border rounded-[4px] p-[16px] border-[#A39F9B]">
      <div className ="flex gap-x-2 cursor-pointer"
      onClick={checkAll}
      >           
       {allTog?
        <img alt="checkon" src={checkon}/>
        :<img alt="checkoff" src={checkoff}/>
        }
        <span className="text-gray_100 text-body_2 font-semibold">전체 동의하기</span>
      </div>
      <div className="text-caption_2 text-gray_100 pb-[16px] border-b border-[#A39F9B]">
        <p>힐리어리를 이용하시기에 앞서 아래의 서비스 동의를 진해해 주세요.</p>
        <p>미 동의 시 웹 이용이 불가능 합니다.</p>
      </div>
      <ul className="pt-[16px] flex flex-col gap-y-[12px]">
        <li className='flex justify-between cursor-pointer'
        onClick = {checkComp}>
          <div className ="flex gap-x-2">
            {serviceTog?
            <img alt="checkon" src={checkon}/>
            :<img alt="checkoff" src={checkoff}/>
            }
            <span className=
            {serviceTog?
              "text-gray_100 text-caption_1 font-semibold"
              :"text-gray_50 text-caption_1 font-semibold"
            }>[필수] 서비스 이용약관</span>
          </div>
          {
            serviceTog?
            <img alt="arrownext" src={arrownext}
            onClick={()=>dispatch(toggleFooter("서비스 이용약관"))}
            />
            :          
            <img alt="arrownextoff" src={arrownextoff}
            onClick={()=>dispatch(toggleFooter("서비스 이용약관"))}
            />
          }
        </li>
        <li className='flex justify-between cursor-pointer'
        onClick = {checkComp}>
          <div className ="flex gap-x-2 ">
            {personalTog?
              <img alt="checkon" src={checkon}/>
              :<img alt="checkoff" src={checkoff}/>
            }
            <span className=
            {personalTog?
              "text-gray_100 text-caption_1 font-semibold" 
              :"text-gray_50 text-caption_1 font-semibold" 
            }>[필수] 개인정보 처리방침</span>
          </div>
          {personalTog?
            <img alt="arrownext" src={arrownext}
            onClick={()=>dispatch(toggleFooter("개인정보 처리방침"))}
            />:
            <img alt="arrownextoff" src={arrownextoff}
            onClick={()=>dispatch(toggleFooter("개인정보 처리방침"))}
            />
          }

        </li>
      </ul>
    </div>
  )
}

export default AllSigned