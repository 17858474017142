import React, { useEffect, useState } from "react";
import Sidebar from "../../components/common/Sidebar";
import Navbar from "../../components/common/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import DontEditAccountInput from "../../components/account/DontEditAccountInput";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../components/common/ButtonCustom";
import ConfirmModal from "../../components/common/ConfirmModal";
import { AnimatePresence } from "framer-motion";

const HospitalId = () => {
  const navigate = useNavigate();
  const state = useLocation().state;
  const [confirmModal, setConfirmModal] = useState(false);
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const showConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  useEffect(() => {
    if (!loginInfo?.adminId) {
      navigate("/admin/signin");
    }
  }, []);

  return (
    <>
      <AnimatePresence initial={false} mode="wait">
        {confirmModal ? (
          <ConfirmModal
            showConfirmModal={showConfirmModal}
            flag="hospitalDelete"
            state={state}
          />
        ) : (
          ""
        )}
      </AnimatePresence>
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex w-auto min-w-min flex-col items-start justify-start gap-[16px] p-[24px]">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="flex h-[112px] w-[882px] items-center justify-between gap-[48px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              <div className="flex h-[64px] items-center justify-between gap-[24px]">
                <div className="relative flex h-[64px] w-[72px]">
                  <span className="flex h-[64px] w-[64px] items-center justify-center rounded-full bg-gray-100 font-bold">
                    {state.name === null ? "null" : state.name}
                  </span>
                </div>
                <div className="flex h-[48px] flex-col justify-center ">
                  <h3 className="text-xl font-bold text-black">
                    {state.name === null ? "null" : state.name}
                  </h3>
                </div>
              </div>
              <p className="h-[18px] w-[125px] text-sm text-[#05040D]">
                since {dayjs(state.createdAt).format("YYYY-MM-DD")}
              </p>
            </div>
            <div className="mt-[16px] flex h-[450px] w-[882px] flex-col items-center justify-center gap-[24px] rounded-[24px] bg-[#FCFCFC] p-[24px] shadow-md">
              {state === undefined ? (
                <></>
              ) : (
                <div className="flex w-[340px] flex-col items-center justify-start gap-[8px]">
                  <DontEditAccountInput
                    labelname="병원 코드"
                    value={state.code}
                  />
                  <DontEditAccountInput
                    labelname="주소"
                    value={state.address !== null ? state.address : ""}
                  />
                  <DontEditAccountInput
                    labelname="상세 주소"
                    value={
                      state.addressDetail !== null ? state.addressDetail : ""
                    }
                  />
                  <DontEditAccountInput
                    labelname={
                      state.updatedAt === state.createdAt ? "가입일" : "수정일"
                    }
                    value={
                      state.updatedAt === state.createdAt
                        ? dayjs(state.createdAt).format("YYYY.MM.DD")
                        : dayjs(state.updatedAt).format("YYYY.MM.DD")
                    }
                  />
                </div>
              )}
              <div className="flex w-[340px] gap-[8px]">
                <BorderButtonCustom
                  onClick={showConfirmModal}
                  content={"삭제"}
                  bgcolor={""}
                  textcolor={"text-orange_100"}
                  bordercolor={"border-orange_100"}
                  textsize={"text-caption1_600"}
                  width={"20%"}
                />
                <BorderButtonCustom
                  onClick={() => {
                    navigate("/admin/hospital");
                  }}
                  content={"목록"}
                  bgcolor={""}
                  textcolor={"text-orange_100"}
                  bordercolor={"border-orange_100"}
                  textsize={"text-caption1_600"}
                  width={"20%"}
                />
                <BGButtonCustom
                  onClick={() => {
                    navigate("/admin/hospital/edit", {
                      state: state,
                    });
                  }}
                  content={"수정"}
                  bgcolor={"bg-orange_100"}
                  textcolor={"text-gray_10"}
                  textsize={"text-caption1_600"}
                  width={"60%"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HospitalId;
