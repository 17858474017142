import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  decrement,
  fetchAnswerList,
  fetchQuestion,
  // goBackByAmount,
  resetI,
} from "../../../../features/mobile/questionFoodAddSlice";
import HeadCont from "../../components/common/HeadCont";
import Message from "../../components/common/Message";
import QuestionForm from "../../components/question_addFood/QuestionForm";

const QuestionFoodAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { i, prevI, resultLists } = useSelector(
    (state) => state.questionFoodAdd
  );
  const closeIcon = useMemo(
    () => ({
      background: "url('/images/mobile/icon/close.svg') no-repeat center",
    }),
    []
  );
  const [openMessage, setOpenMessage] = useState(false);
  const onLeftAction = () => {
    if (i <= 0) {
      navigate(-1);
      window.scrollTo(0, 0);
    } else {
      // if (prevI.length >= 2) {
      //   dispatch(goBackByAmount());
      // } else if (prevI.length === 1) {
      //   //i가 1일때
      //   dispatch(decrement());
      // }
      dispatch(decrement());
    }
  };

  useEffect(() => {
    if (!loginInfo?.userId) navigate(`/sign`);
    else {
      window.scrollTo(0, 0);
      dispatch(resetI());
      dispatch(fetchAnswerList(loginInfo.userId));
      dispatch(fetchQuestion());
    }
  }, [dispatch, loginInfo.userId, navigate]);
  return (
    <>
      {!openMessage ? (
        <>
          {/* onRightAction: 
            처음 가입하고 설문하는 사람 관련 이슈 (이전 페이지가 recommend 이거나 healthinfo일 경우 -1이 먹히지 않음) 때문에 api user/questionCheck 쓰려고 했으나, 일단 홈으로 이동 */}
          <HeadCont
            option={"progress"}
            onLeftAction={onLeftAction}
            rightIcon={closeIcon}
            onRightAction={() => navigate(location.state ? -1 : "/home")}
            i={i}
          />
          <section className="px-6 pt-2 mt-14">
            <QuestionForm
              onLeftAction={onLeftAction}
              setOpenMessage={setOpenMessage}
            />
          </section>
        </>
      ) : (
        <Message
          option={2}
          imgUrl={"/images/mobile/image/doctor_moniter.svg"}
          resultLists={resultLists}
        />
      )}
    </>
  );
};

export default QuestionFoodAdd;
