import { useSelector } from "react-redux";
import { CommunityHeadlist } from "../../components/common/TableHeadList";

const CommunityMemberList = ({ showEachNoticeModal }) => {
  const { postingList } = useSelector((state) => state.commu);
  const report_icon = "/images/admin/icon/report_icon.svg";

  const bannerSticker = (title, bannercss) => {
    const commoncss = " p-1 flex justify-center items-center rounded text-xs ";
    return <span className={commoncss + bannercss}>{title}</span>;
  };
  const wrappDataContent = (data) => {
    return (
      <div className="flex gap-x-2">
        {data.notice && bannerSticker("공지", " bg-[#61BE82] text-white ")}
        {data.event && bannerSticker("이벤트", " bg-[#FFE9A0] ")}
        {data.news && bannerSticker("소식", " bg-[#FF7F00] text-white ")}

        <span className={data.writerUserId === null ? "font-bold" : ""}>
          {data.title}
        </span>
      </div>
    );
  };
  console.log(postingList, "postingList");
  const repeatedTd = (index, data) => {
    const commonTdcss =
      " py-4 border-gray_6 text-black border-b border-gray_60 text-center ";

    const 소식리스트 = [
      {
        tdclass:
          commonTdcss +
          " border-l " +
          (index + 1 === postingList?.length ? " rounded-bl-lg" : ""),
        tdcontent: data.no,
      },
      { tdclass: commonTdcss, tdcontent: wrappDataContent(data) },
      {
        tdclass: commonTdcss,
        tdcontent:
          data.content?.length > 20
            ? data.content?.slice(0, 20) + "..."
            : data.content,
      },
      {
        tdclass: commonTdcss + (data.adminName ? " font-bold " : ""),
        tdcontent: data.adminName ? data.adminName : data?.nickName,
        tdcontent2: data.nickNameProtector
          ? `(보: ${data.nickNameProtector})`
          : "",
      },
      { tdclass: commonTdcss, tdcontent: data.createdAt.substr(0, 10) },
      { tdclass: commonTdcss, tdcontent: data.updatedAt.substr(0, 10) },

      { tdclass: commonTdcss, tdcontent: data.readCount },
      { tdclass: commonTdcss, tdcontent: data.likeCount },
      { tdclass: commonTdcss, tdcontent: data.replyCount },

      { tdclass: commonTdcss, tdcontent: data.reportCount },
      {
        tdclass:
          commonTdcss +
          " border-r " +
          (index + 1 === postingList?.length ? " rounded-br-lg" : ""),
        tdcontent: data.isHide ? "비공개" : "공개",
      },
    ];
    return (
      <>
        {소식리스트?.map((el, index) => (
          <td className={el.tdclass} key={index}>
            <div>{el.tdcontent}</div>
            {el.tdcontent2 ? <p>{el.tdcontent2}</p> : ""}
          </td>
        ))}
      </>
    );
  };
  return (
    <table
      className="col-span-3 w-full table-auto border-separate 3xl:col-span-2"
      cellSpacing={0}
    >
      <thead>
        <tr className="bg-gray_60">
          {CommunityHeadlist?.map((head, idx) => (
            <th
              key={idx}
              className={
                " border-y border-gray_60 p-4 font-semibold text-black first:rounded-tl-lg first:border-l last:rounded-tr-lg last:border-r" +
                (idx === 0 ? " w-[80px] " : "")
              }
            >
              {head === "신고 누적" ? (
                <div className="flex items-end justify-center">
                  <img
                    src={report_icon}
                    alt="report_icon"
                    className="pr-[4px]"
                  />
                  <p>{head}</p>
                </div>
              ) : (
                <div>{head}</div>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {postingList &&
          postingList?.map((data, index) => (
            <tr
              key={index}
              className={
                "cursor-pointer px-6  py-3 hover:bg-orange_10 " +
                (index % 2 === 1 ? "bg-gray_95" : "bg-gray_10")
              }
              onClick={() => {
                showEachNoticeModal(data, "소식");
              }}
            >
              {repeatedTd(index, data)}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default CommunityMemberList;
