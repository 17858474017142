import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CommListSkeleton from "../loading/CommListSkeleton";

import ListCard from "./ListCard";

const FoldPosts = ({ parameters }) => {
  const {
    postList,
    onFetchMorePosts,
    isMorePosting,
    prevLikedList,
    onClickPost,
    onDeletePost,
    onClickWritePost,
    onAddReporting,
    setOpenDialog,
    setCurrentUser,
    setReportStatus,
    setOptionOpen,
    optionOpen,
    myPage,
  } = parameters;
  return (
    <InfiniteScroll
      dataLength={postList.length}
      next={onFetchMorePosts}
      hasMore={isMorePosting}
      loader={<CommListSkeleton />}
    >
      <ul>
        {postList.map((list, idx) => (
          <li key={list?.id}>
            <ListCard
              main={true}
              PostContents={{
                postId: list?.id,
                header: list?.title,
                content: list?.content,
                thumbnail: list?.files,
                likeCount: prevLikedList[idx]?.likeCount,
                isLiked: prevLikedList[idx]?.isLiked,
                replyCount: prevLikedList[idx]?.replyCount,
                isWriteReply: prevLikedList[idx]?.isWriteReply,
                writer: list?.writer,
                writerAdmin: list?.writerAdmin,
                createdAt: list?.createdAt,
                nickName: list?.nickName,
                event: list?.event,
                notice: list?.notice,
                news: list?.news,

                isReported: prevLikedList[idx]?.report,
              }}
              onClickPost={onClickPost}
              onDeletePost={onDeletePost}
              onEditPost={() => onClickWritePost("edit", list?.id)}
              cancelReport={onAddReporting}
              reportDialog={{
                setOpenDialog: setOpenDialog,
                setCurrentUser: setCurrentUser,
                setReportStatus: setReportStatus,
              }}
              setOptionOpen={setOptionOpen}
              optionOpen={optionOpen}
              myPage={myPage}
            />
          </li>
        ))}
      </ul>
    </InfiniteScroll>
  );
};

export default FoldPosts;
