import React from "react";
import { appDownload } from "./SixthPart";

const MobileFirstPart = () => {
  return (
    <section>
      <div
        className="relative h-[450px]"
        style={{
          backgroundImage:
            "url('/images/mobile/landing/landing_main1-1-a.png')",
          backgroundSize: "cover",
          backgroundPosition: "35% 10%",
        }}
      >
        <div className="ml-6 flex h-full flex-col justify-center">
          <p className="text-[25px] text-gray_200">
            <strong className="font-extrabold">암환자의 일상</strong>을
            <span> 변화시켜 줄</span>
            <br />
            <span> 든든한 동반자</span>
          </p>
          <h1 className="mt-[10px] text-[40px] font-extrabold text-main">
            힐리어리
          </h1>
          <div className="mt-5 flex flex-row items-start gap-2">
            <button
              onClick={appDownload}
              className="flex items-center justify-around gap-3 rounded-full bg-main px-4 py-3 text-sm font-semibold tracking-wide text-white"
            >
              <img
                src="/images/mobile/landing/download.svg"
                alt="다운로드"
                className="w-5 pb-[1px]"
              />
              <span>힐리어리 시작하기</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default MobileFirstPart;
