import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMealAnalysis,
  fetchMealStatistics,
  onSetChosenDate,
} from "../../../../features/mobile/mealSlice";
import Parser from "html-react-parser";
import HeadCont from "../../components/common/HeadCont";
import MenuBar from "../../components/common/MenuBar";
import CalorieProteinCard from "../../components/meal/CalorieProteinCard";
import NutritionGraph from "../../components/common/NutritionGraph";
import Btn from "../../components/common/Btn";
import TitleBox from "../../components/common/TitleBox";
import Graph from "../../components/meal/Graph";
import Loading from "../../components/common/Loading";
import { checkQuestionsComplete } from "../../../../features/mobile/otherSlice";

const Analysis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useParams();
  const { loginInfo } = useSelector((state) => state.commonApp);
  const { analysisLists, statisticsStatus, statisticsLists } = useSelector(
    (state) => state.meal
  );
  const nutritons = [
    { name: "식이섬유", keyName: "totalDietaryfiber", unit: "g" },
    { name: "당류", keyName: "totalsugar", unit: "g" },
    { name: "포화지방", keyName: "totalSaturatedfattyacid", unit: "g" },
    { name: "나트륨", keyName: "sodium", unit: "mg" },
    { name: "콜레스테롤", keyName: "cholesterol", unit: "mg" },
    { name: "트랜스지방", keyName: "transfattyacid", unit: "g" },
  ];
  const tabs = [
    { id: 1, tab: "최근 7일" },
    { id: 2, tab: "최근 1개월" },
    { id: 3, tab: "전체" },
  ];
  const [graphOption, setGraphOption] = useState(1);

  const onSetTab = (num) => {
    let params = { userId: loginInfo.userId, type: num };
    dispatch(fetchMealStatistics(params));
    setGraphOption(num);
  };

  const LabelLists = (names, labelbg) => {
    return (
      <ul className="flex justify-end pt-3 gap-x-5">
        {names?.map((el, idx) => (
          <li key={idx} className="flex items-center justify-center gap-x-1">
            <span className={"block h-4 w-4 " + labelbg[idx]}></span>
            <span className="inline-block text-caption2_400 text-gray_120">
              {el}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    dispatch(checkQuestionsComplete(loginInfo.userId))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          if (!res.healthComplete) {
            // 마이페이지에서 들어온 경우 건강설문 미입력인 경우 설문조사 페이지로 이동
            navigate(`/question/health`, { replace: true });
          } else {
            window.scrollTo(0, 0);
            let params = { userId: loginInfo.userId, mealDate: type };
            let params2 = { userId: loginInfo.userId, type: graphOption };
            dispatch(fetchMealAnalysis(params));
            dispatch(fetchMealStatistics(params2));
          }
        }
      });

    // eslint-disable-next-line
  }, [dispatch, loginInfo.userId]);

  return (
    <>
      <HeadCont
        title={"식사 분석"}
        leftIcon={"no icon"}
        rightIcon={"close"}
        onRightAction={() => {
          dispatch(onSetChosenDate(type));
          navigate(-1);
        }}
      />
      <section className="mb-6 mt-14">
        <div className="mx-6">
          <h3 className="text-body1_700 text-gray_200">
            {type.replaceAll("-", ". ")}
          </h3>
          <h1 className="text-subtitle3 text-gray_180">
            {loginInfo.patientNickName
              ? loginInfo.patientNickName
              : loginInfo.nickName}
            님의 식사 분석
          </h1>
          {analysisLists?.ok ? (
            <div className="mt-[50px] grid grid-cols-2 gap-4">
              <CalorieProteinCard
                option={"calorie"}
                actual={analysisLists.kcal}
                recommended={analysisLists.needKcal}
                result={analysisLists.kcalIntakeName}
              />
              <CalorieProteinCard
                option={"protein"}
                actual={analysisLists.proteinG}
                recommended={analysisLists.needProteinG}
                result={analysisLists.proteinIntakeName}
              />
            </div>
          ) : null}
        </div>
        <div className="mb-20 mt-[64px] rounded-t-[32px] bg-white shadow-[0_0_12px_rgba(0,0,0,0.1)]">
          <div className="px-6 text-center border-b border-gray_60 py-9">
            <h1 className="text-subtitle3">🥦 열량 및 단백질 섭취 🍖</h1>
            <span className="mx-auto my-4 flex h-[1px] w-[48px] bg-gray_60"></span>
            <ul className="space-y-4 text-body3_700">
              <li>
                {analysisLists.eatReport
                  ? Parser(analysisLists.eatReport.replaceAll("NaN", "-"))
                  : "-"}
              </li>
              <li>
                {analysisLists.eatIntakeComment
                  ? Parser(
                      analysisLists.eatIntakeComment.replaceAll("NaN", "-")
                    )
                  : "-"}
              </li>
            </ul>
          </div>
          <div className="border-b border-gray_60 p-9">
            <h1 className="text-center text-subtitle3">
              <span>{"⚖️ 상세 영양소 섭취"} </span>
            </h1>
            <span className="mx-auto my-4 flex h-[1px] w-[48px] bg-gray_60"></span>
            <NutritionGraph datas={analysisLists} />
            <ul
              className={
                "mt-4 grid grid-flow-col grid-rows-3 bg-gray_15 px-2 py-3 text-caption2_400"
              }
            >
              {nutritons.map((el, idx) => (
                <li key={idx}>
                  <span>· {el.name}:</span>
                  <span> {analysisLists?.[el.keyName]}</span>
                </li>
              ))}
            </ul>
          </div>
          <ul className="flex justify-end gap-3 p-4">
            {tabs.map((tab) => (
              <li
                key={tab.id}
                onClick={() => onSetTab(tab.id)}
                className={
                  "rounded-md border px-3 py-2 text-caption1_700 " +
                  (graphOption === tab.id
                    ? "border-orange_180 bg-orange_10 text-orange_180"
                    : "border-gray_80 text-gray_160")
                }
              >
                {tab.tab}
              </li>
            ))}
          </ul>
          {statisticsStatus === "success" ? (
            <div className="m-6 space-y-3">
              <TitleBox option={6} title={"섭취 열량 kcal"} />
              <div className="mt-3 border rounded-lg border-gray_60">
                <Graph
                  option="calorie"
                  graphOption={graphOption}
                  graphDatas={statisticsLists?.eatKCalList}
                />
              </div>
              {graphOption === 3
                ? LabelLists(["실제 섭취량"], ["bg-orange_60"])
                : LabelLists(
                    ["실제 섭취량", "목표 섭취량"],
                    ["bg-orange_60", "bg-correct"]
                  )}
              <div className="pt-[20px]">
                <TitleBox option={6} title={"단백질 섭취량 g"} />
                <div className="mt-3 border rounded-lg border-gray_60">
                  <Graph
                    option="protein"
                    graphOption={graphOption}
                    graphDatas={statisticsLists?.eatProteinList}
                  />
                </div>
                {graphOption === 3
                  ? LabelLists(["실제 섭취량"], ["bg-green_60"])
                  : LabelLists(
                      ["실제 섭취량", "목표 섭취량"],
                      ["bg-green_60", "bg-correct"]
                    )}
              </div>
              <div className="pt-[20px]">
                <TitleBox option={6} title={"체중 kg"} />
                <div className="mt-3 border rounded-lg border-gray_60">
                  <Graph
                    option="weight"
                    graphOption={graphOption}
                    graphDatas={statisticsLists?.weightList}
                  />
                </div>
              </div>
            </div>
          ) : (
            <Loading />
          )}
          <div className="p-6">
            <Btn
              bgColor={"bg-main"}
              textColor={"text-white font-bold"}
              borderColor={"border border-main"}
              title={"확인"}
              active={"on"}
              onAction={() => {
                dispatch(onSetChosenDate(type));
                navigate(-1);
              }}
            />
          </div>
        </div>
      </section>
      <MenuBar />
    </>
  );
};

export default Analysis;
