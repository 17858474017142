import React from "react";
import { useNavigate } from "react-router-dom";

const Header = ({ tab, setTab }) => {
  // const menu = [
  //   { num: 1, title: "힐리어리 소개" },
  //   { num: 2, title: "이벤트" },
  // ];
  const navigate = useNavigate();

  return (
    <>
      <header className="z-10 flex h-[76px] w-full min-w-fit items-center sm:h-[86px] sm:justify-center">
        <div className="mx-auto flex w-full px-[24px] sm:grid sm:grid-cols-3 sm:px-[120px] xl:px-[150px] 2xl:grid-cols-5 2xl:px-[180px] 3xl:px-[200px] ">
          <h1
            className="font-oneMobileTitle text-2xl font-normal leading-[38px] text-main sm:text-[32px]"
            onClick={() => navigate("/")}
          >
            힐리어리
          </h1>
          {/* <ul className="mx-auto flex gap-5 self-center text-caption1_700 text-gray_170 2xl:col-span-3">
            {menu.map((value) => {
              return (
                <li
                  className={`${
                    tab === value.num ? "text-black" : null
                  } flex items-center gap-1 hover:cursor-pointer ${
                    value.title === "이벤트" ? "text-correct" : "text-gray_160"
                  }`}
                  key={value.num}
                  onClick={() => {
                    setTab(value.num);
                    if (value.title === "이벤트") {
                      window.dataLayer.push({
                        event: "check_event",
                      });
                    }
                  }}
                >
                  {value.title === "이벤트" ? (
                    <img
                      src="/images/mobile/landing/crown.svg"
                      alt=""
                      className="pb-1"
                    />
                  ) : null}
                  {value.title}
                </li>
              );
            })}
          </ul> */}
        </div>
      </header>
    </>
  );
};

export default Header;
