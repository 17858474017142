import React from 'react'
import { useSelector } from 'react-redux';
import SideInner from './SideInner'

const NavTitle = ({NAVTITLE,user}) => {
  //navigation 접을때 토글 boolean
  const navBooleen = useSelector((state) => state.toggleAdmin.navActive);
  return (
    <nav className="mx-4 mt-8">
      <ul className="flex flex-col justify-center">
        {NAVTITLE?.map((item) => (
          <SideInner
            key={item.id}
            item={item}
            navBooleen={navBooleen}
            user={user}
          />
        ))}
      </ul>
    </nav>

  )
}

export default NavTitle