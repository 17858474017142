import React, { useEffect, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { handlePageChange } from "../../../../features/admin/commonSlice";
import {
  searchManagementAdmins,
  searchManagementUsers,
} from "../../../../features/admin/managementSlice";
import {
  searchManagementCommunity,
  searchCustomerCenter,
  getCenterAwaiting,
  getAllReportedPosting,
  getFoodRegistrationRequest,
} from "../../../../features/admin/communitySlice";
const Paging = ({
  totalSearchCount,
  pageUserCount, //item number per one page
  pageRangeDisplayed, //page 1 ~ page {pageRangeDisplayed} 까지 표시
  filterInfo,
  defaultCheckbox,
}) => {
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const activePage = useSelector((state) => state.common.page);
  const commuTabname = useSelector((state) => state.commu.clickCommuTab);
  const accountTabName = useSelector(
    (state) => state.accountadmin.clickAccountTab
  );
  const customerCenterToggle = useSelector(
    (state) => state.commu.toggleCustomer
  );
  const communityCenterToggle = useSelector(
    (state) => state.commu.toggleCommunity
  );
  const { loginInfo } = useSelector((state) => state.adminLogin);

  const handlePageFn = (page) => {
    //전체 페이지에 보낼 page
    dispatch(handlePageChange(page));
    if (path.includes("board")) {
      if (commuTabname === "소식") {
        if (communityCenterToggle === "on") {
          //신고된 게시글 toggle 켜져있을때
          let reportedParam = {
            page: 1,
            countPerPage: pageUserCount,
          };
          dispatch(getAllReportedPosting(reportedParam));
        } else {
          //신고된 게시글 toggle 꺼져있을때
          const commuSelectid = document.getElementById("commuSelect");
          let commuselectValue =
            commuSelectid !== null &&
            commuSelectid.options[commuSelectid.selectedIndex].value;
          let paramCommu = {
            page: page,
            countPerPage: pageUserCount,
            writerUserId: commuselectValue === "작성자" ? filterInfo : "",
            title: commuselectValue === "제목" ? filterInfo : "",
            content: commuselectValue === "내용" ? filterInfo : "",
          };
          dispatch(searchManagementCommunity(paramCommu));
        }
      } else if (commuTabname === "1:1 이용문의") {
        if (customerCenterToggle === "on") {
          //1:1이용문의 toggle이 켜져있을때
          let awaitParam = {
            page: page,
            countPerPage: pageUserCount,
          };
          dispatch(getCenterAwaiting(awaitParam));
        } else {
          //1:1이용문의 toggle 꺼져있을떄
          const centerSelectid = document.getElementById("centerSelect");
          let centerSelectValue =
            centerSelectid.options[centerSelectid.selectedIndex].value;
          let paramCustom = {
            page: page,
            countPerPage: pageUserCount,
            userId: centerSelectValue === "작성자" ? filterInfo : "",
            title: centerSelectValue === "제목" ? filterInfo : "",
            content: centerSelectValue === "내용" ? filterInfo : "",
          };
          dispatch(searchCustomerCenter(paramCustom));
        }
      } else if (commuTabname === "음식DB요청") {
        let param = {
          page: page,
          countPerPage: pageUserCount,
        };
        dispatch(getFoodRegistrationRequest(param));
      }
    }
    if (path.includes("management") || path === "/admin") {
      let paramsManage = {
        page: page,
        countPerPage: pageUserCount,
        searchNameOrID: filterInfo.searchNameOrID,
        filterCancerType: filterInfo.filterCancerType,
        filterOperationYN: filterInfo.filterOperationYN,
        filterAntiCancerYN: filterInfo.filterAntiCancerYN,
        filterAntiCancerType: filterInfo.filterAntiCancerType,
        filterAge: filterInfo.filterAge,
        sortRegistDay: filterInfo.sortRegistDay,
        sortAge: filterInfo.sortAge,
      };
      dispatch(searchManagementUsers(paramsManage));
    }
    if (path.includes("account")) {
      if (accountTabName === "회원") {
        let accountUser = {
          page: page,
          countPerPage: pageUserCount,
          searchNameOrID: filterInfo.searchNameOrID,
        };
        dispatch(searchManagementUsers(accountUser));
      } else {
        let accountAdmin = {
          page: page,
          countPerPage: pageUserCount,
          searchNameOrID: filterInfo.searchNameOrID,
          loginedAdminID: loginInfo?.adminId,
        };
        dispatch(searchManagementAdmins(accountAdmin));
      }
    }
    if (path === "/admin/account/memberlist") {
      defaultCheckbox();
    }
  };

  useEffect(() => {
    let pageOpen = false;
    if (!pageOpen) {
    }
    return () => {
      pageOpen = true;
    };
  }, [dispatch]);
  return (
    <Pagination
      activePage={activePage}
      itemsCountPerPage={pageUserCount}
      totalItemsCount={Number(totalSearchCount)}
      pageRangeDisplayed={pageRangeDisplayed}
      prevPageText={"‹"}
      nextPageText={"›"}
      onChange={(page) => handlePageFn(page)}
    />
  );
};
export default Paging;
