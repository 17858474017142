// end = used metrics (g, kg, 게. etc.,)
const customToolTip = (series, seriesIndex, dataPointIndex, end, yDate) => {
  return (
    `<div class="flex">` +
    `<div class="text-gray-400" >` +
    parseInt(yDate[dataPointIndex].slice(0, 2)) +
    "월" +
    parseInt(yDate[dataPointIndex].slice(3, 5)) +
    "일&nbsp;" +
    "</div>" +
    `<div class="font-bold">` +
    (series[seriesIndex][dataPointIndex] !== undefined && series[seriesIndex][dataPointIndex] !== null
      ?series[seriesIndex][dataPointIndex]?.toFixed(1)
        .toLocaleString("ko-KR")+end
      : "기록 없음") +
    "</div>" +
    "</div>"
  );
};

export default customToolTip;