import React, { useCallback, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import ToggleBtn from "../../../components/ToggleBtn";
import {
  deletePostings,
  getAllRepliesOfOnePosting,
  getNotice,
  handlePageChangeCommu,
  handleReplySliceArr,
  onClickGetEditCommunityPosting,
  searchManagementCommunity,
  toggleHide,
} from "../../../../../features/admin/communitySlice";
import { toggleCommuHide } from "../../../../../features/admin/toggleSlice";
import Comment from "../../../components/board/Comment";
import { questionTypeOption } from "../CancerGPTOptionList";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../../components/common/ButtonCustom";
import { toast } from "react-toastify";
import toastCommonProps from "../../../../../common/toast";
import { handlePageChange } from "../../../../../features/admin/commonSlice";
import { setPageArr } from "../../../../../features/admin/paginationSlice";
import ImageClick from "../../../components/ImageClick";
const NewsModalContent = ({
  postingIdisHideToggle,
  showEachNoticeModal,
  showWriteModal,
}) => {
  const tabName = useSelector((state) => state.commu.clickCommuTab);
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const report_icon = "/images/admin/icon/report_icon.svg";
  const heart = "/images/admin/icon/heart.svg";
  const { postingIdList, postingIdReplies, requestDBEach, cancerGPTEeach } =
    useSelector((state) => state.commu);
  const pageReplyCount = 5;
  const communityUserId = postingIdList?.nickName;
  const { pagePerList } = useSelector((state) => state.pagination);
  const [subTab, setSubTab] = useState("질문&답변");
  const [reportOpen, setReportOpen] = useState(false);

  const updatedDayJS = (somethingDate) => {
    const commonDate = dayjs(somethingDate);
    return (
      commonDate.format("YYYY-MM-DD") +
      " " +
      commonDate.format("A") +
      " " +
      (commonDate.format("HH") > 12
        ? commonDate.format("HH") - 12
        : commonDate.format("HH")) +
      ":" +
      commonDate.format("MM")
    );
  };
  const onToggle = async () => {
    let param = {
      id: postingIdList.id,
      isHide: postingIdList.isHide ? false : true,
    };
    try {
      const res = await dispatch(toggleHide(param)).unwrap();
      if (res.data.ok) {
        const getOnePosting = async () => {
          let postReq = {
            postingId: postingIdList.id,
            userId: loginInfo?.adminId,
          };
          await dispatch(getAllRepliesOfOnePosting(postReq)).unwrap();
        };
        getOnePosting();
        dispatch(toggleCommuHide(postingIdList.isHide ? false : true));
      } else {
        console.log(res, ">>res data false");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageFn = useCallback(
    (page) => {
      dispatch(handlePageChangeCommu(page));
      const slicePages =
        postingIdReplies !== undefined &&
        postingIdReplies.slice(
          (page - 1) * pageReplyCount,
          page * pageReplyCount
        );
      dispatch(handleReplySliceArr(slicePages));
    },
    [dispatch, handlePageChangeCommu, handleReplySliceArr]
  );

  const communityToggle = () => {
    return (
      <div className="flex justify-end ">
        <div className="flex gap-x-2">
          <ToggleBtn
            isOn={postingIdisHideToggle}
            onToggle={onToggle}
            title={"게시글 비공개 처리"}
          />
        </div>
      </div>
    );
  };

  const communityReportLike = () => {
    const reportcss = "flex flex-row items-center gap-[4px] h-[19px]";
    const repeatDV = (icon, iconalt, title, contnum) => {
      return (
        <div
          className={reportcss}
          onClick={() => (title === "신고" ? setReportOpen(true) : null)}
        >
          {icon && <img src={icon} alt={iconalt} />}
          <p>
            {title} {contnum}
          </p>
        </div>
      );
    };
    return (
      <div className="flex w-1/3 flex-row justify-end gap-[18px]">
        {repeatDV(
          report_icon,
          "report_icon",
          "신고",
          postingIdList?.report && postingIdList?.report?.length
        )}
        {repeatDV(heart, "heart", "", postingIdList?.likeCount)}
        {repeatDV(undefined, "", "댓글", postingIdList?.replyCount)}
      </div>
    );
  };

  const communitiyHash = () => {
    return (
      <>
        {!(
          postingIdList?.keyword === null ||
          postingIdList?.keyword === undefined ||
          postingIdList?.keyword?.length === 0
        ) && (
          <div className="flex flex-wrap gap-[6px] pb-[16px] pt-[32px]">
            {postingIdList?.keyword?.map((el, idx) => (
              <span
                key={idx}
                className={
                  "caption_1 flex flex-wrap items-center justify-center rounded-[24px] border border-[#FDD2C3] bg-[#fff4f0] px-[16px] py-[8px] text-[#746F6A]"
                }
              >
                #{el}
              </span>
            ))}
          </div>
        )}
      </>
    );
  };

  const ifFileNo = (AbstractList) => {
    return (
      AbstractList?.files === null ||
      AbstractList?.files === undefined ||
      AbstractList?.files?.length === 0 ||
      AbstractList?.files[0] === ""
    );
  };

  const showPhoto = (lists) => {
    return (
      <div className="isolatd relative mb-[32px] mt-[16px] flex h-[255px] flex-row items-center justify-center rounded-[8px] bg-[#F3F3F2] p-[12px] py-[19px]">
        <div>
          <Carousel
            infiniteLoop
            showArrows
            autoPlay
            showStatus={false}
            showIndicators
            showThumbs={false}
          >
            {lists.files?.map((e, idx) => (
              <div key={idx}>
                <img
                  className=" max-h-[255px] object-contain"
                  src={e}
                  alt="사진"
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    );
  };

  const CancerGPTINFO = () => {
    const subtabName = ["질문&답변", "회원정보"];
    const commutTabcss =
      " rounded-t-xl py-2 px-4 font-semibold cursor-pointer ";
    const commuBodyCss =
      " p-4 bg-gray-100 rounded-b-xl flex flex-col gap-y-2 whitespace-pre-wrap ";
    const qnaLists = [
      {
        label: "질문타입",
        content: questionTypeOption[cancerGPTEeach?.questionType],
      },
      { label: "질문", content: cancerGPTEeach?.question },
      { label: "질문요약", content: cancerGPTEeach?.questionSummary },
      { label: "답변", content: cancerGPTEeach?.answer },
    ];
    const userInfoLists = [
      { label: "전화번호", content: cancerGPTEeach?.userId },
      { label: "치료여부", content: cancerGPTEeach?.anticancerTreatmentYN },
      {
        label: "나이",
        content: dayjs().format("YYYY") - cancerGPTEeach?.birth,
      },
      { label: "암종류", content: cancerGPTEeach?.cancerType },
      { label: "암기수", content: cancerGPTEeach?.cancerStaging },
      {
        label: "성별",
        content: cancerGPTEeach?.gender === "M" ? "남성" : "여성",
      },
      { label: "키", content: cancerGPTEeach?.height + "cm" },
      { label: "몸무게", content: cancerGPTEeach?.weight + "kg" },
      {
        label: "수술명",
        content: cancerGPTEeach?.operationName
          ? cancerGPTEeach?.operationName
          : "모름",
      },
      { label: "수술여부", content: cancerGPTEeach?.operationYN },
    ];
    const bodyLists = (allLists) => {
      return (
        <ul className={commuBodyCss}>
          {allLists.map((el, idx) => (
            <li key={idx}>
              {el.label + " | "}
              {el?.content}
            </li>
          ))}
        </ul>
      );
    };
    return (
      <>
        <ul className="flex pt-4">
          {subtabName?.map((el, idx) => (
            <li
              key={idx}
              onClick={() => {
                setSubTab(el);
              }}
              className={
                commutTabcss +
                (el === subTab
                  ? " bg-gray-100 text-black "
                  : " bg-gray-300 text-gray_140 ")
              }
            >
              {el}
            </li>
          ))}
        </ul>
        {subTab === "질문&답변"
          ? bodyLists(qnaLists)
          : bodyLists(userInfoLists)}
      </>
    );
  };

  const PhotoPopupBtn = (requestDBEach) => {
    return (
      <div className="flex w-full justify-end">
        <a
          target="_blank"
          href={requestDBEach?.files[0]}
          download
          className={`hover:bg-orange_110 rounded-[4px] bg-orange_100 p-3 text-center text-caption1_600 text-gray_10 `}
        >
          사진보기
        </a>
      </div>
    );
  };
  const toastRepeat = (ment) => {
    toast(<p>{ment}</p>, toastCommonProps("top-right", "toast_alert", 1000));
  };

  const onClickSearch = useCallback(
    async (pageNum, toggleCommunity) => {
      dispatch(handlePageChange(pageNum));
      let params = {
        page: pageNum,
        countPerPage: pagePerList,
        writerUserId: null,
        title: null,
        content: null,
        isReport: toggleCommunity,
      };
      const res = await dispatch(searchManagementCommunity(params)).unwrap();
      if (res.ok) {
        const defaultarr = Array(res.totalPage)
          .fill()
          ?.map((v, i) => {
            return i + 1;
          });
        if (pagePerList >= pageNum) {
          //1~10사이일경우 pagePerList 10
          dispatch(setPageArr(defaultarr.slice(0, pagePerList)));
        } else {
          //11~
          dispatch(
            setPageArr(
              defaultarr.slice(
                (Math.ceil(pageNum / pagePerList) - 1) * pagePerList,
                Math.ceil(pageNum / pagePerList) * pagePerList
              )
            )
          );
        }
      }
    },
    [dispatch, pagePerList, setPageArr]
  );

  const onClickEdit = () => {
    showEachNoticeModal();
    // setTimeout(() => {
    //   showWriteModal();
    // }, 500);
    dispatch(onClickGetEditCommunityPosting(postingIdList));
    showWriteModal();
  };
  const onClickDelete = async () => {
    let param = { id: postingIdList?.id };
    try {
      const res = await dispatch(deletePostings(param)).unwrap();
      if (res.data.ok) {
        toastRepeat("해당 글이 삭제되었습니다.");
        showEachNoticeModal();
        if (tabName === "소식") {
          onClickSearch(1, false);
        } else {
          dispatch(getNotice());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterDate = (date) => {
    const resultDate = new Date(date)
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/");

    return `${resultDate[2]}/${resultDate[0]}/${resultDate[1]}`;
  };

  useEffect(() => {
    handlePageFn(1);
  }, []);

  return !reportOpen ? (
    <>
      <div className="p-[24px] ">
        {(tabName === "소식" || tabName === "공지사항") && communityToggle()}
        <div className="flex flex-row items-start justify-between gap-[4px] pt-[16px]">
          <div className="flex w-2/3 flex-col items-start gap-[16px]">
            <div className="flew gap-[4px]">
              <p className="text-body_1 font-semibold text-black">
                {tabName === "소식" || tabName === "공지사항"
                  ? postingIdList?.title
                  : tabName === "음식DB요청"
                  ? requestDBEach?.title
                  : cancerGPTEeach?.title}
              </p>
            </div>
            <div className="flex items-center gap-x-[12px]">
              <p className="text-body_1 font-semibold text-black">
                {tabName === "소식" || tabName === "공지사항"
                  ? communityUserId
                  : tabName === "음식DB요청"
                  ? requestDBEach?.nickName
                  : cancerGPTEeach?.nickName}
              </p>
              {tabName === "CancerGPT" && cancerGPTEeach?.nickNameProtector && (
                <p className="text-body_1 font-semibold text-black">
                  {"(보호자: " + cancerGPTEeach?.nickNameProtector + ")"}
                </p>
              )}
              <p className="flex h-[19px] items-center text-sm font-normal not-italic text-[#463F38] ">
                {tabName === "소식" || tabName === "공지사항"
                  ? updatedDayJS(postingIdList?.createdAt)
                  : updatedDayJS(requestDBEach?.createdAt)}
              </p>
            </div>
          </div>
          {tabName === "소식" && communityReportLike()}
        </div>
        {(tabName === "소식" || tabName === "공지사항") &&
        !ifFileNo(postingIdList)
          ? showPhoto(postingIdList)
          : tabName === "음식DB요청" && !ifFileNo(requestDBEach)
          ? showPhoto(requestDBEach)
          : ""}
        {tabName === "CancerGPT" ? (
          CancerGPTINFO()
        ) : (
          <p className="text-body_2 flex	items-center whitespace-pre-wrap py-[16px] text-black">
            {tabName === "소식" || tabName === "공지사항"
              ? postingIdList?.content
              : requestDBEach?.content}
          </p>
        )}
        {(tabName === "소식" || tabName === "공지사항") && (
          <ImageClick postings={postingIdList} />
        )}
        {(tabName === "소식" || tabName === "공지사항") &&
          postingIdList?.writerAdmin?.name && (
            <div className="flex justify-end gap-x-4">
              <BorderButtonCustom
                onClick={onClickEdit}
                content={"수정"}
                bgcolor={""}
                textcolor={"text-orange_100"}
                bordercolor={"border-orange_100"}
                textsize={"text-caption1_600"}
                xpadding={"12px"}
              />
              <BGButtonCustom
                onClick={onClickDelete}
                content={"삭제"}
                bgcolor={"bg-orange_100"}
                textcolor={"text-white"}
                textsize={"text-caption1_600"}
                xpadding={"12px"}
              />
            </div>
          )}
        {tabName === "음식DB요청" && <ImageClick postings={requestDBEach} />}
        {tabName === "소식" && communitiyHash()}
      </div>
      {tabName === "소식" && (
        <>
          <div className="h-2 bg-[#F3F3F2] "></div>
          <Comment />
        </>
      )}
    </>
  ) : (
    <>
      <div className="mx-auto flex w-full max-w-3xl flex-col rounded-lg bg-white p-6 shadow-md">
        <div className="flex justify-between">
          <h2 className="flex items-center text-xl font-semibold text-gray-700">
            신고 목록
          </h2>
          <BGButtonCustom
            onClick={() => setReportOpen(false)}
            content={"돌아가기"}
            bgcolor={"bg-orange_100"}
            textcolor={"text-white"}
            textsize={"text-caption1_600"}
            xpadding={"12px"}
          />
        </div>
        {/* <!-- Report Item --> */}
        {postingIdList.report.map((reportArr, index) => (
          <div key={index} className="mt-4 border-t border-gray-300 pt-4">
            <div>
              <span className="text-caption1_600 text-gray-600">
                신고자 이름:{" "}
              </span>
              <span className="text-body3_400"> {reportArr.nickName} </span>
            </div>

            <div>
              <span className="text-caption1_600 text-gray-600">
                신고 날짜:{" "}
              </span>
              <span className="text-body3_400">
                {filterDate(reportArr.createdAt)}
              </span>
            </div>

            <div>
              <span>
                <strong>신고 내용: </strong>
              </span>
              <span className="text-body3_400">
                {reportArr.reportingReason}
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default NewsModalContent;
