import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { onSetSection } from "../../../../features/admin/commonSlice";
import { onClickManagementDetailState } from "../../../../features/admin/managementSlice";

const UserSimple = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memberobj = useSelector(
    (state) => state.management.mangementDetailState
  );
  const { userSimple } = useSelector((state) => state.management);

  const userSimpleList = [
    userSimple.treatment
      ? `${userSimple.gender}, ${userSimple.age}세 (${userSimple.treatment})`
      : `${userSimple.gender}, ${userSimple.age}세`,
    `암종: ${userSimple.cancerType ? userSimple.cancerType : "해당사항 없음"}`,
    `병기: ${
      userSimple.cancerStaging ? userSimple.cancerStaging : "해당사항 없음"
    }`,
    userSimple.operationYN
      ? `수술여부: ${userSimple.operationYN}/${
          userSimple.operationName ? userSimple.operationName : "해당사항 없음"
        }`
      : `수술여부: 해당사항 없음`,
    `주차: ${
      userSimple.operationWeek ? userSimple.operationWeek : "해당사항 없음"
    }`,
    `상식/연식: ${userSimple.eatType}`,
  ];

  return (
    <div className="flex justify-between p-6 shadow bg-gray_10 rounded-3xl">
      <div className="flex">
        <div className="flex-shrink-0 pr-4 font-semibold text-black text-body3_600">
          {userSimple.name}
        </div>
        <ul className="flex flex-wrap items-center divide-x">
          {userSimpleList?.map((el, idx) => (
            <li
              className="flex-shrink-0 px-4 font-semibold text-caption1_600 text-gray_100"
              key={idx}
            >
              {el}
            </li>
          ))}
        </ul>
      </div>
      <div
        className="flex items-center flex-shrink-0 gap-2 cursor-pointer"
        onClick={() => {
          navigate("/admin/account/memberlist");
          dispatch(onSetSection("계정 관리"));
          dispatch(onClickManagementDetailState(memberobj));
        }}
      >
        <span className="text-black text-caption2_400 ">자세히 보기</span>
        <img
          className="w-4 h-4"
          src="/images/admin/icon/next_arrow.svg"
          alt="더보기 아이콘"
        />
      </div>
    </div>
  );
};

export default UserSimple;
