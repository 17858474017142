import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addNotice,
  addPostingAdmin,
  editNotice,
  editPostingCommunity,
  getAllRepliesOfOnePosting,
  getNotice,
  onClickNewWrite,
  searchManagementCommunity,
} from "../../../../features/admin/communitySlice";
import {
  BGButtonCustom,
  BorderButtonCustom,
} from "../../components/common/ButtonCustom";
import CustomCenterModal from "../../components/common/CustomCenterModal";
import PhotoComp from "../../components/common/PhotoComp";
import toastCommonProps from "../../../../common/toast";
import PhotoCompRemain from "../../components/common/PhotoCompRemain";
import { resizeFile } from "../../../../common/util/base";
export const RepeatLabel = ({ title, children }) => {
  return (
    <div
      className={
        "flex py-[4px]" +
        (title === "제목" || title === "내용" || title === "이미지"
          ? " items-start "
          : " items-center ")
      }
    >
      <span
        className={
          "inline-block " + (title === "이미지" ? " w-1/5 " : " w-1/4 ")
        }
      >
        {title}
      </span>
      {children}
    </div>
  );
};

const BoardWriteModal = ({ showWriteModal }) => {
  const { loginInfo } = useSelector((state) => state.adminLogin);
  const closesvg = "/images/admin/icon/close.svg";

  const dispatch = useDispatch();
  const noticeRef = useRef(false);
  const eventRef = useRef(false);
  const newsRef = useRef(false);

  const alarmRef = useRef(false);
  const isHideRef = useRef(false);
  const fixOnTopRef = useRef(false);
  const titleRef = useRef("");
  const contentRef = useRef("");
  const { pagePerList } = useSelector((state) => state.pagination);
  const [commuInfo, setCommuInfo] = useState({
    writerAdminAdminId: loginInfo?.adminId,
    title: "",
    content: "",
    files: [],
    remainingFiles: [],
  });

  const [loadedImage, setLoadedImage] = useState([]);
  const tabName = useSelector((state) => state.commu.clickCommuTab);
  const { editCommunityPosting, newWrite } = useSelector(
    (state) => state.commu
  );
  const isButtonOn = !(
    titleRef.current?.value === "" ||
    contentRef.current?.value === "" ||
    titleRef.current.value === undefined ||
    contentRef.current.value === undefined
  );

  const RepeatRes = (resdata, toasttxt, tabName) => {
    try {
      if (resdata.data.ok) {
        toast(
          <p>{toasttxt}</p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
        showWriteModal();
        dispatch(onClickNewWrite(false)); //이 함수 안부르면 새글쓰기
        if (tabName === "소식") {
          let params = {
            page: 1,
            countPerPage: pagePerList,
            writerUserId: "",
            title: "",
            content: "",
          };
          dispatch(searchManagementCommunity(params));
        } else {
          dispatch(getNotice());
        }
      } else {
        console.log(resdata);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitData = async () => {
    const commonRefParam = {
      sendingAlarm: alarmRef.current.checked,
      isHide: isHideRef.current.checked,
    };
    const addRefParam = {
      notice: noticeRef.current.checked,
      event: eventRef.current.checked,
      news: newsRef.current.checked,

      fixOnTop: fixOnTopRef.current.checked,
    };
    const RefsParam = Object.assign(commonRefParam, addRefParam);
    const commuExceptId = Object.assign(commuInfo, RefsParam);
    const noticeExceptId = Object.assign(commuInfo, commonRefParam);

    if (tabName === "소식") {
      if (newWrite) {
        //새글
        const res = await dispatch(addPostingAdmin(commuExceptId)).unwrap();
        RepeatRes(res, "저장되었습니다. ", tabName);
      } else {
        //수정
        if (editCommunityPosting) {
          const editCommuinfo = {
            ...commuExceptId,
            id: editCommunityPosting.id,
          };
          const res = await dispatch(
            editPostingCommunity(editCommuinfo)
          ).unwrap();
          RepeatRes(res, "수정되었습니다. ", tabName);
        }
      }
    } else {
      //공지사항

      if (newWrite) {
        //새글
        const res = await dispatch(addNotice(noticeExceptId)).unwrap();
        RepeatRes(res, "저장되었습니다. ", tabName);
        console.log(noticeExceptId, "저장 정보");
      } else {
        //수정
        const editNoticeinfo = {
          ...noticeExceptId,
          id: editCommunityPosting.id,
        };
        console.log(editNoticeinfo, "수정 정보");
        const res = await dispatch(editNotice(editNoticeinfo)).unwrap();
        RepeatRes(res, "수정되었습니다. ", tabName);
      }
    }
  };
  const onChangeInfo = (e) => {
    const { name, value } = e.target;
    setCommuInfo({ ...commuInfo, [name]: value });
  };

  const checkNotice = () => {
    setCommuInfo({ ...commuInfo, notice: noticeRef.current.checked });
  };
  const checkEvent = () => {
    setCommuInfo({ ...commuInfo, event: eventRef.current.checked });
  };
  const checkNews = () => {
    setCommuInfo({ ...commuInfo, news: newsRef.current.checked });
  };
  const checkAlarm = () => {
    if (isHideRef.current.checked) {
      alarmRef.current.checked = false;
    }
    setCommuInfo({ ...commuInfo, sendingAlarm: alarmRef.current.checked });
  };
  const checkIsHide = () => {
    setCommuInfo({ ...commuInfo, isHide: isHideRef.current.checked });
    alarmRef.current.checked = false;
  };
  const checkFixOnTop = () => {
    setCommuInfo({ ...commuInfo, fixOnTop: fixOnTopRef.current.checked });
  };

  const deleteImage = (image, no) => {
    setLoadedImage([...loadedImage?.filter((el) => el !== image)]);
    commuInfo.files.splice(no, 1);
    setCommuInfo({ ...commuInfo, files: commuInfo.files });
  };
  const onChangeFile = async (e) => {
    if (
      e.target.files?.length +
        commuInfo?.remainingFiles?.length +
        loadedImage?.length >
      5
    ) {
      alert("파일을 최대 5개 선택해주세요.");
    } else {
      let fileUrls = [];
      let cameraArr = [];

      for (let i = 0; i < e.target.files?.length; i++) {
        let reader = new FileReader();
        let resize = await resizeFile(e.target.files[i]);
        reader.readAsDataURL(resize);
        cameraArr.push(resize);
        setCommuInfo({
          ...commuInfo,
          files: commuInfo.files.concat([...cameraArr]),
        });
        reader.onload = async () => {
          fileUrls[i] = reader.result;
          loadedImage?.length > 0
            ? setLoadedImage(loadedImage.concat([...fileUrls]))
            : setLoadedImage([...fileUrls]);
        };
      }
      e.target.value = "";
    }
  };

  const checkboxList = (title, inputName, refName, onClickFunciton, label) => {
    return (
      <RepeatLabel title={title}>
        <div className="flex w-full items-center ">
          <input
            name={inputName}
            ref={refName}
            onClick={onClickFunciton}
            type="checkbox"
            className=" m-[6px] h-[18px] w-[18px]"
          />
          <span className="flex h-[24px] flex-row items-center justify-center rounded-[2px]  py-[2px] text-base">
            {label}
          </span>
        </div>
      </RepeatLabel>
    );
  };
  const checboxLabel = (
    refName,
    inputName,
    onClickFunction,
    labelBG,
    labelName
  ) => {
    return (
      <div className="flex items-center">
        <input
          ref={refName}
          name={inputName}
          onClick={onClickFunction}
          type="checkbox"
          className=" m-[6px] h-[18px] w-[18px]"
        />
        <span
          className={
            "mr-[10px] flex h-[20px] w-[33px] flex-row items-center justify-center rounded-[2px] px-[6px] py-[2px] text-xs text-white" +
            labelBG
          }
        >
          {labelName}
        </span>
      </div>
    );
  };

  const repeatRes = useCallback(
    (res) => {
      const readyPosting = res.posting;
      if (res.ok) {
        setCommuInfo({
          writerAdminAdminId: res.posting?.writerAdmin.adminId,
          title: res.posting?.title,
          content: res.posting?.content,
          files: [],
          remainingFiles: res.posting?.files,
        });
        if (tabName === "소식") {
          noticeRef.current.checked = readyPosting?.notice;
          eventRef.current.checked = readyPosting?.event;
          newsRef.current.checked = readyPosting?.news;

          fixOnTopRef.current.checked = readyPosting?.fixOnTop;
        }
        isHideRef.current.checked = readyPosting?.isHide;
        alarmRef.current.checked = readyPosting?.sendingAlarm;
      }
    },
    [setCommuInfo]
  );
  const getPostingwithId = useCallback(async () => {
    let param = { postingId: editCommunityPosting.id };
    const res = await dispatch(getAllRepliesOfOnePosting(param)).unwrap();
    repeatRes(res);
  }, []);

  useEffect(() => {
    if (newWrite) {
      //새로운글
      setCommuInfo({
        writerAdminAdminId: loginInfo?.adminId,
        title: "",
        content: "",
        files: [],
        remainingFiles: [],
      });
      if (tabName === "소식") {
        noticeRef.current.checked = false;
        eventRef.current.checked = false;
        newsRef.current.checked = false;

        fixOnTopRef.current.checked = false;
      }
      isHideRef.current.checked = false;
      alarmRef.current.checked = false;
    } else {
      //수정
      if (editCommunityPosting) {
        getPostingwithId();
      }
    }
  }, []);
  return (
    <CustomCenterModal
      onClickFc={() => {
        showWriteModal();
        dispatch(onClickNewWrite(false));
      }}
      customlayout={
        "absolute top-1/2 left-1/2 transform-50 bg-white rounded-xl w-[800px] z-30 overflow-hidden"
      }
    >
      <div className="h-[700px] overflow-y-auto px-[32px] ">
        <div className="absolute left-0 top-0 z-30 flex w-full items-center justify-between rounded-t-[8px] border-b  border-gray_80 bg-white p-[8px]">
          <span className="text-subtitle_1 block flex items-center px-[24px] font-bold text-black">
            소식 공지 작성하기
          </span>
          <img
            src={closesvg}
            alt="close"
            className="cursor-pointer"
            onClick={() => {
              showWriteModal();
              dispatch(onClickNewWrite(false));
            }}
          />
        </div>
        <div className="communityCheckbox modal_cont keepall mt-[48px] pb-[32px] text-caption1_600 ">
          <div className="flex flex-col gap-y-[8px] pt-[52px]">
            {tabName === "소식" && (
              <>
                <RepeatLabel title="뱃지 선택">
                  <div className="flex h-[24px] w-full flex-row items-center gap-[4px]">
                    {checboxLabel(
                      noticeRef,
                      "notice",
                      checkNotice,
                      " bg-[#61BE82] ",
                      "공지"
                    )}
                    {checboxLabel(
                      eventRef,
                      "event",
                      checkEvent,
                      " bg-[#FFE9A0] ",
                      "이벤트"
                    )}
                    {checboxLabel(
                      newsRef,
                      "news",
                      checkNews,
                      " bg-[#FF7F00] ",
                      "소식"
                    )}
                  </div>
                </RepeatLabel>
                {checkboxList(
                  "상단 고정",
                  "fixOnTop",
                  fixOnTopRef,
                  checkFixOnTop,
                  "상단에 고정하기"
                )}
              </>
            )}

            {checkboxList(
              "알림 여부",
              "sendingAlarm",
              alarmRef,
              checkAlarm,
              "유저에게 알림보내기(비공개 해제시 선택가능)"
            )}
            {checkboxList(
              "공개여부",
              "isHide",
              isHideRef,
              checkIsHide,
              "게시글 비공개로 전환"
            )}
            <RepeatLabel title="제목">
              <input
                name="title"
                ref={titleRef}
                value={commuInfo.title}
                onChange={onChangeInfo}
                className="w-full rounded-[4px] border border-[#A39F9B] bg-transparent px-[20px] py-[12px] focus:outline-none"
                placeholder="제목을 입력하세요.(최대 40자)"
                maxLength="40"
              />
            </RepeatLabel>
            <RepeatLabel title="내용">
              <textarea
                value={commuInfo.content}
                ref={contentRef}
                name="content"
                onChange={onChangeInfo}
                className="h-[324px] w-full rounded-[4px] border border-[#A39F9B] bg-transparent px-[20px] py-[12px] focus:outline-none"
                placeholder="내용을 입력하세요."
              />
            </RepeatLabel>
            <RepeatLabel title="이미지">
              <div className="flex gap-x-2">
                <PhotoComp
                  onChangeFile={onChangeFile}
                  deleteImage={deleteImage}
                  loadedImage={loadedImage}
                />
                {commuInfo.remainingFiles?.length > 0 ? (
                  <PhotoCompRemain
                    editData={commuInfo}
                    setEditData={setCommuInfo}
                    loadedImage={loadedImage}
                    setLoadedImage={setLoadedImage}
                  />
                ) : (
                  ""
                )}
              </div>
            </RepeatLabel>
            <div className="flex flex w-full items-end justify-end gap-[8px]">
              <BorderButtonCustom
                onClick={showWriteModal}
                content={"취소"}
                bgcolor={""}
                textcolor={"text-orange_100"}
                bordercolor={"border-orange_100"}
                textsize={"text-caption1_600"}
                xpadding={"12px"}
              />
              <BGButtonCustom
                onClick={isButtonOn ? submitData : undefined}
                content={"저장"}
                bgcolor={isButtonOn ? "bg-orange_100" : "bg-orange_80"}
                textcolor={"text-gray_10"}
                textsize={"text-caption1_600"}
                xpadding={"12px"}
              />
            </div>
          </div>
        </div>
      </div>
    </CustomCenterModal>
  );
};

export default BoardWriteModal;
