import React from "react";
import { appDownload } from "./SixthPart";

const FirstPart = () => {
  return (
    <section>
      <div
        className="relative flex h-screen w-screen items-center xl:h-[1080px]"
        style={{
          backgroundImage: "url('/images/mobile/landing/landing_main1-a.png')",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div
          className="flex h-full translate-y-[-86px] flex-col justify-center sm:mx-[120px] sm:max-w-xs lg:max-w-md xl:ml-[150px] xl:max-w-full 
        2xl:ml-[200px] "
        >
          <p className="text-gray_200 sm:text-[25px] lg:text-4xl lg:leading-[50px] 2xl:text-[64px] 2xl:font-medium 2xl:leading-[92px]">
            <strong className="2xl:font-extrabold">암환자의 일상</strong>을
            변화시켜 줄
            <br />
            든든한 동반자
          </p>
          <h1 className="mt-3 font-extrabold text-main sm:text-[38px] lg:text-6xl 2xl:text-8xl">
            힐리어리
          </h1>
          <div className="max-w-1/3 flex flex-row items-start gap-4 sm:mt-7 xl:mt-14">
            <button
              onClick={appDownload}
              className="flex items-center justify-around gap-3 rounded-full bg-[#6C9CFF] px-5 py-3 text-base font-bold tracking-wide text-white hover:bg-main xl:gap-6 xl:px-12 xl:py-5 xl:text-2xl"
            >
              <img
                src="/images/mobile/landing/download.svg"
                alt="다운로드"
                className="w-8 pb-[1px]"
              />
              <span className="shrink-0">힐리어리 시작하기</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FirstPart;
