import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../components/common/Sidebar";
import Navbar from "../components/common/Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  onSetAccountOneInfo,
  onSetAccountTab,
} from "../../../features/admin/accountSlice";
import CommonTabs from "../components/common/CommonTabs";
import Manager from "./Account/table/Manager";
import Users from "./Account/table/Users";
import {
  fetchRecommendMeal,
  onClickManagementDetailState,
  onClickMember,
  searchManagementAdmins,
  searchManagementUsers,
} from "../../../features/admin/managementSlice";
import { handlePageChange } from "../../../features/admin/commonSlice";
import RecommendMealDialog from "../components/common/RecommendMealDialog";
import { searchHospital } from "../../../features/admin/hospitalSlice";
import { setPageArr } from "../../../features/admin/paginationSlice";
const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.adminLogin);

  const [openDialog, setOpenDialog] = useState(false);
  const [hospitalList, setHospitalList] = useState([]);
  //이번주차 식사기록 및 추천식단보기 default 날짜계산
  let now = new Date();
  let nowDayOfWeek = now.getDay();
  let nowDay = now.getDate();
  let nowMonth = now.getMonth();
  let nowYear = now.getYear();
  nowYear += nowYear < 2000 ? 1900 : 0;
  let weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
  let weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
  function formatDate(date) {
    let mymonth = date.getMonth() + 1;
    let myweekday = date.getDate();
    return nowYear + "-" + mymonth + "-" + myweekday;
  }
  const [selected, setSelected] = useState({
    startDate: formatDate(weekStartDate),
    endDate: formatDate(weekEndDate),
  });
  const tabName = useSelector((state) => state.accountadmin.clickAccountTab);
  const { pagePerList } = useSelector((state) => state.pagination);
  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    countPerPage: pagePerList,
    searchNameOrID: "",
    loginedAdminID: loginInfo?.adminId,
    //adminType
    group: "",
    authorityType: "",
    //userType
    filterUserType: "",
    filterHospitalCode: "",
  });
  useEffect(() => {
    if (loginInfo?.adminId) {
      dispatch(handlePageChange(1));
      if (tabName === "") {
        dispatch(onSetAccountTab("회원"));
      } else {
        dispatch(onSetAccountTab(tabName));
      }
      onClickSearch(tabName, 1);
      //hospitalList 받기
      const fetchHosRes = async () => {
        let hosRes = await dispatch(searchHospital()).unwrap();
        if (hosRes.data.ok) {
          try {
            const newArr = [{ name: "전체", code: "" }];
            for (let i = 0; i < hosRes.data.hospitalList?.length; i++) {
              newArr.push({
                name: hosRes.data.hospitalList[i].name,
                code: hosRes.data.hospitalList[i].code,
              });
            }
            setHospitalList(newArr);
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log(hosRes.data.ok, ">>>hosRes");
        }
      };
      fetchHosRes();
    } else {
      navigate("/admin/signin");
    }
  }, [dispatch]);

  const onClickSearch = useCallback(
    async (tab, pageN) => {
      let commonparam = {
        page: pageN,
        countPerPage: pagePerList,
        searchNameOrID: filterInfo.searchNameOrID,
        loginedAdminID: loginInfo?.adminId,
      };
      let userparams = Object.assign(commonparam, {
        filterUserType: filterInfo.filterUserType,
        filterHospitalCode: filterInfo.filterHospitalCode,
      });
      let adminParams = Object.assign(commonparam, {
        group:
          filterInfo.group === "헤링스"
            ? "0"
            : filterInfo.group === "병원"
            ? "1"
            : "",
        authorityType:
          filterInfo.authorityType === "슈퍼관리자"
            ? "0"
            : filterInfo.authorityType === "일반관리자"
            ? "1"
            : filterInfo.authorityType === "대기"
            ? "2"
            : "",
      });
      if (tab === "관리자") {
        const res = await dispatch(
          searchManagementAdmins(adminParams)
        ).unwrap();
        repeatResult(pageN, res);
      } else {
        const res = await dispatch(searchManagementUsers(userparams)).unwrap();
        repeatResult(pageN, res);
      }
    },
    [filterInfo, dispatch, pagePerList]
  );

  const repeatResult = (pageN, res) => {
    if (res.ok) {
      const defaultarr = Array(res.totalPage)
        .fill()
        ?.map((v, i) => {
          return i + 1;
        });
      if (pagePerList >= pageN) {
        //1~10사이일경우 pagePerList 10
        dispatch(setPageArr(defaultarr.slice(0, pagePerList)));
      } else {
        //11~
        dispatch(
          setPageArr(
            defaultarr.slice(
              (Math.ceil(pageN / pagePerList) - 1) * pagePerList,
              Math.ceil(pageN / pagePerList) * pagePerList
            )
          )
        );
      }
    }
  };

  const onChangeNameUserId = (e) => {
    const { name, value } = e.target;
    if (name === "filterHospitalCode") {
      //select의 한글표기명을 코드로 변환
      let findHospitalOBJ = hospitalList?.filter((el) => el.name === value);
      filterInfo.filterHospitalCode = findHospitalOBJ[0].code;
    } else if (name === "group") {
      filterInfo.group = value;
    } else if (name === "authorityType") {
      filterInfo.authorityType = value;
    }
    onClickSearch(tabName, 1);
    dispatch(handlePageChange(1));
  };
  const onChangeInput = (e) => {
    const { value } = e.target;
    setFilterInfo({ ...filterInfo, searchNameOrID: value });
  };

  //테이블 파라미터로 넘겨 항목 클릭시 화면 이동
  const onMemberDetail = (member, e) => {
    //1.tabName 관리자일때
    if (tabName === "관리자") {
      dispatch(onSetAccountTab("관리자"));
      if (loginInfo?.adminId === member.adminId) {
        navigate("/admin/account/myaccount");
      } else {
        //1-1.승인후 관리자
        if (member.approval) {
          navigate("/admin/account/approv");
        } else {
          //1-2.승인전 관리자
          navigate("/admin/account/prevapprov");
        }
      }
      dispatch(onSetAccountOneInfo(member));
    } else {
      //2.tabName 회원일때
      if (e.target.innerText === "보기") {
        let params = {
          userId: member.userId,
          startDate: member.startDate,
          endDate: member.endDate,
        };
        dispatch(onClickMember(member));
        dispatch(fetchRecommendMeal(params));
        setOpenDialog(true);
        setSelected({
          startDate: formatDate(weekStartDate),
          endDate: formatDate(weekEndDate),
        });
      } else {
        navigate("/admin/account/memberlist");
        dispatch(onClickManagementDetailState(member));
      }
    }
  };
  const onCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      {openDialog && (
        <RecommendMealDialog
          onCloseDialog={onCloseDialog}
          selected={selected}
          setSelected={setSelected}
        />
      )}
      <Sidebar user={loginInfo} />
      <div className="flex-1 overflow-y-auto">
        <Navbar user={loginInfo} />
        <div className="flex h-full w-auto min-w-min flex-col items-start justify-start gap-[16px] p-[24px]">
          <div className="flex flex-col items-start justify-start w-full p-0 ">
            <CommonTabs
              user={loginInfo}
              filterInfo={filterInfo}
              tabName={tabName}
              onClickSearch={onClickSearch}
            />
            {tabName === "회원" ? (
              <Users
                user={loginInfo}
                pagePerList={pagePerList}
                onChangeNameUserId={onChangeNameUserId}
                onMemberDetail={onMemberDetail}
                filterInfo={filterInfo}
                setFilterInfo={setFilterInfo}
                onClickSearch={onClickSearch}
                tabName={tabName}
                hospitalList={hospitalList}
                onChangeInput={onChangeInput}
              />
            ) : (
              <Manager
                user={loginInfo}
                pagePerList={pagePerList}
                onChangeNameUserId={onChangeNameUserId}
                filterInfo={filterInfo}
                onMemberDetail={onMemberDetail}
                onClickSearch={onClickSearch}
                tabName={tabName}
                onChangeInput={onChangeInput}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
