import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CalendarHeader from "../components/common/CalendarHeader";
import Dialog from "../components/common/Dialog";
import HeadCont from "../components/common/HeadCont";
// import InputDialog from "../components/diary/InputDialog";
import NewToggle from "../components/common/NewToggle";
import {
  deleteSchedule,
  fetchDrugSchedule,
  fetchDScheduleInfo,
  modifyDrugSchedule,
  saveDrugSchedule,
  scheduleCheck,
  fetchScheduleCalendar,
} from "../../../features/mobile/drugSlice";
import { toast } from "react-toastify";
import toastCommonProps from "../../../common/toast";
import { saveChosenDate } from "../../../features/mobile/commonSlice";

const DrugAlarm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  dayjs.locale("ko");
  const { loginInfo, chosenDate } = useSelector((state) => state.commonApp);
  const { existDrugOfYear, fetchDrugStatus, dayDrugLists } = useSelector(
    (state) => state.drug
  );

  // const [openCalendar, setOpenCalendar] = useState(false);
  // const [chosenDate, setChosenDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [dateStart, setDateStart] = useState(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [status, setStatus] = useState({ status: "show", id: null }); //show: 보여주기, add: 입력하기, edit: 수정하기
  const [openDialog, setOpenDialog] = useState(false);

  const dayArr = ["일", "월", "화", "수", "목", "금", "토"];

  const [checkDay, setCheckDay] = useState(["매일"]);

  // 복약 주기 노출 초기값
  const initCheckState = dayArr.reduce((state, day) => {
    state[day] = false;
    return state;
  }, {});

  // 복약 주기 노출 값
  const [check, setCheck] = useState(initCheckState);

  const [scheduleList, setScheduleList] = useState([]);
  const [alarmCheck, setAlarmCheck] = useState(
    dayDrugLists.reduce((acc, item) => {
      acc[item.id + "_" + item.n] = item.check;
      return acc;
    }, {})
  );

  const [alarmTimes, setAlarmTimes] = useState({
    times: ["08:00", "12:00", "18:00"],
    // times: [],
  });

  dayjs.locale("ko");

  // 초기 상태 정의
  const initialState = {
    userId: loginInfo.userId,
    name: "",
    start_day: dateStart,
    period: 1,
    number: 1,
    week: [true, true, true, true, true, true, true],
    hours: [8, 12, 18],
    minutes: [0, 0, 0],
    alarm: [false],
    memo: "",
  };
  // 저장값
  const [value, setValue] = useState({
    userId: loginInfo.userId,
    name: "", //알람명
    start_day: chosenDate || dateStart, //알람 시작 일
    period: 1, // 복용 일수
    number: 1, // 일일 복용 횟수
    week: [true, true, true, true, true, true, true], // 알람 주기
    hours: [8, 12, 18], // 시
    minutes: [0, 0, 0], // 분
    alarm: [false], // 알람 유무 및 일일 횟수
    memo: "", // 메모
    // cycle: ["매일"],
    // time: dayjs(new Date()).format("A HH:mm"),
  });
  const validateValue = () => {
    // 각 필드에 대한 검사 수행
    if (!value.userId || value.userId.trim() === "") {
      console.log("userId가 비어있습니다.");
      return false;
    }
    if (!value.name || value.name.trim() === "") {
      console.log("name이 비어있습니다.");
      toast(
        <p>알림명을 입력해 주세요.</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
      return false;
    }
    if (!value.start_day || value.start_day.trim() === "") {
      console.log("startDay가 비어있습니다.");
      toast(
        <p>시작일을 입력해 주세요.</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
      return false;
    }
    if (value.period > 180) {
      console.log("복약 일수 180일 초과");
      toast(
        <p>복약 일수는 180일까지 등록 가능합니다.</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
      return false;
    }
    if (!value.period || value.period === 0) {
      console.log("period가 비어있거나 0입니다.");
      toast(
        <p>복약 일수를 입력해 주세요.</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
      return false;
    }
    if (!value.number || value.number === 0) {
      console.log("number가 비어있거나 0입니다.");
      toast(
        <p>일일 복약 횟수를 입력해 주세요.</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
      return false;
    }
    if (!value.week || value.week.length === 0) {
      console.log("week가 비어있습니다.");
      toast(
        <p>복약 주기를 입력해 주세요.</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
      return false;
    }
    if (
      !value.hours ||
      !value.minutes ||
      alarmTimes.times.length === 0
      // ||
      // alarmTimes.times.length !== Number(value.number)
    ) {
      console.log("복약 알림 시간이 비어있습니다.");
      toast(
        <p>복약 알림 시간을 입력해 주세요.</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
      return false;
    }

    // 모든 검사를 통과한 경우
    if (status.status === "edit") {
      toast(
        <p>알림 수정완료</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
    } else {
      toast(
        <p>알림 추가완료</p>,
        toastCommonProps("bottom-center", "toast_alert", 1000)
      );
    }
    return true;
  };

  // 스케줄 리스트 가져오기
  useEffect(() => {
    window.scroll(0, 0);
    if (status.status === "show") {
      const params = {
        userId: loginInfo.userId,
        date: chosenDate ? chosenDate : dayjs(new Date()).format("YYYY-MM-DD"),
      };

      dispatch(fetchDrugSchedule(params))
        .unwrap()
        .then((res) => {
          if (res.ok) {
            console.log(res.list, "resresres");
            setScheduleList(res.list);
          } else {
            console.log("스케줄 가져오기 실패");
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, chosenDate, alarmCheck]);

  //해당 년도 캘린더 일정 가져오기
  useEffect(() => {
    dispatch(
      fetchScheduleCalendar({
        userId: loginInfo.userId,
        year: dayjs(dateStart).format("YYYY"),
      })
    );
  }, [dayjs(dateStart).format("YYYY"), chosenDate, status]);

  // * 복약 주기 요일 선택에 따른 문구 출력
  useEffect(() => {
    const sortDayArray = dayArr.map((day) => check[day]);
    console.log(sortDayArray, "sortDayArray");

    // 모든 요소가 false인 경우 defalut 매일
    if (sortDayArray.every((value) => value === false)) {
      //   setValue({ ...value, week: [] });
      setCheckDay(["매일"]);
      setValue({
        ...value,
        week: [true, true, true, true, true, true, true],
      });
      console.log("복약 주기 값이 비어있다!");
    } else if (sortDayArray.every((value) => value === true)) {
      // 일주일 전부 체크일때
      setCheckDay(["매일"]);
      setValue({ ...value, week: sortDayArray });
    } else if (
      sortDayArray[0] === true &&
      sortDayArray[6] === true &&
      sortDayArray.slice(1, 6).every((value) => value === false)
    ) {
      // 일,토 만 체크일 경우 (주말)
      setCheckDay(["주말"]);
      setValue({ ...value, week: sortDayArray });
    } else if (
      // 평일만 전부 체크일 경우 (주중)
      sortDayArray.slice(1, 6).every((value) => value === true) &&
      sortDayArray[0] === false &&
      sortDayArray[6] === false
    ) {
      setCheckDay(["주중"]);
      setValue({ ...value, week: sortDayArray });
    } else {
      const filterCheckDay = Object.keys(check).filter(
        (day) => check[day] === true
      );
      setCheckDay(filterCheckDay);
      setValue({ ...value, week: sortDayArray });
    }
  }, [check]);

  const dayCheck = (day) => {
    setCheck((prevChecks) => {
      // "매일"이 아닌 다른 요일을 선택하는 로직
      // "매일"이 선택되어 있지 않은 경우에만 다른 요일을 활성화
      if (!prevChecks["매일"]) {
        const updatedChecks = {
          ...prevChecks,
          [day]: !prevChecks[day],
        };

        // 업데이트된 checks를 사용하여 cycle 배열을 생성
        const trueDays = Object.keys(updatedChecks).filter(
          (day) => updatedChecks[day]
        );
        setCheckDay(trueDays);
        return updatedChecks;
      }

      return prevChecks;
    });
  };
  // 복약 일수 숫자만 입력 가능하게함
  const periodCheck = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "");

    // newValue가 빈 문자열이면, period를 빈 문자열로 설정
    // 그렇지 않으면, newValue를 숫자로 변환하여 period에 설정
    if (newValue === "") {
      setValue({ ...value, period: "" });
    } else {
      setValue({ ...value, period: Number(newValue) });
    }
  };

  const correctedTime = (time, index) => {
    setAlarmTimes((prevValue) => {
      // times 배열의 복사본을 생성
      const updatedTimes = [...prevValue.times];

      // 현재 인덱스에 새로운 시간 값을 설정
      updatedTimes[index] = time;

      // 업데이트된 times 배열로 상태를 설정
      return { ...prevValue, times: updatedTimes };
    });

    const timeString = String(time);
    const currentTime = dayjs();
    const timeParts = timeString.split(":");

    const correctedHours = currentTime.hour(timeParts[0]);
    const correctedMinutes = currentTime.minute(timeParts[1]);

    // 현재 입력된 인덱스에 대한 시간을 업데이트하고, 나머지는 기존 값 유지
    setValue((prevValue) => {
      const newHours = [...prevValue.hours];
      const newMinutes = [...prevValue.minutes];

      dayjs((newHours[index] = Number(correctedHours.format("HH"))));
      dayjs((newMinutes[index] = Number(correctedMinutes.format("mm"))));

      // hours 배열의 길이에 맞춰 alarm 배열을 생성하고, 모든 값을 true로 설정
      const newAlarm = newHours.map(() => true);

      return {
        ...prevValue,
        hours: newHours,
        minutes: newMinutes,
        alarm: newAlarm,
      };
    });
  };

  // 복약 주기(cycle) 값이 비어있는 경우
  //   useEffect(() => {
  //     if (value.week.length === 0) {
  //       setCheckDay(["매일"]);
  //       setValue({ ...value, week: [true, true, true, true, true, true, true] });
  //       console.log("복약 주기 값이 비어있다!");
  //     }
  //   }, [value]);

  //value 저장값 리셋
  const valueReset = () => {
    setValue(initialState);
    setDateStart(dayjs(new Date()).format("YYYY-MM-DD"));
    setAlarmTimes({ ...alarmTimes, times: ["08:00", "12:00", "18:00"] });
    setCheckDay(["매일"]);
    setCheck(initCheckState);
  };

  //   const handleChange = (startDay) => {
  //     setDateStart(startDay);
  //     setChosenDate(dayjs(startDay).format("YYYY-MM-DD"));
  //     let params = {
  //       userId: loginInfo.userId,
  //       standardDate: dayjs(dateStart).format("YYYY"),
  //     };
  //     fetchScheduleCalendar(params);
  //     setOpenCalendar(false);
  //   };

  // toggle 버튼
  const toggle = (index) => {
    if (alarmTimes.times.length !== 0) {
      setValue((prevValue) => {
        // alarm 배열의 복사본 생성
        const newAlarm = [...prevValue.alarm];

        // 현재 인덱스에 해당하는 alarm 값 토글
        newAlarm[index] = !newAlarm[index];

        return { ...prevValue, alarm: newAlarm };
      });
    }
  };

  // 시간:분 스트링 변환후 시간으로 포맷
  const timeIn12HourFormat = (time) => {
    let [hour, minute] = time.split(":").map(Number);
    const ampm = hour >= 12 ? "오후" : "오전";
    hour = hour % 12 || 12; // 0시는 12시(AM)으로, 12시 이상은 PM으로 표시
    return `${ampm} ${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")} `;
  };

  // 시간과 분을 조합하여 "HH:MM" 형식의 문자열 배열을 생성
  const combinedTimes = (hours, minutes) => {
    // const hours = [12, 13, 14];
    // const minutes = [10, 20, 30];
    // "HH:MM" 형식의 문자열 배열 생성
    const editTimes = hours.map((hour, index) => {
      const minute = minutes[index];
      return `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
    });
    // 생성된 배열로 상태 업데이트
    setAlarmTimes({ ...alarmTimes, times: editTimes });
  };

  // ! 알람스케줄 등록 api
  const upLoadAlarm = async () => {
    // 검증 함수 호출
    if (!validateValue()) {
      console.log("유효하지 않은 입력입니다.");

      return; // 유효하지 않으면 함수 종료
    }
    const params = {
      userId: loginInfo.userId,
      name: value.name, //알람명
      start_day: chosenDate || dateStart, //알람 시작 일
      period: value.period, // 복용 일수
      number: value.number, // 일일 복용 횟수
      week: value.week, // 알람 주기
      hours: value.hours.slice(0, value.number), // 시
      minutes: value.minutes.slice(0, value.number), // 분
      alarm: value.alarm.slice(0, value.number), // 알람 유무 및 일일 횟수
      memo: value.memo,
    };
    await dispatch(saveDrugSchedule(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          console.log(res, "응답완료");
          valueReset();
          // dispatch(
          //   fetchScheduleCalendar({
          //     userId: loginInfo.userId,
          //     year: dayjs(dateStart).format("YYYY"),
          //   })
          // );
        } else {
          console.log(res, "응답실패?");
        }
      });
    setStatus({ ...status, status: "show" });
  };
  // 알람스케줄 상세 정보 api
  const fetchScheduleInfo = async (id) => {
    try {
      const res = await dispatch(fetchDScheduleInfo(id)).unwrap();

      if (res) {
        const checkDaycall = dayArr.reduce((state, day, index) => {
          state[day] = res.info.week[index];
          return state;
        }, {});
        setValue(res.info);
        combinedTimes(res.info.hours, res.info.minutes);
        setCheck(checkDaycall);
      } else {
        console.log("수정 페이지 데이터 불러오기 실패!");
      }
    } catch (error) {
      console.error("수정 작업 중 오류 발생: ", error);
    }
  };

  // 알람스케줄 수정 api
  const modifySchedule = async (id) => {
    // 검증 함수 호출
    if (!validateValue()) {
      console.log("유효하지 않은 입력입니다.");

      return; // 유효하지 않으면 함수 종료
    }
    await dispatch(modifyDrugSchedule(value))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          console.log(res, "수정 응답완료");
          valueReset();
        } else {
          console.log(res, "수정 응답실패?");
        }
      });
    setStatus({ ...status, status: "show" });
  };

  // 알람스케줄 삭제 api
  const deleteAlarmSchedule = async (id) => {
    try {
      const res = await dispatch(deleteSchedule(id)).unwrap();

      if (res) {
        console.log(res, "deleteAlarmSchedule res");
        valueReset();

        setStatus({ ...status, status: "show" });
      } else {
        console.log("데이터 삭제하기 실패!");
      }
    } catch (error) {
      console.error("삭제 작업 중 오류 발생: ", error);
    }
    setOpenDialog(false);
  };

  // 알람스케줄 완료 여부 체크
  const checkSchedule = async (id, n) => {
    const key = id + "_" + n;
    const updatedCheck = !alarmCheck[key]; // 업데이트 될 상태를 미리 계산

    console.log(alarmCheck, "alarmCheck");
    console.log(updatedCheck, "updatedCheck");

    const params = {
      userId: loginInfo.userId,
      id: id,
      date: chosenDate,
      n: n,
      check: updatedCheck,
    };
    await dispatch(scheduleCheck(params))
      .unwrap()
      .then((res) => {
        if (res.ok) {
          console.log(res, "Checkres");
          console.log("스케줄 체크 완료");
          setAlarmCheck((prevAlarmCheck) => ({
            ...prevAlarmCheck,
            [key]: updatedCheck, // 계산된 값으로 상태 업데이트
          }));
        } else {
          console.log("스케줄 체크 실패");
        }
      });
  };

  return (
    <>
      {status.status === "show" ? (
        <>
          <HeadCont
            title={"복약알림"}
            transparent={"white"}
            underline={true}
            onLeftAction={() => navigate(-1)}
            onRightAction={() => navigate("/home")}
            rightIcon={"home"}
          />

          <CalendarHeader
            type={"복약알림"}
            startDate={dateStart}
            setStartDate={setDateStart}
            chosenDate={chosenDate}
            // setChosenDate={setChosenDate}
            existDaysOfYear={[...existDrugOfYear]}
          />
          <div className="-mt-[28px] rounded-tl-[28px] bg-white p-6">
            <div className="pt-[10px]">
              {dayDrugLists?.length === 0 ? (
                <p className="text-xl font-bold text-[#05040D]">
                  복약알림을 추가해주세요!
                </p>
              ) : (
                <ul className="flex flex-col gap-[10px]">
                  {scheduleList.map((list, i) => {
                    return (
                      <li
                        key={i}
                        className={`flex justify-between rounded-[10px] border-[2px]  ${
                          list.alarm === true
                            ? "border-new_green_100 bg-white"
                            : "border-gray_60 bg-gray_60"
                        }  px-[12px]`}
                      >
                        <div
                          className="flex flex-col justify-center gap-2 py-4"
                          onClick={() => {
                            fetchScheduleInfo(list.schedule_id);
                            setStatus({
                              ...status,
                              status: "edit",
                              id: list.schedule_id,
                            });
                          }}
                        >
                          <div className="flex items-center">
                            <span className="line-clamp-2 w-52 text-ellipsis whitespace-normal text-[18px] font-semibold leading-[20px] text-[#0D0D0D]">
                              {list.name}
                            </span>
                          </div>
                          <div className="flex items-center gap-[6px] text-[#454545]">
                            <img
                              src="/images/mobile/icon/alarm_clock.svg"
                              alt="알람아이콘"
                            />
                            <p className="text-base font-semibold text-neutral-400">
                              {/* {list.hour}:{list.minute} */}
                              {timeIn12HourFormat(
                                `${list.hour}:${list.minute}`
                              )}
                            </p>
                          </div>
                        </div>
                        <div
                          key={list.id + list.n}
                          className={
                            ` my-auto flex h-[33px] w-[85px] items-center justify-center gap-2.5 rounded-[60px] border-[2px] border-new_green_100 p-2.5 ` +
                            (list.alarm === false
                              ? ` ""`
                              : list.check
                              ? " bg-new_green_100"
                              : " bg-white")
                          }
                          onClick={() => checkSchedule(list.id, list.n)}
                        >
                          <span
                            className={
                              ` text-base font-bold ` +
                              (list.alarm === false
                                ? ` text-new_green_100`
                                : list.check
                                ? " text-white"
                                : " text-new_green_100")
                            }
                          >
                            {list.alarm === false
                              ? "해제"
                              : list.check
                              ? "완료"
                              : "예정"}
                          </span>
                        </div>
                        {/* <img
                            src="/images/mobile/icon/chevron_skyblue.svg"
                            alt="수정"
                          /> */}
                      </li>
                    );
                  })}
                </ul>
              )}
              <button
                onClick={() => setStatus({ ...status, status: "add" })}
                className="fixed bottom-4 left-6 right-6 rounded-2xl bg-[#3A404D] p-4 text-body3_700 text-white"
              >
                복약알림 추가
              </button>
            </div>
          </div>
        </>
      ) : status.status === "add" || status.status === "edit" ? (
        <>
          <HeadCont
            title={status.status !== "edit" ? "복약알림 설정" : ""}
            transparent={"white"}
            underline={true}
            onLeftAction={() => {
              setStatus({ ...status, status: "show", id: null });
              valueReset();
            }}
          />

          <div className="mt-14 flex w-full flex-col gap-2 p-5 text-base font-bold text-[#444444]">
            <div className="flex-col items-start gap-2.5 py-2.5">
              <p className="text-xl font-extrabold text-gray-950">
                복약 스케줄 입력
              </p>
              <p className="text-lg font-semibold text-zinc-500">
                <span className="text-lg font-semibold text-emerald-500">
                  알림설정 할{" "}
                </span>{" "}
                약을 추가해주세요!
              </p>
            </div>
            <label className="my-[10px]">
              알림명
              <input
                name="name"
                type="text"
                placeholder="예) 감기약"
                value={value.name}
                className="my-[10px] w-full rounded-md border border-[#DFDFDF] p-[10px] font-semibold text-[#545454] focus:outline-none focus:ring-0"
                onChange={(e) =>
                  setValue({ ...value, name: e.currentTarget.value })
                }
              />
            </label>
            <label className="my-[10px]">
              <p className="text-lg">복약기간</p>
              <p className="text-base font-bold text-neutral-700">시작일</p>
              <input
                name="start_day"
                type="date"
                value={
                  status.status === "edit"
                    ? value.start_day
                    : chosenDate || dateStart
                }
                pattern="\d{4}-\d{2}-\d{2}"
                placeholder={dayjs().format("YYYY-MM-DD")}
                className="my-[10px] w-full appearance-none rounded-md border border-[#DFDFDF] bg-white p-[10px] text-center font-semibold text-[#545454] focus:outline-none focus:ring-0"
                onChange={(e) => {
                  dispatch(saveChosenDate(e.currentTarget.value));
                  setValue({ ...value, start_day: e.currentTarget.value });
                }}
              />
              {/* <p className="text-base font-bold text-neutral-700">
                {chosenDate
                  ? dayjs(chosenDate).format("YYYY-MM-DD")
                  : "날짜를 선택해주세요."}
              </p> */}
            </label>
            <div className="mb-[10px] flex w-full flex-row items-start gap-[30px]">
              <label className="flex-col">
                복약 일수
                <span className="text-[12px] text-gray_80">(최대 180일)</span>
                <div className="flex flex-row items-end gap-2.5">
                  <input
                    name="drug-period"
                    type="number"
                    value={value.period}
                    placeholder="0"
                    className="w-[112px] rounded-md border border-[#DFDFDF] p-2.5"
                    onChange={(e) => periodCheck(e)}
                  />
                  <span>일</span>
                </div>
              </label>

              <label className="flex-col">
                일일 복약 횟수
                <span className="text-[12px] text-gray_80">(최대 3회)</span>
                <div className="flex flex-row items-end gap-2.5">
                  <input
                    name="drug-number"
                    type="number"
                    placeholder="0"
                    min="1" // 최소값 설정
                    max="1" // 최대값 설정
                    value={value.number}
                    className="w-[112px] rounded-md border border-[#DFDFDF] p-2.5"
                    // onChange={(e) => {
                    //   setValue({ ...value, number: e.currentTarget.value });
                    //   //   setValue({
                    //   //     ...value,
                    //   //     alarm: [true] * e.currentTarget.value,
                    //   //   });
                    // }}
                    onChange={(e) => {
                      // 숫자만 입력 허용하는 정규식, 1부터 3까지의 숫자만 정확히 일치
                      const regex = /^([1-3]|\s*)$/;

                      // 입력값이 빈 문자열이거나 정규식과 일치하는 경우에만 상태 업데이트
                      if (regex.test(e.currentTarget.value)) {
                        setValue({
                          ...value,
                          number: e.currentTarget.value,
                        });
                      }
                      // 숫자가 아닌 다른 문자를 입력하려고 하는 경우 입력을 무시합니다.
                    }}
                  />
                  <span>회</span>
                </div>
              </label>
            </div>

            <label className="my-[10px]">
              <p className="text-lg">복약 알림 시간</p>
              <div className="flex flex-col items-center pr-12">
                {Array.from({ length: value.number }, (_, index) => (
                  <div
                    key={index}
                    className="flex w-full flex-row items-center justify-between"
                  >
                    <input
                      name="time"
                      type="time"
                      value={alarmTimes.times[index]} // 현재 인덱스에 해당하는 시간 값
                      //   placeholder={dayjs(new Date()).format("HH:mm")}
                      className="my-[10px] w-[134px] appearance-none rounded-md border border-[#DFDFDF] bg-white p-[10px] font-semibold text-[#545454] focus:outline-none focus:ring-0"
                      onChange={(e) =>
                        correctedTime(e.currentTarget.value, index)
                      }
                    />
                    <NewToggle
                      isOn={value.alarm[index]}
                      onToggle={() => toggle(index)}
                    />
                  </div>
                ))}
              </div>
            </label>

            <label className="my-[10px]">
              <p className="text-lg">복약 주기</p>
              <div
                name="checkDay"
                onClick={() => setStatus({ ...status, status: "daySet" })}
                className="my-[10px] flex w-full appearance-none flex-row justify-center gap-2 rounded-md border border-[#DFDFDF] bg-white p-[10px] text-center font-semibold text-[#545454] focus:outline-none focus:ring-0"
              >
                {checkDay[1]
                  ? checkDay.map((arr, index) => <div key={index}>{arr}</div>)
                  : checkDay}
                {/* <option value="매일">매일</option>
                <option value="일요일">일요일</option>
                <option value="월요일">월요일</option>
                <option value="화요일">화요일</option>
                <option value="수요일">수요일</option>
                <option value="목요일">목요일</option>
                <option value="금요일">금요일</option>
                <option value="토요일">토요일</option> */}
              </div>
              {/* <p className="text-base font-bold text-neutral-700">
                {chosenDate
                  ? dayjs(chosenDate).format("YYYY-MM-DD")
                  : "날짜를 선택해주세요."}
              </p> */}
            </label>

            <label className="mb-[60px]">
              메모추가
              <textarea
                name="memo"
                value={value.memo}
                className="my-[10px] h-64 w-full rounded-md border border-[#DFDFDF] p-[10px] font-semibold text-[#545454] focus:outline-none focus:ring-0"
                placeholder="메모를 입력해주세요."
                onChange={(e) =>
                  setValue({ ...value, memo: e.currentTarget.value })
                }
              />
            </label>
            {/* <InputDialog
              option={option}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              chosenDate={chosenDate}
              onSaveDiary={onSaveDiary}
              symptomArr={symptomArr}
              // feelings={feelings}
            /> */}
          </div>

          <div className="fixed bottom-4 left-6 right-6 flex gap-[10px] text-body3_700">
            {status.status === "edit" ? (
              <>
                <button
                  className="w-2/5 rounded-2xl border border-[#3A404D] bg-white p-4 text-[#3A404D]"
                  onClick={() => setOpenDialog(true)}
                >
                  삭제하기
                </button>
                <button
                  className="w-3/5 rounded-2xl bg-[#939393] p-4 text-white hover:bg-[#3A404D] focus:bg-[#3A404D]"
                  onClick={() => {
                    modifySchedule();
                  }}
                >
                  수정완료
                </button>
              </>
            ) : (
              <button
                className="w-full rounded-2xl bg-[#939393] p-4 text-white hover:bg-[#3A404D] focus:bg-[#3A404D]"
                onClick={() => {
                  upLoadAlarm();
                }}
              >
                추가하기
              </button>
            )}
          </div>

          {openDialog && (
            <Dialog
              innerClass={"p-4"}
              title={"알림 삭제"}
              desc={"해당 알림을 목록에서 삭제할까요?"}
              leftBtnTitle={"네, 삭제할게요"}
              rightBtnTitle={"아니요"}
              onLeftBtnAction={() => {
                deleteAlarmSchedule(value.schedule_id);
              }}
              onRightBtnAction={() => setOpenDialog(false)}
              onCoverAction={() => setOpenDialog(false)}
            />
          )}
        </>
      ) : (
        <div className="flex flex-wrap">
          <HeadCont
            title={""}
            transparent={"white"}
            underline={true}
            onLeftAction={() =>
              status.id === null
                ? setStatus({ ...status, status: "add" })
                : setStatus({ ...status, status: "edit" })
            }
          />
          <div className="mx-[30px] mt-14 flex w-full flex-col flex-wrap items-center gap-2 py-2.5">
            <div className="flex w-full flex-col items-start gap-2 py-2.5">
              <p className="text-lg font-bold text-neutral-700">요일설정</p>
              <div className="flex w-full flex-col items-center">
                {dayArr.map((day, i) => (
                  <div
                    key={day + i}
                    className="mb-2 flex w-full items-center justify-between rounded-[5px] border border-gray_60 bg-white px-6 py-2.5 text-center"
                    onClick={() => {
                      dayCheck(day);
                      setCheckDay(check[day]);
                    }}
                  >
                    <span className="text-lg font-bold text-zinc-500">
                      {day}
                    </span>
                    {check[day] === false ? (
                      <img
                        src="/images/mobile/icon/checkOff.svg"
                        alt="체크off"
                      />
                    ) : (
                      <img src="/images/mobile/icon/checkOn.svg" alt="체크on" />
                    )}
                  </div>
                ))}
              </div>
              <button
                onClick={() =>
                  status.id === null
                    ? setStatus({ ...status, status: "add" })
                    : setStatus({ ...status, status: "edit" })
                }
                className="flex w-full justify-center rounded-2xl bg-[#3A404D] p-4  text-body3_700 text-white"
              >
                확인
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <CalendarDialog
        type={"복약알림"}
        startDate={dateStart}
        setStartDate={setDateStart}
        handleChange={handleChange}
        chosenDate={chosenDate}
        existDates={[...existDaysOfYear]}
        openCalendar={openCalendar}
        setOpenCalendar={setOpenCalendar}
      /> */}
    </>
  );
};

export default DrugAlarm;
