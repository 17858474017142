import ApexChart from "react-apexcharts";
import dayjs from "dayjs";
import customToolTip from "./customToolTipGraph";

const WeightAreaGraph = ({ graphOption, measureUnit, weightLists }) => {
  const xData = weightLists
    ? weightLists?.map((el) => (el.data ? el.data : 0))
    : [];
  const yDate = weightLists
    ? weightLists?.map((el) => dayjs(el.day).format("MM.DD"))
    : [];

  const areaTotal =
    Math.max(...xData) < 1 ? 1 : Math.max(...xData) + Math.max(...xData) / 3;

  return (
    <div className="admin-graphs">
      <div id="yellow_h" className="border rounded-lg border-gray_60">
        {/* <ApexChart
            series={[{type: "area", data: xData }]}
            options={{
              colors: ["#FFE9A0"],
              fill: {
                type: "solid",
              },
              chart: {
                selection: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                  autoSelected: undefined,
                },
                stacked: true,
              },
              stroke: {
                curve: "straight",
                width: 0
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: yDate,
                tickPlacement: "on",
                position: "bottom",
                labels: {
                  rotate: 0,
                  hideOverlappingLabels: true,
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
                tickAmount: 7,
                crosshairs: {
                  //bar background
                  show: true,
                  position: "back",
                  width: 1,
                  stroke: {
                    color: "#A39F9B",
                    opacity: 1,
                    width: 1,
                    dashArray: 5,
                  },
                },
                tooltip: {
                  enabled: false,
                },
              },
              yaxis: {
                min: 0,
                max:areaTotal,
                tickAmount: 5,
                labels: {
                  show: true,
                  formatter: function (val) {
                    if (val <= 1) return Math.round(val * 5);
                    return Math.round(val).toLocaleString("ko-KR");
                  },
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              legend: {
                show: false,
              },
              tooltip: {
                enabled: true,
                custom: ({ series, seriesIndex, dataPointIndex }) =>
                  customToolTip(
                    series,
                    seriesIndex,
                    dataPointIndex,
                    measureUnit,
                    yDate,
                  ),
              },
              grid: {
                padding: {
                  right: 5,
                  left: 15,
                },
              },
            }}
            type="area"
            height={350}
          /> */}

        <ApexChart
          // * 수정 코드
          series={[{ data: xData }]}
          options={{
            colors: ["#FFE9A0"],
            type: "line",
            // fill: {
            //   type: "solid",
            // },
            chart: {
              selection: {
                enabled: true,
              },
              toolbar: {
                show: false,
                autoSelected: undefined,
              },
              stacked: true,
            },
            stroke: {
              curve: "smooth",
              width: [5, 5],
            },
            dataLabels: {
              enabled: false,
              formatter: function (val) {
                return val !== 0 ? val?.toFixed(1).toLocaleString("ko-KR") : "";
              },
            },
            xaxis: {
              categories: yDate,
              tickPlacement: "on",
              position: "bottom",
              labels: {
                rotate: 0,
                hideOverlappingLabels: true,
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tickAmount: 7,
              // crosshairs: {
              //   //bar background
              //   show: true,
              //   position: "back",
              //   width: 1,
              //   stroke: {
              //     color: "#A39F9B",
              //     opacity: 1,
              //     width: 1,
              //     dashArray: 5,
              //   },
              // },
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              min: 0,
              max: areaTotal,
              tickAmount: 5,
              labels: {
                show: true,
                formatter: function (val) {
                  if (val <= 1) return Math.round(val * 5);
                  return Math.round(val).toLocaleString("ko-KR");
                },
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            legend: {
              show: false,
            },
            tooltip: {
              enabled: true,
              custom: ({ series, seriesIndex, dataPointIndex }) =>
                customToolTip(
                  series,
                  seriesIndex,
                  dataPointIndex,
                  measureUnit,
                  yDate
                ),
            },
            grid: {
              padding: {
                right: 5,
                left: 15,
              },
            },
          }}
          type="line"
          height={350}
        />
      </div>
    </div>
  );
};

export default WeightAreaGraph;
