import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

const CalendarDialog = ({
  type,
  startDate,
  setStartDate,
  handleChange,
  chosenDate,
  existDates,
  openCalendar,
  setOpenCalendar,
}) => {
  const { loginInfo } = useSelector((state) => state.commonApp);
  registerLocale("ko", ko); //한국어 설정
  const [selectedDays, setSelectedDays] = useState({});
  // const [selectedDay, setSelectedDay] = useState([]);
  const ref = useRef(null);
  const typeChecker = {
    기록완료: "#417FFF",
    식사기록: "#FE718E",
    체중기록: "#FFC700",
    증상기록: "#847CE9",
    복약알림: "#01B68B",
    진료알림: "#51CCF3",
    걸음수: "#FE718E",
  };

  const formatDate = (d) => {
    //달력 년, 월 header
    const date = new Date(d);
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}년 ${`${monthIndex}`.slice(-2)}월`;
  };
  const [prevMonth, setPrevMonth] = useState(
    new Date(dayjs(startDate).subtract(1, "month"))
  );
  const [nextMonth, setNextMonth] = useState(
    new Date(dayjs(startDate).add(1, "month"))
  );

  useEffect(() => {
    let dates = [];
    if (existDates?.length > 0) {
      existDates.forEach((el) => dates.push(new Date(el)));
      setSelectedDays([...dates]);
      // setSelectedDay(dates);
    }
  }, [existDates]);

  const animation = useAnimation();
  const variants = {
    toLeft: {
      x: "-100%",
      pointerEvents: "none",
    },
    toRight: {
      x: "100%",
      pointerEvents: "none",
    },
    center: {
      x: 0,
      pointerEvents: "initial",
    },
  };

  const handleDragEnd = async (evt, { offset }) => {
    console.log(evt, offset.x);
    const power = (offset.x / window.innerWidth) * 100;

    if (power > 10) {
      await animation.start("toRight");
      setStartDate(
        new Date(dayjs(startDate).subtract(1, "month").set("date", 1))
      );
    } else if (power < -10) {
      await animation.start("toLeft");
      setStartDate(new Date(dayjs(startDate).add(1, "month").set("date", 1)));
    }
  };

  useEffect(() => {
    setPrevMonth(
      new Date(dayjs(startDate).subtract(1, "month").set("date", 1))
    );
    setNextMonth(new Date(dayjs(startDate).add(1, "month").set("date", 1)));
  }, [startDate]);

  return (
    <AnimatePresence initial={false} mode="wait">
      {openCalendar && (
        <motion.div
          className="fixed inset-0 z-20 flex w-full items-center justify-center bg-black bg-opacity-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          onClick={() => {
            if (chosenDate) {
              setStartDate(new Date(chosenDate));
            } else {
              setStartDate(new Date());
            }
            setOpenCalendar(false);
          }}
        >
          <motion.div
            className="fixed bottom-0 z-30 flex w-screen flex-col overflow-hidden rounded-t-[21px] bg-[#fff] pt-4 text-black"
            initial={{ height: 0 }}
            animate={{ height: "fit-content" }}
            exit={{ height: 0 }}
            transition={{
              y: { type: "spring", stiffness: 800, damping: 50 },
              duration: 0.2,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <img
              className="absolute right-10 top-7"
              src="/images/mobile/icon/close.svg"
              alt="닫기"
              onClick={() => {
                if (chosenDate) {
                  setStartDate(new Date(chosenDate));
                } else {
                  setStartDate(new Date());
                }
                setOpenCalendar(false);
              }}
            />

            <div className="mb-[30px] flex items-center justify-center gap-5 p-[10px] px-5 text-xl font-semibold ">
              <img
                src="/images/mobile/icon/calendarChevron.svg"
                alt="이전 달"
                className="rotate-180"
                onClick={async () => {
                  await animation.start("toRight");
                  setStartDate(
                    new Date(
                      dayjs(startDate).subtract(1, "month").set("date", 1)
                    )
                  );
                  animation.stop();
                }}
              />
              <span>{formatDate(startDate)}</span>
              <img
                src="/images/mobile/icon/calendarChevron.svg"
                alt="다음 달"
                onClick={async () => {
                  await animation.start("toLeft");
                  setStartDate(
                    new Date(dayjs(startDate).add(1, "month").set("date", 1))
                  );
                  // animation.stop();
                }}
              />
            </div>
            <div className="mx-auto mb-[10px] flex w-full justify-around px-5 text-[15px] font-bold text-[#9F9F9F] ">
              {["일", "월", "화", "수", "목", "금", "토"].map((val) => (
                <p className="self-center" key={val}>
                  {val}
                </p>
              ))}
            </div>
            <motion.div
              key={startDate}
              className={`relative flex h-[308px] justify-center px-5`}
              drag="x"
              dragDirectionLock
              // dragConstraints={{ left: 0, right: 0 }}
              onDragEnd={handleDragEnd}
              variants={variants}
              animate={animation}
              dragMomentum={false}
              // shouldCloseOnSelect={true}
              // transition={{
              //   x: { type: "spring", mass: 0.5, stiffness: 500, damping: 50 },
              // }}
            >
              <DatePicker
                selected={prevMonth}
                locale="ko"
                inline
                calendarStartDay={1}
                renderCustomHeader={() => null}
                maxDate={
                  type === "식사기록" ||
                  type === "증상기록" ||
                  type === "체중기록"
                    ? new Date()
                    : null
                }
              />
              <DatePicker
                calendarClassName={`${type}`}
                ref={ref}
                selected={new Date(startDate)}
                highlightDates={selectedDays}
                onChange={handleChange}
                disabledKeyboardNavigation //다른 월의 같은 날짜시 자동 selected 되는 현상 방지
                locale="ko"
                inline
                maxDate={
                  type === "식사기록" ||
                  type === "증상기록" ||
                  type === "체중기록"
                    ? new Date()
                    : null
                }
                // popperModifiers={{
                //   //화면을 벗어나지 않도록 하는 설정
                //   preventOverflow: { enabled: true },
                // }}
                popperPlacement="auto" //화면 중앙에 팝업이 출현
                renderDayContents={(dayOfMonth) => (
                  <div className="font-sans">{dayOfMonth}</div>
                )}
                renderCustomHeader={(
                  { date, decreaseMonth, increaseMonth } //header 커스텀 설정
                ) => null}
              />
              <DatePicker
                selected={nextMonth}
                locale="ko"
                inline
                calendarStartDay={1}
                renderCustomHeader={() => null}
                maxDate={
                  type === "식사기록" ||
                  type === "증상기록" ||
                  type === "체중기록"
                    ? new Date()
                    : null
                }
              />
            </motion.div>
            <div className="h-[2px] w-full bg-[#EFEFEF]"></div>
            <div className="flex items-center justify-between px-7 py-5">
              <div className="flex items-center gap-[10px] text-[14px] text-[#5A5A5A]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                >
                  <circle
                    cx="3.5"
                    cy="3.5"
                    r="3.5"
                    fill={`${typeChecker[type]}`}
                  />
                </svg>
                <p>{type}</p>
              </div>
              <button
                className="rounded-full border border-[#417FFF] bg-[#F6FAFF] px-[10px] py-1 text-[13px] text-[#417FFF]"
                onClick={async () => {
                  if (
                    dayjs(startDate).get("year") ===
                      dayjs(new Date()).get("year") &&
                    dayjs(startDate).get("month") ===
                      dayjs(new Date()).get("month")
                  ) {
                    setStartDate(new Date());
                  } else if (new Date(startDate) > new Date()) {
                    await animation.start("toRight");
                    setStartDate(new Date());
                  } else if (new Date(startDate) < new Date()) {
                    await animation.start("toLeft");
                    setStartDate(new Date());
                  }
                }}
              >
                오늘
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CalendarDialog;
