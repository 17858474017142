const ContentsTableHeader = ({ HeadList }) => {
  return (
    <thead className="px-5">
      <tr>
        {HeadList?.map((head, idx) => (
          <th
            key={idx}
            className={"bg-gray_60 py-4 px-2 text-caption_1 font-semibold text-black border-gray_80 border-l border-y last:border-r first:rounded-tl-lg last:rounded-tr-lg"
                      }>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};
export default ContentsTableHeader;
