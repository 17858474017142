import React from "react";
import Parser from "html-react-parser";
import HeadCont from "../../components/common/HeadCont";

const Source = () => {
  const datas = [
    {
      id: 1,
      title: "1. 힐리어리 레퍼런스",
      contents: `1) Muscaritoli M, Arends J, Bachmann P, Baracos V, Barthelemy N, Bertz H, Bozzetti F, Hütterer E, Isenring E, Kaasa S, Krznaric Z, Laird B, Larsson M, Laviano A, Mühlebach S, Oldervoll L, Ravasco P, Solheim TS, Strasser F, de van der Schueren M, Preiser JC, Bischoff SC. ESPEN practical guideline: Clinical Nutrition in cancer. Clin Nutr. 2021 May;40(5):2898-2913. doi: 10.1016/j.clnu.2021.02.005. Epub 2021 Mar 15. PMID: 33946039.

    2) Thompson KL, Elliott L, Fuchs-Tarlovsky V, Levin RM, Voss AC, Piemonte T. Oncology Evidence-Based Nutrition Practice Guideline for Adults. J Acad Nutr Diet. 2017 Feb;117(2):297-310.e47. doi: 10.1016/j.jand.2016.05.010. Epub 2016 Jul 16. PMID: 27436529.
    
    3) Lis, C. G., Gupta, D., Lammersfeld, C. A., Markman, M., & Vashi, P. G. Role of nutritional status in predicting quality of life outcomes in cancer–a systematic review of the epidemiological literature. Nutrition journal, 2012,11, 1-18.
    
    4) Playdon, M. C., Bracken, M. B., Sanft, T. B., Ligibel, J. A., Harrigan, M., & Irwin, M. L. Weight gain after breast cancer diagnosis and all-cause mortality: systematic review and meta-analysis. JNCI: Journal of the National Cancer Institute, 2015,107(12).
    
    5) Pang, Y., Wei, Y., & Kartsonaki, C. Associations of adiposity and weight change with recurrence and survival in breast cancer patients: a systematic review and meta-analysis. Breast Cancer, 2020, 29(4), 575-588.
    
    6) Zhao XH, Yang T, Ma XD, Qi YX, Lin YY, Chen XZ, Duan YQ, Sun DL. Heterogeneity of nutrition care procedures in nutrition guidelines for cancer patients. Clin Nutr. 2020 Jun;39(6):1692-1704. doi: 10.1016/j.clnu.2019.08.022. Epub 2019 Sep 9. PMID: 31542246.
    
    7) Pirat A, Tucker AM, Taylor KA, Jinnah R, Finch CG, Canada TD, Nates JL. Comparison of measured versus predicted energy requirements in critically ill cancer patients. Respir Care. 2009 Apr;54(4):487-94. PMID: 19327184.
    
    8) Khalid, U., Spiro, A., Baldwin, C., Sharma, B., McGough, C., Norman, A. R., ... & Andreyev, H. J. N. Symptoms and weight loss in patients with gastrointestinal and lung cancer at presentation. Supportive care in cancer, 2007,15, 39-46.
    
    9) Sánchez-Lara, K., Ugalde-Morales, E., Motola-Kuba, D., & Green, D. Gastrointestinal symptoms and weight loss in cancer patients receiving chemotherapy. British Journal of Nutrition, 2013, 109(5), 894-897. doi:10.1017/S0007114512002073
    
    10) Kubrak, C., Olson, K., Jha, N., Jensen, L., McCargar, L., Seikaly, H., ... & Baracos, V. E. Nutrition impact symptoms: key determinants of reduced dietary intake, weight loss, and reduced functional capacity of patients with head and neck cancer before treatment. Head & Neck: Journal for the Sciences and Specialties of the Head and Neck, 2010,32(3), 290-300.
    
    11) Bergoz, R., & de Peyer, R. Intestinal lactase and milk consumption before and after gastrectomy. Schweizerische Medizinische Wochenschrift, 1979, 109.16: 605-606.
    
    12) Korean Dietetic Association. Manual of medical nutrition therapy. 4th ed. Korean Dietetic Association, 2022.
    
    13) Rodgers L, Phillips CA. Dumping syndrome: Causes, management, and patient education: Learn how to identify and manage this under-recognized syndrome. American Nurse Today, 2018, 13.1: 6-10.
    
    14) Memorial Sloan Kettering Cancer Center. Eating After Your Gastrectomy. Memorial Sloan Kettering Cancer Center, 2022 Dec
    
    15) Diet and Nutrition After Gastrectomy: Your Plan | Center for Cancer Research [Internet]. [cited 2023 Mar 21]. Available from: 
    https://ccr.cancer.gov/surgical-oncology-program/clinical-team/diet-and-nutrition-after-gastrectomy-your-plan
    
    16) Rogers C. Postgastrectomy nutrition. Nutrition in Clinical Practice, 2011, 26.2: 126-136.
    
    17) Akbulut G. Nutrition in stoma patients: a practical view of dietary therapy. International Journal of Hematology and Oncology, 2011, 32.4: 061-066.
    
    18) Burgess-Stocks J. Eating with an Ostomy. UOAA: United Ostomy Associations of America, 2022 Feb;76.
    
    19) Swan WI, Vivanti A, Hakel-Smith NA, Hotson B, Orrevall Y, Trostler N, Beck Howarter K, Papoutsakis C. Nutrition Care Process and Model Update: Toward Realizing People-Centered Care and Outcomes Management. J Acad Nutr Diet. 2017 Dec;117(12):2003-2014. doi: 10.1016/j.jand.2017.07.015. Epub 2017 Oct 5. PMID: 28988837.`,
    },
    {
      id: 2,
      title: "2. 증상 설문지",
      contents: `The PRO-CTCAE™ items and information herein were 
    developed by the Division of Cancer Control and 
    Population Sciences in the NATIONAL CANCER INSTITUTE 
    at the NATIONAL INSTITUTES OF HEALTH, 
    in Bethesda, Maryland, U.S.A.
    Use of the PRO- CTCAE™ is subject to
    NCI’s Terms of Use.<br/> 
    한글판 환자자기평가용 이상사례공통용어기준
    (PRO-CTCAE) Cho J, Yoon J, Kim Y, Oh D, Kim SJ,
    Ahn J, Suh GY, Nam SJ, Mitchell SA.
    Linguistic Validation of the US National Cancer
    Institute's Patient-Reported Outcomes 
    Version of the Common Terminology
    Criteria for Adverse Events in Korean.<br/> 
    J Glob Oncol. 2019 Mar;5:1-10. doi: 10.1200/
    JGO.18.00193. PMID: 30917069; 
    PMCID: PMC644907`,
    },
    {
      id: 3,
      title: "3. 식품영양 DB",
      contents: "식품의약품안천저, 식품영양성분DB, 2021.11.08.",
    },
    {
      id: 4,
      title: "4. 레시피 DB",
      contents: `식품의약품안전처, 조리식품의 레시피 DB, 2020.05.25.
      농림수산식품교육문화정보원, 레시피 기본정보, 2017.01.09.
      농림수산식품교육문화정보원, 레시피 과정정보, 2017.01.09.`,
    },
    {
      id: 5,
      title: "5. 특허",
      contents:
        "암환자를 위한 식단 제공 시스템 및 그 방법 특허 제 10-2474393 호",
      imgUrl: "/images/mobile/image/certificate.svg",
    },
  ];

  return (
    <>
      <HeadCont title={"정보 출처"} />
      <section className="mt-14 border-t p-4">
        <ul className="whitespace-pre-line break-words">
          {datas.map((data) => (
            <li key={data.id} className="mb-2">
              <h2 className="text-caption2_700 text-gray_160">{data.title}</h2>
              <p className="text-caption2_400 text-gray_120">
                {Parser(data.contents)}
              </p>
              {data?.imgUrl && (
                <img src={data.imgUrl} alt="특허증" className="mx-auto" />
              )}
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default Source;
