const symptomGuide = (exampleHeadList, exampleDatas, exampleColors) => (
  <table
    className="w-full h-min table-auto border-separate"
    cellSpacing={0}
  >
    <thead>
      <tr className="bg-gray_95">
        <th className="rounded-tl-md px-2 border border-gray_80">
          <span className="block w-4 h-4 rounded-full bg-gray_10 border border-gray_80"></span>
        </th>
        {exampleHeadList?.map((head, idx) => (
          <th key={idx} className="py-2 px-1 text-xs text-caption_2 font-semibold text-gray_10 last:rounded-tr-md border-r border-y border-gray_80 last:border-l-0">
            {head}
          </th>
        ))}
      </tr>
    </thead>
    <tbody >
      {exampleDatas?.map((data, idx) => (
        <tr key={idx} className="cursor-pointer hover:bg-primary_10">
          <td className="px-2 py-1 border-x border-gray_80">
            <span
              className={"block w-4 h-4 rounded-full border border-gray_80 "}
              style={{
                backgroundColor: exampleColors[idx],
                borderColor: exampleColors[idx],
              }}
            ></span>
          </td>
          <td className="px-1 py-1 text-xs text-center text-caption_2 text-gray_10 border-r border-gray_80">
            {data.발생빈도}
          </td>
          <td className="px-1 py-1 text-xs text-center text-caption_2 text-gray_10 border-r border-gray_80">
            {data.심각도}
          </td>
          <td className="px-1 py-1 text-xs text-center text-caption_2 text-gray_10 border-r border-gray_80">
            {data.일상생활지장}
          </td>
          <td className="px-1 py-1 text-xs text-center text-caption_2 text-gray_10 border-r border-gray_80">
            {data.발생유무}
          </td>
          <td className="px-1 py-1 text-xs text-center text-caption_2 text-gray_10 border-r border-gray_80 last:border-r">
            {data.양}
          </td>
        </tr>
      ))}
      <tr className="">
        <td className="px-2 border border-gray_80 rounded-bl-md">
          <img src=" /images/admin/icon/해당없음.svg" alt="해당없음 아이콘" />
        </td>
        <td
          colSpan="5"
          className="col-span-4 text-xs text-center py-1 text-caption_1 text-gray_10 border border-l-0 border-gray_80 rounded-br-md"
        >
          질문 해당 안됨
        </td>
      </tr>
    </tbody>
  </table>
);

export default symptomGuide;