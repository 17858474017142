import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import toastCommonProps from "../../../common/toast";
import { adminFindId } from "../../../features/admin/adminSlice";
import CommonSquareBox from "../components/common/CommonSquareBox";
import {
  errorInputRing,
  errorMsgColor,
  normalInputRing,
  normalMsgColor,
} from "../data/TailwindCommon";
import { BGButtonCustom } from "../components/common/ButtonCustom";
import { checkName, checkPhone } from "../commonfunc/Reg";
import CustomCenterModal from "../components/common/CustomCenterModal";
import { LabelAdminInput } from "../components/common/LabelAdminInput";

const FindId = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [findInput, setFindInput] = useState({
    name: "",
    phoneNumber: "",
  });
  const [findid, setFindId] = useState("");
  const [findCode, setFindCode] = useState(false);
  const checkNameFc = findInput.name === "" || checkName(findInput.name);
  const checkPhoneFc =
    findInput.phoneNumber === "" || checkPhone(findInput.phoneNumber);
  const checkInputStringNone =
    findInput.name === "" ||
    findInput.phoneNumber === "" ||
    !checkNameFc ||
    !checkPhoneFc;

  const onChangeFind = (e) => {
    const { name, value } = e.target;
    setFindInput({ ...findInput, [name]: value });
  };
  const FindIdIn = async () => {
    let response = await dispatch(adminFindId(findInput)).unwrap();
    try {
      if (response.data.ok === true) {
        setFindId(response.data.adminId);
        showFindCodeModal();
      } else {
        toast(
          <p className="whitespace-pre-line">
            검색 결과가 없습니다. <br /> 확인 후 다시 시도해주세요.
          </p>,
          toastCommonProps("top-right", "toast_alert", 1000)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showFindCodeModal = () => {
    setFindCode(!findCode);
  };

  const goSignin = () => {
    navigate("/admin/signin");
  };

  return (
    <>
      {findCode && (
        <CustomCenterModal
          onClickFc={showFindCodeModal}
          customlayout={"bg-white rounded-xl p-[24px] w-[400px]"}
        >
          <div className="flex flex-col items-center justify-center ">
            <div className="flex flex-row items-start px-4 py-8">
              <p className="text-body1_400 text-center text-black">
                귀하의 아이디는 <br />
                <span className="text-correct">{findid} </span>
                입니다.
              </p>
            </div>
            <BGButtonCustom
              onClick={goSignin}
              content={"확인 및 로그인"}
              bgcolor={"bg-orange_80"}
              textcolor={"text-white"}
              textsize={"text-button_700"}
            />
          </div>
        </CustomCenterModal>
      )}
      <div className="flex items-center">
        <div className=" flex h-[554px] flex-col items-center">
          <CommonSquareBox>
            <img
              src="/images/admin/icon/힐리어리아이디찾기.svg"
              alt="힐리어리 아이디찾기 이미지"
              className="mb-[37px] items-center"
            />
            <div className="flex w-full flex-col items-end ">
              <div className="flex flex-col items-end gap-[20px]">
                <LabelAdminInput
                  title={"이름"}
                  name={"name"}
                  type={"text"}
                  value={findInput.name}
                  placeholderVal={"이름을 입력해주세요"}
                  inputcss={
                    checkNameFc
                      ? "w-[360px]" + normalInputRing
                      : "w-[360px]" + errorInputRing
                  }
                  onChangeAction={(e) => onChangeFind(e)}
                  onKeyPressAction={FindIdIn}
                  checkCss={!checkNameFc ? errorMsgColor : ""}
                  checkVal={!checkNameFc && "이름 형식을 체크해주세요."}
                />
                <LabelAdminInput
                  title={"휴대폰 번호"}
                  name={"phoneNumber"}
                  type={"text"}
                  value={findInput.phoneNumber}
                  placeholderVal={"예)010-1234-1234"}
                  inputcss={
                    checkPhoneFc
                      ? "w-[360px]" + normalInputRing
                      : "w-[360px]" + errorInputRing
                  }
                  onChangeAction={(e) => onChangeFind(e)}
                  onKeyPressAction={FindIdIn}
                  checkCss={!checkPhoneFc ? errorMsgColor : normalMsgColor}
                  checkVal={
                    !checkPhoneFc
                      ? "전화번호 형식을 체크해주세요."
                      : "가입시 등록했던 휴대폰 번호를 ‘-’ 와 함께 입력해주세요"
                  }
                />
              </div>
              <div className="flex flex-col items-start gap-[10px] self-stretch pt-[37px]">
                <BGButtonCustom
                  onClick={checkInputStringNone ? undefined : FindIdIn}
                  content={"확인"}
                  bgcolor={
                    checkInputStringNone ? "bg-orange_60" : "bg-orange_100"
                  }
                  textcolor={"text-gray_10"}
                  textsize={"text-caption1_600"}
                />
                <BGButtonCustom
                  onClick={goSignin}
                  content={"홈으로"}
                  bgcolor={"bg-gray_120"}
                  textcolor={"text-gray_10"}
                  textsize={"text-caption1_600"}
                />
              </div>
            </div>
          </CommonSquareBox>
        </div>
      </div>
    </>
  );
};

export default FindId;
